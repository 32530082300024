import { defineMessages } from "react-intl";

export const messages = defineMessages({
  mainQuestion: {
    id: "ReviewScreen.mainQuestion",
    description: "main question for review",
    defaultMessage: "How was your overall\nexperience with Eyr?",
  },
  lowRatingCategoriesTitle: {
    id: "ReviewScreen.lowRatingCategoriesTitle",
    description: "Categories section low rating title",
    defaultMessage: "What are you disappointed with?",
  },
  notesPlaceholder: {
    id: "ReviewScreen.notesPlaceholder",
    description: "Other notes input placeholder text",
    defaultMessage:
      "If you have additional feedback, please describe here (do not disclose any sensitive personal information)",
  },
  submitButtonTitle: {
    id: "ReviewScreen.submitButtonTitle",
    description: "Submit button text",
    defaultMessage: "Send feedback",
  },
});
