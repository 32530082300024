import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { MILLISECONDS_TO_SKIP } from "@eyr-mobile/core/Audio";

import { PlayerControls } from "../PlayerControls";

import { styles } from "./AudioPlayer.styles";

export function AudioPlayer({ duration, source, onReady }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [positionMillis, setPositionMillis] = useState(0);
  const [durationMillis, setDurationMillis] = useState(duration || 0);
  const audioRef = useRef(null);
  const playerRef = useRef(null);

  const handleAudioPlayPause = useCallback(() => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      playerRef.current.play();
    }
  }, [isPlaying]);

  const handleBackwards = useCallback(() => {
    playerRef.current.currentTime(
      playerRef.current.currentTime() - MILLISECONDS_TO_SKIP / 1000
    );
  }, []);

  const handleForwards = useCallback(() => {
    playerRef.current.currentTime(
      playerRef.current.currentTime() + MILLISECONDS_TO_SKIP / 1000
    );
  }, []);

  const handleSeekTo = useCallback(async (value) => {
    if (playerRef.current) {
      const seekPosition = value / 1000;
      playerRef.current.currentTime(seekPosition);
    }
  }, []);

  useEffect(() => {
    if (!playerRef.current) {
      const audioElement = audioRef.current;

      if (!audioElement) {
        return;
      }

      const player = (playerRef.current = videojs(
        audioElement,
        {
          sources: source,
          audioOnlyMode: true,
        },
        () => {
          setIsLoading(false);
          onReady && onReady(player);
        }
      ));
      player.on("play", () => setIsPlaying(true));
      player.on("pause", () => setIsPlaying(false));
      player.on("timeupdate", () =>
        setPositionMillis(player.currentTime() * 1000)
      );
      player.on("durationchange", () =>
        setDurationMillis(player.duration() * 1000)
      );
      player.on("seeking", () => setIsLoading(true));
      player.on("seeked", () => setIsLoading(false));
    } else {
      const player = playerRef.current;
      player.src(source);
    }
  }, [onReady, source]);

  useEffect(() => {
    return () => {
      const player = playerRef.current;
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  return (
    <View style={styles.container}>
      <PlayerControls
        onTogglePlayPause={handleAudioPlayPause}
        isPlaying={isPlaying}
        isLoading={isLoading}
        onBackwards={handleBackwards}
        onForwards={handleForwards}
        onSeek={handleSeekTo}
        positionMillis={positionMillis}
        durationMillis={durationMillis}
      />
      <div data-vjs-player>
        <audio ref={audioRef} className="video-js vjs-hidden" />
      </div>
    </View>
  );
}

AudioPlayer.propTypes = {
  duration: PropTypes.number,
  source: PropTypes.string.isRequired,
  onReady: PropTypes.func,
};
