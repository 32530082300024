import { StyleSheet } from "react-native";

import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listItem: {
    width: "100%",
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: "space-between",
    flexDirection: "row",
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
});
