import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "DeviceRegistrationScreen.navigationTitle",
    description: "DeviceRegistrationScreen navigation title",
    defaultMessage: "Device registration",
  },
  complexityError: {
    id: "DeviceRegistrationScreen.complexityError",
    description: "Please choose a more complex PIN code",
    defaultMessage: "Please choose a more complex PIN.",
  },
  mismatchError: {
    id: "DeviceRegistrationScreen.mismatchError",
    description: "The password does not match",
    defaultMessage: "PIN did not match. Try again.",
  },
  registrationHint: {
    id: "DeviceRegistrationScreen.registrationHint",
    description: "Registration hint",
    defaultMessage: "NOTE: You'll be logged out from all other devices",
  },
  setUpPinTitle: {
    id: "DeviceRegistrationScreen.setUpPinTitle",
    description: "No pin title",
    defaultMessage: "Set up a PIN",
  },
  confirmPinTitle: {
    id: "DeviceRegistrationScreen.confirmPinTitle",
    description: "No pin title",
    defaultMessage: "Confirm PIN",
  },
  cancelRegistrationAlertTitle: {
    id: "DeviceRegistrationScreen.cancelRegistrationAlertTitle",
    description: "Cancel registration alert title",
    defaultMessage: "Cancel the registration?",
  },
  cancelRegistrationAlertCancelActionTitle: {
    id: "DeviceRegistrationScreen.cancelRegistrationAlertCancelActionTitle",
    description: "Cancel registration alert cancel action title",
    defaultMessage: "No",
  },
  cancelRegistrationAlertConfirmActionTitle: {
    id: "DeviceRegistrationScreen.cancelRegistrationAlertConfirmActionTitle",
    description: "Cancel registration alert confirm action title",
    defaultMessage: "Yes",
  },
});
