import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "AccountPaymentMethodsScreen.navigationTitle",
    description: "AccountPaymentMethodsScreen navigation title",
    defaultMessage: "Payment methods",
  },
  deletionAlertTitle: {
    id: "AccountPaymentMethodsScreen.deletionAlertTitle",
    description: "Delete payment method alert title",
    defaultMessage: "Delete payment method",
  },
  deletionAlertMessage: {
    id: "AccountPaymentMethodsScreen.deletionAlertMessage",
    description: "Delete payment method alert message",
    defaultMessage: "Are you sure you want to delete this payment method?",
  },
  deletionAlertButtonOk: {
    id: "AccountPaymentMethodsScreen.deletionAlertButtonOk",
    description: "Confirm payment method deletion message",
    defaultMessage: "Confirm",
  },
  deletionAlertButtonCancel: {
    id: "AccountPaymentMethodsScreen.deletionAlertButtonCancel",
    description: "Reject payment method deletion message",
    defaultMessage: "Cancel",
  },
  accountPaymentMethodsTitle: {
    id: "AccountPaymentMethodsScreen.accountPaymentMethodsTitle",
    description: "Account payment methods title",
    defaultMessage: "Your payment methods",
  },
  emptyPaymentMethodsListTitle: {
    id: "AccountPaymentMethodsScreen.emptyPaymentMethodsListTitle",
    description: "Empty payment methods list title",
    defaultMessage: "No payment methods available",
  },
});
