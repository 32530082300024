import React from "react";
import RNPickerSelect from "react-native-picker-select";
import { has, omit } from "lodash/fp";

import { SVGs } from "../../res";

import {
  styles,
  iconStylingProps,
  pickerStylingProps,
} from "./EyrPickerSelect.styles";

function Icon() {
  return <SVGs.MDArrowDropDownOutlinedMono {...iconStylingProps} />;
}

/**
 * @spec https://github.com/lawnstarter/react-native-picker-select/blob/8.0.0/test/test.js
 * Apply default styling
 */
export function EyrPickerSelect(props) {
  const style = has("children", props)
    ? omit(["viewContainer"], styles)
    : styles;
  const { touchableWrapperProps, ...rest } = props;
  return (
    <RNPickerSelect
      pickerProps={{ mode: "dialog", ...pickerStylingProps }}
      modalProps={{ transparent: true }}
      Icon={Icon}
      {...rest}
      style={style}
    />
  );
}

EyrPickerSelect.defaultProps = {
  placeholder: {},
};
