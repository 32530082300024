import { StaticConfig } from "../Config";

export function getSessionTrackingUserIdFromAccount(account = {}) {
  if (StaticConfig.environment !== "production") {
    if (account.id && account.countryUid) {
      return `id=${account.id};countryUid=${account.countryUid};`;
    }
  }
  if (account.slug) {
    return `slug=${account.slug};`;
  }
}
