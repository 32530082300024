import { defineMessages } from "react-intl";

export const messages = defineMessages({
  veryBadTitle: {
    id: "RatingInput.veryBadTitle",
    description: "Explainer text for very bad rating",
    defaultMessage: "Very bad",
  },
  veryGoodTitle: {
    id: "RatingInput.veryGoodTitle",
    description: "Explainer text for very good rating",
    defaultMessage: "Very good",
  },
  ratingLabel: {
    id: "RatingInput.ratingLabel",
    description: "Accessability label for rating item",
    defaultMessage: "Select rating {rating} stars",
  },
});
