import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Pressable, View, KeyboardAvoidingView, Platform } from "react-native";
import { delay } from "lodash/fp";
import { useIsFocused } from "@react-navigation/native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { renderIf } from "@eyr-mobile/core/Lib";
import { createValidator } from "@eyr-mobile/core/Validation";
import { AuthType, useAuth } from "@eyr-mobile/domain/Auth";
import { useAlert } from "@eyr-mobile/core/Alert";
import { useAppState } from "@eyr-mobile/core/AppState";
import { useCall } from "@eyr-mobile/domain/Call";
import { useBiometry } from "@eyr-mobile/core/Biometry";

import { SVGs } from "../../res";
import { PINCode, Paragraph, Heading } from "../../components";

import { stylingProps, styles } from "./SessionRecoveryScreen.styles";
import { messages } from "./SessionRecoveryScreen.messages";

function LogoutHeaderRight({ tintColor }) {
  const { logout } = useAuth();
  const { formatMessage } = useIntl();
  const alert = useAlert();
  const onPress = useCallback(
    () =>
      alert({
        title: formatMessage(messages.logoutAlertTitle),
        buttons: [
          {
            title: formatMessage(messages.logoutAlertCancelActionTitle),
            variant: "secondary",
          },
          {
            title: formatMessage(messages.logoutAlertConfirmActionTitle),
            variant: "primary",
            onPress: logout,
          },
        ],
      }),
    [alert, formatMessage, logout]
  );
  return (
    <View style={styles.headerRightContainer}>
      <Pressable onPress={onPress} {...stylingProps.headerRightStylingProps}>
        <SVGs.LogoutMono fill={tintColor} />
      </Pressable>
    </View>
  );
}
LogoutHeaderRight.propTypes = {
  tintColor: PropTypes.string.isRequired,
};
function LogoutHeaderRightFn(props) {
  return <LogoutHeaderRight {...props} />;
}

export function SessionRecoveryScreen() {
  const [error, setError] = useState(null);
  const { formatMessage } = useIntl();
  const delayedBiometryPromptTimerRef = useRef();
  const isFocused = useIsFocused();
  const { cancelAuthenticateAsync: cancelBiometryPrompt } = useBiometry();
  const {
    tryToUnlockWithPin,
    tryToUnlockWithBiometry,
    initializeIdentification,
    remainingPinEntryAttempts,
    type: authType,
    error: authError,
    pinLength,
    logout,
  } = useAuth();
  const [initialRemainingAttempts] = useState(remainingPinEntryAttempts);
  const alert = useAlert();
  const { callId } = useCall();
  const hasCall = Boolean(callId);

  const resetErrorOnChange = useCallback(
    (pin) => {
      if (pin.length < pinLength) {
        setError(null);
      }
    },
    [pinLength]
  );
  const validator = useMemo(
    () => createValidator(tryToUnlockWithPin),
    [tryToUnlockWithPin]
  );

  const pinValidator = useCallback(validator, [validator]);

  const presentBiometryPrompt = useCallback(
    () =>
      tryToUnlockWithBiometry({
        promptMessage: formatMessage(messages.biometryPromptTitle),
        cancelLabel: formatMessage(messages.biometryPromptCancelActionTitle),
      }),
    [formatMessage, tryToUnlockWithBiometry]
  );
  const logoutAndInitializeIdentification = useCallback(async () => {
    await logout();
    initializeIdentification();
  }, [logout, initializeIdentification]);

  const preferBiometry = authType === AuthType.BIOMETRY;
  const preferPin = authType === AuthType.PIN;
  const hasAuthError = Boolean(authError);

  const onActive = useCallback(
    (previousAppState) => {
      if (previousAppState !== "background" || !isFocused || hasCall) {
        return;
      }
      if (preferBiometry && remainingPinEntryAttempts > 0) {
        presentBiometryPrompt();
      }
    },
    [
      hasCall,
      isFocused,
      preferBiometry,
      presentBiometryPrompt,
      remainingPinEntryAttempts,
    ]
  );

  useAppState({
    onActive,
  });
  useEffect(() => {
    if (preferBiometry && remainingPinEntryAttempts > 0) {
      if (isFocused && !hasCall) {
        delayedBiometryPromptTimerRef.current = delay(
          500,
          presentBiometryPrompt
        );
      }
      if (!isFocused || hasCall) {
        if (delayedBiometryPromptTimerRef.current) {
          clearTimeout(delayedBiometryPromptTimerRef.current);
        }
        if (Platform.OS === "android") {
          cancelBiometryPrompt();
        }
      }
    }
  }, [
    hasCall,
    cancelBiometryPrompt,
    isFocused,
    preferBiometry,
    presentBiometryPrompt,
    remainingPinEntryAttempts,
  ]);

  useEffect(() => {
    if (hasAuthError) {
      alert({
        title: formatMessage(messages.authErrorAlertTitle),
        message: authError,
        buttons: [
          {
            title: formatMessage(messages.authErrorAlertConfirmActionTitle),
            variant: "primary",
            onPress: logoutAndInitializeIdentification,
          },
        ],
      });
    }
  }, [
    alert,
    authError,
    formatMessage,
    hasAuthError,
    logoutAndInitializeIdentification,
  ]);

  useEffect(() => {
    if (remainingPinEntryAttempts < initialRemainingAttempts) {
      setError(
        formatMessage(messages.invalidPinEntryMessage, {
          remainingAttempts: remainingPinEntryAttempts,
        })
      );
    }
  }, [formatMessage, initialRemainingAttempts, remainingPinEntryAttempts]);

  useEffect(() => {
    if (remainingPinEntryAttempts === 0) {
      alert({
        title: formatMessage(messages.exceededPinAttemptsAlertTitle),
        message: formatMessage(messages.exceededPinAttemptsAlertMessage),
        buttons: [
          {
            title: formatMessage(
              messages.exceededPinAttemptsAlertConfirmActionTitle
            ),
            variant: "friendly",
            onPress: logout,
          },
        ],
        onClose: logout,
      });
    }
  }, [alert, formatMessage, logout, remainingPinEntryAttempts]);

  return (
    <KeyboardAvoidingView
      {...stylingProps.keyboardAvoidingView}
      style={styles.container}
    >
      <SafeAreaView
        edges={["left", "right", "bottom"]}
        style={styles.contentContainer}
      >
        {renderIf(remainingPinEntryAttempts > 0 && !hasAuthError)(() => (
          <>
            <View style={styles.pinCodeContainer}>
              <View style={styles.textContainer}>
                <Heading spacing="l" alignment="center">
                  {formatMessage(messages.pinEntryTitle)}
                </Heading>
                <Paragraph size="l" color="negative" alignment="center">
                  {error ? error : " "}
                </Paragraph>
              </View>
              <PINCode
                length={pinLength}
                autoFocus={hasCall ? false : preferPin}
                focusOnForeground={hasCall ? false : preferPin}
                onChange={resetErrorOnChange}
                validator={pinValidator}
                resetOnFailed
                blurOnSubmit
              />
            </View>
            {renderIf(preferBiometry)(() => (
              <Pressable onPress={presentBiometryPrompt}>
                <SVGs.TouchId />
              </Pressable>
            ))}
          </>
        ))}
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
}

SessionRecoveryScreen.routeName = "SessionRecoveryScreen";
SessionRecoveryScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: LogoutHeaderRightFn,
  useResponsiveNavigationContainer: true,
};
