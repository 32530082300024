import React from "react";
import { useIntl } from "react-intl";
import { omit } from "lodash/fp";

import { SVGs } from "../../res";
import { Card } from "../Card";
import { IconContainer } from "../IconContainer";

import { messages } from "./AttachFormStageOrderSummaryItem.messages";

export function AttachFormStageOrderSummaryItem(props) {
  const { formatMessage } = useIntl();
  return (
    <Card
      icon={
        <IconContainer size="s" variant="plain">
          <SVGs.Documents />
        </IconContainer>
      }
      title={formatMessage(messages.formAttached)}
      titleIsBold
      InteractivityIconComponent={SVGs.PencilMono}
      {...omit(["__typename", "orderId", "product", "stageId"], props)}
    />
  );
}
