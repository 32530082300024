import { defineMessages } from "react-intl";

export const messages = defineMessages({
  queuePositionText: {
    id: "DropInConsultationServiceCard.queuePositionText",
    description: "queue position text",
    defaultMessage: "Queue number:",
  },
  preparationNoticeActionTitle: {
    id: "DropInConsultationServiceCard.preparationNoticeActionTitle",
    description: "preparation notice action title",
    defaultMessage: "How to prepare",
  },
  queueWaitingTime: {
    id: "DropInConsultationServiceCard.queueWaitingTime",
    description: "queue waiting time",
    defaultMessage: "Est. waiting time:",
  },
  queueWaitingTimeNumber: {
    id: "DropInConsultationServiceCard.queueWaitingTimeNumber",
    description: "queue waiting time number",
    defaultMessage: "{amount}",
  },
  refundedHeader: {
    id: "DropInConsultationServiceCard.refundedHeader",
    description: "refunded header",
    defaultMessage: "Your order has been refunded.",
  },
  refundedOrderDateTitle: {
    id: "DropInConsultationServiceCard.refundedOrderDateTitle",
    description: "refunded order date title",
    defaultMessage: "Date ordered",
  },
  refundedOrderDate: {
    id: "DropInConsultationServiceCard.refundedOrderDate",
    description: "refunded order date",
    defaultMessage: "{date} at {time}",
  },
  cancellationNoticeActionTitle: {
    id: "AppointmentConsultationServiceCard.cancellationNoticeActionTitle",
    description: "cancellation action title",
    defaultMessage: "Information about cancellation",
  },
});
