import React from "react";
import PropTypes from "prop-types";
import { View, Image } from "react-native";

import { Paragraph } from "../Paragraph";
import { Heading } from "../Heading";

import { styles } from "./Illustration.styles";

export function Illustration({ title, description, imageSource }) {
  return (
    <View>
      <View style={styles.headerContainer}>
        <View style={styles.imageContainer}>
          <Image source={imageSource} style={styles.headerImage} />
        </View>
        <View style={styles.headerFooter} />
      </View>
      <View style={styles.textContainer}>
        <Heading spacing="l" alignment="center">
          {title}
        </Heading>
        <Paragraph size="m" alignment="center" spacing="none">
          {description}
        </Paragraph>
      </View>
    </View>
  );
}

Illustration.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSource: PropTypes.any.isRequired,
};
