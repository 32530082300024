import React, { useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { Linking } from "react-native";
import { storeUrl, useAppUpdate } from "@eyr-mobile/core/AppUpdate";
import { useIntl } from "react-intl";
import { useAlert } from "@eyr-mobile/core/Alert";
import { noop } from "lodash/fp";

import { Loading } from "../Loading";

import { messages } from "./AppUpdate.messages";
export function AppUpdate({ onComplete = noop }) {
  const { formatMessage } = useIntl();
  const alert = useAlert();

  const handleRequestAppUpdate = useCallback(async () => {
    return new Promise((resolve) => {
      alert({
        title: formatMessage(messages.requestUpdateTitle),
        message: formatMessage(messages.requestUpdateMessage),
        buttons: [
          {
            title: formatMessage(messages.requestUpdateConfirmActionTitle),
            onPress: () => Linking.openURL(storeUrl),
          },
          {
            title: formatMessage(messages.requestUpdateCancelActionTitle),
            variant: "secondary",
            onPress: resolve,
          },
        ],
        buttonsLayout: "column",
      });
    });
  }, [alert, formatMessage]);

  const { appUpdateProgress, startAppUpdate } = useAppUpdate({
    onRequestAppUpdate: handleRequestAppUpdate,
  });

  useEffect(() => {
    async function prepare() {
      await startAppUpdate();
      onComplete();
    }

    prepare();
  }, [onComplete, startAppUpdate]);

  return (
    <Loading
      progress={appUpdateProgress}
      label={formatMessage(messages.gettingUpdate)}
    />
  );
}

AppUpdate.propTypes = {
  onComplete: PropTypes.func,
};
