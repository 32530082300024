import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.WHITE,
  },
  headerContainer: {
    paddingBottom: 16,
    backgroundColor: Palette.NUDE_20,
  },
  headerContentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  headerContentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  headerContentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  emptyContainer: {
    flex: 1,
  },
  emptyContentContainer: {
    flex: 1,
  },
  contentContainer: {
    paddingTop: 8,
  },
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
});

export const stylingProps = {
  searchIcon: {
    width: 22,
    height: 22,
    fill: Palette.BURGUNDY_100,
  },
};
