import { defineMessages } from "react-intl";

export const messages = defineMessages({
  microphoneDisabled: {
    id: "CallMainVideoFrame.microphoneDisabled",
    description: "Microphone disabled message",
    defaultMessage: "{name}'s microphone is currently muted",
  },
  cameraDisabled: {
    id: "CallMainVideoFrame.cameraDisabled",
    description: "Camera disabled message",
    defaultMessage: "{name}'s camera is currently turned off",
  },
  microphoneAndCameraDisabled: {
    id: "CallMainVideoFrame.microphoneAndCameraDisabled",
    description: "Microphone and camera disabled message",
    defaultMessage: "{name}'s microphone and camera are currently turned off",
  },
});
