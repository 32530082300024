import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    borderRadius: 8,
    backgroundColor: Palette.NUDE_40,
    paddingVertical: 6,
    paddingHorizontal: 12,
    alignSelf: "flex-start",
  },
});
