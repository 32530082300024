import { gql } from "@apollo/client";

const reviewRequestFields = gql`
  fragment reviewRequestFields on ReviewRequest {
    id
    ... on ConsultationReviewRequest {
      negativeCategories {
        id
        title
      }
    }
  }
`;

export const ReviewRequestedSubscription = gql`
  subscription ReviewRequestedSubscription {
    reviewRequested {
      __typename
      ...reviewRequestFields
    }
  }
  ${reviewRequestFields}
`;

export const SubmitReview = gql`
  mutation SubmitReview($input: SubmitReviewInput!) {
    submitReview(input: $input) {
      id
    }
  }
`;
