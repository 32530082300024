import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import {
  Platform,
  View,
  Modal,
  Pressable,
  useWindowDimensions,
} from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { noop, getOr, compact } from "lodash/fp";
import { CameraType } from "@eyr-mobile/core/Camera";
import { useDevice } from "@eyr-mobile/core/Device";
import { renderIf } from "@eyr-mobile/core/Lib";
import { useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";

import { SVGs } from "../../res";
import { BlurView } from "../BlurView";
import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";

import {
  styles,
  stylingProps,
  stylingHelpers,
} from "./CallControlsOverlay.styles";
import { messages } from "./CallControlsOverlay.messages";

const COMPACT_MODE_REMAINING_VERTICAL_SPACE_THRESHOLD = 0.7;

function CallControl({
  showTitle = true,
  title,
  IconComponent,
  onPress = noop,
  variant = "default",
}) {
  const getIconContainerStyle = useCallback(
    ({ pressed }) => [
      styles.callControlIconContainer,
      getOr(
        styles[`${variant}-CallControlIconContainer`],
        `${variant}-${pressed && "pressed"}CallControlIconContainer`,
        styles
      ),
    ],
    [variant]
  );
  const getCallControlIconStylingProps = useCallback(
    ({ pressed }) => ({
      ...stylingProps.callControlIcon,
      ...getOr(
        stylingProps[`${variant}-CallControlIcon`],
        `${variant}-${pressed && "pressed"}CallControlIcon`,
        stylingProps
      ),
    }),
    [variant]
  );
  return (
    <View style={styles.callControlContainer}>
      <Pressable
        accessibilityLabel={title}
        style={getIconContainerStyle}
        {...stylingProps.callControlPressable}
        onPress={onPress}
      >
        {({ pressed }) => (
          <IconComponent
            {...getCallControlIconStylingProps({
              pressed,
            })}
          />
        )}
      </Pressable>
      {showTitle && (
        <Paragraph alignment={"center"} color={"secondary"} numberOfLines={2}>
          {title}
        </Paragraph>
      )}
    </View>
  );
}

CallControl.propTypes = {
  title: PropTypes.string.isRequired,
  showTitle: PropTypes.bool,
  IconComponent: PropTypes.elementType.isRequired,
  onPress: PropTypes.func,
  variant: PropTypes.oneOf(["default", "active", "danger"]),
};

function getNextCameraType(currentCameraType) {
  switch (currentCameraType) {
    case CameraType.BACK:
      return CameraType.FRONT;
    case CameraType.FRONT:
      return CameraType.BACK;
  }
}

export function CallControlsOverlay({
  title,
  description,
  cameraType = CameraType.FRONT,
  cameraEnabled = true,
  microphoneEnabled = true,
  closable = true,
  onToggleCameraType = noop,
  onToggleCamera = noop,
  onToggleMicrophone = noop,
  onCallEnd = noop,
  onClose = noop,
  onLayout = noop,
  ...rest
}) {
  const { formatMessage } = useIntl();
  const _onToggleCameraType = useCallback(() => {
    onToggleCameraType(getNextCameraType(cameraType));
  }, [cameraType, onToggleCameraType]);
  const _onToggleCamera = useCallback(
    () => onToggleCamera(!cameraEnabled),
    [cameraEnabled, onToggleCamera]
  );
  const _onToggleMicrophone = useCallback(
    () => onToggleMicrophone(!microphoneEnabled),
    [microphoneEnabled, onToggleMicrophone]
  );
  const _onClose = closable ? onClose : noop;

  const [measuredHeight, setMeasuredHeight] = useState(null);
  const _onLayout = useCallback(
    (event) => {
      onLayout(event);
      if (!measuredHeight) {
        setMeasuredHeight(event.nativeEvent.layout.height);
      }
    },
    [measuredHeight, onLayout]
  );

  const { screenSizeSelect, isScreenSizeXS, isPhone, isTablet, isDesktop } =
    useDevice();
  const { height: screenHeight } = useWindowDimensions();
  const insets = useSafeAreaInsets();

  const modalContainerStyle = stylingHelpers.getModalContainerStyle(insets);

  const compactMode =
    isDesktop &&
    (screenHeight - measuredHeight) / screenHeight <=
      COMPACT_MODE_REMAINING_VERTICAL_SPACE_THRESHOLD;
  const Container = closable ? Pressable : View;

  return (
    <Modal
      presentationStyle={"overFullScreen"}
      transparent
      testID={"CallControlsOverlay"}
      animationType={"slide"}
      onRequestClose={_onClose}
      {...rest}
    >
      <Container style={styles.container} onPress={_onClose}>
        <View
          onLayout={_onLayout}
          style={compact([
            modalContainerStyle,
            screenSizeSelect({
              xs: styles.modalContainerXS,
              s: styles.modalContainerS,
            }),
            compactMode && styles.modalContainerCompactOverride,
          ])}
        >
          {renderIf(isScreenSizeXS)(() => (
            <>
              {
                //https://github.com/expo/expo/issues/23239
                Platform.OS !== "android" && (
                  <BlurView
                    {...stylingProps.blurView}
                    style={styles.blurView}
                  />
                )
              }
              <View
                style={compact([
                  styles.textContainer,
                  compactMode && styles.textContainerCompactOverride,
                ])}
              >
                <Heading
                  color={"secondary"}
                  spacing={"s"}
                  numberOfLines={1}
                  ellipsizeMode={"tail"}
                >
                  {title}
                </Heading>
                {description && !compactMode && (
                  <Paragraph
                    color={"secondary"}
                    size={"l"}
                    numberOfLines={1}
                    ellipsizeMode={"tail"}
                  >
                    {description}
                  </Paragraph>
                )}
              </View>
            </>
          ))}
          <View style={styles.controlsContainer}>
            {(isPhone || isTablet) && (
              <CallControl
                showTitle={!compactMode}
                title={formatMessage(messages.toggleCameraTypeActionTitle)}
                IconComponent={SVGs.CameraFlip}
                variant={cameraType === CameraType.BACK ? "active" : "default"}
                onPress={_onToggleCameraType}
              />
            )}
            <CallControl
              showTitle={!compactMode}
              title={formatMessage(messages.toggleCameraActionTitle)}
              IconComponent={
                cameraEnabled ? SVGs.CameraOnMono : SVGs.CameraOffMono
              }
              variant={cameraEnabled ? "default" : "active"}
              onPress={_onToggleCamera}
            />
            <CallControl
              showTitle={!compactMode}
              title={formatMessage(messages.toggleMicrophoneActionTitle)}
              IconComponent={
                microphoneEnabled
                  ? SVGs.MicrophoneOnMono
                  : SVGs.MicrophoneOffMono
              }
              variant={microphoneEnabled ? "default" : "active"}
              onPress={_onToggleMicrophone}
            />
            <CallControl
              showTitle={!compactMode}
              title={formatMessage(messages.endCallActionTitle)}
              IconComponent={SVGs.Call}
              variant={"danger"}
              onPress={onCallEnd}
            />
          </View>
        </View>
      </Container>
    </Modal>
  );
}

CallControlsOverlay.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cameraType: PropTypes.oneOf([CameraType.FRONT, CameraType.BACK]),
  cameraEnabled: PropTypes.bool,
  microphoneEnabled: PropTypes.bool,
  onToggleCameraType: PropTypes.func,
  onToggleCamera: PropTypes.func,
  onToggleMicrophone: PropTypes.func,
  onCallEnd: PropTypes.func,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
  onLayout: PropTypes.func,
};
