import { isUndefined } from "lodash/fp";

import { logWithSessionTracking } from "../SessionTracking/SessionTrackingLogger";
// import crashlytics from "@react-native-firebase/crashlytics";

export const LOGGER_LEVEL_LOG = "log";
export const LOGGER_LEVEL_WARN = "warn";
export const LOGGER_LEVEL_ERROR = "error";

export const logger = (kind, msg, data, level = LOGGER_LEVEL_LOG) => {
  logWithSessionTracking({
    kind,
    msg,
    data,
    level,
  });
  const args = isUndefined(data)
    ? [`[eyr/${kind}]: ${msg}`]
    : [`[eyr/${kind}]: ${msg}`, data];
  if (__DEV__) {
    if (
      ![LOGGER_LEVEL_LOG, LOGGER_LEVEL_WARN, LOGGER_LEVEL_ERROR].includes(level)
    ) {
      throw new Error(
        `Received incorrect level. Expected one of error, log, warn or debug. Received ${level}`
      );
    }
    // eslint-disable-next-line no-console
    console[level](...args);
  }
};
