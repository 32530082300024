import React, { useCallback } from "react";
import { View, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { GetAccount } from "@eyr-mobile/domain/Account";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { useAuth, AuthType } from "@eyr-mobile/domain/Auth";
import { useBiometry } from "@eyr-mobile/core/Biometry";
import { Constants } from "@eyr-mobile/core/Config";
import { useDevice } from "@eyr-mobile/core/Device";

import { AccountSettings } from "../../components";
import { getTabBarIconFn } from "../../Navigation/Navigation.helpers";
import { SVGs } from "../../res";

import { messages } from "./MyAccountTabScreen.messages";
import { styles } from "./MyAccountTabScreen.styles";

export function MyAccountTabScreen() {
  const { navigate } = useNavigation();
  const {
    authenticateAsync,
    hasEnrolledHardware,
    loading: loadingBiometry,
  } = useBiometry();
  const queryResult = useQuery(GetAccount);
  const { handlers, data: { account } = {} } = withHandlers({
    ...queryResult,
    loading: queryResult.loading || loadingBiometry,
  });
  const { logout, type: authType, setPreferableAuthType } = useAuth();

  const handleEditPaymentMethods = useCallback(() => {
    navigate("AccountPaymentMethodsScreen");
  }, [navigate]);

  const handleEditContactInformation = useCallback(() => {
    navigate("EditAccountContactInfoScreen");
  }, [navigate]);

  const handleEditLanguage = useCallback(() => {
    navigate("EditAccountLanguageScreen");
  }, [navigate]);

  const handleViewConsultationHistory = useCallback(() => {
    navigate("AccountConsultationsHistoryScreen");
  }, [navigate]);

  const handleEditConsents = useCallback(() => {
    navigate("EditAccountConsentsScreen");
  }, [navigate]);

  const onHelpAndContactPress = useCallback(() => {
    navigate("HelpAndContactScreen");
  }, [navigate]);

  const onEditBiometryPreference = useCallback(
    async (nextPreferBiometry) => {
      const { success } = await authenticateAsync();
      if (!success) {
        return;
      }
      setPreferableAuthType(
        nextPreferBiometry ? AuthType.BIOMETRY : AuthType.PIN
      );
    },
    [setPreferableAuthType, authenticateAsync]
  );
  const preferBiometry = authType === AuthType.BIOMETRY;

  const { screenSizeSelect } = useDevice();

  return (
    <ScrollView style={styles.container}>
      <View
        style={screenSizeSelect({
          xs: styles.contentContainerXS,
          s: styles.contentContainerS,
          m: styles.contentContainerM,
        })}
      >
        {handlers || (
          <AccountSettings
            appVersion={Constants.expoConfig.version}
            account={account}
            onEditPaymentMethods={handleEditPaymentMethods}
            onEditContactInformation={handleEditContactInformation}
            onEditLanguage={handleEditLanguage}
            onViewConsultationHistory={handleViewConsultationHistory}
            onEditConsents={handleEditConsents}
            showBiometryPreferenceSetting={hasEnrolledHardware}
            preferBiometry={preferBiometry}
            onEditBiometryPreference={onEditBiometryPreference}
            onLogout={logout}
            onHelpAndContactPress={onHelpAndContactPress}
          />
        )}
      </View>
    </ScrollView>
  );
}

MyAccountTabScreen.routeName = "MyAccountTabScreen";
MyAccountTabScreen.navigationOptions = {
  title: messages.navigationTitle,
  tabBarIcon: getTabBarIconFn(SVGs.AccountMono),
};
