import { gql } from "@apollo/client";

export const GetConsentsToAccept = gql`
  query GetConsentsToAccept($category: String, $module: String) {
    consentsToAccept(category: $category, module: $module) {
      id
      description
      category
      fragment
      policyVersion
      module
      policyUrl
      summary
      isRequired
    }
  }
`;
export const AcceptConsent = gql`
  mutation AcceptConsent($id: Int!) {
    acceptConsent(id: $id) {
      id
    }
  }
`;

export const RejectConsent = gql`
  mutation RejectConsent($id: Int!) {
    rejectConsent(id: $id) {
      id
      category
      isRequired
    }
  }
`;

export const GetAccountAcceptedConsents = gql`
  query GetAccountAcceptedConsents {
    account {
      id
      orgAccounts {
        orgAccountConsents(rejected: false) {
          consent {
            id
            description
            isRequired
            module
            fragment
            policyUrl
            policyVersion
            category
            summary
          }
          updatedAt
        }
        org {
          id
          name
        }
      }
    }
  }
`;
