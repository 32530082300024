import { flow, pick, values, map, toLower, some, includes } from "lodash/fp";

export function includesValueInOneOfTheObjectFields({ value, fields, object }) {
  return flow([
    pick(fields),
    values,
    map(toLower),
    some(includes(toLower(value))),
  ])(object);
}
