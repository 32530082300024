import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  modalContainer: {
    justifyContent: "space-between",
    overflow: "hidden",
    padding: 16,
  },
  modalContainerXS: {
    width: "100%",
    backgroundColor: Palette.OVERLAY_DARK_40,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    padding: 24,
    paddingTop: 16,
  },
  modalContainerS: {
    width: 350,
    backgroundColor: Palette.TRANSPARENT,
  },
  modalContainerCompactOverride: {
    padding: 12,
  },
  blurView: StyleSheet.absoluteFill,
  textContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  textContainerCompactOverride: {
    marginBottom: 12,
  },
  controlsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  callControlContainer: {
    width: 72,
    alignItems: "center",
  },
  callControlIconContainer: {
    width: 54,
    height: 54,
    borderRadius: 54,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 8,
  },
  "default-CallControlIconContainer": {
    backgroundColor: Palette.OVERLAY_LIGHT_20,
  },
  "default-pressedCallControlIconContainer": {
    backgroundColor: Palette.OVERLAY_LIGHT_15,
  },
  "active-CallControlIconContainer": {
    backgroundColor: Palette.WHITE,
  },
  "active-pressedCallControlIconContainer": {
    backgroundColor: Palette.GRAY_40,
  },
  "danger-CallControlIconContainer": {
    backgroundColor: Palette.RED_100,
  },
  "danger-pressedCallControlIconContainer": {
    backgroundColor: Palette.RED_80,
  },
});

export const stylingProps = {
  callControlPressable: {
    hitSlop: 8,
  },
  callControlIcon: {
    width: 32,
    height: 32,
  },
  "default-CallControlIcon": {
    fill: Palette.WHITE,
  },
  "active-CallControlIcon": {
    fill: Palette.BLACK,
  },
  "danger-CallControlIcon": {
    fill: Palette.WHITE,
  },
  blurView: {
    intensity: 10,
  },
};

export const stylingHelpers = {
  getModalContainerStyle(insets) {
    return [
      styles.modalContainer,
      insets.bottom && { paddingBottom: 16 + insets.bottom },
    ];
  },
};
