import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const sizeStyles = StyleSheet.create({
  xs: { fontSize: 13, lineHeight: 20 },
  s: { fontSize: 15, lineHeight: 20 },
  m: { fontSize: 16, lineHeight: 22 },
  l: { fontSize: 18, lineHeight: 24 },
  xl: { fontSize: 20, lineHeight: 28 },
  xxl: { fontSize: 24, lineHeight: 32 },
});

export const spacingStyles = StyleSheet.create({
  none: { marginBottom: 0 },
  s: { marginBottom: 4 },
  m: { marginBottom: 8 },
  l: { marginBottom: 16 },
});

export const colorStyles = StyleSheet.create({
  primary: { color: Palette.BURGUNDY_100 },
  secondary: { color: Palette.WHITE },
  info: { color: Palette.DARK_BLUE_100 },
  negative: { color: Palette.RED_100 },
  warning: { color: Palette.YELLOW_100 },
  positive: { color: Palette.GREEN_100 },
  disabled: { color: Palette.BURGUNDY_60 },
  dark: { color: Palette.GRAY_150 },
});

export const alignmentStyles = StyleSheet.create({
  start: { textAlign: "left" },
  center: { textAlign: "center" },
  end: { textAlign: "right" },
});

export const decorationStyles = StyleSheet.create({
  none: { textDecorationLine: "none" },
  underline: { textDecorationLine: "underline" },
  strikethrough: { textDecorationLine: "line-through" },
});

export const styles = StyleSheet.create({
  wrappable: { flex: 1, flexWrap: "wrap" },
});
