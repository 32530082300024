import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import { isEqual } from "lodash/fp";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import PropTypes from "prop-types";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useApolloClient } from "@eyr-mobile/core/DataProvider";

import { GetPreferences, UpdateAccountLocale } from "./Preference.data";

const logger = LoggerFactory.get("domain/Preference");

const initialStateDefaults = {
  preferableLanguage: null,
};

const prefix = "@Eyr:Preference:";

const STORAGE_KEY_PREFERENCE = `${prefix}all`;

export const PreferenceContext = createContext(initialStateDefaults);

export function getStoredPreference() {
  return AsyncStorage.getItem(STORAGE_KEY_PREFERENCE).then(JSON.parse);
}

function cleanStoredPreference() {
  return AsyncStorage.removeItem(STORAGE_KEY_PREFERENCE);
}

export function PreferenceProvider({ initialState, children }) {
  const initialStateWithDefaults = useMemo(
    () => ({
      ...initialStateDefaults,
      ...initialState,
    }),
    [initialState]
  );
  const [preferences, setPreferences] = useState(initialStateWithDefaults);
  const client = useApolloClient();
  const shouldResetDataLayerRef = useRef(false);
  const didMountRef = useRef(false);

  const setPreferableLanguage = useCallback(
    async (nextPreferableLanguage) => {
      if (preferences.preferableLanguage === nextPreferableLanguage) {
        return;
      }
      shouldResetDataLayerRef.current = true;
      await client.mutate({
        mutation: UpdateAccountLocale,
        variables: { locale: nextPreferableLanguage },
      });
      setPreferences((preferences) => ({
        ...preferences,
        preferableLanguage: nextPreferableLanguage,
      }));
    },
    [client, preferences]
  );
  const fetchAndActivatePreferences = useCallback(async () => {
    const { data } = await client.query({
      query: GetPreferences,
      fetchPolicy: "network-only",
    });
    const fetchedPreferences = {
      preferableLanguage: data?.account.locale,
    };
    if (
      fetchedPreferences.preferableLanguage !==
      initialStateWithDefaults.preferableLanguage
    ) {
      shouldResetDataLayerRef.current = true;
    }
    logger("fetchedPreferences", fetchedPreferences);
    if (isEqual(fetchedPreferences, initialStateWithDefaults)) {
      return;
    }
    setPreferences((preferences) => ({
      ...preferences,
      ...fetchedPreferences,
    }));
  }, [client, initialStateWithDefaults]);
  const cleanOnDevicePreferences = useCallback(() => {
    setPreferences(initialStateDefaults);
    cleanStoredPreference();
  }, []);
  useEffect(() => {
    logger("initialPreferences", initialStateWithDefaults);
  }, [initialStateWithDefaults]);
  useEffect(() => {
    if (!didMountRef.current) {
      return;
    }
    logger("nextPreferences", preferences);
    if (shouldResetDataLayerRef.current === true) {
      logger("language change detected, resetting data layer");
      client.resetStore();
      shouldResetDataLayerRef.current = false;
    }
    AsyncStorage.setItem(STORAGE_KEY_PREFERENCE, JSON.stringify(preferences));
  }, [client, preferences]);

  // Keep this to be very last effect
  useEffect(() => {
    didMountRef.current = true;
  }, []);

  const value = useMemo(
    () => ({
      ...preferences,
      setPreferableLanguage,
      fetchAndActivatePreferences,
      cleanOnDevicePreferences,
    }),
    [
      preferences,
      setPreferableLanguage,
      fetchAndActivatePreferences,
      cleanOnDevicePreferences,
    ]
  );

  return (
    <PreferenceContext.Provider value={value}>
      {children}
    </PreferenceContext.Provider>
  );
}

PreferenceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    preferableLanguage: PropTypes.string,
  }),
};

export function usePreference() {
  const preference = useContext(PreferenceContext);
  if (preference === undefined) {
    logger(
      "useCall: Couldn't find a preference object. Is your component inside a PreferenceProvider?",
      preference,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return preference;
}
