import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const DOT_SIZE = 8;

export const styles = StyleSheet.create({
  container: {
    borderRadius: 24,
    height: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-start",
    minWidth: 24,
  },
  dot: {
    width: DOT_SIZE,
    height: DOT_SIZE,
    borderRadius: DOT_SIZE / 2,
  },
  icon: {
    marginRight: 4,
  },
  positive: { backgroundColor: Palette.GREEN_100 },
  secondary: { backgroundColor: Palette.NUDE_60 },
  informal: { backgroundColor: Palette.DARK_BLUE_100 },
  negative: { backgroundColor: Palette.RED_100 },
  primary: { backgroundColor: Palette.PINK_60 },
  disabled: { backgroundColor: Palette.OVERLAY_DARK_8 },
  padding: { paddingHorizontal: 12 },
});

export const stylingProps = {
  iconSize: {
    width: 12,
    height: 12,
  },
  secondary: { fill: Palette.BURGUNDY_100 },
  primary: { fill: Palette.BURGUNDY_100 },
  informal: { fill: Palette.WHITE },
  positive: { fill: Palette.WHITE },
  negative: { fill: Palette.WHITE },
  disabled: { fill: Palette.GRAY_100 },
};

export const stylingHelpers = {
  getContainerStyle: ({ variant, numChars }) => {
    if (numChars === 1) {
      return styles[variant];
    } else {
      return [styles[variant], styles.padding];
    }
  },
};
