import { Socket } from "phoenix";
import { Platform } from "react-native";

import { BackgroundTimer } from "../BackgroundTimers";
const setTimeout = Platform.select({
  android: BackgroundTimer.setTimeout.bind(BackgroundTimer),
  default: global.setTimeout,
});

export class CustomPhoenixSocket extends Socket {
  waitForBufferDone(callback, tries = 1) {
    if (tries === 5 || !this.conn || !this.conn.bufferedAmount) {
      callback();
      return;
    }
    setTimeout(() => {
      this.waitForBufferDone(callback, tries + 1);
    }, 150 * tries);
  }
  waitForSocketClosed(callback, tries = 1) {
    if (tries === 5 || !this.conn || this.conn.readyState === 3) {
      callback();
      return;
    }
    setTimeout(() => {
      this.waitForSocketClosed(callback, tries + 1);
    }, 150 * tries);
  }
}
