const KEY = "GENERIC_PASSWORD";
export async function getGenericPassword() {
  const data = localStorage.getItem(KEY);
  return data ? JSON.parse(data) : false;
}

export const safelyGetGenericPassword = getGenericPassword;

export async function setGenericPassword(username, password) {
  return localStorage.setItem(KEY, JSON.stringify({ username, password }));
}

export async function resetGenericPassword() {
  return localStorage.removeItem(KEY);
}
