import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  heroContainer: {
    padding: 24,
    borderRadius: 8,
    backgroundColor: Palette.NUDE_20,
    marginBottom: 16,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 8,
  },
  listItemNumber: {
    marginRight: 16,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    width: 20,
    height: 20,
    backgroundColor: Palette.BURGUNDY_100,
  },
  listItemNumberText: {
    color: Palette.WHITE,
  },
  finePrintContainer: {
    paddingHorizontal: 8,
  },
  instructionsText: {
    marginBottom: 16,
  },
});
