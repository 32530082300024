import React, { useCallback } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { View, FlatList, Pressable } from "react-native";
import { reject, get, noop, getOr } from "lodash/fp";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { useNavigation } from "@react-navigation/native";
import { useAlert } from "@eyr-mobile/core/Alert";
import { useMutation } from "@apollo/client";
import {
  DeleteAccountPaymentMethod,
  GetAccountPaymentMethods,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  ListItemSeparator,
  PaymentMethodListItem,
  AddPaymentMethod,
  Subtitle,
  Paragraph,
} from "../../components";
import { SVGs } from "../../res";

import { styles, stylingProps } from "./AccountPaymentMethodsScreen.styles";
import { messages } from "./AccountPaymentMethodsScreen.messages";

export function AccountPaymentMethodsScreen() {
  const alert = useAlert();
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  // TODO: add disabledResolutionUrl check <mdrakus>
  const { handlers, data, refetch, refreshing } = withHandlers(
    useQuery(GetAccountPaymentMethods, {
      fetchPolicy: "network-only",
    })
  );

  const [deleteAccountPaymentMethod] = useMutation(DeleteAccountPaymentMethod);

  const handleDeletePaymentMethod = useCallback(
    (id) => {
      alert({
        title: formatMessage(messages.deletionAlertTitle),
        message: formatMessage(messages.deletionAlertMessage),
        buttons: [
          {
            title: formatMessage(messages.deletionAlertButtonCancel),
            variant: "secondary",
            onPress: noop,
          },
          {
            title: formatMessage(messages.deletionAlertButtonOk),
            variant: "negative",
            onPress: () =>
              deleteAccountPaymentMethod({
                variables: { input: { id } },
                update: (client) => {
                  const oldData = client.readQuery({
                    query: GetAccountPaymentMethods,
                    variables: {},
                  });
                  const data = {
                    accountPaymentMethods: reject(
                      { id },
                      get("accountPaymentMethods", oldData)
                    ),
                  };
                  client.writeQuery({
                    query: GetAccountPaymentMethods,
                    variables: {},
                    data,
                  });
                },
              }),
          },
        ],
      });
    },
    [alert, deleteAccountPaymentMethod, formatMessage]
  );

  const handleExtractKey = useCallback((item) => item.id.toString(), []);
  const handleRenderItem = useCallback(
    ({ item: { id, name, ...rest } }) => (
      <PaymentMethodListItem
        {...rest}
        name={name}
        pressable={false}
        showInteractivityIcon
        /* eslint-disable-next-line react/no-unstable-nested-components */
        InteractivityIconComponent={(props) => (
          <Pressable
            accessibilityLabel={`Delete ${name}`}
            onPress={() => handleDeletePaymentMethod(id)}
            hitSlop={{ top: 8, bottom: 8, right: 8 }}
          >
            <SVGs.CloseMono {...props} {...stylingProps.deleteIcon} />
          </Pressable>
        )}
      />
    ),
    [handleDeletePaymentMethod]
  );

  const handleRenderListHeader = useCallback(
    () => (
      <Subtitle size="l" spacing="l">
        {formatMessage(messages.accountPaymentMethodsTitle)}
      </Subtitle>
    ),
    [formatMessage]
  );

  const handleRenderListFooter = useCallback(
    () => (
      <View View style={styles.listFooterContainer}>
        <AddPaymentMethod
          onAddInsuranceOrDiscountSelected={() =>
            navigate("EditAccountInsuranceOrMembershipScreen")
          }
          onAddCardSelected={() => navigate("EditAccountBankCardScreen")}
        />
      </View>
    ),
    [navigate]
  );

  const handleRenderListEmpty = useCallback(
    () => (
      <View style={styles.emptyListContainer}>
        <Paragraph size="m">
          {formatMessage(messages.emptyPaymentMethodsListTitle)}
        </Paragraph>
      </View>
    ),
    [formatMessage]
  );

  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <FlatList
          data={getOr([], "accountPaymentMethods", data)}
          contentContainerStyle={screenSizeSelect({
            xs: styles.contentContainerXS,
            s: styles.contentContainerS,
            m: styles.contentContainerM,
          })}
          refreshing={refreshing}
          onRefresh={refetch}
          ListEmptyComponent={handleRenderListEmpty}
          ItemSeparatorComponent={ListItemSeparator}
          keyExtractor={handleExtractKey}
          renderItem={handleRenderItem}
          ListHeaderComponent={handleRenderListHeader}
          ListFooterComponent={handleRenderListFooter}
        />
      )}
    </SafeAreaView>
  );
}

AccountPaymentMethodsScreen.routeName = "AccountPaymentMethodsScreen";
AccountPaymentMethodsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
