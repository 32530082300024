import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash/fp";
import { useIntl } from "@eyr-mobile/core/Intl";

import { ServiceCard } from "../ServiceCard";

import { messages } from "./DropInConsultationServiceCard.messages";

export function DropInConsultationServiceCard({
  summary,
  state,
  order,
  queuePosition,
  queueWaitingTimeMin,
  onPreparationNoticePress = noop,
  onDismissPress = noop,
  onCancellationInfoPress = noop,
}) {
  const { formatMessage, formatDate, formatTime, formatNumber } = useIntl();

  const textColor = state === "refunded" ? "dark" : "primary";

  switch (state) {
    case "booked":
      return (
        <ServiceCard
          textColor={textColor}
          summary={summary}
          informationLayout="row"
          informationList={[
            {
              label: formatMessage(messages.queuePositionText),
              value: queuePosition < 10 ? "0" + queuePosition : queuePosition,
            },
            {
              label: formatMessage(messages.queueWaitingTime),
              value: formatMessage(messages.queueWaitingTimeNumber, {
                amount: formatNumber(queueWaitingTimeMin, {
                  style: "unit",
                  unit: "minute",
                  unitDisplay: "short",
                }),
              }),
            },
          ]}
          footerList={[
            {
              onPress: onPreparationNoticePress,
              message: formatMessage(messages.preparationNoticeActionTitle),
            },
            {
              onPress: onCancellationInfoPress,
              message: formatMessage(messages.cancellationNoticeActionTitle),
            },
          ]}
        />
      );
    case "refunded":
      return (
        <ServiceCard
          isGrayscale
          textColor={textColor}
          summary={summary}
          alertTitle={formatMessage(messages.refundedHeader)}
          informationTitle={formatMessage(messages.refundedOrderDateTitle)}
          informationDescription={formatMessage(messages.refundedOrderDate, {
            date: formatDate(order.insertedAt),
            time: formatTime(order.insertedAt),
          })}
          onDismissPress={onDismissPress}
          showDismiss={true}
        />
      );
    default:
      return null;
  }
}

DropInConsultationServiceCard.propTypes = {
  summary: PropTypes.string.isRequired,
  order: PropTypes.shape({
    insertedAt: PropTypes.string.isRequired,
  }).isRequired,
  state: PropTypes.oneOf(["booked", "refunded"]).isRequired,
  queuePosition: PropTypes.number,
  queueWaitingTimeMin: PropTypes.number,
  onPreparationNoticePress: PropTypes.func,
  onDismissPress: PropTypes.func,
  onCancellationInfoPress: PropTypes.func,
};
