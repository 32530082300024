import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSetMedicalCategoryScreen.navigationTitle",
    description: "FlowStageSetMedicalCategoryScreen navigation title",
    defaultMessage: "Main concern",
  },
  emergencyWarningMessage: {
    id: "FlowStageSetMedicalCategoryScreen.emergencyWarningMessage",
    description: "Emergency warning in category list",
    defaultMessage: "In case of emergency, call {emergencyNumber} for help",
  },
});
