export const responsiveStyles = {
  containerXS: {
    paddingTop: 16,
  },
  containerS: {
    paddingTop: 32,
  },
  containerM: {
    paddingTop: 48,
  },
  contentContainerXS: { paddingHorizontal: 16 },
  contentContainerS: { width: 480, alignSelf: "center" },
  contentContainerM: { width: 540, alignSelf: "center" },
};
