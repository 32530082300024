import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useApolloClient, useMutation } from "@apollo/client";
import { noop, size, tail, delay } from "lodash/fp";
import { LoggerFactory, LOGGER_LEVEL_ERROR } from "@eyr-mobile/core/Logger";
import { navigate } from "@eyr-mobile/core/Navigation";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { usePrevious } from "@eyr-mobile/core/Lib";

import {
  GetConsentsToAccept,
  AcceptConsent,
  RejectConsent,
} from "./Legal.data";

const logger = LoggerFactory.get("domain/Legal");
const nextTick = delay(0);

const legalStateDefaults = {
  consentsToAccept: [],
  onSucceeded: noop,
  onFailed: noop,
};

export const ConsentCategory = {
  ACCOUNT: "Account",
  ORG_ACCOUNT: "OrgAccount",
  MARKETING: "Marketing",
  MEDICAL_SERVICE: "MedicalService",
};

const LegalContext = createContext();

export const LegalProvider = (props) => {
  const { children, initialState } = props;
  const { logout, accessToken, requiresLegalChecks, completeLegalChecks } =
    useAuth();
  const initialStateWithDefaults = useMemo(
    () => ({
      ...legalStateDefaults,
      ...initialState,
    }),
    [initialState]
  );
  const [{ consentsToAccept, onSucceeded, onFailed }, setLegal] = useState(
    initialStateWithDefaults
  );

  const consentsAmount = size(consentsToAccept);
  const previousConsentsAmount = usePrevious(consentsAmount);
  const reduceRemaining = () =>
    setLegal((legal) => ({
      ...legal,
      consentsToAccept: tail(legal.consentsToAccept),
    }));

  const resetState = useCallback(() => {
    return setLegal(initialStateWithDefaults);
  }, [initialStateWithDefaults]);

  const onAccepted = useCallback(() => {
    if (consentsAmount === 1) {
      nextTick(onSucceeded);
      resetState();
    } else {
      reduceRemaining();
    }
  }, [consentsAmount, onSucceeded, resetState]);
  const onRejected = useCallback(
    ({ rejectConsent: { isRequired, category } }) => {
      if (isRequired && category !== ConsentCategory.MEDICAL_SERVICE) {
        nextTick(onFailed);
        resetState();
      } else if (consentsAmount === 1) {
        nextTick(onSucceeded);
        resetState();
      } else {
        reduceRemaining();
      }
      if (
        [ConsentCategory.ACCOUNT, ConsentCategory.ORG_ACCOUNT].includes(
          category
        )
      ) {
        logout();
      }
    },
    [consentsAmount, logout, onFailed, onSucceeded, resetState]
  );

  const client = useApolloClient();
  const [acceptConsentMutation] = useMutation(AcceptConsent);
  const [rejectConsentMutation] = useMutation(RejectConsent);
  const acceptConsent = useCallback(
    (consent) =>
      acceptConsentMutation({
        variables: { id: consent.id },
        onCompleted: onAccepted,
      }),
    [acceptConsentMutation, onAccepted]
  );
  const rejectConsent = useCallback(
    (consent) =>
      rejectConsentMutation({
        variables: { id: consent.id },
        onCompleted: onRejected,
      }),
    [onRejected, rejectConsentMutation]
  );

  useEffect(() => {
    if (consentsAmount === 0) {
      nextTick(onSucceeded);
      resetState();
    }
  }, [consentsAmount, onSucceeded, resetState]);

  const requireConsents = useCallback(
    async (variables, { onSucceeded = noop, onFailed = noop }) => {
      try {
        const {
          data: { consentsToAccept },
        } = await client.query({
          variables,
          query: GetConsentsToAccept,
          fetchPolicy: "network-only",
        });
        if (size(consentsToAccept) === 0) {
          onSucceeded();
        } else {
          setLegal({
            ...initialStateWithDefaults,
            consentsToAccept,
            onSucceeded,
            onFailed,
          });
        }
      } catch (e) {
        logger("requireConsents", e, LOGGER_LEVEL_ERROR);
      }
    },
    [client, initialStateWithDefaults]
  );

  useEffect(() => {
    if (requiresLegalChecks && accessToken) {
      requireConsents(
        {
          module: "dashboard",
        },
        {
          onSucceeded: completeLegalChecks,
        }
      );
    }
  }, [accessToken, completeLegalChecks, requiresLegalChecks, requireConsents]);

  useEffect(() => {
    if (previousConsentsAmount === 0 && consentsAmount > 0) {
      navigate("TermsAndConditionsScreen");
    }
  }, [consentsAmount, previousConsentsAmount]);

  return (
    <LegalContext.Provider
      value={{
        requireConsents,
        consentsToAccept,
        acceptConsent,
        rejectConsent,
      }}
    >
      {children}
    </LegalContext.Provider>
  );
};

LegalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
};

export const useLegal = () => {
  const legal = useContext(LegalContext);
  if (legal === undefined) {
    logger(
      "useLegal: Couldn't find a legal object. Is your component inside an LegalProvider?",
      legal,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return legal;
};
