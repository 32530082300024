import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.navigationTitle",
    description:
      "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen navigation title",
    defaultMessage: "When",
  },
  dayAndTime: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.dayAndTime",
    description:
      "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen day and time",
    defaultMessage: "{date} at {time}",
  },
  confirmationActionTitle: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.confirmationActionTitle",
    description:
      "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen confirmation action title",
    defaultMessage: "Confirm",
  },
  emergencyWarningConfirmationActionTitle: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.emergencyWarningConfirmationActionTitle",
    description: "Emergency warning confirmation action title",
    defaultMessage: "Continue",
  },
  emergencyWarningCancelActionTitle: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.emergencyWarningCancelActionTitle",
    description: "Emergency warning cancel action title",
    defaultMessage: "Cancel",
  },
  emergencyWarningMessageNO: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.emergencyWarningMessageNO",
    description: "Emergency warning message NO",
    defaultMessage:
      "Digital therapy is not suitable for dealing with acute suicidal thoughts. If you have thoughts about suicide and it is urgent, contact your GP or call 116 117 for the norwegian public emergency ward. If it is acute and your life is at stake, call {emergencyNumber}.",
  },
  emergencyWarningMessageDK: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.emergencyWarningMessageDK",
    description: "Emergency warning message DK",
    defaultMessage:
      "Digital therapy is not suitable for dealing with acute suicidal thoughts. If you have thoughts about suicide and it is urgent, contact your GP or call the public emergency ward. If it is acute and your life is at stake, call {emergencyNumber}.",
  },
  emergencyWarningMessageSE: {
    id: "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.emergencyWarningMessageSE",
    description: "Emergency warning message SE",
    defaultMessage:
      "Digital therapy is not suitable for dealing with acute suicidal thoughts. If you have thoughts about suicide and it is urgent, contact your closest healthcare center or call or the public Swedish healthcare line 1117. If it is acute and your life is at stake, call {emergencyNumber}.",
  },
});
