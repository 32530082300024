import React, { useCallback } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { FlatList, View } from "react-native";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { GetAccountConsultationHistory } from "@eyr-mobile/domain/Account";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useDevice } from "@eyr-mobile/core/Device";

import { ListItem, ListItemSeparator, Paragraph } from "../../components";

import { styles } from "./AccountConsultationsHistoryScreen.styles";
import { messages } from "./AccountConsultationsHistoryScreen.messages";

export function AccountConsultationsHistoryScreen() {
  const { formatDate } = useIntl();
  const { handlers, data: { accountConsultations = [] } = {} } = withHandlers(
    useQuery(GetAccountConsultationHistory)
  );

  const renderItem = useCallback(
    ({ item: { date, title } }) => (
      <ListItem layout="1">
        <View style={styles.listItem}>
          <Paragraph size="l">{title}</Paragraph>
          <Paragraph size="l">{formatDate(date)}</Paragraph>
        </View>
      </ListItem>
    ),
    [formatDate]
  );

  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <FlatList
          contentContainerStyle={screenSizeSelect({
            xs: styles.contentContainerXS,
            s: styles.contentContainerS,
            m: styles.contentContainerM,
          })}
          data={accountConsultations}
          keyExtractor={({ id }) => `${id}`}
          renderItem={renderItem}
          ItemSeparatorComponent={ListItemSeparator}
        />
      )}
    </SafeAreaView>
  );
}

AccountConsultationsHistoryScreen.routeName =
  "AccountConsultationsHistoryScreen";
AccountConsultationsHistoryScreen.navigationOptions = {
  title: messages.navigationTitle,
};
