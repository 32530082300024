import { getLocale } from "../Intl";
import { Constants, StaticConfig } from "../Config";

export const getAdditionalHeaders = () => ({
  "x-client-version": Constants.expoConfig?.version,
  "Accept-Language": getLocale().languageTag,
});
const isLocal = ![
  "api.eyr.md",
  "api.staging.eyr.md",
  "api.dev.eyr.md",
].includes(StaticConfig.API_URI);

const getProtocol = () => `http${isLocal ? "" : "s"}`;

export const getAPI_URL = ({ version = "v1" } = {}) =>
  new URL(
    `${getProtocol()}://${StaticConfig.API_URI}${version ? `/${version}` : ""}`
  );
export const getSocketURL = () =>
  new URL(`ws${isLocal ? "" : "s"}://${StaticConfig.API_URI}/socket`);
export const getWebViewURL = () =>
  new URL(`${getProtocol()}://${StaticConfig.API_URI}/webview`);
export const getGraphqlURL = (isPublic) =>
  new URL(
    `${getProtocol()}://${StaticConfig.API_URI}/${
      isPublic ? "graphql_public" : "graphql"
    }`
  );
export const getWhiteLabelAssetURL = (fileName) =>
  `${getProtocol()}://${StaticConfig.API_URI}/images/whitelabel/${fileName}`;
