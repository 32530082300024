import { defineMessages } from "react-intl";

export const messages = defineMessages({
  confirmOrder: {
    id: "FlowStageSetPaymentMethodsScreen.confirmOrder",
    description: "Confirm order",
    defaultMessage: "Confirm order",
  },
  continueActionTitle: {
    id: "FlowStageSetPaymentMethodsScreen.continueActionTitle",
    description: "action title for continuing to next step",
    defaultMessage: "Continue",
  },
  choosePaymentMethod: {
    id: "FlowStageSetPaymentMethodsScreen.choosePaymentMethod",
    description: "Choose payment method",
    defaultMessage: "Choose payment method",
  },
  initialNavigationTitle: {
    id: "FlowStageSetPaymentMethodsScreen.initialNavigationTitle",
    description: "FlowStageSetPaymentMethodsScreen initial navigation title",
    defaultMessage: "Select payment method",
  },
  completedNavigationTitle: {
    id: "FlowStageSetPaymentMethodsScreen.completedNavigationTitle",
    description: "FlowStageSetPaymentMethodsScreen completed navigation title",
    defaultMessage: "Confirm order",
  },
  permissionsAlertTitle: {
    id: "FlowStageSetPaymentMethodsScreen.permissionsAlertTitle",
    description: "Permissions alert title",
    defaultMessage: "Give Eyr permissions",
  },
  permissionsAlertGotItButton: {
    id: "FlowStageSetPaymentMethodsScreen.permissionsAlertGotItButton",
    description: "Permissions alert got it button title",
    defaultMessage: "Got it",
  },
  permissionsAlertCancelButton: {
    id: "FlowStageSetPaymentMethodsScreen.permissionsAlertCancelButton",
    description: "Permissions alert cancel button title",
    defaultMessage: "Cancel",
  },
  permissionsAlertOpenSettingsButton: {
    id: "FlowStageSetPaymentMethodsScreen.permissionsAlertOpenSettingsButton",
    description: "Open settings button title",
    defaultMessage: "Open Settings",
  },
  unableToOpenSettingsAlertMessage: {
    id: "FlowStageSetPaymentMethodsScreen.unableToOpenSettingsAlertMessage",
    description: "Unable to open settings alert message",
    defaultMessage: "Go to Settings → Eyr → give Eyr access to {permissions}.",
  },
  openSettingsRequestAlertMessage: {
    id: "FlowStageSetPaymentMethodsScreen.openSettingsRequestAlertMessage",
    description: "Open settings request alert message",
    defaultMessage: "Open settings to give Eyr access to {permissions}.",
  },
  cameraPermissionName: {
    id: "FlowStageSetPaymentMethodsScreen.cameraPermissionName",
    description: "Camera permission name",
    defaultMessage: "camera",
  },
  microphonePermissionName: {
    id: "FlowStageSetPaymentMethodsScreen.microphonePermissionName",
    description: "Microphone permission name",
    defaultMessage: "microphone",
  },
  notificationPermissionName: {
    id: "FlowStageSetPaymentMethodsScreen.notificationPermissionName",
    description: "Notification permission name",
    defaultMessage: "notifications",
  },
  // permissionRequestRationaleHint: {
  //   id: "FlowStageSetPaymentMethodsScreen.permissionRequestRationaleHint",
  //   description: "Permission request rationale hint",
  //   defaultMessage: "Please enable it in Settings.",
  // },
  phoneNumberAlertMessage: {
    id: "FlowStageSetPaymentMethodsScreen.phoneNumberAlertMessage",
    description: "title for alert for adding phone number",
    defaultMessage:
      "Add your phone number so that we can notify you about your appointment.",
  },
  phoneNumberAlertSaveActionTitle: {
    id: "FlowStageSetPaymentMethodsScreen.phoneNumberAlertSaveActionTitle",
    description: "save action title for alert for adding phone number",
    defaultMessage: "Save and confirm order",
  },
  phoneNumberFieldLabel: {
    id: "FlowStageSetPaymentMethodsScreen.phoneNumberFieldLabel",
    description: "Telephone number field label",
    defaultMessage: "Telephone number",
  },
  phoneNumberFieldInvalidError: {
    id: "FlowStageSetPaymentMethodsScreen.phoneNumberFieldInvalidError",
    description: "Telephone number field invalid state error message",
    defaultMessage: "Please enter a valid phone number",
  },
  cancelledError: {
    id: "FlowStageSetPaymentMethodsScreen.cancelledError",
    description: "Customer cancelled the checkout payment",
    defaultMessage: "Payment cancelled. Please try again.",
  },
  authorizationError: {
    id: "FlowStageSetPaymentMethodsScreen.authorizationError",
    description: "Customer failed to authorize the payment",
    defaultMessage: "Payment authorization failed. Please try again.",
  },
  failedError: {
    id: "FlowStageSetPaymentMethodsScreen.failedError",
    description:
      "The transaction has been rejected by us, or an error has occurred during transaction processing",
    defaultMessage: "Transaction failed. Please please try again.",
  },
  captureError: {
    id: "FlowStageSetPaymentMethodsScreen.captureError",
    description: "The transaction capture operation failed during auto-capture",
    defaultMessage: "Payment failed. Please please try again.",
  },
});
