import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";

import { styles } from "./ProgressIndicator.styles";

export function ProgressIndicator({ value, ...rest }) {
  let progress = value;
  // covers negative values
  if (value < 0) {
    progress = 0;
  }
  // covers nested view overflow case breaking borders
  if (value > 1) {
    progress = 1;
  }
  return (
    <View style={styles.container} {...rest}>
      <View
        style={[
          styles.filler,
          {
            width: `${progress * 100}%`,
          },
        ]}
      />
    </View>
  );
}

ProgressIndicator.propTypes = {
  value: PropTypes.number.isRequired,
};
