import { defineMessages } from "react-intl";

export const messages = defineMessages({
  bringYourChildSectionTitle: {
    id: "ConsultationPreparationInfo.bringYourChildSectionTitle",
    description: "Consultation preparation child section title",
    defaultMessage: "Bring your child",
  },
  bringYourChildSectionDescription: {
    id: "ConsultationPreparationInfo.bringYourChildSectionDescription",
    description: "Consultation preparation child section description",
    defaultMessage:
      "In order for us to give the right medical advice, your child must be present during the consultation.",
  },
  show4GSectionTitle: {
    id: "ConsultationPreparationInfo.show4GSectionTitle",
    description: "Consultation preparation 4G section title",
    defaultMessage: "Strong internet connection",
  },
  show4GSectionDescription: {
    id: "ConsultationPreparationInfo.show4GSectionDescription",
    description: "Consultation preparation 4G section description",
    defaultMessage:
      "If possible, connect to 4G/5G. Based on our experience 4G/5G is more stable than wifi.",
  },
  addPhoneNumberSectionTitle: {
    id: "ConsultationPreparationInfo.addPhoneNumberSectionTitle",
    description: "Consultation preparation add phone number section title",
    defaultMessage: "Add your phone number",
  },
  addPhoneNumberSectionDescription: {
    id: "ConsultationPreparationInfo.addPhoneNumberSectionDescription",
    description:
      "Consultation preparation add phone number section description",
    defaultMessage:
      "The doctor or psychologist may need to call on your phone in case of technical issues.",
  },
  addPhoneNumberButtonActionTitle: {
    id: "ConsultationPreparationInfo.addPhoneNumberButtonActionTitle",
    description:
      "Consultation preparation add phone number button action title",
    defaultMessage: "Add your phone number",
  },
});
