import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "OnboardingAddAccountContactInfoScreen.navigationTitle",
    description: "OnboardingAddAccountContactInfoScreen navigation title",
    defaultMessage: "Account details",
  },
  primaryActionTitle: {
    id: "OnboardingAddAccountContactInfoScreen.primaryActionTitle",
    description: "OnboardingAddAccountContactInfoScreen primary action title",
    defaultMessage: "Continue",
  },
  secondaryActionTitle: {
    id: "OnboardingAddAccountContactInfoScreen.secondaryActionTitle",
    description: "OnboardingAddAccountContactInfoScreen secondary action title",
    defaultMessage: "Add later",
  },
});
