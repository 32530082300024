import EmailValidator from "email-validator";
import {
  eq,
  flow,
  gte,
  isFunction,
  lte,
  noop,
  size,
  toString,
} from "lodash/fp";
import moment from "moment";
import Personnummer from "personnummer";

import {
  VALIDATION_ERROR_BLANK,
  VALIDATION_ERROR_DO_NOT_MATCH,
  VALIDATION_ERROR_EXACT_LENGTH,
  VALIDATION_ERROR_INVALID_EMAIL,
  VALIDATION_ERROR_MAX_LENGTH,
  VALIDATION_ERROR_MIN_LENGTH,
  VALIDATION_ERROR_NOT_INTEGER,
  VALIDATION_ERROR_NOT_NUMERIC,
} from "./Validation.error";

export const VALIDATION_ERROR = "VALIDATION_ERROR";
const Error = (message, params) =>
  params ? [VALIDATION_ERROR, message, params] : [VALIDATION_ERROR, message];
export const VALIDATION_SUCCESS = "VALIDATION_SUCCESS";
const Success = (subject) => [VALIDATION_SUCCESS, subject];

export const hasValidationFailed = (result) => result[0] === VALIDATION_ERROR;
export const hasValidationSucceeded = (result) =>
  result[0] === VALIDATION_SUCCESS;
export const getValidationMessage = (result) => result[1];
export const getValidationParams = (result) => result[2];

export const createValidatorWithParams =
  (configureValidator, error) => (params, overrideError) => (subject) =>
    configureValidator(params, subject)
      ? Success(subject)
      : Error(overrideError || error, params);

export const createValidator = (validationFunc, error) => (subject) =>
  validationFunc(subject) ? Success(subject) : Error(error);

const createLengthValidator =
  (comparator, defaultErrorMessage) => (length, error) => (subject) =>
    flow([toString, size, comparator(length)])(subject)
      ? Success(subject)
      : Error(error || defaultErrorMessage, { length });

export const configureMinLengthValidator = createLengthValidator(
  lte,
  VALIDATION_ERROR_MIN_LENGTH
);
export const configureMaxLengthValidator = createLengthValidator(
  gte,
  VALIDATION_ERROR_MAX_LENGTH
);
export const configureExactLengthValidator = createLengthValidator(
  eq,
  VALIDATION_ERROR_EXACT_LENGTH
);

export const notBlankValidator = createValidator(
  (subject) => !!subject,
  VALIDATION_ERROR_BLANK
);

export const configureIsRequiredValidator = (error) =>
  createValidator((subject) => !!subject, error);

export const isIntegerValidator = createValidator(
  Number.isInteger,
  VALIDATION_ERROR_NOT_INTEGER
);

export const matchValidator = createValidator(
  ([a, b]) => a === b,
  VALIDATION_ERROR_DO_NOT_MATCH
);

export const isNumericValidator = createValidator(
  (subject) => /^\d+$/.test(subject),
  VALIDATION_ERROR_NOT_NUMERIC
);

export const validateUntilFirstError = (validators) => (subject) => {
  const firstError = validators
    .map((validator) => validator(subject))
    .find(hasValidationFailed);
  return firstError ? firstError : Success(subject);
};

export const validateIfNotBlank = (validator) => (subject) =>
  hasValidationSucceeded(notBlankValidator(subject))
    ? validator(subject)
    : Success();

export const a = (validator, subject, onError) => {
  const result = validator(subject);
  hasValidationFailed(result) ? onError(getValidationMessage(result)) : noop();
};

export const createModelValidator = (validators) => (model) => {
  const result = Object.keys(model).reduce((accumulator, modelProperty) => {
    const validator = validators[modelProperty];
    if (!validator) {
      return accumulator;
    }
    const result = validator(model[modelProperty]);
    if (hasValidationSucceeded(result)) {
      return accumulator;
    }
    accumulator.push([modelProperty, getValidationMessage(result)]);
    return accumulator;
  }, []);
  return result.length ? Error(result) : Success();
};

export const handleValidation =
  (validator, { onFailed, onSucceeded }) =>
  (subject) => {
    const result = validator(subject);
    if (!result) {
      return;
    }
    const validationMessage = getValidationMessage(result);
    const validationParams = getValidationParams(result);
    if (hasValidationFailed(result) && isFunction(onFailed)) {
      if (validationParams) {
        onFailed(validationMessage, validationParams);
      } else {
        onFailed(validationMessage);
      }
    }
    if (hasValidationSucceeded(result) && isFunction(onSucceeded)) {
      onSucceeded(validationMessage);
    }
    return result;
  };

export const configurePhoneNumberValidator = (length) =>
  validateUntilFirstError([
    notBlankValidator,
    isNumericValidator,
    configureExactLengthValidator(length),
  ]);

export const emailValidator = createValidator(
  EmailValidator.validate,
  VALIDATION_ERROR_INVALID_EMAIL
);
const algs = {
  nossn(birthNumber) {
    birthNumber = birthNumber.toString();

    if (!birthNumber || birthNumber.length !== 11) {
      return false;
    }

    const _sum = function (birthNumber, factors) {
      let sum = 0;
      for (let i = 0, l = factors.length; i < l; ++i) {
        sum += parseInt(birthNumber.charAt(i), 10) * factors[i];
      }
      return sum;
    };

    let checksum1 = 11 - (_sum(birthNumber, [3, 7, 6, 1, 8, 9, 4, 5, 2]) % 11);

    if (checksum1 === 11) {
      checksum1 = 0;
    }

    let checksum2 =
      11 - (_sum(birthNumber, [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]) % 11);
    if (checksum2 === 11) {
      checksum2 = 0;
    }
    return (
      checksum1 === parseInt(birthNumber.charAt(9), 10) &&
      checksum2 === parseInt(birthNumber.charAt(10), 10)
    );
  },
  dkssn(input) {
    if (!input) {
      return false;
    }

    if (input.indexOf("-") === -1) {
      if (input.length === 10) {
        input = input.slice(0, 6) + "-" + input.slice(6);
      } else {
        input = input.slice(0, 8) + "-" + input.slice(8);
      }
    }
    if (
      !input.match(
        // eslint-disable-next-line no-useless-escape
        /^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/
      )
    ) {
      return false;
    }

    // Clean input
    input = input.replace("-", "");
    if (input.length === 12) {
      input = input.substring(2);
    }

    // Declare variables
    return moment(input.substring(0, 6), "DDMMYY").isValid();
  },
  svssn: Personnummer.valid,
};
const validateSSN = (country, ssn) => {
  if (ssn !== undefined && typeof ssn !== "string") {
    ssn = ssn.toString();
  }

  switch (country) {
    case "DK":
      return algs.dkssn(ssn);
    case "NO":
      return algs.nossn(ssn);
    case "SV":
      return algs.svssn(ssn);
    default:
      return true;
  }
};
const configureSSNValidator = createValidatorWithParams(validateSSN, "invalid");
export { configureSSNValidator };
