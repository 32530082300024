import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 16,
  },
  countriesListItemContainer: { marginBottom: 8 },
  countryFlag: { width: 24, height: 24 },
});
