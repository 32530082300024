import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  sectionContainer: {
    paddingBottom: 32,
  },
  pharmaciesSectionContainer: {
    alignItems: "center",
  },
  bannerContainerXS: {
    paddingVertical: 24,
    paddingHorizontal: 24,
    alignItems: "flex-start",
    backgroundColor: Palette.BURGUNDY_100,
    borderRadius: 8,
  },
  bannerContainerS: {
    paddingVertical: 24,
    paddingHorizontal: 24,
    alignItems: "center",
    backgroundColor: Palette.BURGUNDY_100,
    borderRadius: 8,
    flexDirection: "row",
  },
  bannerContentContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  buttonContainerXS: {
    marginTop: 16,
  },
  buttonContainerS: {
    marginLeft: 16,
  },
  modalContentTopSpace: {
    marginBottom: 24,
  },
  introContainer: { paddingHorizontal: 4 },
  USPContainer: {
    backgroundColor: Palette.PINK_20,
    borderRadius: 8,
    padding: 20,
    marginBottom: 16,
  },
  conditionsParagraphContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 4,
  },
  conditionsContainer: {
    paddingHorizontal: 4,
  },
  listItemIconContainer: {
    paddingRight: 12,
    paddingVertical: 2,
  },
  conditionsParagraphPriceContainer: {
    marginLeft: 16,
  },
});
