import React, { useCallback } from "react";
import { View } from "react-native";
import { useIntl } from "react-intl";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import {
  GetPaymentMethodsForOrder,
  useOrderFlowStage,
  useSetPaymentMethodsFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  ProgressIndicator,
  SelectPaymentMethod,
  CancelOrderHeaderRight,
  AddPaymentMethod,
  Subtitle,
  Paragraph,
} from "../../components";

import { messages } from "./FlowStageSetPaymentMethods_PrimaryScreen.messages";
import { styles } from "./FlowStageSetPaymentMethods_PrimaryScreen.styles";

export function FlowStageSetPaymentMethods_PrimaryScreen() {
  const { formatMessage } = useIntl();

  const [
    {
      addCard,
      addInsuranceOrMembership,
      maybeSelectInsuranceOrMembership,
      selectPaymentProvider,
      selectCard,
    },
  ] = useSetPaymentMethodsFlowStage();

  const {
    data = {},
    refreshing,
    refetch,
    handlers,
    stageParams: { progress, progressPerStage, product: { price } = {} } = {},
  } = withHandlers(
    useOrderFlowStage(GetPaymentMethodsForOrder, ({ orderId }) => ({
      variables: { orderId },
    }))
  );
  const progressWithinSubStages = progress + (1 / 3) * progressPerStage;
  const {
    accountPaymentMethodsForOrder: accountPaymentMethods,
    paymentProvidersForOrder: paymentProviders,
  } = data;
  const { screenSizeSelect } = useDevice();

  const handleRenderListHeader = useCallback(
    () => (
      <Subtitle size="l" spacing="l">
        {formatMessage(messages.availablePaymentMethodsTitle)}
      </Subtitle>
    ),
    [formatMessage]
  );

  const handleRenderListFooter = useCallback(
    () => (
      <View View style={styles.listFooterContainer}>
        <AddPaymentMethod
          onAddInsuranceOrDiscountSelected={addInsuranceOrMembership}
          onAddCardSelected={addCard}
        />
      </View>
    ),
    [addCard, addInsuranceOrMembership]
  );

  const handleRenderListEmpty = useCallback(
    () => (
      <View style={styles.emptyListContainer}>
        <Paragraph size="m">
          {formatMessage(messages.emptyPaymentMethodsListTitle)}
        </Paragraph>
      </View>
    ),
    [formatMessage]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <SelectPaymentMethod
            refreshing={refreshing}
            onRefresh={refetch}
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
            toPay={price}
            paymentProviders={paymentProviders}
            accountPaymentMethods={accountPaymentMethods}
            onCardSelected={selectCard}
            onPaymentProviderSelected={selectPaymentProvider}
            onInsuranceOrDiscountSelected={maybeSelectInsuranceOrMembership}
            ListHeaderComponent={handleRenderListHeader}
            ListFooterComponent={handleRenderListFooter}
            ListEmptyComponent={handleRenderListEmpty}
          />
        </>
      )}
    </SafeAreaView>
  );
}
FlowStageSetPaymentMethods_PrimaryScreen.routeName =
  "FlowStageSetPaymentMethods_PrimaryScreen";

FlowStageSetPaymentMethods_PrimaryScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
