import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LogBox, Platform, View, useWindowDimensions } from "react-native";
import { AuthState, useAuth } from "@eyr-mobile/domain/Auth";
import { compact } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";
import {
  getCurrentNavigationOptions,
  createBottomTabNavigator,
  createStackNavigator,
  getFocusedRouteNameFromRoute,
  addNavigationListener,
} from "@eyr-mobile/core/Navigation";
import { useWhiteLabel } from "@eyr-mobile/domain/WhiteLabel";
import { useOnboarding } from "@eyr-mobile/domain/Onboarding";

import { SVGs } from "../res";
/* Screens */
import {
  SessionRecoveryScreen,
  CheckoutScreen,
  HomeScreen,
  IdentificationScreen,
  IdentificationCountryScreen,
  IdentificationMethodScreen,
  TermsAndConditionsScreen,
  DeviceRegistrationScreen,
  HomeTabScreen,
  InboxTabScreen,
  MyAccountTabScreen,
  VaccinationCatalogScreen,
  FlowStageSetPaymentMethodsScreen,
  FlowStageSetPaymentMethods_AddCreditCardScreen,
  FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen,
  FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen,
  FlowStageSetPaymentMethods_PrimaryScreen,
  FlowStageSetPaymentMethods_SecondaryScreen,
  FlowStageAttachFormScreen,
  FlowStageSelectClientScreen,
  FlowStageSetMedicalCategoryScreen,
  FlowStageSelectClient_AddChildScreen,
  FlowStageSpecifyAppointmentInformationScreen,
  FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen,
  EditAccountLanguageScreen,
  AccountPaymentMethodsScreen,
  EditAccountContactInfoScreen,
  AccountConsultationsHistoryScreen,
  EditAccountBankCardScreen,
  EditAccountConsentsScreen,
  EditAccountInsuranceOrMembershipScreen,
  AddAccountInsuranceOrDiscountScreen,
  DigitalToolsScreen,
  DigitalToolFormScreen,
  DocumentsScreen,
  ConversationScreen,
  PharmaciesScreen,
  FlowStageSetMedicalCategory_DescribeSymptomsScreen,
  DisabledPaymentMethodResolutionScreen,
  EnableAppleReviewModeScreen,
  ConsultationReviewScreen,
  OngoingCallScreen,
  HowItWorksScreen,
  HelpAndContactScreen,
  IncomingCallScreen,
  FlowStageSetPaymentMethods_ControlQuestionTravelScreen,
  FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen,
  FlowStageSetPaymentMethods_ControlQuestionWorkScreen,
  FlowStageSetPaymentMethods_ControlQuestionChildScreen,
  ContentProgramsScreen,
  DeleteMyAccountScreen,
  ContextualDocumentsScreen,
  OnboardingSelectInsuranceOrDiscountScreen,
  OnboardingAddInsuranceOrDiscountScreen,
  OnboardingAddAccountContactInfoScreen,
} from "../screens";

import {
  stackScreenOptions,
  tabBarOptions,
  styles,
  getHorizontalContainerPadding,
} from "./Navigation.styles";

export const Stack = createStackNavigator();
export const Tab = createBottomTabNavigator();

export function HeaderBackImage({ tintColor }) {
  return (
    <SVGs.NavigationControlBackMono
      fill={tintColor}
      style={styles.headerBackImage}
    />
  );
}
HeaderBackImage.propTypes = {
  tintColor: PropTypes.string.isRequired,
};

LogBox.ignoreLogs([
  "Non-serializable values were found in the navigation state",
]);

function getAuthenticatedTabNavigatorScreenHeaderTitle(route) {
  // If the focused route is not found, we need to assume it's the initial screen
  // This can happen during if there hasn't been any navigation inside the screen
  // In our case, it's "HomeTabScreen" as that's the first screen inside the navigator
  const routeName =
    getFocusedRouteNameFromRoute(route) ?? HomeTabScreen.routeName;

  switch (routeName) {
    case HomeTabScreen.routeName:
      return HomeTabScreen.navigationOptions.title;
    case InboxTabScreen.routeName:
      return InboxTabScreen.navigationOptions.title;
    case MyAccountTabScreen.routeName:
      return MyAccountTabScreen.navigationOptions.title;
  }
}

const FLEX_PADDING_EMPIRICAL_CORRECTION = 40;

export function TabNavigator({ children }) {
  const { screenSizeSelect } = useDevice();
  const { width: screenWidth } = useWindowDimensions();
  const horizontalContainerPadding = getHorizontalContainerPadding(screenWidth);
  const _tabBarOptions = useMemo(() => {
    if (Platform.OS !== "web") {
      return tabBarOptions;
    }
    // temporary workaround
    return {
      ...tabBarOptions,
      tabBarStyle: compact([
        styles.tabBarWeb,
        screenSizeSelect({
          s: {
            paddingHorizontal:
              horizontalContainerPadding.s - FLEX_PADDING_EMPIRICAL_CORRECTION,
          },
          m: {
            paddingHorizontal:
              horizontalContainerPadding.m - FLEX_PADDING_EMPIRICAL_CORRECTION,
          },
        }),
      ]),
    };
  }, [screenSizeSelect, horizontalContainerPadding]);

  return (
    <Tab.Navigator
      screenOptions={{ ..._tabBarOptions }}
      backBehavior={Platform.select({ default: "none", web: "history" })}
    >
      {children}
    </Tab.Navigator>
  );
}

function AuthenticatedTabNavigator() {
  const { formatMessage } = useIntl();
  return (
    <TabNavigator>
      <Tab.Screen
        name={HomeTabScreen.routeName}
        options={{
          ...HomeTabScreen.navigationOptions,
          title: formatMessage(HomeTabScreen.navigationOptions.title),
        }}
        component={HomeTabScreen}
      />
      <Tab.Screen
        name={InboxTabScreen.routeName}
        options={{
          ...InboxTabScreen.navigationOptions,
          title: formatMessage(InboxTabScreen.navigationOptions.title),
        }}
        component={InboxTabScreen}
      />
      <Tab.Screen
        name={MyAccountTabScreen.routeName}
        options={{
          ...MyAccountTabScreen.navigationOptions,
          title: formatMessage(MyAccountTabScreen.navigationOptions.title),
        }}
        component={MyAccountTabScreen}
      />
    </TabNavigator>
  );
}

export function Navigation({ children, ready, ...rest }) {
  const { formatMessage } = useIntl();
  const { state: authState } = useAuth();
  const { screenSizeSelect } = useDevice();
  const whiteLabel = useWhiteLabel();
  const [
    useResponsiveNavigationContainer,
    setUseResponsiveNavigationContainer,
  ] = useState();
  const { onboardingActive, onboardingFlowStages } = useOnboarding();
  useEffect(() => {
    function handleNavigationOptions() {
      const { useResponsiveNavigationContainer } =
        getCurrentNavigationOptions() || {};
      setUseResponsiveNavigationContainer(useResponsiveNavigationContainer);
    }
    handleNavigationOptions();
    return addNavigationListener("state", handleNavigationOptions);
  }, [ready]);

  const unauthenticatedScreens = (
    <Stack.Group navigationKey={AuthState.UNAUTHENTICATED}>
      <Stack.Screen
        name={HomeScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          ...HomeScreen.navigationOptions,
          title: formatMessage(HomeScreen.navigationOptions.title),
          headerStyle: whiteLabel && styles.headerWithWhiteLabel,
        }}
        component={HomeScreen}
      />
      <Stack.Screen
        name={IdentificationMethodScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(
            IdentificationMethodScreen.navigationOptions.title
          ),
        }}
        component={IdentificationMethodScreen}
      />
      <Stack.Screen
        name={IdentificationCountryScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(
            IdentificationCountryScreen.navigationOptions.title
          ),
        }}
        component={IdentificationCountryScreen}
      />
      <Stack.Screen
        name={IdentificationScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(IdentificationScreen.navigationOptions.title),
        }}
        component={IdentificationScreen}
      />
      <Stack.Screen
        name={DeviceRegistrationScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(
            DeviceRegistrationScreen.navigationOptions.title
          ),
        }}
        component={DeviceRegistrationScreen}
      />
      <Stack.Screen
        name={TermsAndConditionsScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(
            TermsAndConditionsScreen.navigationOptions.title
          ),
        }}
        component={TermsAndConditionsScreen}
      />
      <Stack.Screen
        name={EnableAppleReviewModeScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(
            EnableAppleReviewModeScreen.navigationOptions.title
          ),
        }}
        component={EnableAppleReviewModeScreen}
      />
    </Stack.Group>
  );

  const onboardingScreensMap = {
    [OnboardingAddAccountContactInfoScreen.routeName]: (
      <Stack.Screen
        name={OnboardingAddAccountContactInfoScreen.routeName}
        key={OnboardingAddAccountContactInfoScreen.routeName}
        options={{
          ...OnboardingAddAccountContactInfoScreen.navigationOptions,
          title: formatMessage(
            OnboardingAddAccountContactInfoScreen.navigationOptions.title
          ),
        }}
        component={OnboardingAddAccountContactInfoScreen}
      />
    ),
    [OnboardingSelectInsuranceOrDiscountScreen.routeName]: (
      <Stack.Screen
        name={OnboardingSelectInsuranceOrDiscountScreen.routeName}
        key={OnboardingSelectInsuranceOrDiscountScreen.routeName}
        options={{
          ...OnboardingSelectInsuranceOrDiscountScreen.navigationOptions,
          title: formatMessage(
            OnboardingSelectInsuranceOrDiscountScreen.navigationOptions.title
          ),
        }}
        component={OnboardingSelectInsuranceOrDiscountScreen}
      />
    ),
    [OnboardingAddInsuranceOrDiscountScreen.routeName]: (
      <Stack.Screen
        name={OnboardingAddInsuranceOrDiscountScreen.routeName}
        key={OnboardingAddInsuranceOrDiscountScreen.routeName}
        options={{
          ...OnboardingAddInsuranceOrDiscountScreen.navigationOptions,
          title: formatMessage(
            OnboardingAddInsuranceOrDiscountScreen.navigationOptions.title
          ),
        }}
        component={OnboardingAddInsuranceOrDiscountScreen}
      />
    ),
  };

  const onboardingScreens = onboardingActive && (
    <>
      {onboardingFlowStages.map(
        (screenName) => onboardingScreensMap[screenName]
      )}
    </>
  );

  const authenticatedScreens = (
    <Stack.Group key={AuthState.AUTHENTICATED}>
      <Stack.Screen
        name={"AuthenticatedTabNavigatorScreen"}
        options={({ route }) => ({
          title: formatMessage(
            getAuthenticatedTabNavigatorScreenHeaderTitle(route)
          ),
        })}
        component={AuthenticatedTabNavigator}
      />
      <Stack.Screen
        name={IdentificationMethodScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(
            IdentificationMethodScreen.navigationOptions.title
          ),
        }}
        component={IdentificationMethodScreen}
      />
      <Stack.Screen
        name={IdentificationScreen.routeName}
        options={{
          useResponsiveNavigationContainer: true,
          title: formatMessage(IdentificationScreen.navigationOptions.title),
        }}
        component={IdentificationScreen}
      />
      <Stack.Screen
        name={TermsAndConditionsScreen.routeName}
        options={{
          title: formatMessage(
            TermsAndConditionsScreen.navigationOptions.title
          ),
        }}
        component={TermsAndConditionsScreen}
      />
      <Stack.Screen
        name={VaccinationCatalogScreen.routeName}
        options={{
          title: formatMessage(
            VaccinationCatalogScreen.navigationOptions.title
          ),
        }}
        component={VaccinationCatalogScreen}
      />
      <Stack.Screen
        name={FlowStageSpecifyAppointmentInformationScreen.routeName}
        options={{
          ...FlowStageSpecifyAppointmentInformationScreen.navigationOptions,
          title: formatMessage(
            FlowStageSpecifyAppointmentInformationScreen.navigationOptions.title
          ),
        }}
        component={FlowStageSpecifyAppointmentInformationScreen}
      />
      <Stack.Screen
        name={
          FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.routeName
        }
        options={{
          ...FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.navigationOptions,
          title: formatMessage(
            FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen
              .navigationOptions.title
          ),
        }}
        component={
          FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen
        }
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_AddCreditCardScreen.routeName}
        options={{
          ...FlowStageSetPaymentMethods_AddCreditCardScreen.navigationOptions,
          title: formatMessage(
            FlowStageSetPaymentMethods_AddCreditCardScreen.navigationOptions
              .title
          ),
        }}
        component={FlowStageSetPaymentMethods_AddCreditCardScreen}
      />
      <Stack.Screen
        name={
          FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.routeName
        }
        options={{
          ...FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.navigationOptions,
          title: formatMessage(
            FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen
              .navigationOptions.title
          ),
        }}
        component={FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen}
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.routeName}
        options={{
          ...FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.navigationOptions,
          title: formatMessage(
            FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen
              .navigationOptions.title
          ),
        }}
        component={FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen}
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_PrimaryScreen.routeName}
        options={{
          ...FlowStageSetPaymentMethods_PrimaryScreen.navigationOptions,
          title: formatMessage(
            FlowStageSetPaymentMethods_PrimaryScreen.navigationOptions.title
          ),
        }}
        component={FlowStageSetPaymentMethods_PrimaryScreen}
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_SecondaryScreen.routeName}
        options={{
          ...FlowStageSetPaymentMethods_SecondaryScreen.navigationOptions,
          title: formatMessage(
            FlowStageSetPaymentMethods_SecondaryScreen.navigationOptions.title
          ),
        }}
        component={FlowStageSetPaymentMethods_SecondaryScreen}
      />
      <Stack.Screen
        name={FlowStageAttachFormScreen.routeName}
        options={{
          ...FlowStageAttachFormScreen.navigationOptions,
          title: formatMessage(
            FlowStageAttachFormScreen.navigationOptions.title
          ),
        }}
        component={FlowStageAttachFormScreen}
      />
      <Stack.Screen
        name={FlowStageSelectClientScreen.routeName}
        options={{
          ...FlowStageSelectClientScreen.navigationOptions,
          title: formatMessage(
            FlowStageSelectClientScreen.navigationOptions.title
          ),
        }}
        component={FlowStageSelectClientScreen}
      />
      <Stack.Screen
        name={FlowStageSelectClient_AddChildScreen.routeName}
        options={{
          ...FlowStageSelectClient_AddChildScreen.navigationOptions,
          title: formatMessage(
            FlowStageSelectClient_AddChildScreen.navigationOptions.title
          ),
        }}
        component={FlowStageSelectClient_AddChildScreen}
      />
      <Stack.Screen
        name={FlowStageSetMedicalCategoryScreen.routeName}
        options={{
          ...FlowStageSetMedicalCategoryScreen.navigationOptions,
          title: formatMessage(
            FlowStageSetMedicalCategoryScreen.navigationOptions.title
          ),
        }}
        component={FlowStageSetMedicalCategoryScreen}
      />
      <Stack.Screen
        name={FlowStageSetMedicalCategory_DescribeSymptomsScreen.routeName}
        options={
          FlowStageSetMedicalCategory_DescribeSymptomsScreen.navigationOptions
        }
        component={FlowStageSetMedicalCategory_DescribeSymptomsScreen}
      />
      <Stack.Screen
        name={CheckoutScreen.routeName}
        options={() => ({
          ...CheckoutScreen.navigationOptions,
          title: formatMessage(CheckoutScreen.navigationOptions.title),
        })}
        component={CheckoutScreen}
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethodsScreen.routeName}
        options={FlowStageSetPaymentMethodsScreen.navigationOptions}
        component={FlowStageSetPaymentMethodsScreen}
      />
      <Stack.Screen
        name={DisabledPaymentMethodResolutionScreen.routeName}
        options={DisabledPaymentMethodResolutionScreen.navigationOptions}
        component={DisabledPaymentMethodResolutionScreen}
      />
      <Stack.Screen
        name={EditAccountLanguageScreen.routeName}
        options={{
          ...EditAccountLanguageScreen.navigationOptions,
          title: formatMessage(
            EditAccountLanguageScreen.navigationOptions.title
          ),
        }}
        component={EditAccountLanguageScreen}
      />
      <Stack.Screen
        name={AccountPaymentMethodsScreen.routeName}
        options={{
          ...AccountPaymentMethodsScreen.navigationOptions,
          title: formatMessage(
            AccountPaymentMethodsScreen.navigationOptions.title
          ),
        }}
        component={AccountPaymentMethodsScreen}
      />
      <Stack.Screen
        name={EditAccountContactInfoScreen.routeName}
        options={{
          ...EditAccountContactInfoScreen.navigationOptions,
          title: formatMessage(
            EditAccountContactInfoScreen.navigationOptions.title
          ),
        }}
        component={EditAccountContactInfoScreen}
      />
      <Stack.Screen
        name={AccountConsultationsHistoryScreen.routeName}
        options={{
          ...AccountConsultationsHistoryScreen.navigationOptions,
          title: formatMessage(
            AccountConsultationsHistoryScreen.navigationOptions.title
          ),
        }}
        component={AccountConsultationsHistoryScreen}
      />
      <Stack.Screen
        name={EditAccountBankCardScreen.routeName}
        options={{
          ...EditAccountBankCardScreen.navigationOptions,
          title: formatMessage(
            EditAccountBankCardScreen.navigationOptions.title
          ),
        }}
        component={EditAccountBankCardScreen}
      />
      <Stack.Screen
        name={EditAccountConsentsScreen.routeName}
        options={{
          ...EditAccountConsentsScreen.navigationOptions,
          title: formatMessage(
            EditAccountConsentsScreen.navigationOptions.title
          ),
        }}
        component={EditAccountConsentsScreen}
      />
      <Stack.Screen
        name={EditAccountInsuranceOrMembershipScreen.routeName}
        options={{
          ...EditAccountInsuranceOrMembershipScreen.navigationOptions,
          title: formatMessage(
            EditAccountInsuranceOrMembershipScreen.navigationOptions.title
          ),
        }}
        component={EditAccountInsuranceOrMembershipScreen}
      />
      <Stack.Screen
        name={AddAccountInsuranceOrDiscountScreen.routeName}
        options={{
          ...AddAccountInsuranceOrDiscountScreen.navigationOptions,
          title: formatMessage(
            AddAccountInsuranceOrDiscountScreen.navigationOptions.title
          ),
        }}
        component={AddAccountInsuranceOrDiscountScreen}
      />
      <Stack.Screen
        name={"AddAccountInsuranceOrDiscountFromShortLinkScreen"}
        options={{
          ...AddAccountInsuranceOrDiscountScreen.navigationOptions,
          title: formatMessage(
            AddAccountInsuranceOrDiscountScreen.navigationOptions.title
          ),
        }}
        component={AddAccountInsuranceOrDiscountScreen}
      />
      <Stack.Screen
        name={DigitalToolsScreen.routeName}
        options={{
          ...DigitalToolsScreen.navigationOptions,
          title: formatMessage(DigitalToolsScreen.navigationOptions.title),
        }}
        component={DigitalToolsScreen}
      />
      <Stack.Screen
        name={DigitalToolFormScreen.routeName}
        options={{
          ...DigitalToolFormScreen.navigationOptions,
          title: formatMessage(DigitalToolFormScreen.navigationOptions.title),
        }}
        component={DigitalToolFormScreen}
      />
      <Stack.Screen
        name={DocumentsScreen.routeName}
        options={{
          ...DocumentsScreen.navigationOptions,
          title: formatMessage(DocumentsScreen.navigationOptions.title),
        }}
        component={DocumentsScreen}
      />
      <Stack.Screen
        name={ConversationScreen.routeName}
        options={ConversationScreen.navigationOptions}
        component={ConversationScreen}
      />
      <Stack.Screen
        name={PharmaciesScreen.routeName}
        options={{
          ...PharmaciesScreen.navigationOptions,
          title: formatMessage(PharmaciesScreen.navigationOptions.title),
        }}
        component={PharmaciesScreen}
      />
      <Stack.Screen
        name={HowItWorksScreen.routeName}
        options={{
          ...HowItWorksScreen.navigationOptions,
          title: formatMessage(HowItWorksScreen.navigationOptions.title),
        }}
        component={HowItWorksScreen}
      />
      <Stack.Screen
        name={ConsultationReviewScreen.routeName}
        options={{
          ...ConsultationReviewScreen.navigationOptions,
        }}
        component={ConsultationReviewScreen}
      />
      <Stack.Screen
        name={HelpAndContactScreen.routeName}
        options={{
          ...HelpAndContactScreen.navigationOptions,
          title: formatMessage(HelpAndContactScreen.navigationOptions.title),
        }}
        component={HelpAndContactScreen}
      />
      <Stack.Screen
        name={DeleteMyAccountScreen.routeName}
        options={{
          ...DeleteMyAccountScreen.navigationOptions,
          title: formatMessage(DeleteMyAccountScreen.navigationOptions.title),
        }}
        component={DeleteMyAccountScreen}
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_ControlQuestionTravelScreen.routeName}
        options={
          FlowStageSetPaymentMethods_ControlQuestionTravelScreen.navigationOptions
        }
        component={FlowStageSetPaymentMethods_ControlQuestionTravelScreen}
      />
      <Stack.Screen
        name={
          FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.routeName
        }
        options={
          FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.navigationOptions
        }
        component={
          FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen
        }
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_ControlQuestionWorkScreen.routeName}
        options={
          FlowStageSetPaymentMethods_ControlQuestionWorkScreen.navigationOptions
        }
        component={FlowStageSetPaymentMethods_ControlQuestionWorkScreen}
      />
      <Stack.Screen
        name={FlowStageSetPaymentMethods_ControlQuestionChildScreen.routeName}
        options={
          FlowStageSetPaymentMethods_ControlQuestionChildScreen.navigationOptions
        }
        component={FlowStageSetPaymentMethods_ControlQuestionChildScreen}
      />
      <Stack.Screen
        name={ContentProgramsScreen.routeName}
        options={{
          ...ContentProgramsScreen.navigationOptions,
          title: formatMessage(ContentProgramsScreen.navigationOptions.title),
        }}
        component={ContentProgramsScreen}
      />
      <Stack.Screen
        name={IncomingCallScreen.routeName}
        options={IncomingCallScreen.navigationOptions}
        component={IncomingCallScreen}
      />
      <Stack.Screen
        name={OngoingCallScreen.routeName}
        options={OngoingCallScreen.navigationOptions}
        component={OngoingCallScreen}
      />
      <Stack.Screen
        name={ContextualDocumentsScreen.routeName}
        options={{
          ...ContextualDocumentsScreen.navigationOptions,
          title: formatMessage(
            ContextualDocumentsScreen.navigationOptions.title
          ),
        }}
        component={ContextualDocumentsScreen}
      />
    </Stack.Group>
  );
  const authLockedScreens = (
    <Stack.Group navigationKey={AuthState.AUTHENTICATED}>
      <Stack.Screen
        name={SessionRecoveryScreen.routeName}
        options={{
          ...SessionRecoveryScreen.navigationOptions,
          title: formatMessage(SessionRecoveryScreen.navigationOptions.title),
        }}
        component={SessionRecoveryScreen}
      />
      <Stack.Screen
        name={TermsAndConditionsScreen.routeName}
        options={{
          title: formatMessage(
            TermsAndConditionsScreen.navigationOptions.title
          ),
        }}
        component={TermsAndConditionsScreen}
      />
      <Stack.Screen
        name={IncomingCallScreen.routeName}
        options={IncomingCallScreen.navigationOptions}
        component={IncomingCallScreen}
      />
      <Stack.Screen
        name={OngoingCallScreen.routeName}
        options={OngoingCallScreen.navigationOptions}
        component={OngoingCallScreen}
      />
    </Stack.Group>
  );

  function getStackScreens() {
    switch (true) {
      case Boolean(children):
        return children;
      case authState === AuthState.AUTHENTICATED:
        return onboardingActive ? onboardingScreens : authenticatedScreens;
      case authState === AuthState.LOCKED:
        return authLockedScreens;
      case authState === AuthState.UNAUTHENTICATED:
      default:
        return unauthenticatedScreens;
    }
  }

  return (
    <View
      style={compact([
        styles.flexView,
        useResponsiveNavigationContainer &&
          screenSizeSelect({
            s: styles.framedContainer,
          }),
      ])}
    >
      <View
        style={
          useResponsiveNavigationContainer
            ? screenSizeSelect({
                xs: styles.flexView,
                s: styles.framedContentContainer,
              })
            : styles.flexView
        }
      >
        <Stack.Navigator
          screenOptions={{
            headerBackImage: HeaderBackImage,
            ...stackScreenOptions,
          }}
          {...rest}
        >
          {getStackScreens()}
        </Stack.Navigator>
      </View>
    </View>
  );
}

Navigation.propTypes = {
  ready: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};
