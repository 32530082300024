import { useCallback, useMemo, useRef, useState } from "react";
import {
  get,
  mapValues,
  every,
  some,
  noop,
  isEqual,
  tail,
  isFunction,
} from "lodash/fp";

import { hasValidationFailed, hasValidationSucceeded } from "../Validation";

const mapValuesWithKey = mapValues.convert({ cap: false, curry: false });

const computeFieldValidityState = (validator) => (value) => {
  if (isFunction(validator)) {
    const result = validator(value);
    const valid = hasValidationSucceeded(result);
    const invalid = hasValidationFailed(result);

    const error = invalid ? tail(result) : null;
    return {
      valid,
      invalid,
      error,
    };
  } else {
    return {
      valid: true,
      invalid: false,
      error: null,
    };
  }
};

const computeFieldChangeState = (initialValue) => (value) => {
  const pristine = isEqual(initialValue, value);
  return {
    pristine,
    dirty: !pristine,
  };
};

const computeFieldVisitState = (blur) => ({
  touched: !!blur,
  untouched: !blur,
});

const computeFieldFocusState = (focus) => ({
  focused: !!focus,
  unfocused: !focus,
});

const computeFormValidityState = (fields) => ({
  valid: every("valid", fields),
  invalid: some("invalid", fields),
});

const computeFormChangeState = (fields) => ({
  pristine: every("pristine", fields),
  dirty: some("dirty", fields),
});

const computeFormVisitState = (fields) => ({
  untouched: every("untouched", fields),
  touched: some("touched", fields),
});

const computeFormFocusState = (fields) => ({
  unfocused: every("unfocused", fields),
  focused: some("focused", fields),
});

const initialState = {
  value: null,
  pristine: true,
  dirty: false,
  touched: false,
  untouched: true,
  valid: null,
  invalid: null,
  focused: false,
  unfocused: true,
  error: null,
};

export function useForm(
  { initialValues, validators = {}, onSubmit = noop } = {},
  {
    validateOnMount = false,
    validateOnBlur = false,
    validateOnChange = false,
    editable = true,
  } = {}
) {
  const initialValuesRef = useRef(initialValues);
  const initialFields = useMemo(
    () =>
      mapValuesWithKey((value, fieldName) => {
        const validator = get(fieldName, validators);
        const validityStateFor = computeFieldValidityState(validator);
        return {
          ...initialState,
          editable,
          value,
          ...(validateOnMount && validityStateFor(value)),
        };
      }, initialValues),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const initialForm = useMemo(
    () => ({
      ...initialState,
      editable,
      value: initialValues,
      isSubmitting: false,
      ...(validateOnMount && computeFormValidityState(initialFields)),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [{ fields, form }, setState] = useState({
    form: initialForm,
    fields: initialFields,
  });

  const formHandlers = useMemo(() => {
    const getUpdatedFields = (
      fields,
      { visit, validate } = { validate: false, visit: false }
    ) =>
      mapValuesWithKey((fieldState, fieldName) => {
        const validator = get(fieldName, validators);
        const validityStateFor = computeFieldValidityState(validator);
        return {
          ...fieldState,
          ...(validate && validityStateFor(fieldState.value)),
          ...(visit && computeFieldVisitState(true)),
        };
      }, fields);
    return {
      validate: () => {
        const nextFields = getUpdatedFields(fields, { validate: true });
        const nextForm = {
          ...form,
          ...computeFormValidityState(nextFields),
        };
        setState({
          fields: nextFields,
          form: nextForm,
        });
      },
      submit: async () => {
        const nextFields = getUpdatedFields(fields, {
          validate: true,
          visit: true,
        });
        const validityState = computeFormValidityState(nextFields);
        const visitState = computeFormVisitState(nextFields);
        const nextForm = {
          ...form,
          ...validityState,
          ...visitState,
          error: null,
          isSubmitting: validityState.valid === true,
        };
        setState({
          fields: nextFields,
          form: nextForm,
        });
        if (nextForm.invalid) {
          return;
        }
        try {
          const result = await onSubmit(nextForm.value);
          setState((prevState) => ({
            ...prevState,
            form: { ...nextForm, isSubmitting: false },
          }));
          return result;
        } catch (e) {
          const error = e ? e : null;
          setState((prevState) => ({
            ...prevState,
            form: { ...nextForm, isSubmitting: false, error },
          }));
        }
      },
    };
  }, [validators, fields, form, onSubmit]);
  const getFieldHandlers = useCallback(
    (fieldName) => {
      const validator = get(fieldName, validators);
      const initialValue = get(fieldName, initialValuesRef.current);
      const validityStateFor = computeFieldValidityState(validator);
      const changeStateFor = computeFieldChangeState(initialValue);

      return {
        validate: () => {
          setState(({ form, fields }) => {
            const fieldState = get(fieldName, fields);
            const nextFields = {
              ...fields,
              [fieldName]: {
                ...fieldState,
                ...validityStateFor(fieldState.value),
              },
            };
            const nextForm = {
              ...form,
              ...computeFormValidityState(nextFields),
            };
            return {
              fields: nextFields,
              form: nextForm,
            };
          });
        },
        // choosing onChange name to support all kinds of form controls callbacks onValueChanged, onTextChanged, etc...
        onChange: (value) => {
          setState(({ form, fields }) => {
            const fieldState = get(fieldName, fields);
            const nextFields = {
              ...fields,
              [fieldName]: {
                ...fieldState,
                value,
                ...(validateOnChange && validityStateFor(value)),
                ...changeStateFor(value),
              },
            };
            const nextForm = {
              ...form,
              error: null,
              value: {
                ...form.value,
                [fieldName]: value,
              },
              ...(validateOnChange && computeFormValidityState(nextFields)),
              ...computeFormChangeState(nextFields),
            };
            return { fields: nextFields, form: nextForm };
          });
        },
        onFocus: () => {
          setState(({ form, fields }) => {
            const fieldState = get(fieldName, fields);
            const nextFields = {
              ...fields,
              [fieldName]: {
                ...fieldState,
                ...computeFieldFocusState(true),
              },
            };
            const nextForm = {
              ...form,
              ...computeFormFocusState(nextFields),
            };
            return {
              fields: nextFields,
              form: nextForm,
            };
          });
        },
        onBlur: () => {
          setState(({ form, fields }) => {
            const fieldState = get(fieldName, fields);
            const nextFields = {
              ...fields,
              [fieldName]: {
                ...fieldState,
                ...(validateOnBlur && validityStateFor(fieldState.value)),
                ...computeFieldVisitState(true),
                ...computeFieldFocusState(false),
              },
            };
            const nextForm = {
              ...form,
              ...(validateOnBlur && computeFormValidityState(nextFields)),
              ...computeFormVisitState(nextFields),
              ...computeFormFocusState(nextFields),
            };
            return {
              fields: nextFields,
              form: nextForm,
            };
          });
        },
      };
    },
    [validators, validateOnChange, validateOnBlur]
  );
  const updateInitialValues = useCallback(
    (initialValues) => {
      initialValuesRef.current = {
        ...initialValuesRef.current,
        ...initialValues,
      };
      const initialFields = mapValuesWithKey((value, fieldName) => {
        const validator = get(fieldName, validators);
        const validityStateFor = computeFieldValidityState(validator);
        return {
          ...initialState,
          editable,
          value,
          ...(validateOnMount && validityStateFor(value)),
        };
      }, initialValuesRef.current);
      setState({
        fields: initialFields,
        form: {
          ...initialState,
          editable,
          value: initialValues,
          ...(validateOnMount && computeFormValidityState(initialFields)),
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const _fields = useMemo(
    () =>
      mapValuesWithKey(
        (field, fieldName) => ({
          ...field,
          ...getFieldHandlers(fieldName),
        }),
        fields
      ),
    [fields, getFieldHandlers]
  );

  return {
    form: { ...form, ...formHandlers },
    fields: _fields,
    // not reactive
    initialFields: initialValuesRef.current,
    updateInitialValues,
  };
}
