import { defineMessages } from "react-intl";

import { ProductType } from "../../../domain/Order";

export const messages = defineMessages({
  noSelectionTitle: {
    id: "ProviderPicker.noSelectionTitle",
    description: "Nothing is selected title",
    defaultMessage: "All physicians",
  },
  selectActionTitle: {
    id: "ProviderPicker.selectActionTitle",
    description: "Select action title",
    defaultMessage: "Select a physician",
  },
  changeSelectionActionTitle: {
    id: "ProviderPicker.changeSelectionActionTitle",
    description: "Change selection action title",
    defaultMessage: "Change physician",
  },
  [`${ProductType.CONSULTATION}_noSelectionTitle`]: {
    id: "ProviderPicker.CONSULTATION_noSelectionTitle",
    description: "Nothing is selected title",
    defaultMessage: "All doctors",
  },
  [`${ProductType.CONSULTATION}_selectActionTitle`]: {
    id: "ProviderPicker.CONSULTATION_selectActionTitle",
    description: "Select action title",
    defaultMessage: "Select a doctor",
  },
  [`${ProductType.CONSULTATION}_changeSelectionActionTitle`]: {
    id: "ProviderPicker.CONSULTATION_changeSelectionActionTitle",
    description: "Change selection action title",
    defaultMessage: "Change doctor",
  },
  [`${ProductType.PSYCHOLOGIST_CONSULTATION}_noSelectionTitle`]: {
    id: "ProviderPicker.PSYCHOLOGIST_CONSULTATION_noSelectionTitle",
    description: "Nothing is selected title",
    defaultMessage: "All psychologists",
  },
  [`${ProductType.PSYCHOLOGIST_CONSULTATION}_selectActionTitle`]: {
    id: "ProviderPicker.PSYCHOLOGIST_CONSULTATION_selectActionTitle",
    description: "Select action title",
    defaultMessage: "Select a psychologist",
  },
  [`${ProductType.PSYCHOLOGIST_CONSULTATION}_changeSelectionActionTitle`]: {
    id: "ProviderPicker.PSYCHOLOGIST_CONSULTATION_changeSelectionActionTitle",
    description: "Change selection action title",
    defaultMessage: "Change psychologist",
  },
  [`${ProductType.GUIDE_CONSULTATION}_noSelectionTitle`]: {
    id: "ProviderPicker.GUIDE_CONSULTATION_noSelectionTitle",
    description: "Nothing is selected title",
    defaultMessage: "All doctors",
  },
  [`${ProductType.GUIDE_CONSULTATION}_selectActionTitle`]: {
    id: "ProviderPicker.GUIDE_CONSULTATION_selectActionTitle",
    description: "Select action title",
    defaultMessage: "Select a doctor",
  },
  [`${ProductType.GUIDE_CONSULTATION}_changeSelectionActionTitle`]: {
    id: "ProviderPicker.GUIDE_CONSULTATION_changeSelectionActionTitle",
    description: "Change selection action title",
    defaultMessage: "Change doctor",
  },
  [`${ProductType.PHYSIOTHERAPIST_CONSULTATION}_noSelectionTitle`]: {
    id: "ProviderPicker.PHYSIOTHERAPIST_CONSULTATION_noSelectionTitle",
    description: "Nothing is selected title",
    defaultMessage: "All physiotherapists",
  },
  [`${ProductType.PHYSIOTHERAPIST_CONSULTATION}_selectActionTitle`]: {
    id: "ProviderPicker.PHYSIOTHERAPIST_CONSULTATION_selectActionTitle",
    description: "Select action title",
    defaultMessage: "Select a physiotherapist",
  },
  [`${ProductType.PHYSIOTHERAPIST_CONSULTATION}_changeSelectionActionTitle`]: {
    id: "ProviderPicker.PHYSIOTHERAPIST_CONSULTATION_changeSelectionActionTitle",
    description: "Change selection action title",
    defaultMessage: "Change physiotherapist",
  },
});
