import { StyleSheet } from "react-native";
import { getImageDimensions } from "@sanity/asset-utils";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  list: {
    marginBottom: 16,
    paddingLeft: 8,
  },
  listItem: {
    justifyContent: "flex-start",
    flexDirection: "row",
  },
  listItemBullet: {
    marginHorizontal: 8,
  },
  listItemNumber: {
    marginHorizontal: 8,
  },
  blockquoteContainer: {
    borderLeftWidth: 4,
    borderLeftColor: Palette.BURGUNDY_0,
    paddingHorizontal: 12,
    marginBottom: 16,
  },
  blockContainer: {
    paddingHorizontal: 8,
  },
  hardBreak: {
    marginBottom: 24,
  },
});

// taken from https://github.com/portabletext/react-portabletext#types
export const getImageStyle = ({ value }) => {
  let dimensions;
  try {
    dimensions = getImageDimensions(value);
  } catch (error) {}
  return {
    // Avoid jumping around with aspect-ratio CSS property
    aspectRatio: dimensions && dimensions.width / dimensions.height,
    width: "100%",
    borderRadius: 8,
    marginBottom: 16,
    position: "relative",
  };
};
