import { StyleSheet } from "react-native";
import { capitalize, get } from "lodash/fp";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    borderRadius: 12,
    backgroundColor: Palette.WHITE,
    minHeight: 160,
    overflow: "hidden",
    ...Shadows.xs,
  },
  disabledContainer: {
    borderRadius: 12,
    backgroundColor: Palette.OVERLAY_DARK_4,
    minHeight: 160,
  },
  contentContainerColumn: {
    padding: 20,
  },
  contentContainerRow: {
    padding: 20,
    width: "60%",
  },
  buttonsContainer: {
    marginTop: 20,
  },
  buttonContainerRow: {
    marginRight: 14,
  },
  buttonContainerColumn: {
    marginBottom: 14,
  },
  lastButtonContainerRow: {
    flexGrow: 1,
    flexShrink: 1,
  },
  imageContainerColumn: {
    minHeight: 100,
  },
  imageContainerRow: {
    minHeight: 100,
    width: "40%",
  },
  imageColumn: {
    ...StyleSheet.absoluteFill,
    width: "100%",
    height: "100%",
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
  },
  imageRow: {
    ...StyleSheet.absoluteFill,
    width: "100%",
    height: "100%",
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  coverageContainer: {
    position: "absolute",
    right: 12,
    top: 14,
    zIndex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-end",
  },
  coverageChip: {
    backgroundColor: Palette.WHITE,
    borderRadius: 16,
    paddingVertical: 6,
    paddingHorizontal: 16,
    marginBottom: 8,
    ...Shadows.xs,
  },
  insuranceChips: {
    marginLeft: 8,
    flexDirection: "row",
  },
  insuranceChip: { height: 32, width: 32, borderRadius: 16, ...Shadows.xs },
  toBePaidContainer: {
    marginTop: 14,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  coverageDisclaimerContainer: {
    marginTop: 14,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  pricesContainer: {
    flexDirection: "row",
  },
  priceToBePaidContainer: {
    justifyContent: "center",
  },
  originalPriceContainer: {
    marginLeft: 8,
    justifyContent: "center",
  },
  spacingNone: {
    marginBottom: 0,
  },
  spacingM: {
    marginBottom: 16,
  },
  flexDirectionRow: {
    flexDirection: "row",
  },
  imageOverlayColumn: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Palette.OVERLAY_DARK_20,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
  },
  imageOverlayRow: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: Palette.OVERLAY_DARK_20,
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
  },
  grayScaleImageContainer: StyleSheet.absoluteFill,
});

export const stylingProps = {
  insuranceIcon: {
    width: 32,
    height: 32,
  },
};

export const stylingHelpers = {
  getOverlapStyle(index, total, onTopOverride = false) {
    if (total > 1) {
      return {
        marginLeft: index === 0 ? 0 : -12,
        zIndex: onTopOverride ? total + 1 : total - index,
      };
    }
  },
  getContainerStyle(disabled) {
    if (disabled) {
      return styles.disabledContainer;
    }
    return styles.container;
  },
  getLayoutAwareStyleFor(layout) {
    return (descriptor) => get(`${descriptor}${capitalize(layout)}`, styles);
  },
};
