import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    padding: 12,
    flex: 1,
  },
  textWrap: {
    marginBottom: 24,
  },
  buttonWrap: {
    alignItems: "center",
  },
});
