import React from "react";
import { Pressable, View } from "react-native";
import PropTypes from "prop-types";
import {
  compact,
  isString,
  noop,
  capitalize,
  get,
  isUndefined,
} from "lodash/fp";

import { Subtitle } from "../Subtitle";
import { Paragraph } from "../Paragraph";
import { SVGs } from "../../res";

import { styles, stylingProps, stylingHelpers } from "./Card.styles";

export function CardInner({
  title,
  titleIsBold,
  badge,
  disabled,
  description,
  extraInfo,
  onPress = noop,
  icon,
  layout = "row",
  showInteractivityIcon: showInteractivityIconProp,
  InteractivityIconComponent = SVGs.ChevronRightMono,
  pressable = true,
  padded = false,
  ...rest
}) {
  const showInteractivityIcon = isUndefined(showInteractivityIconProp)
    ? !disabled
    : showInteractivityIconProp;
  const interactivityIcon = showInteractivityIcon && (
    <InteractivityIconComponent
      {...stylingProps.interactivityIcon}
      style={styles.interactivityIcon}
    />
  );
  const isColumn = layout === "column";
  const isRow = layout === "row";
  const TitleComponent = titleIsBold ? Subtitle : Paragraph;
  const badgeContainer = badge && (
    <View style={styles.badgeContainer}>{badge}</View>
  );
  const textColor = disabled ? "dark" : "primary";

  return (
    <Pressable
      style={compact([
        styles.innerContainer,
        { flexDirection: layout },
        padded && styles.paddedContainer,
      ])}
      disabled={disabled}
      {...(!pressable && { disabled: true })}
      onPress={onPress}
      {...rest}
    >
      {(interactivityIcon || icon) && (
        <View style={styles.iconContainer}>
          {icon && (
            <View
              style={compact([
                isColumn && styles.iconColumnContainer,
                isRow && styles.iconRowContainer,
              ])}
            >
              {icon}
            </View>
          )}
          {isColumn && (
            <>
              {badgeContainer}
              {interactivityIcon}
            </>
          )}
        </View>
      )}
      <View style={styles.textContainer}>
        <View style={styles.titleAndBadgeContainer}>
          <View style={styles.titleContainer}>
            <TitleComponent size="l" wrappable color={textColor}>
              {title}
            </TitleComponent>
            <View style={styles.interactivityIconAndBadgeContainer}>
              {isRow && (
                <>
                  {badgeContainer}
                  {interactivityIcon}
                </>
              )}
            </View>
          </View>
        </View>
        {description && (
          <View style={styles.descriptionOrExtraInfoContainer}>
            {isString(description) ? (
              <Paragraph color={textColor}>{description}</Paragraph>
            ) : (
              description
            )}
          </View>
        )}
        {extraInfo && (
          <View style={styles.descriptionOrExtraInfoContainer}>
            {isString(extraInfo) ? (
              <Paragraph color={textColor}>{extraInfo}</Paragraph>
            ) : (
              extraInfo
            )}
          </View>
        )}
      </View>
    </Pressable>
  );
}

CardInner.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  layout: PropTypes.oneOf(["row", "column"]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  extraInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  badge: PropTypes.node,
  titleIsBold: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  showInteractivityIcon: PropTypes.bool,
  InteractivityIconComponent: PropTypes.elementType,
  pressable: PropTypes.bool,
  padded: PropTypes.bool,
};

export function Card({ spacing = "m", ...rest }) {
  return (
    <View
      style={compact([
        stylingHelpers.getContainerStyle(rest.disabled),
        get(`spacing${capitalize(spacing)}`, styles),
      ])}
    >
      <CardInner padded {...rest} />
    </View>
  );
}

Card.propTypes = {
  spacing: PropTypes.oneOf(["none", "m"]),
};
