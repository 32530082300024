import React from "react";
import {
  AddTokenizedCard,
  GetAccountPaymentMethods,
  tokenizeCard,
} from "@eyr-mobile/domain/Order";
import { useMutation } from "@apollo/client";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { get } from "lodash/fp";
import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import { useDevice } from "@eyr-mobile/core/Device";

import { AddCreditCard, parseExpiration } from "../../components";

import { styles } from "./EditAccountBankCardScreen.styles";
import { messages } from "./EditAccountBankCardScreen.messages";

export function EditAccountBankCardScreen() {
  const { goBack } = useNavigation();
  const [addTokenizedCard] = useMutation(AddTokenizedCard);
  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <View
        style={[
          styles.contentContainer,
          screenSizeSelect({
            xs: styles.contentContainerXS,
            s: styles.contentContainerS,
            m: styles.contentContainerM,
          }),
        ]}
      >
        <AddCreditCard
          initialValues={{ number: "", exp: "", cvc: "" }}
          onSubmit={async (card) => {
            const { exp, number, cvc } = card;
            const { exp_month, exp_year } = parseExpiration(exp);
            const token = await tokenizeCard({
              number,
              exp_month,
              exp_year,
              cvc,
            });
            await addTokenizedCard({
              variables: { input: { token, provider: "stripe" } },
              update: (store, { data: { addTokenizedCard } }) => {
                const data = store.readQuery({
                  query: GetAccountPaymentMethods,
                  variables: {},
                });
                const newData = { ...data };
                newData.accountPaymentMethods = [
                  ...data.accountPaymentMethods,
                  get("card", addTokenizedCard),
                ];
                store.writeQuery({
                  query: GetAccountPaymentMethods,
                  variables: {},
                  data: newData,
                });
              },
            });
            goBack();
          }}
        />
      </View>
    </SafeAreaView>
  );
}

EditAccountBankCardScreen.routeName = "EditAccountBankCardScreen";
EditAccountBankCardScreen.navigationOptions = {
  title: messages.navigationTitle,
};
