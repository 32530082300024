import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "FlowStageSetPaymentMethods_SecondaryScreen.title",
    description: "Title for the screen with remaining payment methods",
    defaultMessage: "How will you pay the remaining {remainingAmount}?",
  },
  navigationTitle: {
    id: "FlowStageSetPaymentMethods_SecondaryScreen.navigationTitle",
    description: "FlowStageSetPaymentMethods_SecondaryScreen navigation title",
    defaultMessage: "Select payment method",
  },
});
