import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "VaccinationCatalogScreen.navigationTitle",
    description: "VaccinationCatalogScreen navigation title",
    defaultMessage: "Vaccines",
  },
  terms: {
    id: "VaccinationCatalogScreen.terms",
    description: "VaccinationCatalogScreen terms and conditions text",
    defaultMessage:
      "Price includes medical assessment and prescription. The vaccine itself and the vaccination are paid for at the pharmacy.",
  },
  instructionsTitle: {
    id: "VaccinationCatalogScreen.instructionsTitle",
    description: "VaccinationCatalogScreen instructions title",
    defaultMessage: "How it works",
  },
  orderInstructionsText: {
    id: "VaccinationCatalogScreen.orderInstructionsText",
    description: "VaccinationCatalogScreen order instructions text",
    defaultMessage:
      "You order a vaccine by filling out a simple questionnaire.",
  },
  prescriptionInstructionsText: {
    id: "VaccinationCatalogScreen.prescriptionInstructionsText",
    description: "VaccinationCatalogScreen prescription instructions text",
    defaultMessage:
      "Your prescription becomes ready within 1 working day. In some cases a doctor may contact you for additional information before a prescription can be issued.",
  },
  pharmacyInstructionsText: {
    id: "VaccinationCatalogScreen.pharmacyInstructionsText",
    description: "VaccinationCatalogScreen pharmacy instructions text",
    defaultMessage:
      "You go to your preferred Apotek 1 pharmacy to get vaccinated.",
  },
  findPharmacyButtonText: {
    id: "VaccinationCatalogScreen.findPharmacyButtonText",
    description: "VaccinationCatalogScreen find pharmacy button text",
    defaultMessage: "Find your nearest pharmacy",
  },
});
