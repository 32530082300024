import React, { useCallback, useMemo } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { useLegal, GetAccountAcceptedConsents } from "@eyr-mobile/domain/Legal";
import { ScrollView } from "react-native";
import { useDevice } from "@eyr-mobile/core/Device";

import { Consent } from "../../components";

import { messages } from "./EditAccountConsentsScreen.messages";
import { styles } from "./EditAccountConsentsScreen.styles";

export function EditAccountConsentsScreen() {
  const { rejectConsent } = useLegal();
  const { handlers, data } = withHandlers(
    useQuery(GetAccountAcceptedConsents, {
      fetchPolicy: "network-only",
    })
  );

  const handleReject = useCallback(
    ({ id }) =>
      () => {
        rejectConsent({ id });
      },
    [rejectConsent]
  );

  const consents = useMemo(() => {
    if (!data?.account?.orgAccounts?.length) {
      return [];
    }

    const {
      account: { orgAccounts },
    } = data;

    return orgAccounts.reduce(
      (reduced, orgAccount) =>
        reduced.concat(
          orgAccount.orgAccountConsents.map((orgAccountConsent) => (
            <Consent
              key={orgAccountConsent.consent.id.toString()}
              {...orgAccountConsent.consent}
              onRejected={handleReject(orgAccountConsent.consent)}
              accepted
              acceptedAt={orgAccountConsent.updatedAt}
            />
          ))
        ),
      []
    );
  }, [data, handleReject]);

  const { screenSizeSelect } = useDevice();
  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {/*TODO: Add positioning for handlers, now loading is rendered on top of the screen <Finn Fitzsimons>*/}
      <ScrollView
        contentContainerStyle={screenSizeSelect({
          s: styles.contentContainerS,
          m: styles.contentContainerM,
        })}
      >
        {handlers || consents}
      </ScrollView>
    </SafeAreaView>
  );
}

EditAccountConsentsScreen.routeName = "EditAccountConsentsScreen";
EditAccountConsentsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
