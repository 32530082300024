import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    paddingBottom: 16,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    paddingBottom: 32,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    paddingBottom: 48,
    ...responsiveStyles.contentContainerM,
  },
  headerIcon: {
    alignItems: "center",
    marginBottom: 8,
  },
  textSection: {
    marginBottom: 24,
    marginHorizontal: 8,
  },
  openingHoursSection: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardContentContainer: {
    flexDirection: "row",
  },
  cardIconLeftContainer: {
    marginRight: 12,
  },
  cardIconRightContainer: {
    marginLeft: 12,
  },
  cardTextContainer: {
    flexShrink: 1,
  },
});
