import { StyleSheet, Platform } from "react-native";
import { fontMaker } from "@eyr-mobile/core/Lib";

import { Palette } from "../../shared-styles/palette";

const borderWidth = 1;
const baseLineHeight = 22;
const padding = 16;

const base = {
  ...fontMaker(),
  fontSize: 16,
  height: 56,
  // DO NOT replace with paddingVertical/paddingHorizontal it won't work for multiline mode.
  // Keep paddingTop and paddingBottom, paddingLeft and paddingRight
  // https://github.com/facebook/react-native/issues/21720#issuecomment-532642093
  paddingTop: padding,
  paddingRight: padding,
  paddingBottom: padding,
  paddingLeft: padding,
  borderRadius: 8,
  borderWidth: borderWidth,
  lineHeight: baseLineHeight,
  borderColor: Palette.BURGUNDY_20,
  // as for RN 0.63.1 TextInput does not support boxSizing: "border-box"
  color: Palette.BURGUNDY_80,
};

const focused = {
  ...Platform.select({ web: { boxShadow: "0 0 0 1px" } }),
};

const disabled = {
  ...base,
  backgroundColor: Palette.BURGUNDY_0,
  color: Palette.BURGUNDY_60,
};

export const styles = StyleSheet.create({
  startAdornment: {
    position: "absolute",
    left: 16,
    top: 16,
  },
  withStartAdornment: {
    paddingLeft: 46,
  },
  endAdornment: {
    position: "absolute",
    right: 16,
    top: 16,
  },
  withEndAdornment: {
    paddingRight: 46,
  },
  disabled,
  primary: {
    ...base,
    backgroundColor: Palette.WHITE,
  },
  "primary-focused": {
    ...base,
    ...focused,
    borderColor: Palette.BURGUNDY_100,
    backgroundColor: Palette.WHITE,
  },
  danger: {
    ...base,
    borderColor: Palette.RED_100,
    color: Palette.RED_100,
    backgroundColor: Palette.WHITE,
  },
  "danger-focused": {
    ...base,
    ...focused,
    borderColor: Palette.RED_100,
    color: Palette.RED_100,
    backgroundColor: Palette.WHITE,
  },
  ghost: {
    ...base,
    borderColor: Palette.TRANSPARENT,
    backgroundColor: Palette.TRANSPARENT,
  },
  "ghost-focused": {
    ...base,
    borderColor: Palette.TRANSPARENT,
    backgroundColor: Palette.TRANSPARENT,
  },
  "ghost-disabled": {
    ...base,
    borderColor: Palette.TRANSPARENT,
    backgroundColor: Palette.TRANSPARENT,
  },
  UNSAFE_search: {
    ...base,
    backgroundColor: Palette.WHITE,
  },
  "UNSAFE_search-focused": {
    ...base,
    ...focused,
    borderColor: Palette.BURGUNDY_100,
    backgroundColor: Palette.WHITE,
  },
  "UNSAFE_search-disabled": {
    ...base,
    backgroundColor: Palette.WHITE,
  },
});

export const stylingProps = {
  selectionColor: Palette.BURGUNDY_100,
  placeholderTextColor: Palette.BURGUNDY_40,
  textAlignVertical: "top",
};

export const stylingHelpers = {
  getMultilineStyle(numberOfLines) {
    return {
      height: numberOfLines * baseLineHeight + 2 * padding,
    };
  },
  getMaxNumberOfLinesStyle(maxNumberOfLines) {
    return {
      maxHeight: maxNumberOfLines * baseLineHeight + 2 * padding,
    };
  },
  getNumberOfLinesFromContentHeight(height) {
    if (Platform.OS === "web") {
      return Math.floor((height - 2 * padding) / baseLineHeight);
    } else {
      return height / baseLineHeight;
    }
  },
};

//https://github.com/facebook/react-native/issues/29702
export const androidContentHeightBugOffset = padding * 2;
