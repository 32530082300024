import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";
import { noop } from "lodash/fp";
import { useIntl } from "@eyr-mobile/core/Intl";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Subtitle } from "../Subtitle";
import { EyrButton } from "../EyrButton";
import { Paragraph } from "../Paragraph";
import { SVGs } from "../../res";

import { messages } from "./ConsultationPreparationInfo.messages";
import { styles, stylingProps } from "./ConsultationPreparationInfo.styles";

export function ConsultationPreparationInfo({
  showChildSection,
  showAddPhoneNumberSection,
  onAddPhoneNumberButtonPressed = noop,
}) {
  const { formatMessage } = useIntl();

  return (
    <View>
      <View style={styles.section}>
        <View style={styles.sectionIconContainer}>
          <SVGs.FourAndFiveG {...stylingProps.sectionIcon} />
        </View>
        <View style={styles.sectionContent}>
          <Subtitle size="m">
            {formatMessage(messages.show4GSectionTitle)}
          </Subtitle>
          <Paragraph>
            {formatMessage(messages.show4GSectionDescription)}
          </Paragraph>
        </View>
      </View>
      {renderIf(showChildSection)(
        <View style={[styles.section, styles.sectionTopBorder]}>
          <View style={styles.sectionIconContainer}>
            <SVGs.Stroller {...stylingProps.sectionIcon} />
          </View>
          <View style={styles.sectionContent}>
            <Subtitle size="m">
              {formatMessage(messages.bringYourChildSectionTitle)}
            </Subtitle>
            <Paragraph>
              {formatMessage(messages.bringYourChildSectionDescription)}
            </Paragraph>
          </View>
        </View>
      )}
      {renderIf(showAddPhoneNumberSection)(
        <View style={[styles.addPhoneNumberSection, styles.sectionTopBorder]}>
          <View style={styles.addPhoneNumberTextSection}>
            <View style={styles.sectionIconContainer}>
              <SVGs.Phone {...stylingProps.sectionIcon} />
            </View>
            <View style={styles.sectionContent}>
              <Subtitle size="m">
                {formatMessage(messages.addPhoneNumberSectionTitle)}
              </Subtitle>
              <Paragraph>
                {formatMessage(messages.addPhoneNumberSectionDescription)}
              </Paragraph>
            </View>
          </View>
          <View style={styles.addPhoneNumberButtonContainer}>
            <EyrButton
              onPress={onAddPhoneNumberButtonPressed}
              variant="secondary"
              title={formatMessage(messages.addPhoneNumberButtonActionTitle)}
              accessibilityRole={"button"}
              accessibilityLabel={formatMessage(
                messages.addPhoneNumberButtonActionTitle
              )}
              IconComponent={SVGs.AddCircleOutlineMono}
            />
          </View>
        </View>
      )}
    </View>
  );
}

ConsultationPreparationInfo.propTypes = {
  showChildSection: PropTypes.bool,
  showAddPhoneNumberSection: PropTypes.bool,
  onAddPhoneNumberButtonPressed: PropTypes.func,
};
