// Names should contain 1 to 40 alphanumeric characters or underscores.
export const CallAnalyticsEvents = {
  CALL_SETUP_FAILED: "call_setup_failed",
  CALL_ENDED_BY_CALLING_PARTY: "call_ended_by_calling_party",
  CALL_ENDED: "call_ended",
  CALL_DECLINED_ELSEWHERE_OR_ENDED_BY_CALLING_PARTY:
    "declined_elsewhere_or_ended_by_calling",
  CALL_ACCEPTED: "call_accepted",
  CALL_ENDED_BY_CALLED_PARTY: "call_ended_by_called_party",
  CALL_TIMED_OUT: "call_timed_out",
  INCOMING_CALL_PRESENTED: "incoming_call_presented",
  CALL_DECLINED: "call_declined",
  CALL_ACCEPTED_ELSEWHERE: "call_accepted_elsewhere",
  CALL_DECLINED_ELSEWHERE: "call_declined_elsewhere",
};
