import { StyleSheet } from "react-native";

import { responsiveStyles } from "../../shared-styles/responsive";
import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainer: { flex: 1 },
  containerXS: { ...responsiveStyles.containerXS },
  containerS: { ...responsiveStyles.containerS },
  containerM: { ...responsiveStyles.containerM },
  contentContainerXS: {
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.contentContainerM,
  },
});
