import React, { useCallback, useMemo } from "react";
import { SectionList, View, Switch } from "react-native";
import PropTypes from "prop-types";
import { noop, compact } from "lodash/fp";
import { getLanguageNameByCode, useIntl } from "@eyr-mobile/core/Intl";
import { useAlert } from "@eyr-mobile/core/Alert";

import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";
import { EyrButton } from "../EyrButton";
import { ListItem } from "../ListItem";
import { ListItemSeparator } from "../ListItemSeparator";

import { styles, stylingProps } from "./AccountSettings.styles";
import { messages } from "./AccountSettings.messages";

const SettingsItem = ({
  icon,
  label,
  value,
  handler = noop,
  pressable = true,
}) => {
  const Icon = icon && SVGs[icon] ? SVGs[icon] : null;
  return (
    <ListItem
      layout="1"
      onPress={handler}
      pressable={pressable}
      style={styles.listItem}
    >
      <View style={styles.listItemInner}>
        <View style={styles.row}>
          {Icon && <Icon {...stylingProps.icon} style={styles.listItemIcon} />}
          <Paragraph size="l" spacing="none" color="primary">
            {label}
          </Paragraph>
        </View>
        <View style={styles.row}>
          {value && <Paragraph size="l">{value}</Paragraph>}
          {pressable && <ListItem.Arrow style={styles.listItemChevron} />}
        </View>
      </View>
    </ListItem>
  );
};

SettingsItem.propTypes = {
  handler: PropTypes.func,
  pressable: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export function AccountSettings({
  onHelpAndContactPress,
  onEditPaymentMethods,
  onEditContactInformation,
  onEditLanguage,
  onEditConsents,
  onEditBiometryPreference,
  onLogout,
  appVersion,
  showBiometryPreferenceSetting,
  preferBiometry,
  account,
}) {
  const { formatMessage, formatDate, locale } = useIntl();
  const alert = useAlert();

  const logoutWithAlert = useCallback(() => {
    alert({
      title: formatMessage(messages.logOut),
      message: formatMessage(messages.verifyActionQuestion),
      buttons: [
        {
          title: formatMessage(messages.cancel),
          variant: "secondary",
          onPress: noop,
        },
        {
          title: formatMessage(messages.logOut),
          variant: "negative",
          onPress: onLogout,
        },
      ],
    });
  }, [alert, formatMessage, onLogout]);

  const removePinCodeWithAlert = useCallback(() => {
    alert({
      title: formatMessage(
        preferBiometry
          ? messages.changeIdentificationMethod
          : messages.changePin
      ),
      message: formatMessage(messages.logoutMsg),
      buttons: [
        {
          title: formatMessage(messages.cancel),
          variant: "secondary",
          onPress: noop,
        },
        {
          title: formatMessage(messages.reLoginMsg),
          variant: "negative",
          onPress: onLogout,
        },
      ],
    });
  }, [alert, formatMessage, preferBiometry, onLogout]);

  const settingsSections = useMemo(() => {
    const { firstName, lastName, birthdate, gender } = account;
    const sections = [
      {
        title: null,
        data: compact([
          {
            label: formatMessage(messages.name),
            value: `${firstName || ""} ${lastName || ""}`,
            pressable: false,
          },
          {
            label: formatMessage(messages.dateOfBirth),
            value: formatDate(birthdate),
            pressable: false,
          },
          Boolean(gender) && {
            label: formatMessage(messages.gender),
            value: formatMessage(
              gender === "male" ? messages.male : messages.female
            ),
            pressable: false,
          },
        ]),
      },

      {
        title: null,
        data: [
          {
            label: formatMessage(messages.paymentMethods),
            handler: onEditPaymentMethods,
            icon: "CreditCard",
          },
          {
            label: formatMessage(messages.accountDetails),
            handler: onEditContactInformation,
            icon: "ContactInfo",
          },
          {
            label: formatMessage(messages.language),
            value: getLanguageNameByCode(locale),
            handler: onEditLanguage,
            icon: "Language",
          },
        ],
      },

      {
        title: null,
        data: [
          {
            label: formatMessage(messages.changePin),
            handler: removePinCodeWithAlert,
            icon: "PinCode",
          },
        ],
      },
      {
        title: null,
        data: [
          {
            label: formatMessage(messages.helpAndContact),
            handler: onHelpAndContactPress,
            icon: "Support",
          },
          {
            label: formatMessage(messages.consents),
            handler: onEditConsents,
            icon: "Prescription",
          },
        ],
      },
    ];

    if (showBiometryPreferenceSetting) {
      sections[2].data.push({
        label: formatMessage(messages.enableBiometry),
        icon: "TouchId",
        value: (
          <Switch
            value={preferBiometry}
            onValueChange={onEditBiometryPreference}
          />
        ),
      });
    }

    return sections;
  }, [
    account,
    formatMessage,
    formatDate,
    onEditPaymentMethods,
    onEditContactInformation,
    locale,
    onEditLanguage,
    removePinCodeWithAlert,
    onHelpAndContactPress,
    onEditConsents,
    showBiometryPreferenceSetting,
    preferBiometry,
    onEditBiometryPreference,
  ]);

  const renderItem = useCallback(({ item }) => {
    return <SettingsItem {...item} />;
  }, []);

  const renderSectionFooter = useCallback(
    () => <View style={styles.listSectionFooter} />,
    []
  );
  const keyExtractor = useCallback(({ label }) => label, []);

  return (
    <SectionList
      testID="AccountSettingsList"
      sections={settingsSections}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      renderSectionFooter={renderSectionFooter}
      ItemSeparatorComponent={ListItemSeparator}
      stickySectionHeadersEnabled={false}
      ListFooterComponent={
        <View style={styles.footerContainer}>
          <EyrButton
            onPress={logoutWithAlert}
            title={formatMessage(messages.logOut)}
            variant={"negative"}
            accessibilityLabel={formatMessage(messages.logOut)}
          />
          <Paragraph size="m" spacing="none">
            {appVersion}
          </Paragraph>
        </View>
      }
    />
  );
}

const ACCOUNT_SHAPE = PropTypes.shape({
  id: PropTypes.number.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  birthdate: PropTypes.string.isRequired,
  gender: PropTypes.string,
});

AccountSettings.propTypes = {
  onHelpAndContactPress: PropTypes.func.isRequired,
  onEditPaymentMethods: PropTypes.func.isRequired,
  onEditContactInformation: PropTypes.func.isRequired,
  onEditLanguage: PropTypes.func.isRequired,
  onEditConsents: PropTypes.func.isRequired,
  onEditBiometryPreference: PropTypes.func,
  onLogout: PropTypes.func,
  account: ACCOUNT_SHAPE.isRequired,
  showBiometryPreferenceSetting: PropTypes.bool,
  preferBiometry: PropTypes.bool,
  appVersion: PropTypes.string.isRequired,
};
