import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "InboxTabScreen.navigationTitle",
    description: "InboxTabScreen navigation title",
    defaultMessage: "Inbox",
  },
  documents: {
    id: "InboxTabScreen.documents",
    description: "button text to navigate to documents",
    defaultMessage: "Documents",
  },
  digitalTools: {
    id: "InboxTabScreen.digitalTools",
    description: "button text to navigate to digital tools",
    defaultMessage: "Treatment tools",
  },
  noMessages: {
    id: "InboxTabScreen.noMessages",
    description: "text when no inbox items",
    defaultMessage: "You have no messages",
  },
});
