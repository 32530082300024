import { messages } from "./Notifications.messages";

export function buildMissedCallNotification({ formatMessage }) {
  return {
    content: {
      title: formatMessage(messages.missedCallNotificationTitle),
      body: formatMessage(messages.missedCallNotificationBody),
    },
    trigger: null,
  };
}
