import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { isString, mapValues, size } from "lodash/fp";

import { CloudInstance } from "../Cloud";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";

const logger = LoggerFactory.get("core/Analytics");
const analytics = getAnalytics(CloudInstance);
let marketingSdkInitialized = false;

export function logAnalyticsEvent(name, params) {
  logEvent(analytics, name, params);
}

export function logScreenView(params) {
  return logEvent(analytics, "screen_view", params);
}

export function logSelectContent(params) {
  return logEvent(analytics, "select_content", params);
}

export function setAnalyticsUserId(userId, options) {
  logger("setAnalyticsUserId", userId);
  return setUserId(analytics, userId, options);
}

export function setAnalyticsUserProperties(userProperties, options) {
  const typeSafeUserProperties = mapValues((value) => {
    if (isString(value)) {
      return value;
    }
    return JSON.stringify(value);
  }, userProperties);
  logger("setAnalyticsUserProperties", {
    userProperties: typeSafeUserProperties,
    options,
  });
  return setUserProperties(analytics, typeSafeUserProperties, options);
}

export function logMarketingEvent(
  eventName,
  eventValues = {},
  eventRevenue = {}
) {
  if (size(name) > 45) {
    logger(
      "logMarketingEvent:Events names with more than 45 characters do not appear in the dashboard, but only in the raw Data, Pull and Push APIs.",
      { eventName, eventValues, eventRevenue },
      LOGGER_LEVEL_WARN
    );
  }
  const marketing = global.AF;
  if (!marketing) {
    logger(
      "logMarketingEvent:AppsFlyer SDK is not available",
      { eventName, eventValues, eventRevenue },
      LOGGER_LEVEL_WARN
    );
    return;
  }
  marketing("pba", "event", {
    eventType: "EVENT",
    eventName,
    ...(eventRevenue && {
      eventRevenue: eventRevenue.amount,
      eventRevenueCurrency: eventRevenue.currency,
    }),
    ...(eventValues && { eventValue: eventValues }),
  });
}

export function initMarketingSdk(options) {
  if (marketingSdkInitialized) {
    return;
  }
  logger("initMarketingSdk", options);
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.text = `!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){
(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},
t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,
o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),
p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF","pba",{pba: {webAppId: "${options.devKey}"}})`;
  document.head.appendChild(script);
  marketingSdkInitialized = true;
}

export async function getAdvertisingId() {
  logger("getAdvertisingId", "not applicable for web", LOGGER_LEVEL_WARN);
}

export async function setMarketingUserId(customerUserId) {
  const marketing = global.AF;
  if (!marketing) {
    logger(
      "setMarketingUserId:AppsFlyer SDK is not available",
      customerUserId,
      LOGGER_LEVEL_WARN
    );
    return;
  }
  logger("setMarketingUserId", customerUserId);
  // keep as setCustomerUserId
  marketing("pba", "setCustomerUserId", customerUserId);
}
