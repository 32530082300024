import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  header: {
    marginBottom: 24,
  },
  iconsContainer: {
    marginBottom: 12,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  forwardIcon: {
    width: 28,
    height: 28,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.PINK_60,
    borderRadius: 14,
  },
  closedLabel: {
    backgroundColor: Palette.TURQUOISE_60,
    borderRadius: 4,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    alignItems: "center",
  },
  closedIcon: {
    marginRight: 4,
  },
});
