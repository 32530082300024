import React from "react";
import PropTypes from "prop-types";

export function OrderedList({ children }) {
  return React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      ...child.props,
      children: `${index + 1}. ${child.props.children}`,
    });
  });
}

OrderedList.propTypes = {
  children: PropTypes.node.isRequired,
};
