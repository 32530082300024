import React from "react";
import PropTypes from "prop-types";
import { every } from "lodash/fp";
import { useIntl } from "react-intl";
import { View } from "react-native";
import { Grid, Col } from "react-native-easy-grid";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Paragraph } from "../Paragraph";
import { EyrButton } from "../EyrButton";

import { styles } from "./GraphQLError.styles.js";
import { messages } from "./GraphQLError.messages.js";

/**
 * Inherits View props
 */
export function GraphQLError({ error: { message }, retryAction, ...rest }) {
  const { formatMessage } = useIntl();
  return (
    <Grid {...rest}>
      <Col style={styles.container}>
        {renderIf(message)(
          <Paragraph
            size="l"
            alignment="center"
            spacing="none"
            color="negative"
          >
            {message}
          </Paragraph>
        )}
        {renderIf(retryAction)(() => (
          <View>
            <EyrButton
              variant={"primary"}
              accessibilityLabel={"Retry"}
              onPress={retryAction}
              title={formatMessage(messages.retry)}
            />
          </View>
        ))}
      </Col>
    </Grid>
  );
}

GraphQLError.propTypes = {
  retryAction: PropTypes.func,
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
    graphQLErrors: PropTypes.arrayOf(
      PropTypes.shape({ message: PropTypes.string.isRequired })
    ),
    networkError: PropTypes.shape({ message: PropTypes.string.isRequired }),
  }),
};

GraphQLError.isError = (maybeError) =>
  !!maybeError &&
  every(
    (prop) => prop in maybeError,
    ["message", "graphQLErrors", "networkError"]
  );
