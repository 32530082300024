import React, { useCallback } from "react";
import { ScrollView } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { getOr } from "lodash/fp";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { useWhiteLabel } from "@eyr-mobile/domain/WhiteLabel";
import { GetAccountCountryOrgs } from "@eyr-mobile/domain/Account";
import { useDevice } from "@eyr-mobile/core/Device";
import { useNavigation } from "@react-navigation/native";

import { SelectInsuranceOrDiscount } from "../../components";

import { styles } from "./EditAccountInsuranceOrMembershipScreen.styles";
import { messages } from "./EditAccountInsuranceOrMembershipScreen.messages";

const getAccountCountryInsuranceProviders = getOr([], "account.country.orgs");

export function EditAccountInsuranceOrMembershipScreen() {
  const whitelabel = useWhiteLabel();
  const { handlers, data } = withHandlers(
    useQuery(GetAccountCountryOrgs, {
      variables: { whitelabel },
    })
  );

  const { navigate } = useNavigation();
  const { screenSizeSelect } = useDevice();

  const insuranceProviders = getAccountCountryInsuranceProviders(data);

  const handleSelectInsuranceOrDiscount = useCallback(
    ({ slug }) =>
      navigate("AddAccountInsuranceOrDiscountScreen", {
        org: slug,
      }),
    [navigate]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ScrollView
            style={styles.contentContainer}
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
          >
            <SelectInsuranceOrDiscount
              insuranceProviders={insuranceProviders}
              onInsuranceOrDiscountPress={handleSelectInsuranceOrDiscount}
            />
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
}

EditAccountInsuranceOrMembershipScreen.routeName =
  "EditAccountInsuranceOrMembershipScreen";
EditAccountInsuranceOrMembershipScreen.navigationOptions = {
  title: messages.navigationTitle,
};
