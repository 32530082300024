import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    paddingVertical: 12,
  },
  labelContainer: {
    marginBottom: 8,
    marginLeft: 4,
  },
  helperContainer: {
    marginTop: 8,
    marginLeft: 4,
  },
});

export const stylingHelpers = {
  getTextColor({ disabled, error }) {
    if (disabled) {
      return "disabled";
    }
    if (error) {
      return "negative";
    }
    return "primary";
  },
};
