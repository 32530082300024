import React from "react";
import PropTypes from "prop-types";
import { View, Image } from "react-native";
import { useFormattedRelativeTimeTo, useIntl } from "@eyr-mobile/core/Intl";
import { getInitials, renderIfElse } from "@eyr-mobile/core/Lib";
import { isString } from "lodash/fp";
import Hyperlink from "react-native-hyperlink";

import { Paragraph } from "../Paragraph";

import { styles } from "./ConversationMessage.styles";

export function ConversationMessage({
  insertedAt,
  text,
  variant = "outgoing",
  avatar,
  messengerName,
}) {
  const { formatDate } = useIntl();
  const [timeAgo, { unit, value }] = useFormattedRelativeTimeTo(insertedAt);
  const isIncoming = variant === "incoming";

  return (
    <View
      style={[
        styles.container,
        isIncoming ? styles.incomingContainer : styles.outgoingContainer,
      ]}
    >
      {isIncoming && (
        <View style={styles.avatarContainer}>
          {renderIfElse(avatar)(
            () => (
              <Image source={{ uri: avatar }} style={styles.avatar} />
            ),
            <Paragraph>{getInitials(messengerName)}</Paragraph>
          )}
        </View>
      )}
      <View
        style={[
          styles.contentContainer,
          isIncoming
            ? styles.incomingContentContainer
            : styles.outgoingContentContainer,
        ]}
      >
        <Paragraph size="s" spacing="m">
          {unit === "day" && value < -1 ? formatDate(insertedAt) : timeAgo}
        </Paragraph>
        <View
          style={[
            styles.messageContainer,
            isIncoming
              ? styles.incomingMessageContainer
              : styles.outgoingMessageContainer,
          ]}
        >
          <Hyperlink linkDefault linkStyle={styles.hyperlink}>
            <Paragraph size="l">{text}</Paragraph>
          </Hyperlink>
        </View>
      </View>
    </View>
  );
}

ConversationMessage.propTypes = {
  insertedAt: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  messengerName: (props) => {
    if (props.variant === "incoming" && !isString(props.messengerName)) {
      return new Error("'messengerName' must be a string");
    }
  },
  avatar: PropTypes.string,
  variant: PropTypes.oneOf(["incoming", "outgoing"]),
};
