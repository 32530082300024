import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import Slider from "@react-native-community/slider";
import moment from "moment";
import { noop } from "lodash/fp";

import { Paragraph } from "../Paragraph";

import { styles, stylingProps } from "./PlayerProgress.styles";

const VARIANT_TO_TEXT_VARIANT = {
  audio: "primary",
  video: "secondary",
};

export const PlayerProgress = ({
  onSeekStart = noop,
  positionMillis,
  durationMillis,
  variant = "audio",
  ...rest
}) => {
  const timeFormat = useMemo(() => {
    if (durationMillis > 3599000) {
      return "HH:mm:ss";
    }
    return "mm:ss";
  }, [durationMillis]);
  const timeWatched = useMemo(
    () => moment.utc(positionMillis).format(timeFormat),
    [timeFormat, positionMillis]
  );
  const timeToWatch = useMemo(
    () => moment.utc(durationMillis - positionMillis).format(timeFormat),
    [durationMillis, positionMillis, timeFormat]
  );

  if (!durationMillis) {
    return null;
  }

  return (
    <>
      <Slider
        tapToSeek={true}
        thumbSize={16}
        thumbStyle={styles.thumb}
        {...rest}
        {...stylingProps[variant]}
        style={styles.container}
        value={positionMillis}
        minimumValue={0}
        maximumValue={Math.round(durationMillis)}
        onSlidingStart={onSeekStart}
      />
      <View style={styles.labelContainer}>
        <Paragraph color={VARIANT_TO_TEXT_VARIANT[variant]}>
          {timeWatched}
        </Paragraph>
        <Paragraph color={VARIANT_TO_TEXT_VARIANT[variant]}>
          {timeToWatch}
        </Paragraph>
      </View>
    </>
  );
};

PlayerProgress.propTypes = {
  positionMillis: PropTypes.number.isRequired,
  durationMillis: PropTypes.number.isRequired,
  onSeekStart: PropTypes.func,
  variant: PropTypes.oneOf(["audio", "video"]),
};
