import React, { useEffect } from "react";
import { useQuery, useSubscription } from "@eyr-mobile/core/DataProvider";
import {
  GetInbox,
  InboxConversationsUpdatedSubscription,
  InboxDocumentsUpdatedSubscription,
  InboxAccountDigitalToolsUpdatedSubscription,
} from "@eyr-mobile/domain/Conversation";
import { useNavigation } from "@react-navigation/native";

import { getTabBarIconFn } from "../../Navigation/Navigation.helpers";
import { SVGs } from "../../res";

const Icon = getTabBarIconFn(SVGs.InboxMono);

export function InboxTabIcon(props) {
  const { data } = useQuery(GetInbox);
  const { setOptions } = useNavigation();

  useSubscription(InboxConversationsUpdatedSubscription, {
    onData: ({ data: subscriptionData, client }) =>
      client.writeQuery({
        query: GetInbox,
        data: {
          ...client.readQuery({ query: GetInbox }),
          inboxConversations: subscriptionData.data.inboxConversationsUpdated,
        },
      }),
  });
  useSubscription(InboxDocumentsUpdatedSubscription, {
    onData: ({ data: subscriptionData, client }) =>
      client.writeQuery({
        query: GetInbox,
        data: {
          ...client.readQuery({ query: GetInbox }),
          inboxDocuments: subscriptionData.data.inboxDocumentsUpdated,
        },
      }),
  });
  useSubscription(InboxAccountDigitalToolsUpdatedSubscription, {
    onData: ({ data: subscriptionData, client }) =>
      client.writeQuery({
        query: GetInbox,
        data: {
          ...client.readQuery({ query: GetInbox }),
          inboxAccountDigitalTools:
            subscriptionData.data.inboxAccountDigitalToolsUpdated,
        },
      }),
  });

  useEffect(() => {
    if (!data?.inboxConversations) {
      return;
    }

    const unseenConversationsCount = data.inboxConversations.reduce(
      (reduced, { unseenCount }) => reduced + unseenCount,
      0
    );

    const inboxUnseenCount =
      unseenConversationsCount +
      (data?.inboxDocuments.unseenCount || 0) +
      (data?.inboxAccountDigitalTools.unseenCountForms || 0) +
      (data?.inboxAccountDigitalTools.unseenCountContent || 0);
    setOptions({
      tabBarBadge: inboxUnseenCount ? inboxUnseenCount : null,
    });
  }, [data, setOptions]);

  return <Icon {...props} />;
}
