import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "ContextualDocumentsScreen.navigationTitle",
    description: "title",
    defaultMessage: "{name} documents",
  },
  uploadActionTitle: {
    id: "ContextualDocumentsScreen.uploadActionTitle",
    description: "upload documents action title",
    defaultMessage: "Upload document",
  },
  uploadedTimeTitle: {
    id: "ContextualDocumentsScreen.uploadedTimeTitle",
    description: "uploaded at date",
    defaultMessage: "Uploaded {date}",
  },
  filePickError: {
    id: "ContextualDocumentsScreen.filePickError",
    description: "error message when failure to pick document",
    defaultMessage: "Error picking a document.",
  },
  uploadFailedErrorTitle: {
    id: "ContextualDocumentsScreen.uploadFailedErrorTitle",
    description: "error message when failed to upload document",
    defaultMessage: "Upload failed",
  },
  retryUploadActionTitle: {
    id: "ContextualDocumentsScreen.retryUploadActionTitle",
    description: "retry upload action title",
    defaultMessage: "Retry",
  },
  deleteDocumentAlertTitle: {
    id: "ContextualDocumentsScreen.deleteDocumentAlertTitle",
    description: "delete document alert ",
    defaultMessage: "Are you sure you want to delete?",
  },
  deleteDocumentAlertMessage: {
    id: "ContextualDocumentsScreen.deleteDocumentAlertMessage",
    description: "delete document alert ",
    defaultMessage:
      "By deleting this document, your doctor will loose the access to the file.",
  },
  confirmDeleteActionTitle: {
    id: "ContextualDocumentsScreen.confirmDeleteActionTitle",
    description: "delete document alert ",
    defaultMessage: "Yes",
  },
  cancelDeleteActionTitle: {
    id: "ContextualDocumentsScreen.cancelDeleteActionTitle",
    description: "delete document alert ",
    defaultMessage: "No",
  },
  emptyListTitle: {
    id: "ContextualDocumentsScreen.emptyListTitle",
    description: "list is empty title",
    defaultMessage: "No documents",
  },
});
