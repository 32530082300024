import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.NUDE_20,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: Palette.NUDE_20,
    paddingVertical: 12,
    paddingHorizontal: 16,
    flexDirection: "row",
  },
  containerPressed: {
    borderColor: Palette.BURGUNDY_80,
  },
  imageContainer: { marginRight: 16 },
  imageContainerWithDescription: { marginTop: 12, marginRight: 16 },
  textContainer: { flex: 1 },
});

export const stylingProps = {
  android_ripple: {
    color: Palette.NUDE_100,
    borderless: false,
  },
};
