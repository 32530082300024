import { StyleSheet, Platform } from "react-native";
import { fontMaker } from "@eyr-mobile/core/Lib/font";

import { Palette } from "../../shared-styles/palette";

const fontStyle = {
  ...fontMaker({
    fontWeight: "400",
  }),
  // duplicating fontWeight property due to shitty internal SegmentedControlTab implementation detail
  fontWeight: "400",
  fontSize: 15,
  lintHeight: 20,
  color: Palette.BURGUNDY_60,
};

export const styles = StyleSheet.create({
  container: {
    height: 48,
    borderRadius: 8,
    backgroundColor: Palette.WHITE,
  },
});

export const stylingProps = {
  fontStyle,
  activeFontStyle: {
    ...fontStyle,
    color: Palette.BURGUNDY_100,
    ...fontMaker({
      fontWeight: "600",
    }),
    // duplicating fontWeight property due to shitty internal SegmentedControlTab implementation detail
    fontWeight: "600",
  },
  tintColor: Palette.WHITE,
  backgroundColor: Platform.select({
    ios: Palette.NUDESHADE_20,
    default: Palette.OVERLAY_DARK_4,
  }),
};
