import { createRef } from "react";
import {
  StackActions,
  getStateFromPath,
  getPathFromState,
  getActionFromState,
} from "@react-navigation/native";
/**
 * https://reactnavigation.org/docs/navigating-without-navigation-prop/
 */
export const isReadyRef = createRef();

export const navigationRef = createRef();

export function navigate(name, params) {
  if (isReadyRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current.navigate(name, params);
  }
}

export function push(...args) {
  if (isReadyRef.current && navigationRef.current) {
    navigationRef.current.dispatch(StackActions.push(...args));
  }
}

export function replace(...args) {
  if (isReadyRef.current && navigationRef.current) {
    navigationRef.current.dispatch(StackActions.replace(...args));
  }
}

export function goBack() {
  if (isReadyRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current?.goBack();
  }
}

export function resetNavigation(
  state = { index: 0, routes: [{ name: "HomeScreen" }] }
) {
  if (isReadyRef.current && navigationRef.current) {
    navigationRef.current.resetRoot(state);
  }
}

export function getCurrentNavigationOptions() {
  if (isReadyRef.current && navigationRef.current) {
    return navigationRef.current.getCurrentOptions();
  }
}

export function getCurrentNavigationRoute() {
  if (isReadyRef.current && navigationRef.current) {
    return navigationRef.current.getCurrentRoute();
  }
}

export function getNavigationRootState() {
  if (isReadyRef.current && navigationRef.current) {
    return navigationRef.current.getRootState();
  }
}

export function addNavigationListener(type, callback) {
  if (isReadyRef.current && navigationRef.current) {
    return navigationRef.current.addListener(type, callback);
  }
}

export function dispatchNavigationAction(action) {
  if (isReadyRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    navigationRef.current.dispatch(action);
  }
}

export function getRootNavigationState() {
  if (isReadyRef.current && navigationRef.current) {
    // Perform navigation if the app has mounted
    return navigationRef.current.getRootState();
  }
}

export const checkDeepLinkResult = (url, routes) => {
  const path = new URL(url).pathname;
  const currentState = navigationRef.current?.getRootState();
  const linkState = getStateFromPath(path, routes);

  if (!linkState) {
    return {
      action: null,
      linkPath: null,
      didDeepLinkLand: null,
    };
  }

  const currentPath = getPathFromState(currentState);
  const linkPath = getPathFromState(linkState);
  const action = getActionFromState(linkState);

  return {
    action,
    linkPath,
    didDeepLinkLand: currentPath === linkPath,
  };
};
