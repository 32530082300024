import { StyleSheet } from "react-native";

import { responsiveStyles } from "../../shared-styles/responsive";
import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
  footerContainer: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  footerContentContainerXS: responsiveStyles.contentContainerXS,
  footerContentContainerS: responsiveStyles.contentContainerS,
  footerContentContainerM: responsiveStyles.contentContainerM,
});
