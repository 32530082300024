import React, { useCallback, useEffect, useRef } from "react";
import { View, ScrollView, KeyboardAvoidingView } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  useReview,
  ReviewState,
  NEUTRAL_RATING,
} from "@eyr-mobile/domain/Review";
import {
  EyrButton,
  Heading,
  EyrTextInput,
  RatingInput,
  ToggleButtonGroup,
  ToggleButton,
} from "@eyr-mobile/ui/components";
import { SafeAreaView, useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";
import { size } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res";

import { styles, stylingProps } from "./ConsultationReviewScreen.styles";
import { messages } from "./ConsultationReviewScreen.messages";

export function ConsultationReviewScreen() {
  const {
    activeReview,
    activeReviewRequest,
    updateActiveReview,
    submitActiveReview,
    cancelActiveReview,
  } = useReview();
  const { addListener } = useNavigation();
  const { formatMessage } = useIntl();
  const scrollViewRef = useRef();
  const { screenSizeSelect } = useDevice();
  const insets = useSafeAreaInsets();

  useEffect(
    () => addListener("beforeRemove", cancelActiveReview),
    [cancelActiveReview, addListener]
  );

  const handleRatingChange = useCallback(
    (rating) => {
      updateActiveReview({ rating });
    },
    [updateActiveReview]
  );

  const handleChangeNotes = useCallback(
    (notes) => {
      updateActiveReview({ notes });
    },
    [updateActiveReview]
  );

  const handleSetSelectedCategories = useCallback(
    (categories) => {
      updateActiveReview({ categories });
    },
    [updateActiveReview]
  );
  const handleTextInputFocus = useCallback(
    () => scrollViewRef.current.scrollToEnd(),
    []
  );

  if (!activeReview) {
    return null;
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      {...stylingProps.keyboardAvoidingView}
    >
      <SafeAreaView
        style={styles.contentContainer}
        edges={["top", "left", "right"]}
      >
        <View style={styles.closeIconContainer}>
          <EyrButton
            size="s"
            variant="secondary"
            onPress={cancelActiveReview}
            accessibilityRole="button"
            accessibilityLabel="Close"
            IconComponent={SVGs.CloseMono}
          />
        </View>
        <ScrollView
          contentContainerStyle={screenSizeSelect({
            xs: styles.innerContainerXS,
            s: styles.innerContainerS,
            m: styles.innerContainerM,
          })}
          ref={scrollViewRef}
        >
          <Heading alignment="center" spacing="l" size="l">
            {formatMessage(messages.mainQuestion)}
          </Heading>

          <RatingInput
            rating={activeReview.rating}
            onChange={handleRatingChange}
          />
          {activeReview.rating && (
            <>
              <View style={styles.divider} />
              {Boolean(activeReview.rating <= NEUTRAL_RATING) &&
                Boolean(size(activeReviewRequest.negativeCategories)) && (
                  <View style={styles.categoriesContainer}>
                    <Heading size="s" spacing="l">
                      {formatMessage(messages.lowRatingCategoriesTitle)}
                    </Heading>
                    <ToggleButtonGroup
                      value={activeReview.categories}
                      onChange={handleSetSelectedCategories}
                    >
                      {activeReviewRequest.negativeCategories.map(
                        (category) => (
                          <ToggleButton
                            key={category.id}
                            title={category.title}
                            value={category.id}
                          />
                        )
                      )}
                    </ToggleButtonGroup>
                  </View>
                )}
              {activeReview.rating && (
                <View style={styles.notesContainer}>
                  <EyrTextInput
                    onFocus={handleTextInputFocus}
                    variant={"ghost"}
                    numberOfLines={4}
                    multiline
                    placeholder={formatMessage(messages.notesPlaceholder)}
                    onChangeText={handleChangeNotes}
                    value={activeReview.notes}
                    {...stylingProps.notesInput}
                  />
                </View>
              )}
            </>
          )}
        </ScrollView>
        {activeReview.rating && (
          <View style={styles.bottomBar}>
            <View
              style={[
                screenSizeSelect({
                  xs: styles.buttonContainerXS,
                  s: styles.buttonContainerS,
                  m: styles.buttonContainerM,
                }),
                { paddingBottom: insets.bottom },
              ]}
            >
              <EyrButton
                size="l"
                variant="primary"
                title={formatMessage(messages.submitButtonTitle)}
                onPress={submitActiveReview}
                disabled={activeReview.state !== ReviewState.REQUESTED}
              />
            </View>
          </View>
        )}
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
}

ConsultationReviewScreen.routeName = "ConsultationReviewScreen";
ConsultationReviewScreen.navigationOptions = {
  headerShown: false,
};
