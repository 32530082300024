import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { FlatList, Image, View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation, useRoute } from "@react-navigation/native";
import { getOr } from "lodash/fp";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { AuthState, useAuth } from "@eyr-mobile/domain/Auth";

import { Heading, Option } from "../../components";
import { GetIdentificationMethodsForCountry } from "../../../domain/Auth/Auth.data";

import { styles } from "./IdentificationMethodScreen.styles";
import { messages } from "./IdentificationMethodScreen.messages";

export function IdentificationMethodScreen() {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const route = useRoute();
  const { state: authState } = useAuth();
  const routeParams = route.params || {};
  const { countryCode, reidentification, returnRoute } = routeParams;
  const { refreshing, handlers, data, refetch } = withHandlers(
    useQuery(GetIdentificationMethodsForCountry, {
      context: { public: true },
      variables: { code: countryCode.toUpperCase() },
    }),
    { loadingAccessibilityLabel: "Fetching identification methods" }
  );

  const renderIdentityProvider = useCallback(
    ({ item: { name, description, id: identityProviderId } }) => (
      <View style={styles.identityProvidersListItemContainer}>
        <Option
          title={name}
          description={description}
          onPress={() =>
            navigate("IdentificationScreen", {
              identityProviderId,
              reidentification,
              returnRoute,
            })
          }
        />
      </View>
    ),
    [navigate, reidentification, returnRoute]
  );
  const handleOnPressOption = useCallback(
    () => navigate("IdentificationCountryScreen"),
    [navigate]
  );
  const keyExtractor = useCallback((item) => item.id.toString(), []);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <Heading spacing="l" alignment="center">
            {formatMessage(messages.identityProvidersListTitle)}:
          </Heading>
          <FlatList
            style={styles.identityProvidersList}
            data={getOr([], "country.identityProviders", data)}
            keyExtractor={keyExtractor}
            renderItem={renderIdentityProvider}
            refreshing={refreshing}
            onRefresh={refetch}
          />
          {authState === AuthState.UNAUTHENTICATED && (
            <>
              <Heading spacing="l" alignment="center">
                {formatMessage(messages.countryChangerTitle)}:
              </Heading>

              <Option
                title={data.country.name}
                image={
                  <Image
                    source={{ uri: data.country.flag }}
                    style={styles.countryFlag}
                  />
                }
                onPress={handleOnPressOption}
              />
            </>
          )}
        </>
      )}
    </SafeAreaView>
  );
}

IdentificationMethodScreen.routeName = "IdentificationMethodScreen";
IdentificationMethodScreen.navigationOptions = {
  title: messages.navigationTitle,
};
