import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { View, ScrollView } from "react-native";
import { noop, compact } from "lodash/fp";
import { renderIf } from "@eyr-mobile/core/Lib";
import { useAlert } from "@eyr-mobile/core/Alert";

import { EyrButton } from "../EyrButton";
import { EyrMarkdown } from "../EyrMarkdown";
import { Paragraph } from "../Paragraph";
import { SVGs } from "../../res";

import { messages } from "./Consent.messages";
import { styles } from "./Consent.styles";

export function Consent(props) {
  const {
    initiallyExpanded = false,
    onRejected = noop,
    onAccepted = noop,
    summary,
    description,
    accepted = false,
    acceptedAt,
    isRequired = false,
    category,
    policyVersion,
  } = props;

  const [expanded, setExpanded] = useState(initiallyExpanded);
  const { formatMessage, formatDate } = useIntl();
  const alert = useAlert();

  const toggleExpanded = useCallback(() => setExpanded(!expanded), [expanded]);
  const _onRejected = useCallback(() => {
    if (isRequired) {
      switch (category) {
        case "Account":
        case "OrgAccount":
          alert({
            title: formatMessage(
              accepted
                ? messages.withdrawAccountConsentAlertTitle
                : messages.declineAccountConsentAlertTitle
            ),
            message: formatMessage(
              accepted
                ? messages.withdrawAccountConsentAlertMessage
                : messages.declineAccountConsentAlertMessage
            ),
            IconComponent: SVGs.DocumentWarning,
            buttonsLayout: "column",
            buttons: [
              {
                title: formatMessage(
                  accepted
                    ? messages.withdrawAccountConsentAlertCancelActionTitle
                    : messages.declineAccountConsentAlertCancelActionTitle
                ),
                variant: "primary",
              },
              {
                title: formatMessage(
                  accepted
                    ? messages.withdrawAccountConsentAlertConfirmActionTitle
                    : messages.declineAccountConsentAlertConfirmActionTitle
                ),
                variant: "secondary",
                onPress: onRejected,
              },
            ],
          });
          break;
        // TODO: Handle withdrawal messages in Jira EYR-2681
        case "MedicalService":
          alert({
            title: formatMessage(
              accepted
                ? messages.withdrawMedicalServiceConsentAlertTitle
                : messages.declineMedicalServiceConsentAlertTitle
            ),
            message: formatMessage(
              accepted
                ? messages.withdrawMedicalServiceConsentAlertMessage
                : messages.declineMedicalServiceConsentAlertMessage
            ),
            buttonsLayout: "column",
            buttons: [
              {
                title: formatMessage(
                  accepted
                    ? messages.withdrawMedicalServiceConsentAlertConfirmActionTitle
                    : messages.declineMedicalServiceConsentAlertConfirmActionTitle
                ),
                variant: "primary",
                onPress: onRejected,
              },
              {
                title: formatMessage(
                  accepted
                    ? messages.withdrawMedicalServiceConsentAlertCancelActionTitle
                    : messages.declineMedicalServiceConsentAlertCancelActionTitle
                ),
                variant: "secondary",
              },
            ],
          });
          break;
        default:
          alert({
            title: formatMessage(messages.declineRequiredConsentAlertTitle),
            message: formatMessage(messages.declineRequiredConsentAlertMessage),
            buttonsLayout: "column",
            buttons: [
              {
                title: formatMessage(
                  messages.declineRequiredConsentAlertCancelActionTitle
                ),
                variant: "primary",
              },
              {
                title: formatMessage(
                  messages.declineRequiredConsentAlertConfirmActionTitle
                ),
                variant: "secondary",
                onPress: onRejected,
              },
            ],
          });
          break;
      }
    } else {
      onRejected();
    }
  }, [alert, category, accepted, formatMessage, isRequired, onRejected]);

  return (
    <>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <EyrMarkdown>{summary}</EyrMarkdown>
        {renderIf(description && expanded)(() => (
          <EyrMarkdown>{description}</EyrMarkdown>
        ))}
        {accepted && acceptedAt && (
          <Paragraph size="l" spacing="none" color="positive">
            {formatMessage(messages.acceptedConsentMessage, {
              policyVersion,
              acceptedAt: formatDate(acceptedAt),
            })}
          </Paragraph>
        )}
        {renderIf(description)(
          <View style={styles.expandControlContainer}>
            <EyrButton
              variant="secondary"
              iconPosition="trailing"
              IconComponent={
                expanded ? SVGs.ChevronUpMono : SVGs.ChevronDownMono
              }
              title={formatMessage(
                expanded
                  ? messages.collapseActionTitle
                  : messages.expandActionTitle
              )}
              onPress={toggleExpanded}
            />
          </View>
        )}
      </ScrollView>
      <View
        style={compact([
          styles.actionControlsContainer,
          !accepted && styles.toAcceptActionControlsContainer,
        ])}
      >
        <View style={styles.actionControlContainer}>
          <EyrButton
            variant={accepted ? "primary" : "secondary"}
            title={formatMessage(
              accepted
                ? messages.withdrawConsentActionTitle
                : messages.declineConsentActionTitle
            )}
            onPress={_onRejected}
          />
        </View>
        {!accepted && (
          <>
            <View style={styles.actionControlsSpacer} />
            <View style={styles.actionControlContainer}>
              <EyrButton
                variant={"primary"}
                title={formatMessage(messages.acceptConsentActionTitle)}
                onPress={onAccepted}
              />
            </View>
          </>
        )}
      </View>
    </>
  );
}

Consent.propTypes = {
  summary: PropTypes.string.isRequired,
  description: PropTypes.string,
  category: PropTypes.oneOf([
    "Account",
    "OrgAccount",
    "Marketing",
    "MedicalService",
  ]),
  fragment: PropTypes.oneOf(["Identity", "HealthData"]),
  initiallyExpanded: PropTypes.bool,
  onAccepted: PropTypes.func,
  onRejected: PropTypes.func,
  accepted: PropTypes.bool,
  acceptedAt: PropTypes.string,
  isRequired: PropTypes.bool,
  policyVersion: PropTypes.number,
};
