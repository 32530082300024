import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  alert: {
    paddingHorizontal: 20,
    paddingVertical: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Palette.TURQUOISE_20,
  },
  container: {
    borderRadius: 8,
    backgroundColor: Palette.WHITE,
    overflow: "hidden",
  },
  contentContainer: {
    padding: 20,
  },
  contentContainerWithSpacing: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  contentContainerWithoutSpacing: {
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1,
  },
  contentContainerColumn: {
    flexDirection: "column",
  },
  providerImageContainer: {
    marginRight: 12,
  },
  providerContainer: {
    flex: 1,
  },
  roundedBox: {
    padding: 16,
    borderRadius: 8,
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  grayBackground: {
    backgroundColor: Palette.GRAY_0,
  },
  pinkBackground: {
    backgroundColor: Palette.PINK_20,
  },
  listItem: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 12,
    alignItems: "center",
  },
  separator: {
    height: 1,
    backgroundColor: Palette.GRAY_20,
  },
  table: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  leftColumn: {
    width: "70%",
  },
  rightColumn: {
    marginLeft: 8,
  },
});

export const stylingProps = {
  listItemChevron: {
    fill: Palette.BURGUNDY_100,
    width: 22,
    height: 22,
  },
  dismissActionTitle: {
    hitSlop: 8,
  },
};

export const stylingHelpers = {
  getAlertStyle(isGrayscale) {
    return [styles.alert, isGrayscale && styles.grayBackground];
  },
  getAlertTitleProps(isGrayscale) {
    return { color: isGrayscale ? "dark" : "info", size: "l" };
  },
  getInfoContentContainerStyle(isGrayscale, informationList) {
    return [
      styles.roundedBox,
      ...(informationList.length ? [styles.table] : []),
      ...(isGrayscale ? [styles.grayBackground] : []),
    ];
  },
};
