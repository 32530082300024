import React, { useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { WebView } from "react-native-webview";
import { Platform } from "react-native";
import { LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  patchPostMessageInjectedJavaScript,
  disableScalingInjectedJavaScript,
  applicationNameForUserAgent,
  safeHandlePostMessage,
} from "@eyr-mobile/core/WebView";
import {
  getAuthorizationBearerHeader,
  injectWebWebViewAuth,
} from "@eyr-mobile/domain/Auth";
import { getAdditionalHeaders, getWebViewURL } from "@eyr-mobile/core/Net";

const logger = LoggerFactory.get("components/FillForm");

const injectedJavaScript =
  disableScalingInjectedJavaScript + patchPostMessageInjectedJavaScript;

export function FillForm({ url, accessToken, onSucceeded, onFailed, ...rest }) {
  const { locale } = useIntl();
  const _ref = useRef();
  // returning formDataId as a String. Explicitly converting it to a number
  // As for react-native-webview 10.3.2 WebView has a limitation: data must be a string.
  // https://github.com/react-native-community/react-native-webview/blob/master/docs/Reference.md#onmessage
  const onMessage = useCallback(
    ({ nativeEvent }) => {
      safeHandlePostMessage(nativeEvent, logger, (data) => {
        const { status, id, ...rest } = JSON.parse(data);
        switch (status) {
          case "ok":
            onSucceeded(Number(id));
            break;
          case "error":
            onFailed(rest);
            break;
          default:
        }
      });
    },
    [onSucceeded, onFailed]
  );

  return (
    <WebView
      key={locale}
      ref={_ref}
      source={Platform.select({
        default: {
          headers: {
            ...getAdditionalHeaders(),
            ...getAuthorizationBearerHeader(accessToken),
          },
          uri: url,
        },
        web: {
          uri: injectWebWebViewAuth(url, accessToken),
        },
      })}
      injectedJavaScript={injectedJavaScript}
      onMessage={onMessage}
      postMessageTarget={"window"}
      applicationNameForUserAgent={applicationNameForUserAgent}
      originWhitelist={[getWebViewURL().origin]}
      testID={"FillForm"}
      {...rest}
      web_postMessageTarget={"window"}
    />
  );
}

FillForm.propTypes = {
  url: PropTypes.string.isRequired,
  accessToken: PropTypes.string.isRequired,
  onSucceeded: PropTypes.func,
  onFailed: PropTypes.func,
};
