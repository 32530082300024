import React, { useEffect } from "react";
import {
  View,
  KeyboardAvoidingView,
  Keyboard,
  Pressable,
  Platform,
} from "react-native";
import { noop, compact, getOr } from "lodash/fp";
import { SafeAreaView, useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  ProductType,
  useOrderFlowStageParams,
  useSetMedicalCategoryFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";
import { useNavigation } from "@react-navigation/native";

import {
  CancelOrderHeaderRight,
  EyrButton,
  EyrTextInput,
  Paragraph,
  ProgressIndicator,
} from "../../components";

import {
  styles,
  stylingProps,
} from "./FlowStageSetMedicalCategory_DescribeSymptomsScreen.styles";
import { messages } from "./FlowStageSetMedicalCategory_DescribeSymptomsScreen.messages";

const FREE_FORM_SYMPTOMS_INPUT_MAX_LENGTH = 1000;
const FREE_FORM_SYMPTOMS_INPUT_NUMBER_OF_LINES = 10;

function getNavigationTitleForProductType(productType) {
  switch (productType) {
    case ProductType.PSYCHOLOGIST_CONSULTATION:
      return messages.navigationTitlePsychology;
    case ProductType.GUIDE_CONSULTATION:
      return messages.navigationTitlePatientGuide;
    default:
      return messages.navigationTitle;
  }
}

export function FlowStageSetMedicalCategory_DescribeSymptomsScreen() {
  const insets = useSafeAreaInsets();
  const { formatMessage } = useIntl();
  const { screenSizeSelect } = useDevice();
  const { setOptions } = useNavigation();

  const {
    stageParams: {
      progress,
      progressPerStage,
      product: { type: productType } = {},
    } = {},
    handlers,
  } = useOrderFlowStageParams();
  const progressWithinSubStages = progress + (1 / 2) * progressPerStage;
  const [{ freeFormSymptoms, setFreeFormSymptoms, saveFreeFormSymptoms }] =
    useSetMedicalCategoryFlowStage();

  useEffect(() => {
    if (!productType) {
      return;
    }
    setOptions({
      title: formatMessage(getNavigationTitleForProductType(productType)),
    });
  }, [formatMessage, productType, setOptions]);

  return (
    <KeyboardAvoidingView
      style={styles.container}
      {...stylingProps.keyboardAvoidingView}
    >
      <SafeAreaView style={styles.container} edges={["left", "right"]}>
        {handlers || (
          <>
            <ProgressIndicator
              value={progressWithinSubStages}
              accessibilityLabel={`Order progress indicator ${
                progressWithinSubStages * 100
              }%`}
            />
            <Pressable
              style={[
                styles.contentContainer,
                screenSizeSelect({
                  xs: styles.contentContainerXS,
                  s: styles.contentContainerS,
                  m: styles.contentContainerM,
                }),
              ]}
              onPress={Platform.select({
                default: Keyboard.dismiss,
                web: noop,
              })}
            >
              <View style={styles.inputContainer}>
                <EyrTextInput
                  numberOfLines={FREE_FORM_SYMPTOMS_INPUT_NUMBER_OF_LINES}
                  multiline
                  {...stylingProps.textInput}
                  maxLength={FREE_FORM_SYMPTOMS_INPUT_MAX_LENGTH}
                  placeholder={formatMessage(
                    getOr(
                      messages.default_symptomsInputPlaceholderText,
                      `${productType}_symptomsInputPlaceholderText`,
                      messages
                    )
                  )}
                  onChangeText={setFreeFormSymptoms}
                  value={freeFormSymptoms}
                />
                <Paragraph
                  color="disabled"
                  size="m"
                >{`${freeFormSymptoms.length}/${FREE_FORM_SYMPTOMS_INPUT_MAX_LENGTH}`}</Paragraph>
              </View>
            </Pressable>
            <View style={styles.bottomBar}>
              <View
                style={compact([
                  styles.finishStageButtonContainer,
                  screenSizeSelect({
                    s: styles.finishStageButtonContainerS,
                    xs: styles.finishStageButtonContainerXS,
                    m: styles.finishStageButtonContainerM,
                  }),
                  { paddingBottom: insets.bottom },
                ])}
              >
                <EyrButton
                  size={"l"}
                  title={formatMessage(messages.finishStageButtonTitle)}
                  onPress={saveFreeFormSymptoms}
                />
              </View>
            </View>
          </>
        )}
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
}

FlowStageSetMedicalCategory_DescribeSymptomsScreen.routeName =
  "FlowStageSetMedicalCategory_DescribeSymptomsScreen";

FlowStageSetMedicalCategory_DescribeSymptomsScreen.navigationOptions = {
  headerRight: CancelOrderHeaderRight,
};
