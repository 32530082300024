import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export const stylingProps = {
  logo: {
    width: 100,
    height: 45,
  },
};
