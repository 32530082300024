import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  noDataContentContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  listItemContainer: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    height: 64,
    borderRadius: 8,
    backgroundColor: Palette.WHITE,
    ...Shadows.xs,
    marginBottom: 16,
  },
  listItemContentContainer: {
    height: "100%",
    justifyContent: "center",
  },
});
