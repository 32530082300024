import { defineMessages } from "react-intl";

export const messages = defineMessages({
  introduction: {
    id: "ExtraInfoForPhysiotherapistConsultation.introduction",
    description: "Introduction",
    defaultMessage:
      "The treatment plan includes consultations with a physiotherapist and access to personalised exercise programs through the Injurymap app. Our experienced physiotherapists can effectively treat 9 out of 10 injuries. Start your pain-free journey today!",
  },
  contentTitle: {
    id: "ConsultationPreparationInfo.contentTitle",
    description: "Content title",
    defaultMessage: "Treatment plan",
  },
  productInfoSection1Title: {
    id: "ConsultationPreparationInfo.productInfoSection1Title",
    description: "Product information title",
    defaultMessage: "Get started",
  },
  productInfoSection1ConsultationDuration: {
    id: "ConsultationPreparationInfo.productInfoSection1ConsultationDuration",
    description: "Product information duration",
    defaultMessage: "{duration} initial consultation over video",
  },
  productInfoSection1TreatmentDescription: {
    id: "ConsultationPreparationInfo.productInfoSection1TreatmentDescription",
    description: "Product information treatment description",
    defaultMessage: "Diagnosis & treatment goals",
  },
  productInfoSection1AppAccessDuration: {
    id: "ConsultationPreparationInfo.productInfoSection1AppAccessDuration",
    description: "Product information app access duration",
    defaultMessage:
      "{duration} of access to an app with your personalised exercise program",
  },
  productInfoSection2Title: {
    id: "ConsultationPreparationInfo.productInfoSection2Title",
    description: "Product information title",
    defaultMessage: "Exercises and follow-up",
  },
  productInfoSection2ConsultationDuration: {
    id: "ConsultationPreparationInfo.productInfoSection2ConsultationDuration",
    description: "Product information duration",
    defaultMessage: "{duration} follow-up consultation over video",
  },
  productInfoSection2TreatmentDescription: {
    id: "ConsultationPreparationInfo.productInfoSection2TreatmentDescription",
    description: "Product information treatment description",
    defaultMessage: "Treatment optimization",
  },
  productInfoSection2ChatAccess: {
    id: "ConsultationPreparationInfo.productInfoSection2ChatAccess",
    description: "Product information chat access",
    defaultMessage: "Chat with your physiotherapist",
  },
  productInfoSection2AppAccessDuration: {
    id: "ConsultationPreparationInfo.productInfoSection2AppAccessDuration",
    description: "Product information app access duration",
    defaultMessage:
      "Free access to an app with your personalised exercise program",
  },
  partnerInfo: {
    id: "ConsultationPreparationInfo.partnerInfo",
    description: "Partner information",
    defaultMessage: "Digital physiotherapy",
  },
  partnerInfo2: {
    id: "ConsultationPreparationInfo.partnerInfo2",
    description: "Partner information",
    defaultMessage:
      "Physiotherapy is offered in collaboration with Injurymap, a leading digital physiotherapy service in the Nordics. The Injurymap app offers a dynamic exercise program that continuously adapts to your specific needs. Consultations are booked through the Eyr app. If necessary, the physiotherapist can refer you to private or public physical treatment.",
  },
});
