import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  contentContainer: {
    padding: 24,
  },
  expandControlContainer: {
    marginTop: 24,
  },
  actionControlsContainer: {
    flexDirection: "row",
    paddingBottom: 16,
    paddingHorizontal: 24,
  },
  toAcceptActionControlsContainer: {
    borderTopWidth: 1,
    borderTopColor: Palette.GRAY_0,
    paddingTop: 16,
  },
  actionControlsSpacer: { width: 16 },
  actionControlContainer: {
    flex: 1,
  },
});
