import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "HelpAndContactScreen.navigationTitle",
    description: "HelpAndContactScreen navigation title",
    defaultMessage: "Help & contact",
  },
  headerText: {
    id: "HelpAndContactScreen.headerText",
    description: "Main text on the screen",
    defaultMessage:
      "Our Support team can help you with bookings, refunds and general questions.",
  },
  emphasizedParagraph: {
    id: "HelpAndContactScreen.emphasizedParagraph",
    description: "emphasized paragraph in the header",
    defaultMessage:
      "Please do not share personal medical information when contacting Support.",
  },
  openingHoursTitle: {
    id: "HelpAndContactScreen.openingHoursTitle",
    description: "title for opening hours section",
    defaultMessage: "Opening hours",
  },
  range: {
    id: "HelpAndContactScreen.range",
    description: "Temporary thing before formatRange is fixed",
    defaultMessage: "{start} — {end}",
  },
  weekendAndHolidays: {
    id: "HelpAndContactScreen.weekendAndHolidays",
    description: "Weekends and holidays at Eyr",
    defaultMessage: "{start} — {end} and holidays",
  },
  emailCardTitle: {
    id: "HelpAndContactScreen.emailCardTitle",
    description: "tile for the email card",
    defaultMessage: "Send an email",
  },
  phoneCardTitle: {
    id: "HelpAndContactScreen.phoneCardTitle",
    description: "title on the phone card",
    defaultMessage: "Call Support",
  },
  faqCardTitle: {
    id: "HelpAndContactScreen.faqCardTitle",
    description: "FAQ card title",
    defaultMessage: "FAQ and Chat",
  },
  faqDescription: {
    id: "HelpAndContactScreen.faqDescription",
    description: "FAQ description message",
    defaultMessage:
      "Read answers to frequently asked questions and chat with Support",
  },
});
