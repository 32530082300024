import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { Platform } from "react-native";
import { isEmulator } from "react-native-device-info";
import { has } from "lodash/fp";
import { Context as RawDeviceContext, useMediaQuery } from "react-responsive";
import * as Device from "expo-device";

const DeviceType = Device.DeviceType;

import { LOGGER_LEVEL_ERROR, LoggerFactory } from "../Logger";

export const isMobileWeb = () => {
  if (!Platform.OS === "web") {
    return false;
  }
  return navigator.userAgent.match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  );
};

export const isDesktopWeb = () => Platform.OS === "web" && !isMobileWeb();

const logger = LoggerFactory.get("core/Device");

export const breakpoints = {
  xs: 0,
  s: 600,
  m: 900,
  // l: 1200,
  // xl: 1536,
};

export const ScreenSizes = {
  XS: "xs",
  S: "s",
  M: "m",
};
export * from "expo-device";

function getScreenSizeName({ xs, s, m }) {
  switch (true) {
    case m:
      return ScreenSizes.M;
    case s:
      return ScreenSizes.S;
    case xs:
      return ScreenSizes.XS;
    default:
      return "undetermined";
  }
}
function getDeviceTypeName(deviceType) {
  switch (deviceType) {
    case DeviceType.PHONE:
      return "phone";
    case DeviceType.TABLET:
      return "tablet";
    case DeviceType.DESKTOP:
      return "desktop";
    default:
      return DeviceType.UNKNOWN;
  }
}
const deviceStateDefaults = {};

export const DeviceContext = createContext(deviceStateDefaults);

function RawDeviceProvider({ children }) {
  const xs = useMediaQuery({ minWidth: breakpoints.xs });
  const s = useMediaQuery({ minWidth: breakpoints.s });
  const m = useMediaQuery({ minWidth: breakpoints.m });

  const screenSize = getScreenSizeName({ xs, s, m });

  const screenSizeSelect = useCallback(
    (values) => {
      const { xs: _xs, s: _s, m: _m } = values;
      switch (true) {
        case m && has("m", values):
          return _m;
        case s && has("s", values):
          return _s;
        case xs && has("xs", values):
          return _xs;
        default:
          return;
      }
    },
    [m, s, xs]
  );
  const deviceContext = useContext(RawDeviceContext) || {};
  const contextDeviceType = deviceContext.deviceType;
  const [deviceType, setDeviceType] = useState(
    contextDeviceType || DeviceType.UNKNOWN
  );
  useEffect(() => {
    if (contextDeviceType) {
      return;
    }
    Device.getDeviceTypeAsync().then(setDeviceType);
  }, [contextDeviceType]);
  const deviceTypeSelect = useCallback(
    ({ phone, tablet, desktop } = {}) => {
      switch (deviceType) {
        case DeviceType.PHONE:
          return phone;
        case DeviceType.TABLET:
          return tablet || phone;
        case DeviceType.DESKTOP:
          return desktop || tablet || phone;
        default:
          return;
      }
    },
    [deviceType]
  );

  useEffect(() => {
    if (deviceType === DeviceType.UNKNOWN) {
      return;
    }
    logger("deviceType:", getDeviceTypeName(deviceType));
  }, [deviceType]);

  useEffect(() => {
    logger("screenSize:", screenSize);
  }, [screenSize]);
  return (
    <DeviceContext.Provider
      value={{
        ...Device,
        ...deviceContext,
        screenSize,
        isScreenSizeXS: screenSize === ScreenSizes.XS,
        isScreenSizeS: screenSize === ScreenSizes.S,
        isScreenSizeM: screenSize === ScreenSizes.M,
        screenSizeSelect,
        deviceType,
        isPhone: deviceType === DeviceType.PHONE,
        isTablet: deviceType === DeviceType.TABLET,
        isDesktop: deviceType === DeviceType.DESKTOP,
        deviceTypeSelect,
      }}
    >
      {children}
    </DeviceContext.Provider>
  );
}

RawDeviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function DeviceProvider({ children, initialState }) {
  return (
    <RawDeviceContext.Provider value={initialState}>
      <RawDeviceProvider>{children}</RawDeviceProvider>
    </RawDeviceContext.Provider>
  );
}

DeviceProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
};

export function useDevice() {
  const device = useContext(DeviceContext);
  if (device === undefined) {
    logger(
      "useDevice: Couldn't find a device. Is your component inside an DeviceProvider?",
      device,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return device;
}

export { isEmulator };
