import React, { useState, useCallback, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { SectionList, View, FlatList } from "react-native";
import {
  GetContentPrograms,
  GetContent,
  MarkArticleAsRead,
  ContentUpdated,
} from "@eyr-mobile/domain/DigitalTool";
import {
  CardInner,
  ListItemSeparator,
  IconContainer,
  Heading,
  Accordion,
  Badge,
  Content,
  Loading,
} from "@eyr-mobile/ui/components";
import { ContentIconByType } from "@eyr-mobile/ui/components/Content";
import {
  useQuery,
  withHandlers,
  useMutation,
  useLazyQuery,
  useSubscription,
} from "@eyr-mobile/core/DataProvider";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { curry, map, upperFirst, camelCase } from "lodash/fp";
import { useIntl, RawIntlProvider } from "@eyr-mobile/core/Intl";
import { useDevice } from "@eyr-mobile/core/Device";
import { useAlert } from "@eyr-mobile/core/Alert";
import { show as showToast } from "@eyr-mobile/core/Toast";

import { styles } from "./ContentProgramsScreen.styles";
import { messages } from "./ContentProgramsScreen.messages";

const mapProgramsToSections = curry(
  map(({ externalId, name, unseenCount, modules }) => ({
    key: externalId,
    name,
    unseenCount,
    data: modules.map((module) => ({
      ...module,
      content: module.content.map((content) => ({
        ...content,
        programExternalId: externalId,
      })),
    })),
  }))
);

const initialContentState = {
  externalId: null,
  hasFetched: false,
  content: null,
};

function WrappedLoading({ style, ...rest }) {
  return (
    <View style={style}>
      <Loading {...rest} />
    </View>
  );
}

WrappedLoading.propTypes = {
  style: PropTypes.object,
};

export function ContentProgramsScreen() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const { screenSizeSelect } = useDevice();
  const [
    { externalId, programExternalId, hasFetched, content },
    setContentState,
  ] = useState(initialContentState);
  const alert = useAlert();

  const [markArticleAsReadMutation] = useMutation(MarkArticleAsRead);

  const { handlers, data, refetch, refreshing } = withHandlers(
    useQuery(GetContentPrograms)
  );

  const [getContent] = useLazyQuery(GetContent);

  useSubscription(ContentUpdated);

  const programs = useMemo(
    () => (data ? mapProgramsToSections(data.contentForUserV2) : []),
    [data]
  );

  useEffect(() => {
    if (!externalId || hasFetched) {
      return;
    }

    setContentState((contentState) => ({
      ...contentState,
      hasFetched: true,
    }));

    getContent({
      variables: { externalId, programExternalId },
      onCompleted: ({ content }) => {
        setContentState((contentState) => ({
          ...contentState,
          content,
        }));
        markArticleAsReadMutation({ variables: { externalId } });
      },
      onError: (error) => {
        showToast(formatMessage(messages.contentLoadError));
        setContentState(initialContentState);
      },
    });
  }, [
    externalId,
    programExternalId,
    getContent,
    hasFetched,
    markArticleAsReadMutation,
    formatMessage,
  ]);

  useEffect(() => {
    const commonAlertOptions = {
      variant: "fullscreen",
      showCloseButton: true,
      size: "l",
      onClose: () => {
        setContentState(initialContentState);
      },
    };
    if (externalId && !content) {
      alert({
        ...commonAlertOptions,
        children: (
          <View style={styles.loadingContainer}>
            <Loading />
          </View>
        ),
      });
    } else if (content) {
      alert({
        ...commonAlertOptions,
        children: (
          <RawIntlProvider value={intl}>
            <SafeAreaView edges={["top", "bottom", "left", "right"]}>
              <Content content={content} />
            </SafeAreaView>
          </RawIntlProvider>
        ),
      });
    }
  }, [content, alert, intl, externalId, screenSizeSelect]);

  const renderContentListItem = useCallback(
    ({
      item: {
        title,
        seen,
        externalId: itemExternalId,
        programExternalId: itemProgramExternalId,
        __typename,
        kind,
      },
    }) => (
      <View style={styles.listItemContainer}>
        <CardInner
          onPress={() => {
            setContentState({
              externalId: itemExternalId,
              programExternalId: itemProgramExternalId,
            });
          }}
          icon={
            <IconContainer size="xs" variant="plain">
              {ContentIconByType[__typename]}
            </IconContainer>
          }
          title={title}
          description={formatMessage(
            messages[`kind${upperFirst(camelCase(kind))}`]
          )}
          titleIsBold={!seen}
          badge={!seen && <Badge variant="primary" />}
        />
      </View>
    ),
    [formatMessage]
  );

  const renderProgramHeader = useCallback(({ section: { name } }) => {
    return (
      <View style={styles.sectionTitleContainer}>
        <Heading spacing="m" size="s">
          {name}
        </Heading>
      </View>
    );
  }, []);

  const keyExtractor = useCallback(
    ({ externalId }) => externalId.toString(),
    []
  );

  const renderModule = useCallback(
    ({ item: { content, name, unseenCount }, index }) => {
      const title = formatMessage(messages.moduleNumberTitle, {
        count: index + 1,
      });
      return (
        <View style={styles.moduleContainer}>
          <Accordion
            title={title}
            header={
              <View style={styles.accordionHeaderContainer}>
                <CardInner
                  pressable={false}
                  title={title}
                  titleIsBold
                  description={name}
                  showInteractivityIcon={false}
                  badge={
                    Boolean(unseenCount) && (
                      <Badge label={unseenCount} variant="primary" />
                    )
                  }
                />
              </View>
            }
          >
            <FlatList
              stickySectionHeadersEnabled={false}
              data={content}
              renderItem={renderContentListItem}
              keyExtractor={keyExtractor}
              ItemSeparatorComponent={ListItemSeparator}
              refreshing={refreshing}
              onRefresh={refetch}
              contentContainerStyle={styles.listContentContainer}
              initialNumToRender={100}
            />
          </Accordion>
        </View>
      );
    },
    [refetch, refreshing, renderContentListItem, keyExtractor, formatMessage]
  );

  return (
    <>
      <SafeAreaView style={styles.container} edges={["left", "right"]}>
        {handlers || (
          <SectionList
            stickySectionHeadersEnabled={false}
            sections={programs}
            renderItem={renderModule}
            renderSectionHeader={renderProgramHeader}
            keyExtractor={keyExtractor}
            ItemSeparatorComponent={ListItemSeparator}
            refreshing={refreshing}
            onRefresh={refetch}
            automaticallyAdjustsScrollIndicatorInsets={false}
            contentContainerStyle={[
              styles.listContentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
            initialNumToRender={100}
          />
        )}
      </SafeAreaView>
    </>
  );
}

ContentProgramsScreen.routeName = "ContentProgramsScreen";
ContentProgramsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
