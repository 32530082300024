import { defineMessages } from "react-intl";

export const serviceProviderTitleMessages = defineMessages({
  serviceProviderTitleFor_CONSULTATION: {
    id: "ServiceProviderTitleFor.CONSULTATION",
    description: "an umbrella term for people with a PHD",
    defaultMessage: "{amount, plural, one {doctor} other {doctors}}",
  },
  serviceProviderTitleFor_PSYCHOLOGIST_CONSULTATION: {
    id: "ServiceProviderTitleFor.PSYCHOLOGIST_CONSULTATION",
    description: "a non medical licenced health care specialist",
    defaultMessage:
      "{amount, plural, one {psychologist} other {psychologists}}",
  },
  serviceProviderTitleFor_GUIDE_CONSULTATION: {
    id: "ServiceProviderTitleFor.GUIDE_CONSULTATION",
    description: "an umbrella term for people with a PHD",
    defaultMessage: "{amount, plural, one {doctor} other {doctors}}",
  },
  serviceProviderTitleFor_PHYSIOTHERAPIST_CONSULTATION: {
    id: "ServiceProviderTitleFor.PHYSIOTHERAPIST_CONSULTATION",
    description: "an umbrella term for people with a PHD",
    defaultMessage:
      "{amount, plural, one {physiotherapist} other {physiotherapists}}",
  },
  serviceProviderTitleFor_HEALTHCARE_PROVIDER: {
    id: "ServiceProviderTitleFor.HEALTHCARE_PROVIDER",
    description:
      "an umbrella term for people who provide services in health care",
    defaultMessage:
      "{amount, plural, one {healthcare provider} other {healthcare providers}}",
  },
  serviceProviderTitleFor_HEALTHCARE_PROVIDER_infinitive: {
    id: "ServiceProviderTitleFor.HEALTHCARE_PROVIDER.infinitive",
    description:
      "an umbrella infinitive term for people who provide services in health care",
    defaultMessage:
      "{amount, plural, one {the healthcare provider} other {the healthcare providers}}",
  },
});
