import React from "react";
import PropTypes from "prop-types";
import { Pressable, View } from "react-native";

import { Subtitle } from "../Subtitle";
import { Paragraph } from "../Paragraph";
import { renderIf } from "../../../core/Lib/render";

import { styles, stylingProps } from "./Option.styles";

export function Option(props) {
  const { title, description, image, ...rest } = props;
  return (
    <Pressable
      {...stylingProps}
      style={({ pressed }) => [
        styles.container,
        pressed && styles.containerPressed,
      ]}
      {...rest}
    >
      {renderIf(Boolean(image && !description))(
        <View style={styles.imageContainer}>{image}</View>
      )}
      {renderIf(Boolean(image && description))(
        <View style={styles.imageContainerWithDescription}>{image}</View>
      )}
      <View style={styles.textContainer}>
        <Subtitle size="l" color="primary">
          {title}
        </Subtitle>
        {renderIf(description)(() => (
          <Paragraph>{description}</Paragraph>
        ))}
      </View>
    </Pressable>
  );
}

Option.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.any,
};
