import { Platform, StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  headerRightContainer: {
    borderRadius: 22,
    padding: 8,
    overflow: "hidden",
  },
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 16,
    paddingVertical: 40,
  },
  pinCodeContainer: { flex: 1, justifyContent: "center" },
  textContainer: { marginBottom: 16 },
});

export const stylingProps = {
  keyboardAvoidingView: {
    ...Platform.select({
      ios: {
        keyboardVerticalOffset: 56,
        behavior: "padding",
      },
    }),
  },
  headerRightStylingProps: {
    android_ripple: {
      color: Palette.NUDE_100,
      borderless: true,
    },
    hitSlop: 8,
  },
};
