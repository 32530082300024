import React from "react";
import { renderIf } from "@eyr-mobile/core/Lib";

import { catalogItemPropTypes } from "../catalogItemPropTypes";
import { ListItem } from "../ListItem";
import { moneyShape } from "../../../domain/Order";
import { Money } from "../Money";
import { Paragraph } from "../Paragraph";
import { IconContainer } from "../IconContainer";
import { ResAwareSvg } from "../ResAwareSvg";

export function ListCatalogItem(props) {
  const { image, title, price, ...rest } = props;

  return (
    <ListItem layout={"2-9-3-1"} {...rest}>
      <IconContainer size="xs" variant="plain">
        <ResAwareSvg svg={image} />
      </IconContainer>
      <Paragraph size="l" numberOfLines={2}>
        {title}
      </Paragraph>
      {renderIf(price)(() => (
        <Money money={price} />
      ))}
      <ListItem.Arrow />
    </ListItem>
  );
}

ListCatalogItem.propTypes = {
  ...catalogItemPropTypes,
  price: moneyShape,
};
