import { defineMessages } from "react-intl";

export const messages = defineMessages({
  missedCallNotificationTitle: {
    id: "Notifications.missedCallNotificationTitle",
    description: "Missed call notification title",
    defaultMessage: "Missed call",
  },
  missedCallNotificationBody: {
    id: "Notifications.missedCallNotificationBody",
    description: "Missed call notification body",
    defaultMessage:
      "You have a missed call from Eyr. Please make sure you are logged in to the Eyr app, and the practitioner will call you again in a few minutes.",
  },
});
