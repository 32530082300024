import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { baseLineHeight } from "../../shared-styles/font";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    paddingVertical: (baseLineHeight * 2) / 3,
  },
  center: {
    alignItems: "center",
  },
  multilineMargin: {
    marginTop: baseLineHeight / 2,
    justifyContent: "flex-start",
  },
  delete: {
    paddingRight: 24,
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
});

export const gridSize = 15;

const centered = (size) => ({ size, style: styles.center });
const righted = (size) => ({ size, style: styles.right });

export const layouts = {
  1: [centered(1)],
  "2-13": [centered(2), { size: 13 }],
  "3-12": [centered(3), { size: 12 }],
  "14-1": [{ size: 14 }, centered(1)],
  "2-12-1": [centered(2), { size: 12 }, centered(1)],
  "2-9-3-1": [centered(2), { size: 9 }, { size: 3 }, centered(1)],
  "7-8": [{ size: 7 }, righted(8)],
};

export const stylingProps = {
  delete: {
    fill: Palette.RED_100,
  },
  chevronIcon: {
    fill: Palette.GRAY_100,
  },
};
