/*
  This lib was done to overcome platforms differences on fonts usage
  iOS: supports fontWeight, fontStyle, fontFamily, but has weird font names interpretation that differs from font
   filename (e.g. OpenSans.ext file name maps to "Open Sans" font name)
  android: doesn't support fontWeight, fontStyle, but aggregates them in fontFamily property (e.g. OpenSans-BoldItalic)

  Default font is Euclid Circular B.
  Feel free to map extra fonts to their actual file names;

  example of usage:
  StyleSheet.create({
    myComponentStyle: {
      ...fontMaker({
        fontStyle: "italic",
        fontWeight: "700"
      })
    }
  });
 */
import { Platform, PixelRatio } from "react-native";

export const Fonts = {
  FONT_FAMILY_EUCLID_CIRCULAR_B: Platform.select({
    android: "EuclidCircularB",
    default: "Euclid Circular B",
  }),
  FONT_FAMILY_BUENOS_AIRES: Platform.select({
    android: "BuenosAires",
    default: "Buenos Aires",
  }),
};

export const supportedFontConfigurations = {
  [Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B]: {
    weights: {
      300: "Light",
      normal: "Regular",
      400: "Regular",
      500: "Medium",
      600: "Semibold",
      bold: "Bold",
      700: "Bold",
    },
  },
  [Fonts.FONT_FAMILY_BUENOS_AIRES]: {
    weights: {
      bold: "Bold",
      700: "Bold",
    },
  },
};

export const isFontWeightValid = (fontFamily, fontWeight) => {
  return !!supportedFontConfigurations[Fonts[fontFamily]].weights[fontWeight];
};

export const getValidFontWeights = (fontFamily) => {
  return Object.keys(supportedFontConfigurations[Fonts[fontFamily]].weights);
};

const defaultFontFamily = Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B;

export const isFontScaleLarge = () => PixelRatio.getFontScale() >= 2;

export const fontMaker = (options = {}) => {
  const { fontWeight, fontStyle, fontFamily, backgroundColor } = {
    fontWeight: null,
    fontStyle: null,
    fontFamily: defaultFontFamily,
    backgroundColor: "transparent",
    ...options,
  };

  const { weights = {}, styles = {} } = supportedFontConfigurations[fontFamily];

  if (Platform.OS === "android") {
    const weight = weights[fontWeight] || "Regular";
    const style = styles[fontStyle] || "";

    const suffix = weight + style;

    return {
      fontFamily: fontFamily + (suffix.length ? `-${suffix}` : ""),
      backgroundColor,
    };
  } else {
    return {
      fontFamily,
      fontWeight: weights[fontWeight] ? fontWeight : "normal",
      fontStyle: styles[fontStyle] ? fontStyle : "normal",
      backgroundColor,
    };
  }
};
