import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const { WHITE } = Palette;

export const styles = StyleSheet.create({
  listItem: {
    backgroundColor: WHITE,
  },
  listItemInner: {
    alignItems: "center",
    justifyContent: "space-between",
    alignContent: "center",
    paddingHorizontal: 16,
    flexDirection: "row",
    width: "100%",
  },
  listItemIcon: {
    marginRight: 16,
  },
  listItemChevron: {
    marginLeft: 16,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  listSectionFooter: {
    height: 24,
  },
  footerContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    height: 86,
  },
});

export const stylingProps = {
  icon: {
    width: 24,
    height: 24,
  },
};
