import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  formatNextTwoDaysAsRelative_DateOtherwise,
  useInterval,
  isDelayed,
} from "@eyr-mobile/core/Lib";
import { noop, upperFirst, get, getOr, includes } from "lodash/fp";

import { ServiceCard } from "../ServiceCard";

import { messages } from "./AppointmentConsultationServiceCard.messages";

const UPDATE_INTERVAL = 30000;
const getProductType = get("product.type");

export function AppointmentConsultationServiceCard({
  summary,
  state,
  provider,
  notice,
  digitalTool,
  date,
  onPreparationNoticePress = noop,
  onDismissPress = noop,
  onDigitalToolSelected = noop,
  testOnly_initialProvider,
  testOnly_nowOverride,
  onCancellationInfoPress = noop,
  order,
}) {
  const intl = useIntl();
  const { formatMessage, formatDate, formatTime } = intl;

  const {
    image: providerImage,
    name: providerName,
    description: providerDescription,
  } = provider;
  const [now, setNow] = useState(
    testOnly_nowOverride ? new Date(testOnly_nowOverride).getTime() : Date.now()
  );
  useInterval(() => setNow(now + UPDATE_INTERVAL), UPDATE_INTERVAL, false);
  const [initialProvider] = useState(testOnly_initialProvider || provider);

  const productType = useMemo(() => getProductType(order), [order]);

  const formattedDate = useMemo(
    () =>
      upperFirst(
        formatNextTwoDaysAsRelative_DateOtherwise({
          date,
          formatDateOptions: {
            weekday: "long",
            day: "numeric",
            month: "long",
          },
          intl,
          nowOverride: now,
        })
      ),
    [date, intl, now]
  );

  const handleDigitalToolSelected = useCallback(
    () => onDigitalToolSelected(digitalTool),
    [digitalTool, onDigitalToolSelected]
  );

  const delayed = useMemo(() => isDelayed({ date, now }), [now, date]);
  const reassigned = useMemo(
    () => initialProvider?.id !== provider?.id,
    [initialProvider, provider]
  );

  const alertTitle =
    (delayed
      ? formatMessage(messages.delayedHeader, {
          serviceProviderTitle: formatMessage(
            getOr(
              messages.serviceProviderTitleFor_HEALTHCARE_PROVIDER,
              `serviceProviderTitleFor_${productType}`,
              messages
            ),
            {
              amount: 1,
            }
          ),
        })
      : "") ||
    (reassigned
      ? formatMessage(messages.reassignedHeader, { providerName })
      : "");

  const textColor = state === "refunded" ? "dark" : "primary";

  switch (state) {
    case "booked":
      return (
        <ServiceCard
          providerName={providerName}
          providerDescription={providerDescription}
          providerImage={providerImage}
          summary={summary}
          textColor={textColor}
          showAlert={delayed || reassigned}
          alertTitle={alertTitle}
          informationTitle={formatMessage(
            messages.upcomingAppointmentDetailsTitle
          )}
          informationDescription={formatMessage(
            messages.bookedConsultationDate,
            {
              date: formattedDate,
              time: formatTime(date),
            }
          )}
          instructionDescription={notice}
          {...(digitalTool && {
            instructionAction: {
              title: formatMessage(messages.digitalToolActionTitle),
              onPress: handleDigitalToolSelected,
            },
          })}
          footerList={[
            {
              onPress: onPreparationNoticePress,
              message: formatMessage(messages.listItemHowToPrepareTitle),
            },
            {
              onPress: onCancellationInfoPress,
              message: formatMessage(messages.listItemCancellationInfoTitle),
            },
          ]}
        />
      );
    case "refunded":
      return (
        <ServiceCard
          isGrayscale
          providerName={providerName}
          providerDescription={providerDescription}
          providerImage={providerImage}
          summary={summary}
          textColor={textColor}
          alertTitle={formatMessage(messages.refundedHeader)}
          informationTitle={formatMessage(
            messages.refundedConsultationDateTitle
          )}
          informationDescription={formatMessage(
            messages.refundedConsultationDate,
            {
              date: formatDate(date),
              time: formatTime(date),
            }
          )}
          onDismissPress={onDismissPress}
          showDismiss={true}
        />
      );
    default:
      return null;
  }
}

AppointmentConsultationServiceCard.propTypes = {
  summary: PropTypes.string.isRequired,
  notice: PropTypes.string,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    image: PropTypes.string,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  digitalTool: PropTypes.shape({
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }),
  date: PropTypes.string.isRequired,
  state: PropTypes.oneOf(["booked", "refunded"]).isRequired,
  onPreparationNoticePress: PropTypes.func,
  onDismissPress: PropTypes.func,
  onDigitalToolSelected: PropTypes.func,
  testOnly_nowOverride: PropTypes.string,
  testOnly_initialProvider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  onCancellationInfoPress: PropTypes.func,
  order: function (props, propName, componentName) {
    if (props.state === "booked") {
      const value = getProductType(props[propName]);
      if (
        !includes(value, [
          "CONSULTATION",
          "PSYCHOLOGIST_CONSULTATION",
          "PHYSIOTHERAPIST_CONSULTATION",
        ])
      ) {
        return new Error(
          `Invalid prop ${propName} passed to ${componentName}. Expected order.product.type to be one of CONSULTATION, PSYCHOLOGIST_CONSULTATION, PHYSIOTHERAPIST_CONSULTATION. Got ${JSON.stringify(
            value
          )}`
        );
      }
    }
  },
};
