import { useCallback, useEffect, useMemo, useState } from "react";
import { capitalize, toString } from "lodash/fp";

import {
  getPermissionsAsync as getCameraPermissionsAsync,
  requestPermissionsAsync as requestCameraPermissionsAsync,
} from "../Camera";
import {
  getPermissionsAsync as getNotificationsPermissionsAsync,
  requestPermissionsAsync as requestNotificationsPermissionsAsync,
} from "../Notifications";
import {
  getPermissionsAsync as getAudioPermissionsAsync,
  requestPermissionsAsync as requestAudioPermissionsAsync,
} from "../Audio";
import {
  getPermissionsAsync as getMicrophonePermissionsAsync,
  requestPermissionsAsync as requestMicrophonePermissionsAsync,
} from "../Microphone";
import {
  getBackgroundPermissionsAsync as getLocationBackgroundPermissionsAsync,
  getForegroundPermissionsAsync as getLocationForegroundPermissionsAsync,
  requestBackgroundPermissionsAsync as requestLocationBackgroundPermissionsAsync,
  requestForegroundPermissionsAsync as requestLocationForegroundPermissionsAsync,
} from "../Location";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "../Logger";

import {
  coalesceCanAskAgain,
  coalesceExpirations,
  coalesceGranted,
  coalesceStatuses,
} from "./CoalescedPermissions";

const logger = LoggerFactory.get("core/AppPermissions");

export const AppPermissions = {
  CAMERA: "camera",
  NOTIFICATION: "notification",
  MICROPHONE: "microphone",
  AUDIO: "audio",
  LOCATION_FOREGROUND: "location_foreground",
  LOCATION_BACKGROUND: "location_background",
};

export const getUnauthorizedPermissions = async (permissionNames) => {
  const results = await getPermissionsAsync(permissionNames);
  return mergePermissionResponses(results);
};

export const formatPermissionsForCertClient = (permissionsResponse) => {
  const permissionTypes = Object.keys(permissionsResponse.permissions);
  return permissionTypes.reduce(
    (reduced, permissionType) => ({
      ...reduced,
      [`permission${capitalize(permissionType)}`]:
        permissionsResponse.permissions[permissionType].granted,
    }),
    {}
  );
};

const getPermissionsByType = {
  [AppPermissions.NOTIFICATION]: getNotificationsPermissionsAsync,
  [AppPermissions.CAMERA]: getCameraPermissionsAsync,
  [AppPermissions.MICROPHONE]: getMicrophonePermissionsAsync,
  [AppPermissions.AUDIO]: getAudioPermissionsAsync,
  [AppPermissions.LOCATION_FOREGROUND]: getLocationForegroundPermissionsAsync,
  [AppPermissions.LOCATION_BACKGROUND]: getLocationBackgroundPermissionsAsync,
};

const requestPermissionsByType = {
  [AppPermissions.NOTIFICATION]: requestNotificationsPermissionsAsync,
  [AppPermissions.CAMERA]: requestCameraPermissionsAsync,
  [AppPermissions.MICROPHONE]: requestMicrophonePermissionsAsync,
  [AppPermissions.AUDIO]: requestAudioPermissionsAsync,
  [AppPermissions.LOCATION_FOREGROUND]:
    requestLocationForegroundPermissionsAsync,
  [AppPermissions.LOCATION_BACKGROUND]:
    requestLocationBackgroundPermissionsAsync,
};

const getPermissionAsync = async (permission) => ({
  permission,
  permissionResponse: await getPermissionsByType[permission](),
});
const requestPermissionAsync = async (permission) => ({
  permission,
  permissionResponse: await requestPermissionsByType[permission](),
});

const mapPermissionResponses = (permissionResponses) =>
  permissionResponses.reduce(
    (reduced, { permission, permissionResponse }) => ({
      ...reduced,
      [permission]: permissionResponse,
    }),
    {}
  );

const mergePermissionResponses = (permissionResponses) => {
  const permissionResponsesMap = mapPermissionResponses(permissionResponses);
  return {
    status: coalesceStatuses(permissionResponsesMap),
    expires: coalesceExpirations(permissionResponsesMap),
    canAskAgain: coalesceCanAskAgain(permissionResponsesMap),
    granted: coalesceGranted(permissionResponsesMap),
    permissions: permissionResponsesMap,
  };
};

export const getPermissionsAsync = async (permissions) => {
  const permissionResponses = await Promise.all(
    permissions.map(getPermissionAsync)
  );
  const result = mergePermissionResponses(permissionResponses);
  logger(`getPermissionsAsync:${permissions}`, result);
  return result;
};

// this will first get the permissions, then request any that are not granted.
// So this function can be used to do get and request at the same time
export const requestPermissionsAsync = async (permissions) => {
  const results = await Promise.all(permissions.map(getPermissionAsync));
  const requestPermissionResponses = [];
  for (const result of results) {
    const { permission, permissionResponse } = result;
    if (
      permissionResponse.granted ||
      (!permissionResponse.granted && !permissionResponse.canAskAgain)
    ) {
      requestPermissionResponses.push(result);
    }
    if (!permissionResponse.granted && permissionResponse.canAskAgain) {
      requestPermissionResponses.push(await requestPermissionAsync(permission));
    }
  }
  const result = mergePermissionResponses(requestPermissionResponses);
  logger(`requestPermissionsAsync:${permissions}`, result);
  return result;
};

export const useAppPermissions = (
  permissionTypes = [],
  { get = true, request = false } = {}
) => {
  const [permissions, setPermissions] = useState(null);

  const getOrRequestAndSetPermissions = useCallback(
    (getPermissionsFunction) => async () => {
      try {
        const permissions = await getPermissionsFunction(permissionTypes);
        setPermissions(permissions);
        return permissions;
      } catch (error) {
        logger("useAppPermissions", error, LOGGER_LEVEL_ERROR);
      }
      return Promise.resolve();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [toString(permissionTypes)]
  );

  const _getPermissionsAsync = useMemo(
    () => getOrRequestAndSetPermissions(getPermissionsAsync),
    [getOrRequestAndSetPermissions]
  );
  const _requestPermissionsAsync = useMemo(
    () => getOrRequestAndSetPermissions(requestPermissionsAsync),
    [getOrRequestAndSetPermissions]
  );

  useEffect(() => {
    if (get) {
      _getPermissionsAsync();
    }
  }, [get, _getPermissionsAsync]);
  useEffect(() => {
    if (request) {
      _requestPermissionsAsync();
    }
  }, [request, _requestPermissionsAsync]);

  return {
    permissions,
    getPermissionsAsync: _getPermissionsAsync,
    requestPermissionsAsync: _requestPermissionsAsync,
  };
};
global.getPermissionsAsync = getPermissionsAsync;
global.requestPermissionsAsync = requestPermissionsAsync;
