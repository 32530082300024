import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { noop, size } from "lodash/fp";
import { SectionList } from "react-native";
import {
  CoverageType,
  isMonetaryPaymentMethod,
  isNonMonetaryPaymentMethod,
  moneyShape,
} from "@eyr-mobile/domain/Order";

import { PaymentMethodListItem } from "../PaymentMethodListItem";

export function SelectPaymentMethod({
  toPay,
  accountPaymentMethods,
  paymentProviders,
  onPaymentProviderSelected,
  onInsuranceOrDiscountSelected,
  onCardSelected,
  ...rest
}) {
  const renderAccountPaymentMethodItem = useCallback(
    ({ item: paymentMethod }) => {
      const {
        type,
        disabled,
        disabledReason,
        name,
        description,
        image,
        orderCoverage,
      } = paymentMethod;
      let primaryValue;
      let secondaryValue;
      if (isNonMonetaryPaymentMethod(paymentMethod)) {
        switch (orderCoverage.type) {
          case CoverageType.FULL:
            primaryValue = {
              ...toPay,
              amount: 0,
            };
            break;
          case CoverageType.DISCOUNT:
            primaryValue = {
              ...toPay,
              amount: toPay.amount - orderCoverage.discount.amount,
            };
            secondaryValue = toPay;
            break;
          case CoverageType.PARTIAL:
            primaryValue = {
              ...toPay,
              amount: toPay.amount - orderCoverage.discount.amount,
            };
            break;
          default:
            break;
        }
      }
      if (isMonetaryPaymentMethod(paymentMethod)) {
        primaryValue = toPay;
      }

      return (
        <PaymentMethodListItem
          name={name}
          description={description}
          image={image}
          type={type}
          disabled={disabled}
          disabledReason={disabledReason}
          primaryValue={primaryValue}
          secondaryValue={secondaryValue}
          onPress={() => {
            if (type === "INSURANCE" || type === "DISCOUNT") {
              onInsuranceOrDiscountSelected(paymentMethod);
            }
            if (type === "CARD") {
              onCardSelected(paymentMethod);
            }
          }}
        />
      );
    },
    [onInsuranceOrDiscountSelected, onCardSelected, toPay]
  );
  const renderPaymentProviderItem = useCallback(
    ({ item: paymentProvider }) => {
      const { __typename, disabled, name, image } = paymentProvider;
      return (
        <PaymentMethodListItem
          name={name}
          image={image}
          type={__typename}
          disabled={disabled}
          primaryValue={toPay}
          onPress={() => onPaymentProviderSelected(paymentProvider)}
        />
      );
    },
    [onPaymentProviderSelected, toPay]
  );
  const sections = useMemo(() => {
    const sections = [];
    if (size(accountPaymentMethods) > 0) {
      sections.push({
        data: accountPaymentMethods,
        keyExtractor: (item) => item.id.toString(),
        renderItem: renderAccountPaymentMethodItem,
      });
    }
    if (size(paymentProviders) > 0) {
      sections.push({
        data: paymentProviders,
        keyExtractor: (item) => item.slug,
        renderItem: renderPaymentProviderItem,
      });
    }
    return sections;
  }, [
    accountPaymentMethods,
    paymentProviders,
    renderAccountPaymentMethodItem,
    renderPaymentProviderItem,
  ]);
  return (
    <SectionList sections={sections} ListEmptyComponent={null} {...rest} />
  );
}
SelectPaymentMethod.propTypes = {
  toPay: moneyShape.isRequired,
  onPaymentProviderSelected: PropTypes.func,
  onInsuranceOrDiscountSelected: PropTypes.func,
  onCardSelected: PropTypes.func,
  paymentProviders: PropTypes.arrayOf(
    PropTypes.shape({ slug: PropTypes.string.isRequired })
  ),
  accountPaymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf(["CARD", "INSURANCE", "DISCOUNT"]),
    })
  ),
};

SelectPaymentMethod.defaultProps = {
  paymentProviders: [],
  accountPaymentMethods: [],
  onPaymentProviderSelected: noop,
  onInsuranceOrDiscountSelected: noop,
  onCardSelected: noop,
};

export default SelectPaymentMethod;
