import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  listItem: {
    flex: 1,
    paddingHorizontal: 16,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    width: "100%",
  },
  contentContainerStyleS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerStyleM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
});

export const stylingProps = {
  icon: {
    fill: Palette.BURGUNDY_100,
  },
};
