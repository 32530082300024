import React, { useCallback, useEffect } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useAlert } from "@eyr-mobile/core/Alert";
import {
  ProgressIndicator,
  Heading,
  EyrButton,
  IconContainer,
} from "@eyr-mobile/ui/components";
import {
  useWorkControlQuestion,
  useOrderFlowStage,
  GetPaymentMethodsForOrder,
  useCancelOrder,
} from "@eyr-mobile/domain/Order";
import { View } from "react-native";
import { find } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res";

import { styles } from "./FlowStageSetPaymentMethods_ControlQuestionWorkScreen.styles";
import { messages } from "./FlowStageSetPaymentMethods_ControlQuestionWorkScreen.messages";

export function FlowStageSetPaymentMethods_ControlQuestionWorkScreen() {
  const { formatMessage } = useIntl();
  const { setOptions } = useNavigation();
  const alert = useAlert();

  const {
    data,
    stageParams: { progress, progressPerStage } = {},
    handlers,
  } = useOrderFlowStage(GetPaymentMethodsForOrder, ({ orderId }) => ({
    variables: { orderId },
  }));

  const [
    { answerNegatively, answerPositively, controlPaymentMethodId, onCompleted },
  ] = useWorkControlQuestion();

  const [cancelOrder] = useCancelOrder();

  const controlPaymentMethodName = find(
    { id: controlPaymentMethodId },
    data?.accountPaymentMethodsForOrder
  )?.name;

  useEffect(() => {
    if (controlPaymentMethodName) {
      setOptions({ title: controlPaymentMethodName });
    }
  }, [controlPaymentMethodName, setOptions]);

  const progressWithinSubStages = progress + (1 / 3) * progressPerStage;

  const answerNegativelyWithAlert = useCallback(() => {
    answerNegatively({
      onCompleted: (data) => {
        function resume() {
          onCompleted(data);
        }
        alert({
          icon: (
            <IconContainer size="s" variant="round">
              <SVGs.Employee />
            </IconContainer>
          ),
          title: formatMessage(messages.alertInvalidWorkTitle),
          message: formatMessage(messages.alertInvalidWorkDescription),
          onClose: resume,
          buttonsLayout: "column",
          buttons: [
            {
              title: formatMessage(messages.changePaymentMethodActionTitle),
              onPress: resume,
            },
            {
              title: formatMessage(messages.cancelOrderActionTitle),
              variant: "secondary",
              onPress: cancelOrder,
            },
          ],
        });
      },
    });
  }, [alert, answerNegatively, formatMessage, onCompleted, cancelOrder]);
  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
          >
            <View style={styles.titleContainer}>
              <Heading alignment="center" size="s">
                {formatMessage(messages.title)}
              </Heading>
            </View>

            <View style={styles.buttonsContainer}>
              <View style={styles.buttonContainer}>
                <EyrButton
                  onPress={answerPositively}
                  title={formatMessage(messages.confirmActionTitle)}
                  variant={"secondary"}
                  accessibilityLabel={formatMessage(
                    messages.confirmActionTitle
                  )}
                />
              </View>
              <View style={styles.buttonContainer}>
                <EyrButton
                  onPress={answerNegativelyWithAlert}
                  title={formatMessage(messages.declineActionTitle)}
                  variant={"secondary"}
                  accessibilityLabel={formatMessage(
                    messages.declineActionTitle
                  )}
                />
              </View>
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetPaymentMethods_ControlQuestionWorkScreen.routeName =
  "FlowStageSetPaymentMethods_ControlQuestionWorkScreen";

FlowStageSetPaymentMethods_ControlQuestionWorkScreen.navigationOptions = {
  title: null,
};
