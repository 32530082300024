import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { noop, getOr, compact } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res";
import { Accordion } from "../Accordion";
import { EyrButton } from "../EyrButton";
import { Paragraph } from "../Paragraph";

import {
  styles,
  stylingProps,
  variantStyles,
  variantStylingProps,
} from "./Alert.styles";

const ICONS = {
  info: SVGs.InfoOutlinedMono,
  warning: SVGs.WarningAmberOutlinedMono,
  danger: SVGs.ErrorOutlineOutlinedMono,
  success: SVGs.CheckCircleOutlineOutlinedMono,
};

// TODO: replace buttons with text links
function AlertAction({ action, onAction }) {
  const handlePress = useCallback(() => {
    onAction(action);
  }, [onAction, action]);
  return (
    <View style={styles.actionContainer}>
      <EyrButton
        title={action.title}
        accessibilityLabel={action.title}
        variant={"text"}
        size="s"
        onPress={handlePress}
      />
    </View>
  );
}

const ActionShape = PropTypes.shape({
  title: PropTypes.string,
  target: PropTypes.string,
  __typename: PropTypes.string,
});

AlertAction.propTypes = {
  action: ActionShape,
  onAction: PropTypes.func,
};

export function Alert({
  variant = "info",
  title,
  body,
  collapsible = false,
  showIcon = true,
  responsive = false,
  actions = [],
  onAction = noop,
}) {
  const { screenSizeSelect } = useDevice();
  const IconComponent = getOr(ICONS.info, variant, ICONS);
  const variantStyle = getOr({}, variant, variantStyles);
  const variantProps = getOr({}, variant, variantStylingProps);
  const iconProps = {
    ...stylingProps.icon,
    ...variantProps.icon,
  };
  const Header = (
    <View style={styles.headerContainer}>
      {showIcon && (
        <View style={styles.iconContainer}>
          <IconComponent {...iconProps} />
        </View>
      )}
      <Paragraph size={"m"} {...variantProps.typography}>
        {title}
      </Paragraph>
    </View>
  );
  const Body = body ? (
    <View
      style={compact([
        styles.bodyContainer,
        showIcon && styles.bodyContainerWithIcon,
      ])}
    >
      <Paragraph size={"s"} {...variantProps.typography}>
        {body}
      </Paragraph>
    </View>
  ) : null;
  const contentContainerStyle = responsive
    ? screenSizeSelect({
        xs: styles.contentContainerXS,
        s: styles.contentContainerS,
        m: styles.contentContainerM,
      })
    : styles.contentContainerXS;

  return (
    <View
      style={compact([
        styles.container,
        responsive && styles.responsiveContainer,
        variantStyle.container,
      ])}
    >
      {collapsible && body ? (
        <Accordion
          title={title}
          header={Header}
          isHeadless
          headerIconRightProps={iconProps}
          style={contentContainerStyle}
        >
          {Body}
        </Accordion>
      ) : (
        <View style={contentContainerStyle}>
          {Header}
          {Body}
        </View>
      )}
      {Boolean(actions.length) && (
        <View style={[contentContainerStyle, styles.footer]}>
          {actions.map((action, index) => (
            <AlertAction
              action={action}
              key={index.toString()}
              onAction={onAction}
            />
          ))}
        </View>
      )}
    </View>
  );
}

Alert.propTypes = {
  variant: PropTypes.oneOf(["info", "warning", "danger", "success"]),
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  collapsible: PropTypes.bool,
  showIcon: PropTypes.bool,
  responsive: PropTypes.bool,
  onAction: PropTypes.func,
  actions: PropTypes.arrayOf(ActionShape),
};
