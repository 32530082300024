import React, { useCallback, useEffect, useMemo } from "react";
import { View } from "react-native";
import { isString } from "lodash/fp";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useRoute, useNavigation } from "@react-navigation/native";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "@eyr-mobile/core/Logger";
import { show as showToast } from "@eyr-mobile/core/Toast";
import {
  useApolloClient,
  useQuery,
  withHandlers,
} from "@eyr-mobile/core/DataProvider";
import { GetAccountPaymentMethods } from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";
import { GetAddAccountInsuranceOrDiscountData } from "@eyr-mobile/domain/Account";

import { AddInsuranceOrDiscount } from "../../components";

import { styles } from "./AddAccountInsuranceOrDiscountScreen.styles";
import { messages } from "./AddAccountInsuranceOrDiscountScreen.messages";

const logger = LoggerFactory.get("screens/AddAccountInsuranceOrDiscountScreen");

export function AddAccountInsuranceOrDiscountScreen() {
  const { navigate, goBack } = useNavigation();
  const route = useRoute();
  const { accessToken } = useAuth();
  const client = useApolloClient();
  const { org, orgCodeHint } = route?.params || {};
  const { handlers, data, error } = withHandlers(
    useQuery(GetAddAccountInsuranceOrDiscountData, {
      variables: { slug: org },
    })
  );

  const { screenSizeSelect } = useDevice();

  useEffect(() => {
    if (!org) {
      logger("expects to have route.params.org set", org, LOGGER_LEVEL_WARN);
    }
  }, [org]);

  const specificInsuranceOrDiscountUrl = useMemo(() => {
    if (!data) {
      return;
    }
    if (!orgCodeHint) {
      return data.org.newPolicyUrl;
    }
    const url = new URL(data.org.newPolicyUrl);
    url.searchParams.append("codeHint", orgCodeHint);
    return url.toString();
  }, [data, orgCodeHint]);

  const handleSucceeded = useCallback(async () => {
    try {
      await client.query({
        query: GetAccountPaymentMethods,
        fetchPolicy: "network-only",
      });
    } finally {
      navigate("HomeTabScreen");
    }
  }, [client, navigate]);

  useEffect(() => {
    if (error) {
      if (isString(error?.message)) {
        showToast(error.message);
      }
      goBack();
    }
  }, [error, goBack]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <View
          style={[
            styles.contentContainer,
            screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            }),
          ]}
        >
          <AddInsuranceOrDiscount
            accessToken={accessToken}
            onSucceeded={handleSucceeded}
            specificInsuranceOrDiscountUrl={specificInsuranceOrDiscountUrl}
          />
        </View>
      )}
    </SafeAreaView>
  );
}

AddAccountInsuranceOrDiscountScreen.routeName =
  "AddAccountInsuranceOrDiscountScreen";
AddAccountInsuranceOrDiscountScreen.navigationOptions = {
  title: messages.navigationTitle,
};
