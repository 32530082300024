/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useRef, useCallback } from "react";

import { BackgroundTimer } from "../BackgroundTimers";

function useWait(waitFunction, cleanWaitFunction) {
  return function (callback, delay, autorun = true) {
    const savedCallback = useRef(callback);
    const waitRef = useRef(undefined);

    const startWait = useCallback(() => {
      if (waitRef.current || !Number(delay) || delay < 0) {
        return;
      }
      waitRef.current = waitFunction(savedCallback.current, delay);
    }, [delay]);

    const clearWait = useCallback(() => {
      if (!waitRef.current) {
        return;
      }
      cleanWaitFunction(waitRef.current);
      waitRef.current = undefined;
    }, []);

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      if (autorun) {
        clearWait();
        startWait();
      }
      return () => clearWait();
    }, [delay, autorun, clearWait, startWait]);

    return [startWait, clearWait];
  };
}

export const useInterval = useWait(setInterval, clearInterval);

export const useTimeout = useWait(setTimeout, clearTimeout);

const originalSetTimeout = global.setTimeout;
const originalClearTimeout = global.clearTimeout;
const originalSetInterval = global.setInterval;
const originalClearInterval = global.clearInterval;
const backgroundCapableSetTimeout =
  BackgroundTimer.setTimeout.bind(BackgroundTimer);
const backgroundCapableClearTimeout =
  BackgroundTimer.clearTimeout.bind(BackgroundTimer);
const backgroundCapableSetInterval =
  BackgroundTimer.setInterval.bind(BackgroundTimer);
const backgroundCapableClearInterval =
  BackgroundTimer.clearInterval.bind(BackgroundTimer);

export function enableBackgroundTimers() {
  global.setTimeout = backgroundCapableSetTimeout;
  global.clearTimeout = backgroundCapableClearTimeout;
  global.setInterval = backgroundCapableSetInterval;
  global.clearInterval = backgroundCapableClearInterval;
}

export function disableBackgroundTimers() {
  global.setTimeout = originalSetTimeout;
  global.clearTimeout = originalClearTimeout;
  global.setInterval = originalSetInterval;
  global.clearInterval = originalClearInterval;
}
