import React from "react";
import PropTypes from "prop-types";
import { Platform, Image } from "react-native";

function SvgUriWeb({ uri, themeOverride, height, width, ...rest }) {
  return <Image source={{ uri, height, width }} {...rest} />;
}

SvgUriWeb.propTypes = {
  uri: PropTypes.string,
};

export const SvgUri = Platform.select({
  default: () => SvgUriWeb,
  native: () => require("react-native-svg").SvgUri,
})();
