import { StyleSheet } from "react-native";
import { fontMaker } from "@eyr-mobile/core/Lib";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  listItemImage: {
    marginRight: 12,
    width: 40,
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Palette.NUDE_20,
    borderRadius: 8,
  },
});

export const tabBarBadgeStyle = {
  ...fontMaker(),
  maxWidth: "auto",
  backgroundColor: Palette.PINK_60,
  color: Palette.BURGUNDY_100,
  fontSize: 12,
  paddingHorizontal: 8,
  paddingVertical: 3,
  height: 24,
  borderRadius: 12,
  flex: 1,
  alignItems: "center",
};
