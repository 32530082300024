import { gql } from "@apollo/client";
import moment from "moment";

export const GetOnboardingAddAccountContactInfoData = gql`
  query GetOnboardingAddAccountContactInfoData {
    countries(available: true) {
      id
      codePhone
      phoneNumberLength
    }
    account {
      id
      country {
        id
        codePhone
        phoneNumberLength
      }
    }
  }
`;

export const GetOnboardingAddInsuranceOrDiscountData = gql`
  query GetOnboardingAddInsuranceOrDiscountData($slug: String!) {
    org(slug: $slug) {
      id
      newPolicyUrl
    }
  }
`;

export const CheckOnboardingStatus = gql`
  query CheckOnboardingStatus {
    account {
      id
      email
      phoneCountryCode
      phoneNumber
      onboardedAt
    }
    accountPaymentMethods {
      id
    }
  }
`;
export const OnboardingSelectInsuranceOrDiscountScreenData = gql`
  query OnboardingSelectInsuranceOrDiscountScreen($whitelabel: String) {
    account {
      country {
        id
        orgs(default: false, type: 1, whitelabel: $whitelabel) {
          id
          name
          slug
          smallIconUrl
        }
      }
    }
  }
`;

export const AddOnboardingAccountContactInfo = gql`
  mutation AddOnboardingAccountContactInfo($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
        email
        notificationSettings
        phoneCountryCode
        phoneNumber
      }
    }
  }
`;
export const CompleteOnboarding = gql`
  mutation CompleteOnboarding {
    updateAccount(input: {onboardedAt: "${moment.utc().format()}"}) {
      account {
        id
        onboardedAt
      }
    }
  }
`;
