import React from "react";
import PropTypes from "prop-types";
import { omit } from "lodash/fp";

import { Card } from "../Card";
import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";
import { IconContainer } from "../IconContainer";
import { ResAwareSvg } from "../ResAwareSvg";

export function FlowStageSetMedicalCategorySummaryItem({
  medicalCategory: { image, name },
  freeFormSymptoms,
  ...rest
}) {
  return (
    <Card
      icon={
        <IconContainer size="s" variant="plain">
          <ResAwareSvg svg={image} />
        </IconContainer>
      }
      title={name}
      description={
        Boolean(freeFormSymptoms) && (
          <Paragraph numberOfLines={2} ellipsizeMode={"tail"}>
            {freeFormSymptoms}
          </Paragraph>
        )
      }
      titleIsBold
      InteractivityIconComponent={SVGs.PencilMono}
      {...omit(["__typename", "orderId", "product", "stageId"], rest)}
    />
  );
}
FlowStageSetMedicalCategorySummaryItem.propTypes = {
  medicalCategory: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }),
  freeFormSymptoms: PropTypes.string,
};
