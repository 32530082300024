import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.navigationTitle",
    description:
      "FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen navigation title",
    defaultMessage: "Add insurance or membership",
  },
});
