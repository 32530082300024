import { StyleSheet, Platform } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainer: {
    flex: 1,
  },
  closeIconContainer: {
    alignSelf: "flex-end",
    padding: 16,
  },
  innerContainerXS: {
    flexGrow: 1,
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  innerContainerS: {
    flexGrow: 1,
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  innerContainerM: {
    flexGrow: 1,
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  divider: {
    height: 2,
    width: 48,
    alignSelf: "center",
    marginVertical: 24,
    backgroundColor: Palette.OVERLAY_DARK_4,
  },
  categoriesContainer: {
    marginBottom: 12,
  },
  notesContainer: {
    marginBottom: 24,
    backgroundColor: Palette.WHITE,
    borderColor: Palette.NUDE_40,
    borderRadius: 8,
  },
  bottomBar: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  buttonContainerXS: {
    ...responsiveStyles.contentContainerXS,
    paddingHorizontal: 24,
  },
  buttonContainerS: {
    ...responsiveStyles.contentContainerS,
    flexDirection: "row",
    justifyContent: "center",
  },
  buttonContainerM: {
    ...responsiveStyles.contentContainerM,
    flexDirection: "row",
    justifyContent: "center",
  },
});

export const stylingProps = {
  closeIcon: {
    width: 18,
    height: 18,
    fill: Palette.BURGUNDY_100,
  },
  keyboardAvoidingView: {
    behavior: Platform.select({ ios: "padding" }),
  },
  notesInput: {
    textAlignVertical: "top",
  },
};
