import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  noProductsContentContainer: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  listCatalogItemContainer: { alignItems: "center" },
});
