import { useMemo, useState } from "react";
import {
  fetchAndActivate,
  getAll,
  getRemoteConfig,
  getNumber,
  getBoolean,
  getString,
} from "firebase/remote-config";
import Constants from "expo-constants";

import { LoggerFactory } from "../Logger";
import { useInterval } from "../Lib";
import { CloudInstance } from "../Cloud";

const logger = LoggerFactory.get("core/Config");

const remoteConfig = getRemoteConfig(CloudInstance);

export const setupConfig = ({ defaults = {}, settings = {} } = {}) => {
  remoteConfig.settings = settings;
  remoteConfig.defaultConfig = defaults;
  return fetchAndActivate(remoteConfig).then(() => {
    logger("Dynamic:", getAll(remoteConfig));
  });
};

export const useConfig = () => ({
  getString: (key) => getString(remoteConfig, key),
  getBoolean: (key) => getBoolean(remoteConfig, key),
  getNumber: (key) => getNumber(remoteConfig, key),
  getAll: () => getAll(remoteConfig),
});

export const useConfigValue = (key, refreshIntervalMs = 60000) => {
  const initialValue = useMemo(() => getString(remoteConfig, key), [key]);
  const [value, setValue] = useState(initialValue);
  useInterval(() => {
    setValue(getString(remoteConfig, key));
  }, refreshIntervalMs);
  return value;
};

export const useFeatureFlag = (key, refreshIntervalMs = 60000) => {
  const initialValue = useMemo(() => getBoolean(remoteConfig, key), [key]);
  const [featureEnabled, setFeatureEnabled] = useState(initialValue);
  useInterval(() => {
    setFeatureEnabled(getBoolean(remoteConfig, key));
  }, refreshIntervalMs);
  return featureEnabled;
};

export const StaticConfig = global.StaticConfig;
export { Constants };

if (!global.__TEST__) {
  logger("Static:", StaticConfig);
}
