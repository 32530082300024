import { defineMessages } from "react-intl";

export const messages = defineMessages({
  addInsuranceOrDiscount: {
    id: "AddPaymentMethod.addInsuranceOrDiscount",
    description: "Add insurance or discount button title",
    defaultMessage: "Add insurance or membership",
  },
  addCard: {
    id: "AddPaymentMethod.addCard",
    description: "Add card button title",
    defaultMessage: "Add bank card",
  },
  newPaymentMethodTitle: {
    id: "AddPaymentMethod.newPaymentMethodTitle",
    description: "Add payment method title",
    defaultMessage: "New payment method",
  },
});
