import { gql } from "@apollo/client";

export const GetCountryWithAvailabilityStatus = gql`
  query GetCountryWithAvailabilityStatus($alpha2Code: String!) {
    country(code: $alpha2Code) {
      id
      available
      codeIso2
    }
  }
`;

export const RegisterDevice = gql`
  mutation RegisterDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      cert {
        id
        issuedAt
        privateKey
        publicKey
        identityProvider {
          id
        }
        salt
      }
      accessToken
    }
  }
`;

export const GetIdentificationMethodsForCountry = gql`
  query GetIdentificationMethodsForCountry($code: String!) {
    country(code: $code) {
      id
      name
      flag
      identityProviders {
        id
        name
        description
      }
    }
  }
`;

export const GetCountriesAvailableForAuthentication = gql`
  query GetCountriesAvailableForAuthentication {
    countries(available: true) {
      id
      name
      flag
      loginDescription
      codeIso2
    }
  }
`;

export const RecoverSession = gql`
  mutation RecoverSession($input: RecoverSessionInput!) {
    recoverSession(input: $input) {
      accessToken
    }
  }
`;

export const GetAccountCountryCode = gql`
  query GetAccountCountryCode {
    account {
      id
      country {
        id
        codeIso2
      }
    }
  }
`;
