import { defineMessages } from "react-intl";

import { serviceProviderTitleMessages } from "../../shared-messages";

export const messages = {
  ...defineMessages({
    messageInputPlaceholder: {
      id: "ConversationScreen.messageInputPlaceholder",
      description: "message input placeholder text",
      defaultMessage: "Write a message",
    },
    conversationDisabledMessage: {
      id: "ConversationScreen.conversationDisabledMessage",
      description:
        "text displayed when conversation is disabled and messages cant be sent",
      defaultMessage: "Book a consultation to talk to {serviceProviderTitle}",
    },
  }),
  ...serviceProviderTitleMessages,
};
