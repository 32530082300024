import React, { useState, useRef, useLayoutEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {
  Pressable,
  Animated,
  View,
  Easing,
  useWindowDimensions,
} from "react-native";

import { SVGs } from "../../res";

import {
  styles,
  animationDuration,
  stylingProps,
  stylingHelpers,
} from "./Accordion.styles";

function animateValueTo(currentValue, toValue, useNativeDriver = false) {
  Animated.timing(currentValue, {
    useNativeDriver,
    toValue,
    duration: animationDuration,
    easing: Easing.sin,
  }).start();
}

export function Accordion({
  title,
  header,
  children,
  iconPosition = "top", // TODO: find more automatic solution
  isHeadless = false,
  headerIconRightProps,
  ...rest
}) {
  const [open, setOpen] = useState(false);
  const [actualHeight, setActualHeight] = useState(0);
  const { width } = useWindowDimensions();
  const contentMeasuredForScreenWidthRef = useRef([false, width]);
  const nativeDriverProgress = useRef(new Animated.Value(0)).current;
  const jsDriverProgress = useRef(new Animated.Value(0)).current;
  const iconRightAnimatedStyle = {
    transform: [
      {
        rotateZ: nativeDriverProgress.interpolate({
          inputRange: [0, 1],
          outputRange: ["0deg", "180deg"],
        }),
      },
    ],
  };
  const contentAnimatedStyle = {
    height: jsDriverProgress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, actualHeight],
    }),
  };
  const innerContainerStyle = stylingHelpers.getInnerContainerStyle(isHeadless);
  const headerContainerStyle = stylingHelpers.getHeaderContainerStyle({
    isHeadless,
    open,
  });

  const measureContentOnce = useCallback(
    ({ nativeEvent }) => {
      const [measured, measuredForWidth] =
        contentMeasuredForScreenWidthRef.current;
      if (measured && measuredForWidth === width) {
        return;
      }
      contentMeasuredForScreenWidthRef.current = [true, width];
      setActualHeight(nativeEvent.layout.height);
    },
    [width]
  );

  useLayoutEffect(() => {
    if (!actualHeight) {
      return;
    }
    if (open) {
      animateValueTo(nativeDriverProgress, 1, true);
      animateValueTo(jsDriverProgress, 1);
    } else {
      animateValueTo(nativeDriverProgress, 0, true);
      animateValueTo(jsDriverProgress, 0);
    }
  }, [actualHeight, open, nativeDriverProgress, jsDriverProgress]);

  const toggleOpen = useCallback(() => setOpen(!open), [open]);

  return (
    <View
      accessibilityLabel={`${title} section`}
      {...rest}
      accessibilityState={{ expanded: open }}
    >
      <View style={innerContainerStyle}>
        <Pressable
          style={headerContainerStyle}
          onPress={toggleOpen}
          accessibilityHint={`Expands the ${title} section`}
        >
          {header}
          <Animated.View
            style={[
              styles[`${iconPosition}PositionIconRight`],
              iconRightAnimatedStyle,
            ]}
          >
            <SVGs.ChevronDownMono
              {...stylingProps.headerIconRight}
              {...headerIconRightProps}
            />
          </Animated.View>
        </Pressable>
        <Animated.View style={[styles.contentContainer, contentAnimatedStyle]}>
          <View
            style={styles.contentInnerContainer}
            onLayout={measureContentOnce}
          >
            {children}
          </View>
        </Animated.View>
      </View>
    </View>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  iconPosition: PropTypes.oneOf(["top", "center"]),
  isHeadless: PropTypes.bool,
  headerIconRightProps: PropTypes.object,
};
