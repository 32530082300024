import { defineMessages } from "react-intl";

export const messages = defineMessages({
  expandActionTitle: {
    id: "Consent.expandActionTitle",
    description: "Read more",
    defaultMessage: "Read more",
  },
  collapseActionTitle: {
    id: "Consent.collapseActionTitle",
    description: "Show less",
    defaultMessage: "Show less",
  },
  acceptConsentActionTitle: {
    id: "Consent.acceptConsentActionTitle",
    description: "accept",
    defaultMessage: "Accept",
  },
  declineConsentActionTitle: {
    id: "Consent.declineConsentActionTitle",
    description: "decline",
    defaultMessage: "Decline",
  },
  withdrawConsentActionTitle: {
    id: "Consent.withdrawConsentActionTitle",
    description: "Withdraw consent action title",
    defaultMessage: "Withdraw",
  },
  accepted: {
    id: "Consent.accepted",
    description: "accepted",
    defaultMessage: "Accepted",
  },
  acceptedConsentMessage: {
    id: "Consent.acceptedConsentMessage",
    description: "acceptedConsentMessage",
    defaultMessage: "Policy version {policyVersion} accepted on {acceptedAt}.",
  },
  declineAccountConsentAlertTitle: {
    id: "Consent.declineAccountConsentAlertTitle",
    description: "Decline account consent alert title",
    defaultMessage: "Do you really want to decline the consent?",
  },
  declineAccountConsentAlertMessage: {
    id: "Consent.declineAccountConsentAlertMessage",
    description: "Decline account consent alert message",
    defaultMessage:
      "If you decline this consent, the account will be deleted and you will be logged out. You will lose access to all your data in Eyr.",
  },
  declineAccountConsentAlertCancelActionTitle: {
    id: "Consent.declineAccountConsentAlertCancelActionTitle",
    description: "Decline account consent alert cancel action title",
    defaultMessage: "Stay here",
  },
  declineAccountConsentAlertConfirmActionTitle: {
    id: "Consent.declineAccountConsentAlertConfirmActionTitle",
    description: "Decline account consent alert confirm action title",
    defaultMessage: "Delete my account",
  },
  withdrawAccountConsentAlertTitle: {
    id: "Consent.withdrawAccountConsentAlertTitle",
    description: "Withdraw account consent alert title",
    defaultMessage: "Do you really want to withdraw the consent?",
  },
  withdrawAccountConsentAlertMessage: {
    id: "Consent.withdrawAccountConsentAlertMessage",
    description: "Withdraw account consent alert message",
    defaultMessage:
      "By withdraw this consent, the account will be deleted and you will be logged out. You will lose access to all your data in Eyr.",
  },
  withdrawAccountConsentAlertCancelActionTitle: {
    id: "Consent.withdrawAccountConsentAlertCancelActionTitle",
    description: "Withdraw account consent alert cancel action title",
    defaultMessage: "Stay here",
  },
  withdrawAccountConsentAlertConfirmActionTitle: {
    id: "Consent.withdrawAccountConsentAlertConfirmActionTitle",
    description: "Withdraw account consent alert confirm action title",
    defaultMessage: "Delete my account",
  },
  declineMedicalServiceConsentAlertTitle: {
    id: "Consent.declineMedicalServiceConsentAlertTitle",
    description: "Decline medical service consent alert title",
    defaultMessage: "Are you sure you want to decline?",
  },
  declineMedicalServiceConsentAlertMessage: {
    id: "Consent.declineMedicalServiceConsentAlertMessage",
    description: "Decline medical service consent alert message",
    defaultMessage:
      "If you decline this consent, your insurance will be removed from payment methods.",
  },
  declineMedicalServiceConsentAlertCancelActionTitle: {
    id: "Consent.declineMedicalServiceConsentAlertCancelActionTitle",
    description: "Decline medical service consent alert cancel action title",
    defaultMessage: "Cancel",
  },
  declineMedicalServiceConsentAlertConfirmActionTitle: {
    id: "Consent.declineMedicalServiceConsentAlertConfirmActionTitle",
    description: "Decline medical service consent alert confirm action title",
    defaultMessage: "Decline and remove insurance",
  },
  withdrawMedicalServiceConsentAlertTitle: {
    id: "Consent.withdrawMedicalServiceConsentAlertTitle",
    description: "Withdraw medical service consent alert title",
    defaultMessage: "Are you sure you want to withdraw your consent?",
  },
  withdrawMedicalServiceConsentAlertMessage: {
    id: "Consent.withdrawMedicalServiceConsentAlertMessage",
    description: "Withdraw medical service consent alert message",
    defaultMessage:
      "If you withdraw your consent, your insurance will be removed from payment methods.",
  },
  withdrawMedicalServiceConsentAlertCancelActionTitle: {
    id: "Consent.withdrawMedicalServiceConsentAlertCancelActionTitle",
    description: "Withdraw medical service consent alert cancel action title",
    defaultMessage: "Cancel",
  },
  withdrawMedicalServiceConsentAlertConfirmActionTitle: {
    id: "Consent.withdrawMedicalServiceConsentAlertConfirmActionTitle",
    description: "Withdraw medical service consent alert confirm action title",
    defaultMessage: "Withdraw and remove insurance",
  },
  declineRequiredConsentAlertTitle: {
    id: "Consent.declineRequiredConsentAlertTitle",
    description: "Decline required consent alert title",
    defaultMessage: "Decline?",
  },
  declineRequiredConsentAlertMessage: {
    id: "Consent.declineRequiredConsentAlertMessage",
    description: "Decline required consent alert message",
    defaultMessage: "You'll be logged out.",
  },
  declineRequiredConsentAlertCancelActionTitle: {
    id: "Consent.declineRequiredConsentAlertCancelActionTitle",
    description: "Decline required consent alert cancel action title",
    defaultMessage: "Stay here",
  },
  declineRequiredConsentAlertConfirmActionTitle: {
    id: "Consent.declineRequiredConsentAlertConfirmActionTitle",
    description: "Decline required consent alert confirm action title",
    defaultMessage: "Decline",
  },
});
