import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
    paddingBottom: 16,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
    paddingBottom: 32,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
    paddingBottom: 48,
  },
  footerContainer: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  footerContentContainerXS: responsiveStyles.contentContainerXS,
  footerContentContainerS: {
    ...responsiveStyles.contentContainerS,
    flexDirection: "row-reverse",
  },
  footerContentContainerM: {
    ...responsiveStyles.contentContainerM,
    flexDirection: "row-reverse",
  },
  actionContainer: {
    flexGrow: 1,
  },
  primaryActionContainerXS: {
    marginBottom: 16,
  },
  primaryActionContainerS: {
    marginLeft: 8,
  },
});
