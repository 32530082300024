import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  size,
  getOr,
  noop,
  pick,
  keys,
  map,
  sortBy,
  groupBy,
  compact,
  includes,
  reject,
  some,
  concat,
} from "lodash/fp";
import { useIntl } from "react-intl";
import { View, ScrollView } from "react-native";
import {
  ProductAvailabilityState,
  ProductType,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";

import { ProductCard } from "../ProductCard";
import { Paragraph } from "../Paragraph";
import { EyrSegmentedControl } from "../EyrSegmentedControl";

import { messages } from "./ProductsCatalog.messages";
import { styles, stylingHelpers } from "./ProductsCatalog.styles";

const ensureDoctorsFirst = sortBy((productType) =>
  productType === ProductType.CONSULTATION ? 0 : 1
);

const SUPPORTED_PRODUCT_TYPES = [
  ProductType.CONSULTATION,
  ProductType.PSYCHOLOGIST_CONSULTATION,
  ProductType.PHYSIOTHERAPIST_CONSULTATION,
];

const consolidateProducts = (products) => {
  for (const key in products) {
    if (!includes(key, SUPPORTED_PRODUCT_TYPES)) {
      if (!products.CONSULTATION) {
        products.CONSULTATION = [];
      }
      products.CONSULTATION.push(...products[key]);
      delete products[key];
    }
  }

  return products;
};

export function ProductsCatalog({
  onProductSelected = noop,
  onExtraInfoSelected = noop,
  products = [],
  extraInfoEnabledFor = [],
  initialSelectedTabIndex = 0,
}) {
  const { formatMessage } = useIntl();
  const { screenSizeSelect } = useDevice();
  const [selectedProductTypeIndex, selectProductTypeIndex] = useState(
    initialSelectedTabIndex
  );
  const productsToShow = useMemo(
    () =>
      reject(
        { availabilityState: ProductAvailabilityState.UNAVAILABLE },
        some({ type: ProductType.VACCINATION }, products)
          ? concat(reject({ type: ProductType.VACCINATION }, products), [
              {
                __typename: "SubCatalog",
                id: "VaccinationCatalogScreen",
                availabilityState: ProductAvailabilityState.AVAILABLE,
                name: formatMessage(messages.vaccineGroupTitle),
                description: formatMessage(messages.vaccineGroupDescription),
                coverImageUrl: "vaccinesCover",
                primaryActionTitle: formatMessage(
                  messages.vaccinesPrimaryActionTitle
                ),
              },
            ])
          : products
      ),
    [products, formatMessage]
  );
  const productsByProductType = useMemo(
    () =>
      pick(
        SUPPORTED_PRODUCT_TYPES,
        consolidateProducts(groupBy("type", productsToShow))
      ),
    [productsToShow]
  );
  const productTypes = useMemo(
    () => ensureDoctorsFirst(keys(productsByProductType)),
    [productsByProductType]
  );
  const selectedProductType = productTypes[selectedProductTypeIndex];
  const productsOfCurrentlySelectedProductType =
    productsByProductType[selectedProductType];
  const onProductTypeSwitcherChange = useCallback(
    (event) => selectProductTypeIndex(event.nativeEvent.selectedSegmentIndex),
    [selectProductTypeIndex]
  );

  const productTypesAmount = size(productsByProductType);
  const shouldShowProductTypeSwitcher = productTypesAmount > 1;
  const allowScrollForProductTypeSwitcher = productTypesAmount > 2;
  const productTypesForSegmentedControl = useMemo(
    () =>
      map(
        (productType) =>
          formatMessage(
            getOr(
              messages.default_ProvidersName,
              `${productType}_ProvidersName`,
              messages
            )
          ),
        productTypes
      ),
    [formatMessage, productTypes]
  );
  return (
    <View>
      {size(products) === 0 ? (
        <Paragraph size="l" alignment="center">
          {formatMessage(messages.noProducts)}
        </Paragraph>
      ) : (
        <>
          {shouldShowProductTypeSwitcher && (
            <ScrollView
              horizontal
              scrollEnabled={allowScrollForProductTypeSwitcher}
              showsHorizontalScrollIndicator={false}
              style={styles.productTypeSwitcherContainer}
              contentContainerStyle={compact([
                styles.productTypeSwitcher,
                allowScrollForProductTypeSwitcher &&
                  stylingHelpers.getProductTypeSwitcherWidth(
                    productTypesAmount
                  ),
              ])}
            >
              <EyrSegmentedControl
                values={productTypesForSegmentedControl}
                selectedIndex={selectedProductTypeIndex}
                onChange={onProductTypeSwitcherChange}
                testID={"ProductTypeSwitcher"}
              />
            </ScrollView>
          )}
          {map((product) => {
            const {
              __typename,
              id,
              subtype,
              name,
              description,
              coverImageUrl,
              availabilityState,
              price,
              coverages,
              primaryActionTitle,
            } = product;
            return (
              <ProductCard
                itemType={__typename}
                key={id.toString()}
                title={name}
                description={description}
                image={coverImageUrl}
                price={price}
                coverages={coverages}
                disabled={
                  availabilityState === ProductAvailabilityState.PENDING_USAGE
                }
                layout={screenSizeSelect({ xs: "column", m: "row" })}
                buttons={compact([
                  includes(subtype, extraInfoEnabledFor) && {
                    title: formatMessage(messages.extraInfoActionTitle),
                    onPress: () => onExtraInfoSelected(product),
                    variant: "secondary",
                  },
                  {
                    title:
                      primaryActionTitle ||
                      formatMessage(messages.primaryActionTitle),
                    onPress: () => onProductSelected(product),
                    variant: "friendly",
                    accessibilityLabel: `Select ${name} product`,
                  },
                ])}
              />
            );
          }, productsOfCurrentlySelectedProductType)}
        </>
      )}
    </View>
  );
}

ProductsCatalog.propTypes = {
  onProductSelected: PropTypes.func,
  onExtraInfoSelected: PropTypes.func,
  products: PropTypes.array,
  extraInfoEnabledFor: PropTypes.array,
  initialSelectedTabIndex: PropTypes.number,
};
