import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  itemContainer: {
    marginRight: 12,
    alignSelf: "flex-start",
    marginBottom: 12,
  },
});
