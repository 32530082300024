import React, { useCallback, useState } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation } from "@react-navigation/native";
import { StaticConfig } from "@eyr-mobile/core/Config";
import { useIntl } from "react-intl";
import {
  subscriptionsLinkRef,
  useApolloClient,
} from "@eyr-mobile/core/DataProvider";

import { EyrTextInput, EyrButton, Subtitle } from "../../components";

import { styles } from "./EnableAppleReviewModeScreen.styles";
import { messages } from "./EnableAppleReviewModeScreen.messages";
export function EnableAppleReviewModeScreen() {
  const { formatMessage } = useIntl();

  const [textInput, setTextInput] = useState("");
  const { navigate } = useNavigation();
  const client = useApolloClient();

  const onSubmit = useCallback(() => {
    if (textInput === "Review") {
      const subscriptionsLink = subscriptionsLinkRef.current;
      StaticConfig.API_URI = "api.staging.eyr.md";
      if (subscriptionsLink) {
        subscriptionsLink?.teardown();
      }
      client.resetStore();
      navigate("HomeScreen");
    }
  }, [client, navigate, textInput]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <Subtitle size="l" spacing="l">
        {formatMessage(messages.passcodePromptTitle)}
      </Subtitle>
      <EyrTextInput
        value={textInput}
        placeholder={formatMessage(messages.placeholderPasscodeInputTitle)}
        onChangeText={setTextInput}
        autoFocus
        maxLength={10}
      />
      <EyrButton
        onPress={onSubmit}
        title={formatMessage(messages.passcodeConfirmationActionTitle)}
      />
    </SafeAreaView>
  );
}

EnableAppleReviewModeScreen.routeName = "EnableAppleReviewModeScreen";

EnableAppleReviewModeScreen.navigationOptions = {
  title: messages.navigationTitle,
};
