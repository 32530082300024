import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Pressable, View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { noop } from "lodash/fp";

import { SVGs } from "../../res/svgs";
import { Paragraph } from "../Paragraph";

import { styles, stylingProps } from "./RatingInput.styles";
import { messages } from "./RatingInput.messages";

const RATINGS = [
  {
    value: 1,
    icon: {
      enabled: SVGs.OneStarsEnabled,
      disabled: SVGs.OneStarsDisabled,
    },
  },
  {
    value: 2,
    icon: {
      enabled: SVGs.TwoStarsEnabled,
      disabled: SVGs.TwoStarsDisabled,
    },
  },
  {
    value: 3,
    icon: {
      enabled: SVGs.ThreeStarsEnabled,
      disabled: SVGs.ThreeStarsDisabled,
    },
  },
  {
    value: 4,
    icon: {
      enabled: SVGs.FourStarsEnabled,
      disabled: SVGs.FourStarsDisabled,
    },
  },
  {
    value: 5,
    icon: {
      enabled: SVGs.FiveStarsEnabled,
      disabled: SVGs.FiveStarsDisabled,
    },
  },
];

export function RatingInput({ rating, onChange = noop }) {
  const { formatMessage } = useIntl();
  const [internalRating, setInternalRating] = useState(rating);

  const stars = useMemo(
    () =>
      RATINGS.map(({ value, icon }) => {
        const Icon = icon[value === internalRating ? "enabled" : "disabled"];
        return (
          <Pressable
            onPress={() => {
              setInternalRating(value);
              onChange(value);
            }}
            key={value}
            accessibilityLabel={formatMessage(messages.ratingLabel, {
              rating: value,
            })}
          >
            <Icon {...stylingProps.starIcon} />
          </Pressable>
        );
      }),
    [onChange, internalRating, formatMessage]
  );

  return (
    <View style={styles.container}>
      <View style={styles.starsContainer}>{stars}</View>
      <View style={styles.sentimentDescriptionsContainer}>
        <Paragraph size="s" color="disabled">
          {formatMessage(messages.veryBadTitle)}
        </Paragraph>
        <Paragraph size="s" alignment="end" color="disabled">
          {formatMessage(messages.veryGoodTitle)}
        </Paragraph>
      </View>
    </View>
  );
}

RatingInput.propTypes = {
  rating: PropTypes.number,
  onChange: PropTypes.func,
};
