import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "OnboardingSelectInsuranceOrDiscountScreen.navigationTitle",
    description: "OnboardingSelectInsuranceOrDiscountScreen navigation title",
    defaultMessage: "Insurance or membership",
  },
  secondaryActionTitle: {
    id: "OnboardingSelectInsuranceOrDiscountScreen.secondaryActionTitle",
    description: "OnboardingSelectInsuranceOrDiscountScreen skip action title",
    defaultMessage: "Add later or pay with card",
  },
});
