import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useRoute } from "@react-navigation/native";
import { useAuth } from "@eyr-mobile/domain/Auth";
import {
  GetOnboardingAddInsuranceOrDiscountData,
  useOnboarding,
} from "@eyr-mobile/domain/Onboarding";
import { useDevice } from "@eyr-mobile/core/Device";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { useIntl } from "@eyr-mobile/core/Intl";

import { AddInsuranceOrDiscount, EyrButton } from "../../components";

import { styles } from "./OnboardingAddInsuranceOrDiscountScreen.styles";
import { messages } from "./OnboardingAddInsuranceOrDiscountScreen.messages";

export function OnboardingAddInsuranceOrDiscountScreen() {
  const route = useRoute();
  const { accessToken } = useAuth();
  const { completeOnboardingStage, org, orgCodeHint } = useOnboarding();
  const { formatMessage } = useIntl();
  const { screenSizeSelect } = useDevice();
  const orgParam = route.params?.org;
  const slug = orgParam || org;
  const { handlers, data } = withHandlers(
    useQuery(GetOnboardingAddInsuranceOrDiscountData, {
      variables: { slug },
    })
  );

  const handleSucceeded = useCallback(() => {
    completeOnboardingStage("OnboardingAddInsuranceOrDiscountScreen");
  }, [completeOnboardingStage]);

  const specificInsuranceOrDiscountUrl = useMemo(() => {
    if (!data) {
      return;
    }
    if (!orgCodeHint) {
      return data.org.newPolicyUrl;
    }
    const url = new URL(data.org.newPolicyUrl);
    url.searchParams.append("codeHint", orgCodeHint);
    return url.toString();
  }, [data, orgCodeHint]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <View
          style={[
            styles.contentContainer,
            screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            }),
          ]}
        >
          <AddInsuranceOrDiscount
            accessToken={accessToken}
            onSucceeded={handleSucceeded}
            specificInsuranceOrDiscountUrl={specificInsuranceOrDiscountUrl}
          />
        </View>
      )}
      <View style={styles.footerContainer}>
        <View
          style={screenSizeSelect({
            xs: styles.footerContentContainerXS,
            s: styles.footerContentContainerS,
            m: styles.footerContentContainerM,
          })}
        >
          <EyrButton
            variant="text"
            title={formatMessage(messages.secondaryActionTitle)}
            size="l"
            onPress={handleSucceeded}
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

OnboardingAddInsuranceOrDiscountScreen.routeName =
  "OnboardingAddInsuranceOrDiscountScreen";
OnboardingAddInsuranceOrDiscountScreen.navigationOptions = {
  title: messages.navigationTitle,
};
