import React from "react";
import { useIntl } from "react-intl";
import { FlatList, Image, View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation, useRoute } from "@react-navigation/native";
import { getOr } from "lodash/fp";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";

import { Heading, Option } from "../../components";
import { GetCountriesAvailableForAuthentication } from "../../../domain/Auth/Auth.data";

import { styles } from "./IdentificationCountryScreen.styles";
import { messages } from "./IdentificationCountryScreen.messages";

export function IdentificationCountryScreen() {
  const { formatMessage } = useIntl();
  const route = useRoute();
  const { navigate } = useNavigation();
  const { refreshing, handlers, data, refetch } = withHandlers(
    useQuery(GetCountriesAvailableForAuthentication, {
      context: { public: true },
    })
  );

  const routeParams = route.params || {};
  const { reidentification, returnRoute } = routeParams;

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <Heading spacing="l" alignment="center">
            {formatMessage(messages.countriesListTitle)}
          </Heading>
          <FlatList
            refreshing={refreshing}
            onRefresh={refetch}
            style={styles.identityProvidersList}
            data={getOr([], "countries", data)}
            keyExtractor={(item) => item.id.toString()}
            renderItem={({
              item: { codeIso2: countryCode, flag, name, loginDescription },
            }) => (
              <View style={styles.countriesListItemContainer}>
                <Option
                  title={name}
                  description={loginDescription}
                  image={
                    <Image source={{ uri: flag }} style={styles.countryFlag} />
                  }
                  onPress={() =>
                    navigate("IdentificationMethodScreen", {
                      reidentification,
                      returnRoute,
                      countryCode,
                    })
                  }
                />
              </View>
            )}
          />
        </>
      )}
    </SafeAreaView>
  );
}

IdentificationCountryScreen.routeName = "IdentificationCountryScreen";
IdentificationCountryScreen.navigationOptions = {
  title: messages.navigationTitle,
};
