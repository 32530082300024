import { defineMessages } from "react-intl";

export const messages = defineMessages({
  endCallActionTitle: {
    id: "OngoingCallScreen.endCallActionTitle",
    description: "OngoingCallScreen end call action title",
    defaultMessage: "Hang up",
  },
  ongoingCallNotificationSubtitle: {
    id: "OngoingCallScreen.ongoingCallNotificationSubtitle",
    description: "OngoingCallScreen ongoing call notification subtitle",
    defaultMessage: "Ongoing Eyr video call",
  },
  connectingStateTitle: {
    id: "OngoingCallScreen.connectingStateTitle",
    description: "OngoingCallScreen connecting state title",
    defaultMessage: "Connecting",
  },
});
