import React from "react";
import { ScrollView } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { getOr } from "lodash/fp";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { useWhiteLabel } from "@eyr-mobile/domain/WhiteLabel";
import { GetAccountCountryOrgs } from "@eyr-mobile/domain/Account";
import { useDevice } from "@eyr-mobile/core/Device";
import {
  useOrderFlowStage,
  useSetPaymentMethodsFlowStage,
} from "@eyr-mobile/domain/Order";

import {
  SelectInsuranceOrDiscount,
  CancelOrderHeaderRight,
  ProgressIndicator,
} from "../../components";

import { styles } from "./FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.styles";
import { messages } from "./FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.messages";

const getAccountCountryInsuranceProviders = getOr([], "account.country.orgs");

export function FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen() {
  const whitelabel = useWhiteLabel();
  const { screenSizeSelect } = useDevice();

  const {
    handlers,
    data,
    stageParams: { progress, progressPerStage } = {},
  } = withHandlers(
    useOrderFlowStage(GetAccountCountryOrgs, {
      variables: { whitelabel },
    })
  );
  const [{ addSpecificInsuranceOrMembership }] =
    useSetPaymentMethodsFlowStage();

  const insuranceProviders = getAccountCountryInsuranceProviders(data);
  const progressWithinSubStages = progress + (2 / 3) * progressPerStage;

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <ScrollView
            style={styles.contentContainer}
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
          >
            <SelectInsuranceOrDiscount
              insuranceProviders={insuranceProviders}
              onInsuranceOrDiscountPress={addSpecificInsuranceOrMembership}
            />
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.routeName =
  "FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen";
FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen.navigationOptions =
  {
    title: messages.navigationTitle,
    headerRight: CancelOrderHeaderRight,
  };
