import { StyleSheet, Platform } from "react-native";
import { compact, capitalize } from "lodash/fp";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

const ICON_MARGIN = 24;

export const styles = StyleSheet.create({
  invisibleOverlay: {
    ...StyleSheet.absoluteFill,
    ...Platform.select({
      web: {
        cursor: "default",
      },
    }),
  },
  overlay: {
    ...StyleSheet.absoluteFill,
    backgroundColor: Palette.OVERLAY_DARK_40,
  },
  modalContainer: {
    flex: 1,
    alignItems: "center",
    marginHorizontal: 32,
    justifyContent: "center",
    ...Platform.select({
      web: {
        pointerEvents: "none",
      },
    }),
  },
  bottomSheetContainer: {
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    ...Platform.select({
      web: {
        pointerEvents: "none",
      },
    }),
  },
  fullscreenContainer: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    ...Platform.select({
      web: {
        pointerEvents: "none",
      },
    }),
  },
  modalContentContainer: {
    width: "100%",
    maxHeight: "90%",
    borderRadius: 16,
    overflow: "hidden",
    backgroundColor: Palette.WHITE,
    ...Shadows.xl,
    ...Platform.select({
      web: {
        pointerEvents: "auto",
      },
    }),
  },
  fullscreenContentContainer: {
    overflow: "hidden",
    backgroundColor: Palette.WHITE,
    ...StyleSheet.absoluteFill,
    flex: 1,
    ...Platform.select({
      web: {
        pointerEvents: "auto",
      },
    }),
  },
  bottomSheetContentContainer: {
    width: "100%",
    maxHeight: "80%",
    borderRadius: 16,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    overflow: "hidden",
    backgroundColor: Palette.WHITE,
    ...Shadows.xl,
    ...Platform.select({
      web: {
        pointerEvents: "auto",
      },
    }),
  },
  responsiveContentContainerM: {
    width: 400,
  },
  responsiveContentContainerL: {
    width: 600,
  },
  header: { padding: 24 },
  headerWithDivider: {
    padding: 24,
    borderBottomColor: Palette.GRAY_20,
    borderBottomWidth: 1,
  },
  headingContainer: {
    marginHorizontal: 50,
  },
  bodyPaddingVerticalS: { paddingVertical: 16 },
  bodyPaddingVerticalM: { paddingVertical: 24 },
  bodyPaddingVerticalL: { paddingVertical: 40 },
  bodyPaddingHorizontalS: { paddingHorizontal: 16 },
  bodyPaddingHorizontalM: { padding: 24 },
  bodyPaddingHorizontalL: { paddingHorizontal: 40 },
  buttonsContainerWithoutMessage: {
    marginTop: 4,
  },
  buttonsContainerWithMessage: {
    marginTop: 24,
  },
  rowLayoutButtonContainer: {
    flex: 1,
    marginRight: 16,
  },
  columnLayoutButtonContainer: {
    marginBottom: 16,
  },
  rowLayoutLastButtonContainer: {
    flex: 1,
  },
  iconContainer: {
    marginBottom: 16,
    alignItems: "center",
  },
  closeIcon: {
    position: "absolute",
    zIndex: 1,
  },
  noPadding: {
    padding: 0,
  },
  bottomBar: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    padding: 16,
  },
});

export const stylingProps = {
  icon: {
    height: 82,
    width: 82,
  },
};

export const stylingHelpers = {
  getContainerStyle({ variant }) {
    return styles[`${variant}Container`];
  },
  getContentContainerStyle({ variant }) {
    return styles[`${variant}ContentContainer`];
  },
  getContentContainerResponsiveStyle({ size }) {
    switch (size) {
      case "m":
        return styles.responsiveContentContainerM;
      case "l":
        return styles.responsiveContentContainerL;
    }
  },
  getHeaderStyle(showHeaderDivider) {
    if (showHeaderDivider) {
      return styles.headerWithDivider;
    }
    return styles.header;
  },
  getBodyStyle(paddingHorizontalSize, paddingVerticalSize) {
    return compact([
      styles[`bodyPaddingHorizontal${capitalize(paddingHorizontalSize)}`],
      styles[`bodyPaddingVertical${capitalize(paddingVerticalSize)}`],
    ]);
  },
  getCloseIconStyle({ variant, insets }) {
    return [
      styles.closeIcon,
      {
        top: ICON_MARGIN + (variant === "fullscreen" ? insets.top : 0),
        right: ICON_MARGIN + (variant === "fullscreen" ? insets.right : 0),
      },
    ];
  },
  getBottomBarStyle(insets) {
    return [
      styles.bottomBar,
      insets.bottom && { paddingBottom: 16 + insets.bottom },
    ];
  },
};
