import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    paddingBottom: 16,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    paddingBottom: 32,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    paddingBottom: 48,
    ...responsiveStyles.contentContainerM,
  },
  headerIconContainer: {
    marginBottom: 24,
    alignItems: "center",
  },
  sectionsContainer: {
    marginTop: 24,
    marginBottom: 12,
  },
  sectionSpacer: {
    height: 12,
  },
  section: {
    padding: 16,
  },
  sectionChapter: {
    marginBottom: 24,
  },
  sectionButton: {
    marginBottom: 8,
  },
});

export const stylingProps = {
  icon: {
    width: 144,
    height: 144,
  },
};
