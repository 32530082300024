import React, {
  useState,
  Children,
  cloneElement,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { noop, without } from "lodash/fp";

import { styles } from "./ToggleButtonGroup.styles.js";

export function ToggleButtonGroup({
  value: initialValue = [],
  children,
  onChange = noop,
}) {
  const [selected, setSelected] = useState(initialValue);

  const isSelected = useCallback(
    (value) => selected.includes(value),
    [selected]
  );

  const handleToggle = useCallback(
    (value) => {
      setSelected(
        isSelected(value) ? without([value], selected) : [...selected, value]
      );
    },
    [selected, isSelected]
  );

  useEffect(() => {
    onChange(selected);
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  const items = useMemo(
    () =>
      Children.map(children, (child) => {
        const { value, onPress } = child.props;
        return (
          <View style={styles.itemContainer}>
            {cloneElement(child, {
              onPress: () => {
                handleToggle(value);
                if (onPress) {
                  onPress();
                }
              },
              selected: isSelected(value),
            })}
          </View>
        );
      }),
    [children, handleToggle, isSelected]
  );

  return <View style={styles.container}>{items}</View>;
}

ToggleButtonGroup.propTypes = {
  value: PropTypes.array,
  children: PropTypes.node,
  onChange: PropTypes.func,
};
