import { StyleSheet } from "react-native";
import { fontMaker } from "@eyr-mobile/core/Lib";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 24,
    backgroundColor: Palette.WHITE,
    borderRadius: 8,
    ...Shadows.xs,
  },
  starsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12,
  },
  sentimentDescriptionsContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  sentimentDescription: {
    ...fontMaker(),
    color: Palette.GRAY_100,
    fontSize: 12,
    lineHeight: 18,
  },
});

export const stylingProps = {
  starIcon: {
    width: 44,
    height: 44,
  },
};
