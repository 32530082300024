import React, { useRef, useEffect, useState, useCallback } from "react";
import videojs from "video.js";
import "@videojs/http-streaming";
import "video.js/dist/video-js.css";
import { renderIf } from "@eyr-mobile/core/Lib";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  MILLISECONDS_TO_SKIP,
  CONTROLS_HIDE_TIMEOUT,
} from "@eyr-mobile/core/Video";
import { View } from "react-native";
import { PropTypes } from "prop-types";

import { SVGs } from "../../res";
import { PlayerControls } from "../PlayerControls";
import { EyrButton } from "../EyrButton";
import { Loading } from "../Loading";

import { styles } from "./VideoPlayer.styles";
import { messages } from "./VideoPlayer.messages";

export function VideoPlayer({ source, ...rest }) {
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const hideControlsTimeoutIDRef = useRef(null);
  const [startedPlaying, setStartedPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showControls, setShowControls] = useState(true);
  const [status, setStatus] = useState({
    isPlaying: false,
    isLoading: true,
    positionMillis: 0,
    durationMillis: 0,
  });
  const { formatMessage } = useIntl();

  const handleClearHideControlsTimeout = useCallback(() => {
    if (!hideControlsTimeoutIDRef.current) {
      return;
    }
    clearTimeout(hideControlsTimeoutIDRef.current);
  }, []);

  useEffect(() => {
    return handleClearHideControlsTimeout;
  }, [handleClearHideControlsTimeout]);

  const handleHideControlsAfterPeriod = useCallback(() => {
    handleClearHideControlsTimeout();
    hideControlsTimeoutIDRef.current = setTimeout(() => {
      setShowControls(false);
    }, CONTROLS_HIDE_TIMEOUT);
  }, [handleClearHideControlsTimeout]);

  const handleStartPlay = useCallback(() => {
    setStartedPlaying(true);
    playerRef.current.play();
    handleHideControlsAfterPeriod();
  }, [handleHideControlsAfterPeriod]);

  const handleTogglePlayback = useCallback(async () => {
    if (status.isPlaying) {
      playerRef.current.pause();
      handleClearHideControlsTimeout();
    } else {
      playerRef.current.play();
      handleHideControlsAfterPeriod();
    }
  }, [status, handleHideControlsAfterPeriod, handleClearHideControlsTimeout]);

  const handleBackwards = useCallback(() => {
    handleHideControlsAfterPeriod();
    playerRef.current.currentTime(
      playerRef.current.currentTime() - MILLISECONDS_TO_SKIP / 1000
    );
  }, [handleHideControlsAfterPeriod]);

  const handleForwards = useCallback(() => {
    handleHideControlsAfterPeriod();
    playerRef.current.currentTime(
      playerRef.current.currentTime() + MILLISECONDS_TO_SKIP / 1000
    );
  }, [handleHideControlsAfterPeriod]);

  const handleSeek = useCallback(
    async (value) => {
      if (playerRef.current != null) {
        playerRef.current.currentTime(value / 1000);
        if (status.isPlaying) {
          handleHideControlsAfterPeriod();
        }
      }
    },
    [status.isPlaying, handleHideControlsAfterPeriod]
  );

  const handlePressContainer = useCallback(() => {
    setShowControls(true);
    if (!status.isPlaying) {
      return;
    }
    handleHideControlsAfterPeriod();
  }, [handleHideControlsAfterPeriod, status.isPlaying]);

  const handleToggleFullScreen = useCallback(() => {
    if (isFullScreen) {
      playerRef.current.exitFullscreen();
    } else {
      playerRef.current.requestFullscreen();
    }
    setIsFullScreen(!isFullScreen);
  }, [isFullScreen]);

  useEffect(() => {
    if (!playerRef.current) {
      const containerElement = containerRef.current;

      if (!containerElement) {
        return;
      }

      const player = (playerRef.current = videojs(
        containerElement,
        {
          fluid: true,
          ...rest,
          sources: source.uri,
          controls: false,
        },
        () => {
          rest.onReady && rest.onReady(player);
          setStatus((oldStatus) => ({
            ...oldStatus,
            isLoading: false,
          }));
        }
      ));
      player.on("play", () =>
        setStatus((oldStatus) => ({
          ...oldStatus,
          isPlaying: true,
        }))
      );
      player.on("pause", () =>
        setStatus((oldStatus) => ({
          ...oldStatus,
          isPlaying: false,
        }))
      );
      player.on("timeupdate", () =>
        setStatus((oldStatus) => ({
          ...oldStatus,
          positionMillis: player.currentTime() * 1000,
        }))
      );
      player.on("durationchange", () =>
        setStatus((oldStatus) => ({
          ...oldStatus,
          durationMillis: player.duration() * 1000,
        }))
      );
      player.on("fullscreenchange", () => {
        setIsFullScreen(player.isFullscreen());
      });
    }
  }, [source, rest]);

  useEffect(() => {
    return () => {
      const player = playerRef.current;
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    playerRef.current.controls(isFullScreen);
  }, [isFullScreen]);

  return (
    <div onMouseEnter={handlePressContainer} style={styles.containerWeb}>
      <video ref={containerRef} className="video-js" />

      <View style={styles.overlayContainer}>
        {renderIf(status.isLoading)(<Loading />)}
        {renderIf(!startedPlaying && !status.isLoading)(
          <EyrButton
            variant="secondary"
            title={formatMessage(messages.play)}
            IconComponent={SVGs.PlayCircleMono}
            onPress={handleStartPlay}
          />
        )}
        {renderIf(startedPlaying && showControls)(() => (
          <View style={styles.controlsContainer}>
            <PlayerControls
              onTogglePlayPause={handleTogglePlayback}
              isPlaying={status.isPlaying}
              isLoading={status.isLoading}
              onBackwards={handleBackwards}
              onForwards={handleForwards}
              onSeek={handleSeek}
              onSeekStart={handleClearHideControlsTimeout}
              positionMillis={status.positionMillis}
              durationMillis={status.durationMillis}
              variant="video"
              showFullScreenToggle={true}
              onFullScreenToggle={handleToggleFullScreen}
              isFullscreen={isFullScreen}
            />
          </View>
        ))}
      </View>
    </div>
  );
}

VideoPlayer.propTypes = {
  source: PropTypes.shape({
    uri: PropTypes.string,
  }),
};
