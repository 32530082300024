import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  introContainer: { paddingHorizontal: 20, paddingBottom: 24 },
  productInfoContainer: {
    paddingHorizontal: 20,
    paddingVertical: 24,
    backgroundColor: Palette.PINK_0,
    rowGap: 16,
  },
  productInfoCardContainer: {
    padding: 20,
    borderRadius: 16,
    backgroundColor: Palette.WHITE,
    rowGap: 16,
  },
  productInfoItemContainer: {
    rowGap: 12,
  },
  productInfoItem: {
    columnGap: 8,
    flexDirection: "row",
  },
  productInfoItemIconContainer: {
    width: 20,
    height: 20,
  },
  footerContainer: {
    paddingHorizontal: 20,
    paddingTop: 20,
    backgroundColor: Palette.WHITE,
  },
  partnershipLogosContainer: {
    flexDirection: "row",
    marginBottom: 12,
    alignItems: "center",
  },
});

export const stylingProps = {
  productInfoItemIcon: {
    width: 20,
    height: 20,
    fill: Palette.BURGUNDY_100,
  },
  ourLogoIcon: {
    height: 28,
    width: 61,
  },
  conjunctionIcon: {
    height: 16,
    width: 16,
    fill: Palette.BURGUNDY_0,
  },
  theirLogoIcon: {
    height: 20,
  },
};
