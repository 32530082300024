import { useState, useEffect, useRef } from "react";
import {
  getForegroundPermissionsAsync,
  getBackgroundPermissionsAsync,
  requestForegroundPermissionsAsync,
  requestBackgroundPermissionsAsync,
  getCurrentPositionAsync,
  getLastKnownPositionAsync,
  watchPositionAsync,
  Accuracy,
} from "expo-location";

import { LoggerFactory } from "../Logger";

const logger = LoggerFactory.get("core/Location");

export const useCurrentPosition = ({
  watch = false,
  watchOptions = {
    timeInterval: 30 * 1000,
  },
  accuracy,
  skip = false,
}) => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [loading, setLoading] = useState(null);
  const stopWatchingRef = useRef(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    const handler = async () => {
      const position = await getCurrentPositionAsync({
        accuracy,
      });
      setCurrentPosition(position);
      setLoading(false);
    };
    handler();
  }, [setLoading, setCurrentPosition, skip, accuracy]);

  useEffect(() => {
    const handleAsync = async () => {
      if (watch) {
        if (!stopWatchingRef.current && !skip) {
          const { remove } = await watchPositionAsync(
            watchOptions,
            (position) => {
              setCurrentPosition(position);
              setLoading(false);
            }
          );
          stopWatchingRef.current = { remove };
        }
      } else if (stopWatchingRef.current) {
        stopWatchingRef.current.remove();
        stopWatchingRef.current = null;
      }
    };

    handleAsync();

    return () => {
      if (stopWatchingRef.current) {
        stopWatchingRef.current.remove();
      }
    };
  }, [setCurrentPosition, setLoading, watch, skip, watchOptions]);

  useEffect(
    () => logger("useCurrentPosition", currentPosition),
    [currentPosition]
  );

  return {
    currentPosition,
    loading,
  };
};

export const useLastKnownPosition = ({
  skip = false,
  maxAge,
  requiredAccuracy,
}) => {
  const [lastKnownPosition, setLastKnownPosition] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (skip) {
      return;
    }
    const handler = async () => {
      const position = await getLastKnownPositionAsync({
        maxAge,
        requiredAccuracy,
      });
      setLastKnownPosition(position);
      setLoading(false);
    };
    handler();
  }, [skip, setLastKnownPosition, setLoading, maxAge, requiredAccuracy]);
  useEffect(
    () => logger("useLastKnownPosition", lastKnownPosition),
    [lastKnownPosition]
  );

  return {
    lastKnownPosition,
    loading,
  };
};

export {
  getForegroundPermissionsAsync,
  getBackgroundPermissionsAsync,
  requestForegroundPermissionsAsync,
  requestBackgroundPermissionsAsync,
  Accuracy,
};
