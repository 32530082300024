import { Palette } from "../../shared-styles/palette";

export const stylingProps = {
  icon: {
    width: 28,
    height: 28,
    fill: Palette.BURGUNDY_80,
  },
  pressable: {
    android_ripple: {
      color: Palette.NUDE_100,
      borderless: true,
    },
    hitSlop: 8,
  },
};
