import React from "react";
import SegmentedControl from "@react-native-segmented-control/segmented-control";

import { styles, stylingProps } from "./EyrSegmentedControl.styles";

export function EyrSegmentedControl(props) {
  return (
    <SegmentedControl {...props} {...stylingProps} style={styles.container} />
  );
}
