import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  disabledContainer: {
    backgroundColor: Palette.OVERLAY_DARK_8,
  },
  // primary
  primaryContainer: {
    backgroundColor: Palette.BURGUNDY_100,
  },
  primaryActiveContainer: {
    backgroundColor: Palette.BURGUNDY_125,
  },
  // secondary
  secondaryContainer: {
    borderWidth: 1,
    backgroundColor: Palette.WHITE,
    borderColor: Palette.OVERLAY_DARK_8,
  },
  secondaryActiveContainer: {
    borderWidth: 1,
    backgroundColor: Palette.WHITE_20,
    borderColor: Palette.OVERLAY_DARK_4,
  },
  // friendly
  friendlyContainer: {
    backgroundColor: Palette.PINK_60,
  },
  friendlyActiveContainer: {
    backgroundColor: Palette.PINK_80,
  },
  // positive
  positiveContainer: {
    backgroundColor: Palette.GREEN_100,
  },
  positiveActiveContainer: {
    backgroundColor: Palette.GREEN_125,
  },
  // negative
  negativeContainer: {
    backgroundColor: Palette.RED_100,
  },
  negativeActiveContainer: {
    backgroundColor: Palette.RED_125,
  },
  // text
  textContainer: {
    backgroundColor: Palette.TRANSPARENT,
    borderRadius: 8,
  },
  textActiveContainer: {
    backgroundColor: Palette.OVERLAY_DARK_4,
    borderRadius: 8,
  },
  // text-secondary
  "text-secondaryContainer": {
    backgroundColor: Palette.TRANSPARENT,
    borderRadius: 8,
  },
  "text-secondaryActiveContainer": {
    backgroundColor: Palette.OVERLAY_DARK_4,
    borderRadius: 8,
  },

  sContainer: {
    minWidth: 80,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
  },
  mContainer: {
    minWidth: 80,
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 8,
  },
  lContainer: {
    minWidth: 80,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderRadius: 8,
  },
  sIconOnlyContainer: {
    padding: 8,
    borderRadius: 17,
  },
  mIconOnlyContainer: {
    padding: 12,
    borderRadius: 22,
  },
  lIconOnlyContainer: {
    padding: 16,
    borderRadius: 27,
  },
  sSecondaryContainer: {
    minWidth: 80,
    paddingVertical: 7,
    paddingHorizontal: 15,
    borderRadius: 4,
  },
  mSecondaryContainer: {
    minWidth: 80,
    paddingVertical: 11,
    paddingHorizontal: 19,
    borderRadius: 8,
  },
  lSecondaryContainer: {
    minWidth: 80,
    paddingVertical: 15,
    paddingHorizontal: 23,
    borderRadius: 8,
  },
  sSecondaryIconOnlyContainer: {
    padding: 7,
    borderRadius: 17,
  },
  mSecondaryIconOnlyContainer: {
    padding: 11,
    borderRadius: 22,
  },
  lSecondaryIconOnlyContainer: {
    padding: 15,
    borderRadius: 27,
  },
  sTextContainer: {
    minWidth: 80,
    padding: 8,
    borderRadius: 4,
  },
  mTextContainer: {
    minWidth: 80,
    paddingVertical: 12,
    paddingHorizontal: 8,
    borderRadius: 8,
  },
  lTextContainer: {
    minWidth: 80,
    paddingVertical: 16,
    paddingHorizontal: 8,
    borderRadius: 8,
  },
  sTextIconOnlyContainer: {
    padding: 8,
  },
  mTextIconOnlyContainer: {
    paddingVertical: 12,
    paddingHorizontal: 8,
  },
  lTextIconOnlyContainer: {
    paddingVertical: 16,
    paddingHorizontal: 8,
  },
  sLeadingIcon: {
    marginRight: 4,
  },
  mLeadingIcon: {
    marginRight: 6,
  },
  lLeadingIcon: {
    marginRight: 8,
  },
  sTrailingIcon: {
    marginLeft: 4,
  },
  mTrailingIcon: {
    marginLeft: 6,
  },
  lTrailingIcon: {
    marginLeft: 8,
  },
});

export const stylingProps = {
  primaryTitle: {
    color: "secondary",
  },
  secondaryTitle: {
    color: "primary",
  },
  friendlyTitle: {
    color: "primary",
  },
  positiveTitle: {
    color: "secondary",
  },
  negativeTitle: {
    color: "secondary",
  },
  textTitle: {
    color: "primary",
  },
  "text-secondaryTitle": {
    color: "secondary",
  },
  disabledTitle: {
    color: "disabled",
  },
  sTitle: {
    size: "s",
  },
  mTitle: {
    size: "m",
  },
  lTitle: {
    size: "l",
  },
  sIcon: {
    width: 18,
    height: 18,
  },
  mIcon: {
    width: 20,
    height: 20,
  },
  lIcon: {
    width: 22,
    height: 22,
  },
  primaryIcon: {
    fill: Palette.WHITE,
  },
  secondaryIcon: {
    fill: Palette.BURGUNDY_100,
  },
  friendlyIcon: {
    fill: Palette.BURGUNDY_100,
  },
  positiveIcon: {
    fill: Palette.WHITE,
  },
  negativeIcon: {
    fill: Palette.WHITE,
  },
  textIcon: {
    fill: Palette.BURGUNDY_100,
  },
  "text-secondaryIcon": {
    fill: Palette.WHITE,
  },
  disabledIcon: {
    fill: Palette.GRAY_100,
  },
};
