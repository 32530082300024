export const PaletteAvonova = {
  NUDE_200: "#53372E",
  NUDE_175: "#68463B",
  NUDE_150: "#775144",
  NUDE_125: "#8C6251",
  NUDE_100: "#AB816D",
  NUDE_80: "#CFAB9A",
  NUDE_60: "#E2CDC3",
  NUDE_40: "#F3E8E3",
  NUDE_20: "#FAF7F6",
  NUDE_0: "#FDFBFA",
  BURGUNDY_125: "#00211B",
  BURGUNDY_100: "#01332A",
  BURGUNDY_80: "#334D49",
  BURGUNDY_60: "#667A76",
  BURGUNDY_40: "#99A6A4",
  BURGUNDY_20: "#CCD3D1",
  BURGUNDY_0: "#E6E9E8",
  DARK_BLUE_125: "#00100D",
  DARK_BLUE_100: "#00211B",
  DARK_BLUE_80: "#01332A",
  DARK_BLUE_60: "#334D49",
  TURQUOISE_100: "#09CCA6",
  TURQUOISE_80: "#6BE2CC",
  TURQUOISE_60: "#88F0DD",
  TURQUOISE_40: "#BDFBF0",
  TURQUOISE_20: "#E8FDF9",
  TURQUOISE_0: "#F4FFFD",
  PINK_100: "#CB9CFF",
  PINK_80: "#D5B0FF",
  PINK_60: "#E0C3FF",
  PINK_40: "#EAD7FF",
  PINK_20: "#F5EBFF",
  PINK_0: "#FAF5FF",
};

export const IconPaletteAvonova = {
  BURGUNDY_100: PaletteAvonova.BURGUNDY_100,
  NUDE_80: PaletteAvonova.PINK_100,
  NUDE_60: PaletteAvonova.PINK_40,
  PINK_60: PaletteAvonova.PINK_40,
  DARK_BLUE_100: "#410583",
  DARK_BLUE_60: "#6C33AC",
  TURQUOISE_60: PaletteAvonova.PINK_40,
};
