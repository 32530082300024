import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "OnboardingAddInsuranceOrDiscountScreen.navigationTitle",
    description: "OnboardingAddInsuranceOrDiscountScreen navigation title",
    defaultMessage: "Add insurance or membership",
  },
  secondaryActionTitle: {
    id: "OnboardingAddInsuranceOrDiscountScreen.secondaryActionTitle",
    description: "OnboardingAddInsuranceOrDiscountScreen skip action title",
    defaultMessage: "Add later or pay with card",
  },
});
