import { gql } from "@apollo/client";

export const GetPharmacies = gql`
  query GetPharmacies {
    pharmacies {
      name
      address
      lat
      lng
      openingHoursWeekDay
      openingHoursWeekEnd
      city
    }
  }
`;
