import { useEffect } from "react";
import { Platform } from "react-native";
import { noop } from "lodash/fp";

export const useScript = ({ url, onLoad = noop }) => {
  useEffect(() => {
    if (Platform.OS !== "web") {
      return;
    }
    const script = document.createElement("script");

    script.src = url;
    script.async = true;
    script.onload = onLoad;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url, onLoad]);
};
