import React, { useCallback, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { View, Pressable } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  formatNextTwoDaysAsRelative_DateOtherwise,
  renderIf,
  renderIfElse,
  useInterval,
  isDelayed,
} from "@eyr-mobile/core/Lib";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import { noop, upperFirst } from "lodash/fp";

import { Paragraph } from "../Paragraph";
import { IconContainer } from "../IconContainer";
import { ServiceCard } from "../ServiceCard";
import { SVGs } from "../../res";

import { styles } from "./PatientGuideServiceCard.styles";
import { messages } from "./PatientGuideServiceCard.messages";

const logger = LoggerFactory.get("component/PatientGuideServiceCard");
const UPDATE_INTERVAL = 30000;

export function PatientGuideServiceCard({
  summary,
  serviceState,
  provider,
  attachedDocumentsCount = 0,
  onDocumentListPress = noop,
  onPreparationNoticePress = noop,
  onCancellationInfoPress = noop,
  onDismissPress = noop,
  onPress = noop,
  date,
  testOnly_nowOverride,
  testOnly_initialProvider,
}) {
  const {
    image: providerImage,
    name: providerName,
    description: providerDescription,
  } = provider;
  const [initialProvider] = useState(testOnly_initialProvider || provider);
  const [now, setNow] = useState(
    testOnly_nowOverride ? new Date(testOnly_nowOverride).getTime() : Date.now()
  );
  useInterval(() => setNow(now + UPDATE_INTERVAL), UPDATE_INTERVAL, false);
  const intl = useIntl();
  const { formatMessage, formatTime } = intl;

  const textColor = serviceState === "REFUNDED" ? "dark" : "primary";

  const formattedDate = useMemo(
    () =>
      upperFirst(
        formatNextTwoDaysAsRelative_DateOtherwise({
          date,
          formatDateOptions: {
            weekday: "long",
            day: "numeric",
            month: "long",
          },
          intl,
          nowOverride: now,
        })
      ),
    [date, intl, now]
  );
  const delayed = useMemo(() => isDelayed({ date, now }), [now, date]);
  const reassigned = useMemo(
    () => initialProvider?.id !== provider?.id,
    [initialProvider, provider]
  );
  const handleDocumentUploadPress = useCallback(
    () => onDocumentListPress({ pickDocumentOnMount: true }),
    [onDocumentListPress]
  );
  const handleDocumentListPress = useCallback(
    () => onDocumentListPress(),
    [onDocumentListPress]
  );

  const alert = (
    <View style={styles.alertMessageContainer}>
      {renderIf(delayed)(
        <Paragraph color="info" size="l">
          {formatMessage(messages.delayedHeader, {
            serviceProviderTitle: formatMessage(
              messages.serviceProviderTitleFor_CONSULTATION,
              {
                amount: 1,
              }
            ),
          })}
        </Paragraph>
      )}
      {renderIf(reassigned)(
        <Paragraph color="info" size="l">
          {formatMessage(messages.reassignedHeader, { providerName })}
        </Paragraph>
      )}
    </View>
  );

  const instructionDescription = renderIfElse(attachedDocumentsCount)(
    <View style={styles.documentsCountContainer}>
      <View style={styles.documentsCountIcon}>
        <IconContainer variant="plain" size="xxs">
          <SVGs.CheckMono />
        </IconContainer>
      </View>
      <View style={styles.documentsCountText}>
        <Paragraph size="l">
          {formatMessage(messages.attachedDocumentsCount, {
            attachedDocumentsCount,
          })}
        </Paragraph>
      </View>
      <Pressable onPress={handleDocumentListPress}>
        <Paragraph decoration="underline" size="l">
          {formatMessage(messages.viewDocumentsActionTitle)}
        </Paragraph>
      </Pressable>
    </View>,
    formatMessage(messages.uploadDocumentsExplaination)
  );

  const commonProps = {
    summary,
    textColor,
    providerName,
    providerDescription,
    providerImage,
  };

  switch (serviceState) {
    case "INITIAL_BOOKED":
      return (
        <ServiceCard
          {...commonProps}
          showAlert={delayed || reassigned}
          alertTitle={alert}
          informationTitle={formatMessage(
            messages.upcomingAppointmentDetailsTitle
          )}
          informationDescription={formatMessage(
            messages.bookedConsultationDate,
            {
              date: formattedDate,
              time: formatTime(date),
            }
          )}
          footerList={[
            {
              onPress: onPreparationNoticePress,
              message: formatMessage(messages.listItemHowToPrepareTitle),
            },
            {
              onPress: onCancellationInfoPress,
              message: formatMessage(messages.listItemCancellationInfoTitle),
            },
          ]}
        />
      );

    case "INITIAL_COMPLETED":
      return (
        <ServiceCard
          {...commonProps}
          instructionDescription={instructionDescription}
          instructionAction={{
            title: formatMessage(messages.uploadDocumentActionTitle),
            onPress: handleDocumentUploadPress,
            IconComponent: SVGs.UploadMono,
          }}
          footerList={[
            {
              onPress: onPreparationNoticePress,
              message: formatMessage(messages.listItemHowToPrepareTitle),
            },
            {
              onPress: onCancellationInfoPress,
              message: formatMessage(messages.listItemCancellationInfoTitle),
            },
          ]}
          serviceCardActionTitle={formatMessage(
            messages.bookFollowUpActionTitle
          )}
          onServiceCardActionPress={onPress}
        />
      );

    case "FOLLOWUP_BOOKED":
      return (
        <ServiceCard
          {...commonProps}
          showAlert={delayed || reassigned}
          alertTitle={alert}
          informationTitle={formatMessage(
            messages.upcomingAppointmentDetailsTitle
          )}
          informationDescription={formatMessage(
            messages.bookedConsultationDate,
            {
              date: formattedDate,
              time: formatTime(date),
            }
          )}
          instructionDescription={instructionDescription}
          instructionAction={{
            title: formatMessage(messages.uploadDocumentActionTitle),
            onPress: handleDocumentUploadPress,
            IconComponent: SVGs.UploadMono,
          }}
          footerList={[
            {
              onPress: onPreparationNoticePress,
              message: formatMessage(messages.listItemHowToPrepareTitle),
            },
            {
              onPress: onCancellationInfoPress,
              message: formatMessage(messages.listItemCancellationInfoTitle),
            },
          ]}
        />
      );

    case "REFUNDED":
      return (
        <ServiceCard
          {...commonProps}
          isGrayscale
          alertTitle={formatMessage(messages.refundedAppointmentInfoMessage)}
          informationTitle={formatMessage(
            messages.refundedAppointmentDetailsTitle
          )}
          informationDescription={formatMessage(
            messages.bookedConsultationDate,
            {
              date: formattedDate,
              time: formatTime(date),
            }
          )}
          onDismissPress={onDismissPress}
          showDismiss={true}
        />
      );

    default: {
      logger(
        "`serviceState` value unsupported",
        serviceState,
        LOGGER_LEVEL_ERROR
      );
      return null;
    }
  }
}

PatientGuideServiceCard.propTypes = {
  summary: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  serviceState: PropTypes.oneOf([
    "INITIAL_BOOKED",
    "INITIAL_COMPLETED",
    "FOLLOWUP_BOOKED",
    "REFUNDED",
  ]).isRequired,
  provider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  testOnly_initialProvider: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    image: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  attachedDocumentsCount: PropTypes.number,
  onDocumentListPress: PropTypes.func,
  onPreparationNoticePress: PropTypes.func,
  onCancellationInfoPress: PropTypes.func,
  onDismissPress: PropTypes.func,
  onPress: PropTypes.func,
  testOnly_nowOverride: PropTypes.string,
};
