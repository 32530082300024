import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  noScheduleItemsContainer: {
    marginHorizontal: 40,
    flex: 1,
    justifyContent: "center",
  },
  iconContainer: {
    marginBottom: 16,
    alignSelf: "center",
  },
  sectionHeaderContainer: {
    marginTop: 24,
    marginBottom: 8,
  },
});
