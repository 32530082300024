import { defineMessages } from "react-intl";

export const messages = defineMessages({
  reviewSuccessAlertTitle: {
    id: "Alerts.reviewSuccessAlertTitle",
    description: "Review success alert title",
    defaultMessage: "We appreciate your feedback!",
  },
  reviewSuccessWithBadRatingAlertBody: {
    id: "Alerts.reviewSuccessWithBadRatingAlertBody",
    description: "Review success alert for review with bad rating body",
    defaultMessage:
      "Unfortunately, we do not have capacity to reply to everyone. But we carefully review every feedback. Thank you for helping us improve!",
  },
  closeActionTitle: {
    id: "Alerts.closeActionTitle",
    description: "Close button text",
    defaultMessage: "Close",
  },
  completedOrderAlertTitle: {
    id: "HomeTabScreen.completedOrderAlertTitle",
    defaultMessage: "Thank you for the order!",
    description: "completed order alert title",
  },
  completedOrderAlertMessage: {
    id: "HomeTabScreen.completedOrderAlertMessage",
    defaultMessage:
      "We will remind you about your appointment at +{phoneCountryCode}{phoneNumber}",
    description: "completed order alert description text",
  },
  completedOrderAlertConfirmActionTitle: {
    id: "HomeTabScreen.completedOrderAlertConfirmActionTitle",
    defaultMessage: "OK",
    description: "completed order confirm action title",
  },
  completedOrderAlertChangeContactActionTitle: {
    id: "HomeTabScreen.completedOrderAlertChangeContactActionTitle",
    defaultMessage: "Update phone number",
    description: "completed order change contact info action title",
  },
});
