import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { View } from "react-native";
import { Grid, Col, Row } from "react-native-easy-grid";
import { renderIf } from "@eyr-mobile/core/Lib";
import { configureSSNValidator } from "@eyr-mobile/core/Validation";
import { useForm } from "@eyr-mobile/core/Form";
import { noop } from "lodash/fp";

import { EyrTextInput } from "../EyrTextInput";
import { EyrButton } from "../EyrButton";
import { Paragraph } from "../Paragraph";
import { Heading } from "../Heading";

import { messages } from "./AddChild.messages";
import { styles } from "./AddChild.styles";

export const AddChild = ({ countryCode, consentAge = 16, onSubmit = noop }) => {
  const { formatMessage } = useIntl();
  const countryUidValidator = useMemo(
    () => configureSSNValidator(countryCode, messages.invalidNIN),
    [countryCode]
  );
  const {
    form,
    fields: { countryUid },
  } = useForm(
    {
      initialValues: { countryUid: "" },
      validators: { countryUid: countryUidValidator },
      onSubmit,
    },
    { validateOnBlur: true, validateOnChange: true }
  );

  return (
    <Grid>
      <Row size={3}>
        <Col style={styles.container}>
          <Heading spacing="l" alignment="center">
            {formatMessage(messages.title)}
          </Heading>
          <View style={styles.inputContainer}>
            <EyrTextInput
              {...countryUid}
              onChangeText={countryUid.onChange}
              placeholder={formatMessage(messages.ninPlaceholder)}
              accessibilityLabel={formatMessage(messages.ninPlaceholder)}
              keyboardType={"number-pad"}
              spellCheck={false}
              autoCorrect={false}
              clearButtonMode="while-editing"
              returnKeyType={"done"}
              variant={countryUid.invalid ? "danger" : "primary"}
              textAlign={"center"}
            />
          </View>
          <Paragraph size="l" spacing="none" alignment="center">
            {formatMessage(messages.explanation, { consentAge })}
          </Paragraph>
        </Col>
      </Row>
      <Row size={3}>
        <Col>
          {renderIf(countryUid.touched && countryUid.error)(() => (
            <Paragraph color="negative" alignment="center">
              {formatMessage(...countryUid.error)}
            </Paragraph>
          ))}
          {renderIf(form.error)(() => (
            <Paragraph color="negative" alignment="center">
              {formatMessage(messages.defaultError)}
            </Paragraph>
          ))}
        </Col>
      </Row>
      <Row>
        <Col style={styles.container}>
          <EyrButton
            onPress={form.submit}
            title={formatMessage(messages.next)}
            variant={"primary"}
            disabled={!form.valid}
            accessibilityLabel={formatMessage(messages.next)}
          />
        </Col>
      </Row>
    </Grid>
  );
};

AddChild.propTypes = {
  countryCode: PropTypes.oneOf(["NO", "DK", "SV"]).isRequired,
  consentAge: PropTypes.number,
  onSubmit: PropTypes.func,
};
