import React from "react";
import PropTypes from "prop-types";
import { getOr } from "lodash/fp";
import { Image } from "react-native";

import { images } from "../../res";

export function ResAwareImage(props) {
  const { image, ...rest } = props;

  return <Image source={getOr({ uri: image }, image, images)} {...rest} />;
}
ResAwareImage.propTypes = {
  image: PropTypes.string.isRequired,
};
