import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { get, map } from "lodash/fp";
import { Pressable } from "react-native";

import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";

import { styles, stylingProps } from "./EyrRadioSelect.styles";

const mapWithKey = map.convert({ cap: false });

export function EyrRadioSelect({
  variant,
  onSelected,
  disabled,
  initialValue,
  items,
}) {
  const [value, setValue] = useState(initialValue);
  const descriptor = variant;
  const containerStyle = get(`${descriptor}Container`, styles);
  const iconProps = get(`${descriptor}Icon`, stylingProps);

  const renderItem = useCallback(
    function RadioButton({ value: itemValue, label }, key) {
      const Icon = SVGs[`RadioButton${itemValue === value ? "On" : "Off"}Mono`];
      return (
        <Pressable
          style={[containerStyle, disabled && styles.disabled]}
          disabled={disabled}
          onPress={() => {
            setValue(itemValue);
            onSelected(itemValue);
          }}
          key={key.toString()}
        >
          <Icon style={styles.icon} {...iconProps} />
          <Paragraph size="l">{label}</Paragraph>
        </Pressable>
      );
    },
    [value, containerStyle, disabled, iconProps, onSelected]
  );
  return mapWithKey(renderItem, items);
}

EyrRadioSelect.propTypes = {
  variant: PropTypes.oneOf(["primary", "danger"]),
  onSelected: PropTypes.func.isRequired,
  initialValue: PropTypes.any,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }).isRequired
  ),
};
EyrRadioSelect.defaultProps = {
  variant: "primary",
  items: [],
};
