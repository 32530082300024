import { defineMessages } from "react-intl";

export const messages = defineMessages({
  howToCancelMessage: {
    id: "CancellationInfo.howToCancelMessage",
    description: "message explaining how to cancel order",
    defaultMessage:
      "If you need to cancel your appointment, send an email to support@eyr.md stating your",
  },
  listItemBirthDate: {
    id: "CancellationInfo.listItemBirthDate",
    description: "birth date list item",
    defaultMessage: "Date of birth",
  },
  listItemName: {
    id: "CancellationInfo.listItemName",
    description: "name list item",
    defaultMessage: "Full name",
  },
  listItemConsultationTime: {
    id: "CancellationInfo.listItemConsultationTime",
    description: "consultation time list item",
    defaultMessage: "Appointment date and time",
  },
  consultationCancellationMessage: {
    id: "CancellationInfo.consultationCancellationMessage",
    description: "Cancellation fine print for consultation product",
    defaultMessage:
      "Scheduled consultations with doctors can be cancelled free of charge up to 4 hours in advance.",
  },
  dropInConsultationCancellationMessage: {
    id: "CancellationInfo.dropInConsultationCancellationMessage",
    description: "Cancellation fine print for drop-in consultation product",
    defaultMessage:
      "Drop-in consultations with doctors can be cancelled free of charge if the support agent has sufficient time to process your cancellation request before a doctor calls you.",
  },
  psychologistConsultationCancellationMessage: {
    id: "CancellationInfo.psychologistConsultationCancellationMessage",
    description: "Cancellation fine print for psychologyu consultation product",
    defaultMessage:
      "Psychology consultations can be cancelled free of charge up to 24 hours in advance. If you cancel less than 24 hours before your appointment time or do not attend your appointment, you will receive an invoice with the regular price of the consultation.",
  },
  patientGuideCancellationMessage: {
    id: "CancellationInfo.patientGuideCancellationMessage",
    description: "Cancellation fine print for patient guide product",
    defaultMessage:
      "Scheduled consultations with doctors can be cancelled free of charge up to 24 hours in advance.",
  },
  physiotherapistConsultationCancellationMessage: {
    id: "CancellationInfo.physiotherapistConsultationCancellationMessage",
    description: "Cancellation fine print for patient guide product",
    defaultMessage:
      "Physiotherapy consultations can be cancelled free of charge up to 24 hours before the appointment.",
  },
});
