import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const CALLER_IMAGE_SIZE = 80;
const CONTROL_ICON_CONTAINER_SIZE = 54;
const CONTROL_ICON_SIZE = 32;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_40,
    alignItems: "center",
    justifyContent: "space-around",
    paddingHorizontal: 24,
  },
  headerContainer: { alignItems: "center" },
  callerInformationContainer: {
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
    backgroundColor: Palette.WHITE,
    width: "100%",
    borderRadius: 8,
  },
  callerImage: {
    width: CALLER_IMAGE_SIZE,
    height: CALLER_IMAGE_SIZE,
    borderRadius: CALLER_IMAGE_SIZE / 2,
    marginBottom: 16,
  },
  controlsContainer: {
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-around",
  },
  controlContainer: {
    alignItems: "center",
  },
  controlIconContainer: {
    width: CONTROL_ICON_CONTAINER_SIZE,
    height: CONTROL_ICON_CONTAINER_SIZE,
    borderRadius: CONTROL_ICON_CONTAINER_SIZE / 2,
    marginBottom: 8,
    padding: 11,
  },
  declineIconContainer: {
    backgroundColor: Palette.RED_100,
  },
  acceptIconContainer: {
    backgroundColor: Palette.GREEN_100,
  },
  headerIcon: {
    marginVertical: 12,
  },
});

export const stylingProps = {
  controlIcon: {
    width: CONTROL_ICON_SIZE,
    height: CONTROL_ICON_SIZE,
    fill: Palette.WHITE,
  },
  headerIcon: {
    width: 104,
    height: 46,
  },
};
