import React, { useMemo } from "react";
import { Text as RNText } from "react-native";
import PropTypes from "prop-types";
import {
  fontMaker,
  Fonts,
  getValidFontWeights,
  isFontWeightValid,
} from "@eyr-mobile/core/Lib";
import { compact } from "lodash/fp";

import {
  colorStyles,
  sizeStyles,
  spacingStyles,
  alignmentStyles,
  decorationStyles,
  styles,
} from "./TypographyRaw.styles";

export const TypographyRaw = ({
  fontFamily,
  fontWeight,
  size,
  spacing,
  color,
  alignment,
  decoration,
  wrappable = false,
  ...rest
}) => {
  const sizeStyle = size && sizeStyles[size];
  const spacingStyle = spacing && spacingStyles[spacing];
  const colorStyle = color && colorStyles[color];
  const alignmentStyle = alignment && alignmentStyles[alignment];
  const decorationStyle = decoration && decorationStyles[decoration];
  const wrappableStyle = wrappable && styles.wrappable;

  // preserve fontStyle name for potential fontStyle (italics, etc.) property
  const fontStyleStyle = useMemo(
    () =>
      fontMaker({
        fontWeight: fontWeight,
        fontFamily: Fonts[fontFamily],
      }),
    [fontFamily, fontWeight]
  );

  return (
    <RNText
      {...rest}
      style={compact([
        sizeStyle,
        spacingStyle,
        colorStyle,
        alignmentStyle,
        decorationStyle,
        fontStyleStyle,
        wrappableStyle,
      ])}
    />
  );
};

TypographyRaw.propTypes = {
  size: PropTypes.oneOf(["xs", "s", "m", "l", "xl", "xxl"]),
  spacing: PropTypes.oneOf(["none", "s", "m", "l"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "negative",
    "warning",
    "positive",
    "disabled",
    "dark",
  ]),
  alignment: PropTypes.oneOf(["start", "center", "end"]),
  decoration: PropTypes.oneOf(["none", "underline", "strikethrough"]),
  fontFamily: PropTypes.oneOf([
    "FONT_FAMILY_EUCLID_CIRCULAR_B",
    "FONT_FAMILY_BUENOS_AIRES",
  ]),
  fontWeight: function (props, propName, componentName) {
    if (!isFontWeightValid(props.fontFamily, props.fontWeight)) {
      return new Error(
        `Unsupported ${componentName}.${propName}: '${
          props.fontWeight
        }' is supplied for
        fontFamily: '${
          props.fontFamily
        }'. Expected one of ${getValidFontWeights(props.fontFamily)}.`
      );
    }
  },
  wrappable: PropTypes.bool,
};
