import React, { useCallback, useMemo } from "react";
import { View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { find, get, map } from "lodash/fp";

import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import { EyrFormField } from "../EyrFormField";
import { EyrPickerSelect } from "../EyrPickerSelect";
import { EyrTextInput } from "../EyrTextInput";
import { EyrSwitch } from "../EyrSwitch";

import { messages } from "./ContactInformationForm.messages";
import { styles } from "./ContactInformationForm.styles";

const getPhoneNumberLengthForCountryPhoneCode = (codePhone, countries = []) => {
  return get("phoneNumberLength", find({ codePhone }, countries));
};
export function ContactInformationForm({
  phoneNumber,
  phoneCountryCode,
  email,
  notificationSettings,
  countries,
}) {
  const { formatMessage } = useIntl();

  const onNotificationSettingsChange = useCallback(
    (nextNotificationSettings) => {
      notificationSettings.onChange(Number(nextNotificationSettings));
    },
    [notificationSettings]
  );
  const onPhoneNumberChange = useCallback(
    (nextPhoneNumber) =>
      phoneNumber.onChange(nextPhoneNumber.replace(/\D/g, "")),
    [phoneNumber]
  );

  const phoneCodes = useMemo(
    () =>
      map(
        ({ id, codePhone }) => ({
          key: id,
          label: `+${codePhone}`,
          value: codePhone,
        }),
        countries
      ),
    [countries]
  );
  const phoneNumberLength = useMemo(
    () =>
      getPhoneNumberLengthForCountryPhoneCode(
        phoneCountryCode.value,
        countries
      ),
    [countries, phoneCountryCode.value]
  );
  return (
    <>
      <View style={styles.headerContainer}>
        <Heading size="s" alignment="center" spacing="s">
          {formatMessage(messages.headerTitle)}
        </Heading>
        <Paragraph size="l" alignment="center" spacing="m">
          {formatMessage(messages.headerDescription)}
        </Paragraph>
      </View>
      <EyrFormField
        label={formatMessage(messages.phoneControlLabel)}
        helperText={
          phoneNumber.invalid && phoneNumber.unfocused
            ? formatMessage(messages.invalidPhone)
            : undefined
        }
        error={phoneNumber.invalid && phoneNumber.unfocused}
      >
        <View style={styles.phoneFieldContainer}>
          <View style={styles.phoneCodeControlContainer}>
            <EyrPickerSelect
              items={phoneCodes}
              {...phoneCountryCode}
              onValueChange={(value) => {
                phoneCountryCode.onFocus();
                phoneCountryCode.onChange(value);
                phoneCountryCode.onBlur();
              }}
              touchableWrapperProps={{
                testID: "phoneCountryCode",
              }}
            />
          </View>
          <View style={styles.phoneNumberControlContainer}>
            <EyrTextInput
              keyboardType="numeric"
              returnKeyType="done"
              maxLength={phoneNumberLength}
              {...phoneNumber}
              onChangeText={onPhoneNumberChange}
              testID={"phoneNumber"}
              variant={
                phoneNumber.invalid && phoneNumber.unfocused
                  ? "danger"
                  : "primary"
              }
            />
          </View>
        </View>
      </EyrFormField>
      <EyrFormField
        label={formatMessage(messages.emailControlLabel)}
        helperText={
          email.invalid && email.unfocused
            ? formatMessage(messages.invalidEmail)
            : undefined
        }
        error={email.invalid && email.unfocused}
      >
        <EyrTextInput
          keyboardType="email-address"
          returnKeyType="done"
          {...email}
          onChangeText={email.onChange}
          testID={"email"}
          variant={email.invalid && email.unfocused ? "danger" : "primary"}
        />
      </EyrFormField>
      <EyrFormField>
        <View style={styles.notificationSettingsControlContainer}>
          <View style={styles.notificationSettingsControlLabelContainer}>
            <Paragraph size="l">
              {formatMessage(messages.notificationSettingsControlLabel)}
            </Paragraph>
          </View>
          <EyrSwitch
            value={Boolean(notificationSettings.value)}
            onValueChange={onNotificationSettingsChange}
            testID={"notificationsSettings"}
          />
        </View>
      </EyrFormField>
    </>
  );
}
