import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { size, compact } from "lodash/fp";
import { useIntl } from "react-intl";
import { FlatList } from "react-native";

import { IconContainer } from "../IconContainer";
import { Paragraph } from "../Paragraph";
import { ResAwareSvg } from "../ResAwareSvg";
import { Card } from "../Card";

import { messages } from "./SelectMedicalCategory.messages";
import { styles } from "./SelectMedicalCategory.styles";

export function SelectMedicalCategory({
  medicalCategories,
  onMedicalCategorySelected,
  contentContainerStyle,
  ...rest
}) {
  const { formatMessage } = useIntl();
  const renderItem = useCallback(
    ({ item: category }) => {
      const { name, image } = category;
      return (
        <Card
          title={name}
          onPress={() => onMedicalCategorySelected(category)}
          {...(image && {
            icon: (
              <IconContainer size="xs" variant="plain">
                <ResAwareSvg svg={image} />
              </IconContainer>
            ),
          })}
        />
      );
    },
    [onMedicalCategorySelected]
  );
  const ListEmptyComponent = useCallback(
    () => (
      <Paragraph size="l" alignment="center">
        {formatMessage(messages.noCategories)}
      </Paragraph>
    ),
    [formatMessage]
  );
  const keyExtractor = useCallback((item) => item.id.toString(), []);

  return (
    <FlatList
      data={medicalCategories}
      contentContainerStyle={compact([
        size(medicalCategories) === 0 && styles.noDataContentContainer,
        contentContainerStyle,
      ])}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ListEmptyComponent={ListEmptyComponent}
      {...rest}
    />
  );
}

SelectMedicalCategory.propTypes = {
  medicalCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string,
    })
  ),
  onMedicalCategorySelected: PropTypes.func,
  contentContainerStyle: PropTypes.any,
};
