import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "EditAccountContactInfoScreen.navigationTitle",
    description: "EditAccountContactInfoScreen navigation title",
    defaultMessage: "Account details",
  },
  submitActionTitle: {
    id: "EditAccountContactInfoScreen.submitActionTitle",
    description: "submit action title",
    defaultMessage: "Save",
  },
  deleteMyAccountActionTitle: {
    id: "EditAccountContactInfoScreen.deleteMyAccountActionTitle",
    description: "delete my account",
    defaultMessage: "Delete my account",
  },
});
