import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.BURGUNDY_100,
  },
  videoComponentPlaceholderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
