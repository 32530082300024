import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { noop, map, get } from "lodash/fp";
import { useIntl } from "react-intl";
import { FlatList } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib";

import { SVGs } from "../../res";
import { Card } from "../Card";
import { Subtitle } from "../Subtitle";

import { messages } from "./AddPaymentMethod.messages";

export const AddPaymentMethod = (props) => {
  const { formatMessage } = useIntl();

  const {
    orderedOptions,
    onAddCardSelected,
    onAddInsuranceOrDiscountSelected,
    showTitle,
    ...rest
  } = props;

  const options = {
    CARD: {
      key: "CARD",
      Icon: SVGs.CreditCard,
      title: formatMessage(messages.addCard),
      onPress: () => onAddCardSelected(),
    },
    INSURANCE_OR_DISCOUNT: {
      key: "INSURANCE_OR_DISCOUNT",
      Icon: SVGs.Insurance,
      title: formatMessage(messages.addInsuranceOrDiscount),
      onPress: () => onAddInsuranceOrDiscountSelected(),
    },
  };

  const handleExtractKey = useCallback((item) => `${item.key}`, []);
  const handleRenderItem = useCallback(({ item }) => {
    const { onPress, Icon, title } = item;
    return <Card icon={<Icon />} title={title} onPress={onPress} />;
  }, []);

  return (
    <>
      {renderIf(showTitle)(() => (
        <Subtitle size="l" spacing="l">
          {formatMessage(messages.newPaymentMethodTitle)}
        </Subtitle>
      ))}
      <FlatList
        {...rest}
        data={map((option) => get(option, options), orderedOptions)}
        keyExtractor={handleExtractKey}
        renderItem={handleRenderItem}
      />
    </>
  );
};

AddPaymentMethod.propTypes = {
  orderedOptions: PropTypes.arrayOf(
    PropTypes.oneOf(["CARD", "INSURANCE_OR_DISCOUNT"]).isRequired
  ),
  onAddCardSelected: PropTypes.func,
  onAddDiscountSelected: PropTypes.func,
  onAddInsuranceSelected: PropTypes.func,
  onAddInsuranceOrDiscountSelected: PropTypes.func,
};

AddPaymentMethod.defaultProps = {
  orderedOptions: ["INSURANCE_OR_DISCOUNT", "CARD"],
  onAddCardSelected: noop,
  onAddDiscountSelected: noop,
  onAddInsuranceSelected: noop,
  showTitle: true,
};
