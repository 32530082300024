import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { values, getOr } from "lodash/fp";
import { View, FlatList } from "react-native";
import { ProductType } from "@eyr-mobile/domain/Order";

import { SVGs } from "../../res";
import { Heading } from "../Heading";
import { Card } from "../Card";
import { IconContainer } from "../IconContainer";

import { messages } from "./FamilyMemberClientPicker.messages";
import { styles } from "./FamilyMemberClientPicker.styles";

export function FamilyMemberClientPicker({
  familyMembers = [],
  consentAge = 16,
  productType,
  onAddChildSelected,
  onChildSelected,
  onMyselfSelected,
  ...rest
}) {
  const { formatMessage } = useIntl();

  const renderItem = useCallback(
    ({ item: child }) => {
      const { countryUid } = child;
      return (
        <Card
          title={formatMessage(messages.child)}
          titleIsBold
          description={countryUid}
          onPress={() => onChildSelected(child)}
          icon={
            <IconContainer size="s" variant="plain">
              <SVGs.Child />
            </IconContainer>
          }
        />
      );
    },
    [formatMessage, onChildSelected]
  );
  return (
    <View style={styles.container}>
      <Heading spacing="l" alignment="center">
        {formatMessage(messages.whoIs, {
          productType: formatMessage(
            getOr(messages.default, productType, messages)
          ),
        })}
      </Heading>
      <FlatList
        ListHeaderComponent={
          <Card
            title={formatMessage(messages.myself)}
            titleIsBold
            onPress={onMyselfSelected}
            icon={
              <IconContainer size="s" variant="plain">
                <SVGs.MySelf />
              </IconContainer>
            }
          />
        }
        data={familyMembers}
        keyExtractor={(item) => item.id.toString()}
        renderItem={renderItem}
        ListFooterComponent={
          <Card
            title={formatMessage(messages.addChild)}
            titleIsBold
            description={formatMessage(messages.childrenUnderAge, {
              consentAge,
            })}
            onPress={onAddChildSelected}
            icon={
              <IconContainer size="s" variant="plain">
                <SVGs.ChildAdd />
              </IconContainer>
            }
          />
        }
        {...rest}
      />
    </View>
  );
}

FamilyMemberClientPicker.propTypes = {
  onChildSelected: PropTypes.func.isRequired,
  onMyselfSelected: PropTypes.func.isRequired,
  onAddChildSelected: PropTypes.func.isRequired,
  familyMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      countryUid: PropTypes.string.isRequired,
    })
  ),
  consentAge: PropTypes.number,
  productType: PropTypes.oneOf(values(ProductType)).isRequired,
};
