import { StyleSheet } from "react-native";

import { Shadows } from "../../shared-styles/shadow";
import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  priceInfo: {
    flexDirection: "row",
  },
  priceInfoTitleContainer: { flexGrow: 1 },
  imageContainer: {
    width: 28,
    height: 28,
  },
  remoteImageContainer: {
    backgroundColor: Palette.WHITE,
    borderRadius: 16,
    ...Shadows.xs,
  },
  secondaryValueContainer: { marginLeft: 8 },
  grayScaleImageContainer: StyleSheet.absoluteFill,
});

export const stylingProps = {
  image: {
    width: 28,
    height: 28,
  },
};
