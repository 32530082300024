import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "DeleteMyAccountScreen.navigationTitle",
    description: "DeleteMyAccountScreen navigation title",
    defaultMessage: "Delete my account",
  },
  deletionSummary: {
    id: "DeleteMyAccountScreen.deletionSummary",
    description: "DeleteMyAccountScreen deletion summary",
    defaultMessage:
      "If you delete your Eyr account, both your account and associated user data will be immediately deleted.",
  },
  deletionInstruction: {
    id: "DeleteMyAccountScreen.deletionInstruction",
    description: "DeleteMyAccountScreen deletion instruction",
    defaultMessage:
      "Account and data deletion cannot be undone. But you can create a new Eyr account later, if you want to.",
  },
  contactSupport: {
    id: "DeleteMyAccountScreen.contactSupport",
    description: "DeleteMyAccountScreen contact support",
    defaultMessage:
      "You can contact Support at {email} for questions about account deletion.",
  },
  dataThatWillBeDeleted: {
    id: "DeleteMyAccountScreen.dataThatWillBeDeleted",
    description: "DeleteMyAccountScreen data that will be deleted",
    defaultMessage: "Data that will be deleted",
  },
  dataThatWillNotBeDeleted: {
    id: "DeleteMyAccountScreen.dataThatWillNotBeDeleted",
    description: "DeleteMyAccountScreen data that will not be deleted",
    defaultMessage: "Data that will not be deleted",
  },
  accountDeletionAlertTitle: {
    id: "DeleteMyAccountScreen.accountDeletionAlertTitle",
    description: "DeleteMyAccountScreen account deletion alert title",
    defaultMessage: "Are you sure you want to delete your account?",
  },
  accountDeletionAlertConfirmActionTitle: {
    id: "DeleteMyAccountScreen.accountDeletionAlertConfirmActionTitle",
    description:
      "DeleteMyAccountScreen account deletion alert confirm action title",
    defaultMessage: "Yes, delete my account",
  },
  accountDeletionAlertCancelActionTitle: {
    id: "DeleteMyAccountScreen.accountDeletionAlertCancelActionTitle",
    description:
      "DeleteMyAccountScreen account deletion alert cancel action title",
    defaultMessage: "Cancel",
  },
  name: {
    id: "DeleteMyAccountScreen.name",
    description: "DeleteMyAccountScreen data that will be deleted: name",
    defaultMessage: "Name",
  },
  personalIdentificationNumber: {
    id: "DeleteMyAccountScreen.personalIdentificationNumber",
    description:
      "DeleteMyAccountScreen data that will be deleted: personal identification number",
    defaultMessage: "Personal Identification Number",
  },
  email: {
    id: "DeleteMyAccountScreen.email",
    description: "DeleteMyAccountScreen data that will be deleted: email",
    defaultMessage: "Email",
  },
  phoneNumber: {
    id: "DeleteMyAccountScreen.phoneNumber",
    description:
      "DeleteMyAccountScreen data that will be deleted: phone number",
    defaultMessage: "Phone number",
  },
  yourAnswers: {
    id: "DeleteMyAccountScreen.yourAnswers",
    description:
      "DeleteMyAccountScreen data that will be deleted: your answers to health-related questionnaires and forms",
    defaultMessage: "Your answers to health-related questionnaires and forms",
  },
  yourMessages: {
    id: "DeleteMyAccountScreen.yourMessages",
    description:
      "DeleteMyAccountScreen data that will be deleted: your messages",
    defaultMessage: "Your messages in Eyr Inbox",
  },
  healthRelatedData: {
    id: "DeleteMyAccountScreen.healthRelatedData",
    description:
      "DeleteMyAccountScreen data that will not be deleted: health related data",
    defaultMessage:
      "Health-related data that is part of your medical record. Your medical records contain information about your Eyr appointments, prescriptions for medicines, referrals to specialists, sick leave certificates, diagnoses, etc.",
  },
  infoNecessaryTo: {
    id: "DeleteMyAccountScreen.infoNecessaryTo",
    description:
      "DeleteMyAccountScreen data that will not be deleted: info necessary to...",
    defaultMessage:
      "Information necessary to invoice insurance partners or reimburse payments.",
  },
  deleteMyAccount: {
    id: "EditAccountContactInfoScreen.deleteMyAccount",
    description: "delete my account",
    defaultMessage: "Delete my account",
  },
});
