import React, { useLayoutEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Animated,
  useWindowDimensions,
  View,
  StatusBar,
  Platform,
} from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { CameraState } from "@eyr-mobile/core/Camera";
import { MicrophoneState } from "@eyr-mobile/core/Microphone";
import { useDevice } from "@eyr-mobile/core/Device";
import { compact, flow, head, split } from "lodash/fp";

import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";
import { Subtitle } from "../Subtitle";
import { BlurView } from "../BlurView";

import { messages } from "./CallMainVideoFrame.messages";
import {
  styles,
  stylingProps,
  stateOverlayInitialPositionBottom,
  stateOverlayAnimationDuration,
  stylingHelpers,
} from "./CallMainVideoFrame.styles";

const extractFirstWord = flow([split(" "), head]);

function getVideoOverlayMessage({ microphoneState, cameraState }) {
  switch (true) {
    case microphoneState === MicrophoneState.DISABLED &&
      cameraState !== CameraState.DISABLED:
      return messages.microphoneDisabled;
    case cameraState === CameraState.DISABLED &&
      microphoneState !== MicrophoneState.DISABLED:
      return messages.cameraDisabled;
    case microphoneState === MicrophoneState.DISABLED &&
      cameraState === CameraState.DISABLED:
      return messages.microphoneAndCameraDisabled;
  }
}

export function CallMainVideoFrame({
  title,
  description,
  cameraState = CameraState.UNKNOWN,
  microphoneState = MicrophoneState.UNKNOWN,
  enableStateOverlay = false,
  stateOverlayOffset = 0,
  VideoComponent = View,
  videoComponentProps = {},
  ...rest
}) {
  const { formatMessage } = useIntl();
  const cameraDisabled = cameraState === CameraState.DISABLED;
  const microphoneDisabled = microphoneState === MicrophoneState.DISABLED;
  const cameraOrMicrophoneDisabled = microphoneDisabled || cameraDisabled;
  const videoComponentRef = useRef();
  const { screenSizeSelect, isScreenSizeXS } = useDevice();
  const overlayPositionBottomRef = useRef(
    new Animated.Value(stateOverlayInitialPositionBottom)
  );
  const { height: screenHeightOriginal, width: screenWidth } =
    useWindowDimensions();
  // https://github.com/facebook/react-native/issues/36447
  const screenHeight = Platform.select({
    android: Math.ceil(screenHeightOriginal + StatusBar.currentHeight),
    default: screenHeightOriginal,
  });

  const videoStyle = screenSizeSelect({
    xs: stylingHelpers.getVideoStyleXS(screenHeight),
    s: stylingHelpers.getVideoStyleS(
      screenHeight,
      screenWidth,
      stateOverlayOffset
    ),
  });

  useLayoutEffect(() => {
    if (!enableStateOverlay) {
      return;
    }
    const overlayPositionBottom = overlayPositionBottomRef.current;
    const animation = Animated.timing(overlayPositionBottom, {
      useNativeDriver: false,
      toValue: stateOverlayOffset
        ? stateOverlayOffset
        : stateOverlayInitialPositionBottom,
      duration: stateOverlayAnimationDuration,
    });
    animation.start();
    return () => animation.stop();
  }, [enableStateOverlay, stateOverlayOffset, screenSizeSelect]);
  return (
    <View
      {...rest}
      style={screenSizeSelect({
        xs: styles.container,
        s: [styles.container, styles.containerS],
      })}
    >
      <View
        style={compact([
          styles.contentContainer,
          videoStyle,
          screenSizeSelect({
            s: styles.contentContainerS,
          }),
        ])}
      >
        <VideoComponent
          {...videoComponentProps}
          properties={{
            fitMode: "cover",
            ...stylingProps.video,
            ...videoComponentProps.properties,
          }}
          ref={videoComponentRef}
          style={videoStyle}
        />
        {enableStateOverlay &&
          ((isScreenSizeXS && cameraOrMicrophoneDisabled) ||
            !isScreenSizeXS) && (
            <Animated.View
              style={compact([
                styles.videoStateOverlay,
                screenSizeSelect({
                  xs: styles.videoStateOverlayXS,
                  s: styles.videoStateOverlayS,
                }),
                !cameraOrMicrophoneDisabled &&
                  styles.videoStateOverlayWithoutIcons,
                isScreenSizeXS &&
                  stylingHelpers.getStateOverlayPosition(
                    overlayPositionBottomRef.current
                  ),
              ])}
            >
              {
                //https://github.com/expo/expo/issues/23239
                Platform.OS !== "android" && (
                  <BlurView
                    {...stylingProps.blurView}
                    style={styles.blurView}
                  />
                )
              }
              {cameraOrMicrophoneDisabled && (
                <View style={styles.videoStateIconsContainer}>
                  {microphoneDisabled && (
                    <View
                      style={[
                        styles.videoStateIconContainer,
                        cameraDisabled && styles.videoStateIconSpacer,
                      ]}
                    >
                      <SVGs.MicrophoneOffMono
                        {...stylingProps.videoStateIcon}
                      />
                    </View>
                  )}
                  {cameraDisabled && (
                    <View style={styles.videoStateIconContainer}>
                      <SVGs.CameraOffMono {...stylingProps.videoStateIcon} />
                    </View>
                  )}
                </View>
              )}
              <View style={styles.videoStateOverlayTextContainer}>
                {isScreenSizeXS &&
                  (cameraState !== CameraState.UNKNOWN ||
                    microphoneState !== MicrophoneState.UNKNOWN) && (
                    <Subtitle
                      color={"secondary"}
                      numberOfLines={2}
                      ellipsizeMode={"tail"}
                    >
                      {formatMessage(
                        getVideoOverlayMessage({
                          cameraState,
                          microphoneState,
                        }),
                        {
                          name: extractFirstWord(title),
                        }
                      )}
                    </Subtitle>
                  )}
                {!isScreenSizeXS && (
                  <>
                    <Subtitle
                      color="secondary"
                      numberOfLines={1}
                      ellipsizeMode={"tail"}
                    >
                      {title}
                    </Subtitle>
                    {description && (
                      <Paragraph
                        color="secondary"
                        numberOfLines={1}
                        ellipsizeMode={"tail"}
                      >
                        {description}
                      </Paragraph>
                    )}
                  </>
                )}
              </View>
            </Animated.View>
          )}
      </View>
    </View>
  );
}

CallMainVideoFrame.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  cameraState: PropTypes.oneOf(["unknown", "enabled", "disabled"]),
  microphoneState: PropTypes.oneOf(["unknown", "enabled", "disabled"]),
  enableStateOverlay: PropTypes.bool,
  stateOverlayOffset: PropTypes.number,
  VideoComponent: PropTypes.elementType,
  videoComponentProps: PropTypes.object,
};
