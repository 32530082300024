import { useMemo } from "react";
import { flow, values, find } from "lodash/fp";
import { LoggerFactory } from "@eyr-mobile/core/Logger";

import { PaletteAvonova, IconPaletteAvonova } from "./PaletteAvonova";
import { PaletteGjensidige, IconPaletteGjensidige } from "./PaletteGjensidige";

const logger = LoggerFactory.get("domain/WhiteLabel");

export const WhiteLabels = { GJENSIDIGE: "gjensidige", AVONOVA: "avonova" };

const testForWhiteLabel = (value) =>
  window.location.host.split(".")[0] === value ||
  new URL(window.location).searchParams.get("wl") === value;

export const detectWhiteLabel = () =>
  flow([values, find(testForWhiteLabel)])(WhiteLabels);

export const useWhiteLabel = () => {
  return useMemo(detectWhiteLabel, []);
};

function activateWhiteLabelTheme(whiteLabel) {
  global.theme = {
    [WhiteLabels.AVONOVA]: {
      colors: PaletteAvonova,
      iconColors: IconPaletteAvonova,
    },
    [WhiteLabels.GJENSIDIGE]: {
      colors: PaletteGjensidige,
      iconColors: IconPaletteGjensidige,
    },
  }[whiteLabel];
}

const whiteLabel = detectWhiteLabel();

if (whiteLabel) {
  logger("detectWhiteLabel:", whiteLabel);
  activateWhiteLabelTheme(whiteLabel);
}
