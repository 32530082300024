import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "AddChild.title",
    description: "Fill out your child's national identity number",
    defaultMessage: "Fill out your child's national identity number",
  },
  ninPlaceholder: {
    id: "AddChild.ninPlaceholder",
    description: "Child's national identity number",
    defaultMessage: "Child's national identity number",
  },
  explanation: {
    id: "AddChild.explanation",
    description: "Adults must use personal account",
    defaultMessage:
      "If your child is {consentAge} years or older, they must log in with their own account.",
  },
  invalidNIN: {
    id: "AddChild.invalidNIN",
    description: "Not a valid national identity number",
    defaultMessage: "Not a valid national identity number",
  },
  next: {
    id: "AddChild.next",
    description: "Submit button title",
    defaultMessage: "Next",
  },
  defaultError: {
    id: "AddChild.defaultError",
    description: "Generic error message",
    defaultMessage: "Something went wrong",
  },
});
