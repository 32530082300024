import { defineMessages } from "react-intl";

import { serviceProviderTitleMessages } from "../../shared-messages";

export const messages = {
  ...defineMessages({
    bookedConsultationDate: {
      id: "AppointmentConsultationServiceCard.bookedConsultationDate",
      description: "booked order date",
      defaultMessage: "{date} at {time}",
    },
    refundedHeader: {
      id: "AppointmentConsultationServiceCard.refundedHeader",
      description: "refunded header",
      defaultMessage: "Your order has been refunded.",
    },
    refundedConsultationDateTitle: {
      id: "AppointmentConsultationServiceCard.refundedConsultationDateTitle",
      description: "refunded order date title",
      defaultMessage: "Date of consultation",
    },
    refundedConsultationDate: {
      id: "AppointmentConsultationServiceCard.refundedConsultationDateDate",
      description: "refunded order date",
      defaultMessage: "{date} at {time}",
    },
    delayedHeader: {
      id: "AppointmentConsultationServiceCard.delayedHeader",
      description: "delayed header",
      defaultMessage:
        "The {serviceProviderTitle} will call you as soon as possible. We apologise for the delay.",
    },
    reassignedHeader: {
      id: "AppointmentConsultationServiceCard.reassignedHeader",
      description: "reassigned header",
      defaultMessage:
        "You have been reassigned to {providerName} for this consultation.",
    },
    digitalToolActionTitle: {
      id: "AppointmentConsultationServiceCard.digitalToolActionTitle",
      description: "digital tool action title",
      defaultMessage: "Fill out questionnaire",
    },
    upcomingAppointmentDetailsTitle: {
      id: "PatientGuideServiceCard.upcomingAppointmentDetailsTitle",
      description: "upcoming appointment title",
      defaultMessage: "Upcoming appointment:",
    },
    listItemHowToPrepareTitle: {
      id: "PatientGuideServiceCard.listItemHowToPrepareTitle",
      description: "How to prepare list item title",
      defaultMessage: "How to prepare",
    },
    listItemCancellationInfoTitle: {
      id: "PatientGuideServiceCard.listItemCancellationInfoTitle",
      description: "Information about cancellation list item title",
      defaultMessage: "Information about cancellation",
    },
  }),
  ...serviceProviderTitleMessages,
};
