import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { map } from "lodash/fp";

import { Checkbox } from "../Checkbox";

const mapWithKey = map.convert({ cap: false });

export function EyrCheckSelect({ onSelected, initialValue, items, ...rest }) {
  const [initialSelectedItems] = useState(
    initialValue ? new Set(initialValue) : new Set()
  );
  const [selectedItems, setSelectedItems] = useState(initialSelectedItems);
  const handleSelection = useCallback(
    (item) => {
      if (selectedItems.has(item)) {
        selectedItems.delete(item);
        setSelectedItems(selectedItems);
      } else {
        selectedItems.add(item);
        setSelectedItems(selectedItems);
      }
      onSelected(Array.from(selectedItems));
    },
    [selectedItems, onSelected]
  );

  const renderItem = useCallback(
    ({ label, value }, key) => {
      return (
        <Checkbox
          {...rest}
          initialValue={initialSelectedItems.has(value)}
          label={label}
          onSelected={() => handleSelection(value)}
          key={key.toString()}
        />
      );
    },
    [rest, initialSelectedItems, handleSelection]
  );

  return mapWithKey(renderItem, items);
}

EyrCheckSelect.propTypes = {
  onSelected: PropTypes.func.isRequired,
  initialValue: PropTypes.array,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }).isRequired
  ),
};
