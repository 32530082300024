import { defineMessages } from "react-intl";

export const messages = defineMessages({
  kindExercise: {
    id: "Content.kindExercise",
    description: "kind Exercise title",
    defaultMessage: "Exercise",
  },
  kindPsychoEducation: {
    id: "Content.kindPsychoEducation",
    description: "kind PsychoEducation title",
    defaultMessage: "Psychoeducation",
  },
});
