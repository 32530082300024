import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.navigationTitle",
    description:
      "FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen navigation title",
    defaultMessage: "Add insurance or membership",
  },
});
