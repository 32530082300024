import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Pressable, View } from "react-native";
import { get, noop, omit, includes, capitalize, compact } from "lodash/fp";

import { Subtitle } from "../Subtitle";

import { styles, stylingProps } from "./EyrButton.styles.js";

const resolveStyles = ({
  variant,
  iconOnly,
  iconPosition,
  size,
  pressed,
  disabled,
}) => {
  const isTextVariant = ["text", "text-secondary"].includes(variant);
  const descriptor = disabled && !isTextVariant ? "disabled" : variant;
  let containerStyle = [];
  if (
    includes(variant, ["primary", "friendly", "negative", "positive"]) ||
    (variant === "secondary" && disabled)
  ) {
    containerStyle = [
      get(`${descriptor}${pressed ? "Active" : ""}Container`, styles),
      get(`${size}${iconOnly ? "IconOnly" : ""}Container`, styles),
    ];
  }
  if (variant === "secondary") {
    containerStyle = [
      get(`${descriptor}${pressed ? "Active" : ""}Container`, styles),
      get(
        `${size}${capitalize(variant)}${iconOnly ? "IconOnly" : ""}Container`,
        styles
      ),
    ];
  }
  if (isTextVariant) {
    containerStyle = compact([
      !disabled &&
        get(`${descriptor}${pressed ? "Active" : ""}Container`, styles),
      get(
        `${size}${capitalize(variant)}${iconOnly ? "IconOnly" : ""}Container`,
        styles
      ),
    ]);
  }
  return {
    containerStyle: [styles.container, ...containerStyle],
    iconStylingProps: {
      ...get(`${descriptor}Icon`, stylingProps),
      ...get(`${size}Icon`, stylingProps),
      ...(!iconOnly && {
        style: get(`${size}${capitalize(iconPosition)}Icon`, styles),
      }),
    },
    titleStylingProps: {
      ...get(`${descriptor}Title`, stylingProps),
      ...get(`${size}Title`, stylingProps),
    },
  };
};

export function EyrButton({
  title,
  variant = "primary",
  disabled,
  injectNativeEvent = false,
  onPress = noop,
  IconComponent,
  iconPosition = "leading",
  size = "m",
  ...rest
}) {
  const handlePress = useCallback(
    (nativeEvent) => (injectNativeEvent ? onPress(nativeEvent) : onPress()),
    [injectNativeEvent, onPress]
  );

  const iconOnly = IconComponent && !title;
  const leadingIcon = title && IconComponent && iconPosition === "leading";
  const trailingIcon = title && IconComponent && iconPosition === "trailing";

  return (
    <Pressable
      {...omit(["style"], rest)}
      disabled={disabled}
      onPress={handlePress}
    >
      {({ pressed }) => {
        const { containerStyle, iconStylingProps, titleStylingProps } =
          resolveStyles({
            pressed,
            disabled,
            iconOnly,
            iconPosition,
            size,
            variant,
          });

        const icon = IconComponent && <IconComponent {...iconStylingProps} />;

        return (
          <View style={containerStyle}>
            {(iconOnly || leadingIcon) && icon}
            {title && <Subtitle {...titleStylingProps}>{title}</Subtitle>}
            {trailingIcon && icon}
          </View>
        );
      }}
    </Pressable>
  );
}

const requiredPropsCheck = (props, propName, componentName) => {
  if (!props.title && !props.IconComponent) {
    return new Error(
      `'title' or 'IconComponent' is required for '${componentName}' component.`
    );
  }

  if (props.title) {
    PropTypes.checkPropTypes(
      { title: PropTypes.string },
      { title: props.title },
      "prop",
      componentName
    );
  }

  if (props.IconComponent) {
    PropTypes.checkPropTypes(
      { IconComponent: PropTypes.elementType },
      { IconComponent: props.IconComponent },
      "prop",
      componentName
    );
  }
};

EyrButton.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "friendly",
    "positive",
    "negative",
    "text",
    "text-secondary",
  ]),
  size: PropTypes.oneOf(["s", "m", "l"]),
  IconComponent: requiredPropsCheck,
  iconPosition: PropTypes.oneOf(["leading", "trailing"]),
  title: requiredPropsCheck,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  injectNativeEvent: PropTypes.bool,
};
