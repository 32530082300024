import { StyleSheet } from "react-native";
import { fontMaker } from "@eyr-mobile/core/Lib";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainer: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 32,
    paddingHorizontal: 24,
    flexGrow: 0.4,
    flexShrink: 1,
  },
  mainImage: {
    width: "100%",
    height: "auto",
    flexGrow: 0.6,
    flexShrink: 1,
  },
  loginContainer: {
    alignItems: "center",
    marginBottom: 16,
  },
  appVersionIndicator: {
    ...fontMaker(),
    position: "absolute",
    bottom: 0,
    right: 0,
    fontSize: 12,
    lineHeight: 16,
    color: Palette.GRAY_125,
    padding: 4,
  },
  whiteLabelContainer: {
    marginTop: 32,
    flexDirection: "row",
  },
  whiteLabelLogo: {
    marginLeft: 8,
  },
});

export const stylingProps = {
  headerTitleSvg: {
    height: 32,
  },
  headerTitleImage: {
    width: 162,
    height: 40,
    resizeMode: "contain",
  },
  whiteLabelIcon: {
    width: 46, // TODO: fix weirdness
    height: 20,
  },
};
