import React from "react";
import PropTypes from "prop-types";
import { View, Image } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import { EyrButton } from "../EyrButton";

import { styles, stylingProps } from "./BannerImage.styles";

export function BannerImage({ title, description, button, ...rest }) {
  return (
    <View style={styles.container}>
      <Image {...rest} style={styles.bannerImage} />
      <LinearGradient {...stylingProps.linearGradient} style={styles.overlay} />
      <View style={styles.contentContainer}>
        {renderIf(title)(() => (
          <Heading color="secondary" alignment="center" spacing="s">
            {title}
          </Heading>
        ))}
        {renderIf(description)(() => (
          <Paragraph color="secondary" alignment="center">
            {description}
          </Paragraph>
        ))}
        {renderIf(button)(() => (
          <View style={styles.button}>
            <EyrButton {...button} />
          </View>
        ))}
      </View>
    </View>
  );
}

BannerImage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  button: PropTypes.object,
};
