import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { View, ScrollView, Linking } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import {
  EYR_SUPPORT_EMAIL,
  GetAccountCountryCode,
} from "@eyr-mobile/domain/Order";
import { capitalize, getOr, get } from "lodash/fp";
import moment from "moment";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res";
import { Card, Subtitle, Paragraph, IconContainer } from "../../components";

import { messages } from "./HelpAndContactScreen.messages";
import { styles } from "./HelpAndContactScreen.styles";

const CardLink = ({
  title,
  IconComponent,
  IconComponentRight,
  paragraph,
  onPress,
}) => {
  return (
    <Card
      onPress={onPress}
      titleIsBold
      showInteractivityIcon={Boolean(IconComponentRight)}
      InteractivityIconComponent={IconComponentRight}
      icon={
        <IconContainer variant="plain" size="s">
          <IconComponent />
        </IconContainer>
      }
      title={title}
      description={paragraph}
    />
  );
};

CardLink.propTypes = {
  title: PropTypes.string.isRequired,
  IconComponent: PropTypes.elementType.isRequired,
  IconComponentRight: PropTypes.elementType,
  paragraph: PropTypes.string,
  onPress: PropTypes.func.isRequired,
};

const COUNTRY_PHONE_CODE_NORWAY = "+47";
const EYR_SUPPORT_PHONE_NUMBER = "23898606";
const EYR_SUPPORT_PHONE_NUMBER_DOMESTIC_DISPLAY_TEXT =
  EYR_SUPPORT_PHONE_NUMBER.replace(/(.{2})/g, "$1 ").trim();
const EYR_SUPPORT_PHONE_NUMBER_INTERNATIONAL_DISPLAY_TEXT =
  COUNTRY_PHONE_CODE_NORWAY +
  " " +
  EYR_SUPPORT_PHONE_NUMBER_DOMESTIC_DISPLAY_TEXT;
const EYR_FAQ_LINKS = {
  DK: "https://eyr.md/dk/spørgsmål-og-svar",
  NO: "https://eyr.md/no/spm-og-svar",
  EN: "https://eyr.md/en/faq",
  SE: "https://eyr.md/se/frågor-och-svar",
};
const openingHoursWeekdaysStart = moment().isoWeekday(1).hours(8).minutes(0);
const openingHoursWeekdaysEnd = moment().isoWeekday(5).hours(22).minutes(0);
const openingHoursWeekendsStart = moment().isoWeekday(6).hours(10).minutes(0);
const openingHoursWeekendsEnd = moment().isoWeekday(7).hours(22).minutes(0);

const getAccountCountryCode = get("account.country.codeIso2");

const handleEmailPress = () => Linking.openURL(`mailto:${EYR_SUPPORT_EMAIL}`);
const handlePhonePress = () =>
  Linking.openURL(
    `tel:${COUNTRY_PHONE_CODE_NORWAY + EYR_SUPPORT_PHONE_NUMBER}`
  );

export function HelpAndContactScreen() {
  const { formatMessage, formatDate, formatTime } = useIntl();
  const { data, handlers } = withHandlers(useQuery(GetAccountCountryCode));

  const countryCode = getAccountCountryCode(data);

  const handleFaqPress = useCallback(
    () => Linking.openURL(getOr(EYR_FAQ_LINKS.EN, countryCode, EYR_FAQ_LINKS)),
    [countryCode]
  );
  const { screenSizeSelect } = useDevice();
  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <ScrollView
          contentContainerStyle={screenSizeSelect({
            xs: styles.contentContainerXS,
            s: styles.contentContainerS,
            m: styles.contentContainerM,
          })}
        >
          <View style={styles.headerIcon}>
            <SVGs.UserInformationSecondary />
          </View>
          <View style={styles.textSection}>
            <Paragraph size="l" spacing="m">
              {formatMessage(messages.headerText)}
            </Paragraph>
            <Subtitle size="l" spacing="m">
              {formatMessage(messages.emphasizedParagraph)}
            </Subtitle>
            <Paragraph size={"l"} spacing={"s"}>
              {formatMessage(messages.openingHoursTitle) + ":"}
            </Paragraph>
            <View style={styles.openingHoursSection}>
              <Paragraph size={"l"} spacing={"s"}>
                {formatMessage(messages.range, {
                  start: capitalize(
                    formatDate(openingHoursWeekdaysStart, { weekday: "short" })
                  ),
                  end: capitalize(
                    formatDate(openingHoursWeekdaysEnd, { weekday: "short" })
                  ),
                })}
              </Paragraph>
              <Paragraph size={"l"} spacing={"s"}>
                {formatMessage(messages.range, {
                  start: formatTime(openingHoursWeekdaysStart, {
                    hour: "numeric",
                    minute: "numeric",
                  }),
                  end: formatDate(openingHoursWeekdaysEnd, {
                    hour: "numeric",
                    minute: "numeric",
                  }),
                })}
              </Paragraph>
            </View>
            <View style={styles.openingHoursSection}>
              <Paragraph size={"l"} spacing={"s"}>
                {formatMessage(messages.weekendAndHolidays, {
                  start: capitalize(
                    formatDate(openingHoursWeekendsStart, { weekday: "short" })
                  ),
                  end: capitalize(
                    formatDate(openingHoursWeekendsEnd, { weekday: "short" })
                  ),
                })}
              </Paragraph>
              <Paragraph size={"l"} spacing={"s"}>
                {formatMessage(messages.range, {
                  start: formatTime(openingHoursWeekendsStart, {
                    hour: "numeric",
                    minute: "numeric",
                  }),
                  end: formatDate(openingHoursWeekendsEnd, {
                    hour: "numeric",
                    minute: "numeric",
                  }),
                })}
              </Paragraph>
            </View>
          </View>

          <CardLink
            title={formatMessage(messages.emailCardTitle)}
            IconComponent={SVGs.MailMono}
            onPress={handleEmailPress}
            paragraph={EYR_SUPPORT_EMAIL}
          />
          <CardLink
            title={formatMessage(messages.phoneCardTitle)}
            IconComponent={SVGs.CallMono}
            onPress={handlePhonePress}
            paragraph={
              countryCode === "NO"
                ? EYR_SUPPORT_PHONE_NUMBER_DOMESTIC_DISPLAY_TEXT
                : EYR_SUPPORT_PHONE_NUMBER_INTERNATIONAL_DISPLAY_TEXT
            }
          />
          <CardLink
            title={formatMessage(messages.faqCardTitle)}
            IconComponent={SVGs.ChatMono}
            IconComponentRight={SVGs.ExternalLinkMono}
            onPress={handleFaqPress}
            paragraph={formatMessage(messages.faqDescription)}
          />
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

HelpAndContactScreen.routeName = "HelpAndContactScreen";
HelpAndContactScreen.navigationOptions = {
  title: messages.navigationTitle,
};
