import moment from "moment";
import { uniqWith } from "lodash/fp";

export const getMinutesDiff = (end, start = moment()) =>
  Math.round(moment.duration(end.diff(start)).asMinutes() * 100) / 100;

export const isToday = (date) => {
  const momentDate = moment(date);
  const now = moment();
  return !momentDate.isBefore(now, "day") && !momentDate.isAfter(now, "day");
};

export const DATE_FORMAT_DEFAULT = "YYYY-MM-DD";

export const formatNextTwoDaysAsRelative_DateOtherwise = ({
  date,
  formatDateOptions,
  intl,
  nowOverride,
}) => {
  const { formatDate, formatRelativeTime } = intl;
  const dateMoment = moment(date);
  const today = nowOverride ? moment(nowOverride) : moment();
  const tomorrow = today.clone().add(1, "day");
  let days;
  if (dateMoment.isSame(today, "day")) {
    days = 0;
  } else if (dateMoment.isSame(tomorrow, "day")) {
    days = 1;
  }

  if ([0, 1].includes(days)) {
    return formatRelativeTime(days, "day", {
      numeric: "auto",
    });
  } else {
    return formatDate(date, formatDateOptions);
  }
};

export const uniqLocal = (dates) =>
  uniqWith(
    (date, anotherDate) => moment(date).isSame(anotherDate, "day"),
    dates
  );

export const isDelayed = ({ date, now }) => moment(now).isAfter(date);

export const getDiffFromNowInSeconds = (date) =>
  moment.utc(date).diff(moment(), "seconds");
