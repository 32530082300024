import React, { useCallback, useMemo } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { head } from "lodash/fp";
import { useLegal } from "@eyr-mobile/domain/Legal";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Consent } from "../../components";

import { styles } from "./TermsAndConditionsScreen.styles";
import { messages } from "./TermsAndConditionsScreen.messages";

export function TermsAndConditionsScreen() {
  const { consentsToAccept, acceptConsent, rejectConsent } = useLegal();

  const consent = useMemo(() => head(consentsToAccept), [consentsToAccept]);

  const onAccepted = useCallback(
    () => acceptConsent(consent),
    [acceptConsent, consent]
  );
  const onRejected = useCallback(
    () => rejectConsent(consent),
    [consent, rejectConsent]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {renderIf(consent)(() => (
        <Consent {...consent} onAccepted={onAccepted} onRejected={onRejected} />
      ))}
    </SafeAreaView>
  );
}

TermsAndConditionsScreen.routeName = "TermsAndConditionsScreen";
TermsAndConditionsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
