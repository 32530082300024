import React, { useCallback } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { View, ScrollView } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useNavigation } from "@react-navigation/native";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { useDevice } from "@eyr-mobile/core/Device";
import {
  GetProducts,
  ProductType,
  useInitOrder,
} from "@eyr-mobile/domain/Order";

import {
  ProductsListCatalog,
  EyrButton,
  Paragraph,
  OrderedList,
  Subtitle,
} from "../../components";

import { messages } from "./VaccinationCatalogScreen.messages";
import { styles } from "./VaccinationCatalogScreen.styles";

export function VaccinationCatalogScreen() {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { screenSizeSelect } = useDevice();

  const { handlers, data, refetch } = withHandlers(
    useQuery(GetProducts, { variables: { type: ProductType.VACCINATION } })
  );

  const [initOrder] = useInitOrder();

  const handleNavigateToPharmaciesScreen = useCallback(() => {
    navigate("PharmaciesScreen");
  }, [navigate]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <ScrollView
        contentContainerStyle={screenSizeSelect({
          xs: styles.contentContainerXS,
          s: styles.contentContainerS,
          m: styles.contentContainerM,
        })}
      >
        {handlers || (
          <ProductsListCatalog
            refreshing={false}
            products={data.products}
            onRefresh={refetch}
            onSelected={initOrder}
            accessibilityLabel={"Vaccines catalog"}
            ListFooterComponent={
              <View>
                <View style={styles.textSection}>
                  <Paragraph size="l" spacing="m">
                    {formatMessage(messages.terms)}
                  </Paragraph>
                </View>
                <View style={styles.textSection}>
                  <Subtitle size="l" spacing="l">
                    {formatMessage(messages.instructionsTitle)}
                  </Subtitle>
                  <OrderedList>
                    <Paragraph size="l" spacing="m">
                      {formatMessage(messages.orderInstructionsText)}
                    </Paragraph>
                    <Paragraph size="l" spacing="m">
                      {formatMessage(messages.prescriptionInstructionsText)}
                    </Paragraph>
                    <Paragraph size="l" spacing="m">
                      {formatMessage(messages.pharmacyInstructionsText)}
                    </Paragraph>
                  </OrderedList>
                </View>
                <View style={styles.textSection}>
                  <EyrButton
                    onPress={handleNavigateToPharmaciesScreen}
                    variant="primary"
                    size="l"
                    title={formatMessage(messages.findPharmacyButtonText)}
                  />
                </View>
              </View>
            }
          />
        )}
      </ScrollView>
    </SafeAreaView>
  );
}
VaccinationCatalogScreen.routeName = "VaccinationCatalogScreen";
VaccinationCatalogScreen.navigationOptions = {
  title: messages.navigationTitle,
};
