import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib";
import { useIntl } from "react-intl";
import { upperFirst, camelCase } from "lodash/fp";

import { Heading } from "../Heading";
import { Tag } from "../Tag";
import { IconContainer } from "../IconContainer";
import { VideoPlayer } from "../VideoPlayer";
import { AudioPlayer } from "../AudioPlayer";
import { PortableText, PortableTextImage } from "../PortableText";
import { SVGs } from "../../res";

import { styles } from "./Content.styles";
import { messages } from "./Content.messages";

export const ContentIconByType = {
  ContentPsychoEducation: <SVGs.Article />,
  ContentVideoExercise: <SVGs.MediaVideo />,
  ContentTextExercise: <SVGs.Exercise />,
  ContentAudioExercise: <SVGs.MediaAudio />,
};

export function Content({
  content: {
    __typename,
    title,
    kind,
    program,
    bannerImage,
    content,
    description,
    duration,
    url,
  },
}) {
  const { formatMessage } = useIntl();
  return (
    <View>
      <View style={styles.iconContainer}>
        <IconContainer variant="plain">
          {ContentIconByType[__typename]}
        </IconContainer>
      </View>

      <View style={styles.textBlockContainer}>
        <Heading size="l" spacing="m">
          {title}
        </Heading>

        <View style={styles.tagsContainer}>
          <View style={styles.tagContainer}>
            <Tag>
              {formatMessage(messages[`kind${upperFirst(camelCase(kind))}`])}
            </Tag>
          </View>
          <View style={styles.tagContainer}>
            <Tag>{program}</Tag>
          </View>
        </View>
      </View>

      {renderIf(
        ["ContentTextExercise", "ContentPsychoEducation"].includes(__typename)
      )(() => (
        <>
          {renderIf(bannerImage)(() => (
            <View style={styles.bannerImageContainer}>
              <PortableTextImage value={bannerImage} />
            </View>
          ))}
          <PortableText value={JSON.parse(content)} />
        </>
      ))}

      {renderIf(__typename === "ContentAudioExercise")(() => (
        <>
          <PortableText value={JSON.parse(description)} />
          <View style={styles.textBlockContainer}>
            <AudioPlayer source={url} duration={duration} />
          </View>
        </>
      ))}

      {renderIf(__typename === "ContentVideoExercise")(() => (
        <>
          <PortableText value={JSON.parse(description)} />
          <View style={styles.mediaBlockContainer}>
            <VideoPlayer source={{ uri: url }} />
          </View>
        </>
      ))}

      {/* TODO feedback section */}
    </View>
  );
}

Content.propTypes = {
  content: PropTypes.shape({
    __typename: PropTypes.string,
    title: PropTypes.string,
    kind: PropTypes.string,
    program: PropTypes.string,
    bannerImage: PropTypes.string,
    content: PropTypes.string,
    description: PropTypes.string,
    duration: PropTypes.number,
    url: PropTypes.string,
  }),
};
