import React, { useRef, useEffect } from "react";
import { isFunction } from "lodash/fp";
/**
 * @example predefined conditional rendering
 * const error = "";
 * const renderIfError = renderIf(error)
 * {renderIfError(
   <Text>{error}</Text>
 )}
 *
 * @example lazy evaluation (When you don't have data field initialized)
 * {renderIf(data)(() => (<Text>{data}</Text>)}
 *
 * @example in place conditional rendering
 * {renderIf(error)(<Text>{error}</Text>})}
 * @param predicate {*}
 * @returns {function(*): null}
 */
export const renderIf = (predicate) => (element) => {
  if (predicate) {
    return isFunction(element) ? element() : element;
  }
  return null;
};

export const renderIfElse =
  (predicate) =>
  (elementTrue, elementFalse = null) => {
    if (predicate) {
      return isFunction(elementTrue) ? elementTrue() : elementTrue;
    } else {
      return isFunction(elementFalse) ? elementFalse() : elementFalse;
    }
  };

export const wrapWith = (Wrapper, props) => (Component) => {
  class WrappedComponent extends Component {
    static displayName = `with${Wrapper.displayName || Wrapper.name}(${
      Component.displayName || Component.name
    })`;
    render() {
      return (
        <Wrapper
          {...(isFunction(props)
            ? props(this.props)
            : { ...props, ...this.props })}
        >
          {super.render()}
        </Wrapper>
      );
    }
  }
  return WrappedComponent;
};

export const ConditionalWrapper = ({ condition, Wrapper, children, ...rest }) =>
  condition ? <Wrapper {...rest}>{children}</Wrapper> : children;

// https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useOnUnmount(onUnmount, dependencies) {
  const isUnmountingRef = useRef(false);
  useEffect(() => {
    return () => {
      isUnmountingRef.current = true;
    };
  }, []);

  useEffect(() => {
    return () => {
      if (isUnmountingRef.current) {
        onUnmount();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
