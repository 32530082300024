import React from "react";
import PropTypes from "prop-types";
import { get, omit, upperFirst } from "lodash/fp";
import { useIntl } from "react-intl";
import { formatNextTwoDaysAsRelative_DateOtherwise } from "@eyr-mobile/core/Lib";
import { GetQueueWaitingTimeMinutes } from "@eyr-mobile/domain/Order";
import { useQuery } from "@eyr-mobile/core/DataProvider";

import { SVGs } from "../../res";
import { Card } from "../Card";
import { IconContainer } from "../IconContainer";

import { messages } from "./FlowStageSpecifyAppointmentInformationSummaryItem.messages";

export function FlowStageSpecifyAppointmentInformationSummaryItem({
  now,
  testOnly_nowOverride,
  timeslot,
  product: { type: productType } = {},
  ...rest
}) {
  const intl = useIntl();
  const { formatMessage, formatTime } = intl;

  const { data = {}, loading } = useQuery(GetQueueWaitingTimeMinutes, {
    skip: !now,
  });
  const { queueWaitingTimeMinutes } = data;

  const serviceProviderTitleMessage = get(
    `serviceProviderTitleFor_${productType}`,
    messages
  );

  return (
    <Card
      icon={
        <IconContainer size="s" variant="plain">
          {now ? <SVGs.DropIn /> : <SVGs.CalendarTime />}
        </IconContainer>
      }
      title={
        now
          ? formatMessage(messages.dropInSelectionTitle, {
              serviceProviderTitle: formatMessage(serviceProviderTitleMessage, {
                amount: 1,
              }),
            })
          : formatMessage(messages.appointmentSelectionTitle, {
              date: upperFirst(
                formatNextTwoDaysAsRelative_DateOtherwise({
                  date: get("startsAt", timeslot),
                  intl,
                  nowOverride: testOnly_nowOverride,
                })
              ),
              time: formatTime(get("startsAt", timeslot)),
            })
      }
      description={
        now
          ? !loading &&
            queueWaitingTimeMinutes &&
            `${formatMessage(messages.dropInSelectionDescription)}:`
          : formatMessage(messages.appointmentSelectionDescription, {
              name: get("provider.name", timeslot),
            })
      }
      extraInfo={
        now
          ? !loading &&
            queueWaitingTimeMinutes &&
            formatMessage(messages.waitingTime, {
              queueWaitingTimeMinutes,
            })
          : null
      }
      titleIsBold
      InteractivityIconComponent={SVGs.PencilMono}
      {...omit(["__typename", "orderId", "product", "stageId"], rest)}
    />
  );
}
FlowStageSpecifyAppointmentInformationSummaryItem.propTypes = {
  now: PropTypes.bool.isRequired,
  testOnly_nowOverride: PropTypes.string,
  timeslot: PropTypes.shape({
    startsAt: PropTypes.string.isRequired,
    provider: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  }),
  product: PropTypes.shape({
    type: PropTypes.oneOf([
      "CONSULTATION",
      "PSYCHOLOGIST_CONSULTATION",
      "GUIDE_CONSULTATION",
    ]).isRequired,
  }).isRequired,
};
