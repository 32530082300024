import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  documentsCountContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  documentsCountIcon: {
    marginRight: 8,
  },
  documentsCountText: {
    marginRight: 8,
    flexGrow: 1,
  },
  alertMessageContainer: {
    flex: 1,
  },
});
