import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Image, View } from "react-native";
import { getInitials } from "@eyr-mobile/core/Lib/string";
import { Grayscale } from "@eyr-mobile/core/ImageFilters";

import { Paragraph } from "../Paragraph";

import { sizeStyles, stylingHelpers } from "./ProfileImage.styles";

const URL_IMAGE_DEFAULT_DOCTOR_IMAGE =
  "http://res.cloudinary.com/eyr/image/upload/c_scale,q_100,w_500/c_crop,h_500,q_100,w_500,y_0/v1535122428/EYR_MOS_JulianneLeikanger_highres-5_bpusv6.jpg";

const URL_IMAGE_DEFAULT_BOOKING_IMAGE = URL_IMAGE_DEFAULT_DOCTOR_IMAGE;

export const ProfileImage = ({
  uri,
  placeholder,
  size = "m",
  shape = "rounded",
  disabled,
}) => {
  const borderRadiusStyle = stylingHelpers.getBorderRadiusStyle({
    size,
    shape,
  });
  const placeholderStyle = stylingHelpers.getPlaceholderStyle(disabled);
  const initials = useMemo(() => getInitials(placeholder), [placeholder]);
  const textColor = disabled ? "dark" : "primary";
  const ImageWithFilter = disabled ? (
    <Grayscale>
      <Image
        source={{ uri: uri || URL_IMAGE_DEFAULT_BOOKING_IMAGE }}
        style={[sizeStyles[size], borderRadiusStyle]}
      />
    </Grayscale>
  ) : (
    <Image
      source={{ uri: uri || URL_IMAGE_DEFAULT_BOOKING_IMAGE }}
      style={[sizeStyles[size], borderRadiusStyle]}
    />
  );
  return uri || !placeholder ? (
    ImageWithFilter
  ) : (
    <View style={[placeholderStyle, sizeStyles[size], borderRadiusStyle]}>
      <Paragraph size={size} color={textColor}>
        {initials}
      </Paragraph>
    </View>
  );
};

ProfileImage.propTypes = {
  placeholder: PropTypes.string,
  size: PropTypes.oneOf(["s", "m", "l"]),
  shape: PropTypes.oneOf(["round", "rounded"]),
  uri: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ProfileImage;
