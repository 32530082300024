import { StyleSheet } from "react-native";

import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  contentContainer: {
    flex: 1,
  },
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
});
