export const CallChannelMessages = {
  TRIGGER_END: "trigger_end",
  HANDLE_END: "handle_end",
  HANDLE_CALL_ACCEPTED_ELSEWHERE: "call_accepted_elsewhere",
  TRIGGER_CALL_ACCEPTED: "call_accepted",
  REPORT_VIDEO_API_EVENT: "report_video_api_event",
};
export const CallEndedReasons = {
  CALL_ENDED_BY_CALLING_PARTY: "by_caller",
  CALL_ENDED_BY_CALLED_PARTY: "by_called",
  CALL_ENDED_WITH_NO_CONTEXT: "call_ended",
  CALL_TIMED_OUT: "timeout",
  CALL_ACCEPTED_ELSEWHERE: "call_accepted_elsewhere",
};
