import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "EnableAppleReviewModeScreen.navigationTitle",
    description: "EnableAppleReviewModeScreen navigation title",
    defaultMessage: "Demo Mode Protection",
  },
  passcodePromptTitle: {
    id: "EnableAppleReviewModeScreen.passcodePromptTitle",
    description: "Phrase to prompt the user to enter demo mode",
    defaultMessage: "Enter your passcode to enter demo mode",
  },
  passcodeConfirmationActionTitle: {
    id: "EnableAppleReviewModeScreen.passcodeConfirmationActionTitle",
    description: "Ok",
    defaultMessage: "Ok",
  },
  placeholderPasscodeInputTitle: {
    id: "EnableAppleReviewModeScreen.placeholderPasscodeInputTitle",
    description: "Passcode",
    defaultMessage: "Passcode",
  },
});
