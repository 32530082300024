import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "@eyr-mobile/core/Intl";
import { noop } from "lodash/fp";

import { ServiceCard } from "../ServiceCard";

import { messages } from "./VaccinationServiceCard.messages";

export function VaccinationServiceCard({
  state,
  summary,
  order,
  onDismissPress = noop,
  onFindPharmacyPress = noop,
}) {
  const { formatMessage, formatDate, formatTime } = useIntl();

  const textColor = state === "refunded" ? "dark" : "primary";

  const commonProps = { summary, textColor };

  switch (state) {
    case "booked":
      return (
        <ServiceCard
          {...commonProps}
          informationTitle={formatMessage(messages.defaultState)}
          informationDescription={formatMessage(messages.defaultInstructions)}
          isInvertedEmphasis
        />
      );
    case "completed":
      return (
        <ServiceCard
          {...commonProps}
          isInvertedEmphasis
          instructionTitle={formatMessage(messages.completedState)}
          instructionDescription={formatMessage(messages.completedInstructions)}
          onDismissPress={onDismissPress}
          showDismiss={true}
          instructionAction={{
            title: formatMessage(messages.findPharmacyActionTitle),
            onPress: onFindPharmacyPress,
          }}
        />
      );
    case "refunded":
      return (
        <ServiceCard
          {...commonProps}
          alertTitle={formatMessage(messages.refundedHeader)}
          onDismissPress={onDismissPress}
          showDismiss={true}
          isGrayscale
          informationTitle={formatMessage(messages.refundedOrderDateTitle)}
          informationDescription={formatMessage(messages.refundedOrderDate, {
            date: formatDate(order.insertedAt),
            time: formatTime(order.insertedAt),
          })}
        />
      );
    default:
      return null;
  }
}

VaccinationServiceCard.propTypes = {
  state: PropTypes.oneOf(["booked", "completed", "refunded"]),
  summary: PropTypes.string.isRequired,
  order: PropTypes.shape({
    insertedAt: PropTypes.string.isRequired,
  }).isRequired,
  onDismissPress: PropTypes.func,
  onFindPharmacyPress: PropTypes.func,
};
