import React, { useCallback, useEffect, useMemo, useState } from "react";
import { View, FlatList, KeyboardAvoidingView } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  GetTravelControlCountryData,
  useOrderFlowStage,
  useTravelControlQuestion,
} from "@eyr-mobile/domain/Order";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { filter, find, isEmpty } from "lodash/fp";
import { useAlert } from "@eyr-mobile/core/Alert";
import { includesValueInOneOfTheObjectFields } from "@eyr-mobile/core/Lib";
import { useDevice } from "@eyr-mobile/core/Device";
import { useNavigation } from "@react-navigation/native";

import {
  ProgressIndicator,
  EyrTextInput,
  IconContainer,
  Empty,
  Card,
  Heading,
} from "../../components";
import { SVGs } from "../../res";

import {
  styles,
  stylingProps,
} from "./FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.styles";
import { messages } from "./FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.messages";

export function FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen() {
  const { screenSizeSelect } = useDevice();
  const { formatMessage } = useIntl();
  const alert = useAlert();
  const { setOptions } = useNavigation();

  const [searchText, setSearchText] = useState("");

  const {
    handlers,
    data = {},
    stageParams: { progress, progressPerStage } = {},
  } = withHandlers(
    useOrderFlowStage(GetTravelControlCountryData, ({ orderId }) => ({
      variables: { orderId },
    }))
  );

  const [{ answerWith, controlPaymentMethodId }] = useTravelControlQuestion();

  const progressWithinSubStages = progress + (2 / 3) * progressPerStage;

  const controlPaymentMethodName = find(
    { id: controlPaymentMethodId },
    data?.accountPaymentMethodsForOrder
  )?.name;

  useEffect(() => {
    if (controlPaymentMethodName) {
      setOptions({ title: controlPaymentMethodName });
    }
  }, [controlPaymentMethodName, setOptions]);

  const filteredCountries = useMemo(() => {
    return isEmpty(searchText)
      ? data.countries
      : filter((country) => {
          if (country.id === data.account.country.id) {
            return false;
          }
          return includesValueInOneOfTheObjectFields({
            value: searchText,
            fields: ["codeIso3", "codeIso2", "name"],
            object: country,
          });
        }, data.countries);
  }, [data, searchText]);

  const handleExtractKey = useCallback(({ id }) => id.toString(), []);
  const handleRenderItem = useCallback(
    ({ item: { name, codeIso3 } }) => {
      return (
        <Card
          title={name}
          onPress={() =>
            alert({
              icon: (
                <IconContainer size="xl">
                  <SVGs.GlobeMono />
                </IconContainer>
              ),
              title: `${formatMessage(messages.confirmationAlertTitle)}:`,
              message: name,
              buttonsLayout: "column",
              buttons: [
                {
                  title: formatMessage(
                    messages.confirmCountrySelectionActionTitle
                  ),
                  onPress: () => answerWith(codeIso3),
                },
                {
                  title: formatMessage(messages.alertCloseActionTitle),
                  variant: "secondary",
                },
              ],
            })
          }
        />
      );
    },
    [alert, answerWith, formatMessage]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <View style={styles.headerContainer}>
            <View
              style={screenSizeSelect({
                xs: styles.headerContentContainerXS,
                s: styles.headerContentContainerS,
                m: styles.headerContentContainerM,
              })}
            >
              <Heading alignment="center" size="s" spacing="l">
                {formatMessage(messages.title)}
              </Heading>
              <EyrTextInput
                placeholder={formatMessage(messages.searchInputPlaceholder)}
                onChangeText={setSearchText}
                variant="UNSAFE_search"
                startAdornment={
                  <SVGs.SearchMono {...stylingProps.searchIcon} />
                }
              />
            </View>
          </View>
          <KeyboardAvoidingView
            style={styles.emptyContainer}
            contentContainerStyle={styles.emptyContentContainer}
            behavior="height"
          >
            <FlatList
              data={filteredCountries}
              keyExtractor={handleExtractKey}
              renderItem={handleRenderItem}
              contentContainerStyle={[
                styles.contentContainer,
                screenSizeSelect({
                  xs: styles.contentContainerXS,
                  s: styles.contentContainerS,
                  m: styles.contentContainerM,
                }),
              ]}
              ListEmptyComponent={
                <Empty
                  message={formatMessage(messages.listEmptyTitle, {
                    searchText,
                  })}
                />
              }
            />
          </KeyboardAvoidingView>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.routeName =
  "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen";

FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.navigationOptions =
  {
    title: null,
  };
