import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "IdentificationMethodScreen.navigationTitle",
    description: "IdentificationMethodScreen navigation title",
    defaultMessage: "Identification method",
  },
  identityProvidersListTitle: {
    id: "IdentificationMethodScreen.identityProvidersListTitle",
    description: "Identity providers list title",
    defaultMessage: "Sign in with",
  },
  countryChangerTitle: {
    id: "IdentificationMethodScreen.countryChangerTitle",
    description: "Country changer title",
    defaultMessage: "Change country",
  },
});
