import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "FlowStageSetPaymentMethods_ControlQuestionChildScreen.title",
    description: "FlowStageSetPaymentMethods_ControlQuestionChildScreen title",
    defaultMessage: "Is your child insured?",
  },
  description: {
    id: "FlowStageSetPaymentMethods_ControlQuestionChildScreen.description",
    description:
      "FlowStageSetPaymentMethods_ControlQuestionChildScreen description",
    defaultMessage:
      "Storebrand Health insurance only covers consultations for children with their own health insurance." +
      " Consultations for uninsured children can be paid for with a bank card or another valid payment method.",
  },
  confirmActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionChildScreen.confirmActionTitle",
    description:
      "FlowStageSetPaymentMethods_ControlQuestionChildScreen confirm action title",
    defaultMessage: "Yes, my child is insured",
  },
  declineActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionChildScreen.declineActionTitle",
    description:
      "FlowStageSetPaymentMethods_ControlQuestionChildScreen decline action title",
    defaultMessage: "No, change payment method",
  },
});
