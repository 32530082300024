import { defineMessages } from "react-intl";

export const messages = defineMessages({
  headerTitle: {
    id: "IncomingCallScreen.headerTitle",
    description: "IncomingCallScreen header title",
    defaultMessage: "Incoming Eyr video call",
  },
  declineCallActionTitle: {
    id: "IncomingCallScreen.declineCallActionTitle",
    description: "IncomingCallScreen decline call action title",
    defaultMessage: "Decline",
  },
  answerCallActionTitle: {
    id: "IncomingCallScreen.answerCallActionTitle",
    description: "IncomingCallScreen answer call action title",
    defaultMessage: "Accept",
  },
});
