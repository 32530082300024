import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: { flex: 1, position: "relative" },
  containerWeb: { height: "100%", flex: 1 },
  videoContainer: {
    flex: 1,
  },
  videoPlayer: StyleSheet.absoluteFill,
  overlayContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    flexDirection: "row",
  },
  controlsContainer: {
    backgroundColor: Palette.OVERLAY_DARK_40,
    flex: 1,
    height: "100%",
  },
});
