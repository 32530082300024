import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "ContentProgramsScreen.navigationTitle",
    description: "Treatment program navigation title",
    defaultMessage: "Treatment program",
  },
  moduleNumberTitle: {
    id: "ContentProgramsScreen.moduleNumberTitle",
    description: "title for module showing module number",
    defaultMessage: "Module {count}",
  },
  contentLoadError: {
    id: "ContentProgramsScreen.contentLoadError",
    description: "Message toast displayed when error loading content",
    defaultMessage: "Something went wrong",
  },
  kindExercise: {
    id: "ContentProgramsScreen.kindExercise",
    description: "kind Exercise title",
    defaultMessage: "Exercise",
  },
  kindPsychoEducation: {
    id: "ContentProgramsScreen.kindPsychoEducation",
    description: "kind PsychoEducation title",
    defaultMessage: "Psycho education",
  },
});
