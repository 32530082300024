import React from "react";
import PropTypes from "prop-types";

import { Card } from "../Card";
import { IconContainer } from "../IconContainer";
import { ResAwareSvg } from "../ResAwareSvg";

export function ProductOrderSummaryItem(props) {
  const { image, name, description } = props;
  return (
    <Card
      title={name}
      titleIsBold
      description={description}
      showInteractivityIcon={false}
      pressable={false}
      icon={
        <IconContainer size="xs" variant="plain">
          <ResAwareSvg svg={image} />
        </IconContainer>
      }
    />
  );
}
ProductOrderSummaryItem.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
};
