import { Platform, StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainer: {
    flex: 1,
    paddingBottom: 16,
    justifyContent: "space-between",
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  inputContainer: { flex: 1 },
  bottomBar: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  finishStageButtonContainer: {
    alignItems: "center",
  },
  finishStageButtonContainerXS: responsiveStyles.contentContainerXS,
  finishStageButtonContainerS: responsiveStyles.contentContainerS,
  finishStageButtonContainerM: responsiveStyles.contentContainerM,
});

export const stylingProps = {
  textInput: {
    textAlignVertical: "top",
  },
  keyboardAvoidingView: {
    ...Platform.select({
      ios: {
        keyboardVerticalOffset: 56,
        behavior: "padding",
      },
    }),
  },
};
