import React from "react";
import { View, StyleSheet } from "react-native";
import PropTypes from "prop-types";

import { Palette } from "../../shared-styles/palette";

const styles = StyleSheet.create({
  itemSeparator: {
    height: 1,
    backgroundColor: Palette.GRAY_20,
    marginHorizontal: 16,
  },
});

export const ListItemSeparator = () => <View style={styles.itemSeparator} />;

export const ListSectionSeparator = ({
  trailingSection,
  leadingSection,
  leadingItem,
}) => {
  if (
    trailingSection ||
    (leadingSection && leadingItem) ||
    (!trailingSection && !leadingSection)
  ) {
    return null;
  }
  return <ListItemSeparator />;
};

ListSectionSeparator.propTypes = {
  leadingItem: PropTypes.object,
  trailingSection: PropTypes.object,
  leadingSection: PropTypes.object,
};
