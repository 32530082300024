import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.WHITE,
    borderRadius: 12,
    padding: 24,
    ...Shadows.xs,
  },
  issue: {
    marginBottom: 24,
    alignItems: "flex-start",
  },
  lastIssue: {
    alignItems: "flex-start",
  },
  issueText: {
    marginBottom: 16,
  },
});
