import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "HowItWorksScreen.navigationTitle",
    description: "HowItWorksScreen navigation title",
    defaultMessage: "How Eyr works",
  },

  /* MAIN SCREEN TEXTS */
  header: {
    id: "HowItWorksScreen.header",
    description: "Main title text for the screen",
    defaultMessage:
      "Eyr can help you with a lot of different things. Below is an overview of our services and a description of how they work.",
  },
  footer: {
    id: "HowItWorksScreen.footer",
    description: "Footer text shown underneath the accordion menu pieces",
    defaultMessage:
      "For more questions please visit {url} or contact our customer support at {email}",
  },
  /* MENU TEXTS */
  /* Doctor menu texts */
  menuDoctorTitle: {
    id: "HowItWorksScreen.menuDoctorTitle",
    description: "Accordion doctor menu title",
    defaultMessage: "Talk to a doctor",
  },
  menuDoctorSummary: {
    id: "HowItWorksScreen.menuDoctorSummary",
    description: "top summary section for the doctor menu",
    defaultMessage:
      "Our doctors can help you with medical advice and guidance, prescriptions in all EU/EØS countries, referrals and much more. Our doctors can also provide you with a medical certificate in some cases. Be aware that some pharmacies in some EU/EEA countries do not accept international prescriptions.",
  },
  menuDoctorBookingTitle: {
    id: "HowItWorksScreen.menuDoctorBookingTitle",
    description: "booking section title",
    defaultMessage: "Booking",
  },
  menuDoctorBookingText: {
    id: "HowItWorksScreen.menuDoctorBookingText",
    description: "Booking section main text",
    defaultMessage:
      "Click on the “talk to a doctor” on the home screen, and choose when and who you want to talk to. You can order the consultation for yourself or your children under the age of sixteen. If you have insurance, remember to add this to your account before or while completing the booking.",
  },
  menuDoctorContactTitle: {
    id: "HowItWorksScreen.menuDoctorContactTitle",
    description: "contact section title",
    defaultMessage: "We will call you",
  },
  menuDoctorContactTextP1: {
    id: "HowItWorksScreen.menuDoctorContactTextP1",
    description: "contact section main text, paragraph 1",
    defaultMessage:
      "When it is your turn, one of our skilled doctors will call you. You will receive a call on your phone or web browser, depending on where you booked your appointment. Please be ready to take the video call in a quiet, well-lighted place with good internet connection.",
  },
  menuDoctorContactTextP2: {
    id: "HowItWorksScreen.menuDoctorContactTextP2",
    description: "contact section main text, paragraph two",
    defaultMessage:
      "Everyone that works in Eyr have their medical license and are used to helping patients with a variety of issues. As long as you dont require a physical consultation, we can help you.",
  },
  menuDoctorButton: {
    id: "HowItWorksScreen.menuDoctorButton",
    description: "Doctor booking button title",
    defaultMessage: "Book a consultation",
  },

  /* Psychologist menu texts */
  menuPsychologistTitle: {
    id: "HowItWorksScreen.menuPsychologistTitle",
    description: "Accordion psychologist menu title",
    defaultMessage: "Talk to a psychologist",
  },
  menuPsychologistSummary: {
    id: "HowItWorksScreen.menuPsychologistSummary",
    description: "top summary section for the psychologist menu",
    defaultMessage:
      "Eyr psychologists are experienced helpers - whether you are in a demanding and stressful period of life or find yourself suddenly facing a crisis. Perhaps you are going through something like bereavement, breakup or job loss, or you are feeling depressed or worried and anxious.",
  },
  menuPsychologistBookingTitle: {
    id: "HowItWorksScreen.menuPsychologistBookingTitle",
    description: "booking section title",
    defaultMessage: "Booking",
  },
  menuPsychologistBookingTextP1: {
    id: "HowItWorksScreen.menuPsychologistBookingTextP1",
    description: "Psychologist booking section, paragraph 1",
    defaultMessage:
      "Click “Talk to a psychologist” on the home screen to choose a time that suits you and who you want to talk to.",
  },
  menuPsychologistBookingTextP2: {
    id: "HowItWorksScreen.menuPsychologistBookingTextP2",
    description: "Psychologist booking section, paragraph 2",
    defaultMessage:
      "If you have insurance, remember to add this to your account before or while completing the booking.",
  },
  menuPsychologistBookingTextP3: {
    id: "HowItWorksScreen.menuPsychologistBookingTextP3",
    description: "Psychologist booking section, paragraph 3",
    defaultMessage:
      "Before your appointment you will receive a questionnaire in the app, asking you about different symptoms you may or may not be experiencing. Submitting this questionnaire will enable the psychologist to better tailor the consultation to your needs.",
  },
  menuPsychologistContactTitle: {
    id: "HowItWorksScreen.menuPsychologistContactTitle",
    description: "Psychologist contact section title",
    defaultMessage: "The psychologist will call you",
  },
  menuPsychologistContactTextP1: {
    id: "HowItWorksScreen.menuPsychologistContactTextP1",
    description: "Psychologist contact section, paragraph 1",
    defaultMessage:
      "At the time of your consultation your psychologist will call you. You will receive a call on your phone or web browser, depending on where you booked your appointment. Please be ready to take the video call in a quiet, well-lighted place with good internet connection.",
  },
  menuPsychologistContactTextP2: {
    id: "HowItWorksScreen.menuPsychologistContactTextP2",
    description: "Psychologist contact section, paragraph 2",
    defaultMessage:
      "Consultations with a psychologist will last up to 45 minutes, and together you will figure out how to move forward.",
  },
  menuPsychologistContactTextP3: {
    id: "HowItWorksScreen.menuPsychologistContactTextP3",
    description: "psychologist contact section, paragrah 3",
    defaultMessage:
      "All psychologists in Eyr have Norwegian authorisation, experience with a broad variety of issues, and are members of the Norwegian Psychological Association.",
  },
  menuPsychologistButton: {
    id: "HowItWorksScreen.menuPsychologistButton",
    description: "Psychologist booking button title",
    defaultMessage: "Book a consultation",
  },

  /* Vaccine menu texts */
  menuVaccineTitle: {
    id: "HowItWorksScreen.menuVaccineTitle",
    description: "Accordion vaccine menu title",
    defaultMessage: "Order vaccines",
  },
  menuVaccineOrderTitle: {
    id: "HowItWorksScreen.menuVaccineOrderTitle",
    description: "Order vaccine section title",
    defaultMessage: "Ordering",
  },
  menuVaccineOrderText: {
    id: "HowItWorksScreen.menuVaccineOrderText",
    description: "Order vaccine section text",
    defaultMessage:
      "Click “Order a vaccine” on the home screen, and choose which vaccine you would like to order. Fill out a simple questionnaire.",
  },
  menuVaccinePrescriptionsTitle: {
    id: "HowItWorksScreen.menuVaccinePrescriptionsTitle",
    description: "Vaccine prescriptions section title",
    defaultMessage: "Your prescription is ready",
  },
  menuVaccinePrescriptionsTextP1: {
    id: "HowItWorksScreen.menuVaccinePrescriptionsTextP1",
    description: "Vaccine prescriptions section, pragraph 1",
    defaultMessage:
      "One of our doctors will send you a message in the Eyr app within 24 hours to let you know that your prescription is ready. You can then go to your preferred Apotek1 pharmacy to get vaccinated.",
  },
  menuVaccinePrescriptionsTextP2: {
    id: "HowItWorksScreen.menuVaccinePrescriptionsTextP2",
    description: "Vaccine prescriptions section, pragraph 2",
    defaultMessage:
      "The price of the vaccine and vaccination procedure should be paid at the pharmacy.",
  },
  menuVaccinePrescriptionsTextP3: {
    id: "HowItWorksScreen.menuVaccinePrescriptionsTextP3",
    description: "Vaccine prescriptions section, paragraph 3",
    defaultMessage: "We do not vaccinate children under the age of 12.",
  },
  menuVaccineButton: {
    id: "HowItWorksScreen.menuVaccineButton",
    description: "Vaccine order button title",
    defaultMessage: "Order a vaccine",
  },
  /* Patient Guide menu texts */
  menuPatientGuideTitle: {
    id: "HowItWorksScreen.menuPatientGuideTitle",
    description: "Accordion patient guide menu title",
    defaultMessage: "Patient Guide",
  },
  menuPatientGuideSummary: {
    id: "HowItWorksScreen.menuPatientGuideSummary",
    description: "Top summary section for the patient guide menu",
    defaultMessage:
      "In the event of a long-term illness, our experienced specialists in general medicine can help you understand the illness and provide guidance on the treatment.",
  },
  menuPatientGuideScheduleAppointmentTitle: {
    id: "HowItWorksScreen.menuPatientGuideScheduleAppointmentTitle",
    description: "Schedule an appointment section title",
    defaultMessage: "Schedule an appointment",
  },
  menuPatientGuideScheduleAppointmentText: {
    id: "HowItWorksScreen.menuPatientGuideScheduleAppointmentText",
    description: "Schedule an appointment section text",
    defaultMessage:
      "To schedule an appointment you need to choose Patient Guide service on the home screen, then choose a preferred time slot and the specialist you want to have a consultation with. You can book an appointment for yourself or your children under the age of 16 years old. If you have an insurance, please remember to add it to your Eyr account before or while booking an appointment  so that your consultation is covered by the insurance.",
  },
  menuPatientGuideContactTitle: {
    id: "HowItWorksScreen.menuPatientGuideContactTitle",
    description: "We will call you section title",
    defaultMessage: "We will call you",
  },
  menuPatientGuideContactTextP1: {
    id: "HowItWorksScreen.menuPatientGuideContactTextP1",
    description: "We will call you section text, paragraph one",
    defaultMessage:
      "At the time of the consultation the specialist will call you. You will receive a call on the device where you are logged in to Eyr.",
  },
  menuPatientGuideContactTextP2: {
    id: "HowItWorksScreen.menuPatientGuideContactTextP2",
    description: "We will call you section text, paragraph two",
    defaultMessage:
      "In order to get the most out of the video consultation, it is important that you are in a quiet environment with good lighting and internet coverage.",
  },
  menuPatientGuideContactTextP3: {
    id: "HowItWorksScreen.menuPatientGuideContactTextP3",
    description: "We will call you section text, paragraph three",
    defaultMessage:
      "Patient Guide consultations last between 15 and 45 minutes. The first consultation will last approximately 15 minutes. Together with the specialist, you will review your questions and what you need help with. The doctor will subsequently review the documents you have shared , and then you will need to book a new appointment with the same doctor. The second consultation will last up to 45 minutes so the doctor has plenty of time to present his/her assessment, advise you and answer your questions.",
  },
  menuPatientGuideContactTextP4: {
    id: "HowItWorksScreen.menuPatientGuideContactTextP4",
    description: "We will call you section text, paragraph four",
    defaultMessage:
      "All our specialists in general medicine have Danish authorisation, experience in helping patients with various problems and they are of course members of The Medical Association.",
  },
  menuPatientGuideButton: {
    id: "HowItWorksScreen.menuPatientGuideButton",
    description: "Patient Guide booking button title",
    defaultMessage: "Book a consultation",
  },
});
