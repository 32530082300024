import { StyleSheet } from "react-native";

import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  contentContainer: { flex: 1 },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
});
