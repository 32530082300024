import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    borderRadius: 8,
  },
  responsiveContainer: {
    borderRadius: 0,
  },
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
  iconContainer: {
    marginRight: 8,
  },
  footer: {
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "flex-end",
    flexWrap: "wrap",
  },
  actionContainer: {
    marginLeft: 8,
  },
  headerContainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "row",
    marginRight: 8,
  },
  bodyContainer: {
    paddingTop: 8,
    paddingRight: 8,
  },
  bodyContainerWithIcon: {
    paddingLeft: 28,
  },
});

export const variantStyles = {
  info: StyleSheet.create({
    container: {
      backgroundColor: Palette.PINK_40,
    },
  }),
  warning: StyleSheet.create({
    container: {
      backgroundColor: Palette.YELLOW_20,
    },
  }),
  success: StyleSheet.create({
    container: {
      backgroundColor: Palette.GREEN_0,
    },
  }),
  danger: StyleSheet.create({
    container: {
      backgroundColor: Palette.RED_0,
    },
  }),
};

export const stylingProps = {
  icon: {
    width: 20,
    height: 20,
  },
};

export const variantStylingProps = {
  info: {
    icon: {
      fill: Palette.BURGUNDY_100,
    },
    typography: {
      color: "primary",
    },
  },
  warning: {
    icon: {
      fill: Palette.YELLOW_100,
    },
    typography: {
      color: "warning",
    },
  },
  success: {
    icon: {
      fill: Palette.GREEN_100,
    },
    typography: {
      color: "positive",
    },
  },
  danger: {
    icon: {
      fill: Palette.RED_100,
    },
    typography: {
      color: "negative",
    },
  },
};
