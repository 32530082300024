import { StyleSheet } from "react-native";

import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    paddingBottom: 16,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    paddingBottom: 32,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    paddingBottom: 48,
    ...responsiveStyles.contentContainerM,
  },
  textSection: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});
