import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { map, noop } from "lodash/fp";
import { useIntl } from "react-intl";
import { WebView } from "react-native-webview";
import {
  getAuthorizationBearerHeader,
  injectWebWebViewAuth,
} from "@eyr-mobile/domain/Auth";
import {
  patchPostMessageInjectedJavaScript,
  applicationNameForUserAgent,
  safeHandlePostMessage,
} from "@eyr-mobile/core/WebView";
import { LoggerFactory } from "@eyr-mobile/core/Logger";
import { getAdditionalHeaders, getWebViewURL } from "@eyr-mobile/core/Net";
import { Platform } from "react-native";
import { useWhiteLabel } from "@eyr-mobile/domain/WhiteLabel";

const logger = LoggerFactory.get("components/AddInsuranceOrDiscount");

export function AddInsuranceOrDiscount({
  onSucceeded,
  onFailed,
  accessToken,
  specificInsuranceOrDiscountUrl,
}) {
  const { locale } = useIntl();
  const whiteLabel = useWhiteLabel();

  const uri =
    specificInsuranceOrDiscountUrl ||
    `${getWebViewURL()}/insurances${whiteLabel ? `?wl=${whiteLabel}` : ""}`;

  const handleMessage = useCallback(
    ({ nativeEvent }) => {
      safeHandlePostMessage(
        nativeEvent,
        (message, data, level) => {
          logger(`${uri}:${message}`, data, level);
        },
        (data) => {
          const [result, payload] = data.split(":");
          switch (result) {
            case "ok":
              onSucceeded(map(Number, payload.split(",")));
              break;
            case "error":
              onFailed(payload);
              break;
            default:
          }
        }
      );
    },
    [uri, onSucceeded, onFailed]
  );

  return (
    <WebView
      key={locale}
      source={Platform.select({
        default: {
          headers: {
            ...getAdditionalHeaders(),
            ...getAuthorizationBearerHeader(accessToken),
          },
          uri,
        },
        web: {
          uri: injectWebWebViewAuth(uri, accessToken),
        },
      })}
      injectedJavaScript={patchPostMessageInjectedJavaScript}
      onMessage={handleMessage}
      originWhitelist={[getWebViewURL().origin]}
      applicationNameForUserAgent={applicationNameForUserAgent}
      testID={"AddInsuranceOrDiscount"}
      web_postMessageTarget={"window"}
    />
  );
}

AddInsuranceOrDiscount.defaultProps = {
  onSucceeded: noop,
  onFailed: noop,
};

AddInsuranceOrDiscount.propTypes = {
  onSucceeded: PropTypes.func,
  onFailed: PropTypes.func,
  accessToken: PropTypes.string.isRequired,
  specificInsuranceOrDiscountUrl: PropTypes.string,
};
