import { defineMessages } from "react-intl";

export const messages = defineMessages({
  numberInputLabel: {
    id: "AddCreditCard.numberInputLabel",
    description: "Credit card number input label",
    defaultMessage: "Card number",
  },
  numberInputPlaceholder: {
    id: "AddCreditCard.numberInputPlaceholder",
    description: "Credit card number input placeholder",
    defaultMessage: "Add card number (16 digits)",
  },
  numberInputLengthError: {
    id: "AddCreditCard.numberInputLengthError",
    description: "Credit card number input length validation error message",
    defaultMessage: "Your card number must be {length} digits",
  },
  expirationInputLabel: {
    id: "AddCreditCard.expirationInputLabel",
    description: "Credit card expiration date input label",
    defaultMessage: "Expiration date",
  },
  expirationInputPlaceholder: {
    id: "AddCreditCard.expirationInputPlaceholder",
    description: "Credit card expiration date input placeholder",
    defaultMessage: "MM/YY",
  },
  expirationInputInvalidError: {
    id: "AddCreditCard.expirationInputInvalidError",
    description: "Credit card expiration date input error",
    defaultMessage: "Invalid expiration date",
  },
  cvcInputLabel: {
    id: "AddCreditCard.cvcInputLabel",
    description: "Credit card safety code input label",
    defaultMessage: "Safety code",
  },
  cvcInputPlaceholder: {
    id: "AddCreditCard.cvcInputPlaceholder",
    description: "Credit card safety code input placeholder",
    defaultMessage: "CVC (3 digits)",
  },
  cvcInputLengthError: {
    id: "AddCreditCard.cvcInputLengthError",
    description:
      "Credit card safety code input length validation error message",
    defaultMessage: "Your CVC must be {length} digits",
  },
  fieldIsRequiredError: {
    id: "AddCreditCard.fieldIsRequiredError",
    description: "Required field validation error message",
    defaultMessage: "Field is required",
  },
  addCard: {
    id: "AddCreditCard.addCard",
    description: "Add credit card button title",
    defaultMessage: "Add card",
  },
  safePaymentMessage: {
    id: "AddCreditCard.safePaymentMessage",
    description: "Safe payment with credit card message",
    defaultMessage: "Safe payment via Stripe",
  },
  cardValidityCheckMessage: {
    id: "AddCreditCard.cardValidityCheckMessage",
    description: "Add card explanation message",
    defaultMessage:
      "We check the validity of the card - you will not be charged until you confirm the order",
  },
  card_declined: {
    id: "AddCreditCard.card_declined",
    description: "Card declined. Please try again with another card",
    defaultMessage: "Card declined. Please try again with another card",
  },
  expired_card: {
    id: "AddCreditCard.expired_card",
    description: "Card expired. Please try again with another card",
    defaultMessage: "Card expired. Please try again with another card",
  },
  incorrect_cvc: {
    id: "AddCreditCard.incorrect_cvc",
    description:
      "Invalid security code. Please enter a valid security code (CVC)",
    defaultMessage:
      "Invalid security code. Please enter a valid security code (CVC)",
  },
  invalid_cvc: {
    id: "AddCreditCard.invalid_cvc",
    description:
      "Invalid security code. Please enter a valid security code (CVC)",
    defaultMessage:
      "Invalid security code. Please enter a valid security code (CVC)",
  },
  incorrect_number: {
    id: "AddCreditCard.incorrect_number",
    description: "Invalid card number. Please enter a valid card number",
    defaultMessage: "Invalid card number. Please enter a valid card number",
  },
  invalid_expiry_month: {
    id: "AddCreditCard.invalid_expiry_month",
    description:
      "Invalid expiration month. Please enter a valid expiration month",
    defaultMessage:
      "Invalid expiration month. Please enter a valid expiration month",
  },
  invalid_expiry_year: {
    id: "AddCreditCard.invalid_expiry_year",
    description:
      "Invalid expiration year. Please enter a valid expiration year",
    defaultMessage:
      "Invalid expiration year. Please enter a valid expiration year",
  },
  invalid_number: {
    id: "AddCreditCard.invalid_number",
    description: "Invalid card number. Please enter a valid card number",
    defaultMessage: "Invalid card number. Please enter a valid card number",
  },
  processing_error: {
    id: "AddCreditCard.processing_error",
    description: "An error occurred while processing the transaction.",
    defaultMessage: "An error occurred while processing the transaction.",
  },
  rate_limit: {
    id: "AddCreditCard.rate_limit",
    description:
      "The server has sent too many requests. Please contact support if you see this message more than once.",
    defaultMessage:
      "The server has sent too many requests. Please contact support if you see this message more than once.",
  },
  defaultError: {
    id: "AddCreditCard.defaultError",
    description:
      "Something went wrong. Please try again with another card or contact customer support",
    defaultMessage:
      "Something went wrong. Please try again with another card or contact customer support",
  },
});
