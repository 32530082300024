import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    alignSelf: "stretch",
  },
  labelContainer: {
    alignSelf: "stretch",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 4,
  },
  thumb: {
    ...Shadows.xs,
  },
});

export const stylingProps = {
  audio: {
    thumbTintColor: Palette.WHITE,
    minimumTrackTintColor: Palette.BURGUNDY_100,
    maximumTrackTintColor: Palette.BURGUNDY_0,
  },
  video: {
    thumbTintColor: Palette.WHITE,
    minimumTrackTintColor: Palette.WHITE,
    maximumTrackTintColor: Palette.OVERLAY_LIGHT_30,
  },
};
