import { getDistance, convertDistance } from "geolib";
import { isNumber } from "lodash/fp";

import { usesMetricSystem } from "../Intl";

function getDistanceValues(distanceInMeters) {
  if (!isNumber(distanceInMeters)) {
    return { unit: undefined, distance: undefined };
  }
  if (usesMetricSystem()) {
    const distanceInKilometers = convertDistance(distanceInMeters, "km");
    if (distanceInKilometers < 1) {
      return { unit: "meter", distance: distanceInMeters.toFixed(0) };
    } else {
      return { unit: "kilometer", distance: distanceInKilometers.toFixed(1) };
    }
  } else {
    const distanceInMiles = convertDistance(distanceInMeters, "mi");
    if (distanceInMiles < 0.1) {
      return {
        unit: "yard",
        distance: convertDistance(distanceInMeters, "yd").toFixed(0),
      };
    } else {
      return {
        unit: "mile",
        distance: distanceInMiles.toFixed(2),
      };
    }
  }
}

export { getDistance, convertDistance, getDistanceValues };
