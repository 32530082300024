import { defineMessages } from "react-intl";

export const messages = defineMessages({
  initializeIdentificationButton: {
    id: "SensitiveContent.initializeIdentificationButton",
    description: "button for initializing identification again",
    defaultMessage: "Log in again",
  },
  accessSensitiveData: {
    id: "SensitiveContent.accessSensitiveData",
    description: "To access sensitive data you must be logged in",
    defaultMessage:
      "To access your sensitive data, you are must have logged in with BankID or equivalent within the past 48 hours.",
  },
});
