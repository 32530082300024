import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Palette.OVERLAY_DARK_40,
    padding: 16,
  },
  contentContainer: {
    width: "100%",
    maxHeight: "90%",
    backgroundColor: Palette.WHITE,
    borderRadius: 8,
    paddingVertical: 24,
    shadowColor: Palette.BLACK,
    ...Shadows.xl,
  },
  modalTitleContainer: {
    flexDirection: "row",
    paddingHorizontal: 24,
  },
  titleTextContainer: {
    flexShrink: 1,
    flexGrow: 1,
    paddingLeft: 36,
  },
  closeIcon: {
    width: 36,
    height: 36,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Palette.WHITE,
    ...Shadows.xs, // change the btn to Eyrbutton with icon?
    borderRadius: 18,
  },
  scrollableContainer: { paddingHorizontal: 24 },
  section: {
    paddingVertical: 12,
    flexDirection: "row",
  },
  sectionTopBorder: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
  },
  sectionIconContainer: {
    marginRight: 16,
    backgroundColor: Palette.NUDE_20,
    width: 40,
    height: 40,
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
  },
  sectionContent: {
    flexShrink: 1,
  },
  addPhoneNumberSection: {
    paddingVertical: 12,
  },
  addPhoneNumberTextSection: {
    flexDirection: "row",
  },
  addPhoneNumberButtonContainer: {
    marginTop: 16,
  },
});

export const stylingProps = {
  closeIcon: {
    width: 24,
    height: 24,
    fill: Palette.BURGUNDY_100,
  },
  sectionIcon: {
    width: 19,
    height: 19,
  },
};
