import React, { useState, useCallback, forwardRef } from "react";
import PropTypes from "prop-types";
import { LayoutAnimation, Platform, TextInput, View } from "react-native";
import { get, compact } from "lodash/fp";
import { renderIf } from "@eyr-mobile/core/Lib";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";

import {
  styles,
  stylingProps,
  stylingHelpers,
  androidContentHeightBugOffset,
} from "./EyrTextInput.styles.js";

const logger = LoggerFactory.get("components/EyrTextInput");

export const EyrTextInput = forwardRef(function EyrTextInput(
  {
    variant = "primary",
    disabled,
    testOnly_focused,
    onBlur,
    onFocus,
    onContentSizeChange,
    numberOfLines,
    maxNumberOfLines,
    textRightOffset,
    textAlign,
    multiline,
    startAdornment,
    endAdornment,
    ...rest
  },
  ref
) {
  const [hasFocus, setHasFocus] = useState(false);

  const [internalNumberOfLines, setInternalNumberOfLines] =
    useState(numberOfLines);
  // [Multiline TextInput's onContentSizeChange doesn't take horizontal padding into a consideration (IOS)]
  // (https://github.com/facebook/react-native/issues/35234)
  const handleContentSizeChange = useCallback(
    (event) => {
      if (onContentSizeChange) {
        onContentSizeChange(event);
      }
      if (!maxNumberOfLines) {
        return;
      }
      const contentHeight = Platform.select({
        default: event.nativeEvent.contentSize.height,
        // https://github.com/facebook/react-native/issues/29702
        android:
          event.nativeEvent.contentSize.height - androidContentHeightBugOffset,
      });
      const nextNumberOfLines =
        stylingHelpers.getNumberOfLinesFromContentHeight(contentHeight);
      if (nextNumberOfLines < numberOfLines) {
        return;
      }
      if (nextNumberOfLines <= maxNumberOfLines) {
        LayoutAnimation.configureNext({
          ...LayoutAnimation.Presets.linear,
          duration: 50,
        });
        setInternalNumberOfLines(nextNumberOfLines);
      }
    },
    [maxNumberOfLines, numberOfLines, onContentSizeChange]
  );

  const onFocusInternal = useCallback(() => {
    setHasFocus(true);
    if (onFocus) {
      onFocus();
    }
  }, [onFocus]);
  const onBlurInternal = useCallback(() => {
    setHasFocus(false);
    if (onBlur) {
      onBlur();
    }
  }, [onBlur]);

  let styleDescriptor = variant;
  if (disabled) {
    styleDescriptor =
      styleDescriptor === "ghost" ? "ghost-disabled" : "disabled";
  } else {
    if (hasFocus || testOnly_focused) {
      styleDescriptor = `${styleDescriptor}-focused`;
    }
  }
  const inputStyle = get(styleDescriptor, styles);

  if (!inputStyle) {
    logger("can't find styles for:", styleDescriptor, LOGGER_LEVEL_ERROR);
  }
  const input = (
    <TextInput
      {...rest}
      {...stylingProps}
      editable={!disabled}
      multiline={multiline}
      numberOfLines={numberOfLines}
      style={compact([
        Platform.OS === "web" && textAlign && { textAlign },
        inputStyle,
        maxNumberOfLines &&
          stylingHelpers.getMaxNumberOfLinesStyle(maxNumberOfLines),
        internalNumberOfLines > 1 &&
          stylingHelpers.getMultilineStyle(internalNumberOfLines),
        textRightOffset && {
          paddingRight: textRightOffset,
        },
        startAdornment && styles.withStartAdornment,
        endAdornment && styles.withEndAdornment,
      ])}
      onBlur={onBlurInternal}
      onFocus={onFocusInternal}
      onContentSizeChange={handleContentSizeChange}
      textAlign={textAlign}
      ref={ref}
    />
  );

  return startAdornment || endAdornment ? (
    <View style={styles.container}>
      {input}
      {renderIf(startAdornment)(
        <View style={styles.startAdornment}>{startAdornment}</View>
      )}
      {renderIf(endAdornment)(
        <View style={styles.endAdornment}>{endAdornment}</View>
      )}
    </View>
  ) : (
    input
  );
});

EyrTextInput.propTypes = {
  variant: PropTypes.oneOf(["primary", "danger", "ghost", "UNSAFE_search"]),
  textAlign: PropTypes.oneOf(["left", "center", "right"]),
  numberOfLines: PropTypes.number,
  maxNumberOfLines: PropTypes.number,
  textRightOffset: PropTypes.number,
  testOnly_focused: PropTypes.bool,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onContentSizeChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
};

EyrTextInput.defaultProps = {
  variant: "primary",
};
