import React, { useCallback, useMemo } from "react";
import { WebView } from "react-native-webview";
import { Platform, SafeAreaView } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import { LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  applicationNameForUserAgent,
  disableScalingInjectedJavaScript,
  patchPostMessageInjectedJavaScript,
  safeHandlePostMessage,
} from "@eyr-mobile/core/WebView";
import {
  getAuthorizationBearerHeader,
  injectWebWebViewAuth,
  useAuth,
} from "@eyr-mobile/domain/Auth";
import { getAdditionalHeaders, getWebViewURL } from "@eyr-mobile/core/Net";
import { useIntl } from "@eyr-mobile/core/Intl";

import { styles } from "./DigitalToolFormScreen.styles";
import { messages } from "./DigitalToolFormScreen.messages";

const injectedJavaScript =
  disableScalingInjectedJavaScript + patchPostMessageInjectedJavaScript;

const logger = LoggerFactory.get("screens/DigitalToolFormScreen");

export const DigitalToolFormScreen = function DigitalToolFormScreen() {
  const { accessToken } = useAuth();
  const { goBack, popToTop, setOptions } = useNavigation();
  const { params } = useRoute();
  const { formatMessage } = useIntl();
  const uri = useMemo(() => {
    const url = params?.url;
    if (Platform.OS === "web") {
      const formURL = new URL(url);
      return getWebViewURL().origin + formURL.pathname + formURL.search;
    } else {
      return url;
    }
  }, [params]);
  const onMessage = useCallback(
    ({ nativeEvent }) => {
      safeHandlePostMessage(nativeEvent, logger, (data) => {
        const { status } = JSON.parse(data);
        switch (status) {
          case "ok":
            goBack();
            break;
          case "error":
            popToTop();
            break;
          default:
        }
      });
    },
    [goBack, popToTop]
  );

  const handleLoad = useCallback(
    ({ nativeEvent: { title } }) => {
      setOptions({ title: title || formatMessage(messages.navigationTitle) });
    },
    [formatMessage, setOptions]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <WebView
        source={Platform.select({
          default: {
            headers: {
              ...getAdditionalHeaders(),
              ...getAuthorizationBearerHeader(accessToken),
            },
            uri,
          },
          web: {
            uri: injectWebWebViewAuth(uri, accessToken),
          },
        })}
        decelerationRate="normal"
        automaticallyAdjustContentInsets={false}
        applicationNameForUserAgent={applicationNameForUserAgent}
        bounces={false}
        injectedJavaScript={injectedJavaScript}
        scalesPageToFit={false}
        startInLoadingState
        onMessage={onMessage}
        onLoadEnd={handleLoad}
        testID={"DigitalToolFormRenderer"}
        web_postMessageTarget={"window"}
      />
    </SafeAreaView>
  );
};

DigitalToolFormScreen.routeName = "DigitalToolFormScreen";
DigitalToolFormScreen.navigationOptions = {
  title: messages.navigationTitle,
};
