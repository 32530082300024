import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { createIntl, RawIntlProvider, createIntlCache } from "react-intl";
import { get, has } from "lodash/fp";
import moment from "moment";

import { LoggerFactory, LOGGER_LEVEL_ERROR } from "../Logger";

import {
  getLocale,
  IntlConfig,
  supportedLanguages,
  intlRef,
  getTimeZone,
} from "./Intl";

const logger = LoggerFactory.get("core/Intl");
const onError = (error) => logger("error", error, LOGGER_LEVEL_ERROR);
const deviceLanguage = getLocale()?.languageCode;
const cache = createIntlCache();
const timeZone = getTimeZone();

export function IntlProvider({ children, messages, preferableLanguage }) {
  const language = preferableLanguage || deviceLanguage;
  const locale = supportedLanguages.includes(language)
    ? language
    : IntlConfig.defaultLocale;

  const messagesForLocale = useMemo(
    () => get(locale, messages),
    [locale, messages]
  );

  const setMomentLocale = useCallback(
    (momentLocale) => {
      /**
       * @description WARNING!
       * moment.locale function sets the current locale from known ones,
       * but it uses fallback to node require function
       * which crashes the app in RN https://github.com/moment/moment/issues/3624#issuecomment-288713419
       * carefully set locale to the one of those defined in localeData.js
       */
      try {
        if (has(momentLocale, messages)) {
          moment.locale(momentLocale);
          return;
        }
        moment.locale(IntlConfig.defaultLocale);
      } catch (e) {
        logger("moment.locale:error", e, LOGGER_LEVEL_ERROR);
      }
    },
    [messages]
  );

  useEffect(() => {
    setMomentLocale(locale);
  }, [locale, setMomentLocale]);

  const intl = useMemo(
    () =>
      createIntl(
        {
          locale: locale,
          defaultLocale: IntlConfig.defaultLocale,
          messages: messagesForLocale,
          onError,
          timeZone,
        },
        cache
      ),
    [locale, messagesForLocale]
  );

  useEffect(() => {
    intlRef.current = intl;
  }, [intl]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.object.isRequired,
  preferableLanguage: PropTypes.oneOf(["en", "nb", "da", "sv", "uk"]),
};

export { RawIntlProvider };
