import { defineMessages } from "react-intl";
import { ProductType } from "@eyr-mobile/domain/Order";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.navigationTitle",
    description:
      "FlowStageSetMedicalCategory_DescribeSymptomsScreen screen navigation title",
    defaultMessage: "Symptoms",
  },
  navigationTitlePsychology: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.navigationTitlePsychology",
    description: "navigation title for psychology product",
    defaultMessage: "Main concern",
  },
  navigationTitlePatientGuide: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.navigationTitlePatientGuide",
    description: "navigation title for psychology product",
    defaultMessage: "Main concern",
  },
  finishStageButtonTitle: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.finishStageButtonTitle",
    description:
      "FlowStageSetMedicalCategory_DescribeSymptomsScreen finish stage button title",
    defaultMessage: "Next",
  },
  default_symptomsInputPlaceholderText: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.symptomsInputPlaceholderText",
    description:
      "FlowStageSetMedicalCategory_DescribeSymptomsScreen symptoms input placeholder text",
    defaultMessage: "Describe the symptoms",
  },
  [`${ProductType.GUIDE_CONSULTATION}_symptomsInputPlaceholderText`]: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.GUIDE_CONSULTATION_symptomsInputPlaceholderText",
    description:
      "FlowStageSetMedicalCategory_DescribeSymptomsScreen symptoms input placeholder text",
    defaultMessage:
      "Here you can write the name of your diagnosis and briefly explain what you need help with.",
  },
  [`${ProductType.PSYCHOLOGIST_CONSULTATION}_symptomsInputPlaceholderText`]: {
    id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.PSYCHOLOGIST_CONSULTATION_symptomsInputPlaceholderText",
    description: "input field placeholder text for use with psychology product",
    defaultMessage:
      "Please tell your psychologist a little about your situation and the issue at hand",
  },
  [`${ProductType.PHYSIOTHERAPIST_CONSULTATION}_symptomsInputPlaceholderText`]:
    {
      id: "FlowStageSetMedicalCategory_DescribeSymptomsScreen.PHYSIOTHERAPIST_CONSULTATION_symptomsInputPlaceholderText",
      description:
        "input field placeholder text for use with psychology product",
      defaultMessage:
        "Here you can tell the physiotherapist a little about your situation and what you want help with.",
    },
});
