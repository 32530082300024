import React from "react";
import PropTypes from "prop-types";
import { has } from "lodash/fp";
import { SvgUri } from "@eyr-mobile/core/Svg";

import { SVGs } from "../../res";

const HARDCODED_SVGS_MAP = {
  asthma: SVGs.Asthma,
  muscleSkeleton: SVGs.MuscleSkeleton,
  acidReflux: SVGs.Heartburn,
  allergy: SVGs.Allergy,
  digestion: SVGs.Indigestion,
  children: SVGs.ChildSickness,
  insomnia: SVGs.Insomnia,
  erectileDysfunction: SVGs.ErectileDysfunction,
  eyeInfection: SVGs.EyeInfection,
  prevention: SVGs.Contraception,
  psychology: SVGs.Psychological,
  headacheMigraine: SVGs.Headache,
  pregnancyChild: SVGs.PregnantBreastfeeding,
  sinusitis: SVGs.SinusInflammation,
  skinProblems: SVGs.SkinDiseases,
  medicalAdvice: SVGs.Advice,
  urinaryTractInfection: SVGs.UrinaryInfection,
  coldFever: SVGs.ColdFlue,
  headache: SVGs.Headache,
  vaccine: SVGs.Vaccine,
  vaccination_travel: SVGs.Travel,
  vaccination_flu: SVGs.ProductVaccineFlu,
  vaccination_tick: SVGs.ProductVaccineTick,
  vaccination_meningitis: SVGs.ProductVaccineMeningites,
  vaccination_hpv: SVGs.ProductVaccineHPV,
  vaccination_boostrix: SVGs.ProductVaccineBoostrix,
  vaccination_pneumococcal: SVGs.ProductVaccinePneumonia,
  fear: SVGs.Fear,
  lifeEvents: SVGs.LifeEvents,
  lowSelfEsteem: SVGs.LowSelfEsteem,
  patientGuide: SVGs.ProductPatientGuide,
  mentalSelfHelp: SVGs.ProductMentalSelfHelp,
  CreditCard: SVGs.CreditCard,
};

export function ResAwareSvg({ svg, ...rest }) {
  if (
    (svg.startsWith("http:") || svg.startsWith("https:")) &&
    svg.indexOf(".svg") !== -1
  ) {
    return <SvgUri uri={svg} {...rest} />;
  } else {
    const iconName = Object.keys(HARDCODED_SVGS_MAP).find(
      (key) => svg.indexOf(key) !== -1
    );
    const IconComponent = has(iconName, HARDCODED_SVGS_MAP)
      ? HARDCODED_SVGS_MAP[iconName]
      : SVGs.PlaceholderIconMono;
    return <IconComponent {...rest} />;
  }
}

ResAwareSvg.propTypes = { svg: PropTypes.string.isRequired };
