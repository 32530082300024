/* eslint-disable no-bitwise */
import { mapValues, reduce } from "lodash/fp";
import { Base64, sha512 } from "@eyr-mobile/core/Crypto";

import { AuthState } from "./Auth.constants";

// const JWT_SECTION_HEADER = 0;
const JWT_SECTION_PAYLOAD = 1;
// const JWT_SECTION_SIGNATURE = 2;

const getJWTSectionDecoder = (section) => (token) =>
  JSON.parse(Base64.atob(token.split(".")[section]));

export const encodeJWTPayload = (payload) =>
  Base64.encodeURI(JSON.stringify(payload));

export function hashPassword(password, salt) {
  return sha512()
    .update(password + salt)
    .digest("hex");
}

export const decodeJWTPayload = getJWTSectionDecoder(JWT_SECTION_PAYLOAD);

const AUTH_HEADER_AUTHORIZATION = "Authorization";

export const getAuthorizationBearerHeader = (credentials) => ({
  [AUTH_HEADER_AUTHORIZATION]: `Bearer ${credentials}`,
});

export function injectWebWebViewAuth(uri, accessToken) {
  const url = new URL(uri);
  const searchParams = new URLSearchParams(url.searchParams);
  searchParams.append("token", accessToken);
  url.search = searchParams.toString();
  return url.toString();
}

export const createMask = reduce((acc, curr) => acc | curr, 0);

export const oneOf = (expectedPermissions) => (actualPermissions) =>
  Boolean(actualPermissions & createMask(expectedPermissions));

export const ensure = (expectedPermissions) => (actualPermissions) =>
  (actualPermissions & createMask(expectedPermissions)) ===
  createMask(expectedPermissions);

export const toObject = (permissions) =>
  mapValues((p) => ensure([p])(permissions));

export function checkIfNotExpired(accessToken) {
  return accessToken && Date.now() / 1000 <= decodeJWTPayload(accessToken).exp;
}

export const getAuthState = ({ cert, accessToken, requiresLegalChecks }) => {
  switch (true) {
    case Boolean(cert && accessToken && !requiresLegalChecks):
      return AuthState.AUTHENTICATED;
    case Boolean(cert):
      return AuthState.LOCKED;
    default:
      return AuthState.UNAUTHENTICATED;
  }
};
