import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.title",
    description: "screen title",
    defaultMessage:
      "The insurance you are attempting to use is a corporate insurance. Do you still work for the company providing this insurance to you?",
  },
  confirmActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.confirmActionTitle",
    description: "action title for confirming working for the company",
    defaultMessage: "Yes",
  },
  declineActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.declineActionTitle",
    description: "action title for declining working for the company",
    defaultMessage: "No",
  },
  alertInvalidWorkTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.alertInvalidWorkTitle",
    description: "alert shown for negative answer title",
    defaultMessage: "Insurance not valid",
  },
  alertInvalidWorkDescription: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.alertInvalidWorkDescription",
    description: "alert shown for negative answer description",
    defaultMessage:
      "This insurance is only valid for employees of the company you used to work for, and you can no longer use it, unfortunately.",
  },
  changePaymentMethodActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.changePaymentMethodActionTitle",
    description: "button text for selecting different payment method",
    defaultMessage: "Select another payment method",
  },
  cancelOrderActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionWorkScreen.cancelOrderActionTitle",
    description: "button text for cancelling order",
    defaultMessage: "Cancel order",
  },
});
