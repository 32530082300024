import React from "react";
import PropTypes from "prop-types";
import { head, size, find, filter, pick } from "lodash/fp";
import { Pressable } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib";
import { LoggerFactory, LOGGER_LEVEL_ERROR } from "@eyr-mobile/core/Logger";
import {
  CoverageType,
  GetPaymentMethodsForOrder,
  productShape,
} from "@eyr-mobile/domain/Order";
import { useQuery } from "@eyr-mobile/core/DataProvider";

import { PaymentMethodListItem } from "../PaymentMethodListItem";
import { SVGs } from "../../res";

const logger = LoggerFactory.get(
  "components/SetPaymentMethodsStageSummaryItem"
);

const paymentMethodDisplayProps = [
  "name",
  "description",
  "type",
  "image",
  "disabled",
  "disabledReason",
  "primaryValue",
  "secondaryValue",
  "discountValue",
  "priceInfoDisplayMode",
];
export function SetPaymentMethodsStageSummaryItem({
  product,
  discountIds,
  cardId,
  providerSlug,
  orderId,
  showInteractivityIcon,
  pressable,
  onPress,
}) {
  const { data = {}, loading } = useQuery(GetPaymentMethodsForOrder, {
    variables: { orderId },
  });
  if (loading) {
    return null;
  }
  const {
    accountPaymentMethodsForOrder: accountPaymentMethods,
    paymentProvidersForOrder: paymentProviders,
  } = data;
  const card = find({ id: cardId }, accountPaymentMethods);
  const paymentProvider = find({ slug: providerSlug }, paymentProviders);
  const discounts = discountIds
    ? filter((d) => discountIds.includes(d.id), accountPaymentMethods)
    : [];
  const monetaryPaymentMethod = card || paymentProvider;
  const nonMonetaryPaymentMethod = head(discounts);

  if (card && paymentProvider) {
    logger(
      "There should be only one monetary payment method, got:",
      { card, paymentProvider },
      LOGGER_LEVEL_ERROR
    );
  }
  if (size(discounts) > 1) {
    logger(
      "There should be only one non-monetary payment method, got:",
      discounts,
      LOGGER_LEVEL_ERROR
    );
  }

  let primaryPaymentMethod = null;
  let secondaryPaymentMethod = null;

  switch (true) {
    case Boolean(!monetaryPaymentMethod && !nonMonetaryPaymentMethod):
      return null;

    case Boolean(monetaryPaymentMethod && !nonMonetaryPaymentMethod):
      primaryPaymentMethod = {
        ...monetaryPaymentMethod,
        primaryValue: product.price,
      };
      break;

    case Boolean(!monetaryPaymentMethod && nonMonetaryPaymentMethod):
      primaryPaymentMethod = {
        ...nonMonetaryPaymentMethod,
        primaryValue: {
          ...nonMonetaryPaymentMethod.orderCoverage.discount,
          amount: 0,
        },
      };
      break;

    case Boolean(monetaryPaymentMethod && nonMonetaryPaymentMethod):
      primaryPaymentMethod = {
        ...nonMonetaryPaymentMethod,
        ...(nonMonetaryPaymentMethod.orderCoverage.type ===
          CoverageType.PARTIAL && { priceInfoDisplayMode: "none" }),
        ...(nonMonetaryPaymentMethod.orderCoverage.type ===
          CoverageType.DISCOUNT && {
          priceInfoDisplayMode: "coverage-value",
          primaryValue: nonMonetaryPaymentMethod.orderCoverage.discount,
          discountValue: Math.min(
            nonMonetaryPaymentMethod.orderCoverage.discount.amount /
              product.price.amount,
            1
          ),
        }),
      };

      secondaryPaymentMethod = {
        ...monetaryPaymentMethod,
        primaryValue: {
          ...product.price,
          amount:
            product.price.amount -
            primaryPaymentMethod.orderCoverage.discount.amount,
        },
      };
      break;
  }
  return (
    <Pressable onPress={onPress} disabled={!pressable}>
      <PaymentMethodListItem
        pressable={false}
        InteractivityIconComponent={SVGs.PencilMono}
        showInteractivityIcon={showInteractivityIcon}
        {...pick(paymentMethodDisplayProps, primaryPaymentMethod)}
      />
      {renderIf(secondaryPaymentMethod)(() => (
        <>
          <PaymentMethodListItem
            pressable={false}
            showInteractivityIcon={false}
            {...pick(paymentMethodDisplayProps, secondaryPaymentMethod)}
          />
        </>
      ))}
    </Pressable>
  );
}

SetPaymentMethodsStageSummaryItem.propTypes = {
  product: productShape,
  orderId: PropTypes.number.isRequired,
  discountIds: PropTypes.arrayOf(PropTypes.number),
  cardId: PropTypes.number,
  pressable: PropTypes.bool,
  showInteractivityIcon: PropTypes.bool,
  providerSlug: PropTypes.string,
};
