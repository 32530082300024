import React from "react";
import PropTypes from "prop-types";
import { Col } from "react-native-easy-grid";
import { sum, get, map, flow } from "lodash/fp";
import { View, Pressable } from "react-native";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";

import { SVGs } from "../../res";
import { Palette } from "../../shared-styles/palette";
import { baseLineHeight } from "../../shared-styles/font";

import { styles, stylingProps, layouts, gridSize } from "./ListItem.styles";

const logger = LoggerFactory.get("components/ListItem");
const sumSizes = flow([map(get("size")), sum]);

/**
 * Meant to be used as a grid for all list items
 * @param props
 * @returns {*}
 * @constructor
 */
export function ListItem(props) {
  const { layout, children, multiline, pressable, style, ...rest } = props;

  const columns = get(layout, layouts);

  if (gridSize % sumSizes(columns) !== 0) {
    logger(`grid violation for layout ${layout}:`, columns, LOGGER_LEVEL_ERROR);
  }

  const childrenCount = React.Children.count(children);

  if (columns.length !== childrenCount) {
    logger(
      `expected children.length of ${columns.length}, but got:`,
      childrenCount,
      LOGGER_LEVEL_ERROR
    );
  }

  const Wrapper = pressable ? Pressable : View;

  return (
    <Wrapper
      {...(pressable && {
        android_ripple: {
          color: Palette.NUDE_100,
          borderless: false,
        },
      })}
      {...rest}
    >
      <View style={style ? [styles.container, style] : styles.container}>
        {React.Children.map(children, (child, i) => {
          const { size, style: columnStyle } = columns[i];
          return (
            <Col
              size={size}
              style={
                multiline && columnStyle
                  ? [columnStyle, styles.multilineMargin]
                  : columnStyle
              }
            >
              {child ? React.cloneElement(child) : null}
            </Col>
          );
        })}
      </View>
    </Wrapper>
  );
}

ListItem.propTypes = {
  layout: PropTypes.oneOf([
    "1",
    "2-13",
    "3-12",
    "14-1",
    "2-12-1",
    "2-9-3-1",
    "7-8",
  ]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  multiline: PropTypes.bool,
  pressable: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ListItem.defaultProps = {
  layout: "1",
  pressable: true,
};

ListItem.lineHeight = baseLineHeight;

ListItem.Arrow = function ListItemArrow(props) {
  return <SVGs.ChevronRightMono {...stylingProps.chevronIcon} {...props} />;
};

ListItem.Edit = function ListItemEdit(props) {
  return (
    <SVGs.PencilMono
      width={(ListItem.lineHeight * 2) / 3}
      height={(ListItem.lineHeight * 2) / 3}
      fill={Palette.DARK_BLUE_60}
      {...props}
    />
  );
};

ListItem.Delete = function ListItemDelete({ onPress, ...props }) {
  return (
    <Pressable style={styles.delete} onPress={onPress}>
      <SVGs.CloseMono
        width={ListItem.lineHeight * 0.8}
        height={ListItem.lineHeight * 0.8}
        {...stylingProps.delete}
        {...props}
      />
    </Pressable>
  );
};

ListItem.Delete.propTypes = {
  onPress: PropTypes.func,
};
