import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  productTypeSwitcherContainer: { marginBottom: 24 },
  productTypeSwitcher: {
    minWidth: "100%",
    flexDirection: "column",
  },
});

export const stylingHelpers = {
  getProductTypeSwitcherWidth: (amount) => ({
    width: amount * 150,
  }),
};
