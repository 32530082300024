import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { FlatList } from "react-native";
import { differenceWith, noop, pick, size } from "lodash/fp";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  flowStageToOrderStage,
  orderStageToFlowStage,
  productShape,
} from "@eyr-mobile/domain/Order";

import { ProductOrderSummaryItem } from "../ProductOrderSummaryItem";

import { getSummaryItemComponentForStage } from "./OrderSummary.helpers";

const logger = LoggerFactory.get("components/OrderSummary");

export function OrderSummary(props) {
  const {
    onOrderStageSelected = noop,
    excludedStages = [],
    editableStages = [],
    order: { product, stages = [] },
    ...rest
  } = props;

  if (size(editableStages) > 0 && onOrderStageSelected === noop) {
    logger(
      "OrderSummary has editable stages, expected onOrderStageSelected to be set, got:",
      undefined,
      LOGGER_LEVEL_ERROR
    );
  }

  const renderItem = useCallback(
    ({ item: stage }) => {
      const SummaryItem = getSummaryItemComponentForStage(stage);
      const editable = editableStages.includes(
        orderStageToFlowStage(stage.__typename)
      );
      return (
        <SummaryItem
          {...stage}
          product={product}
          showInteractivityIcon={editable}
          pressable={editable}
          onPress={() => onOrderStageSelected(stage)}
        />
      );
    },
    [product, editableStages, onOrderStageSelected]
  );
  const keyExtractor = useCallback((item) => item.stageId.toString(), []);
  const data = useMemo(
    () =>
      differenceWith(
        (stage, excludedStageTypename) =>
          stage.__typename === flowStageToOrderStage(excludedStageTypename),
        stages,
        excludedStages
      ),
    [excludedStages, stages]
  );
  return (
    <FlatList
      ListHeaderComponent={
        <ProductOrderSummaryItem
          {...pick(["image", "name", "description"], product)}
        />
      }
      data={data}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      {...rest}
    />
  );
}

OrderSummary.propTypes = {
  onOrderStageSelected: PropTypes.func,
  order: PropTypes.shape({
    product: productShape.isRequired,
    stages: PropTypes.arrayOf(
      PropTypes.shape({
        __typename: PropTypes.string.isRequired,
        stageId: PropTypes.number.isRequired,
        orderId: PropTypes.number.isRequired,
      })
    ),
  }).isRequired,
  editableStages: PropTypes.arrayOf(PropTypes.string),
  excludedStages: PropTypes.arrayOf(PropTypes.string),
};
