import { StyleSheet, Platform } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  pickerContainer: {
    marginBottom: 16,
  },
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  containerXS: responsiveStyles.containerXS,
  containerS: responsiveStyles.containerS,
  containerM: responsiveStyles.containerM,
  ...Platform.select({
    web: {
      scheduleContainer: {
        scrollbarGutter: "stable both-edges",
      },
    },
  }),
  flatListContentContainer: {
    paddingTop: 8,
  },
  flatListEmptyContentContainer: {
    paddingTop: 8,
    flex: 1,
  },
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
});

export const stylingHelpers = {
  getContentContainerStyle(hasScheduleItems) {
    return hasScheduleItems
      ? styles.flatListContentContainer
      : styles.flatListEmptyContentContainer;
  },
};
