import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancellationAlertTitle: {
    id: "CancelOrderHeaderRight.cancellationAlertTitle",
    description: "Cancel order confirmation title",
    defaultMessage: "Are you sure you want to cancel?",
  },
  cancellationAlertButtonConfirmAction: {
    id: "CancelOrderHeaderRight.cancellationAlertButtonConfirmAction",
    description: "Confirm order cancellation message",
    defaultMessage: "Yes",
  },
  cancellationAlertButtonCancelAction: {
    id: "CancelOrderHeaderRight.cancellationAlertButtonCancelAction",
    description: "Cancel order cancellation message",
    defaultMessage: "No",
  },
});
