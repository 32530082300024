import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "PharmaciesScreen.navigationTitle",
    description: "PharmaciesScreen navigation title",
    defaultMessage: "Apotek1 closest to you",
  },
  searchInputPlaceholder: {
    id: "PharmaciesScreen.searchInputPlaceholder",
    description: "PharmaciesScreen search input placeholder",
    defaultMessage: "Search for your location",
  },
  pharmacyDistance: {
    id: "PharmaciesScreen.pharmacyDistance",
    description: "pharmacy item distance",
    defaultMessage: "{distance} away",
  },
  pharmacyOpeningHours: {
    id: "PharmaciesScreen.pharmacyOpeningHours",
    description: "weekday and weekend opening hours",
    defaultMessage: "Open {openingHoursWeekDay} ({openingHoursWeekEnd})",
  },
  noSearchResults: {
    id: "PharmaciesScreen.noSearchResults",
    description: "no search results text",
    defaultMessage: "No pharmacies found",
  },
  openInMapLink: {
    id: "PharmaciesScreen.openInMapLink",
    description: "link to open pharmacy in map",
    defaultMessage: "Show in map",
  },
  resetSearch: {
    id: "PharmaciesScreen.resetSearch",
    description: "reset search",
    defaultMessage: "Show all",
  },
});
