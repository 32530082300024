import { StyleSheet } from "react-native";
import { compact } from "lodash/fp";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  innerContainer: {
    backgroundColor: Palette.WHITE,
    borderRadius: 8,
    ...Shadows.xs,
  },
  innerContainerHeadless: {
    overflow: "hidden",
  },
  headerContainer: {
    zIndex: 2,
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 16,
    flexShrink: 1,
    flexGrow: 1,
  },
  headlessHeaderContainer: {
    padding: 0,
  },
  openHeaderContainer: {
    borderBottomColor: Palette.GRAY_20,
    borderBottomWidth: 1,
  },
  contentContainer: {
    overflow: "hidden",
  },
  contentInnerContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
  topPositionIconRight: {
    alignSelf: "flex-start",
  },
  centerPositionIconRight: {
    alignSelf: "center",
  },
});

export const stylingProps = {
  headerIconRight: {
    height: 22,
    width: 22,
    fill: Palette.BURGUNDY_100,
  },
};

export const stylingHelpers = {
  getInnerContainerStyle: (isHeadless) => {
    return isHeadless ? styles.innerContainerHeadless : styles.innerContainer;
  },
  getHeaderContainerStyle: ({ isHeadless, open }) => {
    return compact([
      styles.headerContainer,
      isHeadless && styles.headlessHeaderContainer,
      open && !isHeadless && styles.openHeaderContainer,
    ]);
  },
};

export const animationDuration = 300;
