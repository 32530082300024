import { StyleSheet } from "react-native";

import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  serviceContainer: {
    marginBottom: 16,
    borderRadius: 12,
    ...Shadows.xs,
  },
});
