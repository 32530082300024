import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  contentContainerXS: {
    flexGrow: 1,
    padding: 24,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
    paddingHorizontal: 0,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
    paddingHorizontal: 0,
  },
  sectionContainer: {
    marginVertical: 24,
  },
  accordionContainer: {
    marginBottom: 16,
  },
  bottomBar: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  buttonContainerXS: {
    ...responsiveStyles.contentContainerXS,
    paddingHorizontal: 24,
  },
  buttonContainerS: {
    ...responsiveStyles.contentContainerS,
  },
  buttonContainerM: {
    ...responsiveStyles.contentContainerM,
  },
  accordionContentContainer: {
    padding: 16,
  },
});
