import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";

import { Paragraph } from "../Paragraph";

import { styles } from "./Tag.styles";

export function Tag({ children }) {
  return (
    <View style={styles.container}>
      <Paragraph spacing="none" size="s">
        {children}
      </Paragraph>
    </View>
  );
}

Tag.propTypes = {
  children: PropTypes.string,
};
