import React, { useCallback } from "react";
import { noop } from "lodash/fp";
import { useIntl } from "react-intl";
import { useAlert } from "@eyr-mobile/core/Alert";
import {
  useCancelOrder,
  useOrderFlowStageParams,
} from "@eyr-mobile/domain/Order";

import { HeaderButton } from "../HeaderButton";

import { messages } from "./CancelOrderHeaderRight.messages";

export function CancelOrderHeaderRight(props) {
  const { formatMessage } = useIntl();
  const alert = useAlert();
  const [cancelOrder] = useCancelOrder();
  const onPress = useCallback(
    () =>
      alert({
        heading: formatMessage(messages.cancellationAlertTitle),
        buttons: [
          {
            title: formatMessage(messages.cancellationAlertButtonConfirmAction),
            onPress: cancelOrder,
          },
          {
            title: formatMessage(messages.cancellationAlertButtonCancelAction),
            variant: "secondary",
            onPress: noop,
          },
        ],
        buttonsLayout: "column",
      }),
    [alert, formatMessage, cancelOrder]
  );
  const { stageParams } = useOrderFlowStageParams();

  return stageParams ? (
    <HeaderButton
      {...props}
      iconName="CloseMono"
      accessibilityLabel={"Close"}
      onPress={onPress}
    />
  ) : null;
}
