import React from "react";
import PropTypes from "prop-types";
import { View, Pressable } from "react-native";
import { noop, compact } from "lodash/fp";
import { renderIf } from "@eyr-mobile/core/Lib";

import { PlayerButtons } from "../PlayerButtons";
import { PlayerProgress } from "../PlayerProgress";
import { SVGs } from "../../res";

import { styles, stylingProps } from "./PlayerControls.styles";

export function PlayerControls({
  isPlaying = false,
  isLoading = false,
  isFullscreen = false,
  positionMillis,
  durationMillis,
  onTogglePlayPause = noop,
  onBackwards = noop,
  onForwards = noop,
  onSeek = noop,
  onSeekStart = noop,
  variant = "audio",
  showFullScreenToggle = false,
  onFullScreenToggle = noop,
}) {
  return (
    <View style={compact([styles.container, styles[`${variant}Container`]])}>
      {renderIf(variant === "video")(
        <View style={styles.topSpacer}>
          {renderIf(showFullScreenToggle && !isFullscreen)(
            <Pressable onPress={onFullScreenToggle}>
              <SVGs.ExpandMono {...stylingProps.fullScreenToggleIcon} />
            </Pressable>
          )}
        </View>
      )}
      <View style={styles.buttonsContainer}>
        <PlayerButtons
          onTogglePlayPause={onTogglePlayPause}
          isPlaying={isPlaying}
          isLoading={isLoading}
          onBackwards={onBackwards}
          onForwards={onForwards}
          variant={variant}
        />
      </View>
      <View style={styles.progressContainer}>
        {durationMillis ? (
          <PlayerProgress
            onSlidingComplete={onSeek}
            positionMillis={positionMillis}
            durationMillis={durationMillis}
            disabled={isLoading}
            variant={variant}
            onSeekStart={onSeekStart}
          />
        ) : null}
      </View>
    </View>
  );
}

PlayerControls.propTypes = {
  isPlaying: PropTypes.bool,
  isLoading: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  positionMillis: PropTypes.number.isRequired,
  durationMillis: PropTypes.number.isRequired,
  onTogglePlayPause: PropTypes.func,
  onBackwards: PropTypes.func,
  onForwards: PropTypes.func,
  onSeek: PropTypes.func,
  variant: PropTypes.oneOf(["audio", "video"]),
  onSeekStart: PropTypes.func,
  showFullScreenToggle: PropTypes.bool,
  onFullScreenToggle: PropTypes.func,
};
