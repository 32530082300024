import React, { useEffect, useCallback, useState } from "react";
import { FlatList, View, KeyboardAvoidingView, Pressable } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import PropTypes from "prop-types";
import {
  withHandlers,
  useQuery,
  useMutation,
  useSubscription,
} from "@eyr-mobile/core/DataProvider";
import {
  GetConversation,
  CreateMessage,
  ReadConversation,
  InboxConversationUpdatedSubscription,
} from "@eyr-mobile/domain/Conversation";
import { useIntl } from "@eyr-mobile/core/Intl";
import { AuthPermission, useAuth } from "@eyr-mobile/domain/Auth";
import { useNavigation, useRoute } from "@react-navigation/native";
import { trim, get } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useHeaderHeight } from "@react-navigation/elements";

import {
  ConversationMessage,
  EyrTextInput,
  SensitiveContent,
  Paragraph,
} from "../../components";
import { SVGs } from "../../res";

import {
  styles,
  stylingProps,
  stylingHelpers,
} from "./ConversationScreen.styles";
import { messages } from "./ConversationScreen.messages";

const initialMessageText = "";
const TEXT_INPUT_MAX_NUMBER_OF_LINES = 6;

export const ConversationScreen = () => {
  const { formatMessage } = useIntl();
  const [messageText, setMessageText] = useState(initialMessageText);
  const { setOptions, goBack } = useNavigation();
  const { id: conversationId } = useRoute().params;
  const { ensureAll, initializeIdentification } = useAuth();
  const { screenSizeSelect } = useDevice();
  const insets = useSafeAreaInsets();
  const headerHeight = useHeaderHeight();
  const { handlers, data, error } = withHandlers(
    useQuery(GetConversation, {
      variables: {
        id: conversationId,
      },
      fetchPolicy: "cache-and-network",
      nextFetchPolicy: "cache-first",
    })
  );
  const unseenCount = data?.inboxConversation?.unseenCount;
  const resetMessageText = useCallback(
    () => setMessageText(initialMessageText),
    [setMessageText]
  );

  const handleInitializeIdentification = useCallback(() => {
    initializeIdentification({ returnRoute: "ConversationScreen" });
  }, [initializeIdentification]);

  const hasAuthPermission = ensureAll([AuthPermission.CONVERSATIONS]);
  const [createMessageMutation] = useMutation(CreateMessage, {
    onCompleted: resetMessageText,
  });

  const [readConversationMutation] = useMutation(ReadConversation);

  useEffect(() => {
    if (error) {
      goBack();
    }
  }, [error, goBack]);
  useEffect(() => {
    if (!unseenCount) {
      return;
    }
    readConversationMutation({
      variables: { input: { conversationId } },
    });
  }, [unseenCount, readConversationMutation, conversationId]);

  const conversationTitle = get("inboxConversation.title", data);
  useEffect(() => {
    if (!conversationTitle) {
      return;
    }
    setOptions({ title: conversationTitle });
  }, [conversationTitle, setOptions]);

  const handleSendMessage = useCallback(() => {
    const text = trim(messageText);
    if (text.length === 0) {
      return;
    }
    createMessageMutation({
      variables: {
        input: {
          conversationId,
          text,
        },
      },
    });
  }, [conversationId, createMessageMutation, messageText]);

  const handleRenderItem = useCallback(
    ({ item }) => {
      const ownMessengerId = data?.account.messenger.id;
      return (
        <ConversationMessage
          insertedAt={item.insertedAt}
          text={item.text}
          messengerName={item.sender.name}
          avatar={item.sender.image}
          variant={ownMessengerId !== item.sender.id ? "incoming" : "outgoing"}
        />
      );
    },
    [data?.account]
  );

  useSubscription(InboxConversationUpdatedSubscription, {
    variables: {
      id: conversationId,
    },
  });
  const handleExtractKey = useCallback((item) => item.id.toString(), []);
  return (
    <KeyboardAvoidingView
      style={styles.container}
      {...stylingProps.keyboardAvoidingView}
      keyboardVerticalOffset={stylingHelpers.keyboardVerticalOffset(
        insets,
        headerHeight
      )}
    >
      <SafeAreaView
        style={styles.safeAreaContainer}
        edges={["left", "right", "bottom"]}
      >
        {hasAuthPermission ? (
          (handlers && !data) || (
            <View style={styles.contentContainer}>
              <FlatList
                inverted
                contentContainerStyle={screenSizeSelect({
                  xs: [
                    styles.messagesListContentContainer,
                    styles.messagesListContentContainerXS,
                  ],
                  s: [
                    styles.messagesListContentContainer,
                    styles.messagesListContentContainerS,
                  ],
                  m: [
                    styles.messagesListContentContainer,
                    styles.messagesListContentContainerM,
                  ],
                })}
                data={data.inboxConversation.messages}
                keyExtractor={handleExtractKey}
                renderItem={handleRenderItem}
              />
              {data.inboxConversation.disabled ? (
                <View style={styles.alertContainer}>
                  <View
                    style={screenSizeSelect({
                      xs: styles.contentContainerXS,
                      s: styles.contentContainerS,
                      m: styles.contentContainerM,
                    })}
                  >
                    <Paragraph size="l">
                      {formatMessage(messages.conversationDisabledMessage, {
                        serviceProviderTitle: formatMessage(
                          messages.serviceProviderTitleFor_HEALTHCARE_PROVIDER_infinitive,
                          {
                            amount: 1,
                          }
                        ),
                      })}
                    </Paragraph>
                  </View>
                </View>
              ) : (
                <View style={styles.textInputBackgroundContainer}>
                  <View
                    style={screenSizeSelect({
                      xs: styles.contentContainerXS,
                      s: styles.contentContainerS,
                      m: styles.contentContainerM,
                    })}
                  >
                    <EyrTextInput
                      multiline
                      maxNumberOfLines={TEXT_INPUT_MAX_NUMBER_OF_LINES}
                      placeholder={formatMessage(
                        messages.messageInputPlaceholder
                      )}
                      onChangeText={setMessageText}
                      value={messageText}
                      {...stylingProps.textInput}
                    />
                    {Boolean(messageText) && (
                      <Pressable
                        onPress={handleSendMessage}
                        style={styles.submitButtonContainer}
                      >
                        <SVGs.MDArrowRoundUpMono
                          {...stylingProps.submitButtonIcon}
                        />
                      </Pressable>
                    )}
                  </View>
                </View>
              )}
            </View>
          )
        ) : (
          <SensitiveContent
            onInitializeIdentification={handleInitializeIdentification}
          />
        )}
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
};

ConversationScreen.propTypes = {
  route: PropTypes.object,
};

ConversationScreen.routeName = "ConversationScreen";
ConversationScreen.navigationOptions = {
  title: "",
};
