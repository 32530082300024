import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "@eyr-mobile/core/Intl";
import { View } from "react-native";
import { noop } from "lodash/fp";

import { EyrButton } from "../EyrButton";
import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";
import { Subtitle } from "../Subtitle";

import { messages } from "./IssueBox.messages";
import { styles } from "./IssueBox.styles";

const Issue = ({ issueText, buttonTitle, onPress, last = false }) => {
  return (
    <View style={last ? styles.lastIssue : styles.issue}>
      <View style={styles.issueText}>
        <Paragraph size="l">{issueText}</Paragraph>
      </View>
      <EyrButton
        variant="friendly"
        IconComponent={SVGs.AddMono}
        title={buttonTitle}
        onPress={onPress}
      />
    </View>
  );
};

Issue.propTypes = {
  last: PropTypes.bool,
  issueText: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  onPress: PropTypes.func.isRequired,
};

export function IssueBox({
  showNoContactIssue = false,
  showNoInsuranceIssue = false,
  onAddContactPress = noop,
  onAddInsurancePress = noop,
}) {
  const { formatMessage } = useIntl();

  // TODO: <mdrakus> add a proper "last issue detection"
  return (
    (showNoContactIssue || showNoInsuranceIssue) && (
      <View style={styles.container}>
        <Subtitle size="l" spacing="l">
          {formatMessage(messages.headerText)}
        </Subtitle>

        {showNoContactIssue && (
          <Issue
            issueText={formatMessage(messages.noContactInfo)}
            buttonTitle={formatMessage(messages.noContactBtnTitle)}
            onPress={onAddContactPress}
          />
        )}

        {showNoInsuranceIssue && (
          <Issue
            last
            issueText={formatMessage(messages.noInsuranceInfo)}
            buttonTitle={formatMessage(messages.noInsuranceBtnTitle)}
            onPress={onAddInsurancePress}
          />
        )}
      </View>
    )
  );
}

IssueBox.propTypes = {
  showNoContactIssue: PropTypes.bool,
  showNoInsuranceIssue: PropTypes.bool,
  onAddContactPress: PropTypes.func,
  onAddInsurancePress: PropTypes.func,
};
