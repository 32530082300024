import React from "react";
import PropTypes from "prop-types";

import { TypographyRaw } from "../TypographyRaw";

const subtitleFontSizesMap = { s: "xs", m: "s", l: "m" };
export function Subtitle({ size = "m", ...rest }) {
  return (
    <TypographyRaw
      size={subtitleFontSizesMap[size]}
      spacing={"none"}
      color={"primary"}
      alignment={"start"}
      decoration={"none"}
      // allow to override allowed defaults, children and all other RNText props will go here
      {...rest}
      // disallow overriding fontFamily and fontWeight (what makes this component unique)
      fontFamily={"FONT_FAMILY_EUCLID_CIRCULAR_B"}
      fontWeight={"600"}
    />
  );
}

Subtitle.propTypes = {
  size: PropTypes.oneOf(["s", "m", "l"]),
};
