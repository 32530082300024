import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "IdentificationCountryScreen.navigationTitle",
    description: "IdentificationCountryScreen navigation title",
    defaultMessage: "Your country",
  },
  countriesListTitle: {
    id: "IdentificationCountryScreen.countriesListTitle",
    description: "IdentificationCountryScreen countries list title",
    defaultMessage: "Choose your country",
  },
});
