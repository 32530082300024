import {
  filter,
  find,
  first,
  flow,
  get,
  getOr,
  includes,
  last,
  replace,
  size,
  some,
} from "lodash/fp";
import { capitalize } from "lodash";

import interfacesAndUnions from "../interfacesAndUnions.json";

const knownFlowStages = interfacesAndUnions.possibleTypes.FlowStage;

export function isMonetaryPaymentMethod(paymentMethod) {
  return (
    (get("__typename", paymentMethod) === "AccountPaymentMethod" &&
      paymentMethod.type === "CARD") ||
    get("__typename", paymentMethod) === "PaymentProvider"
  );
}

export function isNonMonetaryPaymentMethod(paymentMethod) {
  return (
    get("__typename", paymentMethod) === "AccountPaymentMethod" &&
    includes(get("type", paymentMethod), ["INSURANCE", "DISCOUNT"])
  );
}
export const getOrderProgress = (order) =>
  size(order.stages) / size(order.flowStages);

export const getFlowStageById = (order, id) =>
  find(
    {
      id,
    },
    order.flowStages
  );

export function isStageCompleted(stageId, order) {
  return Boolean(find({ stageId }, order.stages));
}

export function getPassPropsFromOrder(order) {
  if (!order) {
    return {};
  }
  const { id: orderId, product, flowStages, currentStageId } = order;
  const {
    id: stageId,
    __typename: stageType,
    ...params
  } = getFlowStageById(order, currentStageId || get("id", last(flowStages))) ||
  {};
  const completed = isStageCompleted(stageId, order);
  const progressPerStage = 1 / flowStages.length;
  const progress = getOrderProgress(order);
  return {
    orderId,
    product,
    progress: progress, //!completed ? progress : progress - progressPerStage,
    stageId,
    stageType,
    params,
    completed,
    progressPerStage,
    isFirstInTheFlow: first(flowStages).id === currentStageId,
  };
}
export const doesCover = (price) => (paymentMethod) => {
  const { currency: priceCurrency, amount: priceAmount } = price;
  if (isMonetaryPaymentMethod(paymentMethod)) {
    return true;
  }

  const {
    currency: paymentMethodCoverageCurrency,
    amount: paymentMethodCoverageAmount,
  } = getOr({}, "orderCoverage.discount", paymentMethod);

  if (
    priceCurrency !== paymentMethodCoverageCurrency ||
    paymentMethodCoverageAmount < priceAmount
  ) {
    return false;
  }

  return (
    priceCurrency === paymentMethodCoverageCurrency &&
    paymentMethodCoverageAmount >= priceAmount
  );
};

export const getApplicablePaymentMethodByIds = (
  accountPaymentMethods,
  newlyAddedPaymentMethodsIdList
) => {
  if (!accountPaymentMethods || size(newlyAddedPaymentMethodsIdList) !== 1) {
    return null;
  }

  return find(
    { id: first(newlyAddedPaymentMethodsIdList) },
    filter({ disabled: false }, accountPaymentMethods)
  );
};
export const orderStageToFlowStage = (__typename) =>
  replace("OrderStage", "FlowStage", __typename);
export const flowStageToOrderStage = (__typename) =>
  replace("FlowStage", "OrderStage", __typename);
export const hasPaymentMethods = flow([
  get("stages"),
  some({
    __typename: flowStageToOrderStage("FlowStageSetPaymentMethods"),
  }),
]);
export const hasEveryStageFilled = (order) =>
  size(order.flowStages) === size(order.stages);

export function getOrderRoutePath(path) {
  return `order/:orderId/${path}`;
}

export function stageTypeToRoute({ stageType, subStage }) {
  return subStage ? `${stageType}_${subStage}Screen` : `${stageType}Screen`;
}

export function controlQuestionToRoute(controlQuestion) {
  return stageTypeToRoute({
    stageType: "FlowStageSetPaymentMethods",
    subStage: `ControlQuestion${capitalize(controlQuestion)}`,
  });
}

export function isKnownFlowStage(nextStage) {
  return includes(nextStage, knownFlowStages);
}
