import { StyleSheet, Platform } from "react-native";

export const styles = StyleSheet.create({
  headerContainer: {
    paddingTop: 8,
    marginBottom: 12,
    paddingHorizontal: 16,
  },
  phoneFieldContainer: { flexDirection: "row" },
  phoneCodeControlContainer: {
    // https://github.com/react-native-picker/picker/issues/322
    width: Platform.select({ android: 112, default: 88 }),
    marginRight: 16,
  },
  phoneNumberControlContainer: { flex: 1 },
  notificationSettingsControlContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 12,
    paddingHorizontal: 4,
  },
  notificationSettingsControlLabelContainer: {
    flex: 1,
    marginRight: 16,
  },
});
