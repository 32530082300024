import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const IMAGE_SIZE = 32;

export const styles = StyleSheet.create({
  container: {
    maxWidth: "90%",
    marginVertical: 12,
    flexDirection: "row",
  },
  incomingContainer: {
    alignSelf: "flex-start",
  },
  outgoingContainer: {
    alignSelf: "flex-end",
  },
  contentContainer: {
    flexShrink: 1,
  },
  incomingContentContainer: {
    alignItems: "flex-start",
  },
  outgoingContentContainer: {
    alignItems: "flex-end",
  },
  messageContainer: {
    padding: 12,
    borderRadius: 16,
    maxWidth: "100%",
  },
  incomingMessageContainer: {
    backgroundColor: Palette.WHITE,
  },
  outgoingMessageContainer: {
    backgroundColor: Palette.NUDE_60,
  },
  avatarContainer: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    backgroundColor: Palette.WHITE,
    borderRadius: IMAGE_SIZE / 2,
    justifyContent: "center",
    alignSelf: "flex-end",
    marginRight: 8,
    alignItems: "center",
  },
  avatar: {
    width: "100%",
    height: "100%",
    borderRadius: IMAGE_SIZE / 2,
  },
  hyperlink: {
    textDecorationLine: "underline",
  },
});
