import { conforms, find, flow, get, isString } from "lodash/fp";

import { IntlLanguages } from "./Intl";

export const getLanguageNameByCode = (code) =>
  flow([find({ code }), get("name")])(IntlLanguages);

export const isMessageDescriptor = conforms({
  id: isString,
  defaultMessage: isString,
});
