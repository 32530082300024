import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";

import { SVGs } from "../../res/svgs";
import { Paragraph } from "../Paragraph";

import { styles, stylingProps } from "./Empty.styles";
import { messages } from "./Empty.messages";

export function Empty({ message, iconName, children }) {
  const { formatMessage } = useIntl();
  const Icon = iconName ? SVGs[iconName] : null;

  return (
    <View style={styles.container}>
      {iconName && (
        <View style={styles.iconBackground}>
          <Icon {...stylingProps.icon} />
        </View>
      )}
      <View style={styles.text}>
        <Paragraph size="l" alignment="center">
          {message || formatMessage(messages.noResults)}
        </Paragraph>
      </View>
      {children}
    </View>
  );
}

Empty.propTypes = {
  message: PropTypes.string,
  iconName: PropTypes.string,
  children: PropTypes.any,
};
