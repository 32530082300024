import { gql } from "@apollo/client";

const countryFields = gql`
  fragment countryFields on Country {
    id
    name
    available
    codeIso2
    codeIso3
    codePhone
    phoneNumberLength
    currency
    emergencyNumber
  }
`;

const contactInfoFields = gql`
  fragment contactInfoFields on Account {
    email
    phoneCountryCode
    phoneNumber
  }
`;

const countryPhoneNumberFields = gql`
  fragment countryPhoneNumberFields on Country {
    codePhone
    phoneNumberLength
  }
`;

const editableAccountFields = gql`
  fragment editableAccountFields on Account {
    email
    locale
    notificationSettings
    onboardedAt
    phoneCountryCode
    phoneNumber
  }
`;

// we can't use Account model for everything atm, https://eyrmedical.atlassian.net/browse/EYR-571
export const GetAccount = gql`
  query AccountQuery {
    account {
      id
      country {
        ...countryFields
      }
      countryUid
      firstName
      lastName
      gender
      slug
      birthdate
      ...editableAccountFields
    }
  }
  ${countryFields}
  ${editableAccountFields}
`;

export const UpdateAccount = gql`
  mutation UpdateAccountMutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
        ...editableAccountFields
      }
    }
  }
  ${editableAccountFields}
`;

export const GetContactInfoAndAvailableCountries = gql`
  query GetContactInfoAndAvailableCountries {
    countries(available: true) {
      id
      ...countryPhoneNumberFields
    }
    account {
      id
      country {
        id
        ...countryPhoneNumberFields
      }
      ...contactInfoFields
      notificationSettings
    }
  }
  ${contactInfoFields}
  ${countryPhoneNumberFields}
`;

export const GetAccountConsultationHistory = gql`
  query GetAccountConsultationHistory {
    accountConsultations {
      id
      title
      date
    }
  }
`;

export const GetAccountCountryOrgs = gql`
  query GetAccountCountryOrgs($whitelabel: String) {
    account {
      country {
        id
        orgs(default: false, type: 1, whitelabel: $whitelabel) {
          id
          name
          smallIconUrl
          slug
        }
      }
    }
  }
`;

export const GetAddAccountInsuranceOrDiscountData = gql`
  query GetAddAccountInsuranceOrDiscountData($slug: String!) {
    org(slug: $slug) {
      id
      newPolicyUrl
    }
  }
`;
