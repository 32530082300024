import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Platform, SectionList, View, Pressable } from "react-native";
import { SafeAreaView, useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";
import { show as showToast } from "@eyr-mobile/core/Toast";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useDevice, isDesktopWeb } from "@eyr-mobile/core/Device";
import { GetTreatmentPlan } from "@eyr-mobile/domain/Order";
import {
  AttachDocumentToTreatmentPlan,
  RemoveDocument,
} from "@eyr-mobile/domain/Document";
import {
  EyrButton,
  Paragraph,
  Subtitle,
  ProfileImage,
  IconContainer,
  Card,
  Loading,
  Empty,
} from "@eyr-mobile/ui/components";
import {
  useQuery,
  withHandlers,
  useMutation,
} from "@eyr-mobile/core/DataProvider";
import { getDocumentAsync, downloadAsync } from "@eyr-mobile/core/FileSystem";
import { v4 as uuid } from "uuid";
import { LoggerFactory, LOGGER_LEVEL_ERROR } from "@eyr-mobile/core/Logger";
import { noop, size, sortBy, first } from "lodash/fp";
import { ReactNativeFile } from "apollo-upload-client";
import { useAlert } from "@eyr-mobile/core/Alert";
import { getAuthorizationBearerHeader, useAuth } from "@eyr-mobile/domain/Auth";
import { getAdditionalHeaders } from "@eyr-mobile/core/Net";
import { useShare } from "@eyr-mobile/core/Share";

import { SVGs } from "../../res";

import { styles } from "./ContextualDocumentsScreen.styles";
import { messages } from "./ContextualDocumentsScreen.messages";
const logger = LoggerFactory.get("screens/CheckoutScreen");

const getIconFromExtension = (extension) => {
  switch (extension) {
    case "doc":
    case "docx": {
      return SVGs.FileWordMono;
    }
    case "xls":
    case "xlsx": {
      return SVGs.FileExcelMono;
    }
    case "mp4": {
      return SVGs.FileVideoMono;
    }
    case "png":
    case "jpg": {
      return SVGs.FileImageMono;
    }
    case "mp3":
    case "wav": {
      return SVGs.FileAudioMono;
    }
    case "ppt":
    case "pptx": {
      return SVGs.FilePowerpointMono;
    }
    case "pdf": {
      return SVGs.FilePDFMono;
    }
    default: {
      return SVGs.FileGeneralMono;
    }
  }
};

export function ContextualDocumentsScreen() {
  const { formatMessage, formatDate } = useIntl();
  const { setOptions } = useNavigation();
  const { screenSizeSelect } = useDevice();
  const insets = useSafeAreaInsets();
  const { params } = useRoute();
  const [failedDocuments, setFailedDocuments] = useState([]);
  const [uploadingDocuments, setUploadingDocuments] = useState([]);
  const abortControllers = React.useRef({});
  const alert = useAlert();
  const { accessToken } = useAuth();
  const { shareOpen } = useShare();

  const { handlers, data, refetch, refreshing } = withHandlers(
    useQuery(GetTreatmentPlan, {
      variables: { id: params.treatmentPlanId },
      skip: !params.treatmentPlanId,
    })
  );

  const [attachDocumentToTreatmentPlan] = useMutation(
    AttachDocumentToTreatmentPlan
  );

  const [removeDocument] = useMutation(RemoveDocument);

  const headers = useMemo(
    () => ({
      ...getAdditionalHeaders(),
      ...getAuthorizationBearerHeader(accessToken),
    }),
    [accessToken]
  );

  useEffect(() => {
    if (!params.treatmentPlanId) {
      const message = "params.treatmentPlanId not provided";
      logger(message, new Error(message), LOGGER_LEVEL_ERROR);
    }
  }, [params.treatmentPlanId]);

  useEffect(() => {
    if (params.pickDocumentOnMount) {
      handlePickAndUploadDocument();
    }
  }, [params.pickDocumentOnMount, handlePickAndUploadDocument]);

  useEffect(() => {
    if (!data?.treatmentPlan.name) {
      return;
    }
    setOptions({
      title: formatMessage(messages.navigationTitle, {
        name: data?.treatmentPlan.name,
      }),
    });
  }, [data?.treatmentPlan.name, setOptions, formatMessage]);

  const handleClearUploadingDocument = useCallback(
    (id) => {
      setUploadingDocuments(
        uploadingDocuments.filter((document) => document.id !== id)
      );
    },
    [uploadingDocuments]
  );

  const handleClearFailedDocument = useCallback(
    (id) => {
      setFailedDocuments(
        failedDocuments.filter((document) => document.id !== id)
      );
    },
    [failedDocuments]
  );

  const handlePickAndUploadDocument = useCallback(async () => {
    const result = await getDocumentAsync();
    if (result.canceled) {
      return;
    }
    if (size(result.assets) === 0) {
      showToast(formatMessage(messages.filePickError));
      return;
    }
    const file = first(result.assets);
    const tempId = `temp-id-${uuid()}`;
    setUploadingDocuments([
      ...uploadingDocuments,
      {
        id: tempId,
        title: file.name,
      },
    ]);
    abortControllers.current[tempId] = new window.AbortController();
    const documentInput = Platform.select({
      web: file.file,
      default: new ReactNativeFile({
        uri: Platform.select({
          android: `file://${file.uri}`,
          default: file.uri,
        }),
        type: file.mimeType,
        name: file.name,
      }),
    });
    attachDocumentToTreatmentPlan({
      variables: {
        treatmentPlanId: params.treatmentPlanId,
        documentInput,
      },
      options: {
        context: {
          fetchOptions: { signal: abortControllers.current[tempId].signal },
        },
      },
      onCompleted() {
        handleClearUploadingDocument(tempId);
      },
      onError() {
        handleClearUploadingDocument(tempId);
        setFailedDocuments([
          ...failedDocuments,
          {
            id: tempId,
            title: file.name,
          },
        ]);
      },
    });
  }, [
    attachDocumentToTreatmentPlan,
    params.treatmentPlanId,
    formatMessage,
    failedDocuments,
    uploadingDocuments,
    handleClearUploadingDocument,
  ]);

  const handleRenderFailedDocument = useCallback(
    ({ item: { title, id } }) => (
      <Card
        title={title}
        InteractivityIconComponent={SVGs.CloseMono}
        onPress={() => {
          handleClearFailedDocument(id);
        }}
        icon={
          <IconContainer variant="plain" size="xs" color="danger">
            <SVGs.AlertTriangleMono />
          </IconContainer>
        }
        description={formatMessage(messages.uploadFailedErrorTitle)}
      />
    ),
    [formatMessage, handleClearFailedDocument]
  );

  const handleAbortUpload = useCallback(
    (id) => {
      abortControllers.current[id] && abortControllers.current[id].abort();
      handleClearUploadingDocument(id);
    },
    [handleClearUploadingDocument]
  );

  const handleRemoveDocument = useCallback(
    (documentId) => {
      alert({
        title: formatMessage(messages.deleteDocumentAlertTitle),
        // message: formatMessage(messages.removeDocumentAlertMessage),
        buttonsLayout: "column",
        buttons: [
          {
            title: formatMessage(messages.confirmDeleteActionTitle),
            onPress: () => removeDocument({ variables: { documentId } }),
          },
          {
            title: formatMessage(messages.cancelDeleteActionTitle),
            variant: "secondary",
            onPress: noop,
          },
        ],
      });
    },
    [formatMessage, removeDocument, alert]
  );

  const handleDownloadDocument = useCallback(
    async ({ title, downloadUrl, extension }) => {
      try {
        if (isDesktopWeb()) {
          return await downloadAsync(downloadUrl, `${title}.${extension}`, {
            headers,
          });
        }

        return await shareOpen({
          headers,
          title: `${title}.${extension}`,
          url: downloadUrl,
        });
      } catch (error) {
        logger("handleDownloadDocument", error, LOGGER_LEVEL_ERROR);
      }
    },
    [headers, shareOpen]
  );

  const handleRenderUploadingDocument = useCallback(
    ({ item: { title, id } }) => (
      <Card
        title={title}
        InteractivityIconComponent={SVGs.CloseMono}
        onPress={() => {
          handleAbortUpload(id);
        }}
        icon={<Loading />}
      />
    ),
    [handleAbortUpload]
  );

  const handleRenderDocument = useCallback(
    ({ item: { id, title, extension, createdAt, downloadUrl } }) => {
      const Icon = getIconFromExtension(extension);
      return (
        <Card
          title={`${title}.${extension}`}
          /* eslint-disable-next-line react/no-unstable-nested-components */
          InteractivityIconComponent={(props) => (
            <Pressable
              onPress={() => handleRemoveDocument(id)}
              hitSlop={{ top: 8, bottom: 8, right: 8 }}
            >
              <SVGs.DeleteMono {...props} />
            </Pressable>
          )}
          onPress={() => {
            handleDownloadDocument({ title, downloadUrl, extension });
          }}
          icon={
            <IconContainer variant="plain" size="xs">
              <Icon />
            </IconContainer>
          }
          description={formatMessage(messages.uploadedTimeTitle, {
            date: formatDate(createdAt),
          })}
        />
      );
    },
    [formatDate, formatMessage, handleRemoveDocument, handleDownloadDocument]
  );

  const listSections = useMemo(() => {
    if (
      failedDocuments.length ||
      uploadingDocuments.length ||
      data?.treatmentPlan.documents.length
    ) {
      return [
        {
          data: failedDocuments,
          renderItem: handleRenderFailedDocument,
        },
        {
          data: uploadingDocuments,
          renderItem: handleRenderUploadingDocument,
        },
        {
          data: sortBy(
            ["createdAt"],
            data?.treatmentPlan.documents || []
          ).reverse(),
        },
      ];
    }

    return [];
  }, [
    data,
    failedDocuments,
    uploadingDocuments,
    handleRenderFailedDocument,
    handleRenderUploadingDocument,
  ]);

  const handleRenderEmpty = useCallback(
    () => (
      <Empty
        message={formatMessage(messages.emptyListTitle)}
        iconName="Documents"
      />
    ),
    [formatMessage]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      {handlers || (
        <>
          <View style={styles.topBar}>
            <View
              style={screenSizeSelect({
                xs: styles.providerContainerXS,
                s: styles.providerContainerS,
                m: styles.providerContainerM,
              })}
            >
              <View style={styles.providerImageContainer}>
                <ProfileImage
                  uri={data?.treatmentPlan.provider.image}
                  placeholder={data?.treatmentPlan.provider.name}
                />
              </View>
              <View>
                <Subtitle size="l">
                  {data?.treatmentPlan.provider.name}
                </Subtitle>
                <Paragraph size="l">
                  {data?.treatmentPlan.provider.description}
                </Paragraph>
              </View>
            </View>
          </View>
          <SectionList
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
            sections={listSections}
            renderItem={handleRenderDocument}
            ListEmptyComponent={handleRenderEmpty}
            refreshing={refreshing}
            onRefresh={refetch}
          />
          <View style={styles.bottomBar}>
            <View
              style={[
                screenSizeSelect({
                  xs: styles.buttonContainerXS,
                  s: styles.buttonContainerS,
                  m: styles.buttonContainerM,
                }),
                { paddingBottom: insets.bottom },
              ]}
            >
              <EyrButton
                size="l"
                variant="primary"
                title={formatMessage(messages.uploadActionTitle)}
                onPress={handlePickAndUploadDocument}
                IconComponent={SVGs.UploadMono}
              />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

ContextualDocumentsScreen.routeName = "ContextualDocumentsScreen";
ContextualDocumentsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
