import React, { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Platform } from "react-native";
import "@expo/match-media";
import "@eyr-mobile/ui/shared-styles/palette";
import "react-native-gesture-handler";
import {
  beginSessionTracking,
  disableVideoRecording,
} from "@eyr-mobile/core/SessionTracking";
import {
  setupApolloCache,
  setupApolloClient,
} from "@eyr-mobile/core/DataProvider";
import {
  LOGGER_LEVEL_ERROR,
  LOGGER_LEVEL_WARN,
  LoggerFactory,
} from "@eyr-mobile/core/Logger";
import { isReadyRef } from "@eyr-mobile/core/Navigation";
import { setupConfig, StaticConfig } from "@eyr-mobile/core/Config";
import { Loading, GraphQLError } from "@eyr-mobile/ui/components";
import { getAuthState, getStoredAuth, authRef } from "@eyr-mobile/domain/Auth";
import { intlRef } from "@eyr-mobile/core/Intl";
import * as SplashScreen from "expo-splash-screen";
import { useStorybook, StorybookRoot } from "@eyr-mobile/ui/Storybook";
import interfacesAndUnions from "@eyr-mobile/domain/interfacesAndUnions.json";
import { getStoredPreference } from "@eyr-mobile/domain/Preference";
import { buildNetworkErrorMessage } from "@eyr-mobile/ui/Toasts";
import { show as showToast } from "@eyr-mobile/core/Toast";
import { initMarketingSdk } from "@eyr-mobile/core/Analytics";
import { appID } from "@eyr-mobile/core/AppUpdate";
import {
  logToErrorTracking,
  recordError,
} from "@eyr-mobile/core/ErrorTracking";
import {
  getInitialURL,
  getStoredLinking,
  getURLFromNotification,
} from "@eyr-mobile/core/Linking";
import {
  getStoredOnboarding,
  parseInitialOnboardingStateFromLinking,
} from "@eyr-mobile/domain/Onboarding";
import {
  getInitialRouteName,
  routes,
} from "@eyr-mobile/ui/Navigation/Navigation.routes";
import { getInitialNotification } from "@eyr-mobile/core/Notifications";
import { parseInitialOrderStateFromLinking } from "@eyr-mobile/domain/Order";

import { AppRoot } from "./AppRoot";

const logger = LoggerFactory.get("Root");

const { SESSION_TRACKING_SDK_KEY } = StaticConfig;
if (SESSION_TRACKING_SDK_KEY) {
  if (Platform.OS === "ios") {
    // Doesn't work well with Opentok video session during the call
    disableVideoRecording();
  }
  beginSessionTracking(SESSION_TRACKING_SDK_KEY);
}
SplashScreen.preventAutoHideAsync();
export const Root = ({ initialCallState }) => {
  const [appIsReady, setAppIsReady] = useState(false);
  const [appRootState, setAppRootState] = useState();
  const storybookActive = useStorybook(false);
  const startedByIncomingCall = Boolean(initialCallState);

  const handleNetworkError = useCallback(() => {
    showToast(buildNetworkErrorMessage(intlRef.current));
  }, []);

  useEffect(() => {
    const TAG = "bootstrap";
    (async function bootstrap() {
      function giveRemoteConfigAChanceToSettleWithin(waitForMs) {
        return Promise.race([
          setupConfig({
            defaults: {
              FEATURE_PSYCHOLOGY_SELF_HELP: true,
              FEATURE_SATISFACTION_REVIEW: true,
            },
            settings: {
              minimumFetchIntervalMillis: 10 * 60000,
            },
          }).catch((e) => {
            const CLAUSE_TAG = `${TAG}:remoteConfig:setupConfig`;
            recordError(e, CLAUSE_TAG);
            return logger(
              `${CLAUSE_TAG}:failed to fetchAndActivate config. This is not blocking, continuing using defaults.`,
              e,
              LOGGER_LEVEL_WARN
            );
          }),
          new Promise((resolve) => setTimeout(resolve, waitForMs)),
        ]);
      }
      try {
        const [
          initialAuthState,
          initialPreferenceState,
          initialLinkingURL,
          initialStoredLinkingState,
          initialNotification,
          initialStoredOnboardingState,
        ] = await Promise.all([
          getStoredAuth(),
          getStoredPreference(),
          getInitialURL(),
          getStoredLinking(),
          getInitialNotification(),
          getStoredOnboarding(),
          giveRemoteConfigAChanceToSettleWithin(50),
        ]);
        const initialNotificationURL =
          getURLFromNotification(initialNotification);
        const initialURL = initialNotificationURL || initialLinkingURL;
        const initialLinkingOnboardingState =
          parseInitialOnboardingStateFromLinking(initialURL, routes);
        const initialOnboardingState = {
          ...initialStoredOnboardingState,
          ...initialLinkingOnboardingState,
        };
        const initialOrderState = parseInitialOrderStateFromLinking(initialURL);
        const { MARKETING_SDK_KEY_WEB, MARKETING_SDK_KEY_NATIVE } =
          StaticConfig;
        const marketingSdkKey = Platform.select({
          web: MARKETING_SDK_KEY_WEB,
          native: MARKETING_SDK_KEY_NATIVE,
        });
        if (marketingSdkKey) {
          initMarketingSdk({
            devKey: marketingSdkKey,
            isDebug: __DEV__,
            appId: appID,
            timeToWaitForATTUserAuthorization: 10,
          });
        }

        const apolloClient = setupApolloClient({
          cache: setupApolloCache({
            possibleTypes: interfacesAndUnions.possibleTypes,
          }),
          defaultOptions: {
            query: {
              LoadingComponent: Loading,
              ErrorComponent: GraphQLError,
            },
          },
          onNetworkError: handleNetworkError,
        });
        const nextAppRootState = {
          auth: { initialState: initialAuthState },
          apollo: { client: apolloClient },
          preference: { initialState: initialPreferenceState },
          ...(initialCallState && {
            call: { initialState: { ...initialCallState, accepted: true } },
          }),
          onboarding: {
            initialState: initialOnboardingState,
          },
          order: {
            initialState: initialOrderState,
          },
          linking: {
            initialState: {
              config: {
                ...routes,
                get initialRouteName() {
                  return getInitialRouteName(
                    authRef?.current?.state || getAuthState(initialAuthState),
                    initialOnboardingState
                  );
                },
              },
              initialURL,
              persistPendingLinkForRoutes: [
                "AddAccountInsuranceOrDiscountFromShortLinkScreen",
              ],
              ...initialStoredLinkingState,
            },
          },
        };
        setAppRootState(nextAppRootState);
        logToErrorTracking(`${TAG}:appReady`);
        logger(`${TAG}:appReady`);
        setAppIsReady(true);
      } catch (e) {
        const CLAUSE_TAG = `${TAG}:error`;
        recordError(e, CLAUSE_TAG);
        logger(CLAUSE_TAG, e, LOGGER_LEVEL_ERROR);
      }

      return () => {
        isReadyRef.current = false;
      };
    })();
  }, [handleNetworkError, initialCallState]);

  useEffect(() => {
    if (appIsReady && storybookActive) {
      SplashScreen.hideAsync();
    }
  }, [appIsReady, storybookActive]);
  useEffect(() => {
    logger("startedByIncomingCall", startedByIncomingCall);
  }, [startedByIncomingCall]);

  if (!appIsReady) {
    return null;
  }

  if (storybookActive && !startedByIncomingCall) {
    return <StorybookRoot />;
  }

  return <AppRoot {...appRootState} onReady={SplashScreen.hideAsync} />;
};

Root.propTypes = {
  initialCallState: PropTypes.object,
};
