import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { isArray, concat } from "lodash/fp";

const filter = { filter: "grayscale(100%)" };

function GrayscaleWeb({ children, style, ...rest }) {
  return (
    <View
      style={isArray(style) ? concat(style, [filter]) : [style, filter]}
      {...rest}
    >
      {children}
    </View>
  );
}

GrayscaleWeb.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Grayscale = GrayscaleWeb;
