import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { KeyboardAvoidingView, View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  createValidator,
  createValidatorWithParams,
  validateUntilFirstError,
} from "@eyr-mobile/core/Validation";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { useAlert } from "@eyr-mobile/core/Alert";

import { PINCode, Heading, Paragraph } from "../../components";

import { messages } from "./DeviceRegistrationScreen.messages";
import { styles, stylingProps } from "./DeviceRegistrationScreen.styles";

const isNotRepeatingValidator = createValidator(
  (subject) => !/^\b(\d)\1+\b$/.test(subject),
  messages.complexityError
);

const isNotSequentialValidator = createValidator(
  (subject) =>
    !("01234567890".includes(subject) || "09876543210".includes(subject)),
  messages.complexityError
);

const pinValidator = validateUntilFirstError([
  isNotRepeatingValidator,
  isNotSequentialValidator,
]);

const configureMatchValidator = createValidatorWithParams(
  (pin, confirmationPin) => pin === confirmationPin,
  messages.mismatchError
);

export function DeviceRegistrationScreen() {
  const [pin, setPin] = useState(useRoute().params?.initialPin ?? "");
  const [error, setError] = useState(null);

  const { formatMessage } = useIntl();
  const { registerDevice, logout, pinLength } = useAuth();
  const navigation = useNavigation();
  const alert = useAlert();

  const resetError = useCallback(
    (pin) => {
      if (pin.length < pinLength) {
        setError(null);
      }
    },
    [pinLength]
  );

  const setErrorAndResetPin = useCallback((error) => {
    setError(error);
    setPin("");
  }, []);

  useEffect(() => {
    return navigation.addListener("beforeRemove", (e) => {
      e.preventDefault();
      alert({
        title: formatMessage(messages.cancelRegistrationAlertTitle),
        buttons: [
          {
            title: formatMessage(
              messages.cancelRegistrationAlertCancelActionTitle
            ),
            variant: "secondary",
          },
          {
            title: formatMessage(
              messages.cancelRegistrationAlertConfirmActionTitle
            ),
            variant: "primary",
            onPress: () => {
              logout();
              navigation.dispatch(e.data.action);
            },
          },
        ],
      });
    });
  }, [navigation, alert, formatMessage, logout]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const pinMatchValidator = useCallback(configureMatchValidator(pin), [pin]);

  const settingPinUp = Boolean(!pin);
  const confirmingPin = Boolean(pin);

  return (
    <KeyboardAvoidingView
      {...stylingProps.keyboardAvoidingView}
      style={styles.container}
    >
      <SafeAreaView
        style={styles.contentContainer}
        edges={["left", "right", "bottom"]}
      >
        <View style={styles.header}>
          <Heading spacing="l" alignment="center">
            {formatMessage(
              settingPinUp ? messages.setUpPinTitle : messages.confirmPinTitle
            )}
          </Heading>
          <Paragraph size="l" color="negative" alignment="center">
            {error ? formatMessage(error) : " "}
          </Paragraph>
        </View>
        <View style={styles.pinContainer}>
          <PINCode
            length={pinLength}
            autoFocus
            focusOnForeground
            onChange={resetError}
            validator={settingPinUp ? pinValidator : pinMatchValidator}
            resetOnSucceeded={settingPinUp}
            blurOnSucceeded={confirmingPin}
            onSucceeded={settingPinUp ? setPin : registerDevice}
            onFailed={settingPinUp ? setError : setErrorAndResetPin}
          />
        </View>
        <Paragraph alignment="center">
          {formatMessage(messages.registrationHint)}
        </Paragraph>
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
}

DeviceRegistrationScreen.routeName = "DeviceRegistrationScreen";
DeviceRegistrationScreen.navigationOptions = {
  title: messages.navigationTitle,
};
