import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { fontMaker } from "../../../core/Lib/font";

export const CELL_SIZE = 48;
export const FILLED_CELL_SIZE = 12;
export const CELL_BORDER_RADIUS = 8;
export const FILLED_CELL_BORDER_RADIUS = 4;
export const DEFAULT_CELL_BG_COLOR = Palette.NUDE_40;
export const ACTIVE_CELL_BG_COLOR = Palette.NUDE_80;
export const FILLED_CELL_BG_COLOR = Palette.NUDE_100;

export const styles = StyleSheet.create({
  codeFieldRoot: {
    height: CELL_SIZE,
    justifyContent: "center",
    alignItems: "center",
  },
  cell: {
    marginHorizontal: 8,
    height: CELL_SIZE,
    width: 40,
    borderRadius: CELL_BORDER_RADIUS,
    lineHeight: CELL_SIZE - 6,
    ...fontMaker({}),
    fontSize: 20,
    textAlign: "center",
    color: Palette.BURGUNDY_100,
    overflow: "hidden",
  },
  symbol: {},
});
