import React from "react";
import { View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import {
  GetAccountChildren,
  useOrderFlowStage,
  useSelectClientFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  ProgressIndicator,
  FamilyMemberClientPicker,
  CancelOrderHeaderRight,
} from "../../components";

import { messages } from "./FlowStageSelectClientScreen.messages";
import { styles } from "./FlowStageSelectClientScreen.styles";

export function FlowStageSelectClientScreen() {
  const {
    data: { account = {} } = {},
    stageParams = {},
    handlers,
    refreshing,
    refetch,
  } = withHandlers(
    useOrderFlowStage(GetAccountChildren, { fetchPolicy: "cache-and-network" })
  );
  const { progress, product } = stageParams;

  const [{ onChildSelected, onMyselfSelected, onAddChildSelected }] =
    useSelectClientFlowStage();
  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progress}
            accessibilityLabel={`Order progress indicator ${progress * 100}%`}
          />
          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.containerXS,
                s: styles.containerS,
                m: styles.containerM,
              }),
            ]}
          >
            <FamilyMemberClientPicker
              contentContainerStyle={screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              })}
              productType={product?.type}
              familyMembers={account?.children}
              consentAge={account?.country?.consentAge}
              onAddChildSelected={onAddChildSelected}
              onChildSelected={onChildSelected}
              onMyselfSelected={onMyselfSelected}
              refreshing={refreshing}
              onRefresh={refetch}
            />
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSelectClientScreen.routeName = "FlowStageSelectClientScreen";

FlowStageSelectClientScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
