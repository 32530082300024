import { defineMessages } from "react-intl";

export const messages = defineMessages({
  defaultState: {
    id: "VaccinationServiceCard.defaultState",
    description: "default state",
    defaultMessage: "Awaiting prescription",
  },
  defaultInstructions: {
    id: "VaccinationServiceCard.defaultInstructions",
    description: "default instructions",
    defaultMessage:
      "When your prescription is ready, one of our doctors will send you a message in Eyr. In some cases a doctor may contact you for additional information before a prescription can be issued.",
  },
  completedState: {
    id: "VaccinationServiceCard.completedState",
    description: "completed state",
    defaultMessage: "Your prescription is ready",
  },
  completedInstructions: {
    id: "VaccinationServiceCard.completedInstructions",
    description: "completed instructions",
    defaultMessage:
      "Now you can go to your preferred Apotek 1 pharmacy to get vaccinated.",
  },
  findPharmacyActionTitle: {
    id: "VaccinationServiceCard.findPharmacyActionTitle",
    description: "find pharmacy action title",
    defaultMessage: "Find your nearest pharmacy",
  },
  refundedHeader: {
    id: "VaccinationServiceCard.refundedHeader",
    description: "refunded header",
    defaultMessage: "Your order has been refunded.",
  },
  refundedOrderDateTitle: {
    id: "VaccinationServiceCard.refundedOrderDateTitle",
    description: "refunded order date title",
    defaultMessage: "Date ordered",
  },
  refundedOrderDate: {
    id: "VaccinationServiceCard.refundedOrderDate",
    description: "refunded order date",
    defaultMessage: "{date} at {time}",
  },
});
