import React from "react";
import PropTypes from "prop-types";
import { View, ActivityIndicator, Text, ProgressBar } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib/render";

import { styles, stylingProps } from "./Loading.styles";
export function Loading({ progress, color, label, ...rest }) {
  const labelComponent = renderIf(label)(
    <Text style={styles.label}>{label}</Text>
  );
  return (
    <View {...rest} style={styles.loading}>
      {progress ? (
        <>
          <ProgressBar
            style={styles.progress}
            progress={progress}
            {...stylingProps.progressBarWeb(color)}
          />
          {labelComponent}
        </>
      ) : (
        <View style={styles.center}>
          <ActivityIndicator {...stylingProps.activityIndicator(color)} />
          {labelComponent}
        </View>
      )}
    </View>
  );
}

Loading.navigationOptions = {
  header: null,
};

Loading.propTypes = {
  progress: PropTypes.number,
  color: PropTypes.string,
  label: PropTypes.string,
};
