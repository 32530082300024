import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";

import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import { Card } from "../Card";
import { IconContainer } from "../IconContainer";
import { ResAwareSvg } from "../ResAwareSvg";

import { styles } from "./SelectInsuranceOrDiscount.styles";
import { messages } from "./SelectInsuranceOrDiscount.messages";

export function SelectInsuranceOrDiscount({
  insuranceProviders,
  onInsuranceOrDiscountPress,
}) {
  const { formatMessage } = useIntl();

  return (
    <>
      <View style={styles.headerContainer}>
        <Heading size="s" alignment="center" spacing="s">
          {formatMessage(messages.headerTitle)}
        </Heading>
        <Paragraph size="l" alignment="center" spacing="m">
          {formatMessage(messages.headerDescription)}
        </Paragraph>
      </View>

      {insuranceProviders?.map((org) => {
        const { name, id, smallIconUrl } = org;
        return (
          <Card
            accessibilityLabel={`Select ${name}`}
            title={name}
            key={id.toString()}
            icon={
              smallIconUrl ? (
                <IconContainer size="s" variant="plain">
                  <ResAwareSvg svg={smallIconUrl} />
                </IconContainer>
              ) : undefined
            }
            onPress={() => onInsuranceOrDiscountPress(org)}
          />
        );
      })}
    </>
  );
}

SelectInsuranceOrDiscount.propTypes = {
  insuranceProviders: PropTypes.array.isRequired,
  onInsuranceOrDiscountPress: PropTypes.func.isRequired,
};
