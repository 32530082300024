import React from "react";
import { moneyShape } from "@eyr-mobile/domain/Order";
import { useIntl } from "@eyr-mobile/core/Intl";

import { Paragraph } from "../Paragraph";

export function Money(props) {
  const { formatNumber } = useIntl();
  const {
    money: { amount, currency },
    ...rest
  } = props;

  return (
    <Paragraph size="m" alignment="end" numberOfLines={1} {...rest}>
      {formatNumber(amount, {
        style: "currency",
        currency,
        currencyDisplay: "narrowSymbol",
      })}
    </Paragraph>
  );
}

Money.propTypes = {
  money: moneyShape.isRequired,
};
