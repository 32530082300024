import { Platform, StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: { flex: 1 },
  contentContainer: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 16,
    justifyContent: "center",
  },
  header: {
    marginBottom: 16,
  },
  pinContainer: {
    marginBottom: 40,
  },
});

export const stylingProps = {
  keyboardAvoidingView: {
    ...Platform.select({
      ios: {
        keyboardVerticalOffset: 56,
        behavior: "padding",
      },
    }),
  },
};
