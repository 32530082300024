import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { WebView } from "react-native-webview";
import { Platform } from "react-native";
import { v4 as uuid } from "uuid";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  applicationNameForUserAgent,
  disableScalingInjectedJavaScript,
  patchPostMessageInjectedJavaScript,
  safeHandlePostMessage,
} from "@eyr-mobile/core/WebView";
import { show as showToast } from "@eyr-mobile/core/Toast";
import { getAdditionalHeaders } from "@eyr-mobile/core/Net";
import {
  getAuthorizationBearerHeader,
  injectWebWebViewAuth,
  useAuth,
} from "@eyr-mobile/domain/Auth";
import { useShare } from "@eyr-mobile/core/Share";
import { downloadAsync } from "@eyr-mobile/core/FileSystem";
import {
  getIfDocumentCanBeExported,
  getDocumentsRootUrl,
} from "@eyr-mobile/domain/Document";
import { useNavigation, useRoute } from "@react-navigation/native";
import { snakeCase, last } from "lodash/fp";
import { useIntl } from "@eyr-mobile/core/Intl";
import { isDesktopWeb } from "@eyr-mobile/core/Device";
import { recordError } from "@eyr-mobile/core/ErrorTracking";

import { HeaderButton, Loading } from "../../components";
import { buildNetworkErrorMessage } from "../../Toasts";

import { styles } from "./DocumentsScreen.styles";
import { messages } from "./DocumentsScreen.messages";

const logger = LoggerFactory.get("screens/DocumentsScreen");

const injectedJavaScript =
  disableScalingInjectedJavaScript + patchPostMessageInjectedJavaScript;

const getDocumentTitle = (documentUrl, documentTitle) => {
  let title;
  if (documentTitle) {
    title = `${last(documentTitle.split("/"))}.pdf`; // the title is an url sometimes
  } else {
    const documentUri = documentUrl.split("form_data")[1];
    const lastUri = last(documentUrl.split("/"));
    title = `EYR_${documentUri ? snakeCase(documentUri) : lastUri}.pdf`;
  }
  return title;
};

export const DocumentsScreen = () => {
  const { accessToken } = useAuth();
  const { shareOpen } = useShare();
  const { setOptions, goBack, popToTop, addListener } = useNavigation();
  const webViewRef = useRef();
  const { formatMessage } = useIntl();
  const [isExporting, setIsExporting] = useState(false);
  const { params } = useRoute();
  const initialUrl = useMemo(
    () => params?.initialUrl || getDocumentsRootUrl(),
    [params?.initialUrl]
  );
  const [webviewCanGoBack, setWebviewCanGoBack] = useState(false);
  const [sharableInfo, setSharableInfo] = useState(null);

  const headers = useMemo(
    () => ({
      ...getAdditionalHeaders(),
      ...getAuthorizationBearerHeader(accessToken),
    }),
    [accessToken]
  );

  const onMessage = useCallback(
    ({ nativeEvent }) => {
      safeHandlePostMessage(nativeEvent, logger, (data) => {
        const { status } = JSON.parse(data);

        switch (status) {
          case "ok":
            goBack();
            break;
          case "error":
            popToTop();
            break;
          default:
        }
      });
    },
    [goBack, popToTop]
  );

  const showGenericError = useCallback(() => {
    showToast(buildNetworkErrorMessage({ formatMessage }));
  }, [formatMessage]);

  const handleShareDocument = useCallback(
    async (documentUrl, documentTitle) => {
      const title = getDocumentTitle(documentUrl, documentTitle);
      setIsExporting(true);
      try {
        return await shareOpen(
          {
            headers,
            title,
            url: `${documentUrl}?_format=pdf`,
          },
          { failOnCancel: false }
        );
      } catch (error) {
        showGenericError();
        recordError(error);
        logger("handleShareDocument", error, LOGGER_LEVEL_ERROR);
      } finally {
        setIsExporting(false);
      }
    },
    [headers, shareOpen, showGenericError]
  );

  const handleDownloadDocument = useCallback(
    async (documentUrl, documentTitle) => {
      const title = getDocumentTitle(documentUrl, documentTitle);
      setIsExporting(true);
      try {
        return await downloadAsync(`${documentUrl}?_format=pdf`, title, {
          headers,
        });
      } catch (error) {
        showGenericError();
        recordError(error);
        logger("handleDownloadDocument", error, LOGGER_LEVEL_ERROR);
      } finally {
        setIsExporting(false);
      }
    },
    [headers, showGenericError]
  );

  const handleNavigateBack = useCallback(
    (event) => {
      if (!webviewCanGoBack) {
        return;
      }
      webViewRef.current?.goBack();
      event.preventDefault();
    },
    [webviewCanGoBack]
  );

  const handleNavigationStateChange = useCallback(
    ({ url, canGoBack, title, loading }) => {
      if (loading) {
        return;
      }
      setOptions({
        title:
          // handle Android WebView returning URLs without protocol (http(s):) as titles
          title && !/\/webview/.test(title)
            ? title
            : formatMessage(messages.navigationTitle),
      });
      setWebviewCanGoBack(canGoBack);
      const exportable = getIfDocumentCanBeExported(url);
      setSharableInfo(
        exportable
          ? { url, title: `${title}${title ? `-${uuid()}` : ""}` }
          : null
      );
    },
    [formatMessage, setOptions]
  );

  const handleLoad = useCallback(() => {
    handleNavigationStateChange({ url: initialUrl });
  }, [initialUrl, handleNavigationStateChange]);

  useEffect(() => {
    if (isExporting) {
      setOptions({
        headerRight: Loading,
      });
    } else {
      if (sharableInfo) {
        const shouldShare = !isDesktopWeb();
        const action = shouldShare
          ? handleShareDocument
          : handleDownloadDocument;
        const icon = shouldShare ? "ShareMono" : "DownloadMono";
        setOptions({
          headerRight: (props) => (
            <HeaderButton
              {...props}
              iconName={icon}
              onPress={() => {
                action(sharableInfo.url, sharableInfo.title);
              }}
            />
          ),
        });
      } else {
        setOptions({ headerRight: undefined });
      }
    }
  }, [
    handleDownloadDocument,
    handleShareDocument,
    isExporting,
    setOptions,
    sharableInfo,
  ]);

  useEffect(() => {
    return addListener("beforeRemove", handleNavigateBack);
  }, [handleNavigateBack, addListener]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      <WebView
        ref={(ref) => {
          webViewRef.current = ref;
        }}
        source={Platform.select({
          default: { headers, uri: initialUrl },
          web: {
            uri: injectWebWebViewAuth(initialUrl, accessToken),
          },
        })}
        decelerationRate="normal"
        automaticallyAdjustContentInsets={false}
        applicationNameForUserAgent={applicationNameForUserAgent}
        bounces={false}
        injectedJavaScript={injectedJavaScript}
        scalesPageToFit={false}
        startInLoadingState
        onMessage={onMessage}
        onLoad={handleLoad}
        onNavigationStateChange={handleNavigationStateChange}
        testID={"DigitalToolFormRenderer"}
        web_postMessageTarget={"window"}
      />
    </SafeAreaView>
  );
};

DocumentsScreen.routeName = "DocumentsScreen";
DocumentsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
