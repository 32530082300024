import React, { useCallback } from "react";
import { get } from "lodash/fp";
import { ScrollView, KeyboardAvoidingView } from "react-native";
import { useApolloClient, useMutation } from "@apollo/client";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import {
  AddTokenizedCard,
  GetPaymentMethodsForOrder,
  tokenizeCard,
  useOrderFlowStageParams,
  useSetPaymentMethodsFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  ProgressIndicator,
  AddCreditCard,
  parseExpiration,
  CancelOrderHeaderRight,
} from "../../components";

import { messages } from "./FlowStageSetPaymentMethods_AddCreditCardScreen.messages";
import {
  styles,
  stylingProps,
} from "./FlowStageSetPaymentMethods_AddCreditCardScreen.styles";

export function FlowStageSetPaymentMethods_AddCreditCardScreen() {
  const client = useApolloClient();

  const {
    stageParams: { progress, progressPerStage, orderId } = {},
    handlers,
  } = useOrderFlowStageParams();
  const [
    { primaryPaymentMethodId, selectCard, selectCardWithPartialPaymentMethod },
    { loading: modifying },
  ] = useSetPaymentMethodsFlowStage();
  const [addTokenizedCard, { loading: tokenizing }] =
    useMutation(AddTokenizedCard);
  const onSubmit = useCallback(
    async (card) => {
      if (tokenizing || modifying) {
        return;
      }
      const { exp, number, cvc } = card;
      const { exp_month, exp_year } = parseExpiration(exp);
      const token = await tokenizeCard({
        number,
        exp_month,
        exp_year,
        cvc,
      });
      const { data: cardPayload } = await addTokenizedCard({
        variables: { input: { token, provider: "stripe" } },
      });
      await client.query({
        query: GetPaymentMethodsForOrder,
        variables: {
          orderId,
        },
        fetchPolicy: "network-only",
      });
      const tokenizedCard = get("addTokenizedCard.card", cardPayload);
      if (!tokenizedCard) {
        return;
      }
      if (primaryPaymentMethodId) {
        selectCardWithPartialPaymentMethod(tokenizedCard);
      } else {
        selectCard(tokenizedCard);
      }
    },
    [
      addTokenizedCard,
      client,
      modifying,
      orderId,
      primaryPaymentMethodId,
      selectCard,
      selectCardWithPartialPaymentMethod,
      tokenizing,
    ]
  );
  const progressWithinSubStages = progress + (2 / 3) * progressPerStage;
  const { screenSizeSelect } = useDevice();
  return (
    <KeyboardAvoidingView
      {...stylingProps.keyboardAvoidingView}
      style={styles.container}
    >
      <SafeAreaView
        style={styles.container}
        edges={["left", "right", "bottom"]}
      >
        {handlers || (
          <>
            <ProgressIndicator
              value={progressWithinSubStages}
              accessibilityLabel={`Order progress indicator ${
                progressWithinSubStages * 100
              }%`}
            />
            <ScrollView
              contentContainerStyle={[
                styles.contentContainer,
                screenSizeSelect({
                  xs: styles.contentContainerXS,
                  s: styles.contentContainerS,
                  m: styles.contentContainerM,
                }),
              ]}
            >
              <AddCreditCard onSubmit={onSubmit} />
            </ScrollView>
          </>
        )}
      </SafeAreaView>
    </KeyboardAvoidingView>
  );
}
FlowStageSetPaymentMethods_AddCreditCardScreen.routeName =
  "FlowStageSetPaymentMethods_AddCreditCardScreen";
FlowStageSetPaymentMethods_AddCreditCardScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
