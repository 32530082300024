import React, { useCallback, useEffect } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useAlert } from "@eyr-mobile/core/Alert";
import {
  ProgressIndicator,
  Heading,
  Card,
  IconContainer,
} from "@eyr-mobile/ui/components";
import {
  useOrderFlowStage,
  GetTravelControlCountryData,
  useTravelControlQuestion,
  useCancelOrder,
} from "@eyr-mobile/domain/Order";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { View } from "react-native";
import { find, getOr } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res";

import { styles } from "./FlowStageSetPaymentMethods_ControlQuestionTravelScreen.styles";
import { messages } from "./FlowStageSetPaymentMethods_ControlQuestionTravelScreen.messages";

const FlagIcons = {
  NOR: SVGs.FlagNorway,
  DNK: SVGs.FlagDenmark,
  SWE: SVGs.FlagSweden,
};

export function FlowStageSetPaymentMethods_ControlQuestionTravelScreen() {
  const { formatMessage } = useIntl();
  const { setOptions } = useNavigation();
  const alert = useAlert();
  const { screenSizeSelect } = useDevice();

  const {
    stageParams: { progress, progressPerStage } = {},
    handlers,
    data,
  } = withHandlers(
    useOrderFlowStage(GetTravelControlCountryData, ({ orderId }) => ({
      variables: { orderId },
    }))
  );

  const [
    {
      answerWith,
      chooseDestinationCountry,
      controlPaymentMethodId,
      onCompleted,
    },
  ] = useTravelControlQuestion();
  const [cancelOrder] = useCancelOrder();
  const progressWithinSubStages = progress + (1 / 3) * progressPerStage;
  const accountCountry = data?.account.country;

  const FlagIcon = getOr(null, accountCountry?.codeIso3, FlagIcons);

  const controlPaymentMethodName = find(
    { id: controlPaymentMethodId },
    data?.accountPaymentMethodsForOrder
  )?.name;

  useEffect(() => {
    if (controlPaymentMethodName) {
      setOptions({ title: controlPaymentMethodName });
    }
  }, [controlPaymentMethodName, setOptions]);

  const handleAnswerWithInvalidLocation = useCallback(
    () =>
      answerWith(accountCountry?.codeIso3, {
        onCompleted: (data) =>
          alert({
            icon: FlagIcon && (
              <IconContainer size="l">
                <FlagIcon />
              </IconContainer>
            ),
            title: formatMessage(messages.alertInvalidTravelLocationTitle, {
              location: accountCountry?.name,
            }),
            message: formatMessage(
              messages.alertInvalidTravelLocationDescription,
              {
                insurance: controlPaymentMethodName,
                location: accountCountry?.name,
              }
            ),
            buttonsLayout: "column",
            buttons: [
              {
                title: formatMessage(messages.changePaymentMethodActionTitle),
                onPress: () => onCompleted(data),
              },
              {
                title: formatMessage(messages.cancelOrderActionTitle),
                variant: "secondary",
                onPress: cancelOrder,
              },
            ],
          }),
      }),
    [
      FlagIcon,
      alert,
      answerWith,
      controlPaymentMethodName,
      formatMessage,
      accountCountry,
      onCompleted,
      cancelOrder,
    ]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />

          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
          >
            <View style={styles.titleContainer}>
              <Heading alignment="center" size="s">
                {formatMessage(messages.title)}
              </Heading>
            </View>
            <Card
              onPress={chooseDestinationCountry}
              icon={
                <IconContainer variant="plain" size="s">
                  <SVGs.GlobeMono />
                </IconContainer>
              }
              titleIsBold
              title={formatMessage(messages.answerWithValidLocationActionTitle)}
            />
            <Card
              onPress={handleAnswerWithInvalidLocation}
              icon={
                FlagIcon && (
                  <IconContainer variant="plain" size="s">
                    <FlagIcon />
                  </IconContainer>
                )
              }
              titleIsBold
              title={accountCountry?.name}
            />
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetPaymentMethods_ControlQuestionTravelScreen.routeName =
  "FlowStageSetPaymentMethods_ControlQuestionTravelScreen";

FlowStageSetPaymentMethods_ControlQuestionTravelScreen.navigationOptions = {
  title: null,
};
