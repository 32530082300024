import { noop } from "lodash/fp";

export const AuthState = {
  UNAUTHENTICATED: "UNAUTHENTICATED",
  LOCKED: "LOCKED",
  AUTHENTICATED: "AUTHENTICATED",
};
export const AuthType = {
  PIN: "PIN",
  BIOMETRY: "BIOMETRY",
};
export const AuthPermission = {
  DASHBOARD: 2 ** 0,
  ROLE_MANAGER: 2 ** 1,
  CONSULTATIONS: 2 ** 2,
  CALLS: 2 ** 3,
  APPOINTMENTS: 2 ** 4,
  CONVERSATIONS: 2 ** 5,
  FILES: 2 ** 6,
  FORMS: 2 ** 7,
  PAYMENTS: 2 ** 8,
  USERS: 2 ** 9,
  SCHEDULE: 2 ** 10,
  BOOKINGS: 2 ** 11,
  CONSENTS: 2 ** 12,
  MESSAGE_TAG_MANAGER: 2 ** 13,
  VACCINATIONS: 2 ** 14,
  PHARMACIES: 2 ** 15,
  INTERNATIONAL_PRESCRIPTIONS: 2 ** 16,
  BOOKING_FIXED: 2 ** 17,
  BOOKING_QUEUE: 2 ** 18,
  BOOKING_FORM: 2 ** 19,
  CUSTOMER_RECEIPTS: 2 ** 20,
  CONSULTATIONS_TOPIC: 2 ** 21,
  REPORTS_INSURANCE_USAGE: 2 ** 22,
  REPORTS: 2 ** 23,
  TREATMENT: 2 ** 24,
};
const prefix = "@Eyr:";
export const STORAGE_KEY_GENERIC_PASSWORD = `${prefix}GenericPassword`;
export const STORAGE_KEY_CERTIFICATE = `${prefix}Certificate`;
export const STORAGE_KEY_AUTH_TYPE = `${prefix}authType`;
export const STORAGE_KEY_ACCESS_TOKEN = `${prefix}accessToken`;
export const STORAGE_KEY_PASSWORD_REMAINING_ATTEMPTS = `${prefix}PasswordRemainingAttempts`;
export const IdentityProviderAuthPermission = {
  CERTIFICATE: 2 ** 0,
  CONSENTS: 2 ** 1,
  CUSTOMER_RECEIPTS: 2 ** 2,
};
export const TIME_LIMIT_TOKEN_REFRESH_THRESHOLD = 0.1;
export const authStateDefaults = {
  state: null,
  cert: null,
  accessToken: null,
  type: null,
  pin: null,
  pinLength: 4,
  remainingPinEntryAttempts: 3,
  error: null,
  requiresLegalChecks: true,
  completeLegalChecks: noop,
  initializeIdentification: noop,
  completeIdentification: noop,
  completeReidentification: noop,
  registerDevice: noop,
  lock: noop,
  tryToUnlockWithPin: noop,
  tryToUnlockWithBiometry: noop,
  setPreferableAuthType: noop,
  ensureAll: noop,
  ensureOneOf: noop,
  logout: noop,
};
