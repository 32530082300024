import AsyncStorage from "@react-native-async-storage/async-storage";

const STORAGE_KEY_ONBOARDING = "@Eyr:onboarding";

export function storeOnboarding(state) {
  return AsyncStorage.setItem(STORAGE_KEY_ONBOARDING, JSON.stringify(state));
}

export function getStoredOnboarding() {
  return AsyncStorage.getItem(STORAGE_KEY_ONBOARDING).then(JSON.parse);
}

export function clearStoredOnboarding() {
  return AsyncStorage.removeItem(STORAGE_KEY_ONBOARDING);
}
