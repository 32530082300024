import { gql } from "@apollo/client";

export const accountTypeDefs = gql`
  type Consultation {
    id: ID!
    title: String!
    date: DateTime!
  }

  extend type RootQueryType {
    accountConsultations: [Consultation!]!
  }
`;
