import React from "react";
import { View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import PropTypes from "prop-types";

import { EyrButton } from "../EyrButton";
import { Paragraph } from "../Paragraph";

import { messages } from "./SensitiveContent.messages";
import { styles } from "./SensitiveContent.styles";

export function SensitiveContent({ onInitializeIdentification }) {
  const { formatMessage } = useIntl();

  return (
    <View style={styles.container}>
      <View style={styles.textWrap}>
        <Paragraph size="l" alignment="center">
          {formatMessage(messages.accessSensitiveData)}
        </Paragraph>
      </View>
      <View style={styles.buttonWrap}>
        <EyrButton
          variant="primary"
          onPress={onInitializeIdentification}
          title={formatMessage(messages.initializeIdentificationButton)}
        />
      </View>
    </View>
  );
}

SensitiveContent.propTypes = {
  onInitializeIdentification: PropTypes.func.isRequired,
};
