import { gql } from "@apollo/client";

export const UpdateAccountLocale = gql`
  mutation UpdateAccountLocale($locale: String!) {
    updateAccount(input: { locale: $locale }) {
      account {
        id
        locale
      }
    }
  }
`;

export const GetPreferences = gql`
  query GetPreferences {
    account {
      id
      locale
    }
  }
`;
