import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelScreen.title",
    description: "screen title",
    defaultMessage: "Where are you traveling?",
  },
  answerWithValidLocationActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelScreen.answerWithValidLocationActionTitle",
    description: "list item to reenable payment method title",
    defaultMessage: "Abroad",
  },
  alertInvalidTravelLocationTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelScreen.alertInvalidTravelLocationTitle",
    description: "alert shown for invalid travel location title",
    defaultMessage: "Consultation not covered by the insurance",
  },
  alertInvalidTravelLocationDescription: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelScreen.alertInvalidTravelLocationDescription",
    description: "alert shown for invalid travel location description",
    defaultMessage:
      "{insurance} insurance only covers consultations while you are traveling abroad.",
  },
  changePaymentMethodActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelScreen.changePaymentMethodActionTitle",
    description: "button text for selecting different payment method",
    defaultMessage: "Select another payment method",
  },
  cancelOrderActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelScreen.cancelOrderActionTitle",
    description: "button text for cancelling order",
    defaultMessage: "Cancel order",
  },
});
