import { Platform, StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const spacer = 16;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerS: {
    alignItems: "center",
  },
  contentContainer: {
    backgroundColor: Palette.BURGUNDY_125,
  },
  contentContainerS: {
    overflow: "hidden",
    borderRadius: 12,
    margin: spacer,
  },
  blurView: {
    ...StyleSheet.absoluteFill,
    borderRadius: 12,
    overflow: "hidden",
  },
  video: {
    width: "100%",
    height: "100%",
  },
  videoStateOverlay: {
    alignSelf: "center",
    position: "absolute",
    flexDirection: "row",
    backgroundColor: Palette.OVERLAY_DARK_20,
    borderRadius: 12,
    paddingVertical: 8,
    paddingLeft: 8,
    paddingRight: 24,
    marginHorizontal: spacer,
    marginBottom: spacer,
    minWidth: 120,
    minHeight: 56,
    left: 0,
    bottom: 0,
  },
  videoStateOverlayWithoutIcons: {
    paddingLeft: 16,
  },
  videoStateOverlayXS: { right: 0 },
  videoStateOverlayS: { maxWidth: 350 },
  videoStateIconSpacer: {
    marginRight: 8,
  },
  videoStateOverlayTextContainer: {
    flex: 1,
    justifyContent: "center",
  },
  videoStateIconsContainer: {
    flexDirection: "row",
    marginRight: 12,
  },
  videoStateIconContainer: {
    backgroundColor: Palette.OVERLAY_LIGHT_20,
    width: 40,
    height: 40,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
  },
});
export const stylingProps = {
  videoStateIcon: {
    fill: Palette.WHITE,
  },
  video: {
    height: "100%",
    width: "100%",
    ...Platform.select({
      web: {
        showControls: false,
      },
    }),
  },
  blurView: {
    intensity: 10,
  },
};

export const stylingHelpers = {
  getVideoStyleXS: (screenHeight) => ({
    height: screenHeight,
  }),
  getVideoStyleS: (screenHeight, screenWidth, stateOverlayOffset) => ({
    height: screenHeight - stateOverlayOffset - spacer,
    width: screenWidth - 2 * spacer,
    maxWidth: ((screenHeight - 2 * spacer) * 16) / 9,
  }),
  getStateOverlayPosition: (bottom) => ({ bottom }),
};

export const stateOverlayInitialPositionBottom = 0;
export const stateOverlayAnimationDuration = 300;
