import React, { useState, useEffect, useCallback } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { Image, Pressable, Text, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { Constants } from "@eyr-mobile/core/Config";
import { useWhiteLabel } from "@eyr-mobile/domain/WhiteLabel";
import { renderIf } from "@eyr-mobile/core/Lib";
import { getWhiteLabelAssetURL } from "@eyr-mobile/core/Net";
import { SvgUri } from "@eyr-mobile/core/Svg";

import { EyrButton, Heading, Paragraph } from "../../components";
import HomeScreenLoginImage from "../../res/images/HomeScreenLogin.jpg";
import { SVGs } from "../../res";

import { styles, stylingProps } from "./HomeScreen.styles";
import { messages } from "./HomeScreen.messages";

function HeaderTitle() {
  const whiteLabel = useWhiteLabel();
  return whiteLabel ? (
    <SvgUri
      {...stylingProps.headerTitleImage}
      uri={getWhiteLabelAssetURL(`${whiteLabel}.svg`)}
    />
  ) : (
    <SVGs.EyrLogo {...stylingProps.headerTitleSvg} />
  );
}

export function HomeScreen() {
  const [appleDemoActivatorTapCounter, setAppleDemoActivatorTapCounter] =
    useState(0);
  const whiteLabel = useWhiteLabel();

  const incrementTapCounter = useCallback(() => {
    setAppleDemoActivatorTapCounter(appleDemoActivatorTapCounter + 1);
  }, [appleDemoActivatorTapCounter]);

  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { initializeIdentification } = useAuth();

  useEffect(() => {
    if (appleDemoActivatorTapCounter === 7) {
      navigate("EnableAppleReviewModeScreen");
      setAppleDemoActivatorTapCounter(0);
    }
  }, [appleDemoActivatorTapCounter, navigate]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <Image source={HomeScreenLoginImage} style={styles.mainImage} />
      <View style={styles.contentContainer}>
        <View style={styles.loginContainer}>
          <Pressable onPress={incrementTapCounter}>
            <Heading spacing="l" alignment="center" numberOfLines={2}>
              {formatMessage(messages.text)}
            </Heading>
          </Pressable>
          <EyrButton
            variant={"primary"}
            title={formatMessage(messages.primaryActionTitle)}
            onPress={() => initializeIdentification()}
          />
          {renderIf(whiteLabel)(
            <View style={styles.whiteLabelContainer}>
              <Paragraph>{formatMessage(messages.creditText)}</Paragraph>
              <SVGs.EyrLogo
                {...stylingProps.whiteLabelIcon}
                style={styles.whiteLabelLogo}
              />
            </View>
          )}
        </View>
        <Text style={styles.appVersionIndicator}>
          {Constants.expoConfig.version}
        </Text>
      </View>
    </SafeAreaView>
  );
}

HomeScreen.routeName = "HomeScreen";
HomeScreen.navigationOptions = {
  headerTitle: HeaderTitle,
  title: messages.primaryActionTitle,
  useResponsiveNavigationContainer: true,
};
