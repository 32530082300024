import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  itemDescriptionIconContainer: {
    marginRight: 6,
  },
  itemDescriptionContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  sectionTitleContainer: {
    marginTop: 16,
    marginBottom: 4,
  },
  cardContainer: {
    marginHorizontal: 16,
  },
  listContentContainer: {
    paddingBottom: 32,
  },
  moduleContainer: {
    marginBottom: 12,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  loadingContainer: {
    flex: 1,
    paddingVertical: 100,
    alignItems: "center",
  },
  listItemContainer: {
    padding: 16,
  },
  accordionHeaderContainer: {
    paddingRight: 8,
    flex: 1,
  },
});

export const stylingProps = {
  listItemReadingTimeIcon: {
    width: 18,
    height: 18,
    fill: Palette.BURGUNDY_100,
  },
};
