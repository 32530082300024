import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Pressable } from "react-native";
import { get } from "lodash/fp";

import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";
import { stylingProps } from "../EyrRadioSelect/EyrRadioSelect.styles";

import { styles } from "./Checkbox.styles";

export function Checkbox({
  label,
  variant,
  onSelected,
  disabled,
  initialValue,
}) {
  const [value, setValue] = useState(initialValue);
  const descriptor = variant;
  const containerStyle = get(`${descriptor}Container`, styles);
  const iconProps = get(`${descriptor}Icon`, stylingProps);
  const Icon = SVGs[`Checkbox${value === true ? "On" : "Off"}Mono`];

  const onPress = useCallback(() => {
    setValue(!value);
    onSelected(!value);
  }, [onSelected, value]);

  return (
    <Pressable
      style={[containerStyle, disabled && styles.disabled]}
      disabled={disabled}
      onPress={onPress}
    >
      <Icon style={styles.icon} {...iconProps} />
      <Paragraph size="l">{label}</Paragraph>
    </Pressable>
  );
}

Checkbox.propTypes = {
  variant: PropTypes.oneOf(["primary", "danger"]),
  label: PropTypes.string.isRequired,
  onSelected: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  initialValue: PropTypes.bool,
};

Checkbox.defaultProps = {
  variant: "primary",
};
