import { defineMessages } from "react-intl";

import { serviceProviderTitleMessages } from "../../shared-messages";

export const messages = {
  ...defineMessages({
    upcomingAppointmentDetailsTitle: {
      id: "PatientGuideServiceCard.upcomingAppointmentDetailsTitle",
      description: "upcoming appointment title",
      defaultMessage: "Upcoming appointment:",
    },
    attachedDocumentsCount: {
      id: "PatientGuideServiceCard.attachedDocumentsCount",
      description: "X Documents uploaded",
      defaultMessage: "{attachedDocumentsCount} documents uploaded",
    },
    uploadDocumentsExplaination: {
      id: "PatientGuideServiceCard.uploadDocumentsExplaination",
      description: "explanation what to upload",
      defaultMessage:
        "Please upload the documents your doctor has asked you to provide.",
    },
    uploadDocumentActionTitle: {
      id: "PatientGuideServiceCard.uploadDocumentActionTitle",
      description: "Upload document action title",
      defaultMessage: "Upload document",
    },
    bookFollowUpActionTitle: {
      id: "PatientGuideServiceCard.bookFollowUpActionTitle",
      description: "Book a follow-up appointment action title",
      defaultMessage: "Book a follow-up appointment",
    },
    refundedAppointmentDetailsTitle: {
      id: "PatientGuideServiceCard.refundedAppointmentDetailsTitle",
      description: "Date refunded consultation title",
      defaultMessage: "Date of consultation",
    },
    refundedAppointmentInfoMessage: {
      id: "PatientGuideServiceCard.refundedAppointmentInfoMessage",
      description: "Refunded consultation info message",
      defaultMessage: "Your order has been refunded.",
    },
    listItemHowToPrepareTitle: {
      id: "PatientGuideServiceCard.listItemHowToPrepareTitle",
      description: "How to prepare list item title",
      defaultMessage: "How to prepare",
    },
    listItemCancellationInfoTitle: {
      id: "PatientGuideServiceCard.listItemCancellationInfoTitle",
      description: "Information about cancellation list item title",
      defaultMessage: "Information about cancellation",
    },
    viewDocumentsActionTitle: {
      id: "PatientGuideServiceCard.viewDocumentsActionTitle",
      description: "Action title for viewing documents",
      defaultMessage: "View",
    },
    bookedConsultationDate: {
      id: "PatientGuideServiceCard.bookedConsultationDate",
      description: "Date and time appointment is scheduled for",
      defaultMessage: "{date} at {time}",
    },
    delayedHeader: {
      id: "PatientGuideServiceCard.delayedHeader",
      description: "delayed header",
      defaultMessage:
        "The {serviceProviderTitle} will call you as soon as possible. We apologise for the delay.",
    },
    reassignedHeader: {
      id: "PatientGuideServiceCard.reassignedHeader",
      description: "reassigned header",
      defaultMessage:
        "You have been reassigned to {providerName} for this consultation.",
    },
  }),
  ...serviceProviderTitleMessages,
};
