import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "HomeTabScreen.navigationTitle",
    description: "HomeTabScreen navigation title",
    defaultMessage: "Home",
  },
  productsSectionTitle: {
    id: "HomeTabScreen.productsSectionTitle",
    defaultMessage: "What can we help you with?",
    description: "HomeTabScreen products section title",
  },
  introActionTitle: {
    id: "HomeTabScreen.introActionTitle",
    defaultMessage: "How Eyr works",
    description: "HomeTabScreen intro action title",
  },
  introActionDescription: {
    id: "HomeTabScreen.introActionDescription",
    defaultMessage: "Learn about our offerings and services",
    description: "HomeTabScreen intro action description",
  },
  introActionButtonTitle: {
    id: "HomeTabScreen.introActionButtonTitle",
    defaultMessage: "Learn more",
    description: "HomeTabScreen intro action button title",
  },
  ordersSectionTitle: {
    id: "HomeTabScreen.ordersSectionTitle",
    defaultMessage: "Your orders",
    description: "orders section title",
  },
  apoproPharmacyButtonTitle: {
    id: "HomeTabScreen.apoproPharmacyButtonTitle",
    defaultMessage: "Go to Apopro online pharmacy",
    description: "Apopro pharmacy button title",
  },
  apoproPharmacyDescription: {
    id: "HomeTabScreen.apoproPharmacyDescription",
    defaultMessage: "Free shipping and discounts",
    description: "shipping and discounts details text",
  },
  addInformationSectionTitle: {
    id: "HomeTabScreen.addInformationSectionTitle",
    description: "contact information section title",
    defaultMessage: "Add your information",
  },
  noContactDescription: {
    id: "HomeTabScreen.noContactDescription",
    description: "Text for when customer has no contact information stored",
    defaultMessage:
      "Make it easier for your doctor or psychologist to contact you.",
  },
  noContactTitle: {
    id: "HomeTabScreen.noContactTitle",
    description: "Add contact info button title",
    defaultMessage: "Add contact details",
  },
  noInsuranceDescription: {
    id: "HomeTabScreen.noInsuranceDescription",
    description: "Text for when customer has no insurance info stored",
    defaultMessage:
      "Get the consultation fully or partially covered by insurance or membership.",
  },
  noInsuranceTitle: {
    id: "HomeTabScreen.noInsuranceTitle",
    description: "Add insurance info button title",
    defaultMessage: "Add insurance",
  },
  cancellationModalTitle: {
    id: "HomeTabScreen.cancellationModalTitle",
    description: "cancellation modal title",
    defaultMessage: "Cancellation",
  },
  preparationInfoModalTitle: {
    id: "HomeTabScreen.preparationInfoModalTitle",
    description: "preparation info modal title",
    defaultMessage: "How to prepare for your consultation",
  },
  mentalSelfHelpReadMoreIntroductionFirstParagraph: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreIntroductionFirstParagraph",
    defaultMessage:
      "The self-help program consists of a combination of video consultations with a psychologist and digital course modules tailored for patients with mild symptoms of anxiety, depression, stress, low self-esteem or sleep disturbance.",
    description: "Mental self help introduction first paragraph",
  },
  readMoreActionTitle: {
    id: "HomeTabScreen.readMoreActionTitle",
    defaultMessage: "Book consultation",
    description: "Read more action title",
  },
  mentalSelfHelpReadMoreUSPNumberOne: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreUSPNumberOne",
    defaultMessage: "Initial assessment session",
    description: "Mental self help USP number one",
  },
  mentalSelfHelpReadMoreUSPNumberTwo: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreUSPNumberTwo",
    defaultMessage: "Program duration: 5-7 weeks",
    description: "Mental self help USP number two",
  },
  mentalSelfHelpReadMoreUSPNumberThree: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreUSPNumberThree",
    defaultMessage: "Up to 5 video consultations (25 min each)",
    description: "Mental self help USP number three",
  },
  mentalSelfHelpReadMoreUSPNumberFour: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreUSPNumberFour",
    defaultMessage: "Messaging with your psychologist during treatment",
    description: "Mental self help USP number four",
  },
  mentalSelfHelpReadMoreUSPNumberFive: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreUSPNumberFive",
    defaultMessage:
      "Access to course modules: video & audio, articles and assignments",
    description: "Mental self help USP number five",
  },
  mentalSelfHelpReadMoreConditionsTitle: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreConditionsTitle",
    defaultMessage: "Price for patients paying with a bank card:",
    description: "Mental self help conditions title",
  },
  mentalSelfHelpReadMoreConditionsFirstParagraph: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreConditionsFirstParagraph",
    defaultMessage: "Initial assessment session",
    description: "Mental self help conditions first paragraph",
  },
  mentalSelfHelpReadMoreConditionsSecondParagraph: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreConditionsSecondParagraph",
    defaultMessage: "Follow-up consultation",
    description: "Mental self help conditions second paragraph",
  },
  mentalSelfHelpReadMoreDisclaimer: {
    id: "HomeTabScreen.mentalSelfHelpReadMoreDisclaimer",
    defaultMessage:
      "In the case of severe symptoms, your psychologist may refer you to another treatment (either private or public).",
    description: "Mental self help disclaimer",
  },
  couplesTherapyReadMoreIntroductionFirstParagraph: {
    id: "HomeTabScreen.couplesTherapyReadMoreIntroductionFirstParagraph",
    defaultMessage:
      "Being in a relationship has its ups and downs. It is important to work on strengthening the relationship because a healthy couple's connection is linked to good mental health and high quality of life. We customize the treatment so that you can:",
    description: "Couples therapy introduction first paragraph",
  },
  couplesTherapyReadMoreUSPNumberOne: {
    id: "HomeTabScreen.couplesTherapyReadMoreUSPNumberOne",
    defaultMessage: "Get help to solve problems and conflicts",
    description: "Couples therapy USP number one",
  },
  couplesTherapyReadMoreUSPNumberTwo: {
    id: "HomeTabScreen.couplesTherapyReadMoreUSPNumberTwo",
    defaultMessage: "Learn strategies to improve communication",
    description: "Couples therapy USP number two",
  },
  couplesTherapyReadMoreUSPNumberThree: {
    id: "HomeTabScreen.couplesTherapyReadMoreUSPNumberThree",
    defaultMessage:
      "Receive valuable advice on how to strengthen the relationship",
    description: "Couples therapy USP number three",
  },
  couplesTherapyReadMoreUSPNumberFour: {
    id: "HomeTabScreen.couplesTherapyReadMoreUSPNumberFour",
    defaultMessage: "Learn how trust can be rebuilt",
    description: "Couples therapy USP number four",
  },
  couplesTherapyReadMoreUSPNumberFive: {
    id: "HomeTabScreen.couplesTherapyReadMoreUSPNumberFive",
    defaultMessage: "Conduct sessions when and where you want",
    description: "Couples therapy USP number five",
  },
  couplesTherapyReadMoreConditionsTitle: {
    id: "HomeTabScreen.couplesTherapyReadMoreConditionsTitle",
    defaultMessage: "Price:",
    description: "Couples therapy conditions title",
  },
  couplesTherapyReadMoreConditionsFirstParagraph: {
    id: "HomeTabScreen.couplesTherapyReadMoreConditionsFirstParagraph",
    defaultMessage:
      "Couples conversation with psychologist over video, 60 minutes",
    description: "Couples therapy conditions first paragraph",
  },
  couplesTherapyReadMoreConditionsSecondParagraph: {
    id: "HomeTabScreen.couplesTherapyReadMoreConditionsSecondParagraph",
    defaultMessage:
      "{boldWord}: All participants must sit together and use the same device during the sessions. We recommend using the app on a tablet or the {underlinedWord} through a browser on a PC or Mac.",
    description: "Couples therapy conditions second paragraph",
  },
  couplesTherapyReadMoreConditionsBoldWord: {
    id: "HomeTabScreen.couplesTherapyReadMoreConditionsBoldWord",
    defaultMessage: "Important",
    description: "Couples therapy conditions bold word",
  },
  couplesTherapyReadMoreConditionsUnderlinedWord: {
    id: "HomeTabScreen.couplesTherapyReadMoreConditionsUnderlinedWord",
    defaultMessage: "web app",
    description: "Couples therapy conditions underlined word",
  },
  userGreeting: {
    id: "HomeTabScreen.userGreeting",
    defaultMessage: "Hello, {firstName} 👋",
    description: "User greeting",
  },
});
