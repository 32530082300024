import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "@eyr-mobile/core/Intl";
import { Text, View } from "react-native";
import { ProductType } from "@eyr-mobile/domain/Order";

import { Paragraph } from "../Paragraph";

import { styles } from "./CancellationInfo.styles";
import { messages } from "./CancellationInfo.messages";

const getCancellationInfoMessage = (service) => {
  switch (service.order.product.type) {
    case ProductType.PSYCHOLOGIST_CONSULTATION:
      return messages.psychologistConsultationCancellationMessage;
    case ProductType.PHYSIOTHERAPIST_CONSULTATION:
      return messages.physiotherapistConsultationCancellationMessage;
  }
  switch (service.__typename) {
    case "DropInConsultationService":
      return messages.dropInConsultationCancellationMessage;
    case "PatientGuideService":
      return messages.patientGuideCancellationMessage;
  }
  return messages.consultationCancellationMessage;
};

export function CancellationInfo({ service }) {
  const { formatMessage } = useIntl();

  return (
    <>
      <View style={styles.heroContainer}>
        <Paragraph size="l" spacing={"l"}>
          {formatMessage(messages.howToCancelMessage)}:
        </Paragraph>
        <View style={styles.listItem}>
          <View style={styles.listItemNumber}>
            <Text style={styles.listItemNumberText}>1</Text>
          </View>
          <Paragraph>{formatMessage(messages.listItemName)}</Paragraph>
        </View>

        <View style={styles.listItem}>
          <View style={styles.listItemNumber}>
            <Text style={styles.listItemNumberText}>2</Text>
          </View>
          <Paragraph>{formatMessage(messages.listItemBirthDate)}</Paragraph>
        </View>

        <View style={styles.listItem}>
          <View style={styles.listItemNumber}>
            <Text style={styles.listItemNumberText}>3</Text>
          </View>
          <Paragraph>
            {formatMessage(messages.listItemConsultationTime)}
          </Paragraph>
        </View>
      </View>
      <View style={styles.finePrintContainer}>
        <Paragraph>
          {formatMessage(getCancellationInfoMessage(service))}
        </Paragraph>
      </View>
    </>
  );
}

CancellationInfo.propTypes = {
  onClose: PropTypes.func,
  service: PropTypes.shape({
    __typename: PropTypes.string,
    order: PropTypes.shape({
      product: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
};
