import { orderStageToFlowStage } from "@eyr-mobile/domain/Order";

import { AttachFormStageOrderSummaryItem } from "../AttachFormStageOrderSummaryItem";
import { SelectClientStageOrderSummaryItem } from "../SelectClientStageOrderSummaryItem";
import { SetPaymentMethodsStageSummaryItem } from "../SetPaymentMethodsStageSummaryItem";
import { FlowStageSetMedicalCategorySummaryItem } from "../FlowStageSetMedicalCategorySummaryItem";
import { FlowStageSpecifyAppointmentInformationSummaryItem } from "../FlowStageSpecifyAppointmentInformationSummaryItem";

const FlowStageToSummaryItemMap = {
  FlowStageAttachForm: AttachFormStageOrderSummaryItem,
  FlowStageSelectClient: SelectClientStageOrderSummaryItem,
  FlowStageSetPaymentMethods: SetPaymentMethodsStageSummaryItem,
  FlowStageSetMedicalCategory: FlowStageSetMedicalCategorySummaryItem,
  FlowStageSpecifyAppointmentInformation:
    FlowStageSpecifyAppointmentInformationSummaryItem,
};

export const getSummaryItemComponentForStage = (stage) => {
  return (
    FlowStageToSummaryItemMap[orderStageToFlowStage(stage.__typename)] || null
  );
};
