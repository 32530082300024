/**
 * Figma sources
 * {@link https://www.figma.com/file/uq4j7gSWFADFDNfy3DUSFD/Colors?node-id=14%3A1}
 */

const Palette_withFigmaAliases = {
  BURGUNDY_125: "#4D0521",
  BURGUNDY_100: "#5F0C2C",
  BURGUNDY_80: "#6C3240",
  BURGUNDY_60: "#895B66",
  BURGUNDY_40: "#A7848C",
  BURGUNDY_20: "#C4ADB3",
  BURGUNDY_0: "#E2D6D9",
  NUDE_200: "#C9653B",
  NUDE_175: "#D57B55",
  NUDE_150: "#E2916E",
  NUDE_125: "#EEA788",
  NUDE_100: "#FAB99C",
  NUDE_80: "#FFCEBE",
  NUDE_60: "#FFDFD4",
  NUDE_40: "#FFEFEA",
  NUDE_20: "#FBF4F2",
  NUDE_0: "#FFFBFA",
  NUDESHADE_20: "#F3ECEA",
  TURQUOISE_100: "#A7EDF4",
  TURQUOISE_80: "#BAF1F6",
  TURQUOISE_60: "#CCF4F9",
  TURQUOISE_40: "#DFF8FB",
  TURQUOISE_20: "#F2FCFD",
  TURQUOISE_0: "#F9FEFE",
  BLACK: "#000000",
  GRAY_200: "#141414",
  GRAY_175: "#1F1F1F",
  GRAY_150: "#333",
  GRAY_125: "#545454",
  GRAY_100: "#757575",
  GRAY_80: "#AFAFAF",
  GRAY_60: "#CBCBCB",
  GRAY_40: "#E2E2E2",
  GRAY_20: "#EEEEEE",
  GRAY_0: "#F6F6F6",
  WHITE: "#FFF",
  WHITE_20: "#FBFBFB",
  PINK_100: "#FC95E4",
  PINK_80: "#FDACEA",
  PINK_60: "#FEC3F0",
  PINK_40: "#FED9F5",
  PINK_20: "#FFF0FB",
  PINK_0: "#FFF8FD",
  RED_125: "#960C0C",
  RED_100: "#AD1B1B",
  RED_80: "#C24F4F",
  RED_60: "#D68484",
  RED_40: "#FFDFDF",
  RED_20: "#FFECEC",
  RED_0: "#FFF6F6",
  GREEN_125: "#004D3A",
  GREEN_100: "#005A45",
  GREEN_80: "#39816E",
  GREEN_60: "#72A997",
  GREEN_40: "#D1EDDA",
  GREEN_20: "#E4F7EA",
  GREEN_0: "#F2FBF4",
  OVERLAY_DARK_4: "rgba(0,0,0,0.04)",
  OVERLAY_DARK_8: "rgba(0,0,0,0.08)",
  OVERLAY_DARK_20: "rgba(0,0,0,0.2)",
  OVERLAY_DARK_40: "rgba(0,0,0,0.4)",
  OVERLAY_LIGHT_30: "rgba(255,255,255,0.3)",
  OVERLAY_LIGHT_20: "rgba(255,255,255,0.2)",
  OVERLAY_LIGHT_15: "rgba(255,255,255,0.15)",
  DARK_BLUE_125: "#133050",
  DARK_BLUE_100: "#103965",
  DARK_BLUE_80: "#1E4A72",
  DARK_BLUE_60: "#2C5B86",
  YELLOW_20: "#FFF8EC",
  YELLOW_100: "#725522",
  TRANSPARENT: "transparent",
};

if (!global.theme) {
  global.theme = {
    colors: Palette_withFigmaAliases,
    iconColors: Palette_withFigmaAliases,
  };
}

module.exports = {
  get Palette() {
    return {
      ...Palette_withFigmaAliases,
      ...(global.theme && global.theme.colors),
    };
  },
  get IconPalette() {
    return global.theme.iconColors;
  },
};
