import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib";
import { size, isNumber } from "lodash/fp";

import { Paragraph } from "../Paragraph";

import { styles, stylingHelpers, stylingProps } from "./Badge.styles";

function formatLabel(label, { max }) {
  if (isNumber(label)) {
    if (label > max) {
      return `${max}+`;
    }
    return String(label);
  }
  return label;
}

function getTextColor(variant) {
  switch (variant) {
    case "primary":
    case "secondary":
      return "primary";
    case "disabled":
      return "disabled";
    default:
      return "secondary";
  }
}

export function Badge({
  label,
  IconComponent,
  variant = "secondary",
  max = 99,
}) {
  if (!label) {
    return <View style={[styles.dot, styles[variant]]} />;
  }

  const formattedLabel = formatLabel(label, { max });
  return (
    <View
      style={[
        styles.container,
        stylingHelpers.getContainerStyle({
          variant,
          numChars: size(formattedLabel),
        }),
      ]}
    >
      {renderIf(IconComponent)(() => (
        <IconComponent
          {...stylingProps.iconSize}
          {...stylingProps[variant]}
          style={styles.icon}
        />
      ))}
      <Paragraph size={"s"} color={getTextColor(variant)}>
        {formattedLabel}
      </Paragraph>
    </View>
  );
}

Badge.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  IconComponent: PropTypes.elementType,
  variant: PropTypes.oneOf([
    "positive",
    "secondary",
    "informal",
    "negative",
    "primary",
    "disabled",
  ]),
  max: PropTypes.number,
};
