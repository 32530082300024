import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "@eyr-mobile/core/Intl";
import { reject, size } from "lodash/fp";
import { FlatList } from "react-native";
import { moneyShape } from "@eyr-mobile/domain/Order";

import { ListItemSeparator } from "../ListItemSeparator";
import { Paragraph } from "../Paragraph";
import { ListCatalogItem } from "../ListCatalogItem";

import { messages } from "./ProductsListCatalog.messages";
import { styles } from "./ProductsListCatalog.styles";

export function ProductsListCatalog({ onSelected, products, ...rest }) {
  const { formatMessage } = useIntl();

  const productsToShow = useMemo(
    () => reject({ availabilityState: "unavailable" }, products),
    [products]
  );
  return (
    <FlatList
      contentContainerStyle={
        size(productsToShow) === 0 && styles.noProductsContentContainer
      }
      data={productsToShow}
      renderItem={({ item }) => {
        const { name, image, price, availabilityState } = item;
        return (
          <ListCatalogItem
            style={styles.listCatalogItemContainer}
            title={name}
            image={image}
            price={price}
            accessibilityLabel={"Product"}
            accessibilityHint={`Selects ${name} product`}
            onPress={() => onSelected(item)}
            disabled={availabilityState === "pending_usage"}
          />
        );
      }}
      keyExtractor={(item) => item.id.toString()}
      ItemSeparatorComponent={ListItemSeparator}
      ListEmptyComponent={
        <Paragraph size="l" alignment="center">
          {formatMessage(messages.noProducts)}
        </Paragraph>
      }
      {...rest}
    />
  );
}

ProductsListCatalog.propTypes = {
  onSelected: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      availabilityState: PropTypes.oneOf([
        "available",
        "pending_usage",
        "unavailable",
      ]),
      price: moneyShape,
    })
  ),
};
