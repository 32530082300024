import React, {
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";
import { noop } from "lodash/fp";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import PropTypes from "prop-types";
import { useSubscription, useMutation } from "@eyr-mobile/core/DataProvider";
import {
  hasAction as hasStoreReviewAction,
  requestReview as requestStoreReview,
} from "expo-store-review";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useAlert } from "@eyr-mobile/core/Alert";
import { usePrevious } from "@eyr-mobile/core/Lib";
import { useAuth, AuthState } from "@eyr-mobile/domain/Auth";
import { useFeatureFlag } from "@eyr-mobile/core/Config";

import { ReviewRequestedSubscription, SubmitReview } from "./Review.data";

const logger = LoggerFactory.get("domain/Review");

export const ReviewState = {
  REQUESTED: "requested",
  SUBMITTED: "submitted",
  CANCELLED: "canceled",
  FINISHED: "finished",
};
export const NEUTRAL_RATING = 3;
const reviewStateDefaults = {
  pendingReviewRequests: [],
  activeReviewRequest: null,
  activeReview: null,
};

export const ReviewContext = createContext(reviewStateDefaults);

const isMarketplaceReviewRequest = (reviewRequest) =>
  reviewRequest.__typename === "MarketplaceReviewRequest";
const isConsultationReviewRequest = (reviewRequest) =>
  reviewRequest.__typename === "ConsultationReviewRequest";

export function ReviewProvider({
  children,
  initialState,
  onStartReview = noop,
  onEndReview = noop,
  buildReviewSuccessAlert = noop,
}) {
  const initialStateWithDefaults = {
    ...reviewStateDefaults,
    ...initialState,
  };
  const [
    { pendingReviewRequests, activeReviewRequest, activeReview },
    setReview,
  ] = useState(initialStateWithDefaults);
  const alert = useAlert();
  const intl = useIntl();
  const { state: authState } = useAuth();
  const prevActiveReviewState = usePrevious(activeReview?.state);

  const [submitReview] = useMutation(SubmitReview);
  const reviewIsEnabled = useFeatureFlag("FEATURE_SATISFACTION_REVIEW");

  useSubscription(ReviewRequestedSubscription, {
    skip: authState !== AuthState.AUTHENTICATED || !reviewIsEnabled,
    onData: ({
      data: {
        data: { reviewRequested },
      },
    }) => {
      setReview((review) => ({
        ...review,
        pendingReviewRequests: [...pendingReviewRequests, reviewRequested],
      }));
    },
  });

  const handleSubmitActiveReview = useCallback(() => {
    setReview((review) => ({
      ...review,
      activeReview: {
        ...review.activeReview,
        state: ReviewState.SUBMITTED,
      },
    }));
  }, []);

  const handleCancelActiveReview = useCallback(() => {
    setReview((review) => ({
      ...review,
      activeReview: {
        ...review.activeReview,
        state: ReviewState.CANCELLED,
      },
    }));
  }, []);

  const handleUpdateActiveReview = useCallback((activeReviewUpdatedFields) => {
    setReview((review) => ({
      ...review,
      activeReview: { ...review.activeReview, ...activeReviewUpdatedFields },
    }));
  }, []);

  const handleOpenMarketplaceReview = useCallback(async () => {
    if (await hasStoreReviewAction()) {
      await requestStoreReview();
    }
    setReview((review) => ({
      ...review,
      activeReview: {
        ...review.activeReview,
        state: ReviewState.SUBMITTED,
      },
    }));
  }, []);

  useEffect(() => {
    if (pendingReviewRequests.length && !activeReviewRequest) {
      const [nextReviewRequest, ...rest] = pendingReviewRequests;
      setReview((review) => ({
        ...review,
        pendingReviewRequests: rest,
        activeReviewRequest: nextReviewRequest,
        activeReview: {
          reviewRequestId: nextReviewRequest.id,
          categories: [],
          state: ReviewState.REQUESTED,
        },
      }));
    }
  }, [activeReviewRequest, pendingReviewRequests]);

  useEffect(() => {
    if (!activeReview) {
      return;
    }
    if (
      prevActiveReviewState !== activeReview.state &&
      activeReview.state === ReviewState.REQUESTED
    ) {
      if (isMarketplaceReviewRequest(activeReviewRequest)) {
        handleOpenMarketplaceReview();
      } else {
        onStartReview();
      }
    }
  }, [
    activeReviewRequest,
    prevActiveReviewState,
    onStartReview,
    activeReview,
    handleOpenMarketplaceReview,
  ]);

  useEffect(() => {
    if (!activeReview) {
      return;
    }
    if (
      prevActiveReviewState !== activeReview.state &&
      activeReview.state === ReviewState.SUBMITTED
    ) {
      const input = {
        id: activeReview.reviewRequestId,
        state: ReviewState.SUBMITTED,
      };
      if (isConsultationReviewRequest(activeReviewRequest)) {
        input.rating = activeReview.rating;
        input.notes = activeReview.notes;
        if (activeReview.rating <= NEUTRAL_RATING) {
          input.categories = activeReview.categories;
        }
      }
      submitReview({
        variables: {
          input,
        },
        onCompleted: () => {
          if (isConsultationReviewRequest(activeReviewRequest)) {
            alert(
              buildReviewSuccessAlert({
                review: activeReview,
                onClose: () => {
                  setReview((review) => ({
                    ...review,
                    activeReview: {
                      ...review.activeReview,
                      state: ReviewState.FINISHED,
                    },
                  }));
                },
              })
            );
          }
        },
      });
    }
  }, [
    activeReview,
    submitReview,
    activeReviewRequest,
    alert,
    intl,
    buildReviewSuccessAlert,
    prevActiveReviewState,
  ]);

  useEffect(() => {
    if (!activeReview) {
      return;
    }
    if (
      prevActiveReviewState !== activeReview.state &&
      activeReview.state === ReviewState.CANCELLED
    ) {
      submitReview({
        variables: {
          input: {
            id: activeReview.reviewRequestId,
            state: ReviewState.CANCELLED,
          },
        },
      });
      setReview((review) => ({
        ...review,
        activeReview: {
          ...review.activeReview,
          state: ReviewState.FINISHED,
        },
      }));
    }
  }, [activeReview, submitReview, prevActiveReviewState]);

  useEffect(() => {
    if (!activeReview) {
      return;
    }
    if (
      prevActiveReviewState !== activeReview.state &&
      activeReview.state === ReviewState.FINISHED
    ) {
      if (!isMarketplaceReviewRequest(activeReviewRequest)) {
        onEndReview();
      }
      setReview((review) => ({
        ...review,
        activeReview: null,
        activeReviewRequest: null,
        state: null,
      }));
    }
  }, [activeReview, prevActiveReviewState, activeReviewRequest, onEndReview]);

  const value = useMemo(
    () => ({
      activeReview,
      updateActiveReview: handleUpdateActiveReview,
      activeReviewRequest,
      submitActiveReview: handleSubmitActiveReview,
      cancelActiveReview: handleCancelActiveReview,
    }),
    [
      activeReview,
      handleUpdateActiveReview,
      activeReviewRequest,
      handleSubmitActiveReview,
      handleCancelActiveReview,
    ]
  );

  return (
    <ReviewContext.Provider value={value}>{children}</ReviewContext.Provider>
  );
}

ReviewProvider.propTypes = {
  children: PropTypes.node.isRequired,
  onStartReview: PropTypes.func,
  onEndReview: PropTypes.func,
  initialState: PropTypes.shape({
    pendingReviewRequests: PropTypes.array,
    activeReview: PropTypes.object,
  }),
  buildReviewSuccessAlert: PropTypes.func,
};

export function useReview() {
  const review = useContext(ReviewContext);
  if (review === undefined) {
    logger(
      "useReview: Couldn't find a review object. Is your component inside a ReviewProvider?",
      review,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return review;
}
