import React, { useMemo, useCallback } from "react";
import { useIntl, useFormattedRelativeTimeTo } from "@eyr-mobile/core/Intl";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { GetInbox } from "@eyr-mobile/domain/Conversation";
import { View, SectionList, Platform } from "react-native";
import { isFontScaleLarge } from "@eyr-mobile/core/Lib/font";
import { useNavigation } from "@react-navigation/native";
import PropTypes from "prop-types";
import { AuthPermission, useAuth } from "@eyr-mobile/domain/Auth";
import { useDevice } from "@eyr-mobile/core/Device";
import { setBadgeCountAsync } from "@eyr-mobile/core/Notifications";
import { useAppState } from "@eyr-mobile/core/AppState";

import { SVGs } from "../../res";
import {
  ListItem,
  ProfileImage,
  Empty,
  SensitiveContent,
  InboxTabIcon,
  Badge,
  Card,
  Subtitle,
  Paragraph,
} from "../../components";

import { styles, tabBarBadgeStyle } from "./InboxTabScreen.styles";
import { messages } from "./InboxTabScreen.messages";

const hasLargeFontScale = isFontScaleLarge();

const resetAppBadgeCount = () => setBadgeCountAsync(0);

const ConversationThumbnail = ({ placeholder, imageUrl }) => {
  return <ProfileImage placeholder={placeholder} uri={imageUrl} />;
};

ConversationThumbnail.propTypes = {
  placeholder: PropTypes.string,
  imageUrl: PropTypes.string,
};

const InboxListItem = ({
  onPress,
  title,
  avatar,
  unseenCount,
  lastUpdatedAt,
}) => {
  const [timeAgo, { unit, value }] = useFormattedRelativeTimeTo(lastUpdatedAt);
  const { formatDate } = useIntl();
  const LastUpdatedAtComponent = unseenCount ? Subtitle : Paragraph;

  return (
    <Card
      onPress={onPress}
      title={title}
      titleIsBold={Boolean(unseenCount)}
      description={
        lastUpdatedAt && (
          <LastUpdatedAtComponent>
            {unit === "day" && value < -1 ? formatDate(lastUpdatedAt) : timeAgo}
          </LastUpdatedAtComponent>
        )
      }
      icon={
        <View style={styles.listItemImage}>{!hasLargeFontScale && avatar}</View>
      }
      badge={
        Boolean(unseenCount) && <Badge label={unseenCount} variant="primary" />
      }
    />
  );
};

InboxListItem.propTypes = {
  onPress: PropTypes.func.isRequired,
  avatar: PropTypes.node,
  title: PropTypes.string.isRequired,
  unseenCount: PropTypes.number,
  lastUpdatedAt: PropTypes.string,
};

export function InboxTabScreen() {
  const { formatMessage } = useIntl();
  const { handlers, data, refetch, refreshing } = withHandlers(
    useQuery(GetInbox)
  );
  const refetchOnActiveFromBackground = useCallback(
    (previousAppState) => {
      if (previousAppState === "inactive") {
        return;
      }
      refetch();
    },
    [refetch]
  );

  useAppState({
    skip: Platform.OS === "web",
    onActive: refetchOnActiveFromBackground,
  });
  const { navigate } = useNavigation();
  const { ensureAll, initializeIdentification } = useAuth();
  const { screenSizeSelect } = useDevice();

  const handleInitializeIdentification = useCallback(() => {
    initializeIdentification({ returnRoute: "InboxTabScreen" });
  }, [initializeIdentification]);

  const hasAuthPermission = useMemo(
    () => ensureAll([AuthPermission.FORMS, AuthPermission.CONVERSATIONS]),
    [ensureAll]
  );

  const handleNavigateToConversationScreen = useCallback(
    ({ id }) => {
      navigate("ConversationScreen", { id });
    },
    [navigate]
  );

  const sections = useMemo(() => {
    if (!data) {
      return null;
    }
    return [
      {
        data: [
          {
            key: "documents",
            title: formatMessage(messages.documents),
            icon: (
              <SVGs.Documents
                width={ListItem.lineHeight}
                height={ListItem.lineHeight}
              />
            ),
            badgeCounter: data.inboxDocuments.unseenCount,
            navigateTo: "DocumentsScreen",
          },
          {
            key: "digitalTools",
            title: formatMessage(messages.digitalTools),
            icon: (
              <SVGs.TreatmentTools
                width={ListItem.lineHeight}
                height={ListItem.lineHeight}
              />
            ),
            badgeCounter:
              (data.inboxAccountDigitalTools.unseenCountForms || 0) +
              (data.inboxAccountDigitalTools.unseenCountContent || 0),
            navigateTo: "DigitalToolsScreen",
          },
        ],
        keyExtractor: (item) => item.key,

        renderItem: ({ item }) => {
          const { title, icon, badgeCounter, navigateTo } = item;
          return (
            <InboxListItem
              onPress={() => navigate(navigateTo)}
              avatar={icon}
              title={title}
              unseenCount={badgeCounter}
            />
          );
        },
      },
      {
        data: data.inboxConversations,
        keyExtractor: (item) => item.id,

        renderItem: ({ item }) => {
          const { title, thumbnailImageUrl, unseenCount, lastUpdatedAt } = item;
          return (
            <InboxListItem
              onPress={() => handleNavigateToConversationScreen(item)}
              avatar={
                <ConversationThumbnail
                  placeholder={title}
                  imageUrl={thumbnailImageUrl}
                />
              }
              title={title}
              lastUpdatedAt={lastUpdatedAt}
              unseenCount={unseenCount}
            />
          );
        },
      },
    ];
  }, [data, formatMessage, navigate, handleNavigateToConversationScreen]);

  return (
    <View style={styles.container}>
      {hasAuthPermission ? (
        handlers || (
          <>
            <SectionList
              contentContainerStyle={screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              })}
              sections={sections}
              onRefresh={refetch}
              refreshing={refreshing}
              onLayout={resetAppBadgeCount}
            />
            {!data.inboxConversations.length && (
              <>
                <Empty
                  iconName="Email"
                  message={formatMessage(messages.noMessages)}
                />
              </>
            )}
          </>
        )
      ) : (
        <SensitiveContent
          onInitializeIdentification={handleInitializeIdentification}
        />
      )}
    </View>
  );
}

InboxTabScreen.routeName = "InboxTabScreen";
InboxTabScreen.navigationOptions = {
  title: messages.navigationTitle,
  tabBarIcon: InboxTabIcon,
  tabBarBadgeStyle, // TODO: move to navigation
};
