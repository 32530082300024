import React from "react";
import { NEUTRAL_RATING } from "@eyr-mobile/domain/Review";
import { intlRef } from "@eyr-mobile/core/Intl";
import { noop } from "lodash/fp";
import { ProductType } from "@eyr-mobile/domain/Order";
import { Platform } from "react-native";

import { SVGs } from "../res/svgs";
import { IconContainer } from "../components";

import { messages } from "./Alerts.messages";

export function buildReviewSuccessAlert({ review: { rating }, onClose }) {
  const { formatMessage } = intlRef.current;
  return {
    icon: (
      <IconContainer size="l">
        <SVGs.Advice />
      </IconContainer>
    ),
    title: formatMessage(messages.reviewSuccessAlertTitle),
    message:
      rating <= NEUTRAL_RATING
        ? formatMessage(messages.reviewSuccessWithBadRatingAlertBody)
        : null,
    onClose,
    buttons: [
      {
        title: formatMessage(messages.closeActionTitle),
        onPress: noop,
      },
    ],
  };
}

export function buildCompletedOrderAlert({
  recentlyCompletedOrderType,
  account,
  onSecondaryActionPress,
}) {
  const { formatMessage } = intlRef.current;
  const completedOrderAlertIcon = (
    <IconContainer size="xl" variant="round">
      <SVGs.OrderConfirmation />
    </IconContainer>
  );
  if (
    Platform.OS === "web" &&
    [
      ProductType.CONSULTATION,
      ProductType.PSYCHOLOGIST_CONSULTATION,
      ProductType.GUIDE_CONSULTATION,
    ].includes(recentlyCompletedOrderType)
  ) {
    return {
      icon: completedOrderAlertIcon,
      title: formatMessage(messages.completedOrderAlertTitle),
      message: formatMessage(messages.completedOrderAlertMessage, account),
      buttonsLayout: "column",
      buttons: [
        {
          title: formatMessage(messages.completedOrderAlertConfirmActionTitle),
          variant: "primary",
        },
        {
          title: formatMessage(
            messages.completedOrderAlertChangeContactActionTitle
          ),
          variant: "secondary",
          onPress: onSecondaryActionPress,
        },
      ],
    };
  } else {
    return {
      icon: completedOrderAlertIcon,
      title: formatMessage(messages.completedOrderAlertTitle),
      showCloseButton: true,
    };
  }
}
