import React from "react";
import { View } from "react-native";
import { get } from "lodash/fp";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useAuth } from "@eyr-mobile/domain/Auth";
import {
  useAttachFormFlowStage,
  useOrderFlowStageParams,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";
import { withHandlers } from "@eyr-mobile/core/DataProvider";

import {
  ProgressIndicator,
  FillForm,
  CancelOrderHeaderRight,
} from "../../components";

import { styles } from "./FlowStageAttachFormScreen.styles";
import { messages } from "./FlowStageAttachFormScreen.messages.js";

export function FlowStageAttachFormScreen() {
  const { accessToken } = useAuth();
  const { screenSizeSelect } = useDevice();

  const { stageParams: { params, progress } = {}, handlers } = withHandlers(
    useOrderFlowStageParams()
  );
  const [{ attachFormDataId, recentDataId }] = useAttachFormFlowStage();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      {handlers || (
        <>
          <ProgressIndicator value={progress} />
          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
          >
            <FillForm
              key={recentDataId}
              url={get("formUrl", params)}
              accessToken={accessToken}
              onSucceeded={attachFormDataId}
            />
          </View>
        </>
      )}
    </SafeAreaView>
  );
}
FlowStageAttachFormScreen.routeName = "FlowStageAttachFormScreen";
FlowStageAttachFormScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
