import muscleSkeleton from "./muscleSkeleton.png";
import pregnancyChild from "./pregnancyChild.png";
import headacheMigraine from "./headacheMigraine.png";
import coldFever from "./coldFever.png";
import medicalAdvice from "./medicalAdvice.png";
import allergy from "./allergy.png";
import digestion from "./digestion.png";
import skinProblems from "./skinProblems.png";
import eyeInfection from "./eyeInfection.png";
import urinaryTractInfection from "./urinaryTractInfection.png";
import addDocument from "./addDocument.png";
import testTube from "./testTube.png";
import acidReflux from "./acidReflux.png";
import prevention from "./prevention.png";
import erectileDysfunction from "./erectileDysfunction.png";
import children from "./children.png";
import asthma from "./asthma.png";
import earPain from "./earPain.png";
import vaccination from "./vaccination.png";
import psychology from "./psychology.png";
import sinusitis from "./sinusitis.png";
import medicine from "./medicine.png";
import fear from "./Fear.png";
import headache from "./Headache.png";
import insomnia from "./Insomnia.png";
import lifeEvents from "./LifeEvents.png";
import lowSelfEsteem from "./LowSelf-esteem.png";
import Doctor from "./Doctor.png";
import Psychologist from "./Psychologist.png";
import Vaccine from "./Vaccine.png";
import vaccinesCover from "./vaccines.jpg";

export const images = {
  muscleSkeleton,
  pregnancyChild,
  headacheMigraine,
  coldFever,
  medicalAdvice,
  allergy,
  digestion,
  skinProblems,
  eyeInfection,
  urinaryTractInfection,
  acidReflux,
  prevention,
  erectileDysfunction,
  asthma,
  insomnia,
  vaccination,
  psychology,
  children,
  sinusitis,
  addDocument,
  testTube,
  earPain,
  medicine,
  fear,
  headache,
  lifeEvents,
  lowSelfEsteem,
  Doctor,
  Psychologist,
  Vaccine,
  vaccinesCover,
};
