import { useCallback, useEffect, useState, useRef } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { registerStorybookToggler } from "./Storybook.helpers";

const prefix = "@Eyr:";
const STORAGE_KEY_STORYBOOK_ACTIVE = `${prefix}StorybookActive`;

export const useStorybook = (initialState) => {
  const [storybookActive, setStorybookActive] = useState(initialState);
  const stateChangedRef = useRef(false);

  const toggleStorybook = useCallback(() => {
    stateChangedRef.current = true;
    setStorybookActive((active) => !active);
  }, []);

  useEffect(() => {
    if (!stateChangedRef.current) {
      return;
    }
    AsyncStorage.setItem(
      STORAGE_KEY_STORYBOOK_ACTIVE,
      JSON.stringify(storybookActive)
    );
  }, [storybookActive]);

  useEffect(() => {
    if (!__DEV__) {
      return;
    }
    return registerStorybookToggler(toggleStorybook);
  }, [toggleStorybook]);

  useEffect(() => {
    const handler = async () => {
      try {
        const storedValue = await AsyncStorage.getItem(
          STORAGE_KEY_STORYBOOK_ACTIVE
        );
        if (storedValue != null) {
          setStorybookActive(JSON.parse(storedValue));
        }
      } catch (e) {
        // error reading value
      }
    };
    handler();
  }, []);

  return storybookActive;
};
