import React, { useState, useCallback } from "react";
import { Switch } from "react-native";

import { styles, stylingProps } from "./EyrSwitch.styles";

export function EyrSwitch(props) {
  const [value, setValue] = useState(false);
  const controlledMode = Boolean(props.value);
  const _onValueChange = useCallback(
    (value) => {
      if (!controlledMode) {
        setValue(value);
      }
      if (props.onValueChange) {
        props.onValueChange(value);
      }
    },
    [controlledMode, props]
  );

  return (
    <Switch
      value={controlledMode ? props.value : value}
      {...props}
      onValueChange={_onValueChange}
      {...stylingProps}
      style={styles.container}
    />
  );
}
