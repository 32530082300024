import { PixelRatio, Platform, StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  videoOuterContainer: {
    position: "absolute",
    backgroundColor: Palette.BURGUNDY_125,
    borderRadius: 12,
    justifyContent: "flex-end",
    ...Shadows.xl,
    top: 32,
    right: 32,
    aspectRatio: 3 / 5,
  },
  videoOuterContainerXS: {
    minWidth: 64,
    maxWidth: 120,
    minHeight: 116,
  },
  videoOuterContainerS: {
    width: 234,
    aspectRatio: 16 / 9,
  },
  videoInnerContainer: {
    flex: 1,
    overflow: "hidden",
    borderRadius: 12,
  },
  videoExpanded: Platform.select({
    default: StyleSheet.absoluteFill,
    web: StyleSheet.absoluteFillObject,
  }),
  videoCollapsed: {
    ...StyleSheet.absoluteFillObject,
    height: 0,
    width: 0,
  },
  videoStateOverlayContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    paddingHorizontal: 12,
    paddingBottom: 12,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  videoStateIconContainer: {
    backgroundColor: Palette.OVERLAY_LIGHT_20,
    width: 40,
    height: 40,
    borderRadius: 8,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 12,
    marginRight: 8,
  },
});

export const stylingProps = {
  videoStateIcon: {
    fill: Palette.WHITE,
  },
  video: {
    height: "100%",
    width: "100%",
    fitMode: "cover",
    ...Platform.select({
      web: {
        showControls: false,
      },
    }),
  },
};

export const stylingHelpers = {
  getVideoOuterContainerStylesPhoneXS: (screenHeight, screenWidth) => ({
    width: PixelRatio.roundToNearestPixel(screenWidth * 0.35),
    maxHeight: PixelRatio.roundToNearestPixel(screenHeight * 0.25),
  }),
  getVideoOuterContainerStyle(insets) {
    return [styles.videoOuterContainer, insets.top && { top: 32 + insets.top }];
  },
};
