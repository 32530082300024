import { defineMessages } from "react-intl";

export const messages = defineMessages({
  headerText: {
    id: "IssueBox.headerText",
    description: "Headertext for issuebox",
    defaultMessage: "Add your information",
  },
  noContactInfo: {
    id: "IssueBox.noContactInfo",
    description: "Text for when customer has no contact information stored",
    defaultMessage:
      "Make it easier for your doctor or psychologist to contact you.",
  },
  noContactBtnTitle: {
    id: "IssueBox.noContactBtnTitle",
    description: "Add contact info button title",
    defaultMessage: "Add contact details",
  },
  noInsuranceInfo: {
    id: "IssueBox.noInsuranceInfo",
    description: "Text for when customer has no insurance info stored",
    defaultMessage:
      "Get the consultation fully or partially covered by insurance or membership.",
  },
  noInsuranceBtnTitle: {
    id: "IssueBox.noInsuranceBtnTitle",
    description: "Add insurance info button title",
    defaultMessage: "Add insurance",
  },
});
