import { useState, useEffect } from "react";
import * as LocalAuthentication from "expo-local-authentication";

import { LoggerFactory, LOGGER_LEVEL_ERROR } from "../Logger";

const logger = LoggerFactory.get("core/Biometry");

export const isEnrolledForBiometry = async () => {
  const [hasHardware, isEnrolled] = await Promise.all([
    LocalAuthentication.hasHardwareAsync(),
    LocalAuthentication.isEnrolledAsync(),
  ]);
  return hasHardware && isEnrolled;
};

export const authenticateAsync = LocalAuthentication.authenticateAsync;
export const cancelAuthenticateAsync = LocalAuthentication.cancelAuthenticate;

export const useBiometry = () => {
  const [loading, setLoading] = useState(true);
  const [hasEnrolledHardware, setHasEnrolledHardware] = useState(null);

  useEffect(() => {
    const checkIfSupportsBiometry = async () => {
      try {
        const supported = await isEnrolledForBiometry();
        setHasEnrolledHardware(supported);
      } catch (error) {
        logger("Biometry error", error, LOGGER_LEVEL_ERROR);
      } finally {
        setLoading(false);
      }
    };

    checkIfSupportsBiometry();
  }, []);

  return {
    loading,
    hasEnrolledHardware,
    authenticateAsync,
    cancelAuthenticateAsync,
  };
};
