import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "DocumentsScreen.navigationTitle",
    description: "the title for the documents screen",
    defaultMessage: "Documents",
  },
  noDocuments: {
    id: "DocumentsScreen.noDocuments",
    description: "when you have documents to display",
    defaultMessage: "You have no documents",
  },
});
