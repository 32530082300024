import { defineMessages } from "react-intl";

import { ProductType } from "../../../domain/Order";

export const messages = defineMessages({
  child: {
    id: "FamilyMemberClientPicker.child",
    description: "Child",
    defaultMessage: "Child",
  },
  addChild: {
    id: "FamilyMemberClientPicker.addChild",
    description: "Add child",
    defaultMessage: "Add child",
  },
  myself: {
    id: "FamilyMemberClientPicker.myself",
    description: "Myself",
    defaultMessage: "Myself",
  },
  whoIs: {
    id: "FamilyMemberClientPicker.whoIs",
    description: "Who is the {productType} for?",
    defaultMessage: "Who is the {productType} for?",
  },
  childrenUnderAge: {
    id: "FamilyMemberClientPicker.childrenUnderAge",
    description: "Add underage child description",
    defaultMessage: "Add a child under {consentAge} years old",
  },
  [ProductType.VACCINATION]: {
    id: "ProductType.VACCINATION",
    description: "Common name for vaccination products group",
    defaultMessage: "vaccination",
  },
  [ProductType.CONSULTATION]: {
    id: "ProductType.CONSULTATION",
    description: "Common name for consultation products group",
    defaultMessage: "consultation",
  },
  [ProductType.PSYCHOLOGIST_CONSULTATION]: {
    id: "ProductType.PSYCHOLOGIST_CONSULTATION",
    description: "Common name for consultation products group",
    defaultMessage: "consultation",
  },
  [ProductType.GUIDE_CONSULTATION]: {
    id: "ProductType.GUIDE_CONSULTATION",
    description: "Common name for consultation products group",
    defaultMessage: "consultation",
  },
  default: {
    id: "ProductType.DEFAULT",
    description: "Default product name",
    defaultMessage: "product",
  },
});
