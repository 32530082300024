import { defineMessages } from "react-intl";

export const messages = defineMessages({
  myself: {
    id: "SelectClientStageOrderSummaryItem.myself",
    description: "Order is for me",
    defaultMessage: "Myself",
  },
  otherPerson: {
    id: "SelectClientStageOrderSummaryItem.otherPerson",
    description: "Order is for child",
    defaultMessage: "Child",
  },
});
