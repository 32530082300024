export const VALIDATION_ERROR_DO_NOT_MATCH = "VALIDATION_ERROR_DO_NOT_MATCH";
export const VALIDATION_ERROR_NOT_NUMERIC = "VALIDATION_ERROR_NOT_NUMERIC";
export const VALIDATION_ERROR_REPEATING = "VALIDATION_ERROR_REPEATING";
export const VALIDATION_ERROR_SEQUENTIAL = "VALIDATION_ERROR_SEQUENTIAL";
export const VALIDATION_ERROR_MIN_LENGTH = "VALIDATION_ERROR_MIN_LENGTH";
export const VALIDATION_ERROR_MAX_LENGTH = "VALIDATION_ERROR_MAX_LENGTH";
export const VALIDATION_ERROR_EXACT_LENGTH = "VALIDATION_ERROR_EXACT_LENGTH";
export const VALIDATION_ERROR_BLANK = "VALIDATION_ERROR_BLANK";
export const VALIDATION_ERROR_NOT_INTEGER = "VALIDATION_ERROR_NOT_INTEGER";
export const VALIDATION_ERROR_INVALID_EMAIL = "VALIDATION_ERROR_INVALID_EMAIL";
export const VALIDATION_ERROR_INVALID_PHONE = "VALIDATION_ERROR_INVALID_PHONE";
