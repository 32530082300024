import React, { useEffect } from "react";
import { View } from "react-native";
import { useRoute } from "@react-navigation/native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import {
  useSetPaymentMethodsFlowStage,
  useOrderFlowStage,
} from "@eyr-mobile/domain/Order";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { useDevice } from "@eyr-mobile/core/Device";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "@eyr-mobile/core/Logger";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { GetAddAccountInsuranceOrDiscountData } from "@eyr-mobile/domain/Account";

import {
  ProgressIndicator,
  CancelOrderHeaderRight,
  AddInsuranceOrDiscount,
} from "../../components";

import { styles } from "./FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.styles";
import { messages } from "./FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.messages.js";

const logger = LoggerFactory.get("screens/AddAccountInsuranceOrDiscountScreen");

export function FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen() {
  const { accessToken } = useAuth();
  const [{ maybeSelectInsuranceOrMembershipByIds }] =
    useSetPaymentMethodsFlowStage();

  const route = useRoute();
  const slug = route.params?.slug;
  const {
    handlers,
    data,
    stageParams: { progress, progressPerStage } = {},
  } = withHandlers(
    useOrderFlowStage(GetAddAccountInsuranceOrDiscountData, {
      variables: { slug },
    })
  );

  useEffect(() => {
    if (!slug) {
      logger("expects to have route.params.slug set", slug, LOGGER_LEVEL_WARN);
    }
  }, [slug]);

  const progressWithinSubStages = progress + (2 / 3) * progressPerStage;
  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
          >
            <AddInsuranceOrDiscount
              accessToken={accessToken}
              onSucceeded={maybeSelectInsuranceOrMembershipByIds}
              specificInsuranceOrDiscountUrl={data?.org?.newPolicyUrl}
            />
          </View>
        </>
      )}
    </SafeAreaView>
  );
}
FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.routeName =
  "FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen";
FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
