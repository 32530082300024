import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 20,
    paddingHorizontal: 64,
    justifyContent: "center",
    alignItems: "center",
    flexGrow: 100,
  },
  text: {
    marginTop: 16,
  },
  iconBackground: {
    width: 88,
    height: 88,
    borderRadius: 44,
    backgroundColor: Palette.NUDE_20,
    justifyContent: "center",
    alignItems: "center",
  },
});

export const stylingProps = {
  icon: {
    fill: Palette.GRAY_40,
    width: 40,
    height: 40,
  },
};
