import React, { Children, cloneElement } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";

import { stylingHelpers } from "./IconContainer.styles";

export function IconContainer({
  size = "m",
  color = "primary",
  variant = "round",
  children,
}) {
  const containerStyle = stylingHelpers.getContainerStyle({
    variant,
    size,
    color,
  });
  const iconStylingProps = stylingHelpers.getIconStylingProps({
    variant,
    size,
    color,
  });

  const child = Children.only(children);

  return (
    <View style={containerStyle}>
      {cloneElement(child, {
        ...iconStylingProps,
        ...child.props,
      })}
    </View>
  );
}

IconContainer.propTypes = {
  size: PropTypes.oneOf(["xxs", "xs", "s", "m", "l", "xl"]),
  color: PropTypes.oneOf(["primary", "dark", "danger"]),
  variant: PropTypes.oneOf(["round", "rounded", "plain"]),
  children: PropTypes.node.isRequired,
};
