import { defineMessages } from "react-intl";

export const messages = defineMessages({
  noAvailableAppointments: {
    id: "Schedule.noAvailableAppointments",
    description: "Empty schedule list message",
    defaultMessage: "No available appointments",
  },
  noAvailablePsychologistAppointments: {
    id: "Schedule.noAvailablePsychologistAppointments",
    description: "Empty psychologist schedule list message",
    defaultMessage:
      "Our psychologists are currently very busy and have no available hours, unfortunately. We will update the app with new time slots as soon as we have capacity to take on new patients.",
  },
});
