import { logger } from "./Logger";

export const LoggerFactory = {
  /**
   * creates scoped logger
   * @param kind
   * @returns {function(string=, *=, string=): *}
   */
  get(kind) {
    /**
     * @param {string} msg
     * @param {any} data
     * @param {string} level
     */
    return (msg, data, level) => {
      return logger(kind, msg, data, level);
    };
  },
};
