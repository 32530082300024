import React from "react";
import { View } from "react-native";
import PropTypes from "prop-types";

import { styles } from "./UnorderedList.styles";

export function UnorderedList({ children, listItemIcon }) {
  return React.Children.map(children, (child) => {
    return (
      <View style={styles.listContainer}>
        {listItemIcon}
        {child}
      </View>
    );
  });
}

UnorderedList.propTypes = {
  children: PropTypes.node.isRequired,
  listItemIcon: PropTypes.node,
};
