import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { map, size, noop } from "lodash/fp";
import { renderIf } from "@eyr-mobile/core/Lib";

import { AppointmentConsultationServiceCard } from "../AppointmentConsultationServiceCard";
import { DropInConsultationServiceCard } from "../DropInConsultationServiceCard";
import { VaccinationServiceCard } from "../VaccinationServiceCard";
import { PatientGuideServiceCard } from "../PatientGuideServiceCard";

import { styles } from "./ServicesList.styles";

const getCardForService = (
  service,
  {
    onServiceSelected,
    onPreparationInfoForServiceRequested,
    onFindPharmacyRequested,
    onServiceDismissed,
    onDigitalToolSelected,
    onCancellationInfoForServiceRequested,
    onDocumentListForServiceRequested,
  }
) => {
  switch (service.__typename) {
    case "AppointmentConsultationService":
      return [
        AppointmentConsultationServiceCard,
        {
          onPress: () => onServiceSelected(service),
          onDismissPress: () => onServiceDismissed(service),
          onPreparationNoticePress: () =>
            onPreparationInfoForServiceRequested(service),
          onDigitalToolSelected: () =>
            onDigitalToolSelected(service.digitalTool),
          onCancellationInfoPress: () =>
            onCancellationInfoForServiceRequested(service),
        },
      ];
    case "DropInConsultationService":
      return [
        DropInConsultationServiceCard,
        {
          onPress: () => onServiceSelected(service),
          onDismissPress: () => onServiceDismissed(service),
          onPreparationNoticePress: () =>
            onPreparationInfoForServiceRequested(service),
          onCancellationInfoPress: () =>
            onCancellationInfoForServiceRequested(service),
        },
      ];
    case "VaccinationService":
      return [
        VaccinationServiceCard,
        {
          onPress: () => onServiceSelected(service),
          onDismissPress: () => onServiceDismissed(service),
          onFindPharmacyPress: onFindPharmacyRequested,
        },
      ];
    case "PatientGuideService":
      return [
        PatientGuideServiceCard,
        {
          onPress: () => onServiceSelected(service),
          onDismissPress: () => onServiceDismissed(service),
          onPreparationNoticePress: () =>
            onPreparationInfoForServiceRequested(service),
          onDocumentListPress: (params) =>
            onDocumentListForServiceRequested(service, params),
          onCancellationInfoPress: () =>
            onCancellationInfoForServiceRequested(service),
        },
      ];
    default:
      return null;
  }
};
const mapWithKey = map.convert({ cap: false });

export function ServicesList({
  services = [],
  onServiceSelected = noop,
  onPreparationInfoForServiceRequested = noop,
  onFindPharmacyRequested = noop,
  onServiceDismissed = noop,
  onDigitalToolSelected = noop,
  onCancellationInfoForServiceRequested = noop,
  onDocumentListForServiceRequested = noop,
}) {
  const renderItem = useCallback(
    (service, index) => {
      const last = size(services) === index;
      const [ServiceCard, callbacks] = getCardForService(service, {
        onServiceSelected,
        onPreparationInfoForServiceRequested,
        onFindPharmacyRequested,
        onServiceDismissed,
        onDigitalToolSelected,
        onCancellationInfoForServiceRequested,
        onDocumentListForServiceRequested,
      });
      return (
        <View style={!last && styles.serviceContainer} key={service.id}>
          <ServiceCard {...service} {...callbacks} />
        </View>
      );
    },
    [
      services,
      onDigitalToolSelected,
      onFindPharmacyRequested,
      onPreparationInfoForServiceRequested,
      onServiceDismissed,
      onServiceSelected,
      onCancellationInfoForServiceRequested,
      onDocumentListForServiceRequested,
    ]
  );

  return (
    <View>
      {renderIf(size(services) !== 0)(() => mapWithKey(renderItem, services))}
    </View>
  );
}

ServicesList.propTypes = {
  services: PropTypes.array,
  onServiceSelected: PropTypes.func,
  onPreparationInfoForServiceRequested: PropTypes.func,
  onFindPharmacyRequested: PropTypes.func,
  onServiceDismissed: PropTypes.func,
  onDigitalToolSelected: PropTypes.func,
  onCancellationInfoForServiceRequested: PropTypes.func,
  onDocumentListForServiceRequested: PropTypes.func,
};
