// as of expo sdk v42-45 microphone permissions are handled with expo-camera
// https://docs.expo.dev/versions/latest/sdk/camera/#methods
import {
  getMicrophonePermissionsAsync,
  requestMicrophonePermissionsAsync as requestPermissionsAsync,
} from "expo-camera";
import { PermissionStatus } from "expo-modules-core";

import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";

const logger = LoggerFactory.get("core/Microphone");

export async function getPermissionsAsync() {
  try {
    return await getMicrophonePermissionsAsync();
  } catch (e) {
    logger(
      "getPermissionsAsync:couldn't determine microphone permissions",
      e,
      LOGGER_LEVEL_WARN
    );
    return {
      status: PermissionStatus.UNDETERMINED,
      expires: "never",
      canAskAgain: true,
      granted: false,
    };
  }
}

export { requestPermissionsAsync };
