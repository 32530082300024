import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { isString, mapValues } from "lodash/fp";

import { CloudInstance } from "../Cloud";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";

const analytics = getAnalytics(CloudInstance);
const logger = LoggerFactory.get("core/ErrorTracking");

export function recordError(error = {}, errorName) {
  logEvent(analytics, "exception", {
    description: errorName,
    fatal: false,
    message: error.message,
    stack: error.stack,
    name: error.name,
    cause: error.cause,
  });
}

export async function setErrorTrackingAttributes(attributes, options) {
  const typeSafeAttributes = mapValues((value) => {
    if (isString(value)) {
      return value;
    }
    return JSON.stringify(value);
  }, attributes);
  logger("setErrorTrackingAttributes", {
    attributes: typeSafeAttributes,
    options,
  });
  return setUserProperties(analytics, typeSafeAttributes, options);
}

export function setErrorTrackingUserId(userId) {
  logger("setErrorTrackingUserId", userId);
  return setUserId(analytics, userId);
}

export function logToErrorTracking(message) {
  logger("logToErrorTracking:NOT IMPLEMENTED", message, LOGGER_LEVEL_WARN);
}
