import { getMainDefinition } from "@apollo/client/utilities";

export const isRefetching = (networkStatus) => networkStatus === 4;

export function hasSubscription({ query }) {
  const { kind, operation } = getMainDefinition(query);
  return kind === "OperationDefinition" && operation === "subscription";
}

export function isPublic(operation) {
  return Boolean(operation.getContext().public);
}
