import { defineMessages } from "react-intl";

export const messages = defineMessages({
  cancel: {
    defaultMessage: "Cancel",
    description: "cancel",
    id: "AccountSettings.cancel",
  },
  changeIdentificationMethod: {
    defaultMessage: "Change PIN or Face ID",
    description: "Change PIN or Face ID",
    id: "AccountSettings.change.identification.method",
  },
  changePin: {
    defaultMessage: "Change PIN",
    description: "Change PIN",
    id: "AccountSettings.change.pin",
  },
  consents: {
    defaultMessage: "Terms and privacy policy",
    description: "Terms and privacy policy",
    id: "AccountSettings.consents",
  },
  accountDetails: {
    defaultMessage: "Account details",
    description: "Account details screen link",
    id: "AccountSettings.account.details",
  },
  dateOfBirth: {
    defaultMessage: "Date of birth",
    description: "Date of birth",
    id: "AccountSettings.date.of.birth",
  },
  enableBiometry: {
    defaultMessage: "Fingerprint or Face ID",
    description: "Fingerprint or Face ID",
    id: "AccountSettings.enable.biometry",
  },
  female: {
    defaultMessage: "Female",
    description: "female",
    id: "AccountSettings.female",
  },
  gender: {
    defaultMessage: "Gender",
    description: "gender",
    id: "AccountSettings.gender",
  },
  helpAndContact: {
    defaultMessage: "Help & contact",
    description: "Help and contact",
    id: "AccountSettings.HelpAndContact",
  },
  language: {
    defaultMessage: "Language",
    description: "Language picker label",
    id: "AccountSettings.language",
  },
  logOut: {
    defaultMessage: "Log out",
    description: "log out",
    id: "AccountSettings.log.out",
  },
  logoutMsg: {
    defaultMessage: "You will be logged out.",
    description: "You will be logged out.",
    id: "AccountSettings.logout.message",
  },
  reLoginMsg: {
    id: "AccountSettings.relogin.message",
    description: "Log in again",
    defaultMessage: "Log in again",
  },
  male: {
    defaultMessage: "Male",
    description: "male",
    id: "AccountSettings.male",
  },
  name: {
    defaultMessage: "Name",
    description: "name",
    id: "AccountSettings.name",
  },
  paymentMethods: {
    defaultMessage: "Payment methods",
    description: "Payment methods",
    id: "AccountSettings.paymentMethods",
  },
  verifyActionQuestion: {
    defaultMessage: "Are you sure?",
    description: "Question for verifying user action",
    id: "global.verify.action.question",
  },
});
