import React from "react";
import PropTypes from "prop-types";
import { noop } from "lodash/fp";

import { EyrButton } from "../EyrButton";

export const ToggleButton = function ({
  title,
  selected = false,
  onPress = noop,
}) {
  return (
    <EyrButton
      size={"m"}
      onPress={onPress}
      variant={selected ? "primary" : "secondary"}
      title={title}
    />
  );
};

ToggleButton.propTypes = {
  title: PropTypes.string.isRequired,
  onPress: PropTypes.func,
  selected: PropTypes.bool,
};
