import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_0,
  },
  providerContainer: {
    flexDirection: "row",
  },
  topBar: {
    borderBottomWidth: 1,
    borderBottomColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  providerContainerXS: {
    ...responsiveStyles.contentContainerXS,
    flexDirection: "row",
    paddingHorizontal: 24,
  },
  providerContainerS: {
    ...responsiveStyles.contentContainerS,
    flexDirection: "row",
  },
  providerContainerM: {
    ...responsiveStyles.contentContainerM,
    flexDirection: "row",
  },
  providerImageContainer: {
    marginRight: 16,
  },
  list: {
    flexGrow: 1,
  },
  documentContainer: {
    padding: 16,
    flexDirection: "row",
  },
  documentIconContainer: {
    marginRight: 12,
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
    flexGrow: 1,
  },
  contentContainerS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerS,
    flexGrow: 1,
  },
  contentContainerM: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerM,
    flexGrow: 1,
  },
  bottomBar: {
    borderTopWidth: 1,
    borderTopColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  buttonContainerXS: {
    ...responsiveStyles.contentContainerXS,
    paddingHorizontal: 24,
  },
  buttonContainerS: {
    ...responsiveStyles.contentContainerS,
    flexDirection: "row",
    justifyContent: "center",
  },
  buttonContainerM: {
    ...responsiveStyles.contentContainerM,
    flexDirection: "row",
    justifyContent: "center",
  },
});
