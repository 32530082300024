import { useEffect } from "react";

import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";

const logger = LoggerFactory.get("core/AppUpdate");
export function useAppUpdate(params) {
  useEffect(() => {
    logger("NOT IMPLEMENTED", params, LOGGER_LEVEL_WARN);
  }, [params]);
  return {
    startAppUpdate() {},
    appUpdateProgress: null,
  };
}
