export const PaletteGjensidige = {
  NUDE_200: "#47423F",
  NUDE_175: "#59524F",
  NUDE_150: "#807874",
  NUDE_125: "#A69D98",
  NUDE_100: "#BBB3B0",
  NUDE_80: "#D0CAC7",
  NUDE_60: "#E0DCDA",
  NUDE_40: "#F0EDEB",
  NUDE_20: "#F8F7F7",
  NUDE_0: "#FBFBFB",
  BURGUNDY_125: "#020521",
  BURGUNDY_100: "#090C33",
  BURGUNDY_80: "#393F66",
  BURGUNDY_60: "#61698F",
  BURGUNDY_40: "#909ABD",
  BURGUNDY_20: "#BAC3D9",
  BURGUNDY_0: "#D6DDEE",
  DARK_BLUE_125: "#090C33",
  DARK_BLUE_100: "#393F66",
  DARK_BLUE_80: "#61698F",
  DARK_BLUE_60: "#909ABD",
  TURQUOISE_100: "#EEFF76",
  TURQUOISE_80: "#F2FF9E",
  TURQUOISE_60: "#F4FFAF",
  TURQUOISE_40: "#F7FFC8",
  TURQUOISE_20: "#F9FFD7",
  TURQUOISE_0: "#FCFFEB",
  PINK_100: "#7C55FF",
  PINK_80: "#9677FF",
  PINK_60: "#B099FF",
  PINK_40: "#CBBBFF",
  PINK_20: "#E0D6FF",
  PINK_0: "#EBE6FF",
};

export const IconPaletteGjensidige = {
  BURGUNDY_100: PaletteGjensidige.BURGUNDY_100,
  NUDE_80: PaletteGjensidige.PINK_80,
  NUDE_60: PaletteGjensidige.PINK_40,
  PINK_60: PaletteGjensidige.PINK_40,
  DARK_BLUE_100: PaletteGjensidige.DARK_BLUE_100,
  DARK_BLUE_60: PaletteGjensidige.DARK_BLUE_80,
  TURQUOISE_60: PaletteGjensidige.PINK_40,
};
