import React, { useMemo, useCallback, Children } from "react";
import PropTypes from "prop-types";
import { Image, View, Linking } from "react-native";
import { PortableText as SanityPortableText } from "@portabletext/react";
import urlBuilder from "@sanity/image-url";
import sanityClient from "@sanity/client";
import { StaticConfig } from "@eyr-mobile/core/Config";
import { useDevice } from "@eyr-mobile/core/Device";
import { isString } from "lodash/fp";

import { Heading } from "../Heading";
import { Subtitle } from "../Subtitle";
import { Paragraph } from "../Paragraph";
import { TypographyRaw } from "../TypographyRaw";

import { getImageStyle, styles } from "./PortableText.styles";

const client = sanityClient({
  projectId: StaticConfig.SANITY_PROJECT_ID,
  dataset: StaticConfig.SANITY_DATASET,
  apiVersion: "2021-03-25",
  useCdn: true,
});

const WIDTH_BY_SCREENSIZE = {
  xs: 540,
  s: 480,
  m: 540,
};

const normalizeValue = (value) =>
  isString(value)
    ? {
        _type: "image",
        asset: {
          _ref: value,
          _type: "reference",
        },
      }
    : value;

export const PortableTextImage = ({ value }) => {
  const { screenSize } = useDevice();
  const style = useMemo(
    () => getImageStyle({ value: normalizeValue(value) }),
    [value]
  );
  const uri = useMemo(() => {
    if (!screenSize) {
      return;
    }
    try {
      return urlBuilder(client)
        .image(normalizeValue(value))
        .width(WIDTH_BY_SCREENSIZE[screenSize])
        .fit("max")
        .auto("format")
        .url();
    } catch (error) {}
  }, [screenSize, value]);

  return <Image source={{ uri }} style={style} />;
};

PortableTextImage.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      _key: PropTypes.string,
      _type: PropTypes.oneOf(["image"]),
      asset: PropTypes.shape({
        _ref: PropTypes.string,
        _type: PropTypes.oneOf(["reference"]),
      }),
    }),
  ]),
  isInline: PropTypes.bool,
};

const PortableTextBlockNormal = ({ children }) => (
  <View style={styles.blockContainer}>
    <Paragraph size="l" spacing="l">
      {children}
    </Paragraph>
  </View>
);

const PortableTextBlockH1 = ({ children }) => (
  <View style={styles.blockContainer}>
    <Heading size="l">{children}</Heading>
  </View>
);

const PortableTextBlockH2 = ({ children }) => (
  <View style={styles.blockContainer}>
    <Heading spacing="m">{children}</Heading>
  </View>
);

const PortableTextBlockH3 = ({ children }) => (
  <View style={styles.blockContainer}>
    <Heading size="s">{children}</Heading>
  </View>
);

const PortableTextBlockH4 = ({ children }) => (
  <View style={styles.blockContainer}>
    <Subtitle size="l" spacing="m">
      {children}
    </Subtitle>
  </View>
);

const PortableTextBlockH5 = ({ children }) => (
  <View style={styles.blockContainer}>
    <Subtitle>{children}</Subtitle>
  </View>
);

const PortableTextBlockH6 = ({ children }) => (
  <View style={styles.blockContainer}>
    <Subtitle size="s">{children}</Subtitle>
  </View>
);

const PortableTextMarkStrong = ({ children }) => (
  <TypographyRaw fontWeight="600" fontFamily={"FONT_FAMILY_EUCLID_CIRCULAR_B"}>
    {children}
  </TypographyRaw>
);

const PortableTextMarkUnderline = ({ children }) => (
  <Paragraph decoration="underline" size="l">
    {children}
  </Paragraph>
);

const PortableTextMarkLink = ({ children, value }) => {
  const handlePress = useCallback(async () => {
    if (!(await Linking.canOpenURL())) {
      return;
    }
    Linking.openURL(value);
  }, [value]);
  return (
    <Paragraph size="l" onPress={handlePress} decoration="underline">
      {children}
    </Paragraph>
  );
};

const PortableTextBulletList = ({ children }) => (
  <View style={styles.list}>{children}</View>
);

const PortableTextNumberList = ({ children }) => (
  <View style={styles.list}>
    {Children.map(children, (child, index) => (
      <PortableTextListItemNumber number={index + 1}>
        {child}
      </PortableTextListItemNumber>
    ))}
  </View>
);

const PortableTextListItemBullet = ({ children }) => (
  <View style={styles.listItem}>
    <View style={styles.listItemBullet}>
      <Paragraph size="l">•</Paragraph>
    </View>
    <Paragraph size="l">{children}</Paragraph>
  </View>
);

// TODO find better way to get number in list and render
const PortableTextListItemNumber = ({ children, number }) => {
  return (
    <View style={styles.listItem}>
      <Paragraph size="l">
        {number}. {children.props.children[0]}
      </Paragraph>
    </View>
  );
};

const PortableTextBlockBlockquote = ({ children }) => {
  return (
    <View style={styles.blockContainer}>
      <View style={styles.blockquoteContainer}>
        <Subtitle size="l">{children}</Subtitle>
      </View>
    </View>
  );
};

const PortableTextHardBreak = () => <View style={styles.hardBreak} />;

const PortableTextUnknownType = () => null;

const portableTextComponents = {
  types: {
    image: PortableTextImage,
  },
  block: {
    normal: PortableTextBlockNormal,
    h1: PortableTextBlockH1,
    h2: PortableTextBlockH2,
    h3: PortableTextBlockH3,
    h4: PortableTextBlockH4,
    h5: PortableTextBlockH5,
    h6: PortableTextBlockH6,
    blockquote: PortableTextBlockBlockquote,
  },
  marks: {
    strong: PortableTextMarkStrong,
    underline: PortableTextMarkUnderline,
    link: PortableTextMarkLink,
    em: ({ children }) => children, // TODO?
    // "strike-through": ({ children }) => <Text>{children}</Text>,
    // code: ({ children }) => <Text>{children}</Text>,
  },
  list: {
    number: PortableTextNumberList,
    bullet: PortableTextBulletList,
  },
  listItem: {
    number: PortableTextListItemNumber,
    bullet: PortableTextListItemBullet,
  },
  hardBreak: PortableTextHardBreak,
  unknownType: PortableTextUnknownType,
  unknownMark: PortableTextUnknownType,
  unknownList: PortableTextUnknownType,
  unknownListItem: PortableTextUnknownType,
  unknownBlockStyle: PortableTextUnknownType,
};

export function PortableText(props) {
  return <SanityPortableText {...props} components={portableTextComponents} />;
}
