import {
  getPermissionsAsync as getNotificationsPermissionsAsync,
  requestPermissionsAsync,
  setBadgeCountAsync,
} from "expo-notifications";
import { PermissionStatus } from "expo-modules-core";

// import { getMessaging, getToken as firebaseGetToken } from "firebase/messaging";
//
// import { CloudInstance } from "../Cloud";

import {
  // LOGGER_LEVEL_ERROR,
  LOGGER_LEVEL_WARN,
  LoggerFactory,
} from "../Logger";

// const messaging = getMessaging(CloudInstance);

const logger = LoggerFactory.get("core/Notifications");

export async function safelyGetNotificationsToken(/* options */) {
  // const TAG = "getToken";
  // return await firebaseGetToken(messaging, {
  //   vapidKey:
  //     "BMqTSVFwTJYTAGQr5ooHQGomcJNTF1vS6O1Jv_aVGAOooUrA0YLDrlGfoznszk7iOZtV42nhLMYiteHJcZY4TJc",
  //   ...options,
  // })
  //   .then((token) => {
  //     if (token) {
  //       return token;
  //     } else {
  //       logger(
  //         TAG,
  //         "No registration token available. Request permission to generate one.",
  //         LOGGER_LEVEL_WARN
  //       );
  //     }
  //   })
  //   .catch((error) => {
  //     logger(TAG, error, LOGGER_LEVEL_ERROR);
  //   });
}

export function scheduleNotificationAsync(NotificationRequestInput) {
  logger(
    "scheduleNotificationAsync:NOT IMPLEMENTED",
    NotificationRequestInput,
    LOGGER_LEVEL_WARN
  );
}

const undeterminedNotificationPermissionsStatus = {
  status: PermissionStatus.UNDETERMINED,
  expires: "never",
  canAskAgain: true,
  granted: false,
};

export async function getPermissionsAsync() {
  try {
    if (/iPhone OS 16/.test(navigator.userAgent)) {
      return undeterminedNotificationPermissionsStatus;
    }
    return await getNotificationsPermissionsAsync();
  } catch (e) {
    logger(
      "getPermissionsAsync:couldn't determine notifications permissions",
      e,
      LOGGER_LEVEL_WARN
    );
    return undeterminedNotificationPermissionsStatus;
  }
}

export function onForegroundMessage(listener) {
  logger("onForegroundMessage:NOT IMPLEMENTED", listener, LOGGER_LEVEL_WARN);
}

export function getInitialNotification() {
  logger(
    "getInitialNotification:NOT IMPLEMENTED",
    arguments,
    LOGGER_LEVEL_WARN
  );
}

export function onNotificationOpenedApp(listener) {
  logger(
    "onNotificationOpenedApp:NOT IMPLEMENTED",
    listener,
    LOGGER_LEVEL_WARN
  );
}

export { requestPermissionsAsync, setBadgeCountAsync };
