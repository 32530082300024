import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { noop } from "lodash/fp";

import { LOGGER_LEVEL_ERROR, LoggerFactory } from "../Logger";
import { renderIf } from "../Lib";

const logger = LoggerFactory.get("core/Alert");

const alertStateDefaults = {
  visible: false,
  title: "",
  message: "",
  buttons: [],
  buttonsLayout: "row",
  onClose: noop,
};
export const AlertContext = createContext();

export const AlertProvider = ({ children, initialState, AlertComponent }) => {
  const initialStateWithDefaults = useMemo(
    () => ({
      ...alertStateDefaults,
      ...initialState,
    }),
    [initialState]
  );
  const [alertState, setAlertState] = useState(initialStateWithDefaults);
  const alert = useCallback(
    ({ onClose, ...rest }) => {
      setAlertState({
        ...alertStateDefaults,
        ...rest,
        visible: true,
        onClose: () => {
          setAlertState(initialStateWithDefaults);
          if (onClose) {
            onClose();
          }
        },
      });
      return () => {
        setAlertState(alertStateDefaults);
      };
    },
    [initialStateWithDefaults]
  );

  return (
    <>
      <AlertContext.Provider value={alert}>{children}</AlertContext.Provider>
      {renderIf(alertState.visible)(<AlertComponent {...alertState} />)}
    </>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.node.isRequired,
  AlertComponent: PropTypes.elementType.isRequired,
  initialState: PropTypes.object,
};

export const useAlert = () => {
  const alert = useContext(AlertContext);
  if (alert === undefined) {
    logger(
      "useAlert: Couldn't find an alert. Is your component inside an AlertProvider?",
      alert,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return alert;
};
