import { useEffect, useMemo, useRef, useState, createRef } from "react";
import { head, map, get } from "lodash/fp";
import {
  getLocales,
  usesMetricSystem,
  getTimeZone,
} from "react-native-localize";
// DO NOT re-export defineMessages it will break babel plugin
// use import { defineMessages } from "react-intl"; instead
import { useIntl, createIntl } from "react-intl";

// noinspection ES6PreferShortImport
import { getDiffFromNowInSeconds } from "../Lib/date";

export const intlRef = createRef();
export const IntlConfig = {
  defaultLocale: "en",
};
export const IntlLanguages = [
  { name: "Dansk", code: "da" },
  { name: "English", code: "en" },
  { name: "Norsk", code: "nb" },
  { name: "Svenska", code: "sv" },
];
export const supportedLanguages = map(get("code"), IntlLanguages);
export const getLocale = () => head(getLocales());

export { useIntl, usesMetricSystem, createIntl, getTimeZone };

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

function selectUnit(seconds) {
  const absValue = Math.abs(seconds);

  if (absValue < MINUTE) {
    return "second";
  }

  if (absValue < HOUR) {
    return "minute";
  }

  if (absValue < DAY) {
    return "hour";
  }

  return "day";
}

function getDurationInSeconds(unit) {
  switch (unit) {
    case "second":
      return 1;
    case "minute":
      return MINUTE;
    case "hour":
      return HOUR;
    default:
      return DAY;
  }
}

const INCREMENTABLE_UNITS = ["second", "minute", "hour"];
function canIncrement(unit = "second") {
  return INCREMENTABLE_UNITS.includes(unit);
}

export function useFormattedRelativeTimeTo(
  date,
  { updateIntervalInSeconds = 30, style = "narrow", numeric = "auto" } = {}
) {
  const initialDiffInSeconds = useMemo(
    () => (date ? getDiffFromNowInSeconds(date) : 0),
    [date]
  );
  const scheduledUpdateRef = useRef();
  const { formatRelativeTime, locale } = useIntl();
  const [valueInSeconds, setValueInSeconds] = useState(initialDiffInSeconds);
  useEffect(() => {
    setValueInSeconds(initialDiffInSeconds);
  }, [initialDiffInSeconds, date]);
  const { currentValue, currentUnit } = useMemo(() => {
    const currentUnit = selectUnit(valueInSeconds);
    const unitDuration = getDurationInSeconds(currentUnit);
    const currentValue = Math.trunc(valueInSeconds / unitDuration);
    return { currentUnit, currentValue };
  }, [valueInSeconds]);

  useEffect(() => {
    function clearUpdateTimer() {
      clearTimeout(scheduledUpdateRef.current);
      scheduledUpdateRef.current = null;
    }
    clearUpdateTimer();
    // // If there's no interval and we cannot increment this unit, do nothing
    if (!updateIntervalInSeconds || !canIncrement(currentUnit)) {
      return clearUpdateTimer;
    }
    // Figure out the next interesting time
    const nextValueInSeconds = valueInSeconds - updateIntervalInSeconds;
    const nextUnit = selectUnit(nextValueInSeconds);
    // We've reached the max auto incrementable unit, don't schedule another update
    if (currentUnit === "day" && nextUnit === "day") {
      return clearUpdateTimer;
    }

    const unitDuration = getDurationInSeconds(nextUnit);
    const remainder = nextValueInSeconds % unitDuration;
    const prevInterestingValueInSeconds = nextValueInSeconds - remainder;
    const nextInterestingValueInSeconds =
      prevInterestingValueInSeconds >= valueInSeconds
        ? prevInterestingValueInSeconds - unitDuration
        : prevInterestingValueInSeconds;
    const delayInSeconds = Math.abs(
      nextInterestingValueInSeconds - valueInSeconds
    );

    if (valueInSeconds !== nextInterestingValueInSeconds) {
      scheduledUpdateRef.current = setTimeout(
        () => setValueInSeconds(nextInterestingValueInSeconds),
        delayInSeconds * 1000
      );
    }
    return clearUpdateTimer;
  }, [valueInSeconds, updateIntervalInSeconds, currentUnit]);
  // Incorrect Norwegian Relative <Unit> Narrow
  // https://unicode-org.atlassian.net/browse/CLDR-15032
  const _style = locale === "nb" && style === "narrow" ? "short" : style;

  return [
    formatRelativeTime(currentValue, currentUnit, { style: _style, numeric }),
    { unit: currentUnit, value: currentValue },
  ];
}
