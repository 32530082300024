import React, { useCallback, useMemo } from "react";
import { FlatList } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation } from "@react-navigation/native";
import {
  useQuery,
  withHandlers,
  useSubscription,
} from "@eyr-mobile/core/DataProvider";
import {
  GetAccountDigitalTools,
  AccountDigitalToolsUpdatedSubscription,
} from "@eyr-mobile/domain/DigitalTool";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useDevice } from "@eyr-mobile/core/Device";
import { compact } from "lodash/fp";
import { useFeatureFlag } from "@eyr-mobile/core/Config";

import {
  Card,
  ListItemSeparator,
  IconContainer,
  Empty,
  Badge,
} from "../../components";
import { SVGs } from "../../res";

import { styles } from "./DigitalToolsScreen.styles";
import { messages } from "./DigitalToolsScreen.messages";

export const DigitalToolsScreen = () => {
  const { navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const psychologySelfHelpIsEnabled = useFeatureFlag(
    "FEATURE_PSYCHOLOGY_SELF_HELP"
  );
  const { screenSizeSelect } = useDevice();

  const { handlers, data, refetch, refreshing } = withHandlers(
    useQuery(GetAccountDigitalTools, {
      context: { public: false },
      fetchPolicy: "cache-and-network",
    })
  );

  useSubscription(AccountDigitalToolsUpdatedSubscription, {
    onData: ({ data: subscriptionData, client }) => {
      const currentAccountDigitalTools = client.readQuery({
        query: GetAccountDigitalTools,
      });
      client.writeQuery({
        query: GetAccountDigitalTools,
        data: {
          ...currentAccountDigitalTools,
          accountDigitalTools: subscriptionData.data.accountDigitalToolsUpdated,
        },
      });
    },
  });

  const renderItem = useCallback(
    ({
      item: {
        digitalTool: { __typename, title, url, id, navigateTo, badgeCount },
      },
    }) => {
      switch (__typename) {
        case "DigitalToolForm":
          return (
            <Card
              titleIsBold
              title={title}
              icon={
                <IconContainer variant="rounded" size="l">
                  <SVGs.Questionnaire />
                </IconContainer>
              }
              onPress={() =>
                navigate("DigitalToolFormScreen", {
                  url,
                  title,
                  id,
                })
              }
            />
          );
        case "NavigationLink":
          return (
            <Card
              titleIsBold
              title={title}
              badge={
                Boolean(badgeCount) && (
                  <Badge variant="primary" label={badgeCount} />
                )
              }
              icon={
                <IconContainer variant="rounded" size="l">
                  <SVGs.TreatmentTools />
                </IconContainer>
              }
              onPress={() => navigate(navigateTo)}
            />
          );
        default:
          return null;
      }
    },
    [navigate]
  );

  const accountDigitalTools = useMemo(
    () =>
      data
        ? compact([
            ...data?.accountDigitalTools,
            psychologySelfHelpIsEnabled &&
              data.contentForUserV2.length && {
                id: "articles",
                digitalTool: {
                  __typename: "NavigationLink",
                  title: formatMessage(messages.treatmentProgram),
                  navigateTo: "ContentProgramsScreen",
                  badgeCount: data.inboxAccountDigitalTools.unseenCountContent,
                },
              },
          ])
        : [],
    [data, formatMessage, psychologySelfHelpIsEnabled]
  );

  const handleExtractKey = useCallback((item) => item.id.toString(), []);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      {handlers || (
        <FlatList
          contentContainerStyle={screenSizeSelect({
            xs: styles.listContentContainerXS,
            s: styles.listContentContainerS,
            m: styles.listContentContainerM,
          })}
          refreshing={refreshing}
          onRefresh={refetch}
          data={accountDigitalTools}
          keyExtractor={handleExtractKey}
          renderItem={renderItem}
          ItemSeparatorComponent={ListItemSeparator}
          ListEmptyComponent={
            <Empty
              iconName="TreatmentTools"
              message={formatMessage(messages.listEmptyText)}
            />
          }
        />
      )}
    </SafeAreaView>
  );
};

DigitalToolsScreen.routeName = "DigitalToolsScreen";
DigitalToolsScreen.navigationOptions = {
  title: messages.navigationTitle,
};
