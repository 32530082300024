import { defineMessages } from "react-intl";

import { serviceProviderTitleMessages } from "../../shared-messages";

export const messages = {
  ...defineMessages({
    header: {
      id: "ConsultationTypePicker.header",
      description: "Header",
      defaultMessage: "Choose a video appointment that is right for you",
    },
    dropInOptionTitle: {
      id: "ConsultationTypePicker.dropInOptionTitle",
      description: "Drop in option title",
      defaultMessage: "See next available {serviceProviderTitle}",
    },
    dropInOptionDescriptionDuringWorkingHours: {
      id: "ConsultationTypePicker.dropInOptionDescriptionDuringWorkingHours",
      description: "Drop in description during working hours",
      defaultMessage: "Estimated waiting time",
    },
    waitingTime: {
      id: "ConsultationTypePicker.waitingTime",
      description: "Waiting time  minutes for drop in",
      defaultMessage: "{queueWaitingTimeMinutes} minutes",
    },
    dropInOptionDescriptionOutsideWorkingHours: {
      id: "ConsultationTypePicker.dropInOptionDescriptionOutsideWorkingHours",
      description: "Drop in description outside working hours",
      defaultMessage: "We are closed until {dateTime}",
    },
    appointmentOptionTitle: {
      id: "ConsultationTypePicker.appointmentOptionTitle",
      description: "Appointment option title",
      defaultMessage: "Book an appointment",
    },
    appointmentOptionDescription: {
      id: "ConsultationTypePicker.appointmentOptionDescription",
      description: "Appointment option description",
      defaultMessage: "Choose a {serviceProviderTitle} and time",
    },
    closedLabel: {
      id: "ConsultationTypePicker.closedLabel",
      description: "Closed label text",
      defaultMessage: "Closed",
    },
  }),
  ...serviceProviderTitleMessages,
};
