import { defineMessages } from "react-intl";

export const messages = defineMessages({
  headerTitle: {
    id: "ContactInformationForm.headerTitle",
    description: "ContactInformationForm add contact details title",
    defaultMessage: "Share your contact details",
  },
  headerDescription: {
    id: "ContactInformationForm.headerDescription",
    description: "ContactInformationForm add contact details description",
    defaultMessage:
      "Our healthcare providers may sometimes need to reach you regarding your booking",
  },
  phoneControlLabel: {
    id: "ContactInformationForm.phoneControlLabel",
    description: "Phone text input field label",
    defaultMessage: "Phone",
  },
  emailControlLabel: {
    id: "ContactInformationForm.emailControlLabel",
    description: "Email text input field label",
    defaultMessage: "Email",
  },
  notificationSettingsControlLabel: {
    id: "ContactInformationForm.notificationSettingsControlLabel",
    description: "Notifications setting label",
    defaultMessage: "Subscribe to our newsletter for valuable medical tips",
  },
  invalidPhone: {
    id: "ContactInformationForm.invalid.phone",
    description: "Please enter a valid phone number",
    defaultMessage: "Please enter a valid phone number",
  },
  invalidEmail: {
    id: "ContactInformationForm.invalid.email",
    description: "Please enter a valid email",
    defaultMessage: "Please enter a valid email",
  },
});
