import React from "react";
import { get } from "lodash/fp";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import {
  GetAccountCountryCodeAndConsentAge,
  useOrderFlowStage,
  useSelectClientFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";
import { View } from "react-native";

import {
  AddChild,
  ProgressIndicator,
  CancelOrderHeaderRight,
} from "../../components";

import { messages } from "./FlowStageSelectClient_AddChildScreen.messages";
import { styles } from "./FlowStageSelectClient_AddChildScreen.styles";

const getAccountCountry = get("account.country");

export function FlowStageSelectClient_AddChildScreen() {
  const {
    data,
    stageParams: { progress, progressPerStage } = {},
    handlers,
  } = withHandlers(useOrderFlowStage(GetAccountCountryCodeAndConsentAge));

  const [{ onChildSelected }] = useSelectClientFlowStage();

  const progressWithinSubStages = progress + (1 / 2) * progressPerStage;
  const accountCountry = getAccountCountry(data);
  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
          >
            <AddChild
              consentAge={accountCountry?.consentAge}
              countryCode={accountCountry?.codeIso2}
              onSubmit={onChildSelected}
            />
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSelectClient_AddChildScreen.routeName =
  "FlowStageSelectClient_AddChildScreen";
FlowStageSelectClient_AddChildScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
