import { defineMessages } from "react-intl";

export const messages = defineMessages({
  OTHER_monetaryRemainderTitle: {
    id: "PaymentMethodListItem.OTHER_monetaryRemainderTitle",
    description: "Other payment method coverage amount",
    defaultMessage: "You pay:",
  },
  DISCOUNT_coverageValueTitle: {
    id: "PaymentMethodListItem.DISCOUNT_coverageValueTitle",
    description: "Discount coverage value title",
    defaultMessage: "{discountPercentage} discount",
  },
});
