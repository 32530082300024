import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  bottomBar: {
    borderTopWidth: 1,
    borderColor: Palette.GRAY_20,
    backgroundColor: Palette.WHITE,
    paddingVertical: 16,
  },
  confirmOrderContainer: {
    alignItems: "center",
  },
  confirmOrderContainerXS: responsiveStyles.contentContainerXS,
  confirmOrderContainerS: responsiveStyles.contentContainerS,
  confirmOrderContainerM: responsiveStyles.contentContainerM,
  listItemContainer: {
    paddingVertical: 12,
    paddingHorizontal: 12,
    height: 78,
    borderRadius: 8,
    backgroundColor: Palette.WHITE,
    ...Shadows.xs,
    marginBottom: 16,
  },
  contentContainer: { flex: 1 },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  phoneContainer: { flexDirection: "row", alignItems: "center" },
  phoneCodeControlContainer: { flex: 1, marginRight: 16 },
  phoneNumberControlContainer: { flex: 2 },
  phoneNumberFieldButtonContainer: {
    paddingTop: 24,
  },
  alertContainer: {
    marginBottom: 16,
    width: "100%",
    borderRadius: 4,
    overflow: "hidden",
  },
});
