import React from "react";
import PropTypes from "prop-types";
import { Pressable } from "react-native";

import { SVGs } from "../../res";

import { stylingProps } from "./HeaderButton.styles";

export function HeaderButton({ iconName, tintColor, onPress, ...rest }) {
  const Icon = SVGs[iconName];
  return (
    <Pressable {...rest} {...stylingProps.pressable} onPress={onPress}>
      <Icon {...stylingProps.icon} fill={tintColor} />
    </Pressable>
  );
}

HeaderButton.propTypes = {
  onPress: PropTypes.func.isRequired,
  iconName: PropTypes.string.isRequired,
  tintColor: PropTypes.string.isRequired,
};
