import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const SIZES = {
  s: 32,
  m: 40,
  l: 64,
};

export const styles = StyleSheet.create({
  placeholder: {
    alignItems: "center",
    backgroundColor: Palette.NUDE_20,
    justifyContent: "center",
  },
  grayBackground: {
    backgroundColor: Palette.GRAY_0,
  },
});

export const sizeStyles = StyleSheet.create({
  s: {
    width: SIZES.s,
    height: SIZES.s,
  },
  m: {
    width: SIZES.m,
    height: SIZES.m,
  },
  l: {
    width: SIZES.l,
    height: SIZES.l,
  },
});

const borderRadiusStyles = StyleSheet.create({
  s: {
    borderRadius: SIZES.s / 2,
  },
  m: {
    borderRadius: SIZES.m / 2,
  },
  l: {
    borderRadius: SIZES.l / 2,
  },
  rounded: {
    borderRadius: 8,
  },
});

export const stylingHelpers = {
  getBorderRadiusStyle: ({ size, shape }) => {
    switch (shape) {
      case "round": {
        return borderRadiusStyles[size];
      }
      case "rounded": {
        return borderRadiusStyles.rounded;
      }
    }
  },
  getPlaceholderStyle: (disabled) => {
    if (disabled) {
      return [styles.placeholder, styles.grayBackground];
    }
    return styles.placeholder;
  },
};
