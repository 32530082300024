import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    height: 32,
  },
});

export const stylingProps = {
  trackColor: { false: Palette.OVERLAY_DARK_8, true: Palette.BURGUNDY_100 },
  thumbColor: Palette.WHITE,
  activeThumbColor: Palette.WHITE,
};
