import React, { useEffect, useCallback, useState } from "react";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  useQuery,
  withHandlers,
  useMutation,
} from "@eyr-mobile/core/DataProvider";
import {
  validateIfNotBlank,
  emailValidator as coreEmailValidator,
  configurePhoneNumberValidator,
} from "@eyr-mobile/core/Validation";
import {
  UpdateAccount,
  GetContactInfoAndAvailableCountries,
} from "@eyr-mobile/domain/Account";
import { ScrollView, View } from "react-native";
import { SafeAreaView, useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";
import { get, find } from "lodash/fp";
import { useNavigation } from "@react-navigation/native";
import { useForm } from "@eyr-mobile/core/Form";
import { useDevice } from "@eyr-mobile/core/Device";
import { usePrevious } from "@eyr-mobile/core/Lib";

import { EyrButton, Card, ContactInformationForm } from "../../components";

import { styles } from "./EditAccountContactInfoScreen.styles";
import { messages } from "./EditAccountContactInfoScreen.messages";

const nullifiedPhone = {
  phoneNumber: null,
  phoneCountryCode: null,
};
const getPhoneNumberLengthForCountryPhoneCode = (codePhone, countries = []) => {
  return get("phoneNumberLength", find({ codePhone }, countries));
};
const emailValidator = validateIfNotBlank(coreEmailValidator);

export function EditAccountContactInfoScreen() {
  const { handlers, data: { account, countries } = {} } = withHandlers(
    useQuery(GetContactInfoAndAvailableCountries)
  );

  const { goBack, navigate } = useNavigation();
  const { formatMessage } = useIntl();
  const { bottom: safeAreaInsetBottom } = useSafeAreaInsets();
  const [updateAccount, { loading }] = useMutation(UpdateAccount);

  const { screenSizeSelect } = useDevice();

  const handleSubmitContactInfo = useCallback(
    async (fields) => {
      await updateAccount({
        variables: {
          input: {
            ...fields,
            email: fields.email || null,
            ...((!fields.phoneNumber || !fields.phoneCountryCode) &&
              nullifiedPhone),
          },
        },
      });
      goBack();
    },
    [goBack, updateAccount]
  );

  const [validators, setValidators] = useState();
  const { form, fields, updateInitialValues } = useForm(
    {
      initialValues: {
        phoneCountryCode: "",
        phoneNumber: "",
        email: "",
        notificationSettings: 0,
      },
      validators,
      onSubmit: handleSubmitContactInfo,
    },
    {
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    }
  );

  useEffect(() => {
    if (!account) {
      return;
    }
    updateInitialValues({
      phoneCountryCode: account.phoneCountryCode || account.country.codePhone,
      phoneNumber: account.phoneNumber ?? "",
      email: account.email ?? "",
      notificationSettings: account.notificationSettings ?? 0,
    });
  }, [account, updateInitialValues]);

  const previousPhoneCountryCode = usePrevious(fields.phoneCountryCode.value);
  useEffect(() => {
    const phoneCountryCode = fields.phoneCountryCode.value;
    if (phoneCountryCode !== previousPhoneCountryCode) {
      setValidators({
        email: emailValidator,
        phoneNumber: validateIfNotBlank(
          configurePhoneNumberValidator(
            getPhoneNumberLengthForCountryPhoneCode(phoneCountryCode, countries)
          )
        ),
      });
    }
  }, [countries, fields.phoneCountryCode.value, previousPhoneCountryCode]);
  const previousValidators = usePrevious(validators);
  const validateForm = form.validate;
  useEffect(() => {
    if (validators !== previousValidators) {
      validateForm();
    }
  }, [validateForm, validators, previousValidators]);

  const handleDeleteMyAccount = useCallback(
    () => navigate("DeleteMyAccountScreen"),
    [navigate]
  );

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      {handlers || (
        <>
          <ScrollView
            style={styles.contentContainer}
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
          >
            <ContactInformationForm {...fields} countries={countries} />
            <Card
              onPress={handleDeleteMyAccount}
              title={formatMessage(messages.deleteMyAccountActionTitle)}
            />
          </ScrollView>
          {(fields.phoneNumber.dirty ||
            fields.email.dirty ||
            fields.notificationSettings.dirty) &&
            form.valid && (
              <View style={styles.footerContainer}>
                <View
                  style={[
                    { paddingBottom: safeAreaInsetBottom },
                    screenSizeSelect({
                      xs: styles.footerContentContainerXS,
                      s: styles.footerContentContainerS,
                      m: styles.footerContentContainerM,
                    }),
                  ]}
                >
                  <EyrButton
                    variant="primary"
                    onPress={form.submit}
                    title={formatMessage(messages.submitActionTitle)}
                    disabled={loading}
                    size="l"
                  />
                </View>
              </View>
            )}
        </>
      )}
    </SafeAreaView>
  );
}

EditAccountContactInfoScreen.routeName = "EditAccountContactInfoScreen";
EditAccountContactInfoScreen.navigationOptions = {
  title: messages.navigationTitle,
};
