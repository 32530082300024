import React, { useCallback, useState, useRef, useEffect } from "react";
import { AppState } from "react-native";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { noop } from "lodash/fp";
import { SafeAreaProvider } from "@eyr-mobile/core/SafeArea";
import { IntlProvider } from "@eyr-mobile/core/Intl";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "@eyr-mobile/core/Logger";
import { AlertProvider } from "@eyr-mobile/core/Alert";
import { EyrAlert } from "@eyr-mobile/ui/components";
import { default as messages } from "@eyr-mobile/ui/translations/messages.generated.json";
import { Navigation, ExtendedNavigationTheme } from "@eyr-mobile/ui/Navigation";
import {
  isReadyRef,
  navigationRef,
  navigate,
  goBack,
  NavigationContainer,
} from "@eyr-mobile/core/Navigation";
import { ToastContainer } from "@eyr-mobile/core/Toast";
import { DeviceProvider } from "@eyr-mobile/core/Device";
import {
  ApolloProvider,
  injectApolloAuth,
} from "@eyr-mobile/core/DataProvider";
import {
  AuthProvider,
  authRef,
  AuthState,
  getAuthorizationBearerHeader,
} from "@eyr-mobile/domain/Auth";
import { LegalProvider } from "@eyr-mobile/domain/Legal";
import { CallProvider } from "@eyr-mobile/domain/Call";
import { OnboardingProvider } from "@eyr-mobile/domain/Onboarding";
import { buildMissedCallNotification } from "@eyr-mobile/ui/Notifications";
import ringtoneSource from "@eyr-mobile/ui/res/audios/call_ringtone.mp3";
import {
  buildCompletedOrderAlert,
  buildReviewSuccessAlert,
} from "@eyr-mobile/ui/Alerts";
import {
  PreferenceContext,
  PreferenceProvider,
} from "@eyr-mobile/domain/Preference";
import { ReviewProvider } from "@eyr-mobile/domain/Review";
import { StatusBar } from "expo-status-bar";
import { logScreenView } from "@eyr-mobile/core/Analytics";
import { useAppState } from "@eyr-mobile/core/AppState";
import {
  logToErrorTracking,
  setErrorTrackingAttributes,
} from "@eyr-mobile/core/ErrorTracking";
import { useNetInfo } from "@eyr-mobile/core/Net";
import { LinkingProvider, LinkingContext } from "@eyr-mobile/core/Linking";
import { OrderProvider } from "@eyr-mobile/domain/Order";

const logger = LoggerFactory.get("AppRoot");

logger("AppState:initialState", AppState.currentState);

const documentTitle = {
  formatter: (options) => {
    const title = options?.title;
    return `${title ? `${title} - ` : ""}Eyr`;
  },
};

export function AppRoot(props) {
  const {
    NavigationContainer,
    SafeAreaProvider,
    safeArea,
    ApolloProvider,
    apollo,
    AuthProvider,
    auth,
    IntlProvider,
    LegalProvider,
    legal,
    Navigation,
    navigation,
    CallProvider,
    OnboardingProvider,
    onboarding,
    call,
    PreferenceProvider,
    preference,
    LinkingProvider,
    linking,
    AlertProvider,
    alert,
    DeviceProvider,
    device,
    OrderProvider,
    order,
    review,
    children,
    onReady,
    ...rest
  } = props;
  const routeNameRef = useRef();
  const [navigationReady, setNavigationReady] = useState(false);
  if (rest.length) {
    logger("unknown props", rest, LOGGER_LEVEL_WARN);
  }

  const handleReady = useCallback(() => {
    isReadyRef.current = true;
    setNavigationReady(true);
    onReady();
    logger("ready");
    const currentRoute = navigationRef.current.getCurrentRoute();
    if (currentRoute) {
      routeNameRef.current = currentRoute.name;
    }
  }, [onReady]);
  const handleNavigationStateChange = useCallback(async () => {
    const previousRouteName = routeNameRef.current;
    const currentRoute = navigationRef.current.getCurrentRoute();
    const currentRouteName = currentRoute.name;
    const currentRouteParams = currentRoute.params || {};

    if (previousRouteName !== currentRouteName) {
      await logScreenView({
        screen_name: currentRouteName,
        screen_class: currentRouteName,
        ...currentRouteParams,
      });
    }
    routeNameRef.current = currentRouteName;
  }, []);
  const injectAuth = useCallback((ref) => {
    authRef.current = ref;
    injectApolloAuth({
      authRef: ref,
      AuthState,
      getAuthorizationBearerHeader,
    });
  }, []);
  const handleStartReview = useCallback(() => {
    navigate("ConsultationReviewScreen");
  }, []);
  const handleEndReview = useCallback(() => {
    goBack();
  }, []);

  const onAppStateChange = useCallback((nextAppState) => {
    logger("AppState:change:", nextAppState);
    logToErrorTracking(`AppState:change:${nextAppState}`);
  }, []);

  useAppState({ onChange: onAppStateChange });
  const netInfoState = useNetInfo();
  useEffect(() => {
    setErrorTrackingAttributes({
      networkType: netInfoState.type,
      isNetworkConnected: Boolean(netInfoState.isConnected),
      isInternetReachable: Boolean(netInfoState.isInternetReachable),
    });
  }, [
    netInfoState.isConnected,
    netInfoState.isInternetReachable,
    netInfoState.type,
  ]);

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <GestureHandlerRootView style={{ flex: 1 }}>
      <DeviceProvider {...device}>
        <SafeAreaProvider {...safeArea}>
          <ToastContainer />
          <ApolloProvider {...apollo}>
            <PreferenceProvider {...preference}>
              <PreferenceContext.Consumer>
                {({ preferableLanguage }) => (
                  <LinkingProvider
                    {...linking}
                    navigationReady={navigationReady}
                  >
                    <LinkingContext.Consumer>
                      {(linking) => (
                        <NavigationContainer
                          ref={navigationRef}
                          onReady={handleReady}
                          onStateChange={handleNavigationStateChange}
                          linking={linking}
                          documentTitle={documentTitle}
                        >
                          <StatusBar />
                          <OnboardingProvider {...onboarding}>
                            <AlertProvider {...alert} AlertComponent={EyrAlert}>
                              <IntlProvider
                                messages={messages}
                                preferableLanguage={preferableLanguage}
                              >
                                <AuthProvider ref={injectAuth} {...auth}>
                                  <LegalProvider {...legal}>
                                    <CallProvider
                                      {...call}
                                      buildMissedCallNotification={
                                        buildMissedCallNotification
                                      }
                                      ringtoneSource={ringtoneSource}
                                    >
                                      <ReviewProvider
                                        {...review}
                                        onStartReview={handleStartReview}
                                        onEndReview={handleEndReview}
                                        buildReviewSuccessAlert={
                                          buildReviewSuccessAlert
                                        }
                                      >
                                        <OrderProvider
                                          {...order}
                                          buildCompletedOrderAlert={
                                            buildCompletedOrderAlert
                                          }
                                          navigationReady={navigationReady}
                                        >
                                          <Navigation
                                            {...navigation}
                                            ready={navigationReady}
                                            theme={ExtendedNavigationTheme}
                                          >
                                            {children}
                                          </Navigation>
                                        </OrderProvider>
                                      </ReviewProvider>
                                    </CallProvider>
                                  </LegalProvider>
                                </AuthProvider>
                              </IntlProvider>
                            </AlertProvider>
                          </OnboardingProvider>
                        </NavigationContainer>
                      )}
                    </LinkingContext.Consumer>
                  </LinkingProvider>
                )}
              </PreferenceContext.Consumer>
            </PreferenceProvider>
          </ApolloProvider>
        </SafeAreaProvider>
      </DeviceProvider>
    </GestureHandlerRootView>
  );
}

AppRoot.defaultProps = {
  NavigationContainer,
  SafeAreaProvider,
  ApolloProvider,
  AuthProvider,
  IntlProvider,
  LegalProvider,
  AlertProvider,
  Navigation,
  CallProvider,
  DeviceProvider,
  PreferenceProvider,
  OnboardingProvider,
  LinkingProvider,
  OrderProvider,
  onReady: noop,
};
