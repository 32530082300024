import React, {
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { noop, delay } from "lodash/fp";
import {
  CodeField,
  Cursor,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import { Animated, Platform } from "react-native";

import { handleValidation } from "../../../core/Validation";
import { useAppState } from "../../../core/AppState";

import {
  styles,
  ACTIVE_CELL_BG_COLOR,
  CELL_BORDER_RADIUS,
  CELL_SIZE,
  DEFAULT_CELL_BG_COLOR,
  FILLED_CELL_BG_COLOR,
  FILLED_CELL_SIZE,
  FILLED_CELL_BORDER_RADIUS,
} from "./PINCode.styles";

function Cell(props) {
  const { symbol, isFocused, onLayout } = props;
  const colorAnimation = useRef(new Animated.Value(0)).current;
  const scaleAnimation = useRef(new Animated.Value(1)).current;

  const hasValue = Boolean(symbol);
  const animatedCellStyle = useMemo(
    () => ({
      backgroundColor: hasValue
        ? colorAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [FILLED_CELL_BG_COLOR, DEFAULT_CELL_BG_COLOR],
          })
        : colorAnimation.interpolate({
            inputRange: [0, 1],
            outputRange: [DEFAULT_CELL_BG_COLOR, ACTIVE_CELL_BG_COLOR],
          }),
      borderRadius: scaleAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [FILLED_CELL_BORDER_RADIUS, CELL_BORDER_RADIUS],
      }),
      height: scaleAnimation.interpolate({
        inputRange: [0, 1],
        outputRange: [FILLED_CELL_SIZE, CELL_SIZE],
      }),
    }),
    [colorAnimation, hasValue, scaleAnimation]
  );

  // Run animation on next event loop tik
  // Because we need first return new style prop and then animate this value
  useLayoutEffect(() => {
    Animated.parallel([
      Animated.timing(colorAnimation, {
        useNativeDriver: false,
        toValue: isFocused ? 1 : 0,
        duration: 250,
      }),
      Animated.spring(scaleAnimation, {
        useNativeDriver: false,
        toValue: hasValue ? 0 : 1,
        duration: hasValue ? 300 : 250,
      }),
    ]).start();
  }, [animatedCellStyle, colorAnimation, hasValue, isFocused, scaleAnimation]);

  return (
    <Animated.Text style={[styles.cell, animatedCellStyle]} onLayout={onLayout}>
      {symbol || (isFocused ? <Cursor /> : null)}
    </Animated.Text>
  );
}

export function PINCode(props) {
  const {
    length = 4,
    initialValue = "",
    validator = noop,
    onSucceeded = noop,
    onFailed = noop,
    onChange = noop,
    focusOnForeground = false,
    blurOnSucceeded = true,
    resetOnSucceeded = false,
    resetOnFailed = true,
    autoFocus,
    ...rest
  } = props;
  const [value, setValue] = useState(initialValue);
  const ref = useRef();
  const [codeFieldProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const translateXAnimation = useRef(new Animated.Value(0)).current;

  const onChangeText = useCallback(
    (value) => {
      setValue(value);
      onChange(value);
    },
    [onChange]
  );
  const onFocus = useCallback(() => {
    // Workaround for TextInput not being focused sometimes even if autoFocus is set
    if (Platform.OS === "web" && autoFocus) {
      delay(0, () => ref.current.focus());
    }
  }, [autoFocus]);

  const reset = useCallback(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onActive = useCallback(
    () => (focusOnForeground ? ref.current?.focus() : noop()),
    [focusOnForeground]
  );

  useAppState({
    onActive,
  });

  useEffect(() => {
    if (value.length === length) {
      const validate = handleValidation(validator, {
        onSucceeded: (pin) => {
          onSucceeded(pin);
          if (resetOnSucceeded) {
            reset();
          }
          if (blurOnSucceeded) {
            ref.current.blur();
          }
        },
        onFailed: (error) => {
          onFailed(error);
          if (resetOnFailed) {
            reset();
          }
          Animated.sequence([
            Animated.timing(translateXAnimation, {
              toValue: 10,
              duration: 100,
              useNativeDriver: true,
            }),
            Animated.timing(translateXAnimation, {
              toValue: -10,
              duration: 100,
              useNativeDriver: true,
            }),
            Animated.timing(translateXAnimation, {
              toValue: 10,
              duration: 100,
              useNativeDriver: true,
            }),
            Animated.timing(translateXAnimation, {
              toValue: 0,
              duration: 100,
              useNativeDriver: true,
            }),
          ]).start();
        },
      });
      validate(value);
    }
  }, [
    blurOnSucceeded,
    length,
    onFailed,
    onSucceeded,
    ref,
    reset,
    resetOnFailed,
    resetOnSucceeded,
    translateXAnimation,
    validator,
    value,
  ]);

  return (
    <Animated.View style={{ transform: [{ translateX: translateXAnimation }] }}>
      <CodeField
        ref={ref}
        {...codeFieldProps}
        value={value}
        onChangeText={onChangeText}
        cellCount={length}
        rootStyle={styles.codeFieldRoot}
        keyboardType="number-pad"
        returnKeyType="done"
        textContentType="password"
        renderCell={({ index, ...rest }) => (
          <Cell
            {...rest}
            onLayout={getCellOnLayoutHandler(index)}
            key={index}
          />
        )}
        secureTextEntry
        caretHidden
        contextMenuHidden
        autoFocus={autoFocus}
        autoCompleteType="off"
        autoCorrect={false}
        onFocus={onFocus}
        {...rest}
      />
    </Animated.View>
  );
}

PINCode.propTypes = {
  initialValue: PropTypes.string,
  length: PropTypes.number,
  validator: PropTypes.func,
  onSucceeded: PropTypes.func,
  onFailed: PropTypes.func,
  onChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  focusOnForeground: PropTypes.bool,
  blurOnSucceeded: PropTypes.bool,
  resetOnSucceeded: PropTypes.bool,
  resetOnFailed: PropTypes.bool,
};
