import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { noop } from "lodash/fp";

import { SVGs } from "../../res";
import { EyrButton } from "../EyrButton";
import { Loading } from "../Loading";

import { styles } from "./PlayerButtons.styles";

const VARIANT_TO_BUTTON_VARIANTS = {
  audio: {
    SEEK: "text",
    PLAY: "primary",
  },
  video: {
    SEEK: "text-secondary",
    PLAY: "secondary",
  },
};

export const PlayerButtons = ({
  onTogglePlayPause = noop,
  isPlaying,
  isLoading,
  onBackwards = noop,
  onForwards = noop,
  variant = "audio",
}) => {
  return (
    <View style={styles.row}>
      <EyrButton
        onPress={onBackwards}
        IconComponent={SVGs.BackwardMono}
        variant={VARIANT_TO_BUTTON_VARIANTS[variant].SEEK}
        size={"l"}
      />
      <View style={styles.playPauseButton}>
        {isLoading ? (
          <Loading />
        ) : (
          <EyrButton
            onPress={onTogglePlayPause}
            IconComponent={isPlaying ? SVGs.PauseMono : SVGs.PlayMono}
            variant={VARIANT_TO_BUTTON_VARIANTS[variant].PLAY}
          />
        )}
      </View>
      <EyrButton
        onPress={onForwards}
        IconComponent={SVGs.ForwardMono}
        size={"l"}
        variant={VARIANT_TO_BUTTON_VARIANTS[variant].SEEK}
      />
    </View>
  );
};

PlayerButtons.propTypes = {
  onTogglePlayPause: PropTypes.func,
  isPlaying: PropTypes.bool,
  isLoading: PropTypes.bool,
  onBackwards: PropTypes.func,
  onForwards: PropTypes.func,
  variant: PropTypes.oneOf(["audio", "video"]),
};
