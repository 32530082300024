import { gql } from "@apollo/client";

const inboxConversationFields = gql`
  fragment inboxConversationFields on InboxConversation {
    __typename
    id
    lastUpdatedAt
    thumbnailImageUrl
    title
    unseenCount
    disabled
  }
`;

const inboxConversationMessagesFields = gql`
  fragment inboxConversationMessagesFields on InboxConversation {
    messages {
      id
      insertedAt
      sender {
        id
        name
        image
      }
      text
    }
  }
`;

const inboxAccountDigitalToolFields = gql`
  fragment inboxAccountDigitalToolFields on InboxAccountDigitalTools {
    __typename
    unseenCountContent
    unseenCountForms
  }
`;

const inboxDocumentFields = gql`
  fragment inboxDocumentFields on InboxDocuments {
    __typename
    unseenCount
  }
`;

export const GetInbox = gql`
  query InboxQuery {
    inboxDocuments {
      ...inboxDocumentFields
    }
    inboxConversations {
      ...inboxConversationFields
    }
    inboxAccountDigitalTools {
      ...inboxAccountDigitalToolFields
    }
  }
  ${inboxConversationFields}
  ${inboxAccountDigitalToolFields}
  ${inboxDocumentFields}
`;

export const InboxConversationUpdatedSubscription = gql`
  subscription InboxConversationUpdatedSubscription($id: String) {
    inboxConversationUpdated(id: $id) {
      ...inboxConversationFields
      ...inboxConversationMessagesFields
    }
  }
  ${inboxConversationMessagesFields}
  ${inboxConversationFields}
`;

export const InboxConversationsUpdatedSubscription = gql`
  subscription InboxConversationsUpdatedSubscription {
    inboxConversationsUpdated {
      ...inboxConversationFields
    }
  }
  ${inboxConversationFields}
`;

export const InboxAccountDigitalToolsUpdatedSubscription = gql`
  subscription InboxAccountDigitalToolsUpdatedSubscription {
    inboxAccountDigitalToolsUpdated {
      ...inboxAccountDigitalToolFields
    }
  }
  ${inboxAccountDigitalToolFields}
`;

export const InboxDocumentsUpdatedSubscription = gql`
  subscription InboxDocumentsUpdatedSubscription {
    inboxDocumentsUpdated {
      ...inboxDocumentFields
    }
  }
  ${inboxDocumentFields}
`;

export const GetConversation = gql`
  query Conversation($id: ID!) {
    inboxConversation(id: $id) {
      ...inboxConversationFields
      ...inboxConversationMessagesFields
    }
    account {
      messenger {
        id
      }
    }
  }
  ${inboxConversationFields}
  ${inboxConversationMessagesFields}
`;

export const CreateMessage = gql`
  mutation createMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      message {
        id
        insertedAt
        sender {
          id
        }
        text
      }
    }
  }
`;

export const ReadConversation = gql`
  mutation readConversation($input: ReadConversationInput!) {
    readConversation(input: $input) {
      inboxConversation {
        id
        lastUpdatedAt
        unseenCount
      }
    }
  }
`;
