import { getWebViewURL } from "@eyr-mobile/core/Net";

export const FormSlug = {
  MEDICAL_CERTIFICATE: "medical-certificate",
  INTERNATIONAL_PRESCRIPTION: "international_prescription",
  LEGEERKLARING: "legeerklaring",
  MEDICAL_ASSESSMENT: "medical_assessment",
  REFERRAL: "referral",
  REVIEW_GENERIC: "review-generic",
  REVIEW_MEDICAL: "review-medical",
  REVIEW_TECH: "review-tech",
  VACCINATION_FLU: "vaccination-flu",
  VACCINATION_TRAVEL: "vaccination-travel",
  PATIENT_GUIDE_SUMMARY: "patient-guide-summary",
  PROVIDER_NOTE: "provider-note",
  CONFIRMATION_LETTER: "confirmation-letter",
  PATIENT_RECORD: "patient-record",
};

const HARDCODED_EXPORTABLE_DOCUMENTS = [
  FormSlug.MEDICAL_CERTIFICATE,
  FormSlug.REFERRAL,
  FormSlug.PATIENT_GUIDE_SUMMARY,
  FormSlug.PROVIDER_NOTE,
  FormSlug.CONFIRMATION_LETTER,
  FormSlug.PATIENT_RECORD,
  FormSlug.INTERNATIONAL_PRESCRIPTION,
];

export const getIfDocumentCanBeExported = (url) =>
  HARDCODED_EXPORTABLE_DOCUMENTS.some((slug) =>
    url.includes(`form_data/${slug}`)
  );

export const getDocumentsRootUrl = () =>
  `${getWebViewURL()}${"/form_data/documents"}`;
