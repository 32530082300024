import { Linking, Platform } from "react-native";
import { isUndefined, split, get } from "lodash/fp";
import { getPathFromState, getStateFromPath } from "@react-navigation/native";
import { createURL } from "expo-linking";

import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";
import { getRootNavigationState } from "../Navigation";
import { StaticConfig } from "../Config";

const logger = LoggerFactory.get("core/Linking");

export async function openInMarketplace({
  appName,
  appStoreId,
  appStoreLocale = "us",
  playStoreId,
}) {
  if (!["android", "ios"].includes(Platform.OS)) {
    return;
  }
  if (Platform.OS === "ios") {
    if ([appName, appStoreId, appStoreLocale].some(isUndefined)) {
      return;
    }
    return Linking.openURL(
      `https://apps.apple.com/${appStoreLocale}/app/${appName}/id${appStoreId}`
    );
  }
  if (Platform.OS === "android") {
    if (!playStoreId) {
      return;
    }
    return Linking.openURL(
      `https://play.google.com/store/apps/details?id=${playStoreId}`
    );
  }
}

export async function maybeOpenURL(
  url,
  { appName, appStoreId, appStoreLocale, playStoreId } = {}
) {
  if (!url) {
    return;
  }
  if (url.startsWith("intent:")) {
    try {
      logger(
        "Unsupported scheme encountered",
        `Trying to convert ${url} to a Deep Link`
      );
      const [linkingURLRaw, additionalParamsRaw] = split("#Intent;", url);
      const additionalParams = new URLSearchParams(
        additionalParamsRaw.replace(";", "&")
      );
      const schemaToTry = additionalParams.get("scheme");
      const packageToTry = additionalParams.get("package");
      const deepLinkToTry = linkingURLRaw.replace("intent:", `${schemaToTry}:`);
      logger("Trying to open Deep Link:", deepLinkToTry);
      return Linking.openURL(deepLinkToTry).catch((err) => {
        if (err.code === "EUNSPECIFIED") {
          openInMarketplace({
            appName,
            appStoreId,
            appStoreLocale,
            playStoreId: packageToTry,
          });
        }
      });
    } catch (error) {
      logger(
        "Failed to open unsupported scheme",
        {
          url,
          error,
        },
        LOGGER_LEVEL_WARN
      );
    }
  } else {
    return Linking.openURL(url).catch((err) => {
      if (err.code === "EUNSPECIFIED") {
        openInMarketplace({ appName, appStoreId, appStoreLocale, playStoreId });
      }
    });
  }
}

export function ensureHandlableLink(url) {
  try {
    const { origin, href, hostname } = new URL(url);
    if (hostname === "expo-development-client") {
      return;
    }
    return href.replace(origin, "");
  } catch {}
}

export function checkLinkResult(link, config) {
  if (!link) {
    return {};
  }
  const linkState = getStateFromPath(link, config);
  if (!linkState) {
    return {
      isKnownRoute: false,
      didDeepLinkLand: false,
      linkPath: link,
    };
  }
  const linkPath = getPathFromState(linkState, config);
  const currentNavigationState = getRootNavigationState();
  const currentNavigationPath = getPathFromState(
    currentNavigationState,
    config
  );
  if (linkPath === currentNavigationPath) {
    return {
      isKnownRoute: true,
      didDeepLinkLand: true,
      linkPath,
    };
  } else {
    return {
      isKnownRoute: true,
      didDeepLinkLand: false,
      linkPath,
    };
  }
}

export function getURLFromNotification(notification) {
  return get("data.url", notification);
}

export function getLinkingURL() {
  return Platform.select({
    default: () => new URL(createURL("/")),
    native: () => new URL(`https://${StaticConfig.LINKING_ASSOCIATED_DOMAIN}`),
  })();
}
