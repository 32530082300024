import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

const paddingHorizontal = 24;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  listHeaderContainer: {
    paddingTop: 16,
    paddingHorizontal: paddingHorizontal,
  },
  searchInput: {
    backgroundColor: Palette.TRANSPARENT,
    borderWidth: 2,
    borderColor: Palette.NUDE_100,
  },
  listItem: {
    paddingHorizontal: paddingHorizontal,
    paddingVertical: 8,
    ...Shadows.xs,
  },
  listItemInner: {
    padding: 16,
    flex: 1,
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: Palette.WHITE,
    borderRadius: 12,
  },
});
