import Calendar from "./Calendar.svg";
import Clock from "./Clock.svg";
import CloseMono from "./CloseMono.svg";
import ClockMono from "./ClockMono.svg";
import ContactInfo from "./ContactInfo.svg";
import CreditCard from "./CreditCard.svg";
import ChildSickness from "./ChildSickness.svg";
import Documents from "./Documents.svg";
import DocumentsMono from "./DocumentsMono.svg";
import Email from "./Email.svg";
import EmailMono from "./EmailMono.svg";
import Export from "./Export.svg";
import History from "./History.svg";
import Insurance from "./Insurance.svg";
import Language from "./Language.svg";
import Notification from "./Notification.svg";
import Pacifier from "./Pacifier.svg";
import Pharmacy from "./Pharmacy.svg";
import PencilMono from "./PencilMono.svg";
import Phone from "./Phone.svg";
import PinCode from "./Pincode.svg";
import Prescription from "./Prescription.svg";
import Stroller from "./Stroller.svg";
import SinusInflammation from "./SinusInflammation.svg";
import Thermometer from "./Thermometer.svg";
import TouchId from "./TouchId.svg";
import Travel from "./Travel.svg";
import Vaccine from "./Vaccine.svg";
import Video from "./Video.svg";
import Percentage from "./Percentage.svg";
import NavigationControlBackMono from "./NavigationControlBackMono.svg";
import LogoutMono from "./LogoutMono.svg";
import AccountMono from "./AccountMono.svg";
import HomeMono from "./HomeMono.svg";
import InboxMono from "./InboxMono.svg";
import PhysiciansMono from "./PhysiciansMono.svg";
import CheckboxOnMono from "./CheckboxOnMono.svg";
import CheckboxOffMono from "./CheckboxOffMono.svg";
import RadioButtonOnMono from "./RadioButtonOnMono.svg";
import RadioButtonOffMono from "./RadioButtonOffMono.svg";
import ChevronRightMono from "./ChevronRightMono.svg";
import CircledQuestionMarkMono from "./CircledQuestionMarkMono.svg";
import Call from "./Call.svg";
import CameraFlip from "./CameraFlip.svg";
import CameraOffMono from "./CameraOffMono.svg";
import CameraOnMono from "./CameraOnMono.svg";
import MicrophoneOffMono from "./MicrophoneOffMono.svg";
import MicrophoneOnMono from "./MicrophoneOnMono.svg";
import ArrowRightMono from "./ArrowRightMono.svg";
import ColdFlue from "./ColdFlue.svg";
import DoctorSecondary from "./DoctorSecondary.svg";
import DocumentWarning from "./DocumentWarning.svg";
import FourAndFiveG from "./FourAndFiveG.svg";
import Headache from "./Headache.svg";
import MDArrowRoundUpMono from "./MDArrowRoundUpMono.svg";
import MDArrowDropDownOutlinedMono from "./MDArrowDropDownOutlinedMono.svg";
import SendMono from "./SendMono.svg";
import TreatmentTools from "./TreatmentTools.svg";
import VideoCamera from "./VideoCamera.svg";
import AddCircleOutlineMono from "./AddCircleOutlineMono.svg";
import AlertMono from "./AlertMono.svg";
import AlertTriangleMono from "./AlertTriangleMono.svg";
import InformalMono from "./InformalMono.svg";
import SuccessMono from "./SuccessMono.svg";
import UserInformation from "./UserInformation.svg";
import MoonMono from "./MoonMono.svg";
import DropIn from "./DropIn.svg";
import EyrLogo from "./EyrLogo.svg";
import CalendarTime from "./CalendarTime.svg";
import AddMono from "./AddMono.svg";
import GlobeMono from "./GlobeMono.svg";
import FlagNorway from "./FlagNorway.svg";
import FlagDenmark from "./FlagDenmark.svg";
import FlagSweden from "./FlagSweden.svg";
import OneStarsDisabled from "./OneStarsDisabled.svg";
import TwoStarsDisabled from "./TwoStarsDisabled.svg";
import ThreeStarsDisabled from "./ThreeStarsDisabled.svg";
import FourStarsDisabled from "./FourStarsDisabled.svg";
import FiveStarsDisabled from "./FiveStarsDisabled.svg";
import OneStarsEnabled from "./OneStarsEnabled.svg";
import TwoStarsEnabled from "./TwoStarsEnabled.svg";
import ThreeStarsEnabled from "./ThreeStarsEnabled.svg";
import FourStarsEnabled from "./FourStarsEnabled.svg";
import FiveStarsEnabled from "./FiveStarsEnabled.svg";
import UserInformationSecondary from "./UserInformationSecondary.svg";
import Support from "./Support.svg";
import CallMono from "./CallMono.svg";
import MailMono from "./MailMono.svg";
import ExternalLinkMono from "./ExternalLinkMono.svg";
import ChatMono from "./ChatMono.svg";
import Advice from "./Advice.svg";
import ChevronDownMono from "./ChevronDownMono.svg";
import ChevronUpMono from "./ChevronUpMono.svg";
import SearchMono from "./SearchMono.svg";
import TimeMono from "./TimeMono.svg";
import OrderConfirmation from "./OrderConfirmation.svg";
import SMSNotification from "./SMSNotification.svg";
import ShareMono from "./ShareMono.svg";
import DownloadMono from "./DownloadMono.svg";
import ForwardMono from "./ForwardMono.svg";
import BackwardMono from "./BackwardMono.svg";
import PlayMono from "./PlayMono.svg";
import PauseMono from "./PauseMono.svg";
import Questionnaire from "./Questionnaire.svg";
import MediaAudio from "./MediaAudio.svg";
import MediaVideo from "./MediaVideo.svg";
import Article from "./Article.svg";
import TreatmentProgram from "./TreatmentProgram.svg";
import Exercise from "./Exercise.svg";
import PlayCircleMono from "./PlayCircleMono.svg";
import ExpandMono from "./ExpandMono.svg";
import Employee from "./Employee.svg";
import Child from "./Child.svg";
import MySelf from "./MySelf.svg";
import ChildAdd from "./ChildAdd.svg";
import ProductVaccineBoostrix from "./ProductVaccineBoostrix.svg";
import ProductVaccineFlu from "./ProductVaccineFlu.svg";
import ProductVaccineHPV from "./ProductVaccineHPV.svg";
import ProductVaccineMeningites from "./ProductVaccineMeningites.svg";
import ProductVaccinePneumonia from "./ProductVaccinePneumonia.svg";
import ProductVaccineTick from "./ProductVaccineTick.svg";
import PlaceholderIconMono from "./PlaceholderIconMono.svg";
import CheckMono from "./CheckMono.svg";
import Allergy from "./Allergy.svg";
import Asthma from "./Asthma.svg";
import Contraception from "./Contraception.svg";
import ErectileDysfunction from "./ErectileDysfunction.svg";
import EyeInfection from "./EyeInfection.svg";
import Heartburn from "./Heartburn.svg";
import Indigestion from "./Indigestion.svg";
import Insomnia from "./Insomnia.svg";
import MuscleSkeleton from "./MuscleSkeleton.svg";
import PregnantBreastfeeding from "./PregnantBreastfeeding.svg";
import Psychological from "./Psychological.svg";
import SkinDiseases from "./SkinDiseases.svg";
import UrinaryInfection from "./UrinaryInfection.svg";
import DeleteMono from "./DeleteMono.svg";
import UploadMono from "./UploadMono.svg";
import FileAudioMono from "./FileAudioMono.svg";
import FileExcelMono from "./FileExcelMono.svg";
import FileGeneralMono from "./FileGeneralMono.svg";
import FileImageMono from "./FileImageMono.svg";
import FilePDFMono from "./FilePDFMono.svg";
import FilePowerpointMono from "./FilePowerpointMono.svg";
import FileVideoMono from "./FileVideoMono.svg";
import FileWordMono from "./FileWordMono.svg";
import InformationCircleMono from "./InformationCircleMono.svg";
import Fear from "./Fear.svg";
import LifeEvents from "./LifeEvents.svg";
import LowSelfEsteem from "./LowSelfEsteem.svg";
import ProductPatientGuide from "./ProductPatientGuide.svg";
import ProductMentalSelfHelp from "./ProductMentalSelfHelp.svg";
import CheckCircleMono from "./CheckCircleMono.svg";
import WarningAmberOutlinedMono from "./WarningAmberOutlinedMono.svg";
import InfoOutlinedMono from "./InfoOutlinedMono.svg";
import ErrorOutlineOutlinedMono from "./ErrorOutlineOutlinedMono.svg";
import CheckCircleOutlineOutlinedMono from "./CheckCircleOutlineOutlinedMono.svg";
import AppSettingsAltOutlinedMono from "./AppSettingsAltOutlinedMono.svg";
import AppShortcutOutlinedMono from "./AppShortcutOutlinedMono.svg";
import ChatOutlinedMono from "./ChatOutlinedMono.svg";
import InjurymapLogo from "./InjurymapLogo.svg";
import VideoCameraFrontOutlinedMono from "./VideoCameraFrontOutlinedMono.svg";
import ListAltOutlinedMono from "./ListAltOutlinedMono.svg";
import SyncOutlinedMono from "./SyncOutlinedMono.svg";

export const SVGs = {
  Advice,
  OneStarsDisabled,
  TwoStarsDisabled,
  ThreeStarsDisabled,
  FourStarsDisabled,
  FiveStarsDisabled,
  OneStarsEnabled,
  TwoStarsEnabled,
  ThreeStarsEnabled,
  FourStarsEnabled,
  FiveStarsEnabled,
  CalendarTime,
  DropIn,
  AccountMono,
  Calendar,
  Clock,
  CloseMono,
  ClockMono,
  ContactInfo,
  CreditCard,
  ChildSickness,
  CheckboxOnMono,
  CheckboxOffMono,
  Documents,
  DocumentsMono,
  Email,
  EmailMono,
  Export,
  HomeMono,
  History,
  Insurance,
  InboxMono,
  Language,
  LogoutMono,
  Notification,
  Pacifier,
  Percentage,
  Pharmacy,
  PhysiciansMono,
  PencilMono,
  Phone,
  PinCode,
  Prescription,
  RadioButtonOnMono,
  RadioButtonOffMono,
  Stroller,
  SinusInflammation,
  Thermometer,
  TouchId,
  Travel,
  Vaccine,
  Video,
  NavigationControlBackMono,
  ChevronRightMono,
  CircledQuestionMarkMono,
  ArrowRightMono,
  Call,
  CameraFlip,
  CameraOnMono,
  CameraOffMono,
  MicrophoneOnMono,
  MicrophoneOffMono,
  VideoCamera,
  FourAndFiveG,
  ColdFlue,
  Headache,
  DoctorSecondary,
  SendMono,
  TreatmentTools,
  DocumentWarning,
  MDArrowRoundUpMono,
  MDArrowDropDownOutlinedMono,
  AddCircleOutlineMono,
  AlertMono,
  AlertTriangleMono,
  InformalMono,
  SuccessMono,
  UserInformation,
  MoonMono,
  EyrLogo,
  AddMono,
  GlobeMono,
  FlagNorway,
  FlagDenmark,
  FlagSweden,
  UserInformationSecondary,
  Support,
  CallMono,
  MailMono,
  ExternalLinkMono,
  ChatMono,
  ChevronDownMono,
  ChevronUpMono,
  SearchMono,
  TimeMono,
  OrderConfirmation,
  SMSNotification,
  ShareMono,
  DownloadMono,
  ForwardMono,
  BackwardMono,
  PlayMono,
  PauseMono,
  Questionnaire,
  MediaAudio,
  MediaVideo,
  Article,
  TreatmentProgram,
  Exercise,
  PlayCircleMono,
  ExpandMono,
  Employee,
  Child,
  MySelf,
  ChildAdd,
  ProductVaccineBoostrix,
  ProductVaccineFlu,
  ProductVaccineHPV,
  ProductVaccineMeningites,
  ProductVaccinePneumonia,
  ProductVaccineTick,
  PlaceholderIconMono,
  CheckMono,
  Allergy,
  Asthma,
  Contraception,
  ErectileDysfunction,
  EyeInfection,
  Heartburn,
  Indigestion,
  Insomnia,
  MuscleSkeleton,
  PregnantBreastfeeding,
  Psychological,
  SkinDiseases,
  UrinaryInfection,
  DeleteMono,
  UploadMono,
  FileAudioMono,
  FileExcelMono,
  FileGeneralMono,
  FileImageMono,
  FilePDFMono,
  FilePowerpointMono,
  FileVideoMono,
  FileWordMono,
  InformationCircleMono,
  Fear,
  LifeEvents,
  LowSelfEsteem,
  ProductPatientGuide,
  ProductMentalSelfHelp,
  CheckCircleMono,
  WarningAmberOutlinedMono,
  InfoOutlinedMono,
  ErrorOutlineOutlinedMono,
  CheckCircleOutlineOutlinedMono,
  AppSettingsAltOutlinedMono,
  AppShortcutOutlinedMono,
  ChatOutlinedMono,
  InjurymapLogo,
  VideoCameraFrontOutlinedMono,
  ListAltOutlinedMono,
  SyncOutlinedMono,
};
