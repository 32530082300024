import { defineMessages } from "react-intl";

export const messages = defineMessages({
  text: {
    id: "HomeScreen.text",
    description: "Home screen text",
    defaultMessage: "Have a video call with a doctor or psychologist",
  },
  primaryActionTitle: {
    id: "HomeScreen.primaryActionTitle",
    description: "Sign in button title",
    defaultMessage: "Sign in",
  },
  creditText: {
    id: "HomeScreen.creditText",
    description: "Text saying Eyr provides it",
    defaultMessage: "Delivered by",
  },
});
