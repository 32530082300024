import React, { useCallback } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { FlatList, View } from "react-native";
import { map } from "lodash/fp";
import { IntlLanguages, useIntl } from "@eyr-mobile/core/Intl";
import { usePreference } from "@eyr-mobile/domain/Preference";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res";
import { Paragraph, ListItemSeparator, ListItem } from "../../components";

import { styles, stylingProps } from "./EditAccountLanguageScreen.styles";
import { messages } from "./EditAccountLanguageScreen.messages";

const languagePickerItems = map(
  (language) => ({
    value: language.code,
    key: language.code,
    label: language.name,
  }),
  IntlLanguages
);

export function EditAccountLanguageScreen() {
  const { locale } = useIntl();
  const { setPreferableLanguage } = usePreference();

  const handleRenderItem = useCallback(
    ({ item: { label, key, ...rest } }) => (
      <ListItem {...rest} layout="1" onPress={() => setPreferableLanguage(key)}>
        <View style={styles.listItem}>
          <Paragraph size="l">{label}</Paragraph>
          {locale === key && <SVGs.CheckMono {...stylingProps.icon} />}
        </View>
      </ListItem>
    ),
    [locale, setPreferableLanguage]
  );

  const { screenSizeSelect } = useDevice();
  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <FlatList
        contentContainerStyle={screenSizeSelect({
          s: styles.contentContainerStyleS,
          m: styles.contentContainerStyleM,
        })}
        data={languagePickerItems}
        renderItem={handleRenderItem}
        ItemSeparatorComponent={ListItemSeparator}
      />
    </SafeAreaView>
  );
}

EditAccountLanguageScreen.routeName = "EditAccountLanguageScreen";
EditAccountLanguageScreen.navigationOptions = {
  title: messages.navigationTitle,
};
