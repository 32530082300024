import { useState, useEffect } from "react";
import { AppState as RNAppState } from "react-native";
import { noop } from "lodash/fp";

export const AppState = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  BACKGROUND: "background",
};

export function useAppState({
  onChange = noop,
  onActive = noop,
  onInactive = noop,
  onBackground = noop,
  skip = false,
} = {}) {
  const [appState, setAppState] = useState(RNAppState.currentState);

  useEffect(() => {
    if (skip) {
      return;
    }
    function handleAppStateChange(nextAppState) {
      if (appState === nextAppState) {
        return;
      }
      if (nextAppState === AppState.ACTIVE) {
        onActive(appState);
      } else if (nextAppState === AppState.INACTIVE) {
        onInactive(appState);
      } else if (nextAppState === AppState.BACKGROUND) {
        onBackground(appState);
      }
      onChange(nextAppState);
      setAppState(nextAppState);
    }
    const subscription = RNAppState.addEventListener(
      "change",
      handleAppStateChange
    );

    return () => subscription.remove();
  }, [onChange, onActive, onBackground, appState, skip, onInactive]);
  return { appState };
}
