import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  inputsContainer: { flexDirection: "row" },
  spacer: {
    flex: 1,
  },
  smallInputContainer: { flex: 2 },
  errorsContainer: { height: 70 },
  footerContainer: {
    alignItems: "center",
  },
});
