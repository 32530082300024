import React, { useCallback, useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  get,
  map,
  find,
  noop,
  flow,
  pickBy,
  keys,
  getOr,
  join,
  isEmpty,
  compact,
  isUndefined,
} from "lodash/fp";
import { useIntl } from "react-intl";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView, useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";
import {
  AppPermissions,
  useAppPermissions,
} from "@eyr-mobile/core/AppPermissions";
import { useAlert } from "@eyr-mobile/core/Alert";
import { View, Platform, Linking } from "react-native";
import { withHandlers, useMutation } from "@eyr-mobile/core/DataProvider";
import {
  GetFlowStageSetPaymentMethodsData,
  ProductType,
  useFinishOrder,
  useOrder,
  useOrderFlowStage,
  useSetPaymentMethodsFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";
import { UpdateAccount } from "@eyr-mobile/domain/Account";
import { useForm } from "@eyr-mobile/core/Form";
import {
  validateIfNotBlank,
  configurePhoneNumberValidator,
} from "@eyr-mobile/core/Validation";
import { RawIntlProvider } from "@eyr-mobile/core/Intl";
import { usePrevious } from "@eyr-mobile/core/Lib";

import {
  OrderSummary,
  CancelOrderHeaderRight,
  EyrButton,
  ProgressIndicator,
  IconContainer,
  EyrFormField,
  EyrPickerSelect,
  EyrTextInput,
  Alert,
} from "../../components";
import { SVGs } from "../../res";

import { messages } from "./FlowStageSetPaymentMethodsScreen.messages";
import { styles } from "./FlowStageSetPaymentMethodsScreen.styles";

const HARDCODED_permissionsPerProductType = {
  [ProductType.CONSULTATION]: Platform.select({
    native: [
      AppPermissions.CAMERA,
      AppPermissions.MICROPHONE,
      AppPermissions.NOTIFICATION,
    ],
    default: [],
  }),
  [ProductType.PSYCHOLOGIST_CONSULTATION]: Platform.select({
    native: [
      AppPermissions.CAMERA,
      AppPermissions.MICROPHONE,
      AppPermissions.NOTIFICATION,
    ],
    default: [],
  }),
  [ProductType.GUIDE_CONSULTATION]: Platform.select({
    native: [
      AppPermissions.CAMERA,
      AppPermissions.MICROPHONE,
      AppPermissions.NOTIFICATION,
    ],
    default: [],
  }),
  [ProductType.VACCINATION]: Platform.select({
    native: [AppPermissions.NOTIFICATION],
    default: [],
  }),
};

const getNotGrantedPermissionsFormatter = (formatMessage) =>
  flow([
    get("permissions"),
    pickBy({ granted: false }),
    keys,
    map((permission) =>
      formatMessage(getOr(permission, `${permission}PermissionName`, messages))
    ),
    join(", "),
  ]);

const getPhoneNumberLengthForCountryPhoneCode = (codePhone, countries) => {
  return get("phoneNumberLength", find({ codePhone }, countries || []));
};

function PhoneContactInfoForm({
  countries,
  initialPhoneCountryCode,
  initialPhoneNumber,
  onSubmit = noop,
}) {
  const { formatMessage } = useIntl();
  const [validators, setValidators] = useState();
  const handleSubmit = useCallback(
    (fields) => {
      onSubmit({ ...fields });
    },
    [onSubmit]
  );
  const { form, fields } = useForm(
    {
      initialValues: {
        phoneCountryCode: initialPhoneCountryCode
          ? initialPhoneCountryCode
          : "",
        phoneNumber: initialPhoneNumber ? initialPhoneNumber : "",
      },
      validators,
      onSubmit: handleSubmit,
    },
    {
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    }
  );
  const phoneNumberLength = useMemo(
    () =>
      getPhoneNumberLengthForCountryPhoneCode(
        fields.phoneCountryCode.value,
        countries
      ),
    [countries, fields.phoneCountryCode.value]
  );

  const previousPhoneCountryCode = usePrevious(fields.phoneCountryCode.value);
  const previousValidators = usePrevious(validators);
  const validateForm = form.validate;
  const onPhoneNumberChange = useCallback(
    (nextPhoneNumber) => {
      return fields.phoneNumber.onChange(nextPhoneNumber.replace(/\D/g, ""));
    },
    [fields.phoneNumber]
  );
  const phoneCodes = useMemo(
    () =>
      map(
        ({ id, codePhone }) => ({
          key: id,
          label: `+${codePhone}`,
          value: codePhone,
        }),
        countries
      ),
    [countries]
  );

  useEffect(() => {
    const phoneCountryCode = fields.phoneCountryCode.value;
    if (phoneCountryCode !== previousPhoneCountryCode) {
      setValidators({
        phoneNumber: validateIfNotBlank(
          configurePhoneNumberValidator(
            getPhoneNumberLengthForCountryPhoneCode(phoneCountryCode, countries)
          )
        ),
      });
    }
  }, [
    countries,
    fields.phoneCountryCode.value,
    form,
    previousPhoneCountryCode,
  ]);

  useEffect(() => {
    if (validators !== previousValidators) {
      validateForm();
    }
  }, [validateForm, validators, previousValidators]);

  return (
    <View>
      <EyrFormField
        helperText={
          fields.phoneNumber.invalid && fields.phoneNumber.unfocused
            ? formatMessage(messages.phoneNumberFieldInvalidError)
            : undefined
        }
        error={fields.phoneNumber.invalid && fields.phoneNumber.unfocused}
      >
        <View style={styles.phoneContainer}>
          <View style={styles.phoneCodeControlContainer}>
            <EyrPickerSelect
              items={phoneCodes}
              {...fields.phoneCountryCode}
              onValueChange={(value) => {
                fields.phoneCountryCode.onFocus();
                fields.phoneCountryCode.onChange(value);
                fields.phoneCountryCode.onBlur();
              }}
              testID={"phoneCountryCode"}
            />
          </View>
          <View style={styles.phoneNumberControlContainer}>
            <EyrTextInput
              keyboardType="numeric"
              returnKeyType="done"
              maxLength={phoneNumberLength}
              {...fields.phoneNumber}
              onChangeText={onPhoneNumberChange}
              testID={"phoneNumber"}
              variant={
                fields.phoneNumber.invalid && fields.phoneNumber.unfocused
                  ? "danger"
                  : "primary"
              }
              placeholder={formatMessage(messages.phoneNumberFieldLabel)}
            />
          </View>
        </View>
      </EyrFormField>
      <View style={styles.phoneNumberFieldButtonContainer}>
        <EyrButton
          title={formatMessage(messages.phoneNumberAlertSaveActionTitle)}
          variant={"primary"}
          disabled={!(fields.phoneNumber.dirty && form.valid)}
          onPress={form.submit}
        />
      </View>
    </View>
  );
}

PhoneContactInfoForm.propTypes = {
  initialPhoneCountryCode: PropTypes.string,
  initialPhoneNumber: PropTypes.string,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      codePhone: PropTypes.string,
      phoneNumberLength: PropTypes.number,
    })
  ),
  onSubmit: PropTypes.func,
};

const errorCodesToErrorMessages = {
  cancelled: messages.cancelledError,
  authorization: messages.authorizationError,
  failed: messages.failedError,
  capture: messages.captureError,
};

export function FlowStageSetPaymentMethodsScreen() {
  const intl = useIntl();
  const { formatMessage } = intl;
  const alert = useAlert();
  const { screenSizeSelect } = useDevice();
  const insets = useSafeAreaInsets();
  const { setOptions } = useNavigation();

  const [updateAccount] = useMutation(UpdateAccount);
  const {
    order,
    editOrderStage,
    orderHasPaymentMethods,
    orderHasEveryStageFilled,
  } = useOrder();
  const [finishOrder, { loading: finishOrderIsInProgress, error }] =
    useFinishOrder();

  const [{ choosePrimaryPaymentMethod }] = useSetPaymentMethodsFlowStage();

  const {
    data: { account, countries } = {},
    stageParams: { progress, product: { type: productType } = {} } = {},
    refreshing,
    refetch,
    handlers,
  } = withHandlers(
    useOrderFlowStage(GetFlowStageSetPaymentMethodsData, ({ orderId }) => ({
      variables: { orderId },
      fetchPolicy: "network-only",
    }))
  );

  useEffect(() => {
    if (isUndefined(progress)) {
      return;
    }
    setOptions({
      title: formatMessage(
        progress === 1
          ? messages.completedNavigationTitle
          : messages.initialNavigationTitle
      ),
    });
  }, [formatMessage, progress, setOptions]);

  const { permissions, requestPermissionsAsync } = useAppPermissions(
    getOr([], productType, HARDCODED_permissionsPerProductType)
  );

  const shouldShowPhoneContactInfoForm = useMemo(
    () =>
      Boolean(
        Platform.OS === "web" &&
          [
            ProductType.CONSULTATION,
            ProductType.PSYCHOLOGIST_CONSULTATION,
            ProductType.GUIDE_CONSULTATION,
          ].includes(order?.product?.type) &&
          isEmpty(account?.phoneNumber)
      ),
    [account?.phoneNumber, order?.product?.type]
  );

  const handleSubmitContactInfo = useCallback(
    (contactInfo) =>
      updateAccount({
        variables: {
          input: contactInfo,
        },
      }),
    [updateAccount]
  );

  const handleFinishOrder = useCallback(async () => {
    if (permissions?.granted) {
      return finishOrder();
    }
    const permissionsRequestResponse = await requestPermissionsAsync();
    if (permissionsRequestResponse.granted) {
      return finishOrder();
    }
    const formatter = getNotGrantedPermissionsFormatter(formatMessage);
    const formattedNotGrantedPermissions = formatter(
      permissionsRequestResponse
    );
    alert({
      title: formatMessage(messages.permissionsAlertTitle),
      message: formatMessage(messages.openSettingsRequestAlertMessage, {
        permissions: formattedNotGrantedPermissions,
      }),
      buttonsLayout: "column",
      buttons: [
        {
          title: formatMessage(messages.permissionsAlertOpenSettingsButton),
          variant: "primary",
          onPress: () =>
            Linking.openSettings().catch(() =>
              alert({
                title: formatMessage(messages.permissionsAlertTitle),
                message: formatMessage(
                  messages.unableToOpenSettingsAlertMessage,
                  {
                    permissions: formattedNotGrantedPermissions,
                  }
                ),
                buttons: [
                  {
                    title: formatMessage(messages.permissionsAlertGotItButton),
                    variant: "secondary",
                    onPress: noop,
                  },
                ],
              })
            ),
        },
        {
          title: formatMessage(messages.permissionsAlertCancelButton),
          variant: "secondary",
          onPress: noop,
        },
      ],
    });
  }, [
    permissions?.granted,
    requestPermissionsAsync,
    formatMessage,
    alert,
    finishOrder,
  ]);

  const phoneContactInfoFormAlertVariant = screenSizeSelect({
    xs: "bottomSheet",
    s: "modal",
  });

  const handleShowPhoneContactInfoForm = useCallback(() => {
    const closeAlert = alert({
      variant: phoneContactInfoFormAlertVariant,
      showCloseButton: true,
      icon: (
        <IconContainer variant="round" size="xl">
          <SVGs.SMSNotification />
        </IconContainer>
      ),
      message: formatMessage(messages.phoneNumberAlertMessage),
      children: (
        <RawIntlProvider value={intl}>
          <PhoneContactInfoForm
            countries={countries}
            initialPhoneCountryCode={account?.country?.codePhone}
            initialPhoneNumber={account?.phoneNumber}
            onSubmit={async (contactInfo) => {
              await handleSubmitContactInfo(contactInfo);
              await handleFinishOrder();
              closeAlert();
            }}
          />
        </RawIntlProvider>
      ),
    });
  }, [
    phoneContactInfoFormAlertVariant,
    intl,
    handleFinishOrder,
    formatMessage,
    alert,
    account,
    countries,
    handleSubmitContactInfo,
  ]);

  const submitButtonTitleMessageDescriptor = (() => {
    switch (true) {
      case !orderHasPaymentMethods:
        return "choosePaymentMethod";
      case shouldShowPhoneContactInfoForm:
        return "continueActionTitle";
      default:
        return "confirmOrder";
    }
  })();

  const submitButtonOnPressAction = (() => {
    switch (true) {
      case !orderHasPaymentMethods:
        return choosePrimaryPaymentMethod;
      case shouldShowPhoneContactInfoForm:
        return handleShowPhoneContactInfoForm;
      default:
        return handleFinishOrder;
    }
  })();

  const submitButtonDisabled = (() => {
    switch (true) {
      case !orderHasPaymentMethods:
        return false;
      case !orderHasEveryStageFilled:
        return true;
      case finishOrderIsInProgress:
        return true;
    }
  })();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progress}
            accessibilityLabel={`Order progress indicator ${progress * 100}%`}
          />
          <View style={styles.contentContainer}>
            <OrderSummary
              order={order}
              editableStages={[
                "FlowStageSetMedicalCategory",
                "FlowStageAttachForm",
                "FlowStageSpecifyAppointmentInformation",
                "FlowStageSelectClient",
                "FlowStageSetPaymentMethods",
              ]}
              onOrderStageSelected={editOrderStage}
              contentContainerStyle={screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              })}
              refreshing={refreshing}
              onRefresh={refetch}
            />
          </View>
          <View style={styles.bottomBar}>
            <View
              style={compact([
                styles.confirmOrderContainer,
                screenSizeSelect({
                  s: styles.confirmOrderContainerS,
                  xs: styles.confirmOrderContainerXS,
                  m: styles.confirmOrderContainerM,
                }),
                { paddingBottom: insets.bottom },
              ])}
            >
              {error && (
                <View style={styles.alertContainer}>
                  <Alert
                    variant={"warning"}
                    title={
                      errorCodesToErrorMessages[error]
                        ? formatMessage(errorCodesToErrorMessages[error])
                        : error
                    }
                  />
                </View>
              )}
              <EyrButton
                title={formatMessage(
                  messages[submitButtonTitleMessageDescriptor]
                )}
                accessibilityLabel={formatMessage(
                  messages[submitButtonTitleMessageDescriptor]
                )}
                onPress={submitButtonOnPressAction}
                disabled={submitButtonDisabled}
                size={"l"}
                variant={"primary"}
              />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetPaymentMethodsScreen.routeName = "FlowStageSetPaymentMethodsScreen";
FlowStageSetPaymentMethodsScreen.navigationOptions = {
  title: null,
  headerRight: CancelOrderHeaderRight,
};
