import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  topSpacer: {
    marginBottom: "auto",
    height: 70,
    alignItems: "flex-end",
  },
  container: {
    justifyContent: "center",
    padding: 16,
  },
  videoContainer: {
    height: "100%",
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 16,
  },
  progressContainer: {
    marginTop: "auto",
  },
});

export const stylingProps = {
  fullScreenToggleIcon: {
    width: 24,
    height: 24,
    fill: Palette.WHITE,
  },
};
