import { defineMessages } from "react-intl";

export const messages = defineMessages({
  toggleCameraTypeActionTitle: {
    id: "CallControlsOverlay.toggleCameraTypeActionTitle",
    description: "Toggle camera position action title",
    defaultMessage: "Flip",
  },
  toggleCameraActionTitle: {
    id: "CallControlsOverlay.toggleCameraActionTitle",
    description: "Toggle camera action title",
    defaultMessage: "Camera",
  },
  toggleMicrophoneActionTitle: {
    id: "CallControlsOverlay.toggleMicrophoneActionTitle",
    description:
      "Toggle microphone action title (keep the original message short to fit the view). This is supposed to be a shortcut for Microphone",
    defaultMessage: "Mic",
  },
  endCallActionTitle: {
    id: "CallControlsOverlay.endCallActionTitle",
    description: "End call action title",
    defaultMessage: "End",
  },
});
