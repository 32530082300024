import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Modal, View, Pressable, Animated, ScrollView } from "react-native";
import { map, noop, compact, size as lodashSize } from "lodash/fp";
import {
  ConditionalWrapper,
  renderIf,
  useOnUnmount,
} from "@eyr-mobile/core/Lib";
import { useDevice } from "@eyr-mobile/core/Device";
import { useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";

import { EyrButton } from "../EyrButton";
import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import { Subtitle } from "../Subtitle";
import { SVGs } from "../../res";

import { styles, stylingProps, stylingHelpers } from "./EyrAlert.styles";

const mapWithKey = map.convert({ cap: false });

const VARIANT = {
  MODAL: "modal",
  BOTTOM_SHEET: "bottomSheet",
  FULLSCREEN: "fullscreen",
};

export function EyrAlert({
  IconComponent,
  icon: iconProp,
  heading,
  title,
  message,
  buttons,
  buttonsContainerPosition = "bottom",
  buttonsLayout = "row",
  onClose = noop,
  onShow = noop,
  variant = VARIANT.MODAL,
  showCloseButton = false,
  showHeaderDivider = false,
  animated = true,
  responsive = true,
  disableRequestClose = false,
  disableOverlayClose = false,
  size = "m",
  children,
  paddingVerticalSize = "m",
  paddingHorizontalSize = "m",
  ...rest
}) {
  const overlayOpacity = useRef(new Animated.Value(animated ? 0 : 1)).current;
  const isLastButton = (key) => key === lodashSize(buttons) - 1;
  const buttonContainerStyle =
    buttonsLayout === "row"
      ? styles.rowLayoutButtonContainer
      : styles.columnLayoutButtonContainer;
  const lastButtonContainerStyle =
    buttonsLayout === "row" && styles.rowLayoutLastButtonContainer;
  const buttonsContainerStyle = {
    ...(message
      ? styles.buttonsContainerWithMessage
      : styles.buttonsContainerWithoutMessage),
    flexDirection: buttonsLayout,
  };
  const { screenSizeSelect } = useDevice();
  const insets = useSafeAreaInsets();

  const responsiveVariant = screenSizeSelect({
    xs: variant,
    s: responsive ? VARIANT.MODAL : variant,
  });

  const animationType =
    responsiveVariant === VARIANT.BOTTOM_SHEET && animated ? "slide" : "none";

  const containerStyle = stylingHelpers.getContainerStyle({
    variant: responsiveVariant,
  });

  const contentContainerStyle = stylingHelpers.getContentContainerStyle({
    variant: responsiveVariant,
  });

  const headerStyle = stylingHelpers.getHeaderStyle(showHeaderDivider);

  const bodyStyle = stylingHelpers.getBodyStyle(
    paddingHorizontalSize,
    paddingVerticalSize
  );

  const closeIconStyle = stylingHelpers.getCloseIconStyle({
    variant: responsiveVariant,
    insets,
  });

  const bottomBarStyle = stylingHelpers.getBottomBarStyle(insets);

  const isButtonsContainerFixed = buttonsContainerPosition === "bottomFixed";

  const buttonsContainer = (
    <View style={buttonsContainerStyle}>
      {mapWithKey(
        ({ onPress, ...button }, key) => (
          <View
            key={key.toString()}
            style={
              isLastButton(key)
                ? lastButtonContainerStyle
                : buttonContainerStyle
            }
          >
            <EyrButton
              size="l"
              onPress={() => {
                onClose();
                if (onPress) {
                  onPress();
                }
              }}
              {...button}
            />
          </View>
        ),
        buttons
      )}
    </View>
  );

  const handleOnShow = () => {
    Animated.timing(overlayOpacity, {
      toValue: 1,
      duration: 200,
      useNativeDriver: true,
    }).start();
    onShow();
  };

  useOnUnmount(onClose, [onClose]);

  const icon =
    iconProp || (IconComponent && <IconComponent {...stylingProps.icon} />);

  return (
    <>
      <Animated.View style={[styles.overlay, { opacity: overlayOpacity }]} />
      <Modal
        visible
        transparent
        presentationStyle={"overFullScreen"}
        {...rest}
        onRequestClose={disableRequestClose ? noop : onClose}
        animationType={animationType}
        onShow={handleOnShow}
      >
        <Pressable
          style={styles.invisibleOverlay}
          onPress={disableOverlayClose ? noop : onClose}
          accessibilityLabel={"Close alert"}
        />
        <View style={containerStyle}>
          <View
            style={compact([
              contentContainerStyle,
              responsiveVariant === VARIANT.MODAL &&
                screenSizeSelect({
                  s: stylingHelpers.getContentContainerResponsiveStyle({
                    size,
                  }),
                }),
              variant === VARIANT.FULLSCREEN && styles.noPadding,
            ])}
          >
            {renderIf(showCloseButton)(() => (
              <View style={closeIconStyle}>
                <EyrButton
                  onPress={onClose}
                  IconComponent={SVGs.CloseMono}
                  variant="secondary"
                  size="s"
                />
              </View>
            ))}

            {renderIf(heading)(() => (
              <View style={headerStyle}>
                <View style={styles.headingContainer}>
                  <Heading alignment="center" size="s">
                    {heading}
                  </Heading>
                </View>
              </View>
            ))}

            <ScrollView
              contentContainerStyle={bodyStyle}
              contentInsetAdjustmentBehavior="automatic"
            >
              {renderIf(icon)(<View style={styles.iconContainer}>{icon}</View>)}
              {renderIf(title)(() => (
                <ConditionalWrapper
                  Wrapper={View}
                  condition={showCloseButton && !heading && !icon}
                  style={styles.headingContainer}
                >
                  <Subtitle alignment="center" spacing="s" size="l">
                    {title}
                  </Subtitle>
                </ConditionalWrapper>
              ))}
              {renderIf(message)(() => (
                <Paragraph alignment="center" size="l">
                  {message}
                </Paragraph>
              ))}

              {children}

              {renderIf(buttons?.length && !isButtonsContainerFixed)(
                () => buttonsContainer
              )}
            </ScrollView>
            {renderIf(buttons?.length && isButtonsContainerFixed)(() => (
              <View style={bottomBarStyle}>{buttonsContainer}</View>
            ))}
          </View>
        </View>
      </Modal>
    </>
  );
}

EyrAlert.propTypes = {
  IconComponent: PropTypes.elementType,
  heading: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  buttons: PropTypes.array,
  buttonsContainerPosition: PropTypes.oneOf(["bottom", "bottomFixed"]),
  buttonsLayout: PropTypes.oneOf(["row", "column"]),
  onClose: PropTypes.func,
  onShow: PropTypes.func,
  icon: PropTypes.node,
  variant: PropTypes.oneOf(["modal", "bottomSheet", "fullscreen"]),
  animated: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showHeaderDivider: PropTypes.bool,
  responsive: PropTypes.bool,
  size: PropTypes.oneOf(["m", "l"]),
  children: PropTypes.node,
  paddingHorizontalSize: PropTypes.oneOf(["none", "s", "m", "l"]),
  paddingVerticalSize: PropTypes.oneOf(["none", "s", "m", "l"]),
  disableRequestClose: PropTypes.bool,
  disableOverlayClose: PropTypes.bool,
};
