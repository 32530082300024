import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const base = {
  container: {
    height: 44,
    flexDirection: "row",
    alignItems: "center",
  },
};

const baseProps = {
  icon: {
    width: 22,
    height: 22,
    fill: Palette.NUDE_100,
  },
};

export const styles = StyleSheet.create({
  primaryContainer: {
    ...base.container,
  },
  dangerContainer: {
    ...base.container,
  },
  icon: {
    marginRight: 11,
  },
  disabled: {
    opacity: 0.4,
  },
});

export const stylingProps = {
  primaryIcon: {
    ...baseProps.icon,
  },
  dangerIcon: {
    ...baseProps.icon,
    fill: Palette.RED_100,
  },
};
