export { getDocumentAsync } from "expo-document-picker";

export async function downloadAsync(url, fileUri, { headers }) {
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
  const blob = await response.blob();
  const blobUrl = window.URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = blobUrl;
  tempLink.download = fileUri;
  document.body.appendChild(tempLink);
  tempLink.click();
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL (https://bugzilla.mozilla.org/show_bug.cgi?id=1282407)
    window.URL.revokeObjectURL(blobUrl);
    tempLink.remove();
  }, 100);
  return { uri: blobUrl, headers: response.headers, status: response.status };
}

export async function deleteAsync() {}
export const documentDirectory = "";
