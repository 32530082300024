import { defineMessages } from "react-intl";

export const messages = defineMessages({
  headerTitle: {
    id: "SelectInsuranceOrDiscount.headerTitle",
    description: "SelectInsuranceOrDiscount add insurance or discount title",
    defaultMessage: "Verify your coverage",
  },
  headerDescription: {
    id: "SelectInsuranceOrDiscount.headerDescription",
    description:
      "SelectInsuranceOrDiscount add insurance or discount description",
    defaultMessage: "Add your insurance or membership to check your coverage",
  },
});
