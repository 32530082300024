import AsyncStorage from "@react-native-async-storage/async-storage";

const STORAGE_KEY_LINKING = "@Eyr:linking";

export function storeLinking(state) {
  return AsyncStorage.setItem(STORAGE_KEY_LINKING, JSON.stringify(state));
}

export function getStoredLinking() {
  return AsyncStorage.getItem(STORAGE_KEY_LINKING).then(JSON.parse);
}

export function clearStoredLinking() {
  return AsyncStorage.removeItem(STORAGE_KEY_LINKING);
}
