import React from "react";
import { get } from "lodash/fp";
import { useIntl } from "react-intl";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { renderIf } from "@eyr-mobile/core/Lib";
import {
  GetMedicalCategoriesAndEmergencyNumber,
  ProductType,
  useOrderFlowStage,
  useSetMedicalCategoryFlowStage,
} from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  SelectMedicalCategory,
  CancelOrderHeaderRight,
  ProgressIndicator,
  Alert,
} from "../../components";

import { styles } from "./FlowStageSetMedicalCategoryScreen.styles";
import { messages } from "./FlowStageSetMedicalCategoryScreen.messages";

export function FlowStageSetMedicalCategoryScreen() {
  const { formatMessage } = useIntl();

  const {
    data,
    handlers,
    stageParams: { progress, product: { type: productType } = {} } = {},
    refreshing,
    refetch,
  } = withHandlers(
    useOrderFlowStage(
      GetMedicalCategoriesAndEmergencyNumber,
      ({ orderId }) => ({
        variables: { orderId },
      })
    )
  );
  const [{ selectMedicalCategory }] = useSetMedicalCategoryFlowStage();
  const medicalCategories = get("medicalCategoriesForOrder", data);
  const emergencyNumber = get("account.country.emergencyNumber", data);
  const { screenSizeSelect } = useDevice();
  const allowEmergencyWarningBanner = productType === ProductType.CONSULTATION;

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progress}
            accessibilityLabel={`Order progress indicator ${progress * 100}%`}
          />
          {renderIf(emergencyNumber && allowEmergencyWarningBanner)(() => (
            <Alert
              collapsible
              responsive
              title={formatMessage(messages.emergencyWarningMessage, {
                emergencyNumber,
              })}
            />
          ))}
          <SelectMedicalCategory
            contentContainerStyle={screenSizeSelect({
              xs: [styles.containerXS, styles.contentContainerXS],
              s: [styles.containerS, styles.contentContainerS],
              m: [styles.containerM, styles.contentContainerM],
            })}
            medicalCategories={medicalCategories}
            refreshing={refreshing}
            onRefresh={refetch}
            onMedicalCategorySelected={selectMedicalCategory}
          />
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetMedicalCategoryScreen.routeName =
  "FlowStageSetMedicalCategoryScreen";
FlowStageSetMedicalCategoryScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
