import { defineMessages } from "react-intl";

export const messages = defineMessages({
  title: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.title",
    description: "title question for screen",
    defaultMessage: "Which country are you traveling in?",
  },
  searchInputPlaceholder: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.searchInputPlaceholder",
    description: "search input placeholder",
    defaultMessage: "Search countries",
  },
  confirmationAlertTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.confirmationAlertTitle",
    description: "alert title for confirming country selection",
    defaultMessage: "You are travelling in",
  },
  confirmCountrySelectionActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.confirmCountrySelectionActionTitle",
    description: "button text for confirming selected country",
    defaultMessage: "Continue",
  },
  alertCloseActionTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.alertCloseActionTitle",
    description: "button text for confirming selected country",
    defaultMessage: "Cancel",
  },
  listEmptyTitle: {
    id: "FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen.listEmptyTitle",
    description: "text displayed when list empty",
    defaultMessage: 'No results found for "{searchText}"',
  },
});
