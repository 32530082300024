import React from "react";
import PropTypes from "prop-types";
import { compact, getOr, includes } from "lodash/fp";
import { useIntl } from "react-intl";
import { View } from "react-native";
import { Grayscale } from "@eyr-mobile/core/ImageFilters";
import {
  defaultCurrencyDisplayRules,
  moneyShape,
  PaymentMethodType,
} from "@eyr-mobile/domain/Order";

import { SVGs } from "../../res";
import { Paragraph } from "../Paragraph";
import { Card } from "../Card";
import { Subtitle } from "../Subtitle";
import { ResAwareSvg } from "../ResAwareSvg";

import { styles, stylingProps } from "./PaymentMethodListItem.styles";
import { messages } from "./PaymentMethodListItem.messages";

const iconFor = {
  INSURANCE: SVGs.Insurance,
  DISCOUNT: SVGs.Percentage,
  CARD: SVGs.CreditCard,
  PaymentProvider: SVGs.CreditCard,
};

export function PaymentMethodListItem({
  name,
  description,
  type,
  image,
  disabled,
  disabledReason,
  priceInfoDisplayMode = "monetary-remainder",
  discountValue,
  primaryValue,
  secondaryValue,
  ...rest
}) {
  const { formatMessage, formatNumber } = useIntl();
  const Icon = getOr(SVGs.CreditCard, type, iconFor);
  // Grayscaling an SVG on native side is not possible ATM, so only works on web
  const IconWrapper = disabled ? Grayscale : View;
  let priceInfo;

  if (primaryValue && priceInfoDisplayMode === "monetary-remainder") {
    priceInfo = (
      <View style={styles.priceInfo}>
        <View style={styles.priceInfoTitleContainer}>
          <Paragraph>
            {formatMessage(messages.OTHER_monetaryRemainderTitle)}
          </Paragraph>
        </View>
        <Subtitle>
          {formatNumber(primaryValue.amount, {
            currency: primaryValue.currency,
            ...defaultCurrencyDisplayRules,
          })}
        </Subtitle>
        {secondaryValue && (
          <View style={styles.secondaryValueContainer}>
            <Paragraph decoration="strikethrough">
              {formatNumber(secondaryValue.amount, {
                currency: secondaryValue.currency,
                ...defaultCurrencyDisplayRules,
              })}
            </Paragraph>
          </View>
        )}
      </View>
    );
  } else if (discountValue && priceInfoDisplayMode === "coverage-value") {
    priceInfo = (
      <View style={styles.priceInfo}>
        <View style={styles.priceInfoTitleContainer}>
          <Paragraph>
            {formatMessage(messages.DISCOUNT_coverageValueTitle, {
              discountPercentage: formatNumber(discountValue, {
                style: "percent",
              }),
            })}
            :
          </Paragraph>
        </View>
        <Subtitle>
          {formatNumber(primaryValue.amount, {
            currency: primaryValue.currency,
            ...defaultCurrencyDisplayRules,
          })}
        </Subtitle>
      </View>
    );
  }

  return (
    <Card
      title={name}
      titleIsBold
      description={disabled ? disabledReason : description}
      icon={
        <IconWrapper
          style={compact([
            styles.imageContainer,
            image &&
              includes(type, [
                PaymentMethodType.INSURANCE,
                PaymentMethodType.DISCOUNT,
              ]) &&
              styles.remoteImageContainer,
          ])}
        >
          {image ? (
            <ResAwareSvg svg={image} {...stylingProps.image} />
          ) : (
            <Icon {...stylingProps.image} />
          )}
        </IconWrapper>
      }
      extraInfo={!disabled ? priceInfo : undefined}
      disabled={disabled}
      {...rest}
    />
  );
}

PaymentMethodListItem.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  IconComponent: PropTypes.elementType,
  type: PropTypes.oneOf(["CARD", "INSURANCE", "DISCOUNT", "PaymentProvider"]),
  disabled: PropTypes.bool,
  disabledReason: PropTypes.string,
  priceInfoDisplayMode: PropTypes.oneOf([
    "after-reduction",
    "coverage-value",
    "none",
  ]),
  primaryValue: moneyShape,
  secondaryValue: moneyShape,
  discountValue: PropTypes.number,
};
