import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { omit } from "lodash/fp";

import { SVGs } from "../../res";
import { Card } from "../Card";
import { IconContainer } from "../IconContainer";

import { messages } from "./SelectClientStageOrderSummaryItem.messages";

export function SelectClientStageOrderSummaryItem({
  forMe,
  otherPersonUid,
  ...rest
}) {
  const { formatMessage } = useIntl();

  return (
    <Card
      icon={
        <IconContainer size="s" variant="plain">
          {forMe ? <SVGs.MySelf /> : <SVGs.Child />}
        </IconContainer>
      }
      title={
        forMe
          ? formatMessage(messages.myself)
          : formatMessage(messages.otherPerson)
      }
      titleIsBold
      description={otherPersonUid}
      InteractivityIconComponent={SVGs.PencilMono}
      {...omit(["__typename", "orderId", "product", "stageId"], rest)}
    />
  );
}

SelectClientStageOrderSummaryItem.propTypes = {
  forMe: PropTypes.bool.isRequired,
  otherPersonUid: PropTypes.string,
};
