import React from "react";
import { Platform } from "react-native";
import { useIntl } from "react-intl";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import {
  getAuthorizationBearerHeader,
  injectWebWebViewAuth,
  useAuth,
} from "@eyr-mobile/domain/Auth";
import { getAdditionalHeaders } from "@eyr-mobile/core/Net";
import {
  applicationNameForUserAgent,
  disableScalingInjectedJavaScript,
  patchPostMessageInjectedJavaScript,
  WebView,
} from "@eyr-mobile/core/WebView";
import { useWebviewCheckoutForOrder } from "@eyr-mobile/domain/Order";

import { CancelOrderHeaderRight } from "../../components";

import { styles } from "./CheckoutScreen.styles";
import { messages } from "./CheckoutScreen.messages";

const injectedJavaScript =
  disableScalingInjectedJavaScript + patchPostMessageInjectedJavaScript;

export function CheckoutScreen() {
  const { locale } = useIntl();
  const { accessToken } = useAuth();
  const [{ checkoutUrl, handleWebViewMessage }] = useWebviewCheckoutForOrder();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      <WebView
        key={locale}
        source={Platform.select({
          default: {
            headers: {
              ...getAdditionalHeaders(),
              ...getAuthorizationBearerHeader(accessToken),
            },
            uri: checkoutUrl,
          },
          web: {
            uri: injectWebWebViewAuth(checkoutUrl, accessToken),
          },
        })}
        injectedJavaScript={injectedJavaScript}
        onMessage={handleWebViewMessage}
        applicationNameForUserAgent={applicationNameForUserAgent}
        testID={"Checkout"}
        web_postMessageTarget={"window"}
      />
    </SafeAreaView>
  );
}

CheckoutScreen.routeName = "CheckoutScreen";
CheckoutScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
