import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "DigitalToolsScreen.navigationTitle",
    description: "DigitalToolsScreen navigation title",
    defaultMessage: "Treatment tools",
  },
  listEmptyText: {
    id: "DigitalToolsScreen.listEmptyText",
    description: "text explaining that no digital tools",
    defaultMessage: "You have no new treatment tools from your clinician",
  },
  treatmentProgram: {
    id: "DigitalToolsScreen.treatmentProgram",
    description: "button text to navigate to text modules",
    defaultMessage: "Treatment program",
  },
});
