import React, { useEffect } from "react";
import { View, Image, Pressable } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useCall } from "@eyr-mobile/domain/Call";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Paragraph, Subtitle } from "../../components";
import { SVGs } from "../../res";

import { styles, stylingProps } from "./IncomingCallScreen.styles";
import { messages } from "./IncomingCallScreen.messages";

export function IncomingCallScreen() {
  const { formatMessage } = useIntl();
  const { addListener } = useNavigation();
  const {
    accepted,
    callerName,
    callerTitle,
    callerImageUrl,
    declineCall,
    answerCall,
  } = useCall();

  useEffect(() => {
    return addListener("beforeRemove", function () {
      if (!accepted) {
        declineCall();
      }
    });
  }, [accepted, addListener, declineCall]);

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <SVGs.EyrLogo style={styles.headerIcon} {...stylingProps.headerIcon} />
        <Paragraph size="l">{formatMessage(messages.headerTitle)}</Paragraph>
      </View>
      <View style={styles.callerInformationContainer}>
        <Image source={{ uri: callerImageUrl }} style={styles.callerImage} />
        <Subtitle size="l">{callerName}</Subtitle>
        {renderIf(callerTitle)(() => (
          <Paragraph size="l">{callerTitle}</Paragraph>
        ))}
      </View>
      <View style={styles.controlsContainer}>
        <Pressable style={styles.controlContainer} onPress={declineCall}>
          <View
            style={[styles.controlIconContainer, styles.declineIconContainer]}
          >
            <SVGs.CloseMono {...stylingProps.controlIcon} />
          </View>
          <Paragraph size="l">
            {formatMessage(messages.declineCallActionTitle)}
          </Paragraph>
        </Pressable>
        <Pressable style={styles.controlContainer} onPress={answerCall}>
          <View
            style={[styles.controlIconContainer, styles.acceptIconContainer]}
          >
            <SVGs.CameraOnMono {...stylingProps.controlIcon} />
          </View>
          <Paragraph size="l">
            {formatMessage(messages.answerCallActionTitle)}
          </Paragraph>
        </Pressable>
      </View>
    </View>
  );
}

IncomingCallScreen.routeName = "IncomingCallScreen";
IncomingCallScreen.navigationOptions = {
  headerShown: false,
  useResponsiveNavigationContainer: true,
};
