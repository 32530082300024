import { Platform, StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: { flex: 1 },
  safeAreaContainer: {
    flex: 1,
    backgroundColor: Palette.WHITE,
  },
  contentContainer: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  messagesListContentContainer: {
    paddingTop: 12,
    paddingHorizontal: 16,
  },
  messagesListContentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  messagesListContentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  messagesListContentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  textInputBackgroundContainer: {
    paddingVertical: 12,
    backgroundColor: Palette.WHITE,
  },
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
  submitButtonContainer: {
    position: "absolute",
    width: 32,
    height: 32,
    borderRadius: 32,
    padding: 6,
    bottom: 14,
    right: 22,
    backgroundColor: Palette.BURGUNDY_100,
  },
  alertContainer: {
    padding: 24,
    backgroundColor: Palette.WHITE,
  },
});

export const stylingProps = {
  textInput: {
    textRightOffset: 44,
  },
  keyboardAvoidingView: {
    behavior: Platform.select({
      ios: "padding",
      android: undefined,
    }),
  },
  submitButtonIcon: {
    fill: Palette.WHITE,
    width: 20,
    height: 20,
  },
};

export const stylingHelpers = {
  keyboardVerticalOffset(insets, headerHeight) {
    return Platform.select({
      ios: headerHeight - insets.bottom,
      android: 0,
    });
  },
};
