import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef,
} from "react";
import { Pressable, Platform, View } from "react-native";
import { useCall } from "@eyr-mobile/domain/Call";
import { VideoSession } from "@eyr-mobile/core/Video";
import {
  noop,
  flow,
  get,
  isFunction,
  has,
  reduce,
  includes,
  pick,
} from "lodash/fp";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  CameraState,
  CameraTypeToFacingMode,
  CameraTypeToPosition,
} from "@eyr-mobile/core/Camera";
import { useNavigation } from "@react-navigation/native";
import { useKeepScreenAwake } from "@eyr-mobile/core/Screen";
import { MicrophoneState } from "@eyr-mobile/core/Microphone";
import { useDevice } from "@eyr-mobile/core/Device";
import {
  recordError,
  logToErrorTracking,
} from "@eyr-mobile/core/ErrorTracking";
import { useIntl } from "@eyr-mobile/core/Intl";

import {
  CallControlsOverlay,
  CallMainVideoFrame,
  CallSecondaryVideoFrame,
  Paragraph,
} from "../../components";

import { styles } from "./OngoingCallScreen.styles";
import { messages } from "./OngoingCallScreen.messages";

const logger = LoggerFactory.get("screens/OngoingCallScreen");

const canRetrieveConnectionIdWithDefaultMethod = flow([
  get("getSessionInfo"),
  isFunction,
]);
const getConnectionIdWithDefaultMethod = get("connection.connectionId");
const canRetrieveConnectionIdWithAlternativeMethod = has(
  "sessionHelper.session.connection.id"
);
const getConnectionIdWithAlternativeMethod = get("connection.id");

function changedStreamIsOurs(changedStream, videoSession) {
  if (canRetrieveConnectionIdWithDefaultMethod(videoSession)) {
    return (
      getConnectionIdWithDefaultMethod(changedStream) ===
      getConnectionIdWithDefaultMethod(videoSession.getSessionInfo())
    );
  }
  if (canRetrieveConnectionIdWithAlternativeMethod(videoSession)) {
    return (
      getConnectionIdWithAlternativeMethod(changedStream) ===
      getConnectionIdWithAlternativeMethod(videoSession.sessionHelper.session)
    );
  }
}

const pickFieldsForEvent = {
  connected: [
    "stream.creationTime",
    "stream.name",
    "stream.streamId",
    "stream.hasAudio",
    "stream.hasVideo",
    "stream.sessionId",
    "stream.connectionId",
    "stream.width",
    "stream.height",
  ],
  disconnected: ["stream.streamId", "stream.sessionId", "stream.connectionId"],
  error: ["code", "message"],
  otrnError: ["code", "message"],
  streamCreated: [
    "creationTime",
    "streamId",
    "hasAudio",
    "hasVideo",
    "sessionId",
    "connectionId",
    "width",
    "height",
  ],
  streamDestroyed: ["streamId", "sessionId", "connectionId"],
  sessionConnected: ["sessionId", "connection"],
  streamPropertyChanged: ["newValue", "oldValue", "changedProperty"],
};

function getEventHandlersForVideoComponent(Component, reporter) {
  const eventSource = Component.displayName || "unknown";
  let events = [];
  if (includes("Subscriber", Component.displayName)) {
    events = ["connected", "disconnected", "error", "otrnError"];
  } else if (includes("Publisher", Component.displayName)) {
    events = ["streamCreated", "streamDestroyed", "error", "otrnError"];
  }

  return reduce(
    function (handlers, eventName) {
      handlers[eventName] = function (eventData) {
        const TAG = `${eventSource}:${eventName}`;
        logger(TAG, eventData);
        const filteredEventData = pick(
          pickFieldsForEvent[eventName],
          eventData
        );
        logToErrorTracking(`${TAG}:${JSON.stringify(filteredEventData)}`);
        reporter({
          eventSource,
          eventName,
          eventData: filteredEventData,
        });
      };
      return handlers;
    },
    {},
    events
  );
}

const VideoComponentPlaceholder = forwardRef(({}, ref) => {
  const { formatMessage } = useIntl();
  return (
    <View ref={ref} style={styles.videoComponentPlaceholderContainer}>
      <Paragraph size={"l"} color={"secondary"}>
        {`${formatMessage(messages.connectingStateTitle)} ...`}
      </Paragraph>
    </View>
  );
});

export function OngoingCallScreen() {
  const {
    callId,
    callerName,
    callerTitle,
    callerCameraState,
    callerMicrophoneState,
    microphoneEnabled,
    cameraEnabled,
    cameraType,
    videoAPIKey,
    videoAPIToken,
    videoAPISessionId,
    videoAPIConnected,
    reportVideoAPIEvent,
    endCall,
    setCameraType,
    setCameraEnabled,
    setMicrophoneEnabled,
    setCallerCameraState,
    setCallerMicrophoneState,
    setVideoAPIConnected,
    MainVideoFrameComponent,
    SecondaryVideoFrameComponent,
  } = useCall();
  const { addListener } = useNavigation();
  const [_controlsOverlayVisible, setControlsOverlayVisible] = useState(true);
  const showOverlay = useCallback(() => setControlsOverlayVisible(true), []);
  const hideOverlay = useCallback(() => setControlsOverlayVisible(false), []);
  const { screenSizeSelect } = useDevice();
  const controlsOverlayVisible = screenSizeSelect({
    xs: _controlsOverlayVisible,
    s: true,
  });

  const toggleOverlay = controlsOverlayVisible ? hideOverlay : showOverlay;
  const toggleOverlayDisabled = screenSizeSelect({
    xs: false,
    s: true,
  });

  useEffect(
    () =>
      addListener("beforeRemove", function () {
        if (callId) {
          endCall();
        }
      }),
    [callId, addListener, endCall]
  );
  useEffect(() => {
    if (!controlsOverlayVisible) {
      return;
    }
    // Do nothing with values, but reschedule hideOverlay
    noop(microphoneEnabled, cameraEnabled, cameraType);
    const timeoutId = setTimeout(hideOverlay, 5000);
    return () => clearTimeout(timeoutId);
  }, [
    controlsOverlayVisible,
    hideOverlay,
    microphoneEnabled,
    cameraEnabled,
    cameraType,
  ]);

  const videoSessionRef = useRef();
  const sessionEventHandlers = useMemo(() => {
    function retrieveCallerMediaStateFromStream(stream) {
      const videoSession = videoSessionRef.current;
      if (!stream || !videoSession) {
        return;
      }
      if (changedStreamIsOurs(stream, videoSession)) {
        return;
      }
      setCallerCameraState(
        get("hasVideo", stream) ? CameraState.ENABLED : CameraState.DISABLED
      );
      setCallerMicrophoneState(
        get("hasAudio", stream)
          ? MicrophoneState.ENABLED
          : MicrophoneState.DISABLED
      );
    }
    return {
      streamPropertyChanged: (event) => {
        const TAG = "VideoSession:streamPropertyChanged";
        logger(TAG, event);
        logToErrorTracking(
          `${TAG}:${JSON.stringify(
            pick(pickFieldsForEvent.streamPropertyChanged, event)
          )}`
        );
        try {
          retrieveCallerMediaStateFromStream(get("stream", event));
        } catch (e) {
          logger(`${TAG}:error`, e, LOGGER_LEVEL_ERROR);
        }
      },
      streamCreated: (event) => {
        const stream = Platform.select({ web: event.stream, default: event });
        const TAG = "VideoSession:streamCreated";
        logger(TAG, stream);
        logToErrorTracking(
          `${TAG}:${JSON.stringify(
            pick(pickFieldsForEvent.streamCreated, stream)
          )}`
        );
        try {
          retrieveCallerMediaStateFromStream(stream);
        } catch (e) {
          logger(`${TAG}:error`, e, LOGGER_LEVEL_ERROR);
        }
      },
      error: (event = {}) => {
        const { message = "" } = event;
        const TAG = "VideoSession:error";
        logger(TAG, event, LOGGER_LEVEL_ERROR);
        recordError(
          new Error(JSON.stringify(pick(pickFieldsForEvent.error, event))),
          `${TAG}:${message}`
        );
      },
      otrnError: (event = {}) => {
        const { message = "" } = event;
        const TAG = "VideoSession:otrnError";
        logger(TAG, event, LOGGER_LEVEL_ERROR);
        recordError(
          new Error(JSON.stringify(pick(pickFieldsForEvent.otrnError, event))),
          `${TAG}:${message}`
        );
      },
      sessionConnected: (event) => {
        const TAG = "VideoSession:sessionConnected";
        logger(TAG, event);
        logToErrorTracking(
          `${TAG}:${JSON.stringify(
            pick(pickFieldsForEvent.sessionConnected, event)
          )}`
        );
        setVideoAPIConnected(true);
      },
    };
  }, [setCallerCameraState, setCallerMicrophoneState, setVideoAPIConnected]);
  const mainVideoFrameEventHandlers = useMemo(
    () =>
      getEventHandlersForVideoComponent(
        MainVideoFrameComponent,
        reportVideoAPIEvent
      ),
    [MainVideoFrameComponent, reportVideoAPIEvent]
  );

  const secondaryVideoFrameEventHandlers = useMemo(
    () =>
      getEventHandlersForVideoComponent(
        SecondaryVideoFrameComponent,
        reportVideoAPIEvent
      ),
    [SecondaryVideoFrameComponent, reportVideoAPIEvent]
  );

  const controlsOverlayHeightRef = useRef();
  const [controlsOverlayMeasured, setControlsOverlayMeasured] = useState(false);
  const [mainVideoFrameOverlayOffset, setMainVideoFrameOverlayOffset] =
    useState(0);
  const measureControlsOverlay = useCallback(
    (event) => {
      controlsOverlayHeightRef.current = event.nativeEvent.layout.height;
      setMainVideoFrameOverlayOffset(
        controlsOverlayVisible ? event.nativeEvent.layout.height : 0
      );
      setControlsOverlayMeasured(true);
    },
    [controlsOverlayVisible]
  );
  useEffect(() => {
    if (!controlsOverlayMeasured) {
      return;
    }
    setMainVideoFrameOverlayOffset(
      controlsOverlayVisible ? controlsOverlayHeightRef.current : 0
    );
  }, [controlsOverlayMeasured, controlsOverlayVisible]);
  useKeepScreenAwake();

  return (
    <Pressable
      style={styles.container}
      disabled={toggleOverlayDisabled}
      onPress={toggleOverlay}
    >
      {videoAPISessionId && (
        <VideoSession
          apiKey={videoAPIKey}
          token={videoAPIToken}
          sessionId={videoAPISessionId}
          eventHandlers={sessionEventHandlers}
          options={Platform.select({
            android: {
              androidOnTop: "publisher",
              useTextureViews: true,
              enableStereoOutput: false,
              iceConfig: {
                transportPolicy: "all",
                includeServers: "all",
                customServers: [],
              },
            },
            ios: { enableStereoOutput: false },
          })}
          ref={videoSessionRef}
        >
          <>
            <CallMainVideoFrame
              title={callerName}
              description={callerTitle}
              enableStateOverlay={controlsOverlayMeasured}
              cameraState={callerCameraState}
              microphoneState={callerMicrophoneState}
              stateOverlayOffset={mainVideoFrameOverlayOffset}
              VideoComponent={
                videoAPIConnected
                  ? MainVideoFrameComponent
                  : VideoComponentPlaceholder
              }
              videoComponentProps={{
                properties: Platform.select({
                  web: {
                    showControls: false,
                  },
                }),
                eventHandlers: mainVideoFrameEventHandlers,
              }}
            />
            <CallSecondaryVideoFrame
              cameraState={
                cameraEnabled ? CameraState.ENABLED : CameraState.DISABLED
              }
              microphoneState={
                microphoneEnabled
                  ? MicrophoneState.ENABLED
                  : MicrophoneState.DISABLED
              }
              VideoComponent={SecondaryVideoFrameComponent}
              videoComponentProps={{
                properties: {
                  publishAudio: microphoneEnabled,
                  publishVideo: cameraEnabled,
                  ...(!videoAPIConnected && {
                    publishAudio: false,
                    publishVideo: false,
                  }),
                  ...Platform.select({
                    native: {
                      cameraPosition: CameraTypeToPosition[cameraType],
                    },
                    default: {
                      facingMode: CameraTypeToFacingMode[cameraType],
                      showControls: false,
                    },
                  }),
                },
                eventHandlers: secondaryVideoFrameEventHandlers,
              }}
            />
          </>
          <CallControlsOverlay
            onLayout={measureControlsOverlay}
            visible={controlsOverlayVisible}
            title={callerName}
            description={callerTitle}
            cameraEnabled={cameraEnabled}
            microphoneEnabled={microphoneEnabled}
            cameraType={cameraType}
            onToggleCameraType={setCameraType}
            onToggleCamera={setCameraEnabled}
            onToggleMicrophone={setMicrophoneEnabled}
            onCallEnd={endCall}
            onClose={toggleOverlay}
            closable={!toggleOverlayDisabled}
          />
        </VideoSession>
      )}
    </Pressable>
  );
}

OngoingCallScreen.routeName = "OngoingCallScreen";
OngoingCallScreen.navigationOptions = {
  headerShown: false,
  animationEnabled: false,
};
