import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { compact, indexOf, nth, size, flatten, noop } from "lodash/fp";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import { useApolloClient } from "@eyr-mobile/core/DataProvider";
import { useNavigation } from "@react-navigation/native";
import { usePrevious } from "@eyr-mobile/core/Lib";
import { useLinking } from "@eyr-mobile/core/Linking";

import { CheckOnboardingStatus, CompleteOnboarding } from "./Onboarding.data";
import { clearStoredOnboarding, storeOnboarding } from "./Onboarding.utils";

const logger = LoggerFactory.get("domain/Onboarding");

const stateDefaults = {
  org: null,
  orgCodeHint: null,
  onboardingActive: false,
  onboardingFlowStages: [],
};

export const OnboardingContext = createContext({
  ...stateDefaults,
  activateOnboardingFlowIfNeeded: noop,
  completeOnboardingStage: noop,
});

export function parseInitialOnboardingStateFromLinking() {}

function shouldAddAccountContactInfo(account = {}) {
  return !account.email && !account.phoneNumber;
}
function shouldAddInsuranceOrDiscount(accountPaymentMethods) {
  return size(accountPaymentMethods) === 0;
}

export function OnboardingProvider({ children, initialState }) {
  const initialStateWithDefaults = {
    ...stateDefaults,
    ...initialState,
  };
  const dataProvider = useApolloClient();
  const [onboardingState, setOnboardingState] = useState(
    initialStateWithDefaults
  );
  const { navigate } = useNavigation();
  const { setPendingLink, pendingLinkRoute } = useLinking();
  const { org, onboardingActive, onboardingFlowStages } = onboardingState;

  useEffect(() => {
    const { org, orgCodeHint } =
      pendingLinkRoute &&
      pendingLinkRoute?.name ===
        "AddAccountInsuranceOrDiscountFromShortLinkScreen"
        ? pendingLinkRoute?.params
        : {};
    if (org && orgCodeHint) {
      setOnboardingState((onboardingState) => ({
        ...onboardingState,
        org,
        orgCodeHint,
      }));
      storeOnboarding({ org, orgCodeHint });
    }
  }, [onboardingActive, pendingLinkRoute]);

  const prevOnboardingActive = usePrevious(onboardingActive);
  useEffect(() => {
    if (!prevOnboardingActive && onboardingActive) {
      storeOnboarding(onboardingState);
      if (
        pendingLinkRoute &&
        pendingLinkRoute?.name ===
          "AddAccountInsuranceOrDiscountFromShortLinkScreen"
      ) {
        setPendingLink(null);
      }
    }
  }, [
    onboardingActive,
    onboardingState,
    pendingLinkRoute,
    prevOnboardingActive,
    setPendingLink,
  ]);

  useEffect(() => {
    if (prevOnboardingActive && !onboardingActive) {
      clearStoredOnboarding();
    }
  }, [onboardingActive, prevOnboardingActive]);

  const activateOnboardingFlowIfNeeded = useCallback(async () => {
    const { data: { account, accountPaymentMethods } = {} } =
      await dataProvider.query({
        query: CheckOnboardingStatus,
      });
    if (account.onboardedAt) {
      return;
    }
    const onboardingFlowGroupedStages = compact([
      shouldAddAccountContactInfo(account) &&
        "OnboardingAddAccountContactInfoScreen",
      shouldAddInsuranceOrDiscount(accountPaymentMethods) &&
        compact([
          !org && "OnboardingSelectInsuranceOrDiscountScreen",
          "OnboardingAddInsuranceOrDiscountScreen",
        ]),
    ]);
    const onboardingFlowStages = flatten(onboardingFlowGroupedStages);
    const shouldActivateOnboardingFlow = size(onboardingFlowStages) > 0;
    setOnboardingState((onboardingState) => ({
      ...onboardingState,
      ...(shouldActivateOnboardingFlow && {
        onboardingActive: shouldActivateOnboardingFlow,
        onboardingFlowStages,
      }),
    }));
  }, [dataProvider, org]);

  const completeOnboardingStage = useCallback(
    async (stageName, payload) => {
      const currentStageIndex = indexOf(stageName, onboardingFlowStages);
      if (!onboardingActive || currentStageIndex === -1) {
        return;
      }
      const nextStage = nth(currentStageIndex + 1, onboardingFlowStages);
      if (nextStage) {
        navigate(nextStage, payload);
      } else {
        await dataProvider.mutate({
          mutation: CompleteOnboarding,
        });
        setOnboardingState(stateDefaults);
      }
    },
    [dataProvider, navigate, onboardingActive, onboardingFlowStages]
  );

  return (
    <OnboardingContext.Provider
      value={{
        ...onboardingState,
        activateOnboardingFlowIfNeeded,
        completeOnboardingStage,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    org: PropTypes.string,
    orgCodeHint: PropTypes.string,
  }),
};

export function useOnboarding() {
  const onboarding = useContext(OnboardingContext);
  if (onboarding === undefined) {
    logger(
      "useOnboarding: Couldn't find a onboarding object. Is your component inside a OnboardingProvider?",
      onboarding,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return onboarding;
}
