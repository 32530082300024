import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  container: { flex: 1 },
  listItemContainer: {
    marginTop: 20,
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 12,
  },
});
