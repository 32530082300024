import React, { Fragment, isValidElement } from "react";
import PropTypes from "prop-types";
import { Pressable, View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { renderIf, renderIfElse } from "@eyr-mobile/core/Lib";
import { noop } from "lodash/fp";

import { SVGs } from "../../res";
import { Heading } from "../Heading";
import { ProfileImage } from "../ProfileImage";
import { Subtitle } from "../Subtitle";
import { Paragraph } from "../Paragraph";
import { IconContainer } from "../IconContainer";
import { EyrButton } from "../EyrButton";

import { styles, stylingProps, stylingHelpers } from "./ServiceCard.styles";
import { messages } from "./ServiceCard.messages";

export function ServiceCard({
  summary,
  textColor,
  providerName,
  providerImage,
  providerDescription,
  informationTitle,
  informationDescription,
  informationList = [],
  onDismissPress = noop,
  instructionTitle,
  instructionDescription,
  instructionAction,
  alertTitle,
  isGrayscale = false,
  footerList = [],
  showAlert = true,
  serviceCardActionTitle,
  onServiceCardActionPress = noop,
  isInvertedEmphasis = false,
  showDismiss = false,
}) {
  const { formatMessage } = useIntl();

  const infoContentContainerStyle = stylingHelpers.getInfoContentContainerStyle(
    isGrayscale,
    informationList
  );
  const alertStyle = stylingHelpers.getAlertStyle(isGrayscale);
  const alertTitleProps = stylingHelpers.getAlertTitleProps(isGrayscale);
  const spacedColumnContainerStyle = [
    styles.contentContainerWithSpacing,
    styles.contentContainerColumn,
  ];

  const TitleComponent = isInvertedEmphasis ? Subtitle : Paragraph;
  const DescriptionComponent = isInvertedEmphasis ? Paragraph : Subtitle;

  return (
    <View style={styles.container}>
      {renderIf(showAlert && alertTitle)(
        <View style={alertStyle}>
          {renderIfElse(isValidElement(alertTitle))(
            alertTitle,
            <Paragraph {...alertTitleProps}>{alertTitle}</Paragraph>
          )}
          {renderIf(onDismissPress && showDismiss)(
            <Pressable
              onPress={onDismissPress}
              {...stylingProps.dismissActionTitle}
              accessibilityLabel={formatMessage(messages.dismissActionTitle)}
            >
              <IconContainer size="xxs" variant="plain" color={textColor}>
                <SVGs.CloseMono />
              </IconContainer>
            </Pressable>
          )}
        </View>
      )}
      <View style={styles.contentContainer}>
        <View style={styles.contentContainerWithoutSpacing}>
          <Heading size="s" color={textColor}>
            {summary}
          </Heading>
          {renderIf(onDismissPress && !alertTitle && showDismiss)(
            <Pressable
              onPress={onDismissPress}
              accessibilityLabel={formatMessage(messages.dismissActionTitle)}
            >
              <IconContainer size="xxs" variant="plain">
                <SVGs.CloseMono />
              </IconContainer>
            </Pressable>
          )}
        </View>
        {renderIf(providerName)(
          <View style={styles.contentContainerWithSpacing}>
            <View style={styles.providerImageContainer}>
              <ProfileImage
                uri={providerImage}
                placeholder={providerName}
                disabled={isGrayscale}
              />
            </View>
            <View style={styles.providerContainer}>
              <Subtitle size="l" color={textColor}>
                {providerName}
              </Subtitle>
              {renderIf(providerDescription)(
                <Paragraph color={textColor} size="l">
                  {providerDescription}
                </Paragraph>
              )}
            </View>
          </View>
        )}
        {renderIf(
          informationTitle || informationDescription || informationList.length
        )(
          <View style={styles.contentContainerWithSpacing}>
            <View style={infoContentContainerStyle}>
              {renderIf(informationTitle || informationDescription)(
                <>
                  <TitleComponent size="l" spacing="s" color={textColor}>
                    {informationTitle}
                  </TitleComponent>
                  <DescriptionComponent size="l" color={textColor}>
                    {informationDescription}
                  </DescriptionComponent>
                </>
              )}
              {renderIf(informationList.length)(
                informationList.map((informationListItem, index) => (
                  <Fragment key={index}>
                    <View style={styles.leftColumn}>
                      <Subtitle size="l" spacing="s" color={textColor}>
                        {informationListItem.label}
                      </Subtitle>
                    </View>
                    <View style={styles.rightColumn}>
                      <Paragraph size="l" color={textColor}>
                        {informationListItem.value}
                      </Paragraph>
                    </View>
                  </Fragment>
                ))
              )}
            </View>
          </View>
        )}
        {renderIf(
          instructionTitle || instructionDescription || instructionAction
        )(
          <View style={styles.contentContainerWithSpacing}>
            <View style={[styles.roundedBox, styles.pinkBackground]}>
              {renderIf(instructionTitle)(
                <Subtitle size="l" spacing="s">
                  {instructionTitle}
                </Subtitle>
              )}
              {renderIfElse(isValidElement(instructionDescription))(
                instructionDescription,
                <Paragraph size="l">{instructionDescription}</Paragraph>
              )}
              {renderIf(instructionAction)(() => (
                <View style={spacedColumnContainerStyle}>
                  <EyrButton
                    size="l"
                    variant="secondary"
                    {...instructionAction}
                  />
                </View>
              ))}
            </View>
          </View>
        )}
        {renderIf(serviceCardActionTitle)(() => (
          <View style={spacedColumnContainerStyle}>
            <EyrButton
              size="l"
              variant="primary"
              title={serviceCardActionTitle}
              onPress={onServiceCardActionPress}
            />
          </View>
        ))}
        {renderIf(footerList.length)(() => (
          <View style={spacedColumnContainerStyle}>
            {footerList.map((footerListItem, index) => (
              <Fragment key={index}>
                {renderIf(index)(<View style={styles.separator} />)}
                <Pressable
                  style={styles.listItem}
                  onPress={footerListItem.onPress}
                >
                  <Paragraph size="l">{footerListItem.message}</Paragraph>
                  <SVGs.ChevronRightMono {...stylingProps.listItemChevron} />
                </Pressable>
              </Fragment>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
}

ServiceCard.propTypes = {
  summary: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  providerName: PropTypes.string,
  providerDescription: PropTypes.string,
  providerImage: PropTypes.string,
  informationTitle: PropTypes.string,
  informationDescription: PropTypes.string,
  informationList: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ),
  onDismissPress: PropTypes.func,
  instructionTitle: PropTypes.string,
  instructionDescription: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  instructionAction: PropTypes.shape({
    title: PropTypes.string,
    onPress: PropTypes.func.isRequired,
  }),
  alertTitle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isGrayscale: PropTypes.bool,
  footerList: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
    })
  ),
  showAlert: PropTypes.bool,
  serviceCardActionTitle: PropTypes.string,
  onServiceCardActionPress: PropTypes.func,
  isInvertedEmphasis: PropTypes.bool,
  showDismiss: PropTypes.bool,
};
