import React, {
  createContext,
  createRef,
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useApolloClient } from "@apollo/client";
import * as Application from "expo-application";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { InteractionManager, LogBox, Platform } from "react-native";
import {
  resetGenericPassword,
  safelyGetGenericPassword,
  setGenericPassword,
} from "@eyr-mobile/core/SecureStore";
import { delay, get, isNull, size } from "lodash/fp";
import { AppState, useAppState } from "@eyr-mobile/core/AppState";
import {
  navigate,
  replace,
  resetNavigation,
} from "@eyr-mobile/core/Navigation";
import { safelyGetNotificationsToken } from "@eyr-mobile/core/Notifications";
import {
  AppPermissions,
  formatPermissionsForCertClient,
  getPermissionsAsync,
} from "@eyr-mobile/core/AppPermissions";
import {
  authenticateAsync,
  isEnrolledForBiometry,
} from "@eyr-mobile/core/Biometry";
import { getLocale } from "@eyr-mobile/core/Intl";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import { getCallsToken } from "@eyr-mobile/core/CallKit";
import { subscriptionsLinkRef } from "@eyr-mobile/core/DataProvider";
import { usePrevious } from "@eyr-mobile/core/Lib";
import { isEmulator } from "@eyr-mobile/core/Device";
import {
  logToErrorTracking,
  recordError,
  setErrorTrackingAttributes,
} from "@eyr-mobile/core/ErrorTracking";
import { Constants } from "@eyr-mobile/core/Config";
import {
  getAdvertisingId,
  setAnalyticsUserProperties,
} from "@eyr-mobile/core/Analytics";

import { usePreference } from "../Preference";
import { useOnboarding } from "../Onboarding";

import {
  checkIfNotExpired,
  decodeJWTPayload,
  ensure,
  getAuthorizationBearerHeader,
  getAuthState,
  hashPassword,
  oneOf,
  toObject,
} from "./Auth.utils";
import {
  GetAccountCountryCode,
  GetCountryWithAvailabilityStatus,
  RecoverSession,
  RegisterDevice,
} from "./Auth.data";
import {
  AuthPermission,
  AuthState,
  authStateDefaults,
  AuthType,
  STORAGE_KEY_ACCESS_TOKEN,
  STORAGE_KEY_AUTH_TYPE,
  STORAGE_KEY_CERTIFICATE,
  STORAGE_KEY_GENERIC_PASSWORD,
  STORAGE_KEY_PASSWORD_REMAINING_ATTEMPTS,
  TIME_LIMIT_TOKEN_REFRESH_THRESHOLD,
} from "./Auth.constants";

const secureStoreSettings = { service: `${Application.applicationId}-Auth` };

const getIdentityProviderIdFromCert = get("identityProvider.id");

const logger = LoggerFactory.get("domain/Auth");
// as far as we use timers with foreground, it's safe to suppress warning
LogBox.ignoreLogs(["Setting a timer"]);

export const authRef = createRef();

// EYR-3518
async function migrateSecureStoreNamespaceIfNeeded() {
  const credentials = await safelyGetGenericPassword();
  if (
    credentials &&
    credentials.username === STORAGE_KEY_GENERIC_PASSWORD &&
    size(credentials.password) === 128
  ) {
    await resetGenericPassword();
    await setGenericPassword(
      STORAGE_KEY_GENERIC_PASSWORD,
      credentials.password,
      secureStoreSettings
    );
    return credentials;
  }
}

export async function getStoredAuth() {
  const [
    cert,
    preferableAuthType,
    remainingPinEntryAttempts,
    accessToken,
    enrolledForBiometry,
    credentials,
  ] = await Promise.all([
    AsyncStorage.getItem(STORAGE_KEY_CERTIFICATE).then(JSON.parse),
    AsyncStorage.getItem(STORAGE_KEY_AUTH_TYPE),
    AsyncStorage.getItem(STORAGE_KEY_PASSWORD_REMAINING_ATTEMPTS),
    AsyncStorage.getItem(STORAGE_KEY_ACCESS_TOKEN),
    isEnrolledForBiometry(),
    safelyGetGenericPassword(secureStoreSettings),
  ]);

  let pin;
  if (credentials) {
    pin = credentials.password;
  } else {
    const credentials = await migrateSecureStoreNamespaceIfNeeded();
    if (credentials) {
      pin = credentials.password;
    }
  }
  function restoreAuthType({ preferableAuthType, enrolledForBiometry }) {
    if (preferableAuthType) {
      if (enrolledForBiometry && preferableAuthType === AuthType.BIOMETRY) {
        return AuthType.BIOMETRY;
      } else {
        return AuthType.PIN;
      }
    }
    if (!preferableAuthType) {
      if (enrolledForBiometry) {
        return AuthType.BIOMETRY;
      } else {
        return AuthType.PIN;
      }
    }
  }

  const hasValidAccessToken = checkIfNotExpired(accessToken);
  if (accessToken && (!hasValidAccessToken || !cert)) {
    AsyncStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
  }

  return {
    type: restoreAuthType({ preferableAuthType, enrolledForBiometry }),
    ...(!isNull(remainingPinEntryAttempts) && {
      remainingPinEntryAttempts: Number(remainingPinEntryAttempts),
    }),
    ...(hasValidAccessToken &&
      cert && {
        requiresLegalChecks: false,
        accessToken,
      }),
    ...(pin && cert && { pin, cert }),
  };
}

function cleanStoredAuth() {
  return Promise.all([
    AsyncStorage.multiRemove([
      STORAGE_KEY_CERTIFICATE,
      STORAGE_KEY_PASSWORD_REMAINING_ATTEMPTS,
      STORAGE_KEY_AUTH_TYPE,
      STORAGE_KEY_ACCESS_TOKEN,
    ]),
    resetGenericPassword(secureStoreSettings),
  ]);
}

function transformPlatformForCertClient(platform) {
  switch (platform) {
    case "web":
      return "browser";
    default:
      return platform;
  }
}

async function composeCertClient() {
  const device_tokens = [];

  const [_isEmulator, FCMToken, permissionsResponse, advertisingId] =
    await Promise.all([
      isEmulator(),
      safelyGetNotificationsToken(),
      getPermissionsAsync([
        AppPermissions.CAMERA,
        AppPermissions.MICROPHONE,
        AppPermissions.NOTIFICATION,
      ]),
      getAdvertisingId(),
    ]);

  if (FCMToken) {
    device_tokens.push({ token: FCMToken, type: "FCM" });
  }

  if (Platform.OS === "ios" && !_isEmulator) {
    const APNS_VOIP_Token = await getCallsToken();
    device_tokens.push({
      token: APNS_VOIP_Token,
      type: "APN",
    });
  }

  const certClientPermissions =
    formatPermissionsForCertClient(permissionsResponse);

  return {
    platform: transformPlatformForCertClient(Platform.OS),
    device_tokens,
    locale: getLocale().languageTag,
    app_version: Constants.expoConfig.version,
    advertisingId,
    ...certClientPermissions,
  };
}

export const AuthContext = createContext(authStateDefaults);

export const AuthProvider = forwardRef(function AuthProviderRaw(
  { children, initialState },
  ref
) {
  const initialStateWithDefaults = {
    ...authStateDefaults,
    ...initialState,
  };
  const [
    {
      cert,
      accessToken,
      type,
      error,
      remainingPinEntryAttempts,
      pin,
      pinLength,
      requiresLegalChecks,
      // TODO: remove when valid test accessToken factory is added
      testOnly_disableAccessTokenMonitor,
    },
    setAuth,
  ] = useState(initialStateWithDefaults);
  const state = getAuthState({ cert, accessToken, requiresLegalChecks });
  const client = useApolloClient();
  const monitorRef = useRef();
  const prevState = usePrevious(state);
  const prevAccessToken = usePrevious(accessToken);
  const prevRequiresLegalChecks = usePrevious(requiresLegalChecks);
  const { fetchAndActivatePreferences, cleanOnDevicePreferences } =
    usePreference();
  const { activateOnboardingFlowIfNeeded } = useOnboarding();

  const permissions = useMemo(() => {
    if (accessToken) {
      return decodeJWTPayload(accessToken)?.pem?.eyr_org ?? null;
    }
    return null;
  }, [accessToken]);

  useEffect(() => {
    if (!cert) {
      return;
    }
    const identityProviderId = getIdentityProviderIdFromCert(cert);
    if (identityProviderId) {
      setErrorTrackingAttributes({
        identityProviderId,
      });
      setAnalyticsUserProperties({ identityProviderId });
    }
  }, [cert]);

  useEffect(() => {
    const subscriptionsLink = subscriptionsLinkRef.current;
    if (
      prevState === AuthState.AUTHENTICATED &&
      (state === AuthState.LOCKED || state === AuthState.UNAUTHENTICATED)
    ) {
      InteractionManager.runAfterInteractions(client.resetStore);
      subscriptionsLink?.teardown();
    }
    if (
      (prevState === AuthState.UNAUTHENTICATED ||
        prevState === AuthState.LOCKED) &&
      state === AuthState.AUTHENTICATED
    ) {
      fetchAndActivatePreferences();
    }
    if (
      (prevState === AuthState.AUTHENTICATED ||
        prevState === AuthState.LOCKED) &&
      state === AuthState.UNAUTHENTICATED
    ) {
      cleanOnDevicePreferences();
      cleanStoredAuth();
      resetNavigation();
    }
  }, [
    cleanOnDevicePreferences,
    fetchAndActivatePreferences,
    client,
    prevState,
    state,
    accessToken,
  ]);

  useEffect(() => {
    if (
      prevState === AuthState.UNAUTHENTICATED &&
      state === AuthState.UNAUTHENTICATED &&
      prevAccessToken &&
      !accessToken
    ) {
      resetNavigation();
    }
  }, [accessToken, prevAccessToken, prevState, state]);

  const setError = (e) => {
    if (e.message) {
      setAuth((auth) => ({
        ...auth,
        error: e.message,
      }));
    }
  };

  const initializeIdentification = useCallback(
    async function ({ returnRoute } = {}) {
      if (state === AuthState.AUTHENTICATED) {
        try {
          const {
            data: {
              account: {
                country: { codeIso2: countryCode },
              },
            },
          } = await client.query({
            query: GetAccountCountryCode,
          });
          navigate("IdentificationMethodScreen", {
            reidentification: true,
            returnRoute,
            countryCode,
          });
        } catch (e) {
          navigate("IdentificationCountryScreen", {
            reidentification: true,
            returnRoute,
          });
          logger(
            "initializeAuthenticatedIdentification",
            e,
            LOGGER_LEVEL_ERROR
          );
        }
        return;
      }
      try {
        const {
          data: { country: deviceRegionCountry },
        } = await client.query({
          query: GetCountryWithAvailabilityStatus,
          context: { public: true },
          variables: { alpha2Code: getLocale().countryCode },
        });
        const { available, codeIso2: countryCode } = deviceRegionCountry;
        if (deviceRegionCountry && available) {
          navigate("IdentificationMethodScreen", {
            countryCode,
          });
        } else {
          navigate("IdentificationCountryScreen");
        }
      } catch (e) {
        navigate("IdentificationCountryScreen");
        logger(
          "initializeUnauthenticatedIdentification",
          e,
          LOGGER_LEVEL_ERROR
        );
      }
    },
    [client, state]
  );

  const completeIdentification = useCallback(function (accessToken) {
    logToErrorTracking(
      `AuthContext:completeIdentification:${JSON.stringify(
        decodeJWTPayload(accessToken)
      )}`
    );
    setAuth((auth) => ({
      ...auth,
      accessToken,
    }));
  }, []);

  const completeLegalChecks = useCallback(function () {
    setAuth((auth) => ({
      ...auth,
      requiresLegalChecks: false,
    }));
  }, []);
  const completeReidentification = useCallback(
    async function renewCert(identityProviderToken) {
      const salt = cert?.salt;
      const TAG = "AuthContext:renewCert";
      try {
        setErrorTrackingAttributes({
          completedReidentification: true,
        });
        if (size(pin) !== 128) {
          const CLAUSE_TAG = `${TAG}:incorrect pincode encoding`;
          const credentials = await safelyGetGenericPassword(
            secureStoreSettings
          );
          logToErrorTracking(
            `${TAG}:${JSON.stringify({
              pin,
              credentials,
            })}`
          );
          recordError(new Error(CLAUSE_TAG));
          logger(CLAUSE_TAG, { pin, credentials }, LOGGER_LEVEL_ERROR);
        }
        const certClient = await composeCertClient();
        const {
          data: {
            registerDevice: { cert, accessToken },
          },
        } = await client.mutate({
          mutation: RegisterDevice,
          variables: { input: { certClient, salt, pincode: pin } },
          context: {
            headers: getAuthorizationBearerHeader(identityProviderToken),
          },
        });
        await AsyncStorage.setItem(
          STORAGE_KEY_CERTIFICATE,
          JSON.stringify(cert)
        );
        logToErrorTracking(
          `${TAG}:RegisterDevice:${JSON.stringify(
            decodeJWTPayload(accessToken)
          )}`
        );
        setAuth((auth) => ({
          ...auth,
          error: authStateDefaults.error,
          cert,
          accessToken,
        }));
      } catch (e) {
        setError(e);
        logger("completeReidentification", e, LOGGER_LEVEL_ERROR);
      }
    },
    [cert?.salt, client, pin]
  );
  const registerDevice = useCallback(
    async function (plainTextPassword) {
      const salt = Math.random().toString();
      const TAG = "AuthContext:registerDevice";
      try {
        const hashedPassword = hashPassword(plainTextPassword, salt);
        if (size(hashedPassword) !== 128) {
          const credentials = await safelyGetGenericPassword(
            secureStoreSettings
          );
          logToErrorTracking(
            `${TAG}:${JSON.stringify({
              salt,
              plainTextPassword,
              hashedPassword,
              credentials,
            })}`
          );
          recordError(new Error(`${TAG}:incorrect pincode encoding`));
        }
        const certClient = await composeCertClient();
        const {
          data: {
            registerDevice: { cert, accessToken },
          },
        } = await client.mutate({
          mutation: RegisterDevice,
          variables: { input: { certClient, salt, pincode: hashedPassword } },
          context: {
            errorTrackingIncludeVariables: true,
          },
        });
        await Promise.all([
          AsyncStorage.setItem(STORAGE_KEY_CERTIFICATE, JSON.stringify(cert)),
          setGenericPassword(
            STORAGE_KEY_GENERIC_PASSWORD,
            hashedPassword,
            secureStoreSettings
          ),
        ]);
        logToErrorTracking(
          `${TAG}:RegisterDevice:${JSON.stringify(
            decodeJWTPayload(accessToken)
          )}`
        );
        await activateOnboardingFlowIfNeeded();
        setAuth((auth) => ({
          ...auth,
          error: authStateDefaults.error,
          remainingPinEntryAttempts:
            authStateDefaults.remainingPinEntryAttempts,
          pin: hashedPassword,
          cert,
          accessToken,
        }));
      } catch (e) {
        setError(e);
        logger("registerDevice", e, LOGGER_LEVEL_ERROR);
        recordError(e, "registerDevice");
      }
    },
    [activateOnboardingFlowIfNeeded, client]
  );

  const logout = useCallback(function () {
    setAuth(authStateDefaults);
  }, []);

  const lock = useCallback(function () {
    setAuth((auth) => ({
      ...auth,
      requiresLegalChecks: true,
      accessToken: null,
    }));
  }, []);
  const unlock = useCallback(
    async function () {
      if (!cert) {
        return;
      }
      const TAG = "AuthContext:unlock";
      try {
        if (size(pin) !== 128) {
          const CLAUSE_TAG = `${TAG}:incorrect pincode encoding`;
          const credentials = await safelyGetGenericPassword(
            secureStoreSettings
          );
          logToErrorTracking(
            `${TAG}:${JSON.stringify({
              pin,
              credentials,
            })}`
          );
          recordError(new Error(CLAUSE_TAG));
          logger(CLAUSE_TAG, { pin, credentials }, LOGGER_LEVEL_ERROR);
        }
        const certClient = await composeCertClient();
        const {
          data: {
            recoverSession: { accessToken },
          },
        } = await client.mutate({
          mutation: RecoverSession,
          variables: {
            input: {
              certClient,
              certId: cert.id,
              certPrivateKey: cert.privateKey,
              hashedPincode: pin,
            },
          },
          context: { public: true },
        });
        logToErrorTracking(
          `${TAG}:RecoverSession:${JSON.stringify(
            decodeJWTPayload(accessToken)
          )}`
        );
        setAuth((auth) => ({
          ...auth,
          error: authStateDefaults.error,
          remainingPinEntryAttempts:
            authStateDefaults.remainingPinEntryAttempts,
          accessToken,
        }));
      } catch (e) {
        const error = e && e.message ? e.message : authStateDefaults.error;
        setAuth((auth) => ({
          ...auth,
          error,
          remainingPinEntryAttempts:
            authStateDefaults.remainingPinEntryAttempts,
          accessToken: authStateDefaults.accessToken,
        }));
        logger("unlock", e, LOGGER_LEVEL_ERROR);
      }
      AsyncStorage.removeItem(STORAGE_KEY_PASSWORD_REMAINING_ATTEMPTS);
    },
    [cert, client, pin]
  );
  const tryToUnlockWithPin = useCallback(
    (guessedPin) => {
      const TAG = "tryToUnlockWithPin";
      logger(TAG);
      if (remainingPinEntryAttempts === 0 || !cert) {
        return false;
      }
      const hashedGuessedPin = hashPassword(guessedPin, cert.salt);
      const valid = hashedGuessedPin === pin;
      if (valid) {
        logger(`${TAG}:valid`);
        unlock();
      } else {
        logger(`${TAG}:invalid`);
        const nextRemainingPinEntryAttempts = remainingPinEntryAttempts - 1;
        setAuth((auth) => ({
          ...auth,
          remainingPinEntryAttempts: nextRemainingPinEntryAttempts,
        }));
        AsyncStorage.setItem(
          STORAGE_KEY_PASSWORD_REMAINING_ATTEMPTS,
          String(nextRemainingPinEntryAttempts)
        );
      }
      return valid;
    },
    [cert, pin, remainingPinEntryAttempts, unlock]
  );
  const tryToUnlockWithBiometry = useCallback(
    async function (authenticateAsyncOptions) {
      const TAG = "tryToUnlockWithBiometry";
      logger(TAG);
      const { success } = await authenticateAsync(authenticateAsyncOptions);
      if (success) {
        logger(`${TAG}:valid`);
        unlock();
      } else {
        logger(`${TAG}:invalidOrCancelled`);
      }
      return success;
    },
    [unlock]
  );
  useEffect(() => {
    if (accessToken && prevRequiresLegalChecks && !requiresLegalChecks) {
      if (!cert) {
        replace("DeviceRegistrationScreen");
      }
    }
  }, [accessToken, cert, requiresLegalChecks, prevRequiresLegalChecks]);
  const AccessTokenMonitor = useMemo(() => {
    function start() {
      if (!accessToken || testOnly_disableAccessTokenMonitor) {
        return;
      }
      const JWTPayload = decodeJWTPayload(accessToken);
      const secondsToExpiry = JWTPayload.exp - Math.floor(Date.now() / 1000);
      const TAG = "AccessTokenMonitor";
      logger(`${TAG}:accessToken`, accessToken);
      if (secondsToExpiry <= 0) {
        const message = `token has expired ${Math.abs(
          secondsToExpiry
        )}s ago. Locking auth.`;
        logger(`${TAG}:`, message);
        logToErrorTracking(`${TAG}:${message}`);
        lock();
        return;
      }
      const refreshIn = Math.floor(
        secondsToExpiry - secondsToExpiry * TIME_LIMIT_TOKEN_REFRESH_THRESHOLD
      );
      const message = `token expires in ${secondsToExpiry}s, next refresh in ${refreshIn}s`;
      logger(`${TAG}:`, message);
      logToErrorTracking(`${TAG}:${message}`);
      logger(`${TAG}:JWTPayload`, JWTPayload);
      const parsedPermissions = toObject(permissions)(AuthPermission);
      logger(`${TAG}:permissions`, parsedPermissions);
      logToErrorTracking(`${TAG}:${JSON.stringify(parsedPermissions)}`);
      monitorRef.current = setTimeout(() => {
        try {
          unlock();
        } catch (e) {
          // keep trying while token is still valid
          start();
        }
      }, (refreshIn > 10 ? refreshIn : secondsToExpiry) * 1000);
    }
    function stop() {
      if (testOnly_disableAccessTokenMonitor) {
        return;
      }
      if (monitorRef.current) {
        clearTimeout(monitorRef.current);
        monitorRef.current = null;
      }
    }
    return { start, stop };
  }, [
    accessToken,
    lock,
    permissions,
    testOnly_disableAccessTokenMonitor,
    unlock,
  ]);
  useEffect(() => {
    if (accessToken && accessToken !== prevAccessToken) {
      AsyncStorage.setItem(STORAGE_KEY_ACCESS_TOKEN, accessToken);
    }
    if (prevAccessToken && !accessToken) {
      AsyncStorage.removeItem(STORAGE_KEY_ACCESS_TOKEN);
    }
  }, [accessToken, prevAccessToken]);
  useEffect(() => {
    if (accessToken) {
      AccessTokenMonitor.stop();
      AccessTokenMonitor.start();
    } else {
      AccessTokenMonitor.stop();
    }
    return () => AccessTokenMonitor.stop();
  }, [AccessTokenMonitor, accessToken]);
  const onActive = useCallback(
    (prevAppState) => {
      if (prevAppState === AppState.INACTIVE) {
        return;
      }
      if (accessToken) {
        AccessTokenMonitor.start();
        const { phoenixSocket: socket } =
          subscriptionsLinkRef.current?.transport || {};
        if (
          socket &&
          decodeJWTPayload(accessToken).exp > Math.floor(Date.now() / 1000)
        ) {
          delay(200, () => socket.connect());
        }
      }
    },
    [AccessTokenMonitor, accessToken]
  );
  const onBackground = useCallback(() => {
    if (accessToken) {
      AccessTokenMonitor.stop();
    }
    const { phoenixSocket: socket } =
      subscriptionsLinkRef.current?.transport || {};
    if (socket && socket.isConnected()) {
      socket.disconnect();
    }
  }, [AccessTokenMonitor, accessToken]);
  useAppState({
    skip: Platform.OS === "web",
    onActive,
    onBackground,
  });
  const setPreferableAuthType = useCallback(function (type) {
    setAuth((auth) => ({
      ...auth,
      type,
    }));
    AsyncStorage.setItem(STORAGE_KEY_AUTH_TYPE, type);
  }, []);

  const ensureAll = useCallback(
    function (expectedPermissions) {
      const guard = ensure(expectedPermissions);
      return guard(permissions);
    },
    [permissions]
  );
  const ensureOneOf = useCallback(
    function (expectedPermissions) {
      const guard = oneOf(expectedPermissions);
      return guard(permissions);
    },
    [permissions]
  );
  const imperativeRefValue = useMemo(
    () => ({
      accessToken,
      state,
      lock,
    }),
    [accessToken, lock, state]
  );
  useImperativeHandle(ref, () => imperativeRefValue, [imperativeRefValue]);

  const value = useMemo(
    () => ({
      cert,
      accessToken,
      type,
      error,
      remainingPinEntryAttempts,
      state,
      pinLength,
      initializeIdentification,
      completeIdentification,
      requiresLegalChecks,
      completeLegalChecks,
      registerDevice,
      completeReidentification,
      logout,
      lock,
      tryToUnlockWithPin,
      tryToUnlockWithBiometry,
      setPreferableAuthType,
      ensureAll,
      ensureOneOf,
    }),
    [
      accessToken,
      cert,
      completeIdentification,
      completeLegalChecks,
      completeReidentification,
      ensureAll,
      ensureOneOf,
      error,
      initializeIdentification,
      lock,
      logout,
      pinLength,
      registerDevice,
      remainingPinEntryAttempts,
      requiresLegalChecks,
      setPreferableAuthType,
      state,
      tryToUnlockWithBiometry,
      tryToUnlockWithPin,
      type,
    ]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    cert: PropTypes.object,
    accessToken: PropTypes.string,
  }),
};

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (auth === undefined) {
    logger(
      "useAuth: Couldn't find an auth object. Is your component inside an AuthProvider?",
      auth,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return auth;
};
