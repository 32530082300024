import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Paragraph } from "../Paragraph";

import { styles, stylingHelpers } from "./EyrFormField.styles";

export function EyrFormField({ label, error, disabled, helperText, children }) {
  const hasHelper = Boolean(helperText);
  const textColor = stylingHelpers.getTextColor({ disabled, error });
  return (
    <View style={styles.container}>
      {renderIf(label)(
        <View style={styles.labelContainer}>
          <Paragraph color={textColor}>{label}</Paragraph>
        </View>
      )}
      {children}
      {renderIf(hasHelper)(
        <View style={styles.helperContainer}>
          <Paragraph color={textColor}>{helperText}</Paragraph>
        </View>
      )}
    </View>
  );
}

EyrFormField.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  children: PropTypes.node.isRequired,
};
