import React from "react";
import PropTypes from "prop-types";
import { View, useWindowDimensions } from "react-native";
import { CameraState } from "@eyr-mobile/core/Camera";
import { MicrophoneState } from "@eyr-mobile/core/Microphone";
import { useDevice } from "@eyr-mobile/core/Device";
import { useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";

import { SVGs } from "../../res";

import {
  styles,
  stylingHelpers,
  stylingProps,
} from "./CallSecondaryVideoFrame.styles";

export function CallSecondaryVideoFrame({
  cameraState = CameraState.UNKNOWN,
  microphoneState = MicrophoneState.UNKNOWN,
  VideoComponent = View,
  videoComponentProps = {},
  ...rest
}) {
  const cameraEnabled = cameraState === CameraState.ENABLED;
  const cameraDisabled = cameraState === CameraState.DISABLED;
  const microphoneDisabled = microphoneState === MicrophoneState.DISABLED;
  const cameraOrMicrophoneDisabled = cameraDisabled || microphoneDisabled;
  const { height: screenHeight, width: screenWidth } = useWindowDimensions();
  const { screenSizeSelect } = useDevice();
  const insets = useSafeAreaInsets();
  const videoOuterContainerStyle =
    stylingHelpers.getVideoOuterContainerStyle(insets);
  const responsiveVideoOuterContainer = screenSizeSelect({
    xs: [
      styles.videoOuterContainerXS,
      stylingHelpers.getVideoOuterContainerStylesPhoneXS(
        screenHeight,
        screenWidth
      ),
    ],
    s: styles.videoOuterContainerS,
  });

  return (
    <View
      {...rest}
      style={[videoOuterContainerStyle, responsiveVideoOuterContainer]}
    >
      {/*wrapping it in extra view to fix https://github.com/facebook/react-native/issues/449#issuecomment-131455892*/}
      <View style={styles.videoInnerContainer}>
        <VideoComponent
          {...videoComponentProps}
          properties={{
            ...stylingProps.video,
            ...videoComponentProps.properties,
          }}
          style={cameraEnabled ? styles.videoExpanded : styles.videoCollapsed}
        />
      </View>
      {cameraOrMicrophoneDisabled && (
        <View style={styles.videoStateOverlayContainer}>
          {microphoneDisabled && (
            <View style={styles.videoStateIconContainer}>
              <SVGs.MicrophoneOffMono {...stylingProps.videoStateIcon} />
            </View>
          )}
          {cameraDisabled && (
            <View style={styles.videoStateIconContainer}>
              <SVGs.CameraOffMono {...stylingProps.videoStateIcon} />
            </View>
          )}
        </View>
      )}
    </View>
  );
}

CallSecondaryVideoFrame.propTypes = {
  cameraState: PropTypes.oneOf(["unknown", "enabled", "disabled"]),
  microphoneState: PropTypes.oneOf(["unknown", "enabled", "disabled"]),
  VideoComponent: PropTypes.elementType,
  videoComponentProps: PropTypes.object,
};
