import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.PINK_60,
    height: 4,
  },
  filler: {
    flex: 1,
    backgroundColor: Palette.PINK_100,
  },
});
