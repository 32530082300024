import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export function show(message) {
  toast(message, {
    hideProgressBar: true,
  });
}

export { ToastContainer };
