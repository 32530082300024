import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  listItem: {
    paddingHorizontal: 16,
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  contentContainerXS: {
    ...responsiveStyles.containerXS,
    ...responsiveStyles.contentContainerXS,
  },
  contentContainerS: {
    ...responsiveStyles.containerS,
    ...responsiveStyles.contentContainerS,
  },
  contentContainerM: {
    ...responsiveStyles.containerM,
    ...responsiveStyles.contentContainerM,
  },
  listFooterContainer: {
    marginTop: 24,
  },
  emptyListContainer: {
    paddingVertical: 16,
    alignItems: "center",
    backgroundColor: Palette.OVERLAY_DARK_4,
    borderRadius: 8,
  },
});

export const stylingProps = {
  deleteIcon: {
    fill: Palette.RED_100,
  },
};
