/* TODO:
  - make so that non-files can be shared too
  - make file type variable (get file type from response?)
  - fetch only once for both sharing and downloading
  - shareOpen should be decoupled from downloading
  - share should only download if sharing doesnt work
  - navigator.share failure returns undefined and fails silently
*/

async function shareOpen({ url, title, headers }, options) {
  const response = await fetch(url, { headers });
  if (!response.ok) {
    throw new Error(`${response.status} ${response.statusText}`);
  }
  const blob = await response.blob();
  const files = [new File([blob], title, { type: "application/pdf" })];

  if (navigator.canShare && navigator.canShare({ files })) {
    await navigator.share({
      files,
      title,
      ...options,
    });
  }
}

export const useShare = () => {
  return {
    shareOpen,
    canShare: navigator.canShare,
  };
};
