import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSetPaymentMethods_PrimaryScreen.navigationTitle",
    description: "FlowStageSetPaymentMethods_PrimaryScreen navigation title",
    defaultMessage: "Select payment method",
  },
  availablePaymentMethodsTitle: {
    id: "FlowStageSetPaymentMethods_PrimaryScreen.availablePaymentMethodsTitle",
    description: "Available payment methods title",
    defaultMessage: "Your payment methods",
  },
  emptyPaymentMethodsListTitle: {
    id: "FlowStageSetPaymentMethods_PrimaryScreen.emptyPaymentMethodsListTitle",
    description: "Empty payment methods list title",
    defaultMessage: "No payment methods available",
  },
});
