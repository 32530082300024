import { defaultDataIdFromObject, InMemoryCache } from "@apollo/client";

// https://www.apollographql.com/docs/react/essentials/local-state/#initializing-the-cache
// ideally should be async and load values right away, so `always: true` part is not needed in @client directive
export const setupApolloCache = (config) => {
  return new InMemoryCache({
    typePolicies: {
      Order: {
        fields: {
          stages: {
            merge: false,
          },
        },
      },
      Query: {
        fields: {
          accountPaymentMethods: {
            merge: false,
          },
          account: {
            merge: true,
          },
          services: {
            merge: false,
          },
          accountDigitalTools: {
            merge: false,
          },
          appointmentOptions: {
            merge: false,
          },
        },
      },
      Subscription: {
        fields: {
          servicesUpdated: {
            merge: false,
          },
          productsUpdated: {
            merge: false,
          },
        },
      },
    },
    // some entities don't have id, it's necessary to derive id somehow to be able to store this entity in apollo cache
    dataIdFromObject: (object) => {
      const { __typename, slug, orderId, stageId, uuid, name } = object;

      switch (true) {
        case __typename === "PaymentProvider":
          return `${__typename}:${slug}`;
        case __typename?.startsWith("OrderStage"):
          return `${__typename}:${orderId}:${stageId}`;
        case __typename === "ScheduleItem":
          return uuid;
        case __typename === "Pharmacy":
          return name;
        default:
          return defaultDataIdFromObject(object);
      }
    },
    ...config,
  });
};
