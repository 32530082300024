import React from "react";
import { parseInt, size, get } from "lodash/fp";
import { useIntl } from "react-intl";
import moment from "moment";
import { View } from "react-native";
import { useForm } from "@eyr-mobile/core/Form";
import PropTypes from "prop-types";
import {
  configureExactLengthValidator,
  configureIsRequiredValidator,
  createValidator,
  validateUntilFirstError,
} from "@eyr-mobile/core/Validation";
import { renderIf } from "@eyr-mobile/core/Lib";

import { Paragraph } from "../Paragraph";
import { Subtitle } from "../Subtitle";
import { EyrFormField } from "../EyrFormField";
import { EyrTextInput } from "../EyrTextInput";
import { EyrButton } from "../EyrButton";
import { GraphQLError as Error } from "../GraphQLError";

import { messages } from "./AddCreditCard.messages";
import { styles } from "./AddCreditCard.styles";

const parseDecimal = parseInt(10);
export const parseExpiration = (expiration) => ({
  exp_month: parseDecimal(expiration.substring(0, 2)),
  exp_year: parseDecimal(expiration.substring(2, 4)) + 2000,
});

const numberValidator = validateUntilFirstError([
  configureIsRequiredValidator(messages.fieldIsRequiredError),
  configureExactLengthValidator(16, messages.numberInputLengthError),
]);

const expirationValidator = validateUntilFirstError([
  configureIsRequiredValidator(messages.fieldIsRequiredError),
  createValidator((expiration) => {
    if (size(expiration) !== 4) {
      return false;
    }
    const { exp_month, exp_year } = parseExpiration(expiration);
    const date = moment();
    // 0 ... 11
    const currentMonth = date.month() + 1;
    const currentYear = date.year();
    return (
      exp_month > 0 &&
      exp_month <= 12 &&
      (exp_year > currentYear ||
        (exp_year === currentYear && exp_month >= currentMonth))
    );
  }, messages.expirationInputInvalidError),
]);

const cvcValidator = validateUntilFirstError([
  configureIsRequiredValidator(messages.fieldIsRequiredError),
  configureExactLengthValidator(3, messages.cvcInputLengthError),
]);

export function AddCreditCard({ onSubmit }) {
  const { formatMessage } = useIntl();

  const {
    form,
    fields: { number, exp, cvc },
  } = useForm(
    {
      initialValues: {
        number: "",
        exp: "",
        cvc: "",
      },
      validators: {
        number: numberValidator,
        exp: expirationValidator,
        cvc: cvcValidator,
      },

      onSubmit: onSubmit,
    },
    { validateOnBlur: true, validateOnChange: true }
  );
  return (
    <View style={styles.container}>
      <EyrFormField label={formatMessage(messages.numberInputLabel)}>
        <EyrTextInput
          {...number}
          value={number.value.replace(/(.{4})/g, "$1 ").trim()}
          placeholder={formatMessage(messages.numberInputPlaceholder)}
          onChangeText={(value) => {
            number.onChange(value.replace(/\D/g, "").trim());
          }}
          maxLength={19}
          keyboardType="number-pad"
          spellCheck={false}
          clearButtonMode="while-editing"
          returnKeyType="done"
          autoCorrect={false}
          variant={number.invalid ? "danger" : "primary"}
        />
      </EyrFormField>
      <View style={styles.inputsContainer}>
        <View style={styles.smallInputContainer}>
          <EyrFormField label={formatMessage(messages.expirationInputLabel)}>
            <EyrTextInput
              {...exp}
              value={
                size(exp.value) <= 2
                  ? exp.value
                  : `${exp.value.substring(0, 2)}/${exp.value.substring(2, 4)}`
              }
              placeholder={formatMessage(messages.expirationInputPlaceholder)}
              keyboardType="number-pad"
              onChangeText={(value) => {
                exp.onChange(value.replace(/\D/g, "").trim());
              }}
              maxLength={5}
              spellCheck={false}
              clearButtonMode="while-editing"
              returnKeyType="done"
              autoCorrect={false}
              variant={exp.invalid ? "danger" : "primary"}
            />
          </EyrFormField>
        </View>
        <View style={styles.spacer} />
        <View style={styles.smallInputContainer}>
          <EyrFormField label={formatMessage(messages.cvcInputLabel)}>
            <EyrTextInput
              {...cvc}
              placeholder={formatMessage(messages.cvcInputPlaceholder)}
              keyboardType="number-pad"
              onChangeText={(value) => {
                cvc.onChange(value.replace(/\D/g, "").trim());
              }}
              maxLength={3}
              spellCheck={false}
              clearButtonMode="while-editing"
              returnKeyType="done"
              autoCorrect={false}
              variant={cvc.invalid ? "danger" : "primary"}
              secureTextEntry
            />
          </EyrFormField>
        </View>
      </View>
      <View style={styles.errorsContainer}>
        {renderIf(number.touched && number.error)(() => (
          <Paragraph
            size="l"
            color="negative"
            spacing="none"
            alignment="center"
          >{`${formatMessage(messages.numberInputLabel)}: ${formatMessage(
            ...number.error
          )}`}</Paragraph>
        ))}
        {renderIf(exp.touched && exp.error)(() => (
          <Paragraph
            size="l"
            color="negative"
            alignment="center"
          >{`${formatMessage(messages.expirationInputLabel)}: ${formatMessage(
            ...exp.error
          )}`}</Paragraph>
        ))}
        {renderIf(cvc.touched && cvc.error)(() => (
          <Paragraph
            size="l"
            color="negative"
            alignment="center"
          >{`${formatMessage(messages.cvcInputLabel)}: ${formatMessage(
            ...cvc.error
          )}`}</Paragraph>
        ))}
        {renderIf(form.error)(() => {
          let error;
          const { code, message } = form.error;
          const errorCodeMessageDescriptor = get(code, messages);
          if (errorCodeMessageDescriptor) {
            error = {
              message: formatMessage(errorCodeMessageDescriptor),
            };
          } else if (message) {
            error = form.error;
          } else {
            error = { message: formatMessage(messages.defaultError) };
          }
          return <Error error={error} />;
        })}
      </View>
      <View style={styles.spacer} />
      <View style={styles.footerContainer}>
        <Subtitle size="l" spacing="m" alignment="center">
          {formatMessage(messages.safePaymentMessage)}
        </Subtitle>
        <Paragraph size="l" spacing="l" alignment="center">
          {formatMessage(messages.cardValidityCheckMessage)}
        </Paragraph>
        <EyrButton
          onPress={form.submit}
          title={formatMessage(messages.addCard)}
          disabled={!form.valid}
          accessibilityLabel={formatMessage(messages.addCard)}
        />
      </View>
    </View>
  );
}

AddCreditCard.propTypes = {
  onSubmit: PropTypes.func,
};
