import { defineMessages } from "react-intl";

export const messages = defineMessages({
  requestUpdateTitle: {
    id: "AppUpdate.requestUpdateTitle",
    description: "Title for alert to update the app",
    defaultMessage: "New Eyr version available",
  },
  requestUpdateMessage: {
    id: "AppUpdate.requestUpdateMessage",
    description: "Message for alert to update the app",
    defaultMessage:
      "We recommend you update the app to the latest version for an improved experience.",
  },
  gettingUpdate: {
    id: "AppUpdate.gettingUpdate",
    description: "Getting update",
    defaultMessage: "Getting update",
  },
  requestUpdateCancelActionTitle: {
    id: "AppUpdate.requestUpdateCancelActionTitle",
    description: "Request update cancel action title",
    defaultMessage: "Later",
  },
  requestUpdateConfirmActionTitle: {
    id: "AppUpdate.requestUpdateConfirmActionTitle",
    description: "Request update confirm action title",
    defaultMessage: "Update now",
  },
});
