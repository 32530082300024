import React, { useEffect, useMemo } from "react";
import { filter, find } from "lodash/fp";
import { useIntl } from "react-intl";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  defaultCurrencyDisplayRules,
  GetPaymentMethodsForOrder,
  useOrderFlowStage,
  useSetPaymentMethodsFlowStage,
} from "@eyr-mobile/domain/Order";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  ProgressIndicator,
  SelectPaymentMethod,
  CancelOrderHeaderRight,
  Heading,
  AddPaymentMethod,
} from "../../components";

import { messages } from "./FlowStageSetPaymentMethods_SecondaryScreen.messages";
import { styles } from "./FlowStageSetPaymentMethods_SecondaryScreen.styles";

const logger = LoggerFactory.get(
  "screens/FlowStageSetPaymentMethods_SecondaryScreen"
);

export function FlowStageSetPaymentMethods_SecondaryScreen() {
  const { formatMessage, formatNumber } = useIntl();

  const [
    {
      primaryPaymentMethodId,
      selectCardWithPartialPaymentMethod,
      selectPaymentProviderWithPartialPaymentMethod,
      addCard,
    },
  ] = useSetPaymentMethodsFlowStage();
  const {
    data: {
      accountPaymentMethodsForOrder: accountPaymentMethods,
      paymentProvidersForOrder: paymentProviders,
    } = {},
    stageParams: { progress, progressPerStage, product: { price } = {} } = {},
    refreshing,
    refetch,
    handlers,
  } = withHandlers(
    useOrderFlowStage(GetPaymentMethodsForOrder, ({ orderId }) => ({
      variables: { orderId },
    }))
  );

  const partialPaymentMethod = useMemo(
    () => find({ id: primaryPaymentMethodId }, accountPaymentMethods),
    [accountPaymentMethods, primaryPaymentMethodId]
  );

  useEffect(() => {
    if (!primaryPaymentMethodId) {
      logger(
        "Secondary payment method is meant to be used with Primary, expected to have primary payment method, got:",
        primaryPaymentMethodId,
        LOGGER_LEVEL_ERROR
      );
    }
  }, [primaryPaymentMethodId]);

  const progressWithinSubStages = progress + (2 / 3) * progressPerStage;

  const remaining =
    price && partialPaymentMethod
      ? {
          ...price,
          amount:
            price.amount - partialPaymentMethod.orderCoverage.discount.amount,
        }
      : undefined;
  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <SelectPaymentMethod
            refreshing={refreshing}
            onRefresh={refetch}
            ListHeaderComponent={
              remaining && (
                <Heading spacing="l" alignment="center">
                  {formatMessage(messages.title, {
                    remainingAmount: formatNumber(remaining.amount, {
                      currency: remaining.currency,
                      ...defaultCurrencyDisplayRules,
                    }),
                  })}
                </Heading>
              )
            }
            ListFooterComponent={
              <AddPaymentMethod
                onAddCardSelected={addCard}
                orderedOptions={["CARD"]}
                showTitle={false}
              />
            }
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
            toPay={remaining}
            accountPaymentMethods={filter(
              { type: "CARD" },
              accountPaymentMethods
            )}
            paymentProviders={paymentProviders}
            onCardSelected={selectCardWithPartialPaymentMethod}
            onPaymentProviderSelected={
              selectPaymentProviderWithPartialPaymentMethod
            }
          />
        </>
      )}
    </SafeAreaView>
  );
}
FlowStageSetPaymentMethods_SecondaryScreen.routeName =
  "FlowStageSetPaymentMethods_SecondaryScreen";

FlowStageSetPaymentMethods_SecondaryScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
