import React, { useCallback, useRef } from "react";
import { LoggerFactory } from "@eyr-mobile/core/Logger";
import { useAppState } from "@eyr-mobile/core/AppState";
import { getAdditionalHeaders, getWebViewURL } from "@eyr-mobile/core/Net";
import { useApolloClient } from "@eyr-mobile/core/DataProvider";
import { useNavigation, useRoute } from "@react-navigation/native";
import { GetAccountPaymentMethods } from "@eyr-mobile/domain/Order";
import {
  getAuthorizationBearerHeader,
  injectWebWebViewAuth,
  useAuth,
} from "@eyr-mobile/domain/Auth";
import {
  applicationNameForUserAgent,
  disableScalingInjectedJavaScript,
  patchPostMessageInjectedJavaScript,
  WebView,
  safeHandlePostMessage,
} from "@eyr-mobile/core/WebView";
import { Platform } from "react-native";

const logger = LoggerFactory.get(
  "screens/DisabledPaymentMethodResolutionScreen"
);

export function DisabledPaymentMethodResolutionScreen() {
  const _ref = useRef(null);
  const route = useRoute();
  const uri = route?.params?.uri;
  const { accessToken } = useAuth();

  const { goBack } = useNavigation();

  const onActive = useCallback(() => {
    const webView = _ref?.current;
    if (!webView) {
      return;
    }
    webView.postMessage("refresh");
  }, []);

  useAppState({
    onActive,
  });
  const client = useApolloClient();

  const onMessage = useCallback(
    async ({ nativeEvent }) => {
      safeHandlePostMessage(
        nativeEvent,
        (message, data, level) => {
          logger(`${getWebViewURL()}/insurances:${message}`, data, level);
        },
        async (data) => {
          const [result] = data.split(":");
          switch (result) {
            case "ok":
              try {
                await client.query({
                  query: GetAccountPaymentMethods,
                  fetchPolicy: "network-only",
                });
              } finally {
                goBack();
              }
              break;
            case "error":
            default:
          }
        }
      );
    },
    [goBack, client]
  );

  return (
    <WebView
      ref={(ref) => (_ref.current = ref)}
      source={Platform.select({
        default: {
          headers: {
            ...getAdditionalHeaders(),
            ...getAuthorizationBearerHeader(accessToken),
          },
          uri,
        },
        web: {
          uri: injectWebWebViewAuth(uri, accessToken),
        },
      })}
      injectedJavaScript={
        disableScalingInjectedJavaScript + patchPostMessageInjectedJavaScript
      }
      onMessage={onMessage}
      applicationNameForUserAgent={applicationNameForUserAgent}
      originWhitelist={[getWebViewURL().origin]}
      testID={"DisabledPaymentMethodResolution"}
      web_postMessageTarget={"window"}
    />
  );
}
DisabledPaymentMethodResolutionScreen.routeName =
  "DisabledPaymentMethodResolutionScreen";
DisabledPaymentMethodResolutionScreen.navigationOptions = ({ route }) => ({
  title: route?.params?.navigationTitle,
});
