import { defineMessages } from "react-intl";

import { serviceProviderTitleMessages } from "../../shared-messages";

export const messages = {
  ...defineMessages({
    dropInSelectionTitle: {
      id: "FlowStageSpecifyAppointmentInformationSummaryItem.dropInSelectionTitle",
      description: "Drop in selection title",
      defaultMessage: "See next available {serviceProviderTitle}",
    },
    dropInSelectionDescription: {
      id: "FlowStageSpecifyAppointmentInformationSummaryItem.dropInSelectionDescription",
      description: "Drop in selection description",
      defaultMessage: "Estimated waiting time",
    },
    appointmentSelectionTitle: {
      id: "FlowStageSpecifyAppointmentInformationSummaryItem.appointmentSelectionTitle",
      description: "Appointment selection title",
      defaultMessage: "{date} at {time}",
    },
    appointmentSelectionDescription: {
      id: "FlowStageSpecifyAppointmentInformationSummaryItem.appointmentSelectionDescription",
      description: "Appointment selection description",
      defaultMessage: "With doctor {name}",
    },
    waitingTime: {
      id: "FlowStageSpecifyAppointmentInformationSummaryItem.waitingTime",
      description: "Waiting time minutes for drop in",
      defaultMessage: "{queueWaitingTimeMinutes} minutes",
    },
  }),
  ...serviceProviderTitleMessages,
};
