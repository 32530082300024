import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

const sizeXXS = 18;
const sizeXS = 24;
const sizeS = 28;
const sizeM = 32;
const sizeL = 40;
const sizeXL = 64;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  xxs: {
    width: sizeXXS,
    height: sizeXXS,
  },
  xs: {
    width: sizeXS,
    height: sizeXS,
  },
  s: {
    width: sizeS,
    height: sizeS,
  },
  m: {
    width: sizeM,
    height: sizeM,
  },
  l: {
    width: sizeL,
    height: sizeL,
  },
  xl: {
    width: sizeXL,
    height: sizeXL,
  },
  primary: {
    backgroundColor: Palette.NUDE_20,
  },
  dark: {
    backgroundColor: Palette.GRAY_150,
  },
  danger: {
    backgroundColor: Palette.RED_100,
  },
  xxsRound: {
    borderRadius: sizeXXS / 2,
  },
  xsRound: {
    borderRadius: sizeXS / 2,
  },
  sRound: {
    borderRadius: sizeS / 2,
  },
  mRound: {
    borderRadius: sizeM / 2,
  },
  lRound: {
    borderRadius: sizeL / 2,
  },
  xlRound: {
    borderRadius: sizeXL / 2,
  },
  rounded: {
    borderRadius: 8,
  },
});

const iconStylingProps = {
  xxs: {
    width: sizeXXS,
    height: sizeXXS,
  },
  xs: {
    width: sizeXS,
    height: sizeXS,
  },
  s: {
    width: sizeS,
    height: sizeS,
  },
  m: {
    width: sizeM,
    height: sizeM,
  },
  l: {
    width: sizeL,
    height: sizeL,
  },
  xl: {
    width: sizeXL,
    height: sizeXL,
  },
  xxsIcon: {
    width: sizeXXS * 0.6,
    height: sizeXXS * 0.6,
  },
  xsIcon: {
    width: sizeXS * 0.6,
    height: sizeXS * 0.6,
  },
  sIcon: {
    width: sizeS * 0.6,
    height: sizeS * 0.6,
  },
  mIcon: {
    width: sizeM * 0.6,
    height: sizeM * 0.6,
  },
  lIcon: {
    width: sizeL * 0.6,
    height: sizeL * 0.6,
  },
  xlIcon: {
    width: sizeXL * 0.6,
    height: sizeXL * 0.6,
  },
  primary: {
    fill: Palette.BURGUNDY_100,
  },
  dark: {
    fill: Palette.GRAY_150,
  },
  danger: {
    fill: Palette.RED_100,
  },
};

export const stylingHelpers = {
  getContainerStyle: ({ variant, color, size }) => {
    switch (variant) {
      case "plain":
        return [styles.container, styles[size]];
      case "round": {
        return [
          styles.container,
          styles[size],
          styles[color],
          styles[`${size}Round`],
        ];
      }
      case "rounded": {
        return [styles.container, styles[size], styles[color], styles.rounded];
      }
    }
  },
  getIconStylingProps: ({ variant, size, color }) => {
    switch (variant) {
      case "round":
      case "rounded": {
        return {
          ...iconStylingProps[color],
          ...iconStylingProps[`${size}Icon`],
        };
      }
      case "plain": {
        return {
          ...iconStylingProps[color],
          ...iconStylingProps[size],
        };
      }
    }
  },
};
