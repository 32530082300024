import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { Shadows } from "../../shared-styles/shadow";

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Palette.WHITE,
    borderRadius: 8,
    ...Shadows.xs,
  },
  disabledContainer: {
    backgroundColor: Palette.OVERLAY_DARK_4,
    borderRadius: 8,
  },
  paddedContainer: {
    padding: 16,
  },
  innerContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  spacingNone: {
    marginBottom: 0,
  },
  spacingM: {
    marginBottom: 16,
  },
  descriptionOrExtraInfoContainer: { marginTop: 4 },
  interactivityIcon: {
    marginLeft: 8,
    minWidth: 22,
    minHeight: 22,
  },
  iconRowContainer: {
    marginRight: 12,
  },
  iconColumnContainer: {
    marginRight: 12,
    marginBottom: 12,
  },
  titleAndBadgeContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: "100%",
  },
  title: {
    flex: 1,
    flexWrap: "wrap",
  },
  interactivityIconAndBadgeContainer: {
    alignItems: "center",
    alignSelf: "flex-start",
    flexDirection: "row",
    marginLeft: "auto",
  },
  titleContainer: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: "center",
  },
  iconContainer: {
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  badgeContainer: {
    marginLeft: 8,
  },
});

export const stylingProps = {
  interactivityIcon: {
    fill: Palette.BURGUNDY_100,
    width: 22,
    height: 22,
  },
};

export const stylingHelpers = {
  getContainerStyle(disabled) {
    if (disabled) {
      return styles.disabledContainer;
    }
    return styles.container;
  },
};
