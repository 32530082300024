import React, { useCallback, useMemo } from "react";
import { Linking, ScrollView, View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation } from "@react-navigation/native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { withHandlers, useQuery } from "@eyr-mobile/core/DataProvider";
import {
  GetHowItWorksScreenData,
  EYR_SUPPORT_EMAIL,
  OrderAnalyticsEvent,
  useInitOrder,
} from "@eyr-mobile/domain/Order";
import { some, find, map, get } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";
import { logAnalyticsEvent } from "@eyr-mobile/core/Analytics";
import { ProductType } from "@eyr-mobile/domain/Order/Order.constants";

import {
  Accordion,
  EyrButton,
  CardInner,
  IconContainer,
  Subtitle,
  Paragraph,
} from "../../components";
import { SVGs } from "../../res";

import { messages } from "./HowItWorksScreen.messages";
import { styles, stylingProps } from "./HowItWorksScreen.styles";

const supportUrl = "eyr.md";
function handleSupportEmailPress() {
  return Linking.openURL(`mailto:${EYR_SUPPORT_EMAIL}`);
}

export function HowItWorksScreen() {
  const { formatMessage } = useIntl();
  const { navigate } = useNavigation();
  const { handlers, data: { products = [], account = {} } = {} } = withHandlers(
    useQuery(GetHowItWorksScreenData)
  );

  const [initOrder] = useInitOrder();

  const [
    {
      showMenu: showPhysicianConsultationSection,
      showButton: showPhysicianConsultationSectionButton,
      handlePress: handleOrderPhysicianConsultationPress,
    },
    {
      showMenu: showPsychologistConsultationSection,
      showButton: showPsychologistConsultationSectionButton,
      handlePress: handleOrderPsychologistConsultationPress,
    },
    {
      showMenu: showVaccinationSection,
      showButton: showVaccinationSectionButton,
      handlePress: handleOrderVaccinationPress,
    },
    {
      showMenu: showGuideConsultationSection,
      showButton: showGuideConsultationButton,
      handlePress: handleOrderGuideConsultationPress,
    },
  ] = useMemo(() => {
    return map(
      (type) => {
        const firstAvailableProduct = find(
          { type, availabilityState: "available" },
          products
        );
        return {
          showMenu: some({ type }, products),
          showButton: Boolean(firstAvailableProduct),
          handlePress: () => {
            if (type === ProductType.VACCINATION) {
              navigate("VaccinationCatalogScreen");
              return;
            }
            const {
              id: productId,
              type: productType,
              subtype: productSubtype,
            } = firstAvailableProduct || {};
            logAnalyticsEvent(OrderAnalyticsEvent.INIT_ORDER, {
              productId,
              productType,
              productSubtype,
            });
            initOrder(firstAvailableProduct);
          },
        };
      },
      // ORDER IS HARDCODED ATM
      [
        ProductType.CONSULTATION,
        ProductType.PSYCHOLOGIST_CONSULTATION,
        ProductType.VACCINATION,
        ProductType.GUIDE_CONSULTATION,
      ]
    );
  }, [products, initOrder, navigate]);

  /*
    TODO:
    clean up (i.ex: slim down) the markUp with new product list + handler
  */

  const { screenSizeSelect } = useDevice();
  const handleSupportUrlPress = useCallback(() => {
    const accountCountryCode = get("country.codeIso2", account);
    const url = new URL(`https://${supportUrl}`);
    if (accountCountryCode) {
      url.pathname = accountCountryCode.toLowerCase();
    }
    return Linking.openURL(url.toString());
  }, [account]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <ScrollView
          contentContainerStyle={screenSizeSelect({
            xs: styles.contentContainerXS,
            s: styles.contentContainerS,
            m: styles.contentContainerM,
          })}
        >
          <View style={styles.headerIconContainer}>
            <SVGs.DoctorSecondary {...stylingProps.icon} />
          </View>
          <Subtitle size="l">{formatMessage(messages.header)}</Subtitle>
          <View style={styles.sectionsContainer}>
            {showPhysicianConsultationSection && (
              <>
                <Accordion
                  title={formatMessage(messages.menuDoctorTitle)}
                  header={
                    <CardInner
                      pressable={false}
                      title={formatMessage(messages.menuDoctorTitle)}
                      titleIsBold
                      showInteractivityIcon={false}
                      icon={
                        <IconContainer variant="plain">
                          <SVGs.ColdFlue />
                        </IconContainer>
                      }
                    />
                  }
                  iconPosition="center"
                >
                  <View style={styles.section}>
                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuDoctorSummary)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuDoctorBookingTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuDoctorBookingText)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuDoctorContactTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuDoctorContactTextP1)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuDoctorContactTextP2)}
                      </Paragraph>
                    </View>

                    {showPhysicianConsultationSectionButton && (
                      <View style={styles.sectionButton}>
                        <EyrButton
                          onPress={handleOrderPhysicianConsultationPress}
                          title={formatMessage(messages.menuDoctorButton)}
                          variant="primary"
                        />
                      </View>
                    )}
                  </View>
                </Accordion>
                <View style={styles.sectionSpacer} />
              </>
            )}
            {showPsychologistConsultationSection && (
              <>
                <Accordion
                  title={formatMessage(messages.menuPsychologistTitle)}
                  header={
                    <CardInner
                      pressable={false}
                      title={formatMessage(messages.menuPsychologistTitle)}
                      titleIsBold
                      showInteractivityIcon={false}
                      icon={
                        <IconContainer variant="plain">
                          <SVGs.Headache />
                        </IconContainer>
                      }
                    />
                  }
                  iconPosition="center"
                >
                  <View style={styles.section}>
                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistSummary)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuPsychologistBookingTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistBookingTextP1)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistBookingTextP2)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistBookingTextP3)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuPsychologistContactTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistContactTextP1)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistContactTextP2)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPsychologistContactTextP3)}
                      </Paragraph>
                    </View>

                    {showPsychologistConsultationSectionButton && (
                      <View style={styles.sectionButton}>
                        <EyrButton
                          onPress={handleOrderPsychologistConsultationPress}
                          title={formatMessage(messages.menuPsychologistButton)}
                          variant="primary"
                        />
                      </View>
                    )}
                  </View>
                </Accordion>
                <View style={styles.sectionSpacer} />
              </>
            )}
            {showVaccinationSection && (
              <>
                <Accordion
                  title={formatMessage(messages.menuVaccineTitle)}
                  header={
                    <CardInner
                      pressable={false}
                      title={formatMessage(messages.menuVaccineTitle)}
                      titleIsBold
                      showInteractivityIcon={false}
                      icon={
                        <IconContainer variant="plain">
                          <SVGs.Vaccine />
                        </IconContainer>
                      }
                    />
                  }
                  iconPosition="center"
                >
                  <View style={styles.section}>
                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuVaccineOrderTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuVaccineOrderText)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuVaccinePrescriptionsTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuVaccinePrescriptionsTextP1)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuVaccinePrescriptionsTextP2)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuVaccinePrescriptionsTextP3)}
                      </Paragraph>
                    </View>

                    {showVaccinationSectionButton && (
                      <View style={styles.sectionButton}>
                        <EyrButton
                          onPress={handleOrderVaccinationPress}
                          title={formatMessage(messages.menuVaccineButton)}
                          variant="primary"
                        />
                      </View>
                    )}
                  </View>
                </Accordion>
                <View style={styles.sectionSpacer} />
              </>
            )}
            {showGuideConsultationSection && (
              <>
                <Accordion
                  title={formatMessage(messages.menuPatientGuideTitle)}
                  header={
                    <CardInner
                      pressable={false}
                      title={formatMessage(messages.menuPatientGuideTitle)}
                      titleIsBold
                      showInteractivityIcon={false}
                      icon={
                        <IconContainer variant="plain">
                          <SVGs.ColdFlue />
                        </IconContainer>
                      }
                    />
                  }
                  iconPosition="center"
                >
                  <View style={styles.section}>
                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPatientGuideSummary)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(
                          messages.menuPatientGuideScheduleAppointmentTitle
                        )}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(
                          messages.menuPatientGuideScheduleAppointmentText
                        )}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Subtitle size="l">
                        {formatMessage(messages.menuPatientGuideContactTitle)}
                      </Subtitle>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPatientGuideContactTextP1)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPatientGuideContactTextP2)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPatientGuideContactTextP3)}
                      </Paragraph>
                    </View>

                    <View style={styles.sectionChapter}>
                      <Paragraph size="l">
                        {formatMessage(messages.menuPatientGuideContactTextP4)}
                      </Paragraph>
                    </View>

                    {showGuideConsultationButton && (
                      <View style={styles.sectionButton}>
                        <EyrButton
                          onPress={handleOrderGuideConsultationPress}
                          title={formatMessage(messages.menuPatientGuideButton)}
                          variant="primary"
                        />
                      </View>
                    )}
                  </View>
                </Accordion>
                <View style={styles.sectionSpacer} />
              </>
            )}
          </View>
          <Paragraph size="l">
            {formatMessage(messages.footer, {
              url: (
                <Subtitle
                  onPress={handleSupportUrlPress}
                  size="l"
                  decoration="underline"
                >
                  {supportUrl}
                </Subtitle>
              ),
              email: (
                <Subtitle
                  onPress={handleSupportEmailPress}
                  size="l"
                  decoration="underline"
                >
                  {EYR_SUPPORT_EMAIL}
                </Subtitle>
              ),
            })}
          </Paragraph>
        </ScrollView>
      )}
    </SafeAreaView>
  );
}

HowItWorksScreen.routeName = "HowItWorksScreen";
HowItWorksScreen.navigationOptions = {
  title: messages.navigationTitle,
};
