import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  loading: {
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  progress: {
    width: 200,
    height: 2,
  },
  label: {
    marginTop: 10,
  },
  center: {
    textAlign: "center",
  },
});

export const stylingProps = {
  progressViewNative: (color) => ({
    trackTintColor: Palette.NUDE_60,
    progressTintColor: color || Palette.BURGUNDY_80,
  }),
  progressBarWeb: (color) => ({
    color: color || Palette.BURGUNDY_80,
    trackColor: Palette.NUDE_60,
  }),
  activityIndicator: (color) => ({
    color: color || Palette.BURGUNDY_80,
  }),
};
