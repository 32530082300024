import React, { useMemo, useEffect, useRef } from "react";
import {
  every,
  getOr,
  get,
  isEmpty,
  isUndefined,
  upperFirst,
  size,
  includes,
} from "lodash/fp";
import moment from "moment";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import { View } from "react-native";
import { useIntl } from "react-intl";
import { useDevice } from "@eyr-mobile/core/Device";
import { useAlert } from "@eyr-mobile/core/Alert";
import { formatNextTwoDaysAsRelative_DateOtherwise } from "@eyr-mobile/core/Lib";
import {
  GetAppointmentOptionsAndAccountInfo,
  ProductSubtype,
  useCancelOrder,
  useOrderFlowStage,
  useSpecifyAppointmentInformationFlowStage,
} from "@eyr-mobile/domain/Order";

import {
  ProgressIndicator,
  ProviderPicker,
  DatePicker,
  Schedule,
  ProfileImage,
  CancelOrderHeaderRight,
} from "../../components";

import { messages } from "./FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.messages";
import {
  styles,
  stylingHelpers,
} from "./FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.styles";

const suicidePreventionAlertEnabledFor = [
  ProductSubtype.ASSISTED_SELF_HELP,
  ProductSubtype.PSYCHOLOGIST_CONSULTATION,
];
const getFilter = ({ date, provider } = {}) => {
  if (every(isEmpty, [date, provider])) {
    return;
  }
  return {
    ...(provider && {
      providerId: get("id", provider),
    }),
    ...(date && {
      startsAtIn: {
        start: moment(date).startOf("day").utc().format(),
        finish: moment(date).endOf("day").utc().format(),
      },
    }),
  };
};

export function FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen() {
  const alert = useAlert();
  const { screenSizeSelect } = useDevice();

  const intl = useIntl();
  const { formatMessage, formatTime } = intl;
  const [cancelOrder] = useCancelOrder();

  const [
    {
      setSelectedProvider,
      initialSelectedProvider,
      selectedScheduleItem,
      saveSelectedScheduleItem,
      setSelectedDate,
      selectedProvider,
      selectedDate,
      setSelectedScheduleItem,
    },
  ] = useSpecifyAppointmentInformationFlowStage();

  const filter = useMemo(
    () =>
      getFilter({
        provider:
          isUndefined(selectedProvider) && initialSelectedProvider
            ? initialSelectedProvider
            : selectedProvider,
        date: selectedDate,
      }),
    [selectedProvider, initialSelectedProvider, selectedDate]
  );
  const {
    data: currentData,
    handlers,
    refreshing,
    refetch,
    previousData,
    stageParams: {
      progress,
      product: {
        type: productType,
        subtype: productSubtype,
        image: productImage,
      } = {},
      progressPerStage,
    } = {},
  } = withHandlers(
    useOrderFlowStage(GetAppointmentOptionsAndAccountInfo, ({ orderId }) => ({
      variables: {
        orderId,
        ...(filter && { filter }),
      },
      fetchPolicy: "network-only",
    }))
  );

  const progressWithinSubStages = progress + (1 / 2) * progressPerStage;
  const data = currentData || previousData;
  const countryCodeIso2 = get("account.country.codeIso2", data);

  useEffect(() => {
    if (!selectedScheduleItem) {
      return;
    }
    const { provider, startsAt } = selectedScheduleItem;
    const { image, name, description } = provider;
    alert({
      title: name,
      heading: formatMessage(messages.dayAndTime, {
        date: upperFirst(
          formatNextTwoDaysAsRelative_DateOtherwise({
            date: startsAt,
            intl,
          })
        ),
        time: formatTime(startsAt),
      }),
      message: description,
      variant: screenSizeSelect({
        xs: "bottomSheet",
        s: "modal",
      }),
      showCloseButton: true,
      onClose: () => setSelectedScheduleItem(null),
      icon: (
        <ProfileImage uri={image} size="l" placeholder={name} shape="round" />
      ),
      buttons: [
        {
          title: formatMessage(messages.confirmationActionTitle),
          onPress: saveSelectedScheduleItem,
        },
      ],
    });
  }, [
    alert,
    selectedScheduleItem,
    setSelectedScheduleItem,
    formatMessage,
    intl,
    formatTime,
    saveSelectedScheduleItem,
    screenSizeSelect,
  ]);

  const emergencyNumber = get("account.country.emergencyNumber", data);
  const suicidePreventionAlertShownOnceRef = useRef(false);

  useEffect(() => {
    if (suicidePreventionAlertShownOnceRef.current) {
      return;
    }
    if (
      includes(productSubtype, suicidePreventionAlertEnabledFor) &&
      Boolean(emergencyNumber)
    ) {
      alert({
        message: formatMessage(
          messages[`emergencyWarningMessage${countryCodeIso2}`],
          {
            emergencyNumber,
          }
        ),
        variant: screenSizeSelect({
          xs: "bottomSheet",
          s: "modal",
        }),
        buttons: [
          {
            title: formatMessage(
              messages.emergencyWarningConfirmationActionTitle
            ),
            onPress: () => (suicidePreventionAlertShownOnceRef.current = true),
          },
          {
            title: formatMessage(messages.emergencyWarningCancelActionTitle),
            onPress: cancelOrder,
            variant: "secondary",
          },
        ],
        buttonsLayout: "column",
        disableRequestClose: true,
        disableOverlayClose: true,
      });
    }
  }, [
    alert,
    countryCodeIso2,
    emergencyNumber,
    formatMessage,
    productSubtype,
    cancelOrder,
    screenSizeSelect,
  ]);

  const responsiveContentContainerStyle = screenSizeSelect({
    xs: styles.contentContainerXS,
    s: styles.contentContainerS,
    m: styles.contentContainerM,
  });
  const filterValues = getOr({}, "appointmentOptions.filterValues", data);
  const scheduleItems = getOr([], "appointmentOptions.scheduleItems", data);
  const hasScheduleItems = size(scheduleItems) > 0;

  const flatListContentContainerStyle =
    stylingHelpers.getContentContainerStyle(hasScheduleItems);

  return (
    <SafeAreaView style={styles.container} edges={["right", "bottom", "left"]}>
      {hasScheduleItems && (
        <ProgressIndicator
          value={progressWithinSubStages}
          accessibilityLabel={`Order progress indicator ${
            progressWithinSubStages * 100
          }%`}
        />
      )}
      {hasScheduleItems && (
        <View
          style={screenSizeSelect({
            xs: styles.containerXS,
            s: styles.containerS,
            m: styles.containerM,
          })}
        >
          <View style={responsiveContentContainerStyle}>
            <View style={styles.pickerContainer}>
              <DatePicker
                dates={getOr([], "dates", filterValues)}
                onDateSelected={setSelectedDate}
                initialSelectedDate={selectedDate}
              />
            </View>
            <View style={styles.pickerContainer}>
              <ProviderPicker
                productType={productType}
                initialSelectedProvider={initialSelectedProvider}
                providers={getOr([], "providers", filterValues)}
                onProviderSelected={setSelectedProvider}
              />
            </View>
          </View>
        </View>
      )}
      {handlers || (
        <Schedule
          style={styles?.scheduleContainer}
          contentContainerStyle={[
            responsiveContentContainerStyle,
            flatListContentContainerStyle,
          ]}
          showProviderName={!selectedProvider}
          showDate={!selectedDate}
          showSectionTitles={!selectedDate}
          scheduleItems={scheduleItems}
          onScheduleItemSelected={setSelectedScheduleItem}
          refreshing={refreshing}
          onRefresh={refetch}
          productType={productType}
          productImage={productImage}
        />
      )}
    </SafeAreaView>
  );
}

FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.routeName =
  "FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen";

FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen.navigationOptions =
  {
    title: messages.navigationTitle,
    headerRight: CancelOrderHeaderRight,
  };
