import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Pressable, View } from "react-native";
import { useIntl } from "react-intl";
import { Grayscale } from "@eyr-mobile/core/ImageFilters";
import {
  compact,
  get,
  capitalize,
  size,
  last,
  reject,
  first,
  uniqBy,
  map,
  includes,
  reverse,
} from "lodash/fp";
import {
  CoverageType,
  defaultCurrencyDisplayRules,
  moneyShape,
} from "@eyr-mobile/domain/Order";

import { Heading } from "../Heading";
import { Paragraph } from "../Paragraph";
import { EyrButton } from "../EyrButton";
import { Subtitle } from "../Subtitle";
import { ResAwareSvg } from "../ResAwareSvg";
import { ResAwareImage } from "../ResAwareImage";

import { styles, stylingProps, stylingHelpers } from "./ProductCard.styles";
import { messages } from "./ProductCard.messages";

const mapWithKey = map.convert({ cap: false });
const getCoverageDisplayName = (
  { coverage, price },
  { formatMessage, formatNumber }
) => {
  function appendCoverageConditionIfNeeded(coverage, originalMessage) {
    return coverage.condition ? `${originalMessage} *` : originalMessage;
  }
  switch (coverage.type) {
    case CoverageType.FULL:
      return appendCoverageConditionIfNeeded(
        coverage,
        formatMessage(messages.coveredTitle)
      );
    case CoverageType.PARTIAL:
      return appendCoverageConditionIfNeeded(
        coverage,
        formatMessage(messages.partialPaymentTitle)
      );
    case CoverageType.DISCOUNT:
      return appendCoverageConditionIfNeeded(
        coverage,
        formatMessage(messages.discountTitle, {
          discountPercentage: formatNumber(
            Math.min(coverage.discount.amount / price.amount, 1),
            {
              style: "percent",
            }
          ),
        })
      );
    default:
      return null;
  }
};
const getCoveragesAffectingThePrice = reject({ type: "NONE" });

export function ProductCard({
  title,
  description,
  image,
  price,
  coverages = [],
  disabled,
  spacing = "m",
  layout = "column",
  buttons = [],
  buttonsLayout = "row",
  itemType = "Product",
}) {
  const { formatMessage, formatNumber } = useIntl();
  const [coverageToShowOverride, setCoverageToShowOverride] = useState(null);
  const getLayoutAwareStyle = stylingHelpers.getLayoutAwareStyleFor(layout);
  const getButtonsLayoutAwareStyle =
    stylingHelpers.getLayoutAwareStyleFor(buttonsLayout);
  const containerStyle = stylingHelpers.getContainerStyle(disabled);
  const textColor = disabled ? "dark" : "primary";

  const coveragesAffectingThePrice = useMemo(
    () => getCoveragesAffectingThePrice(coverages),
    [coverages]
  );

  const theBestCoverage = first(coveragesAffectingThePrice);

  const coveragesToShow = useMemo(
    () => uniqBy("org.id", coveragesAffectingThePrice),
    [coveragesAffectingThePrice]
  );

  const coverageToShow = coverageToShowOverride || theBestCoverage;

  return (
    <View
      style={compact([
        containerStyle,
        get(`spacing${capitalize(spacing)}`, styles),
        { flexDirection: layout },
      ])}
    >
      <View style={getLayoutAwareStyle("imageContainer")}>
        {!disabled && size(coveragesAffectingThePrice) > 0 && (
          <View style={styles.coverageContainer}>
            <View style={styles.coverageChip}>
              <Subtitle size="m" color={textColor}>
                {getCoverageDisplayName(
                  {
                    coverage: coverageToShow,
                    price,
                  },
                  { formatMessage, formatNumber }
                )}
              </Subtitle>
            </View>
            <View style={styles.insuranceChips}>
              {mapWithKey(
                (coverage, key) => (
                  <Pressable
                    onPressIn={() => setCoverageToShowOverride(coverage)}
                    onPressOut={() => setCoverageToShowOverride(null)}
                    style={[
                      styles.insuranceChip,
                      stylingHelpers.getOverlapStyle(
                        key,
                        size(coveragesToShow),
                        coverage === coverageToShowOverride
                      ),
                    ]}
                    key={key.toString()}
                  >
                    <ResAwareSvg
                      svg={coverage.org.smallIconUrl}
                      {...stylingProps.insuranceIcon}
                    />
                  </Pressable>
                ),
                coveragesToShow
              )}
            </View>
          </View>
        )}
        {disabled ? (
          <Grayscale style={styles.grayScaleImageContainer}>
            <ResAwareImage image={image} style={getLayoutAwareStyle("image")} />
          </Grayscale>
        ) : (
          <ResAwareImage image={image} style={getLayoutAwareStyle("image")} />
        )}
        <View style={getLayoutAwareStyle("imageOverlay")} />
      </View>
      <View style={getLayoutAwareStyle("contentContainer")}>
        <View>
          <Heading size="s" spacing="m" color={textColor}>
            {title}
          </Heading>
          <Paragraph color={textColor}>{description}</Paragraph>
        </View>
        {!disabled &&
          itemType === "Product" &&
          (size(coveragesAffectingThePrice) === 0 ||
            (size(coveragesAffectingThePrice) > 0 &&
              includes(coverageToShow.type, [
                CoverageType.DISCOUNT,
                CoverageType.PARTIAL,
              ]))) && (
            <View style={styles.toBePaidContainer}>
              <Paragraph size="l">
                {formatMessage(messages.toBePaidTitle)}
              </Paragraph>
              <View style={styles.pricesContainer}>
                <View style={styles.priceToBePaidContainer}>
                  <Subtitle size="l">
                    {formatNumber(
                      coverageToShow
                        ? Math.max(
                            price.amount - coverageToShow.discount.amount,
                            0
                          )
                        : price.amount,
                      {
                        currency: price.currency,
                        ...defaultCurrencyDisplayRules,
                      }
                    )}
                  </Subtitle>
                </View>
                {Boolean(coverageToShow) &&
                  coverageToShow.type === CoverageType.DISCOUNT && (
                    <View style={styles.originalPriceContainer}>
                      <Paragraph decoration="strikethrough">
                        {formatNumber(price.amount, {
                          currency: price.currency,
                          ...defaultCurrencyDisplayRules,
                        })}
                      </Paragraph>
                    </View>
                  )}
              </View>
            </View>
          )}
        {
          // coverage.condition is not supported by the API yet
          !disabled &&
            Boolean(coverageToShow) &&
            Boolean(coverageToShow.condition) && (
              <View style={styles.coverageDisclaimerContainer}>
                <Paragraph color={textColor}>
                  {`* ${coverageToShow.condition}`}
                </Paragraph>
              </View>
            )
        }
        {!disabled && size(buttons) > 0 && (
          <View
            style={[styles.buttonsContainer, { flexDirection: buttonsLayout }]}
          >
            {mapWithKey(
              (button, key, buttons) => (
                <View
                  style={
                    button === last(buttons)
                      ? getButtonsLayoutAwareStyle("lastButtonContainer")
                      : getButtonsLayoutAwareStyle("buttonContainer")
                  }
                  key={key.toString()}
                >
                  <EyrButton {...button} />
                </View>
              ),
              // they want it reversed you know
              buttonsLayout === "column" ? reverse(buttons) : buttons
            )}
          </View>
        )}
      </View>
    </View>
  );
}

ProductCard.propTypes = {
  itemType: PropTypes.oneOf(["Product", "SubCatalog"]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: moneyShape,
  coverages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["DISCOUNT", "FULL", "NONE", "PARTIAL"]).isRequired,
      discount: moneyShape.isRequired,
      org: PropTypes.shape({
        id: PropTypes.number.isRequired,
        smallIconUrl: PropTypes.string.isRequired,
      }).isRequired,
      condition: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  layout: PropTypes.oneOf(["row", "column"]),
  spacing: PropTypes.oneOf(["none", "m"]),
  buttons: PropTypes.array,
  buttonsLayout: PropTypes.oneOf(["row", "column"]),
};
