import React, { useEffect } from "react";
import { View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import {
  GetPaymentMethodsForOrder,
  useChildControlQuestion,
  useOrderFlowStage,
} from "@eyr-mobile/domain/Order";
import { find } from "lodash/fp";
import { useDevice } from "@eyr-mobile/core/Device";
import { useNavigation } from "@react-navigation/native";

import {
  EyrButton,
  Heading,
  Paragraph,
  ProgressIndicator,
} from "../../components";

import { messages } from "./FlowStageSetPaymentMethods_ControlQuestionChildScreen.messages";
import { styles } from "./FlowStageSetPaymentMethods_ControlQuestionChildScreen.styles";

export function FlowStageSetPaymentMethods_ControlQuestionChildScreen() {
  const { formatMessage } = useIntl();
  const { screenSizeSelect } = useDevice();
  const { setOptions } = useNavigation();

  const {
    data,
    stageParams: { progress, progressPerStage } = {},
    handlers,
  } = useOrderFlowStage(GetPaymentMethodsForOrder, ({ orderId }) => ({
    variables: { orderId },
  }));

  const [{ answerNegatively, answerPositively, controlPaymentMethodId }] =
    useChildControlQuestion();
  const controlPaymentMethodName = find(
    { id: controlPaymentMethodId },
    data?.accountPaymentMethodsForOrder
  )?.name;

  const progressWithinSubStages = progress + (1 / 3) * progressPerStage;

  useEffect(() => {
    if (controlPaymentMethodName) {
      setOptions({ title: controlPaymentMethodName });
    }
  }, [controlPaymentMethodName, setOptions]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progressWithinSubStages}
            accessibilityLabel={`Order progress indicator ${
              progressWithinSubStages * 100
            }%`}
          />
          <View
            style={[
              styles.contentContainer,
              screenSizeSelect({
                xs: styles.contentContainerXS,
                s: styles.contentContainerS,
                m: styles.contentContainerM,
              }),
            ]}
          >
            <View style={styles.textContainer}>
              {/*<View style={styles.titleContainer}>*/}
              <Heading alignment="center" size="s" spacing="s">
                {formatMessage(messages.title)}
              </Heading>
              {/*</View>*/}
              <Paragraph alignment={"center"} size={"m"}>
                {formatMessage(messages.description)}
              </Paragraph>
            </View>

            <View style={styles.buttonsContainer}>
              <EyrButton
                onPress={answerPositively}
                title={formatMessage(messages.confirmActionTitle)}
                variant={"secondary"}
                size={"l"}
              />
              <EyrButton
                onPress={answerNegatively}
                title={formatMessage(messages.declineActionTitle)}
                variant={"secondary"}
                size={"l"}
              />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

FlowStageSetPaymentMethods_ControlQuestionChildScreen.routeName =
  "FlowStageSetPaymentMethods_ControlQuestionChildScreen";
FlowStageSetPaymentMethods_ControlQuestionChildScreen.navigationOptions = {
  title: null,
};
