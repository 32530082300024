import React, { useCallback, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { getOr, noop, size } from "lodash/fp";
import { useIntl } from "react-intl";
import { View } from "react-native";
import { renderIf } from "@eyr-mobile/core/Lib";
import { useAlert } from "@eyr-mobile/core/Alert";
import { useDevice } from "@eyr-mobile/core/Device";

import { SVGs } from "../../res/svgs";
import { ListItemSeparator } from "../ListItemSeparator";
import { Card, CardInner } from "../Card";
import { ProfileImage } from "../ProfileImage";

import { styles } from "./ProviderPicker.styles";
import { messages } from "./ProviderPicker.messages";

const providerShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
  description: PropTypes.string,
});

export function ProviderPicker({
  providers,
  productType,
  initialSelectedProvider,
  onProviderSelected,
}) {
  const { formatMessage } = useIntl();
  const [selectedProvider, setSelectedProvider] = useState(
    initialSelectedProvider
  );
  const alert = useAlert();
  const noSelectionTitle = formatMessage(
    getOr(
      messages.noSelectionTitle,
      `${productType}_noSelectionTitle`,
      messages
    )
  );
  const selectActionTitle = formatMessage(
    getOr(
      messages.selectActionTitle,
      `${productType}_selectActionTitle`,
      messages
    )
  );
  const changeSelectionActionTitle = formatMessage(
    getOr(
      messages.changeSelectionActionTitle,
      `${productType}_changeSelectionActionTitle`,
      messages
    )
  );
  const { screenSizeSelect } = useDevice();
  const handleSelectAProvider = useCallback(() => {
    const closeAlert = alert({
      heading: selectedProvider
        ? changeSelectionActionTitle
        : selectActionTitle,
      paddingHorizontalSize: "none",
      paddingVerticalSize: "none",
      showHeaderDivider: true,
      showCloseButton: true,
      variant: screenSizeSelect({
        xs: "bottomSheet",
        s: "modal",
      }),
      children: (
        <>
          <View style={styles.cardContainer}>
            <CardInner
              title={noSelectionTitle}
              onPress={() => {
                handleSelectProvider(null);
                closeAlert();
              }}
            />
          </View>
          {renderIf(size(providers) > 0)(<ListItemSeparator />)}
          {providers.map((provider, index) => (
            <Fragment key={provider.id.toString()}>
              <View style={styles.cardContainer}>
                <CardInner
                  title={provider.name}
                  titleIsBold
                  description={provider.description}
                  onPress={() => {
                    handleSelectProvider(provider);
                    closeAlert();
                  }}
                  icon={
                    <ProfileImage
                      uri={provider.image}
                      size="s"
                      placeholder={provider.name}
                      shape="round"
                    />
                  }
                />
              </View>
              {renderIf(size(providers) > index + 1)(<ListItemSeparator />)}
            </Fragment>
          ))}
        </>
      ),
    });
  }, [
    handleSelectProvider,
    alert,
    changeSelectionActionTitle,
    noSelectionTitle,
    providers,
    screenSizeSelect,
    selectActionTitle,
    selectedProvider,
  ]);
  const handleSelectProvider = useCallback(
    (nextSelectedProvider) => {
      setSelectedProvider(nextSelectedProvider);
      onProviderSelected(nextSelectedProvider);
    },
    [setSelectedProvider, onProviderSelected]
  );

  return (
    <Card
      title={selectedProvider ? selectedProvider.name : noSelectionTitle}
      onPress={handleSelectAProvider}
      InteractivityIconComponent={SVGs.ChevronDownMono}
      spacing={"none"}
    />
  );
}

ProviderPicker.propTypes = {
  providers: PropTypes.arrayOf(providerShape),
  initialSelectedProvider: providerShape,
  onProviderSelected: PropTypes.func,
  productType: PropTypes.oneOf([
    "CONSULTATION",
    "PSYCHOLOGIST_CONSULTATION",
    "GUIDE_CONSULTATION",
    "PHYSIOTHERAPIST_CONSULTATION",
  ]),
};

ProviderPicker.defaultProps = {
  providers: [],
  initialSelectedProvider: null,
  onProviderSelected: noop,
};
