import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  listContentContainerXS: {
    ...responsiveStyles.containerXS,
    paddingBottom: 16,
    ...responsiveStyles.contentContainerXS,
  },
  listContentContainerS: {
    ...responsiveStyles.containerS,
    paddingBottom: 32,
    ...responsiveStyles.contentContainerS,
  },
  listContentContainerM: {
    ...responsiveStyles.containerM,
    paddingBottom: 48,
    ...responsiveStyles.contentContainerM,
  },
});
