import WebView from "react-native-webview";
import { isString } from "lodash";
import { LOGGER_LEVEL_WARN } from "@eyr-mobile/core/Logger";

import { Constants } from "../Config";

export const disableScalingInjectedJavaScript = `
(function disableScaling() {
  const meta = document.createElement("meta");
  meta.setAttribute(
    "content",
    "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
  );
  meta.setAttribute("name", "viewport");
  document.head.appendChild(meta);
})();`;

export const patchPostMessageInjectedJavaScript = `
(function proxyRNWebViewPostMessage() {
  window.postMessage = function (data) {
    window.ReactNativeWebView.postMessage(data);
  };
})();`;

// As for react-native-webview 10.3.2 WebView has a limitation: data must be a string.
// https://github.com/react-native-community/react-native-webview/blob/master/docs/Reference.md#onmessage
export const safeHandlePostMessage = (nativeEvent, logger, callback) => {
  const { data } = nativeEvent;
  if (!isString(data) || data === "") {
    logger("onMessage: invalid data format", data, LOGGER_LEVEL_WARN);
    return;
  }
  try {
    logger("onMessage", nativeEvent);
    return callback(data);
  } catch (error) {
    logger("onMessage:error", error, LOGGER_LEVEL_WARN);
  }
};

export const applicationNameForUserAgent = `EyrMobile/${Constants.expoConfig.version}`;
export { WebView };
