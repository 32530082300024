import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";

const logger = LoggerFactory.get("core/SessionTracking");
export function beginSessionTracking(token) {
  logger("beginSessionTracking:NOT IMPLEMENTED", token, LOGGER_LEVEL_WARN);
}

export function setSessionTrackingUserId(userId) {
  logger("setSessionTrackingUserId:NOT IMPLEMENTED", userId, LOGGER_LEVEL_WARN);
}

export function hideSensitiveViewFromVideoRecording(nativeID) {
  logger(
    "hideSensitiveViewFromVideoRecording:NOT IMPLEMENTED",
    nativeID,
    LOGGER_LEVEL_WARN
  );
}

export function pauseSessionTracking() {
  logger("pauseSessionTracking:NOT IMPLEMENTED", null, LOGGER_LEVEL_WARN);
}

export function resumeSessionTracking() {
  logger("resumeSessionTracking:NOT IMPLEMENTED", null, LOGGER_LEVEL_WARN);
}

export function disableVideoRecording() {
  logger("resumeSessionTracking:NOT IMPLEMENTED");
}
