import React, { useCallback } from "react";
import { ScrollView, View, Linking } from "react-native";
import { SafeAreaView, useSafeAreaInsets } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useAlert } from "@eyr-mobile/core/Alert";
import {
  GetAccountAcceptedConsents,
  useLegal,
  ConsentCategory,
} from "@eyr-mobile/domain/Legal";
import { EYR_SUPPORT_EMAIL } from "@eyr-mobile/domain/Order";
import { useApolloClient } from "@eyr-mobile/core/DataProvider";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  Paragraph,
  Accordion,
  EyrButton,
  EyrMarkdown,
  CardInner,
  Subtitle,
} from "../../components";

import { styles } from "./DeleteMyAccountScreen.styles";
import { messages } from "./DeleteMyAccountScreen.messages";

const handleEmailPress = () => Linking.openURL(`mailto:${EYR_SUPPORT_EMAIL}`);

export function DeleteMyAccountScreen() {
  const { formatMessage } = useIntl();
  const alert = useAlert();
  const { rejectConsent } = useLegal();
  // useApolloClient is used instead of useLazyQuery because: https://github.com/eyrmedical/eyr-mobile/pull/683/commits/fd79005f8b0e950c3098671b759bcad727815340
  const client = useApolloClient();
  const insets = useSafeAreaInsets();

  const findAccountConsent = (data) => {
    const checkAccountConsent = (orgAccountConsent) =>
      orgAccountConsent.consent.category === ConsentCategory.ACCOUNT;
    return data.account.orgAccounts
      .find((orgAccount) =>
        orgAccount.orgAccountConsents.some(checkAccountConsent)
      )
      .orgAccountConsents.find(checkAccountConsent);
  };

  const handleDeleteMyAccount = useCallback(() => {
    alert({
      title: formatMessage(messages.accountDeletionAlertTitle),
      buttonsLayout: "column",
      buttons: [
        {
          title: formatMessage(messages.accountDeletionAlertConfirmActionTitle),
          onPress: async () => {
            const { data } = await client.query({
              query: GetAccountAcceptedConsents,
              fetchPolicy: "network-only",
            });
            const accountConsent = findAccountConsent(data);
            rejectConsent(accountConsent.consent);
          },
          variant: "negative",
        },
        {
          title: formatMessage(messages.accountDeletionAlertCancelActionTitle),
          variant: "secondary",
        },
      ],
    });
  }, [alert, formatMessage, client, rejectConsent]);

  const dataThatWillBeDeletedMessageList = `
  * ${formatMessage(messages.name)}
  * ${formatMessage(messages.personalIdentificationNumber)}
  * ${formatMessage(messages.email)}
  * ${formatMessage(messages.phoneNumber)}
  * ${formatMessage(messages.yourAnswers)}
  * ${formatMessage(messages.yourMessages)}
  `;

  const dataThatWillNotBeDeletedMessageList = `
  * ${formatMessage(messages.healthRelatedData)}
  * ${formatMessage(messages.infoNecessaryTo)}
  `;

  const { screenSizeSelect } = useDevice();

  return (
    <SafeAreaView style={styles.container} edges={["left", "right"]}>
      <ScrollView
        contentContainerStyle={screenSizeSelect({
          xs: styles.contentContainerXS,
          s: styles.contentContainerS,
          m: styles.contentContainerM,
        })}
      >
        <Paragraph spacing="l" size="l">
          {formatMessage(messages.deletionSummary)}
        </Paragraph>
        <Paragraph size="l">
          {formatMessage(messages.deletionInstruction)}
        </Paragraph>
        <View style={styles.sectionContainer}>
          <View style={styles.accordionContainer}>
            <Accordion
              title={formatMessage(messages.dataThatWillBeDeleted)}
              header={
                <CardInner
                  pressable={false}
                  title={formatMessage(messages.dataThatWillBeDeleted)}
                  titleIsBold
                  showInteractivityIcon={false}
                />
              }
            >
              <View style={styles.accordionContentContainer}>
                <EyrMarkdown>{dataThatWillBeDeletedMessageList}</EyrMarkdown>
              </View>
            </Accordion>
          </View>
          <Accordion
            title={formatMessage(messages.dataThatWillNotBeDeleted)}
            header={
              <CardInner
                pressable={false}
                title={formatMessage(messages.dataThatWillNotBeDeleted)}
                titleIsBold
                showInteractivityIcon={false}
              />
            }
          >
            <View style={styles.accordionContentContainer}>
              <EyrMarkdown>{dataThatWillNotBeDeletedMessageList}</EyrMarkdown>
            </View>
          </Accordion>
        </View>
        <Paragraph size="l">
          {formatMessage(messages.contactSupport, {
            email: (
              <Subtitle
                onPress={handleEmailPress}
                size="l"
                decoration="underline"
              >
                {EYR_SUPPORT_EMAIL}
              </Subtitle>
            ),
          })}
        </Paragraph>
      </ScrollView>
      <View style={styles.bottomBar}>
        <View
          style={[
            screenSizeSelect({
              xs: styles.buttonContainerXS,
              s: styles.buttonContainerS,
              m: styles.buttonContainerM,
            }),
            { paddingBottom: insets.bottom },
          ]}
        >
          <EyrButton
            accessibilityLabel={formatMessage(messages.deleteMyAccount)}
            onPress={handleDeleteMyAccount}
            title={formatMessage(messages.deleteMyAccount)}
            size="l"
          />
        </View>
      </View>
    </SafeAreaView>
  );
}

DeleteMyAccountScreen.routeName = "DeleteMyAccountScreen";
DeleteMyAccountScreen.navigationOptions = {
  title: messages.navigationTitle,
};
