import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "FlowStageSpecifyAppointmentInformationScreen.navigationTitle",
    description:
      "FlowStageSpecifyAppointmentInformationScreen navigation title",
    defaultMessage: "When",
  },
});
