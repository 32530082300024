import React, { createRef } from "react";
import { parse } from "graphql";
import { from, split, ApolloLink, ApolloClient } from "@apollo/client/core";
import { ApolloProvider } from "@apollo/client/react/context";
import {
  useQuery,
  useMutation,
  useApolloClient,
  useLazyQuery,
  useSubscription,
} from "@apollo/client/react/hooks";
import {
  getOperationName,
  getOperationDefinition,
  graphQLResultHasError,
} from "@apollo/client/utilities";
import { createHttpLink } from "@apollo/client/link/http";
import { RetryLink } from "@apollo/client/link/retry";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import * as AbsintheSocket from "@absinthe/socket";
import { createSubscriptionsLink } from "@eyr-mobile/core/Net";
import { noop, has, get, includes, pick, compact } from "lodash/fp";
import { createUploadLink } from "apollo-upload-client";

import { LoggerFactory, LOGGER_LEVEL_ERROR } from "../Logger";
import {
  getAdditionalHeaders,
  getGraphqlURL,
  getSocketURL,
  Socket,
} from "../Net";
import { Constants } from "../Config";
import { logToErrorTracking } from "../ErrorTracking";

import { hasSubscription, isPublic, isRefetching } from "./Apollo.helpers";

const logger = LoggerFactory.get("core/DataProvider");

const REQUEST_ID_HEADER_NAME = "x-request-id";

const apolloAuthRef = createRef();
export const subscriptionsLinkRef = createRef();

const getWSAuthorizationParams = function () {
  const accessToken = apolloAuthRef.current?.accessToken;
  if (!accessToken) {
    return {};
  }
  return { token: accessToken };
};
let getHTTPAuthorizationHeaders = noop;
let onUnauthorized = noop;
let AuthState;

export function injectApolloAuth({
  authRef,
  getAuthorizationBearerHeader,
  AuthState: AuthStateCopy,
}) {
  AuthState = AuthStateCopy;
  if (!authRef) {
    return;
  }
  apolloAuthRef.current = authRef;
  getHTTPAuthorizationHeaders = function () {
    const accessToken = apolloAuthRef.current?.accessToken;
    if (!accessToken) {
      return;
    }
    return getAuthorizationBearerHeader(accessToken);
  };
  onUnauthorized = function () {
    const lock = apolloAuthRef.current?.lock;
    const state = apolloAuthRef.current?.state;
    if (!lock || !state || state !== AuthState.AUTHENTICATED) {
      return;
    }
    lock();
  };
}

const withHeaders = setContext((_, prevContext) => {
  const isPublic = prevContext?.public;
  const contextHeaders = prevContext?.headers;
  return {
    headers: {
      ...getAdditionalHeaders(),
      ...(!isPublic && getHTTPAuthorizationHeaders()),
      ...contextHeaders,
    },
  };
});

const loggingLink = new ApolloLink(function (operation, forward) {
  return forward(operation).map(function (result) {
    const { operationName, query } = operation;
    const operationType = getOperationDefinition(query).operation;
    logger(
      `${operationType}:${operationName}:${
        operationType === "subscription" ? "" : "request"
      }`,
      operation
    );
    logger(
      `${operationType}:${operationName}:${
        operationType === "subscription" ? "update" : "response"
      }`,
      result
    );
    return result;
  });
});

const errorsReportingLink = onError(
  ({ graphQLErrors, networkError, response, operation }) => {
    const { response: contextResponse, errorTrackingIncludeVariables = false } =
      operation.getContext();
    const reqId = contextResponse
      ? contextResponse.headers.get(REQUEST_ID_HEADER_NAME)
      : null;
    logToErrorTracking(
      JSON.stringify({
        graphQLErrors,
        networkError,
        response,
        [REQUEST_ID_HEADER_NAME]: reqId,
        operation: pick(
          compact([
            "operationName",
            errorTrackingIncludeVariables && "variables",
          ]),
          operation
        ),
      })
    );
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        logger(
          "GraphQL error",
          `Message: ${message}, Location: ${JSON.stringify(
            locations
          )}, Path: ${path}, ${REQUEST_ID_HEADER_NAME}: ${reqId}`,
          LOGGER_LEVEL_ERROR
        )
      );
    }
    if (networkError) {
      logger("Network error", networkError, LOGGER_LEVEL_ERROR);
      if (networkError.statusCode === 401) {
        onUnauthorized();
      }
    }
  }
);

class SubscriptionsLink extends ApolloLink {
  constructor() {
    super();
    this.createNewInstance();
  }
  createNewInstance() {
    function filterAndFormatInterestingEvents(kind, message, data) {
      if (kind === "push") {
        if (includes("doc", message)) {
          return [
            `subscription:${getOperationName(
              parse(get("query", data))
            )}:subscribe`,
            data,
          ];
        }
        if (includes("unsubscribe", message) && has("subscriptionId", data)) {
          return [
            `subscription:${get("subscriptionId", data)}:unsubscribe`,
            data,
          ];
        }
      }
      if (includes("transport", kind)) {
        return [`subscriptions:transport ${JSON.stringify(message)}`, data];
      }
      if (includes("channel", kind)) {
        return [
          `subscriptions:transport:channel ${JSON.stringify(message)}`,
          data,
        ];
      }
    }
    this.transport = AbsintheSocket.create(
      new Socket(getSocketURL(), {
        logger: (kind, message, data) => {
          const interestingEvent = filterAndFormatInterestingEvents(
            kind,
            message,
            data
          );
          if (interestingEvent) {
            logger(...interestingEvent);
          }
        },
        params: () => ({
          ...getWSAuthorizationParams(),
          version: Constants.expoConfig.version,
        }),
      })
    );
    this.instance = createSubscriptionsLink(this.transport);
  }
  teardown() {
    if (!this.transport) {
      return;
    }
    const { channel, phoenixSocket: socket } = this.transport;
    channel.onClose(() => {
      socket.disconnect(() => {
        this.transport = null;
        this.instance = null;
        this.createNewInstance();
      });
    });
    channel.leave();
  }
  request(operation, forward) {
    return this.instance.request(operation, forward);
  }
}

// https://github.com/jhen0409/react-native-debugger/issues/432#issuecomment-569184047
function setupApolloClient({
  onNetworkError,
  fetch,
  ...additionalApolloClientParams
}) {
  const httpLink = new RetryLink({
    delay: {
      initial: 100,
      max: 500,
      jitter: true,
    },
    attempts: {
      max: 3,
      retryIf: (error, operation) => {
        const { skipRetry = false } = operation.getContext();

        if (
          has("statusCode", error) &&
          error.statusCode >= 400 &&
          error.statusCode < 500
        ) {
          return false;
        }
        if (
          graphQLResultHasError({ errors: error ? error.graphQLErrors : [] })
        ) {
          return false;
        }
        if (skipRetry) {
          return false;
        }

        return true;
      },
    },
  }).split(
    isPublic,
    createHttpLink({ uri: () => getGraphqlURL(true).toString(), fetch }),
    createUploadLink({ uri: () => getGraphqlURL().toString(), fetch })
  );

  const subscriptionsLink = new SubscriptionsLink();
  subscriptionsLinkRef.current = subscriptionsLink;

  const networkLink = split(hasSubscription, subscriptionsLink, httpLink);

  return new ApolloClient({
    ...additionalApolloClientParams,
    link: from([
      withHeaders,
      loggingLink,
      errorsReportingLink,
      onError(({ networkError }) => {
        if (networkError) {
          if (networkError.statusCode === 401) {
            return;
          }
          onNetworkError(networkError);
        }
      }),
      networkLink,
    ]),
  });
}

function withHandlers(
  QueryResult,
  {
    showHandlersOnRefetch = false,
    showRetryButtonOnError = true,
    loadingAccessibilityLabel,
    LoadingComponent,
    ErrorComponent,
  } = {}
) {
  const { loading, error, networkStatus, client, refetch } = QueryResult;
  const DefaultLoadingComponent =
    client?.defaultOptions?.query?.LoadingComponent;
  const DefaultErrorComponent = client?.defaultOptions?.query?.ErrorComponent;
  const Loading = LoadingComponent || DefaultLoadingComponent;
  const Error = ErrorComponent || DefaultErrorComponent;
  const refreshing = isRefetching(networkStatus);
  let handlers = null;
  if (
    loading &&
    ((showHandlersOnRefetch && refreshing) ||
      (!showHandlersOnRefetch && !refreshing))
  ) {
    handlers = <Loading accessibilityLabel={loadingAccessibilityLabel} />;
  }

  if (error) {
    handlers = (
      <Error
        error={error}
        {...(showRetryButtonOnError ? { retryAction: refetch } : {})}
      />
    );
  }
  return { handlers, refreshing, ...QueryResult };
}

export {
  setupApolloClient,
  ApolloProvider,
  withHandlers,
  useQuery,
  useLazyQuery,
  useMutation,
  useApolloClient,
  useSubscription,
};
