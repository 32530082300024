import React from "react";
import moment from "moment";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { withHandlers } from "@eyr-mobile/core/DataProvider";
import {
  Get_WorkingHours_QueueWaitingTime_AppointmentOptions,
  useOrderFlowStage,
  useSpecifyAppointmentInformationFlowStage,
} from "@eyr-mobile/domain/Order";
import { DATE_FORMAT_DEFAULT } from "@eyr-mobile/core/Lib/date";
import { ScrollView } from "react-native";
import { useDevice } from "@eyr-mobile/core/Device";

import {
  ProgressIndicator,
  ConsultationTypePicker,
  CancelOrderHeaderRight,
} from "../../components";

import { messages } from "./FlowStageSpecifyAppointmentInformationScreen.messages";
import { styles } from "./FlowStageSpecifyAppointmentInformationScreen.styles";

export function FlowStageSpecifyAppointmentInformationScreen() {
  const { screenSizeSelect } = useDevice();

  const {
    handlers,
    data: { workingHours, queueWaitingTimeMinutes } = {},
    stageParams: { progress, product = {} } = {},
  } = withHandlers(
    useOrderFlowStage(
      Get_WorkingHours_QueueWaitingTime_AppointmentOptions,
      ({ orderId }) => ({
        variables: {
          orderId,
          startDate: moment.utc().format(DATE_FORMAT_DEFAULT),
          finishDate: moment.utc().add(1, "week").format(DATE_FORMAT_DEFAULT),
        },
      })
    )
  );
  const [{ selectAppointment, selectDropIn }] =
    useSpecifyAppointmentInformationFlowStage();
  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ProgressIndicator
            value={progress}
            accessibilityLabel={`Order progress indicator ${progress * 100}%`}
          />
          <ScrollView
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
          >
            <ConsultationTypePicker
              queueWaitingTimeMinutes={queueWaitingTimeMinutes}
              workingHours={workingHours}
              onDropInSelected={selectDropIn}
              onAppointmentSelected={selectAppointment}
              productType={product.type}
            />
          </ScrollView>
        </>
      )}
    </SafeAreaView>
  );
}
FlowStageSpecifyAppointmentInformationScreen.routeName =
  "FlowStageSpecifyAppointmentInformationScreen";
FlowStageSpecifyAppointmentInformationScreen.navigationOptions = {
  title: messages.navigationTitle,
  headerRight: CancelOrderHeaderRight,
};
