import { noop } from "lodash/fp";
import { useEffect, useRef } from "react";
import { useNetInfo } from "@eyr-mobile/core/Net";
import {
  logToErrorTracking,
  recordError,
} from "@eyr-mobile/core/ErrorTracking";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "@eyr-mobile/core/Logger";

const TAG = "domain/Call";
const logger = LoggerFactory.get(TAG);

export function usePrefetchCallsToken({
  attempts = 3,
  getCallsToken = noop,
} = {}) {
  const LOGGING_TAG = "prefetchCallsToken";
  const callTokenPrefetchedRef = useRef(false);
  const netInfoState = useNetInfo();
  useEffect(() => {
    if (!netInfoState.isInternetReachable || callTokenPrefetchedRef.current) {
      return;
    }
    let retryTimerId = null;

    async function prefetchCallDeviceToken(attempt = 1) {
      if (callTokenPrefetchedRef.current === true) {
        return;
      }
      const startedAt = Date.now();
      try {
        const token = await getCallsToken();
        const dataToLog = {
          attempt,
          token,
          startedAt,
          finishedAt: Date.now(),
        };
        logger(LOGGING_TAG, dataToLog);
        logToErrorTracking(
          `${TAG}:${LOGGING_TAG}:${JSON.stringify({
            ...dataToLog,
            token: Boolean(token),
          })}`
        );
        if (token) {
          callTokenPrefetchedRef.current = true;
          return;
        }
      } catch (e) {
        const dataToLog = {
          attempt,
          startedAt,
          finishedAt: Date.now(),
          error: e?.message,
        };
        logger(LOGGING_TAG, dataToLog);
        logToErrorTracking(
          `${TAG}:${LOGGING_TAG}:${JSON.stringify(dataToLog)}`
        );
      }
      if (attempt < attempts) {
        retryTimerId = setTimeout(
          () => prefetchCallDeviceToken(attempt + 1),
          attempt * 1000
        );
      }
      if (attempt === attempts) {
        const error = "RETRY_LIMIT_EXCEEDED";
        retryTimerId = null;
        logger(LOGGING_TAG, error, LOGGER_LEVEL_WARN);
        recordError(new Error(`${TAG}:${LOGGING_TAG}:${error}`));
      }
    }

    prefetchCallDeviceToken();
    return () => {
      if (retryTimerId) {
        clearTimeout(retryTimerId);
      }
    };
  }, [netInfoState.isInternetReachable, attempts, getCallsToken]);
}
