import { defineMessages } from "react-intl";

export const messages = defineMessages({
  toBePaidTitle: {
    id: "ProductCard.toBePaidTitle",
    description: "Price to be paid title",
    defaultMessage: "You pay:",
  },
  disabledDescription: {
    id: "ProductCard.disabledDescription",
    description: "Product description for when product is disabled",
    defaultMessage:
      "You can book a new appointment after your upcoming consultation.",
  },
  coveredTitle: {
    id: "ProductCard.coveredTitle",
    description: "Covered product chip title",
    defaultMessage: "Covered",
  },
  partialPaymentTitle: {
    id: "ProductCard.partialPaymentTitle",
    description: "Partial payment product chip title",
    defaultMessage: "Partial payment",
  },
  discountTitle: {
    id: "ProductCard.discountTitle",
    description: "Discount product chip title",
    defaultMessage: "{discountPercentage} discount",
  },
});
