import {
  getCameraPermissionsAsync,
  requestCameraPermissionsAsync as requestPermissionsAsync,
  Constants,
} from "expo-camera";
import { PermissionStatus } from "expo-modules-core";

import { LOGGER_LEVEL_WARN, LoggerFactory } from "../Logger";

const logger = LoggerFactory.get("core/Camera");

export async function getPermissionsAsync() {
  try {
    return await getCameraPermissionsAsync();
  } catch (e) {
    logger(
      "getPermissionsAsync:couldn't determine camera permissions",
      e,
      LOGGER_LEVEL_WARN
    );
    return {
      status: PermissionStatus.UNDETERMINED,
      expires: "never",
      canAskAgain: true,
      granted: false,
    };
  }
}

export { requestPermissionsAsync };

export const CameraType = {
  BACK: Constants.Type.back,
  FRONT: Constants.Type.front,
};

export const CameraTypeToPosition = {
  [Constants.Type.back]: "back",
  [Constants.Type.front]: "front",
};

export const CameraTypeToFacingMode = {
  [Constants.Type.back]: "environment",
  [Constants.Type.front]: "user",
};

export const CameraState = {
  ENABLED: "enabled",
  DISABLED: "disabled",
  UNKNOWN: "unknown",
};
