import React, { forwardRef } from "react";
import {
  OTSession as VideoSession,
  OTPublisher as VideoPublisher,
  OTSubscriber as OriginalVideoSubscriber,
  OTStreams as VideoStreams,
} from "opentok-react";

VideoSession.displayName = "VideoSession";
VideoPublisher.displayName = "VideoPublisher";

const MILLISECONDS_TO_SKIP = 10000;
const CONTROLS_HIDE_TIMEOUT = 5000;

export const VideoSubscriber = forwardRef(function VideoSubscriber(props, ref) {
  return (
    <VideoStreams>
      <OriginalVideoSubscriber {...props} ref={ref} />
    </VideoStreams>
  );
});

export {
  VideoSession,
  VideoPublisher,
  MILLISECONDS_TO_SKIP,
  CONTROLS_HIDE_TIMEOUT,
};
