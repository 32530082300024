import { gql } from "@apollo/client";

const accountDigitalToolsFields = gql`
  fragment accountDigitalToolsFields on AccountDigitalTool {
    __typename
    id
    seen
    digitalTool {
      __typename
      id
      title
      ... on DigitalToolForm {
        url
      }
    }
  }
`;

const commonContentFields = gql`
  fragment commonContentFields on SanityContentInterfaceV2 {
    __typename
    externalId
    kind
    program
    title
  }
`;

export const GetAccountDigitalTools = gql`
  query GetAccountDigitalTools {
    accountDigitalTools {
      ...accountDigitalToolsFields
    }
    contentForUserV2 {
      __typename
      name
      externalId
      modules {
        __typename
        externalId
        name
        unseenCount
      }
    }
    inboxAccountDigitalTools {
      __typename
      unseenCountContent
      unseenCountForms
    }
  }
  ${accountDigitalToolsFields}
`;

export const AccountDigitalToolsUpdatedSubscription = gql`
  subscription AccountDigitalToolsUpdatedSubscription {
    accountDigitalToolsUpdated {
      ...accountDigitalToolsFields
    }
  }
  ${accountDigitalToolsFields}
`;

export const GetContentPrograms = gql`
  query GetContentPrograms {
    contentForUserV2 {
      __typename
      externalId
      name
      modules {
        __typename
        externalId
        name
        unseenCount
        content {
          ...commonContentFields
          seen
        }
      }
    }
  }
  ${commonContentFields}
`;

export const ContentUpdated = gql`
  subscription ContentUpdated {
    contentForUserV2Updated {
      __typename
      externalId
      name
      modules {
        __typename
        externalId
        name
        unseenCount
        content {
          ...commonContentFields
          seen
        }
      }
    }
  }
  ${commonContentFields}
`;

export const GetContent = gql`
  query GetContent($externalId: ID!, $programExternalId: ID!) {
    content(externalId: $externalId, programExternalId: $programExternalId) {
      ...commonContentFields
      ... on ContentPsychoEducation {
        bannerImage
        content
      }
      ... on ContentTextExercise {
        content
      }
      ... on ContentVideoExercise {
        description
        duration
        url
      }
      ... on ContentAudioExercise {
        description
        duration
        url
      }
    }
  }
  ${commonContentFields}
`;

export const MarkArticleAsRead = gql`
  mutation markArticleAsRead($externalId: ID!) {
    markContentAsRead(externalId: $externalId)
  }
`;
