import { Platform } from "react-native";

import { Palette } from "./palette";

/*
 * Figma sources
 * {@link https://www.figma.com/file/0AiHOBE00Q5CyLR70xoIlF/Design-System-General?node-id=836%3A4706}
 */

const iosShadowStyles = {
  shadowColor: Palette.BLACK,
  shadowOpacity: 0.08,
};

export const Shadows = {
  xl: Platform.select({
    ios: {
      ...iosShadowStyles,
      shadowOffset: {
        width: 0,
        height: 16,
      },
      shadowRadius: 24,
    },
    android: {
      elevation: 10,
    },
    web: {
      boxShadow: `0px 16px 24px ${Palette.OVERLAY_DARK_20}`,
    },
  }),
  l: Platform.select({
    ios: {
      ...iosShadowStyles,
      shadowOffset: {
        width: 0,
        height: 12,
      },
      shadowRadius: 16,
    },
    android: {
      elevation: 8,
    },
    web: {
      boxShadow: `0px 12px 16px ${Palette.OVERLAY_DARK_20}`,
    },
  }),
  m: Platform.select({
    ios: {
      ...iosShadowStyles,
      shadowOffset: {
        width: 0,
        height: 8,
      },
      shadowRadius: 12,
    },
    android: {
      elevation: 6,
    },
    web: {
      boxShadow: `0px 8px 12px ${Palette.OVERLAY_DARK_20}`,
    },
  }),
  s: Platform.select({
    ios: {
      ...iosShadowStyles,
      shadowOffset: {
        width: 0,
        height: 4,
      },
      shadowRadius: 8,
    },
    android: {
      elevation: 4,
    },
    web: {
      boxShadow: `0px 4px 8px ${Palette.OVERLAY_DARK_20}`,
    },
  }),
  xs: Platform.select({
    ios: {
      ...iosShadowStyles,
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowRadius: 6,
    },
    android: {
      elevation: 2,
    },
    web: {
      boxShadow: `0px 2px 6px ${Palette.OVERLAY_DARK_20}`,
    },
  }),
};
