import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";

import { Paragraph } from "../Paragraph";
import { SVGs } from "../../res";
import { Subtitle } from "../Subtitle";
import { Heading } from "../Heading";

import { messages } from "./ExtraInfoForPhysiotherapistConsultation.messages";
import {
  styles,
  stylingProps,
} from "./ExtraInfoForPhysiotherapistConsultation.styles";

export function ExtraInfoForPhysiotherapistConsultation({
  initialFormattedPrice,
  initialDurationMinutes,
  followFormattedUpPrice,
  followDurationMinutes,
}) {
  const { formatMessage, formatNumber } = useIntl();

  return (
    <View>
      <View style={styles.introContainer}>
        <Paragraph size="l">{formatMessage(messages.introduction)}</Paragraph>
      </View>
      <View style={styles.productInfoContainer}>
        <View>
          <Heading size={"s"} spacing={"s"}>
            {formatMessage(messages.contentTitle)}
          </Heading>
        </View>
        {initialFormattedPrice && (
          <View style={styles.productInfoCardContainer}>
            <View>
              <Subtitle size={"l"} spacing={"s"}>
                {`1. ${formatMessage(messages.productInfoSection1Title)}`}
              </Subtitle>
            </View>
            <View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <SVGs.VideoCameraFrontOutlinedMono
                    {...stylingProps.productInfoItemIcon}
                  />
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(
                    messages.productInfoSection1ConsultationDuration,
                    {
                      duration: formatNumber(initialDurationMinutes, {
                        style: "unit",
                        unit: "minute",
                        unitDisplay: "long",
                      }),
                    }
                  )}
                </Paragraph>
              </View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <SVGs.ListAltOutlinedMono
                    {...stylingProps.productInfoItemIcon}
                  />
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(
                    messages.productInfoSection1TreatmentDescription
                  )}
                </Paragraph>
              </View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <SVGs.AppSettingsAltOutlinedMono
                    {...stylingProps.productInfoItemIcon}
                  />
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(
                    messages.productInfoSection1AppAccessDuration,
                    {
                      duration: formatNumber(2, {
                        style: "unit",
                        unit: "week",
                        unitDisplay: "long",
                      }),
                    }
                  )}
                </Paragraph>
              </View>
            </View>
            <Subtitle size={"l"}>{initialFormattedPrice}</Subtitle>
          </View>
        )}
        {followFormattedUpPrice && (
          <View style={styles.productInfoCardContainer}>
            <View>
              <Subtitle size={"l"} spacing={"s"}>
                {`2. ${formatMessage(messages.productInfoSection2Title)}`}
              </Subtitle>
            </View>
            <View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <SVGs.VideoCameraFrontOutlinedMono
                    {...stylingProps.productInfoItemIcon}
                  />
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(
                    messages.productInfoSection2ConsultationDuration,
                    {
                      duration: formatNumber(followDurationMinutes, {
                        style: "unit",
                        unit: "minute",
                        unitDisplay: "long",
                      }),
                    }
                  )}
                </Paragraph>
              </View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <SVGs.SyncOutlinedMono
                    {...stylingProps.productInfoItemIcon}
                  />
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(
                    messages.productInfoSection2TreatmentDescription
                  )}
                </Paragraph>
              </View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <SVGs.ChatOutlinedMono
                    {...stylingProps.productInfoItemIcon}
                  />
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(messages.productInfoSection2ChatAccess)}
                </Paragraph>
              </View>
              <View style={styles.productInfoItem}>
                <View style={styles.productInfoItemIconContainer}>
                  <View style={styles.productInfoItemIconContainer}>
                    <SVGs.AppSettingsAltOutlinedMono
                      {...stylingProps.productInfoItemIcon}
                    />
                  </View>
                </View>
                <Paragraph size={"l"} spacing={"m"}>
                  {formatMessage(
                    messages.productInfoSection2AppAccessDuration,
                    {
                      duration: formatNumber(2, {
                        style: "unit",
                        unit: "month",
                        unitDisplay: "long",
                      }),
                    }
                  )}
                </Paragraph>
              </View>
            </View>
            <Subtitle size={"l"}>{followFormattedUpPrice}</Subtitle>
          </View>
        )}
      </View>
      <View style={styles.footerContainer}>
        <View>
          <View style={styles.partnershipLogosContainer}>
            <SVGs.EyrLogo {...stylingProps.ourLogoIcon} />
            <SVGs.CloseMono {...stylingProps.conjunctionIcon} />
            <SVGs.InjurymapLogo {...stylingProps.theirLogoIcon} />
          </View>
          <Paragraph size={"l"} spacing={"l"}>
            {formatMessage(messages.partnerInfo)}
          </Paragraph>
          <Paragraph size={"l"}>
            {formatMessage(messages.partnerInfo2)}
          </Paragraph>
        </View>
        <View />
      </View>
    </View>
  );
}

ExtraInfoForPhysiotherapistConsultation.propTypes = {
  initialFormattedPrice: PropTypes.string,
  initialDurationMinutes: PropTypes.number,
  followFormattedUpPrice: PropTypes.string,
  followDurationMinutes: PropTypes.number,
};
