import AsyncStorage from "@react-native-async-storage/async-storage";
import { v4 } from "uuid";

import { GetAccountChildren } from "./Order.data";

const STORAGE_KEY_FAMILY_MEMBERS = "@Eyr:FamilyMembers";
export const orderResolvers = {
  Query: {
    async familyMembers() {
      const familyMembersJSON = await AsyncStorage.getItem(
        STORAGE_KEY_FAMILY_MEMBERS
      );
      return familyMembersJSON ? JSON.parse(familyMembersJSON) : [];
    },
  },
  Mutation: {
    async addFamilyMember(_, { input: { name, countryUID } }, { cache }) {
      const id = v4();
      const query = GetAccountChildren;
      const familyMembers = await orderResolvers.Query.familyMembers();
      const familyMember = {
        __typename: "FamilyMember",
        id,
        name,
        countryUID,
      };

      const newFamilyMembers =
        familyMembers && familyMembers.length
          ? [...familyMembers, familyMember]
          : [familyMember];

      await AsyncStorage.setItem(
        STORAGE_KEY_FAMILY_MEMBERS,
        JSON.stringify(newFamilyMembers)
      );
      cache.writeQuery({
        query,
        data: {
          familyMembers: newFamilyMembers,
        },
      });
      return familyMember;
    },
  },
};
