import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  container: {
    minHeight: 208,
    justifyContent: "center",
    borderRadius: 8,
    overflow: "hidden",
  },
  contentContainer: {
    paddingVertical: 48,
    paddingHorizontal: 24,
  },
  bannerImage: {
    ...StyleSheet.absoluteFill,
    width: "100%",
    height: "100%",
  },
  overlay: {
    ...StyleSheet.absoluteFill,
  },
  button: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "center",
  },
});

export const stylingProps = {
  linearGradient: {
    colors: [
      Palette.TRANSPARENT,
      Palette.OVERLAY_DARK_40,
      Palette.OVERLAY_DARK_40,
    ],
    locations: [0, 0.6, 1],
  },
};
