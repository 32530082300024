import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
  iconContainer: {
    paddingHorizontal: 8,
    marginBottom: 16,
  },
  textBlockContainer: {
    paddingHorizontal: 8,
    marginBottom: 24,
  },
  mediaBlockContainer: {
    marginBottom: 24,
    aspectRatio: 16 / 9,
    borderRadius: 8,
    overflow: "hidden",
    width: "100%",
    flexDirection: "row",
    alignItems: "stretch",
  },
  tagsContainer: {
    flexDirection: "row",
  },
  tagContainer: {
    marginRight: 12,
  },
  bannerImageContainer: {
    paddingVertical: 8,
  },
});
