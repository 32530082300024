import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FlatList, Linking, View } from "react-native";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import {
  useCurrentPosition,
  useLastKnownPosition,
} from "@eyr-mobile/core/Location";
import {
  AppPermissions,
  useAppPermissions,
} from "@eyr-mobile/core/AppPermissions";
import { sortBy, throttle } from "lodash/fp";
import { GetPharmacies } from "@eyr-mobile/domain/Pharmacy";
import { getDistance, getDistanceValues } from "@eyr-mobile/core/Lib";

import {
  Empty,
  EyrButton,
  EyrTextInput,
  ListItem,
  Paragraph,
  Subtitle,
} from "../../components";

import { styles } from "./PharmaciesScreen.styles";
import { messages } from "./PharmaciesScreen.messages";

const getFilteredPharmaciesThrottled = throttle(
  [500, { leading: true }],
  (text = "", pharmacies) => {
    if (!pharmacies) {
      return [];
    }
    if (!text.length) {
      return pharmacies;
    }
    const searchString = text.toLowerCase();
    return pharmacies.filter(({ name, city }) =>
      (name + city).toLowerCase().includes(searchString)
    );
  }
);

function PharmaciesListItem({
  name,
  distanceInMeters,
  openingHoursWeekDay,
  openingHoursWeekEnd,
  city,
  onOpenMap,
}) {
  const { formatMessage, formatNumber } = useIntl();

  const { unit, distance } = getDistanceValues(distanceInMeters);
  return (
    <ListItem layout="1" onPress={onOpenMap} pressable style={styles.listItem}>
      <View style={styles.listItemInner}>
        <View style={styles.row}>
          <Paragraph size="l">{name}</Paragraph>
        </View>
        <View style={styles.row}>
          <Paragraph size="l">
            {formatMessage(messages.pharmacyOpeningHours, {
              openingHoursWeekDay,
              openingHoursWeekEnd,
            })}
          </Paragraph>
        </View>
        <View style={styles.row}>
          <Subtitle size="l">{city}</Subtitle>
          {distance && (
            <Paragraph>
              {`  ${formatMessage(messages.pharmacyDistance, {
                distance: formatNumber(distance, {
                  style: "unit",
                  unit,
                  unitDisplay: "narrow",
                }),
              })}`}
            </Paragraph>
          )}
        </View>
        <View style={styles.row}>
          <Paragraph size="l" spacing="none" color="info">
            {formatMessage(messages.openInMapLink)}
          </Paragraph>
        </View>
      </View>
    </ListItem>
  );
}

PharmaciesListItem.propTypes = {
  onOpenMap: PropTypes.func.isRequired,
  name: PropTypes.string,
  openingHoursWeekDay: PropTypes.string,
  openingHoursWeekEnd: PropTypes.string,
  city: PropTypes.string,
  distanceInMeters: PropTypes.number,
};

const defaultSearchText = "";
const sortByDistance = sortBy("distanceInMeters");

export function PharmaciesScreen() {
  const [searchText, setSearchText] = useState(defaultSearchText);
  const { handlers, data } = withHandlers(useQuery(GetPharmacies));
  const { formatMessage } = useIntl();

  const { permissions } = useAppPermissions(
    [AppPermissions.LOCATION_FOREGROUND],
    { get: false, request: true }
  );
  const hasNoPermissions = !permissions?.granted;
  const { lastKnownPosition } = useLastKnownPosition({
    skip: hasNoPermissions,
  });
  const { currentPosition } = useCurrentPosition({
    skip: hasNoPermissions,
    watch: true,
    watchOptions: {
      timeInterval: 60 * 1000,
      distanceInterval: 5000,
    },
  });

  const pharmacies = data?.pharmacies;

  const handleRenderPharmacyListItem = useCallback(({ item }) => {
    const { name, address, city, zipCode } = item;
    return (
      <PharmaciesListItem
        {...item}
        onOpenMap={() => {
          const searchAddress = encodeURIComponent(
            `${name}, ${address} ${city} ${zipCode}`
          );
          Linking.openURL(
            `https://www.google.com/maps/search/?api=1&query=${searchAddress}`
          );
        }}
      />
    );
  }, []);

  const sortedPharmacies = useMemo(() => {
    if (!pharmacies) {
      return null;
    }

    const position = currentPosition || lastKnownPosition;

    if (!position?.coords) {
      return pharmacies;
    }

    return sortByDistance(
      pharmacies.map((pharmacy) => {
        const distanceInMeters = getDistance(position.coords, {
          latitude: pharmacy.lat,
          longitude: pharmacy.lng,
        });
        return {
          ...pharmacy,
          distanceInMeters,
        };
      })
    );
  }, [pharmacies, currentPosition, lastKnownPosition]);

  const filteredPharmacies = useMemo(
    () => getFilteredPharmaciesThrottled(searchText, sortedPharmacies),
    [searchText, sortedPharmacies]
  );
  const keyExtractor = useCallback(({ address }) => address, []);

  return (
    <View style={styles.container}>
      {handlers || (
        <>
          <View style={styles.listHeaderContainer}>
            <EyrTextInput
              style={styles.searchInput}
              placeholder={formatMessage(messages.searchInputPlaceholder)}
              value={searchText}
              onChangeText={setSearchText}
            />
          </View>
          <FlatList
            data={filteredPharmacies}
            keyExtractor={keyExtractor}
            renderItem={handleRenderPharmacyListItem}
            ListEmptyComponent={
              <Empty
                message={formatMessage(messages.noSearchResults)}
                iconName="Pharmacy"
              >
                <EyrButton
                  variant="text"
                  onPress={() => {
                    setSearchText(defaultSearchText);
                  }}
                  title={formatMessage(messages.resetSearch)}
                />
              </Empty>
            }
          />
        </>
      )}
    </View>
  );
}

PharmaciesScreen.routeName = "PharmaciesScreen";
PharmaciesScreen.navigationOptions = {
  title: messages.navigationTitle,
};
