import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";
import { responsiveStyles } from "../../shared-styles/responsive";

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Palette.NUDE_20,
  },
  containerXS: responsiveStyles.containerXS,
  containerS: responsiveStyles.containerS,
  containerM: responsiveStyles.containerM,
  contentContainerXS: responsiveStyles.contentContainerXS,
  contentContainerS: responsiveStyles.contentContainerS,
  contentContainerM: responsiveStyles.contentContainerM,
});
