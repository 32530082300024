import { gql } from "@apollo/client";

// https://www.apollographql.com/docs/react/essentials/local-state/#client-side-schema
// https://www.apollographql.com/docs/react/development-testing/client-schema-mocking/
export const orderTypeDefs = gql`
  type FamilyMember {
    id: Int!
    name: String!
    countryUID: String!
  }

  input AddFamilyMemberInput {
    name: String!
    countryUID: String!
  }
  extend type Order {
    createdAt: NaiveDateTime!
  }

  extend type RootQueryType {
    familyMembers: [FamilyMember!]!
  }

  extend type RootMutationType {
    addFamilyMember(input: AddFamilyMemberInput!): FamilyMember
  }
`;
