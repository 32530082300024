export const trueValueString = "true";
export const falseValueString = "false";

export const convertBooleanToString = (value) =>
  !value ? falseValueString : trueValueString;
export const convertStringToBoolean = (value) => {
  switch (value) {
    case trueValueString:
      return true;
    case falseValueString:
      return false;
    default:
      return !!value;
  }
};
