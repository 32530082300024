import { gql } from "@apollo/client";

import { treatmentPlanFields } from "../Order/Order.data";

export const AttachDocumentToTreatmentPlan = gql`
  mutation attachDocumentToTreatmentPlan(
    $documentInput: Upload!
    $treatmentPlanId: ID!
  ) {
    attachDocumentToTreatmentPlan(
      documentInput: $documentInput
      treatmentPlanId: $treatmentPlanId
    ) {
      ...treatmentPlanFields
    }
  }
  ${treatmentPlanFields}
`;

export const RemoveDocument = gql`
  mutation removeDocument($documentId: ID!) {
    removeDocument(documentId: $documentId) {
      ...treatmentPlanFields
    }
  }
  ${treatmentPlanFields}
`;
