import { StyleSheet } from "react-native";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  textContainer: {
    backgroundColor: Palette.NUDE_20,
    borderBottomWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderTopWidth: 0,
    borderStyle: "solid",
    borderColor: Palette.NUDE_40,
    paddingHorizontal: 24,
    paddingTop: 20,
    paddingBottom: 40,
    borderBottomLeftRadius: 16,
    borderBottomRightRadius: 16,
  },
  headerContainer: {
    alignItems: "center",
    aspectRatio: 1.7,
    width: "100%",
  },
  imageContainer: {
    zIndex: 2,
    width: "65%",
    height: "100%",
  },
  headerImage: {
    height: "100%",
    width: "100%",
  },
  headerFooter: {
    position: "absolute",
    height: "50%",
    width: "100%",
    bottom: 0,
    zIndex: 1,
    borderTopWidth: 2,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 0,
    borderStyle: "solid",
    borderColor: Palette.NUDE_40,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    backgroundColor: Palette.NUDE_20,
  },
});
