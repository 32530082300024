import { defineMessages } from "react-intl";

export const messages = defineMessages({
  navigationTitle: {
    id: "SessionRecoveryScreen.navigationTitle",
    description: "SessionRecoveryScreen navigation title",
    defaultMessage: "Session recovery",
  },
  pinEntryTitle: {
    id: "SessionRecoveryScreen.pinEntryTitle",
    description: "Pin entry title",
    defaultMessage: "Enter PIN",
  },
  invalidPinEntryMessage: {
    id: "SessionRecoveryScreen.invalidPinEntryMessage",
    description: "Invalid PIN entry message",
    defaultMessage:
      "Invalid PIN. {remainingAttempts} {remainingAttempts, plural, one {attempt} other {attempts}} left.",
  },
  logoutAlertTitle: {
    id: "SessionRecoveryScreen.logoutAlertTitle",
    description: "Logout alert title",
    defaultMessage: "Log out?",
  },
  logoutAlertCancelActionTitle: {
    id: "SessionRecoveryScreen.logoutAlertCancelActionTitle",
    description: "Logout alert cancel action title",
    defaultMessage: "No",
  },
  logoutAlertConfirmActionTitle: {
    id: "SessionRecoveryScreen.logoutAlertConfirmActionTitle",
    description: "Logout alert confirm action title",
    defaultMessage: "Yes",
  },
  exceededPinAttemptsAlertTitle: {
    id: "SessionRecoveryScreen.exceededPinAttemptsAlertTitle",
    description: "Exceeded PIN attempts alert title",
    defaultMessage: "Exceeded PIN attempts",
  },
  exceededPinAttemptsAlertMessage: {
    id: "SessionRecoveryScreen.exceededPinAttemptsAlertMessage",
    description: "Exceeded PIN attempts alert message",
    defaultMessage: "You'll be logged out.",
  },
  exceededPinAttemptsAlertConfirmActionTitle: {
    id: "SessionRecoveryScreen.exceededPinAttemptsAlertConfirmActionTitle",
    description: "Exceeded PIN attempts confirm action title",
    defaultMessage: "Ok",
  },
  biometryPromptTitle: {
    id: "SessionRecoveryScreen.biometryPromptTitle",
    description: "Biometry prompt title",
    defaultMessage: "Authentication",
  },
  biometryPromptCancelActionTitle: {
    id: "SessionRecoveryScreen.biometryPromptCancelActionTitle",
    description: "Biometry prompt cancel action title",
    defaultMessage: "Cancel",
  },
  authErrorAlertTitle: {
    id: "SessionRecoveryScreen.authErrorAlertTitle",
    description: "Auth error alert title",
    defaultMessage: "Something went wrong",
  },
  authErrorAlertConfirmActionTitle: {
    id: "SessionRecoveryScreen.authErrorAlertConfirmActionTitle",
    description: "Auth error alert confirm action title",
    defaultMessage: "Ok",
  },
});
