import { fontMaker, Fonts } from "@eyr-mobile/core/Lib";
import { Platform } from "react-native";

import {
  colorStyles,
  sizeStyles,
  spacingStyles,
} from "../TypographyRaw/TypographyRaw.styles";
import { Palette } from "../../shared-styles/palette";

export const styles = {
  heading1: [
    { marginTop: 24, flexDirection: "row" },
    sizeStyles.xxl,
    spacingStyles.l,
    fontMaker({
      fontWeight: "bold",
      fontFamily: Fonts.FONT_FAMILY_BUENOS_AIRES,
    }),
  ],
  heading2: [
    { marginTop: 16 },
    sizeStyles.xl,
    spacingStyles.m,
    fontMaker({
      fontWeight: "bold",
      fontFamily: Fonts.FONT_FAMILY_BUENOS_AIRES,
    }),
  ],
  heading3: [
    { marginTop: 8 },
    sizeStyles.l,
    spacingStyles.s,
    fontMaker({
      fontWeight: "bold",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  heading4: [
    { marginTop: 4 },
    sizeStyles.m,
    spacingStyles.s,
    fontMaker({
      fontWeight: "bold",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  text: [colorStyles.primary],
  paragraph: [
    { marginTop: 0 },
    sizeStyles.m,
    spacingStyles.m,
    fontMaker({
      fontWeight: "400",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  strong: [
    sizeStyles.m,
    fontMaker({
      fontWeight: "600",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  link: [
    { marginTop: 0 },
    sizeStyles.m,
    fontMaker({
      fontWeight: "400",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  bullet_list_icon: [
    {
      lineHeight: Platform.select({
        android: 16,
        default: 22,
      }),
    },
    // keep the same with paragraph
    colorStyles.primary,
    fontMaker({
      fontWeight: "600",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  ordered_list_icon: [
    // keep the same with paragraph
    {
      lineHeight: Platform.select({
        android: 16,
        default: 22,
      }),
    },
    colorStyles.primary,
    fontMaker({
      fontWeight: "600",
      fontFamily: Fonts.FONT_FAMILY_EUCLID_CIRCULAR_B,
    }),
  ],
  hr: [{ height: 1, backgroundColor: Palette.GRAY_20 }],
};
