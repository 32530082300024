import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { noop, find, get } from "lodash/fp";
import { View } from "react-native";
import moment from "moment";
import { formatNextTwoDaysAsRelative_DateOtherwise } from "@eyr-mobile/core/Lib";
import { useIntl } from "@eyr-mobile/core/Intl";

import { SVGs } from "../../res";
import { Card } from "../Card";
import { Heading } from "../Heading";
import { IconContainer } from "../IconContainer";
import { Badge } from "../Badge";

import { messages } from "./ConsultationTypePicker.messages";
import { styles } from "./ConsultationTypePicker.styles";

const TIME_LIMIT_DROP_IN_CLOSING_TIME_CUTOFF_MINUTES = 15;

const getCurrentWorkingHours = (workingHours, nowOverride) => {
  const now = nowOverride ? moment.utc(nowOverride) : moment.utc();
  return find(
    ({ opensAt, closesAt }) =>
      now.isBetween(
        moment.utc(opensAt),
        moment
          .utc(closesAt)
          .subtract(TIME_LIMIT_DROP_IN_CLOSING_TIME_CUTOFF_MINUTES, "minutes"),
        null,
        "[]"
      ),
    workingHours
  );
};

const getNextWorkingHours = (workingHours, nowOverride) => {
  const now = nowOverride ? moment.utc(nowOverride) : moment.utc();
  return find(({ opensAt }) => moment.utc(opensAt).isAfter(now), workingHours);
};

export function ConsultationTypePicker({
  now,
  onDropInSelected,
  onAppointmentSelected,
  queueWaitingTimeMinutes,
  workingHours,
  productType,
}) {
  const intl = useIntl();
  const { formatMessage, formatTime } = intl;
  const currentWorkingHours = useMemo(
    () => getCurrentWorkingHours(workingHours, now),
    [workingHours, now]
  );

  const nextWorkingHours = useMemo(
    () => getNextWorkingHours(workingHours, now),
    [workingHours, now]
  );

  const serviceProviderTitleMessage = get(
    `serviceProviderTitleFor_${productType}`,
    messages
  );

  const dropInDescription = useMemo(() => {
    if (currentWorkingHours) {
      return `${formatMessage(
        messages.dropInOptionDescriptionDuringWorkingHours
      )}:`;
    }
    if (!currentWorkingHours && nextWorkingHours) {
      return formatMessage(
        messages.dropInOptionDescriptionOutsideWorkingHours,
        {
          dateTime: `${formatTime(
            nextWorkingHours.opensAt
          )} ${formatNextTwoDaysAsRelative_DateOtherwise({
            date: nextWorkingHours.opensAt,
            intl,
            nowOverride: now,
          })}`,
        }
      );
    }
    return null;
  }, [
    currentWorkingHours,
    formatMessage,
    formatTime,
    intl,
    nextWorkingHours,
    now,
  ]);

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Heading alignment="center">{formatMessage(messages.header)}</Heading>
      </View>

      <Card
        onPress={onAppointmentSelected}
        icon={
          <IconContainer variant="plain" size="s">
            <SVGs.CalendarTime />
          </IconContainer>
        }
        titleIsBold
        layout="column"
        title={formatMessage(messages.appointmentOptionTitle)}
        description={formatMessage(messages.appointmentOptionDescription, {
          serviceProviderTitle: formatMessage(serviceProviderTitleMessage, {
            amount: 1,
          }),
        })}
      />

      <Card
        onPress={onDropInSelected}
        disabled={!currentWorkingHours}
        icon={
          <IconContainer variant="plain" size="s">
            <SVGs.DropIn />
          </IconContainer>
        }
        titleIsBold
        layout="column"
        badge={
          !currentWorkingHours ? (
            <Badge
              label={formatMessage(messages.closedLabel)}
              IconComponent={SVGs.MoonMono}
              variant="informal"
            />
          ) : null
        }
        title={formatMessage(messages.dropInOptionTitle, {
          serviceProviderTitle: formatMessage(serviceProviderTitleMessage, {
            amount: 1,
          }),
        })}
        description={dropInDescription}
        extraInfo={
          currentWorkingHours
            ? formatMessage(messages.waitingTime, {
                queueWaitingTimeMinutes,
              })
            : null
        }
      />
    </View>
  );
}
ConsultationTypePicker.propTypes = {
  workingHours: PropTypes.arrayOf(
    PropTypes.shape({
      opensAt: PropTypes.string.isRequired,
      closesAt: PropTypes.string.isRequired,
    })
  ).isRequired,
  queueWaitingTimeMinutes: PropTypes.number,
  onDropInSelected: PropTypes.func,
  onAppointmentSelected: PropTypes.func,
  now: PropTypes.string,
  productType: PropTypes.oneOf([
    "CONSULTATION",
    "PSYCHOLOGIST_CONSULTATION",
    "GUIDE_CONSULTATION",
  ]).isRequired,
};
ConsultationTypePicker.defaultProps = {
  onDropInSelected: noop,
  onAppointmentSelected: noop,
};
